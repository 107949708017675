import React from 'react';
import symbol from 'currency-symbol-map';
import PropTypes from '@prop-types';
import { withIntl } from '@react-intl';
import { withStyles as css, withTheme } from '@styled-components';
import { compose } from 'underscore';

import { OPTIONS } from 'Modules/dates';

import LineChart from 'Components/Charts/LineChart';

const Chart = ({ className, currency, sales, intl, theme, unit }) => {
  const handleTooltip = (ignore, value) =>
    intl.formatNumber(value, { currency, style: 'currency' });

  const options = OPTIONS[unit];
  const parser = value =>
    unit === 'hour'
      ? `${intl.formatTime(value, {
          hour: options.hour,
          hour12: true,
        })}-${intl.formatDate(value, { day: options.day })}`
      : intl.formatDate(value, options);

  return (
    <LineChart
      className={className}
      colors={[theme['--color-chart-blue']]}
      data={sales}
      options={{
        xTickFormat: value => parser(value),
        y: 'SALES',
        y1Tooltip: handleTooltip,
        y1Unit: symbol(currency),
      }}
    />
  );
};

Chart.propTypes = {
  className: PropTypes.string,
  currency: PropTypes.string,
  intl: PropTypes.intl,
  sales: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.string,
      y: PropTypes.string,
    }),
  ),
  theme: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  ),
  unit: PropTypes.string,
};

export default compose(
  css`
    margin: 0 -2.4rem 0;
    min-height: 34rem;
    padding: 0 2.4rem 1.6rem;
  `,
  withIntl,
  withTheme,
)(Chart);
