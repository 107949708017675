import React from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';
import { useLocation } from 'react-router-dom';

import RawAvatar from 'Components/Avatar';
import RawLink from 'Components/Link';

import { Row } from 'Components/ResponsiveTable';
import {
  withPlaceholderNoData as withPlaceholder,
  PlaceholderNoData as Placeholder,
} from 'Containers/Placeholder';

const Label = styled(Placeholder).attrs({ placeholder: 'label placeholder' })``;
const NameLabel = styled(Label)``;
const Avatar = styled(withPlaceholder(RawAvatar))``;
const Link = styled(withPlaceholder(RawLink))``;

const Template = ({
  answer,
  avatar,
  className,
  customerId,
  firstName,
  lastName,
}) => {
  const { pathname } = useLocation();

  return (
    <Row className={className}>
      <NameLabel>
        <Avatar fill image={avatar} name={`${firstName} ${lastName}`} />
        <Link to={`${pathname}/customers/${customerId}`}>
          {firstName} {lastName}
        </Link>
      </NameLabel>
      <Label>{answer}</Label>
    </Row>
  );
};

Template.propTypes = {
  answer: PropTypes.string,
  avatar: PropTypes.string,
  className: PropTypes.string,
  customerId: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
};

export default styled(Template)`
  grid-template-columns: 35rem 1fr;
  height: 5.6rem;
  padding: 0 2.4rem;

  ${Label} {
    ${theme('--font-medium')}
    ${theme('--font-opacity-60')}
    ${theme('--font-weight-book')}
    align-items: center;
    display: flex;

    ${Avatar} {
      height: 4rem;
      margin-right: 1.6rem;
      width: 4rem;
    }
  }

  ${NameLabel}, ${Link} {
    ${theme('--font-weight-medium')}
    color: ${theme('--color-primary')};
  }

  ${Link}{
    width: 85%;
  }
`;
