import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withStyles as css } from '@styled-components';

import DataCard, { CHART } from 'Components/DataCard';

import { withPlaceholder } from 'Containers/Placeholder/index';
import Chart from './components/Chart';
import Table from './components/Table';

const VisitsAndRevenues = ({
  className,
  currency,
  data,
  isLoading,
  limit,
  unit,
}) => (
  <DataCard
    chartTemplate={() =>
      data[0].length > 0 && (
        <Chart
          currency={currency}
          data={data[0].map((x, index) => ({
            x,
            y1: data[1][index] || 0,
            y2: data[2][index] || 0,
          }))}
          unit={unit}
        />
      )
    }
    className={className}
    initial={CHART}
    tableTemplate={() => (
      <Table
        currency={currency}
        data={data}
        isLoading={isLoading}
        limit={limit}
        unit={unit}
      />
    )}
    title="$VISITS_AND_SALES"
  />
);

VisitsAndRevenues.propTypes = {
  className: PropTypes.string,
  currency: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.array),
  isLoading: PropTypes.bool,
  limit: PropTypes.number,
  unit: PropTypes.string,
};

export default compose(
  css`
    height: 100%;
    padding: 6.4rem 0 0;

    ${Chart} {
      height: calc(100% - 1.6rem);
      margin: 0 2rem 0;
    }
  `,
  withPlaceholder,
)(VisitsAndRevenues);
