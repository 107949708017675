import React from 'react';
import PropTypes from 'prop-types';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { account } from 'Selectors';

export function checkPermissions(verticals = []) {
  return WrappedComponent => {
    const VerticalComponent = ({ vertical, ...props }) =>
      verticals.includes(vertical) && <WrappedComponent {...props} />;

    VerticalComponent.displayName = `checkVertical(${WrappedComponent.displayName ||
      WrappedComponent.name})`;
    VerticalComponent.propTypes = {
      vertical: PropTypes.string,
    };
    return hoistNonReactStatics(VerticalComponent, WrappedComponent);
  };
}

export default function withVerticals(permissions) {
  return function hoc(WrappedComponent) {
    const ComposedComponent = compose(
      connect(state => ({ vertical: account(state).type })),
      checkPermissions(permissions),
    )(WrappedComponent);

    ComposedComponent.displayName = `compose(${WrappedComponent.displayName ||
      WrappedComponent.name})`;

    function WithVerticals(props) {
      return <ComposedComponent {...props} />;
    }
    WithVerticals.displayName = `withVerticals(${ComposedComponent.displayName})`;
    return hoistNonReactStatics(WithVerticals, ComposedComponent);
  };
}
