import React from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';

import Card from 'Components/Card';

import Placeholder from 'Containers/Placeholder/index';

const Formatted = styled(Placeholder.pre)``;

const placeholder = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. `;

const Notes = ({ className, notes }) => (
  <Card className={className} title="NOTES">
    <Formatted placeholder={placeholder}>{notes}</Formatted>
  </Card>
);

Notes.propTypes = {
  className: PropTypes.string,
  notes: PropTypes.string,
};

export default styled(Notes)`
  height: 42rem;

  ${Formatted} {
    ${theme('--font-medium')}
    font-family: inherit;
    height: 100%;
    margin: 0;
    overflow: scroll;
    padding: 0;
    white-space: pre-wrap;
  }
`;
