import React from 'react';
import symbol from 'currency-symbol-map';
import PropTypes from '@prop-types';
import { withIntl } from '@react-intl';
import { withStyles as css, withTheme } from '@styled-components';
import { compose } from 'underscore';

import { OPTIONS } from 'Modules/dates';

import LineChart from 'Components/Charts/LineChart';

const Chart = ({ className, currency, data, intl, unit, theme }) => {
  const handleTooltip = (ignore, value) =>
    intl.formatNumber(value, { currency, style: 'currency' });

  const options = OPTIONS[unit];
  const parser = value =>
    unit === 'hour'
      ? `${intl.formatTime(value, {
          hour: options.hour,
          hour12: true,
        })}-${intl.formatDate(value, { day: options.day })}`
      : intl.formatDate(value, options);

  return (
    <LineChart
      className={className}
      colors={[theme['--color-chart-blue'], theme['--color-chart-purple']]}
      data={data}
      options={{
        xTickFormat: parser,
        xTicks: 10,
        y1: '$SALES',
        y1Tooltip: handleTooltip,
        y1Unit: symbol(currency),
        y2: 'VISITS',
      }}
    />
  );
};

Chart.propTypes = {
  className: PropTypes.string,
  currency: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.string,
      y1: PropTypes.number,
      y2: PropTypes.number,
    }),
  ),
  intl: PropTypes.intl,
  theme: PropTypes.object,
  unit: PropTypes.string,
};

export default compose(
  css`
    height: 34rem;
  `,
  withIntl,
  withTheme,
)(Chart);
