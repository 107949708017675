import React, { useCallback } from 'react';

import PropTypes from '@prop-types';
import FaqForm from 'Containers/FaqForm';
import { removeDuplicatedQuestions } from 'Containers/FaqForm/utils';
import { useMultiDataProviderContext } from 'Containers/MultiDataProvider';

import service from 'Services/faqs';

const JSON_ERROR = 'Unexpected end of JSON input';

const EditFaq = ({
  account,
  data,
  success,
  id,
  showError: error,
  onClose,
  role,
}) => {
  const { refetch } = useMultiDataProviderContext('faqs');
  const { locale, type } = data || {};

  const onSubmit = useCallback(
    async ({
      answer: { type: aType, content, counterQuestion, description },
      questions,
      score,
      tags,
    }) => {
      try {
        const answer =
          aType === 'CMD'
            ? { cmds: JSON.parse(content), counterQuestion, description }
            : { counterQuestion, text: content };

        await service.put({
          account,
          faq: {
            answer,
            questions: removeDuplicatedQuestions(questions),
            score,
            tags,
          },
          id,
          type,
        });

        onClose();
        success('FAQ_UPDATED');
        refetch();
      } catch ({ message }) {
        if (message.includes(JSON_ERROR)) {
          error('JSON_MALFORMED');
          return;
        }
        error('FAQ_UPDATED');
      }
    },
    [account, error, id, onClose, refetch, success, type],
  );

  return (
    <FaqForm
      account={account}
      defaultValue={data}
      error={error}
      locale={locale}
      onClose={onClose}
      onSubmit={onSubmit}
      role={role}
      success={success}
    />
  );
};

EditFaq.propTypes = {
  account: PropTypes.number,
  data: PropTypes.object,
  id: PropTypes.string,
  onClose: PropTypes.func,
  role: PropTypes.string,
  showError: PropTypes.func,
  success: PropTypes.func,
};

export default EditFaq;
