import React from 'react';
import PropTypes from '@prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as logger from 'Actions/logger';

import Loader from 'Components/Loader';

import { account as currentAccount } from 'Selectors';

import TableDataProvider from 'Containers/TableDataProvider';

import Services from 'Services/members';

import View from './Settings';

const mapStateToProps = state => ({
  account: currentAccount(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { error: logger.error, success: logger.success },
    dispatch,
  );

const Settings = ({ account, match: { url }, success, error }) => {
  return (
    <TableDataProvider
      name="settings"
      params={{ account: account.id }}
      query={Services.get}
      template={({ data: { collection: members } }) => {
        if (!members.length) return <Loader />;
        const me = members.find(({ you }) => you);

        return (
          <View
            account={account}
            error={error}
            me={me}
            members={members}
            success={success}
            url={url}
          />
        );
      }}
    />
  );
};

Settings.propTypes = {
  account: PropTypes.object,
  error: PropTypes.func,
  match: PropTypes.shape({ url: PropTypes.string }),
  success: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
