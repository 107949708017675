import React, { useState, useCallback, useEffect } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import styled, { withStyles as css, theme } from '@styled-components';
import { FormattedRelative, FormattedDate } from '@react-intl';
import Button from 'Components/Button';

import { useDeepCompareEffect } from 'Hooks';

import Chip from 'Components/Chip';
import Placeholder from 'Containers/Placeholder';
import Icon from 'Components/Icon';

import Funnel from './components/Funnel';
import Customers from './components/Customers';
import Revenues from './components/Revenues';
import Details from './components/Details';

import Summary from 'Components/Summary';
import InformationCard from 'Components/InformationCard';
import EmptyBar from 'Components/EmptyBar';
import Datum from './datum';

const Basic = styled('section')``;
const CampaignDetails = styled('dl')``;
const DownloadButton = styled(Button)``;
const CampaignIcon = styled(Icon)``;

const Name = styled(Placeholder.h3).attrs({
  placeholder: 'first name and last name',
})``;
const Header = styled('section')``;
const Capitalize = styled(Placeholder).attrs({
  placeholder: 'firstame lastname',
})``;

const Desktop = styled('div')``;
const Mobile = styled('div')``;

const PlatformDatum = styled(Datum).attrs({
  definition: 'platform',
  title: 'BROADCAST_PLATFORM',
})``;
const DestinationDatum = styled(Datum).attrs({
  definition: 'type',
  title: 'DESTINATION_PLATFORM',
})``;
const StatusDatum = styled(Datum).attrs({
  definition: 'status',
  title: 'STATUS',
})``;
const DateDatum = styled(Datum).attrs({
  definition: 'launch date',
  title: 'LAUNCH_DATE',
})``;
const TimeDatum = styled(Datum).attrs({
  definition: 'launch time',
  title: 'LAUNCH_TIME',
})``;
const ActivityDatum = styled(Datum).attrs({
  definition: 'last interaction',
  title: 'LAST_INTERACTION',
})``;

const CustomerSummary = styled(Summary).attrs({ accent: true })``;
const RegularSummary = styled('div')``;
const Desc = styled('span')``;

const Campaign = ({
  account,
  data: [campaign = {}, steps = []] = [],
  className,
  getType,
  onError,
}) => {
  const [step, setStep] = useState(steps[0]);
  const { step: stepName } = step || {};

  const handleStep = useCallback(
    selected => {
      const current = steps[selected];
      setStep(current);
    },
    [setStep, steps],
  );

  useEffect(() => {
    if (campaign.type) {
      getType(campaign.type);
    }
  }, [campaign.type, getType]);

  useDeepCompareEffect(() => {
    const [current = {}] = steps;
    setStep(current);
  }, [setStep, steps]);

  const entries = [
    {
      label: 'CUSTOMERS_ENROLLED',
      type: 'divided',
      value: [campaign.totalSuccess || 0, campaign.totalCustomers || 0],
    },
    {
      label: 'MESSAGES_DELIVERED',
      value: campaign.totalSuccess || 0,
    },
    {
      label: 'MESSAGES_FAILED',
      value: campaign.totalCustomers - campaign.totalSuccess,
    },
    {
      label: 'SUCCESS_RATE',
      type: 'percent',
      value: campaign.successRate || 0,
    },
  ];

  if (campaign.type === 'WHATSAPP') {
    entries.splice(2, 0, {
      label: 'MESSAGES_READ',
      value: campaign.totalRead || 0,
    });
  }

  const nameIsEmpty = campaign.name
    ? campaign.name.replace(/\s/g, '').length === 0
    : '';
  const iconType = campaign.type
    ? `${campaign.type.toLowerCase()}-variant1`
    : '';
  const iconPlatform = campaign.platform
    ? `${campaign.platform.toLowerCase()}-variant1`
    : '';

  let typeDesc;
  if (campaign.type === '') typeDesc = 'N/A';
  else if (campaign.type === 'SMS') typeDesc = 'SMS';
  else if (campaign.type === 'FB_MESSENGER') typeDesc = 'Messenger';
  else if (campaign.type === 'IG_MESSENGER') typeDesc = 'Instagram';
  else if (campaign.type === 'WHATSAPP') typeDesc = 'WhatsApp';
  else typeDesc = campaign.type;

  let platformDesc;
  if (campaign.platform === '') platformDesc = 'N/A';
  else if (campaign.platform === 'SMS') platformDesc = 'SMS';
  else if (campaign.platform === 'FB_MESSENGER') platformDesc = 'Messenger';
  else if (campaign.platform === 'IG_MESSENGER') platformDesc = 'Instagram';
  else if (campaign.platform === 'WHATSAPP') platformDesc = 'WhatsApp';
  else platformDesc = campaign.platform;

  return (
    <section className={className}>
      <Header>
        <CampaignIcon name={iconType} />
        <Basic>
          <Name>
            {!nameIsEmpty ? campaign.name : <EmptyBar>Campaign name</EmptyBar>}
          </Name>
          {/* <DownloadButton disabled>
            Download detailed delivery report
          </DownloadButton> */}
        </Basic>
      </Header>
      <CampaignDetails>
        <PlatformDatum>
          <Icon name={iconType} />
          <Desc> {typeDesc}</Desc>
        </PlatformDatum>
        <DestinationDatum>
          <Icon name={iconPlatform} />
          <Desc> {platformDesc}</Desc>
        </DestinationDatum>
        <StatusDatum>
          <Chip>{campaign.status}</Chip>
        </StatusDatum>
        <DateDatum>
          {campaign.launchDate && (
            <FormattedDate
              day="2-digit"
              month="long"
              value={new Date(parseInt(campaign.launchDate, 10))}
              year="numeric"
            />
          )}
        </DateDatum>
        <TimeDatum>
          {campaign.launchDate && (
            <FormattedDate
              hour="numeric"
              minute="numeric"
              value={new Date(parseInt(campaign.launchDate, 10))}
            />
          )}
        </TimeDatum>
        <ActivityDatum>
          <FormattedRelative value={new Date(campaign.updatedAt)} />
        </ActivityDatum>
      </CampaignDetails>
      <CustomerSummary
        heading={campaign.totalCustomers || 0}
        highlight="true"
        title="CUSTOMERS_ENROLLED"
      />
      <RegularSummary>
        <Desktop>
          <InformationCard entries={entries.slice(1)} />
        </Desktop>
        <Mobile>
          <InformationCard entries={entries} />
        </Mobile>
      </RegularSummary>
      {campaign.type && (
        <Details account={account} onError={onError} {...campaign} />
      )}
      {step && (
        <Funnel
          campaign={campaign}
          id={campaign.id}
          onChange={handleStep}
          step={step}
          steps={steps}
        />
      )}
      <Customers filterBy={stepName} id={campaign.id} />
      {/* {campaign.id && <Revenues id={campaign.id} />} */}
    </section>
  );
};

Campaign.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
  data: PropTypes.array,
  getType: PropTypes.func,
  onError: PropTypes.func.isRequired,
};

export default compose(
  css`
    display: grid;
    grid-gap: 2.4rem;
    grid-template: auto / auto;

    ${CampaignIcon} {
      height: 70px;
      width: 70px;
    }

    ${DownloadButton} {
      background-color: ${theme('--color-light')};
      border: 1px solid #005DFF;
      border-radius: 0.4rem;
      color: #005DFF;
      font-size: 1.2rem;
      font-weight: 400;
      height: 2.4rem;
      padding: 0 1.2rem;
      text-align: center;

      &[disabled] {
        border: 1px solid grey;
        color: grey;
        cursor: not-allowed;
      }
    }

    ${Desc} {
      vertical-align: super;
    }

    ${Chip} {
      ${theme('--font-weight-demi')}
      background: ${theme('--color-main-20')};
      border: none;
      border-radius: 0.6rem;
      color: ${theme('--color-main')};
    }

    ${Funnel} {
      grid-column: 1 / span 3;
    }

    ${Customers} {
      grid-column: 1 / span 3;
    }

    ${Revenues} {
      grid-column: 1 / span 3;
    }

    ${Desktop} {
      display: none;
    }

    ${Header} {
      align-items: center;
      display: flex;
      grid-row: 1;

      ${Basic} {
        ${theme('--font-medium')}
        ${theme('--font-weight-book')}
        ${theme('--color-dark-night-60')}
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 1.6rem;

        ${Name} {
          ${theme('--font-xlarge')}
          ${theme('--font-weight-medium')}
          margin-bottom: 1.2rem;
          max-width: 20rem;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        ${Capitalize} {
          &::first-letter {
            text-transform: Capitalize;
          }

          &:first-child {
            margin-bottom: 0;
          }

          color: ${theme('--color-dark-night-40')};
          margin-bottom: 0.8rem;
          text-transform: Capitalize;
        }
      }
    }

    ${CampaignDetails} {
      display: grid;
      grid-gap: 1.6rem 3.2rem;
      grid-row: 2;
      grid-template: auto auto/ 1fr 1fr;
      margin: 0;

      ${PlatformDatum} {
        grid-column: 1;
        grid-row: 1;
      }

      ${DestinationDatum} {
        grid-column: 2;
        grid-row: 1;
      }

      ${StatusDatum} {
        grid-column: 3;
        grid-row: 1;
      }

      ${DateDatum} {
        grid-column: 1;
        grid-row: 2;
        text-transform: capitalize;
      }

      ${TimeDatum} {
        grid-column: 2;
        grid-row: 2;
        text-transform: capitalize;
      }

      ${ActivityDatum} {
        grid-column: 3;
        grid-row: 2;
      }
    }

    ${CustomerSummary} {
      display: none;
    }

    ${RegularSummary} {
      grid-row: 3;
    }

    ${InformationCard} {
      padding: 2.4rem;
    }

    @media (${theme('--screen-medium')}) {
      grid-gap: 3.2rem;
      grid-template-columns: repeat(3, 1fr);

      ${Desktop} {
        display: block;
      }

      ${Mobile} {
        display: none;
      }

      ${Header} {
        grid-column: 1 / span 2;

        ${Basic} {
          margin-left: 3.2rem;

          ${Name} {
            ${theme('--font-xxlarge')}
            max-width: 45rem;
          }
        }
      }

      ${CampaignDetails} {
        grid-column: 1 / span 2;
        grid-template: auto / repeat(3, 1fr);

        ${StatusDatum} {
          grid-column: 3 / span 2;
          grid-row: 1;
        }

        ${TimeDatum} {
          grid-column: 2;
          grid-row: 2;
        }
      }

      ${CustomerSummary} {
        display: block;
        grid-column: 3;
        grid-row: 1;
      }

      ${RegularSummary} {
        grid-column: 3;
        grid-row: 2;
      }

      ${CampaignIcon} {
        height: 140px;
        width: 140px;
      }
    }
  `,
)(Campaign);
