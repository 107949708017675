const theme = {
  axis: {
    style: {
      axis: {
        stroke: 'none',
      },
      axisLabel: {
        fontSize: 12,
      },
      grid: {
        stroke: 'rgba(242, 243, 244, 0.7)',
      },
      tickLabels: {
        fill: 'rgba(0, 15, 25, 0.4)',
        fontFamily: 'Maison Neue',
        fontSize: 12,
        fontWeight: 0,
      },
      ticks: {
        size: 25,
        stroke: 'none',
      },
    },
  },
};

export default theme;
