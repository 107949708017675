import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';

import { Input } from 'Components/Form';

import Country, { countryFindByPhone } from './Country';

const Phone = ({ className, onChange, value = '' }) => {
  const {
    cca2,
    idd: { root, suffixes },
  } = countryFindByPhone(value);
  const digitalCode = `${root}${suffixes.length === 1 ? suffixes[0] : ''}`;

  const [number, setNumber] = useState(value.length > 1 ? value : digitalCode);
  const [country, setCountry] = useState(cca2);

  const handleCountry = countrySelected => {
    setCountry(countrySelected.stringCode);
    setNumber(countrySelected.digitalCode);
    onChange(countrySelected.digitalCode);
  };

  const handleNumber = v => {
    const numberFormatted = `+${v.replaceAll(/[^\d]/g, '')}`;

    setNumber(numberFormatted);
    onChange(numberFormatted);
  };

  return (
    <div className={className}>
      <Country onChange={handleCountry} value={country} />
      <Input
        label="number"
        name="number"
        onChange={handleNumber}
        value={number}
      />
    </div>
  );
};

Phone.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default styled(Phone)`
  display: flex;
  height: 4.8rem;

  ${Country} {
    ${theme('--font-small')}
    height: 100%;
    margin-right: 0.8rem;
    width: 7.2rem;
  }

  ${Input} {
    height: 100%;
    width: 100%;
  }
`;
