import React, { useRef, useEffect } from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';

import Chip from 'Components/Chip';

import Ellipsis from './Ellipsis';

const ELLIPSIS_WIDTH = 27;
const TAG_SPACING = 4;

const hideElement = element => {
  element.dataset.hidden = true; //eslint-disable-line
  element.style.position = 'absolute'; //eslint-disable-line
  element.style.opacity = 0; //eslint-disable-line
  return element.innerHTML;
};

const TagList = ({ className, tags = [] }) => {
  const ref = useRef(null);
  const tagNames = tags
    .map(({ name }) => name)
    .sort((a, b) => b.length - a.length);

  useEffect(() => {
    window.requestAnimationFrame(() => {
      const { current } = ref;
      if (!current) return;

      const ellipsis = current.querySelector('[data-type="ellipsis"]');
      const tooltip = current.querySelector('[data-type="tooltip"]');
      ellipsis.dataset.hidden = true;

      const { offsetWidth: maxWidth, offsetHeight: maxHeight } = current;
      const maxRows = Math.round(maxHeight / 28) || 1;
      const children = Array.from(current.children);

      ellipsis.style.opacity = 0;

      const hiddenTags = children.reduce(
        (
          {
            accOffset,
            remainingTags,
            rowWidth: lastRowWidth,
            childRow: lastChildRow,
            childsInRow: lastChildsInRow,
            lastChild,
          },
          child,
        ) => {
          const { offsetWidth: childWidth, offsetTop } = child;
          const currentChildRow = (offsetTop - 12) / 28 + 1;
          let childRow;
          let rowWidth;
          let childsInRow;

          if (lastChildRow !== currentChildRow) {
            childRow = currentChildRow;
            rowWidth = childWidth;
            childsInRow = 0;
          } else {
            childRow = currentChildRow;
            rowWidth = lastRowWidth + childWidth + TAG_SPACING;
            childsInRow = lastChildsInRow + 1;
          }

          const isLastRow = childRow === maxRows;
          const acc = accOffset + childWidth;

          if (child === ellipsis) {
            if (
              !ellipsis.dataset.hidden &&
              lastChild &&
              !lastChild.dataset.hidden &&
              childsInRow === 0
            ) {
              const content = hideElement(lastChild);
              ellipsis.style.opacity = 1;
              remainingTags.push(content);
            }
            return remainingTags;
          }

          if (
            childRow > maxRows ||
            (isLastRow && rowWidth > maxWidth - ELLIPSIS_WIDTH)
          ) {
            const content = hideElement(child);
            ellipsis.style.opacity = 1;
            ellipsis.dataset.hidden = false;
            remainingTags.push(content);
          }

          return {
            accOffset: acc,
            childRow,
            childsInRow,
            lastChild: child,
            remainingTags,
            rowWidth,
          };
        },
        {
          accOffset: 0,
          childRow: 0,
          childsInRow: 0,
          lastChild: null,
          remainingTags: [],
          rowWidth: 0,
        },
      );

      tooltip.innerHTML = hiddenTags.join(', ');
    });
  }, []);

  return (
    <div ref={ref} className={className}>
      {tagNames.map(name => (
        <Chip key={name}>{name}</Chip>
      ))}
      <Ellipsis />
    </div>
  );
};

TagList.propTypes = {
  className: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
};

export default styled(TagList)`
  display: block;
  height: 100%;
  max-width: 100%;

  ${Chip} {
    background: #eaccff;
    border: none;
    border-radius: 0.6rem;
    color: #9500ff;
    display: inline-block;
    flex-shrink: 0;
    margin-right: 0.4rem;
    max-width: 100%;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ${Ellipsis} {
    margin-right: 0;
    min-width: unset;
    overflow: visible;
    position: relative;
    top: -0.8rem;
  }
`;
