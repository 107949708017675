import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isLoggedIn } from 'Selectors';

import { logout } from 'Actions/session';

import Logout from './Logout';

const mapDispatchToProps = dispatch => bindActionCreators({ logout }, dispatch);

const mapStateToProps = state => ({
  isLoggedIn: isLoggedIn(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
