import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { set } from 'Actions/accounts';
import { accounts, currentAccount } from 'Selectors';

import DataProvider from 'Containers/DataProvider';

import service from 'Services/conversations';

import Empty from './components/Empty';
import View from './Conversation';

const mapStateToProps = state => ({
  account: currentAccount(state),
  officialAccounts: accounts(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ setOfa: set }, dispatch);

const Conversation = ({ account, officialAccounts = [], setOfa, ...props }) => {
  // eslint-disable-next-line no-restricted-globals
  const urlParams = new URLSearchParams(location.search);
  const contactId = urlParams.get('contactId');
  const ofaId = Number(urlParams.get('ofaId')) || account;
  const ofaSecret = urlParams.get('ofaSecret');
  const type = urlParams.get('type');
  const accountName =
    officialAccounts.find(({ id }) => id === ofaId)?.name || 'Bot';

  useEffect(() => {
    if (account !== ofaId) setOfa(ofaId);
  }, [account, ofaId, setOfa]);

  return (
    <div>
      <DataProvider
        accountName={accountName}
        emptyTemplate={() => <Empty />}
        params={{ contactId, ofaId, ofaSecret, type }}
        query={service.getById}
        template={View}
        {...props}
      />
    </div>
  );
};

Conversation.propTypes = {
  account: PropTypes.number,
  officialAccounts: PropTypes.array,
  setOfa: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Conversation);
