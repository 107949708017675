import React from 'react';
import PropTypes from '@prop-types';
import styled, { theme } from '@styled-components';

import { withPlaceholder } from 'Containers/Placeholder';

import Card from 'Components/Card';
import Empty from 'Components/Empty';

import {
  FormattedDate,
  FormattedMessage,
  FormattedNumber,
  FormattedCurrency,
} from '@react-intl';

const List = styled('dl')``;
const Datum = styled('div')``;
const Label = styled('dt')``;
const Value = styled(withPlaceholder('dd')).attrs({
  placeholder: 'value',
})``;

const noData = value => (value !== undefined ? value : <Empty />);

const renderValueComponent = (
  type,
  value,
  { currency, timeZone, timeZoneName } = {},
) => {
  if (!type || !value) return noData(value);
  if (type === 'currency')
    return <FormattedCurrency currency={currency} value={value} />;
  if (type === 'date')
    return (
      <FormattedDate
        timeZone={timeZone}
        timeZoneName={timeZoneName}
        value={value}
      />
    );
  if (type === 'percent')
    /* eslint-disable-next-line react/style-prop-object */
    return <FormattedNumber style="percent" value={value / 100} />;
  if (type === 'divided') {
    return `${value[0]}/${value[1]}`;
  }
  return noData(value);
};

const InformationCard = ({ className, entries }) => (
  <Card className={className}>
    <List>
      {entries.map(({ label, value, type, options }) => {
        return (
          <Datum key={label}>
            <FormattedMessage capitalize component={Label} id={label}>
              {label}
            </FormattedMessage>
            <Value>{renderValueComponent(type, value, options)}</Value>
          </Datum>
        );
      })}
    </List>
  </Card>
);

InformationCard.propTypes = {
  className: PropTypes.string,
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      options: PropTypes.object,
      type: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
};

export default styled(InformationCard)`
  grid-column: span 1;
  grid-row: span 1;

  ${List} {
    margin: 0;
  }

  ${Datum}{
    display: flex;
    justify-content: space-between;
    padding-bottom: 1.6rem;

    &:last-child{
      padding-bottom: 0;
    }
    
    ${Label} {
      ${theme('--font-large')}
      color: ${theme('--color-dark-night-60')};
    }
    
    ${Value}{
      ${theme('--font-large')}
      ${theme('--font-weight-demi')}
      color: ${theme('--color-dark-night')};
    }
  }
`;
