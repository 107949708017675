import React from 'react';
import PropTypes from '@prop-types';
import styled, { theme } from '@styled-components';
import Icon from 'Components/Icon';
import { FormattedMessage } from '@react-intl';

const Title = styled('h2')``;

const EmptyTemplate = ({ className }) => (
  <article className={className}>
    <Icon name="transactions-empty">TRANSACTIONS_EMPTY</Icon>
    <FormattedMessage
      capitalize
      component={Title}
      id="EMPTY_TRANSACTIONS.FILTER_TRANSACTIONS"
    >
      EMPTY_TRANSACTIONS.FILTER_TRANSACTIONS
    </FormattedMessage>
  </article>
);

EmptyTemplate.propTypes = {
  className: PropTypes.string,
};

export default styled(EmptyTemplate)`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  min-height: 40rem;

  ${Icon} {
    height: 10.4rem;
    margin-bottom: 2.4rem;
    width: 10.4rem;
  }

  ${Title} {
    ${theme('--font-large')}
    ${theme('--font-weight-book')}
    ${theme('--font-opacity-100')}
    margin-bottom: 0.4rem;
  }
`;
