import React from 'react';
import PropTypes from '@prop-types';
import { FormattedMessage } from '@react-intl';
import styled, { withStyles as css, theme } from '@styled-components';
import FocusTrap from 'focus-trap-react';

import { compose } from 'underscore';

import IconButton from 'Components/IconButton';
import withTransition from 'Components/with/transition';

export const Actions = styled('div')``;
const Content = styled('div')``;
const Header = styled('header')``;
const Title = styled('h2')``;
export const Wrapper = styled('article')``;
const Overlay = styled('button')``;

const Dialog = ({ children, noClose, onDismiss, title, className }) => {
  return (
    <FocusTrap focusTrapOptions={{ initialFocus: '#dialog' }}>
      <div className={className}>
        <Wrapper id="dialog">
          <Header>
            {title && (
              <FormattedMessage capitalize component={Title} id={title}>
                {title}
              </FormattedMessage>
            )}
            {!noClose && (
              <IconButton
                data-testid="close-button"
                name="close"
                onClick={onDismiss}
              />
            )}
          </Header>
          <Content>{children}</Content>
        </Wrapper>
        <Overlay onClick={onDismiss} />
      </div>
    </FocusTrap>
  );
};

Dialog.propTypes = {
  children: PropTypes.children,
  className: PropTypes.string,
  noClose: PropTypes.bool,
  onDismiss: PropTypes.func,
  title: PropTypes.string,
};

export default compose(
  css`
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1001;

    ${Wrapper} {
      background-color: ${theme('--color-light')};
      border-radius: 0.6rem;
      margin: 0 auto;
      max-width: 33rem;
      opacity: 0;
      padding: 2.4rem;
      transform: translateY(-25vh);
      transition: transform 350ms ease-out, opacity 350ms ease-out;
      width: 100%;
      z-index: 1002;

      ${Header} {
        height: 5.2rem;
        margin: -2.4rem -2.4rem 0 -2.4rem;
        padding: 2.4rem;
        position: relative;

        ${Title} {
          ${theme('--font-medium')}
          ${theme('--font-weight-medium')}
          ${theme('--font-opacity-80')}
          margin: 0;
        }

        ${IconButton} {
          position: absolute;
          right: 1.2rem;
          top: 1.2rem;
        }
      }
    }

    ${Actions} {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 1.6rem;
    }

    ${Overlay} {
      background-color: ${theme('--color-dark')};
      border: none;
      height: 100%;
      opacity: 0;
      position: absolute;
      top: 0;
      transition: opacity 350ms ease-out;
      width: 100%;
    }

    &.entered {
      ${Wrapper} {
        opacity: 1;
        transform: translateY(0);
      }

      ${Overlay} {
        opacity: 0.4;
      }
    }

    @media screen and (${theme('--screen-small')}) {
      ${Wrapper} {
        max-width: 54rem;
      }

      ${Wrapper} ${Actions} > * {
        margin-right: 1.6rem;
        margin-top: 0;
        min-width: initial;
      }
    }
  `,
  withTransition(350),
)(Dialog);
