import React, { useCallback } from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from '@prop-types';
import { FormattedMessage } from '@react-intl';
import { useField } from 'spooner';

const Title = styled('h5')``;
const Wrapper = styled('div')``;
const Description = styled('p')``;
const Radio = styled('span')``;

const RadioType = ({ className, id, init, name }) => {
  const { value, onChange } = useField({ name });

  const handleChange = useCallback(() => {
    onChange(id);
  }, [id, onChange]);

  let isSelected = false;

  if (value) {
    isSelected = id === value;
  } else if (init) {
    isSelected = id === init;
  }

  return (
    <button className={className} onClick={handleChange} type="button">
      <Wrapper data-selected={isSelected}>
        <Radio />
        <FormattedMessage capitalize component={Title} id={id} />
        <FormattedMessage
          capitalize
          component={Description}
          id={`INSTRUCTIONS.DESCRIPTION_${id}`}
        />
      </Wrapper>
    </button>
  );
};

RadioType.propTypes = {
  className: PropTypes.string,
  id: PropTypes.number,
  init: PropTypes.string,
  name: PropTypes.number,
};

export default styled(RadioType)`
  background: none;
  border: none;
  cursor: pointer;
  height: 5rem;
  margin-bottom: 1rem;
  width: 100%;

  ${Wrapper}{
    border: 0.1rem solid ${theme('--color-dark-night-10')};
    border-radius: 0.6rem;
    display: grid;
    grid-gap: 0.4rem 0.8rem;
    grid-template: auto auto / auto 1fr;
    padding: 0.8rem;
    width: 100%;

    ${Radio}{
      border: 0.1rem solid ${theme('--color-dark-night-10')};
      border-radius: 50%;
      grid-column: 1;
      grid-row: span 2;
      height: 1.6rem;
      width: 1.6rem;
    }

    ${Title}{
      ${theme('--font-medium')}
      ${theme('--font-weight-book')}
      text-align: left;
    }

    ${Description}{
      ${theme('--font-medium')}
      ${theme('--font-weight-book')}
      color: ${theme('--color-dark-night-60')};
      text-align: left;
    }

    &[data-selected="true"]{
      border-color: ${theme('--color-primary')};

      ${Radio}{
        background: ${theme('--color-primary')};
        border: none;
        position: relative;

        &::after{
          background: ${theme('--color-light')};
          border-radius: 50%;
          content: '';
          height: 0.6rem;
          left: 0.5rem;
          position: absolute;
          top: 0.5rem;
          width: 0.6rem;
        }
      }
    }
  }
`;
