import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from '@react-intl';
import styled, { css, theme } from '@styled-components';

import Control from './Control';

const selectedMixin = ({ selected }) =>
  selected
    ? css`
        background: linear-gradient(
          180deg,
          rgba(0, 132, 255, 0.2) 0%,
          rgba(0, 132, 255, 0.1) 100%
        );
        color: ${theme('--color-primary')};
      `
    : css`
        ${theme('--font-opacity-60')}
      `;

const Page = styled(Control)`
  padding: 0 0.4rem;
  ${selectedMixin}
`;

const Pages = ({ current, from, onChange, to }) => {
  const intl = useIntl();

  const visible = to - from;

  return [...Array(visible).keys()].map(i => (
    <li key={from + i}>
      <Page
        aria-label={intl.formatMessage({ id: 'PAGE_%ID%' }, { id: from + i })}
        selected={current === from + i}
        {...{
          ...(current === from + i
            ? {
                'aria-current': intl.formatMessage({ id: 'PAGE' }),
              }
            : {
                onClick: () => onChange(from + i),
              }),
        }}
      >
        {from + i}
      </Page>
    </li>
  ));
};

Pages.propTypes = {
  current: PropTypes.number.isRequired,
  offset: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  visible: PropTypes.number,
};

export default styled(Pages)``;
