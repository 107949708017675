import React from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';
import { useLocation } from 'react-router-dom';

import RawAvatar from 'Components/Avatar';
import RawLink from 'Components/Link';
import { Row } from 'Components/ResponsiveTable';

import {
  withPlaceholderNoData as withPlaceholder,
  PlaceholderNoData as Placeholder,
} from 'Containers/Placeholder';

const Label = styled(Placeholder)``;
const Interaction = styled(Label)``;
const Avatar = styled(withPlaceholder(RawAvatar))``;
const Link = styled(withPlaceholder(RawLink))``;

const Template = ({
  answer,
  avatar,
  className,
  customerId,
  firstName,
  lastName,
}) => {
  const { pathname } = useLocation();

  return (
    <Row className={className}>
      <Avatar fill image={avatar} name={`${firstName} ${lastName}`} />
      <Link
        placeholder="customer name"
        to={`${pathname}/customers/${customerId}`}
      >{`${firstName} ${lastName}`}</Link>
      <Interaction>{answer}</Interaction>
    </Row>
  );
};

Template.propTypes = {
  answer: PropTypes.string,
  avatar: PropTypes.string,
  className: PropTypes.string,
  customerId: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
};

export default styled(Template)`
  align-items: flex-start;
  color: inherit;
  display: grid;
  grid-column-gap: 1.2rem;
  grid-row-gap: 0rem;
  grid-template: 2rem 2rem 1fr / 4rem 1fr;
  height: 10rem;
  padding: 1.2rem 2.4rem;

  ${Label} ,${Link} {
    ${theme('--font-medium')}
    ${theme('--font-opacity-40')}
    ${theme('--font-weight-book')}
  }

  ${Avatar} {
    grid-column: 1;
    grid-row: 1 / span 2;
    height: 100%;
  }

  ${Link} {
    ${theme('--font-weight-medium')}
    color: ${theme('--color-primary')};
    grid-column: 2;
    grid-row: 1;
  }

  ${Interaction} {
    ${theme('--font-opacity-60')}
    ${theme('--font-weight-book')}
    grid-column: 2;
    grid-row: 2 / span 2;
  }
`;
