import React from 'react';
import L from 'leaflet';
import PropTypes from 'prop-types';
import {
  GeoJSON,
  Map as LeafletMap,
  Marker as LeafletMarker,
  Popup as LeafletPopup,
  TileLayer,
} from 'react-leaflet';
import styled, { theme } from '@styled-components';

import { land } from '@countries';

const Icon = L.icon({
  iconAnchor: [9, 24],
  iconSize: [18, 24],
  iconUrl: '/images/marker.svg',
  popupAnchor: [9, -16],
});

const MyPopup = ({ className, children }) => (
  <LeafletPopup className={className} closeButton={false}>
    {children}
  </LeafletPopup>
);

MyPopup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
};

export const Popup = styled(MyPopup)`
  background-color: ${theme('--color-dark-night-40')};
  border-radius: 0.6rem;
  margin-bottom: 1rem;

  .leaflet-popup-content-wrapper {
    background: none;
    box-shadow: none;
    color: ${theme('--color-light')};
    padding: 0.8rem;
    text-align: center;
  }

  .leaflet-popup-content {
    margin: 0;
  }

  .leaflet-popup-tip {
    display: none;
  }
`;

function close() {
  this.closePopup();
}

const MAX_SVG_WIDTH = 1094;
const POPUP_MARGIN = 9.2;
const POPUP_MARGIN_VARIANCE = 5;

function open(e) {
  const {
    layer: {
      _map: {
        options: { center },
      },
      _renderer: { _svgSize },
    },
    latlng,
  } = e;

  const distance = Math.abs(center[1] - latlng.lng);
  const svgWidth = _svgSize.x;
  const maxDistance =
    svgWidth /
    (POPUP_MARGIN +
      (POPUP_MARGIN_VARIANCE * (MAX_SVG_WIDTH - svgWidth)) / MAX_SVG_WIDTH);

  const lng = Math.min(maxDistance, distance) * Math.sign(latlng.lng);

  this.openPopup({ ...e.latlng, lng });
}

export const Country = ({
  children,
  code,
  color = '#ffffff',
  opacity = 0.2,
}) => (
  <GeoJSON
    data={land(code)}
    onBlur={close}
    onFocus={open}
    onMousemove={open}
    onMouseout={close}
    style={{ color, fillOpacity: opacity, stroke: false }}
  >
    <Popup>{children}</Popup>
  </GeoJSON>
);

Country.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  code: PropTypes.string,
  color: PropTypes.string,
  opacity: PropTypes.number,
};

export const Marker = ({ children, lat, lng }) => (
  <LeafletMarker icon={Icon} position={[lat, lng]}>
    {children && <Popup>{children}</Popup>}
  </LeafletMarker>
);

Marker.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  lat: PropTypes.number,
  lng: PropTypes.number,
};

export const Map = ({
  className,
  children,
  lat = 40.4616729,
  lng = -3.6895641,
  zoom = 3,
  ...props
}) => {
  return (
    <LeafletMap
      attributionControl={false}
      center={[lat, lng]}
      className={className}
      dragging={false}
      zoom={zoom}
      {...props}
    >
      <TileLayer
        attribution="Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ"
        maxZoom={16}
        url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
      />
      {children}
    </LeafletMap>
  );
};

Map.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  lat: PropTypes.number,
  lng: PropTypes.number,
  zoom: PropTypes.number,
};

export default styled(Map)`
  height: 100%;
  margin: 0 auto;
  width: 100%;
`;
