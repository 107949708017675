import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import TableDataProvider from 'Containers/TableDataProvider';

import Toolbar from 'Containers/CardToolbar';

import Card from 'Components/Card';
import { Input } from 'Components/Form';

import service from 'Services/products';

import Table from './components/Table';
import styled from 'styled-components';

const Products = ({ className, account, ...props }) => {
  const [search, setSearch] = useState();
  const [{ order, sortBy }, setSortable] = useState({});

  const handleSearch = useCallback(searchBy => {
    setSearch(searchBy);
  }, []);

  const handleOrderBy = useCallback((sort, o) => {
    setSortable({ order: o, sortBy: sort });
  }, []);

  let filterAmmount = 0;
  if (search) filterAmmount += 1;

  return (
    <Card className={className}>
      <Toolbar filterAmmount={filterAmmount} title="LIST_OF_PRODUCTS">
        <Input
          capitalize
          label="SEARCH"
          name="search"
          onChange={handleSearch}
          type="search"
        />
      </Toolbar>
      <TableDataProvider
        {...props}
        filterAmmount={filterAmmount}
        limit={9}
        name="products"
        onOrderBy={handleOrderBy}
        onSearch={handleSearch}
        order={order}
        params={{ account, order, search, sortBy }}
        query={service.get}
        sortBy={sortBy}
        template={Table}
      />
    </Card>
  );
};

Products.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,

  onCursorChange: PropTypes.func,
  onSearch: PropTypes.func,
  type: PropTypes.string,
};

export default styled(Products)`
  grid-template-rows: auto minmax(0, 1fr);
  padding: 0;

  ${Input} {
    width: 24rem;
  }
`;
