import React, { useState, useCallback, useRef } from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from '@prop-types';
import { FormattedMessage } from '@react-intl';

import FilterButton from './FilterButton';
import Button from 'Components/Button';
import Link from 'Components/Link';

const Fixed = styled('div')``;
const Title = styled('h4')``;
export const SoftLink = styled(Button)``;

const Content = styled('div')``;

const CardToolbar = ({
  children,
  className,
  contextMenu: ContextMenu,
  title,
  filterAmmount,
  linkId,
  linkOnClick,
  linkTo,
}) => {
  const filterButton = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = useCallback(() => {
    setIsOpen(prevIsOpen => {
      if (prevIsOpen) setTimeout(() => filterButton.current.blur(), 0);
      return !prevIsOpen;
    });
  }, []);

  return (
    <div className={className} data-open={isOpen}>
      <Fixed>
        <FormattedMessage capitalize component={Title} id={title}>
          {title}
        </FormattedMessage>
        {linkId && (
          <SoftLink
            {...(linkOnClick && { onClick: linkOnClick })}
            {...(linkTo && { as: Link, to: linkTo })}
          >
            <FormattedMessage capitalize id={linkId} />
          </SoftLink>
        )}
        <FilterButton
          ref={filterButton}
          active={isOpen}
          filterAmmount={filterAmmount}
          onClick={toggleIsOpen}
        />
        {ContextMenu && <ContextMenu />}
      </Fixed>
      <Content>{children}</Content>
    </div>
  );
};

CardToolbar.propTypes = {
  children: PropTypes.children,
  className: PropTypes.string,
  contextMenu: PropTypes.component,
  filterAmmount: PropTypes.number,
  linkId: PropTypes.string,
  linkOnClick: PropTypes.func,
  linkTo: PropTypes.string,
  title: PropTypes.string,
};

export default styled(CardToolbar)`
  height: 6.4rem;
  transition: all 500ms ease-out;

  ${Fixed} {
    align-items: center;
    display: flex;
    height: 6.4rem;
    justify-content: space-between;
    padding: 2rem 2.4rem;

    ${Title} {
      ${theme('--font-medium')}
      ${theme('--font-weight-book')}
      ${theme('--opacity-80')}
      flex: 1;
      margin: 0;
    }

    ${SoftLink} {
      ${theme('--font-small')}
      ${theme('--font-weight-demi')}
      color: ${theme('--color-primary')};
      margin-right: 3.2rem;
    }
  }

  ${Content} {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    opacity: 0;
    padding: 0 2.4rem;
    transition: all 250ms linear;
  }

  &[data-open='true'] {
    height: auto;

    ${Content} {
      opacity: 1;
    }
  }

  @media (${theme('--column-8')}) {
    ${SoftLink} {
      margin-right: 1.6rem;
    }

    ${Content} {
      flex-direction: row;
    }

    &[data-open='true'] {
      height: 12.8rem;
    }
  }
`;
