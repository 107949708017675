import React, { useContext } from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';

import { Context } from 'Containers/DataProvider';
import List from 'Components/List';
import withScroll from 'Components/with/scroll';

const ScrollableTable = withScroll(List);

const Body = ({
  className,
  emptyTemplate: EmptyTemplate,
  scrollable,
  ...props
}) => {
  const { empty } = useContext(Context);
  const Template = scrollable ? ScrollableTable : List;

  return empty && EmptyTemplate ? (
    <EmptyTemplate />
  ) : (
    <Template className={className} {...props} />
  );
};

Body.propTypes = {
  className: PropTypes.string,
  emptyTemplate: PropTypes.component,
  scrollable: PropTypes.bool,
};

export default styled(Body)`
  overflow: hidden;

  > li:last-child > *,
  > *:last-child {
    border-bottom: none;

    > *:after {
      border-bottom: none;
    }
  }
`;
