import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@react-intl';
import styled, { theme } from '@styled-components';

import Link from 'Components/Link';
import Logotype from 'Components/Logotype';

const Img = styled('img')``;
const TextContent = styled('article')``;
const Item = styled('li')``;
const List = styled('ul')``;
const Picture = styled('picture')``;
const Subtitle = styled('h4')``;
const Title = styled('h2')``;
const Main = styled('section')``;

const CustomerError = ({ className }) => (
  <section className={className}>
    <Logotype withText />
    <Main>
      <TextContent>
        <FormattedMessage capitalize component={Title} id="ERROR.404">
          Oops!
        </FormattedMessage>
        <FormattedMessage
          capitalize
          component={Subtitle}
          id="ERROR.404.SUBTITLE"
        >
          We can&apos;t seem to find the page you are looking for.
        </FormattedMessage>
        <List>
          <Item>
            <FormattedMessage capitalize component={Link} id="HOME" to="/">
              home
            </FormattedMessage>
          </Item>
          <Item>
            <FormattedMessage
              capitalize
              component={Link}
              id="ANALYTICS"
              to="/analytics"
            >
              analytics
            </FormattedMessage>
          </Item>
          <Item>
            <FormattedMessage
              capitalize
              component={Link}
              id="CAMPAIGNS"
              to="/campaigns"
            >
              campaigns
            </FormattedMessage>
          </Item>
          <Item>
            <FormattedMessage
              capitalize
              component={Link}
              id="CUSTOMERS"
              to="/customers"
            >
              customers
            </FormattedMessage>
          </Item>
          <Item>
            <FormattedMessage
              capitalize
              component={Link}
              id="PRODUCTS"
              to="/products"
            >
              products
            </FormattedMessage>
          </Item>
          <Item>
            <FormattedMessage
              capitalize
              component={Link}
              id="BUILDER"
              to="/builder"
            >
              integrations
            </FormattedMessage>
          </Item>
          <Item>
            <FormattedMessage
              capitalize
              component={Link}
              id="INTEGRATIONS"
              to="/integrations"
            >
              integrations
            </FormattedMessage>
          </Item>
          <Item>
            <FormattedMessage
              capitalize
              component={Link}
              id="SETTINGS"
              to="/settings"
            >
              settings
            </FormattedMessage>
          </Item>
        </List>
      </TextContent>
      <Picture>
        <Img src="/images/robot.svg" />
      </Picture>
    </Main>
  </section>
);

CustomerError.propTypes = {
  className: PropTypes.string,
};

export default styled(CustomerError)`
  background: white url('/images/error404backdrop.svg') no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  width: 100%;

  ${Logotype} {
    align-self: start;
    margin: 4rem 0 0 10.4rem;
    width: 17rem;
    z-index: 2;
  }

  ${Main} {
    display: flex;
    height: calc(100vh - 7rem);
    margin: 0 auto;
    padding: 3.6rem 2rem;

    ${TextContent} {
      max-width: 28rem;
      text-align: justify;

      ${Title} {
        ${theme('--font-xxlarge')}
        margin: 1.6rem 0;
      }

      ${Subtitle} {
        ${theme('--font-large')}
        ${theme('--font-opacity-40')}
        margin: 3.2rem 0;
      }

      ${List} {
        ${theme('--font-small')}
        margin: 3.2rem 0;

        ${Item} {
          list-style: none;
          margin-bottom: 1.6rem;
        }

        ${Link} {
          color: ${theme('--color-trust')};
          ${theme('--font-weight-demi')}
        }
      }
    }

    ${Picture} {
      display: none;
    }

    @media (${theme('--column-8')}) {
      ${Main} {
        padding: 15.5rem 0 0 8rem;

        ${TextContent} {
          max-width: 38rem;
          min-width: 28rem;
        }
      }
    }

    @media (${theme('--screen-large')}) {
      ${Main} {
        padding: 15.5rem 0 0 21.6rem;
      }

      ${Picture} {
        display: block;
        margin-left: 28rem;

        ${Img} {
          width: 48rem;
        }
      }
    }
  }
`;
