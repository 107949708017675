import React from 'react';
import styled, { theme, withStyles as css } from '@styled-components';
import { FormattedMessage } from '@react-intl';
import PropTypes from '@prop-types';
import { compose } from 'underscore';

import { withPlaceholder } from 'Containers/Placeholder';

import Avatar from 'Components/Avatar';
import Button from 'Components/Button';
import Card from 'Components/Card';
import Link from 'Components/Link';
import Icon from 'Components/Icon';
import Role from 'Components/Chip';

const Info = styled('div')``;
const Name = styled('h2')``;
const Account = styled('h3')``;
const Email = styled('h3')``;

const AccountCard = ({
  className,
  link,
  me: {
    fullName,
    images: { large },
    role,
    email,
  },
  company,
}) => {
  return (
    <Card className={className} title="ACCOUNT_INFORMATION">
      <Button as={Link} to={link}>
        <Icon outline name="edit">
          ACTIONS.EDIT_PROFILE
        </Icon>
      </Button>
      <Avatar image={large} name={fullName} />
      <Info>
        <Name>{fullName}</Name>
        <Email>{email}</Email>
      </Info>
      <Account>{company}</Account>
      <FormattedMessage component={Role} id={role}>
        {role}
      </FormattedMessage>
    </Card>
  );
};

AccountCard.propTypes = {
  className: PropTypes.string,
  company: PropTypes.string,
  link: PropTypes.string,
  me: PropTypes.shape({
    email: PropTypes.string,
    fullName: PropTypes.string,
    images: PropTypes.shape({
      large: PropTypes.string,
    }),
    role: PropTypes.string,
  }),
};

export default compose(
  css`
    align-items: center;
    display: flex;
    flex-direction: column;

    ${Info} {
      border-bottom: 0.1rem solid ${theme('--color-dark-night-10')};
      width: 100%;

      ${Name} {
        ${theme('--font-medium')}
        ${theme('--font-weight-medium')}
        text-align: center;
      }

      ${Email} {
        ${theme('--font-medium')}
        ${theme('--font-opacity-40')}
        ${theme('--font-weight-book')}
        padding: 0.8rem 0 2.4rem;
        text-align: center;
      }
    }

    ${Link} {
      border-radius: 0.6rem;
      box-shadow: 0 0.8rem 1.6rem rgba(0, 15, 25, 0.05),
        0 0.1rem 0 rgba(0, 15, 25, 0.05);
      height: 3.2rem;
      position: absolute;
      right: 2.2rem;
      top: 2.2rem;
      width: 3.2rem;
    }

    ${Account} {
      ${theme('--font-medium')}
      ${theme('--font-weight-book')}
      ${theme('--font-opacity-60')}
      padding: 2.8rem 0 0.8rem;
    }

    ${Avatar} {
      border: 0.8rem solid #fff;
      box-shadow: 0 0.2rem 0.8rem rgba(0, 15, 25, 0.05);
      margin: 0 0 3.2rem;
      min-height: 13.6rem;
      width: 13.6rem;
    }

    ${Role} {
      margin-bottom: 2.4rem;
    }

    ${Button} {
      box-shadow: none;
    }
  `,
  withPlaceholder,
)(AccountCard);
