import { createAction, createReducer } from 'redux-act';
import { cancel } from 'redux-saga/effects';

import { createToken } from 'Providers/request';

export function createEffect(success, error = cancel) {
  return function* effect(action = {}) {
    // We need this destructuring outside parameters because effects can be
    // called outside an action
    const { payload } = action;

    try {
      yield* success(payload);
    } catch (e) {
      yield* error(e);
    }
  };
}

export function createService(fn) {
  let token = { cancel() {} };

  const service = (...args) => {
    token = createToken();

    const promise = fn(...args, token);
    promise.cancel = () => token.cancel();

    return promise;
  };

  service.cancel = () => token.cancel();

  return service;
}

export { createAction, createReducer } from 'redux-act';

export default { createAction, createEffect, createReducer };
