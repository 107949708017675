import PropTypes from 'prop-types';
import { deprecate } from 'react-is-deprecated';

export default {
  ...PropTypes,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  component: PropTypes.elementType,
  deprecate,
  intl: PropTypes.object.isRequired,
  ref: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  request: PropTypes.func,
  theme: PropTypes.object,
  webview: PropTypes.shape({
    close: PropTypes.func,
  }),
};
