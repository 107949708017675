import React, { Component } from 'react';
import PropTypes from '@prop-types';
import { FormattedMessage } from '@react-intl';
import styled, { theme } from '@styled-components';

import { File } from 'Components/Form';
import Tip from 'Components/Tip';

import Avatar from 'Components/Avatar';

const Actions = styled('div')``;
const Label = styled('label')``;
const Paragraph = styled('p')``;
const FileTitle = styled('p')``;
const FormatAdvise = styled('p')``;

class AvatarSelector extends Component {
  reader = new FileReader();

  static propTypes = {
    className: PropTypes.string,
    image: PropTypes.string,
    label: PropTypes.string,
    tooltip: PropTypes.component,
  };

  state = { image: this.props.image };

  componentDidMount() {
    this.reader.addEventListener('load', this.handleLoad, false);
  }

  componentWillUnmount() {
    this.reader.removeEventListener('load', this.handleLoad);
  }

  handleLoad = () => {
    this.setState({ image: this.reader.result });
  };

  handleChange = file => {
    this.reader.readAsDataURL(file);
    this.setState({ filename: file.name });
  };

  render() {
    const { className, label, tooltip: Tooltip } = this.props;
    const { image, filename } = this.state;

    return (
      <div className={className}>
        <Avatar image={image} name="avatar selector preview" />
        <Actions>
          <FormattedMessage capitalize component={Label} id={label}>
            Profile picture
          </FormattedMessage>
          {Tooltip && (
            <Tip>
              <Tooltip />
            </Tip>
          )}
          {filename ? (
            <>
              <FileTitle>{filename}</FileTitle>
              <FormattedMessage
                capitalize
                component={FormatAdvise}
                id="INSTRUCTIONS.FILE_FORMAT"
              >
                File should be PNG or JPG
              </FormattedMessage>
            </>
          ) : (
            <FormattedMessage
              capitalize
              component={Paragraph}
              id="INSTRUCTIONS.UPLOAD_IMAGE"
            >
              Upload a JPG or PNG file
            </FormattedMessage>
          )}
          <File name="avatar" onChange={this.handleChange} />
        </Actions>
      </div>
    );
  }
}

export default styled(AvatarSelector)`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 2.4rem 0;

  ${Avatar} {
    border: 0.8rem solid white;
    border-radius: 50%;
    box-shadow: 0 0.2rem 0.8rem rgba(0, 15, 25, 0.05);
    width: 13.6rem;
  }

  ${Actions} {
    ${theme('--font-medium')}
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 0 0 2.4rem;
    position: relative;

    ${Tip} {
      position: absolute;
      right: 0;
      top: 0;
    }

    ${Label}, ${Paragraph} {
      ${theme('--font-weight-medium')}
      margin-bottom: 0.8rem;
    }

    ${FileTitle} {
      ${theme('--font-medium')}
      ${theme('--font-weight-book')}
      ${theme('--font-opacity-80')}
      margin-bottom: 1.8rem;
    }

    ${FormatAdvise} {
      ${theme('--font-small')}
      ${theme('--font-weight-book')}
      ${theme('--font-opacity-60')}
      margin-bottom: 0.8rem;
    }

    ${Paragraph} {
      ${theme('--font-medium')}
      ${theme('--font-weight-book')}
      ${theme('--font-opacity-60')}
      margin-bottom: 2.4rem;
    }
  }
`;
