import React from 'react';
import { FixedSizeList } from 'react-window';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Contact from './Contact';
import ContactFixedSizeListItem from './ContactFixedSizeListItem';

const Loader = styled('div')``;

const Contacts = ({
  className,
  customers,
  isContactSelectedFn,
  isLoading,
  onChange,
}) => {
  const itemData = { customers, isContactSelectedFn, onChange };

  return isLoading ? (
    <div className={className} style={{ display: 'flex' }}>
      <Loader />
      <div className="uploadingText">Loading...</div>
    </div>
  ) : (
    <div>
      {customers.length < 30 ? (
        <div>
          {customers.map(contact => (
            <Contact
              key={contact.id}
              avatar={contact.imageURL_thumb}
              customerId={contact.id}
              firstName={contact.firstName}
              isChecked={isContactSelectedFn(contact.id)}
              lastName={contact.lastName}
              onChange={onChange}
              phone={contact.phone}
            />
          ))}
        </div>
      ) : (
        <FixedSizeList
          height={240}
          itemCount={customers.length}
          itemData={itemData}
          itemSize={48}
        >
          {ContactFixedSizeListItem}
        </FixedSizeList>
      )}
    </div>
  );
};

Contacts.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.arrayOf({
    firstName: PropTypes.string,
    id: PropTypes.number.isRequired,
    imageURL_thumb: PropTypes.string,
    lastName: PropTypes.string,
    phone: PropTypes.string,
  }),
  isContactSelectedFn: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default styled(Contacts)`
  ${Loader} {
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid #005dff;
    display: inline-block;
    height: 20px;
    margin: 1rem 0 1rem 3.3rem;
    width: 20px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .uploadingText {
    color: #666f75;
    margin: auto 0 auto 5px;
  }
`;
