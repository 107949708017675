import { FormattedMessage } from '@react-intl';
import PropTypes from '@prop-types';
import React from 'react';
import styled from '@styled-components';

import Button from 'Components/Button';

import Dialog, { Actions, Wrapper } from 'Components/Dialog';

export const Confirm = ({
  campaignName,
  className,
  in: isIn,
  onAccept,
  phoneNumber,
}) => (
  <Dialog
    className={className}
    in={isIn}
    onDismiss={onAccept}
    title="TEST_SMS_CAMPAIGN"
  >
    <FormattedMessage
      capitalize
      primary
      id="CAMPAIGN_NAME_SENT_TO_PHONE_NUMBER"
      values={{
        campaignName,
        phoneNumber,
      }}
    />
    <Actions>
      <FormattedMessage
        capitalize
        primary
        component={Button}
        id="FINISH"
        onClick={onAccept}
      />
    </Actions>
  </Dialog>
);

Confirm.propTypes = {
  campaignName: PropTypes.string.isRequired,
  className: PropTypes.string,
  in: PropTypes.bool,
  onAccept: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string.isRequired,
};

export default styled(Confirm)`
  ${Wrapper} {
    max-width: 40.8rem;
  }

  ${Actions} {
    margin-top: 4rem;

    ${Button} {
      margin: 0;
    }
  }
`;
