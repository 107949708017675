import React, { useState } from 'react';
import { theme, withStyles as css } from '@styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'underscore';

import Card from 'Components/Card';
import { Input } from 'Components/Form';

import TableDataProvider from 'Containers/TableDataProvider';
import Toolbar from 'Containers/CardToolbar';

import { currentAccount } from 'Selectors';

import service from 'Services/surveys';

import Table from './components/Table';

const Surveys = ({ account, className }) => {
  const [search, setSearch] = useState('');

  const filterAmmount = search.length ? 1 : 0;

  return (
    <Card className={className}>
      <Toolbar filterAmmount={filterAmmount} title="SURVEYS">
        <Input
          capitalize
          label="SEARCH"
          name="search"
          onChange={setSearch}
          type="search"
        />
      </Toolbar>
      <TableDataProvider
        account={account}
        filterAmmount={filterAmmount}
        limit={8}
        name="surveys"
        params={{
          account,
          search,
        }}
        query={service.get}
        template={Table}
      />
    </Card>
  );
};

Surveys.propTypes = {
  account: PropTypes.string,
  className: PropTypes.string,
};

const mapStateToProps = state => ({
  account: currentAccount(state),
});

export default compose(
  css`
    border-radius: 0.6rem 0.6rem 0 0;
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
    padding: 0;

    @media (${theme('--column-12')}) {
      ${Input} {
        width: 24rem;
      }
    }
  `,
  connect(mapStateToProps),
)(Surveys);
