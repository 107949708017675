import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@styled-components';

import Card from 'Components/Card';
import ContextMenu from 'Components/ContextMenu';
import CustomRangePicker from 'Components/CustomRangePicker';

import CardNavigation from '../CardNavigation';
import Question from './Question';

import service from 'Services/surveys';

const Link = styled('a')``;
const Shadow = styled('div')``;

const Questions = ({
  account,
  className,
  from,
  handleDateChange,
  questions = [],
  surveyId,
  to,
}) => {
  const [questionIndex, setQuestionIndex] = useState(0);
  const linkRef = useRef();

  const handleDownload = async () => {
    const response = await service.download({
      account,
      from,
      surveyId,
      to,
    });
    const objectUrl = window.URL.createObjectURL(response);

    linkRef.current.download = 'questions.xlsx';
    linkRef.current.href = objectUrl;
    linkRef.current.click();

    window.URL.revokeObjectURL(objectUrl);
  };

  return (
    <Card className={className}>
      <Link ref={linkRef} download href="/#" />
      <CardNavigation
        contextMenu={
          <ContextMenu
            right
            options={{
              'ACTIONS.DOWNLOAD': handleDownload,
            }}
          />
        }
        filters={
          <CustomRangePicker
            showAllTime
            from={from}
            onRangeChange={handleDateChange}
            to={to}
          />
        }
        onTabSelect={setQuestionIndex}
        selectedTab={questionIndex}
        tabLabel={index => `Q${index + 1}`}
        tabsCount={questions.length}
        titleId="QUESTIONS"
      />
      <Question question={questions[questionIndex]} />
      <Shadow />
    </Card>
  );
};

Questions.propTypes = {
  account: PropTypes.string,
  className: PropTypes.string,
  from: PropTypes.object,
  handleDateChange: PropTypes.func,
  questions: PropTypes.array,
  surveyId: PropTypes.number,
  to: PropTypes.object,
};

export default styled(Questions)`
  ${Shadow} {
    background: linear-gradient(rgba(0, 0, 0, 0), #fff);
    bottom: 0;
    height: 14rem;
    left: 0;
    position: absolute;
    right: 0;
  }
`;
