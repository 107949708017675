import React from 'react';
import PropTypes from 'prop-types';
import styled from '@styled-components';

import Table from 'Components/ResponsiveTable';

import Template from './Template';
import DesktopTemplate from './DesktopTemplate';
import EmptyTemplate from './EmptyTemplate';
import EmptyTemplateFilters from './EmptyTemplateFilters';
import Header from './Header';

const CustomersTable = ({
  className,
  data: { collection, steps = [] } = {},
  filterAmmount,
  onCursorChange: handleCursorChange = () => {},
  surveyId,
}) => (
  <Table
    className={className}
    desktopTemplate={DesktopTemplate}
    emptyTemplate={filterAmmount ? EmptyTemplateFilters : EmptyTemplate}
    header={Header}
    items={collection}
    onCursorChange={handleCursorChange}
    options={{ steps, surveyId }}
    rows={5}
    template={Template}
  />
);

CustomersTable.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    collection: PropTypes.arrayOf(PropTypes.object),
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        displayName: PropTypes.string,
        key: PropTypes.string,
      }),
    ),
  }),
  filterAmmount: PropTypes.number,
  onCursorChange: PropTypes.func,
  surveyId: PropTypes.number,
};

export default styled(CustomersTable)``;
