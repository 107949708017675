import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles as css } from '@styled-components';
import { compose } from 'underscore';

import { ContextMenu } from 'Components/ContextMenu';
import { withRouter } from 'Components/Router';
import withPermissions from 'Components/with/permissions';

import DonwloadDialog from './DownloadDialog';

import C from 'Constants';

const {
  ROLE: { ADMIN, SUPER_ADMIN },
} = C;

const Options = ({ className, account, history: { push }, locale, type }) => {
  const [download, setDownload] = useState(false);

  const handleDownloadClose = () => setDownload(false);

  return (
    <>
      <DonwloadDialog
        account={account}
        in={download}
        locale={locale}
        onClose={handleDownloadClose}
        type={type}
      />
      <ContextMenu
        right
        className={className}
        options={{
          NEW_PAIR: () =>
            push({
              pathname: '/builder/faqstudio/add',
              state: { locale },
            }),
          // eslint-disable-next-line
          IMPORT_FAQS: () => push('/builder/faqstudio/import'),
          // eslint-disable-next-line
          DOWNLOAD: () => setDownload(true),
        }}
      />
    </>
  );
};

Options.propTypes = {
  account: PropTypes.string,
  className: PropTypes.string,
  filters: PropTypes.shape({
    groupBy: PropTypes.arrayOf(PropTypes.string),
    orderBy: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  locale: PropTypes.string,
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
  type: PropTypes.string,
};

export default compose(
  css`
    margin-left: 2rem;
  `,
  withRouter,
  withPermissions([SUPER_ADMIN, ADMIN]),
)(Options);
