import React from 'react';
import PropTypes from '@prop-types';
import styled, { theme } from '@styled-components';

export const DropdownPanel = ({ className, children }) => (
  <article className={className} data-testid="dropdown-panel">
    {children}
  </article>
);

DropdownPanel.propTypes = {
  children: PropTypes.children.isRequired,
  className: PropTypes.string,
};

export default styled(DropdownPanel)`
  background: ${theme('--color-light')};
  border-radius: 0.6rem;
  box-shadow: 0 8px 16px rgba(0, 15, 25, 0.05), 0 1px 0 rgba(0, 15, 25, 0.05);
  left: 2rem;
  min-width: 20rem;
  position: absolute;
  top: 6.8rem;
  z-index: 999;
`;
