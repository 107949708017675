import { createService } from '@redux-act';

import request from 'Providers/request';

const getById = createService(
  async ({ contactId, ofaId, ofaSecret, type }, token) => {
    const data = await request(
      '/conversation',
      'get',
      {
        contactId,
        limit: 100,
        ofaId,
        ofaSecret,
        offset: 0,
        type,
      },
      {},
      { token },
    );

    return data;
  },
);

export default {
  getById,
};
