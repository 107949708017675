import React, { useReducer, useEffect } from 'react';
import PropTypes from '@prop-types';

import {
  useDeepCompareCallback,
  useDeepCompareEffect,
  usePrevious,
} from 'Hooks';

const isInCache = ({ cache, cursor, length }) =>
  !cache
    .slice(cursor * length, cursor * length + length)
    .some(({ empty }) => Boolean(empty));

export const STATE = {
  ADD: 1,
  CHANGE_CURSOR: 2,
  CREATE: 3,
  INITIALIZE: 4,
};

const initialState = {
  cache: [],
  cursor: 0,
};

function reducer(state, action) {
  switch (action.type) {
    case STATE.ADD:
      return {
        ...state,
        cache: [
          ...state.cache.slice(0, action.cursor * action.length),
          ...action.data,
          ...state.cache.slice(
            action.cursor * action.length + action.data.length,
            action.total,
          ),
        ],
      };
    case STATE.CHANGE_CURSOR:
      return {
        ...state,
        cursor: action.cursor,
      };
    case STATE.CREATE:
      return {
        ...state,
        cache: [
          ...action.data,
          ...(action.total && action.total - action.data.length > 0
            ? Array(action.total - action.data.length).fill({ empty: true })
            : []),
        ],
      };
    case STATE.INITIALIZE:
      return initialState;
    default:
      return state;
  }
}

const Cache = ({
  data: { collection = [], total = 0, ...data } = {},
  id,
  length,
  loading,
  onMiss: handleMiss = () => {},
  template: Template = () => {},
  ...rest
}) => {
  const [{ cursor, cache }, dispatch] = useReducer(reducer, initialState);
  const prevLoading = usePrevious(loading);

  useEffect(() => {
    dispatch({ type: STATE.INITIALIZE });
  }, [id, dispatch]);

  useDeepCompareEffect(() => {
    if (!loading && prevLoading) {
      if (!cache.length) {
        dispatch({ data: collection, total, type: STATE.CREATE });
      } else {
        dispatch({
          cursor,
          data: collection,
          length,
          total,
          type: STATE.ADD,
        });
      }
    }
  }, [collection, cursor, dispatch, length, loading, prevLoading, total]);

  const handleRefetch = useDeepCompareCallback(
    (c, l = length) => {
      if (!isInCache({ cache, cursor: c, length: l })) {
        dispatch({ cursor: c, type: STATE.CHANGE_CURSOR });
        handleMiss({ length: l, offset: c * l });
      }
    },
    [cache, dispatch, handleMiss, isInCache, length],
  );

  return (
    <Template
      {...rest}
      key={id}
      cursor={cursor}
      data={{ collection: cache, total, ...data }}
      onCursorChange={handleRefetch}
    />
  );
};

Cache.propTypes = {
  data: PropTypes.shape({
    collection: PropTypes.arrayOf(PropTypes.object),
    total: PropTypes.number,
  }),
  id: PropTypes.bool,
  length: PropTypes.number,
  loading: PropTypes.bool,
  onMiss: PropTypes.func,
  template: PropTypes.elementType,
};

export default Cache;
