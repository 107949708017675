import React from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from '@prop-types';

import Dialog from 'Components/Dialog';
import Form, { Input } from 'Components/Form';

import Button from 'Components/Button';

const Visibility = styled('p')``;

const Confirm = ({ className, defaultValue, in: isIn, onClose, onSubmit }) => {
  return (
    <Dialog
      className={className}
      in={isIn}
      onDismiss={onClose}
      title="INSTRUCTIONS.ADD_MICROAPP_NAME"
    >
      <Visibility translate>INSTRUCTIONS.NAME_VISIBILITY</Visibility>
      <Form defaultValue={defaultValue} onSubmit={onSubmit}>
        <Input capitalize label="name" name="name" />
        <Button capitalize primary translate type="submit">
          ACTIONS.DONE
        </Button>
      </Form>
    </Dialog>
  );
};

Confirm.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.object,
  in: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default styled(Confirm)`
  ${Visibility} {
    ${theme('--font-medium')}
    ${theme('--font-weight-book')}
    color: ${theme('--color-dark-night-60')};
    padding: 1.6rem 0;
  }

  ${Input} {
    margin-bottom: 4.8rem;
  }

  ${Button}{
    width: 100%;
  }

  @media (${theme('--screen-small')}){
    ${Button}{
      width: 15.8rem;
    }
  }
`;
