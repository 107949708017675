import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Redirect } from 'Components/Router';

class Logout extends Component {
  static propTypes = {
    isLoggedIn: PropTypes.bool,
    logout: PropTypes.func,
  };

  static defaultProps = {
    logout() {},
  };

  componentDidMount() {
    const { logout } = this.props;

    logout();
  }

  render() {
    const { isLoggedIn } = this.props;

    if (!isLoggedIn) {
      return <Redirect push to="/login" />;
    }

    return null;
  }
}

export default Logout;
