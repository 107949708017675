import React, { useCallback } from 'react';
import styled, { theme, prop } from '@styled-components';
import { FormattedMessage } from '@react-intl';
import PropTypes from '@prop-types';

import { useField } from 'spooner';

const Title = styled('h3')``;
const Hint = styled('span')``;
const Description = styled('p')``;
const Image = styled('div')``;
const Radio = styled('span')``;
const Wrapper = styled('article')``;

const Card = ({
  className,
  hint,
  id,
  name,
  onSelect,
  selected,
  withDescription,
}) => {
  const { value, onChange } = useField({ name });

  const handleChange = useCallback(() => {
    if (onSelect) onSelect();

    onChange(id);
  }, [id, onChange, onSelect]);

  const isSelected = selected ? selected === id : value === id;

  return (
    <button
      className={className}
      data-selected={isSelected}
      onClick={handleChange}
      type="button"
    >
      <Wrapper>
        <Radio content={id} id={id} name="template" />
        <Title>
          <FormattedMessage
            capitalize
            id={`INSTRUCTIONS.RADIO_CARD.${id.toUpperCase()}`}
          />
          <Hint>{hint}</Hint>
        </Title>
        {withDescription && (
          <FormattedMessage
            capitalize
            component={Description}
            id={`INSTRUCTIONS.RADIO_CARD.${id.toUpperCase()}_DESCRIPTION`}
          />
        )}
        <Image />
      </Wrapper>
    </button>
  );
};

Card.propTypes = {
  className: PropTypes.string,
  hint: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  onSelect: PropTypes.func,
  selected: PropTypes.string,
  withDescription: PropTypes.bool,
};

export default styled(Card)`
  background: none;
  border: none;
  cursor: pointer;
  outline: transparent;
  text-align: left;

  ${Wrapper}{
    background: ${theme('--color-light')};
    border: 0.1rem solid ${theme('--color-dark-night-10')};
    border-radius: 0.6rem;
    display: grid;
    grid-gap: 0.8rem;
    grid-template: auto auto 1fr / 1.6rem calc((100% - 1.6rem - 0.8rem));
    height: 100%;
    padding: 1.6rem;

    ${Radio} {
      border: 0.1rem solid ${theme('--color-dark-night-10')};
      border-radius: 50%;
      grid-column: 1;
      grid-row: 1;
      height: 1.6rem;
      width: 1.6rem;
    }

    ${Title} {
      ${theme('--font-medium')}
      ${theme('--font-weight-medium')}
      color: ${theme('--color-dark-night-80')};
      grid-column: 2;
      grid-row: 1;

      ${Hint}{
        color: ${theme('--color-dark-night-40')};
        margin-left: 0.4rem;
      }
    }

    ${Description} {
      ${theme('--font-small')}
      ${theme('--font-weight-book')}
      color: ${theme('--color-dark-night-60')};
      grid-column: 2;
      grid-row: 2;
    }

    ${Image} {
      background: white url('/images/template_${prop('id')}.svg') no-repeat;
      background-position: center;
      background-size: contain;
      grid-column: 1 / span 2;
      grid-row: 3;
      height: 16rem;
      margin-top: 1.6rem;
    }
  }

  &[data-selected="true"]{
    ${Wrapper}{
      border-color: ${theme('--color-primary')};

      ${Radio}{
        background: ${theme('--color-primary')};
        border: none;
        position: relative;

        &::after{
          background: ${theme('--color-light')};
          border-radius: 50%;
          content: '';
          height: 0.6rem;
          left: 0.5rem;
          position: absolute;
          top: 0.5rem;
          width: 0.6rem;
        }
      }
    }
  }
`;
