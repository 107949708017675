import React from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@react-intl';

import Confirm from 'Components/Confirm';

const Button = styled('button')``;
const Enrolled = styled('div')``;
const GroupInfo = styled('div')``;
const Paragraph = styled('p')``;
const StyledMessage = styled(FormattedMessage)``;
const Title = styled('div')``;

const ConfirmUsers = ({
  className,
  customers,
  in: isIn,
  onAccept,
  onCancel,
  handleEnrolled,
}) => {
  const enrolledList = customers.map(group => {
    const groupTotal = {
      customersTotal: group.customers.length,
      customersWithPhone: 0,
      name: group.name,
    };
    groupTotal.customersWithPhone = group.customers.reduce(
      (acc, customerId) => {
        const customerCurrent = group?.ofaContacts.find(
          customer => customerId === customer.id,
        );

        return acc + (customerCurrent?.phone ? 1 : 0);
      },
      0,
    );
    return groupTotal;
  });

  if (
    enrolledList.some(group => group.customersWithPhone < group.customersTotal)
  ) {
    handleEnrolled(false);
  } else {
    handleEnrolled(true);
  }

  return (
    <Confirm
      className={className}
      in={isIn}
      onAccept={onAccept}
      onCancel={onCancel}
      title="CUSTOMER_LIST"
    >
      <Button onClick={onCancel}>&#10005;</Button>
      <StyledMessage
        capitalize
        component={Paragraph}
        id="INSTRUCTIONS.CAMPAIGN.CUSTOMERS.CONFIRMATION"
      />

      {enrolledList.map(
        group =>
          group.customersWithPhone - group.customersTotal < 0 && (
            <GroupInfo key={group.id}>
              <Title>{group.name}</Title>
              <Enrolled>
                <FormattedMessage id="ENROLLED" /> ({group.customersWithPhone}/
                {group.customersTotal})
              </Enrolled>
            </GroupInfo>
          ),
      )}
    </Confirm>
  );
};

ConfirmUsers.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array,
  handleEnrolled: PropTypes.func,
  in: PropTypes.bool,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
};

export default styled(ConfirmUsers)`
  ${Button} {
    background: transparent;
    border: none;
    color: ${theme('--color-dark-night-40')};
    cursor: pointer;
    font-size: 1.6rem;
    height: 1.2rem;
    position: absolute;
    right: 5%;
    top: 8.5%;
    width: 1.2rem;

    &:active {
      color: ${theme('--color-dark-night-60')};
    }
  }

  ${StyledMessage} {
    margin: 1.5rem;
  }

  ${Enrolled} {
    color: #F03D25;
  }

  ${GroupInfo} {
    box-shadow: inset 0px -1px 0px rgba(0, 15, 25, 0.03);
    display: flex;
    justify-content: space-between;
    padding: 1.5rem;
  }

  ${Paragraph} {
    ${theme('--font-medium')}
    ${theme('--font-weight-book')}
    color: ${theme('--color-dark-night-60')};
    position: relative;
  }
`;
