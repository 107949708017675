import React from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';

import Cart from './components/Cart';
import Header from './components/Header';
import Invoice from './components/Invoice';
import Receipt from './components/Receipt';
import Status from './components/Status';

const Grid = styled('div')``;

const Order = ({
  className,
  data = {},
  handleOrderUpdate,
  ofaId,
  ofaSecret,
  platformId,
  type,
}) => {
  const {
    bannerImage,
    contact,
    invoiceImageUrl,
    invoiceNumber,
    items,
    orderStatus,
    reseller,
    summary,
    supportUrl,
    whatsApp,
  } = data;

  return (
    <div className={className}>
      <Header
        bannerImage={bannerImage}
        ofaId={ofaId}
        ofaSecret={ofaSecret}
        platformId={platformId}
        reseller={reseller}
        supportUrl={supportUrl}
        type={type}
        whatsApp={whatsApp}
      />
      <Grid>
        <Cart items={items} summary={summary} />
        <Invoice invoiceNumber={invoiceNumber} {...contact} />
        <Receipt
          handleOrderUpdate={handleOrderUpdate}
          imageUrl={invoiceImageUrl}
        />
        <Status
          handleOrderUpdate={handleOrderUpdate}
          orderStatus={orderStatus}
        />
      </Grid>
    </div>
  );
};

Order.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    bannerImage: PropTypes.string,
    contact: PropTypes.string,
    invoiceImageUrl: PropTypes.string,
    invoiceNumber: PropTypes.string,
    items: PropTypes.array,
    orderStatus: PropTypes.string,
    reseller: PropTypes.string,
    summary: PropTypes.object,
    supportUrl: PropTypes.string,
    whatsApp: PropTypes.string,
  }),
  handleOrderUpdate: PropTypes.func,
  ofaId: PropTypes.string,
  ofaSecret: PropTypes.string,
  platformId: PropTypes.string,
  type: PropTypes.string,
};

export default styled(Order)`
  margin: 0 auto 3.2rem;
  max-width: 160rem;
  padding: 3.2rem 6.4rem;

  ${Grid} {
    display: grid;
    grid-column-gap: 3.2rem;
    grid-row-gap: 3.2rem;
    grid-template-columns: repeat(5, 1fr);
  }

  ${Invoice}, ${Status} {
    grid-column: 4 / span 2;
  }

  ${Cart}, ${Receipt} {
    grid-column: 1 / span 3;
  }
`;
