import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles as css } from '@styled-components';

import { currentAccount } from 'Selectors';

import TableDataProvider from 'Containers/TableDataProvider';

import service from 'Services/products';

import Template from './MostViewed';

const mapStateToProps = state => ({
  account: currentAccount(state),
});

const MostViewed = ({ className, account }) => (
  <TableDataProvider
    className={className}
    limit={4}
    name="mostViewed"
    params={{ account }}
    query={service.getMostViewed}
    template={Template}
  />
);

MostViewed.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
};

export default compose(css``, connect(mapStateToProps))(MostViewed);
