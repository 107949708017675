import React from 'react';
import { FormattedMessage } from '@react-intl';
import styled, { theme } from '@styled-components';
import Icon from 'Components/Icon';

import PropTypes from 'prop-types';

import Card from 'Components/Card';

const Title = styled('h2')``;
const Content = styled('p')``;
const Wrapper = styled('div')``;

const EmptyTemplate = ({ className }) => (
  <Card className={className} title="REVENUES_PER_CAMPAIGN">
    <Wrapper>
      <Icon name="campaigns-empty">CAMPAIGNS_EMPTY</Icon>
      <FormattedMessage
        capitalize
        component={Title}
        id="EMPTY_CAMPAINGS.CREATE_CAMPAINGS"
      >
        Oops! we did not find any campaign yet
      </FormattedMessage>
    </Wrapper>
  </Card>
);

EmptyTemplate.propTypes = {
  className: PropTypes.string,
};

export default styled(EmptyTemplate)`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  min-height: 43rem;
  overflow-y: scroll;

  ${Wrapper} {
    display: grid;
    grid-gap: 0.4rem;
    /* grid-template-rows: minmax(0, calc(100% - 10.6rem)) 2.4rem 3.2rem 5rem; // Recover this line to be able to create campaigns */
    grid-template-rows: minmax(0, calc(100% - 2.4rem)) 2.4rem;
    height: 100%;
    max-height: 30rem;
    text-align: center;

    ${Icon} {
      height: 100%;
      margin: 0 auto;
    }

    ${Title} {
      ${theme('--font-large')}
      ${theme('--font-weight-book')}
      ${theme('--font-opacity-100')}
    }

    ${Content} {
      ${theme('--font-medium')}
      ${theme('--font-weight-book')}
      ${theme('--font-opacity-40')}
    }
  }
`;
