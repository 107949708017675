import React from 'react';
import styled from '@styled-components';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import { FormattedMessage } from '@react-intl';
import Card from 'Components/Card';
// import Preview from 'Components/CampaignForm/components/Preview';
import DataBlock from './DataBlock';

const HeaderBlock = styled('div')``;
const PreviewBlock = styled('div')``;

const SmsTemplate = ({
  // account,
  className,
  createdBy,
  name,
  // onError,
  templateData = {},
}) => {
  const messageCampaignLink = templateData.messageCampaign?.deeplink;
  const formatedDate = templateData?.date
    ? moment.utc(templateData.date.dateTime).format('ddd, MMM DD, YYYY hh:mm A')
    : null;

  return (
    <div className={className}>
      <Card>
        <HeaderBlock>
          <FormattedMessage capitalize id="CAMPAIGN_OVERVIEW" />
        </HeaderBlock>
        {messageCampaignLink ? (
          <DataBlock
            dataValue={name}
            link={messageCampaignLink}
            title="CAMPAIGN_NAME"
            valueType="hyperlink"
          />
        ) : (
          <DataBlock dataValue={name} title="CAMPAIGN_NAME" valueType="text" />
        )}
        <DataBlock
          dataValue={templateData.customerGroups}
          title="CUSTOMER_LIST"
          valueType="tags"
        />
        <DataBlock
          dataValue={templateData.customerTags}
          title="CAMPAING_TAGS_CUSTOMERS"
          valueType="tags"
        />
        <DataBlock dataValue={createdBy} title="CREATED_BY" valueType="text" />
        <DataBlock
          dataValue={templateData.message}
          title="MESSAGE"
          valueType="message"
        />
        {formatedDate && (
          <DataBlock
            dataValue={formatedDate}
            title="DATE_AND_TIME"
            valueType="text"
          />
        )}
        {/* <PreviewBlock>
          <Preview
            account={account}
            campaignName={name}
            message={templateData.message}
            onError={onError}
          />
        </PreviewBlock> */}
      </Card>
    </div>
  );
};

SmsTemplate.propTypes = {
  // account: PropTypes.number.isRequired,
  className: PropTypes.string,
  createdBy: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  name: PropTypes.string,
  // onError: PropTypes.func.isRequired,
  templateData: PropTypes.shape({
    customerGroups: PropTypes.arrayOf(PropTypes.object),
    customerTags: PropTypes.arrayOf(PropTypes.object),
    date: PropTypes.shape({
      dateTime: PropTypes.object,
    }),
    message: PropTypes.string,
    messageCampaign: PropTypes.shape({
      deeplink: PropTypes.string,
    }),
  }),
};

export default styled(SmsTemplate)`
  grid-column: 1 / span 3;

  ${PreviewBlock} {
    margin-top: 3.4rem;
  }

  ${HeaderBlock} {
    margin: 0 2.4rem 2.9rem 0;
  }
`;
