import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@react-intl';
import { compose } from 'redux';
import styled, { withStyles, theme } from '@styled-components';

import List from 'Components/List';
import withScroll from 'Components/with/scroll';

const Message = styled('span')``;

const Loading = () => (
  <FormattedMessage capitalize component={Message} id="LOADING">
    loading
  </FormattedMessage>
);

const NoOptions = () => (
  <FormattedMessage capitalize component={Message} id="NO_OPTIONS_AVAILABLE">
    There is no options available
  </FormattedMessage>
);

const CreateNewOption = ({ onClick = () => {}, option }) => {
  const handleClick = event => {
    event.stopPropagation();

    onClick({ name: option });
  };

  return (
    <FormattedMessage
      capitalize
      component={Message}
      id="CREATE_TAG_%NAME%"
      onClick={handleClick}
      values={{ name: option }}
    >
      {'Create new tag "{name}"'}
    </FormattedMessage>
  );
};

CreateNewOption.propTypes = {
  onClick: PropTypes.func,
  option: PropTypes.string,
};

const Item = styled('article')``;
const Option = ({ id, name, onClick = () => {} }) => {
  const handleClick = event => {
    event.stopPropagation();

    onClick({ id, name });
  };

  return (
    <Item data-option={name} onClick={handleClick}>
      {name}
    </Item>
  );
};

Option.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  onClick: PropTypes.func,
};

export const Options = ({
  allowCreate,
  className,
  data,
  isLoading,
  onSelect = () => {},
  option,
}) => {
  let template = Loading;
  if (allowCreate && option !== '') {
    template = CreateNewOption;
  } else if (!isLoading) {
    template = NoOptions;
  }

  return (
    <List
      className={className}
      emptyTemplate={template}
      items={data}
      options={{ onClick: onSelect, option }}
      template={Option}
    />
  );
};

Options.propTypes = {
  allowCreate: PropTypes.bool,
  className: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  isLoading: PropTypes.bool,
  onSelect: PropTypes.func,
  option: PropTypes.string,
};

export default compose(
  withStyles`
    background: #FFFFFF;
    border-radius: 0.6rem;
    box-shadow: 0 0.8rem 1.6rem rgba(0, 15, 25, 0.05), 0 0.1rem 0 rgba(0, 15, 25, 0.05);
    min-height: 100%;

    ${Item}, ${Message} {
      align-items: center;
      cursor: pointer;
      display: flex;
      height: 4.8rem;
      padding: 0 1.6rem;

      &:hover {
        background-color: ${theme('--color-blueish-white')};
      }
    }
  `,
  withScroll,
)(Options);
