import React from 'react';
import PropTypes from '@prop-types';
import styled, { theme } from '@styled-components';

import { ARROW_SIZE } from './constants';

const Arrow = styled('div')``;

const Wrapper = ({ className, ...props }) => (
  <div className={className} {...props}>
    <Arrow />
  </div>
);

Wrapper.propTypes = {
  className: PropTypes.string,
};

export default styled(Wrapper)`
  height: calc(${ARROW_SIZE}rem / 2);
  opacity: 0;
  overflow: hidden;
  position: absolute;
  transform-origin: 50% 0;
  transition: opacity 250ms;
  width: ${ARROW_SIZE}rem;

  ${Arrow} {
    background: ${theme('--color-dark-night-60')};
    height: 1.2rem;
    left: ${ARROW_SIZE / 2}rem;
    position: absolute;
    top: ${ARROW_SIZE / 2}rem;
    transform: translate(-0.6rem, -0.6rem) rotate(45deg);
    width: 1.2rem;
  }

  &[data-visible='true'] {
    opacity: 1;
  }

  &[data-anchor='left-top'],
  &[data-anchor='right-top'],
  &[data-anchor='left-bottom'],
  &[data-anchor='right-bottom'] {
    top: 50%;
  }

  &[data-anchor='left-top'],
  &[data-anchor='left-bottom'] {
    right: -${ARROW_SIZE / 2}rem;
    transform: rotate(-90deg);
  }

  &[data-anchor='right-top'],
  &[data-anchor='right-bottom'] {
    left: -${ARROW_SIZE / 2}rem;
    transform: rotate(90deg);
  }

  &[data-anchor='top'],
  &[data-anchor='bottom'] {
    left: calc((100% - ${ARROW_SIZE}rem) / 2);
  }

  &[data-anchor='top'] {
    top: 100%;
  }

  &[data-anchor='bottom'] {
    top: 0;
    transform: rotate(180deg);
  }
`;
