import { createService } from '@redux-act';

import request from 'Providers/request';

import {
  recentOrders as recentOrdersSchema,
  averageItemsByTime as averageItemsByTimeSchema,
  averageOrdersByTime as averageOrdersByTimeSchema,
} from 'Schemas';

const getAverageItemsByTime = createService(
  async ({ account, from, to, unit }, token) => {
    const { items } = await request.read(
      '/analytics/average_item_basket',
      {
        from,
        ofaId: account,
        to,
        unit,
      },
      averageItemsByTimeSchema,
      { token },
    );

    return items;
  },
);

const getAverageValueByTime = createService(
  async ({ account, currency, from, to, unit }, token) => {
    const { orders } = await request.read(
      '/analytics/average_order',
      {
        currency,
        from,
        ofaId: account,
        to,
        unit,
      },
      averageOrdersByTimeSchema,
      { token },
    );

    return { currency, orders };
  },
);

const getById = createService(
  async ({ ofaId, ofaSecret, platformId, shoppingCartId, type }, token) => {
    const data = await request(
      '/order',
      'get',
      {
        ofaId,
        ofaSecret,
        platformId,
        shoppingCartId,
        type,
      },
      {},
      { token },
    );

    return data;
  },
);

const getRecent = createService(async ({ account, limit, offset }, token) => {
  const { collection, total } = await request(
    `/analytics/recent_orders`,
    'get',
    {
      limit,
      ofaId: account,
      offset,
    },
    recentOrdersSchema,
    { token },
  );

  return {
    collection,
    total,
  };
});

const update = createService(
  async ({ data, ofaId, ofaSecret, platformId, shoppingCartId, type }) => {
    const params = new URLSearchParams({
      ofaId,
      ofaSecret,
      platformId,
      shoppingCartId,
      type,
    });

    return request(
      `/order/update?${params.toString()}`,
      'PUT',
      data,
      {},
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      },
    );
  },
);

export default {
  getAverageItemsByTime,
  getAverageValueByTime,
  getById,
  getRecent,
  update,
};
