import React from 'react';
import styled from '@styled-components';
import PropTypes from '@prop-types';

import { Input } from 'Components/Form';

const CustomUrlForm = ({ className, onChange }) => {
  const handleChange = value => onChange({ redirectUrl: value });

  return (
    <div className={className}>
      <Input
        capitalize
        label="REDIRECT_URL"
        name="redirectUrl"
        onChange={handleChange}
      />
    </div>
  );
};

CustomUrlForm.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
};

export default styled(CustomUrlForm)`
  margin-top: 1.6rem;
`;
