import React, { useRef, useState } from 'react';
import PropTypes from '@prop-types';
import styled, { css } from '@styled-components';

import IconButton from 'Components/IconButton';
import { Input, Fieldset } from 'Components/Form';

import Tooltip from './Tooltip';

const InputWrapper = styled('section')``;

const EditableQuestion = ({
  className,
  onKeyDown,
  onRemove,
  value,
  ...props
}) => {
  const [tooltip, setTooltip] = useState({ visible: false });
  const ref = useRef(null);
  const { askedCount, askedUsersPercentage } = value || {};

  const onMouseOut = () => setTooltip({ ...tooltip, visible: false });

  const onMouseOver = () => {
    const { top, right } = ref.current.getBoundingClientRect();
    setTooltip({ left: right, top, visible: true });
  };

  return (
    <Fieldset
      className={className}
      onBlur={onMouseOut}
      onFocus={onMouseOver}
      onMouseOut={onMouseOut}
      onMouseOver={onMouseOver}
      value={value}
      {...props}
    >
      <InputWrapper ref={ref}>
        <Input
          capitalize
          label="QUESTION"
          name="text"
          onKeyDown={onKeyDown}
          type="text"
        />
        {onRemove && <IconButton name="close" onClick={onRemove} />}
      </InputWrapper>
      <Tooltip
        askedCount={askedCount}
        askedUsersPercentage={askedUsersPercentage}
        {...tooltip}
      />
    </Fieldset>
  );
};

EditableQuestion.propTypes = {
  className: PropTypes.string,
  onKeyDown: PropTypes.func,
  onRemove: PropTypes.func,
  value: PropTypes.object,
};

const onRemoveMixin = css`
  ${InputWrapper} {
    padding-right: 1.6rem;
  }
`;

export default styled(EditableQuestion)`
  margin-bottom: 0.8rem;
  position: relative;
  width: 100%;

  ${InputWrapper} {
    display: flex;
    padding: 0.2rem 0.4rem 0.2rem 1.6rem;

    ${Input} {
      flex: 1;
    }
  }

  ${({ onRemove }) => !onRemove && onRemoveMixin}
`;
