import PropTypes from 'prop-types';
import React from 'react';
import styled, { theme } from '@styled-components';

import Button from 'Components/Button';

const Actions = ({ className, onBack, onNext }) => (
  <div className={className}>
    <Button capitalize primary translate onClick={onNext}>
      ACTIONS.NEXT
    </Button>
    <Button capitalize primary secondary translate onClick={onBack}>
      ACTIONS.BACK
    </Button>
  </div>
);

Actions.propTypes = {
  className: PropTypes.string,
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default styled(Actions)`
  margin-top: 4rem;

  ${Button} {
    width: 14.4rem;

    &:last-child {
      background-color: ${theme('--color-light')};
      margin-left: 2.3rem;
    }
  }
`;
