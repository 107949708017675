import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withStyles, theme } from '@styled-components';
import { FormattedMessage } from '@react-intl';

import { withRouter } from 'Components/Router';

const Breadcrumb = ({
  className,
  location: { pathname: rawPath },
  path = rawPath.split('/')[1] || 'dashboard',
}) => {
  return (
    <FormattedMessage
      className={className}
      component="h1"
      id={`HEADER.${path.toUpperCase()}`}
    >
      {path}
    </FormattedMessage>
  );
};

Breadcrumb.propTypes = {
  className: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  path: PropTypes.string,
};

export default compose(
  withStyles`
    font-size: 2.4rem;
    font-weight: 300;
    height: 2.1rem;
    margin: 2.4rem 0;
    text-align: left;

    @media(${theme('--column-8')}) {
      margin-left: 2.4rem;
    }
  `,
  withRouter,
)(Breadcrumb);
