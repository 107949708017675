import React from 'react';
import PropTypes from 'prop-types';

import Contact from './Contact';

const ContactFixedSizeListItem = ({ data, index, style }) => {
  const { customers, isContactSelectedFn, onChange } = data;
  const contact = customers[index];

  return (
    <div style={style}>
      <Contact
        key={contact.id}
        avatar={contact.imageURL_thumb}
        customerId={contact.id}
        firstName={contact.firstName}
        isChecked={isContactSelectedFn(contact.id)}
        lastName={contact.lastName}
        onChange={onChange}
        phone={contact.phone}
      />
    </div>
  );
};

ContactFixedSizeListItem.propTypes = {
  data: PropTypes.shape({
    customers: PropTypes.arrayOf({
      firstName: PropTypes.string,
      id: PropTypes.number.isRequired,
      imageURL_thumb: PropTypes.string,
      lastName: PropTypes.string,
      phone: PropTypes.string,
    }),
    isContactSelectedFn: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
  }),
  index: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
};

export default ContactFixedSizeListItem;
