import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { debounce, defer } from 'underscore';

const SCREEN_SMALL = 600;

export const useDesktop = element => {
  const isMounted = useRef(true);
  const [isDesktop, setDesktop] = useState(false);
  const resize = useCallback(() => {
    if (isMounted.current) {
      const {
        current: { offsetWidth },
      } = element;
      setDesktop(offsetWidth > SCREEN_SMALL);
    }
  }, [element]);

  const resizer = new ResizeObserver(debounce(resize, 250));

  useLayoutEffect(() => {
    const { current: el } = element;
    resize();
    defer(() => {
      if (el) {
        isMounted.current = true;
        resizer.observe(el);
      }
    });
    return () => {
      isMounted.current = false;
      resizer.unobserve(el);
    };
  }, [element, resize, resizer]);

  return isDesktop;
};

export default {
  useDesktop,
};
