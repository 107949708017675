import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import styled, { withStyles as css, theme } from '@styled-components';

import View from 'Components/View';
import ViewHeadline from 'Components/ViewHeadline';

// import AddCustomer from 'Containers/AddCustomer';
import CustomersTable from 'Containers/CustomersTable';
import UsersByCountry from 'Containers/UsersByCountry';

import UserCharts from './components/UserCharts';
import CustomerStats from './components/CustomerStats';

import Constants from 'Constants';
import {
  getDatesTransformedByUnit,
  getUnit as getUnitRaw,
} from 'Modules/dates';
import moment from 'moment';
import CustomRangePicker from 'Components/CustomRangePicker';
import { memoize } from 'underscore';

const Datepicker = styled('div')``;

const {
  FILTERS: { LAST_YEAR },
} = Constants;
const getUnit = memoize(getUnitRaw, (...args) => JSON.stringify(args));
const getDates = memoize(getDatesTransformedByUnit, (...args) =>
  JSON.stringify(args),
);

const Customers = ({
  className,
  filter = LAST_YEAR,
  from: fromInitial = moment()
    .subtract(1, 'year')
    .format(),
  onRangeChange,
  to: toInitial = moment().format(),
}) => {
  const unit = getUnit(fromInitial, toInitial);
  const { from, to } = getDates(unit, fromInitial, toInitial);

  return (
    <View className={className}>
      <ViewHeadline
        heading="INSTRUCTIONS.CUSTOMERS.LEARN"
        title="INSTRUCTIONS.CUSTOMERS"
      />
      <Datepicker>
        <CustomRangePicker
          filter={filter}
          from={from}
          onRangeChange={onRangeChange}
          to={to}
        />
      </Datepicker>
      <CustomerStats />
      <UserCharts />
      <CustomersTable from={from} to={to} />
      <UsersByCountry />
    </View>
  );
};

Customers.propTypes = {
  className: PropTypes.string,
  filter: PropTypes.string,
  from: PropTypes.string,
  onRangeChange: PropTypes.func,
  to: PropTypes.string,
};

export default compose(
  css`

    @media (${theme('--column-8')}) {
      ${ViewHeadline}, ${UserCharts}, ${UsersByCountry}, ${CustomersTable}, ${CustomerStats}{
        grid-column: span 8;
      }
      ${Datepicker}{
        grid-column: span 4;
        text-align: right;
      }
    }

    @media (${theme('--column-12')}) {
       ${UserCharts}, ${UsersByCountry}, ${CustomersTable}, ${CustomerStats}{
        grid-column: span 12;
      }
      ${ViewHeadline}{
        grid-column: span 8;
      }
      ${Datepicker}{
        grid-column: span 4;
        text-align: right;
      }
    }

    @media (${theme('--screen-small')}) {
      ${UsersByCountry} {
        height: 65rem;
      }
    }
  `,
)(Customers);
