import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@styled-components';

import Modal from 'Components/Modal';

import { currentAccount } from 'Selectors';

import service from 'Services/products';

import DataProvider from 'Containers/DataProvider';
import View from './Product';

const mapStateToProps = state => ({
  account: currentAccount(state),
});

const Product = ({
  account,
  order,
  depth,
  onBack,
  onClose,
  in: modalIn,
  id,
}) => (
  <Modal
    depth={depth}
    in={modalIn}
    onBack={onBack}
    onClose={onClose}
    order={order}
    title="PRODUCT"
  >
    <DataProvider
      account={account}
      emptyTemplate={() => <Redirect to="/oops" />}
      id={id}
      params={{ account, id }}
      query={service.findOne}
      template={View}
    />
  </Modal>
);

Product.defaultProps = {
  onClose() {},
};

Product.propTypes = {
  account: PropTypes.number,
  depth: PropTypes.number,
  id: PropTypes.any.isRequired,
  in: PropTypes.bool,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  order: PropTypes.number,
};

export default compose(connect(mapStateToProps), withStyles``)(Product);
