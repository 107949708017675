import React from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';
import { FormattedMessage } from '@react-intl';

import Avatar from 'Components/Avatar';
import { Row } from 'Components/ResponsiveTable';

const Id = styled('span')``;
const Name = styled('span')``;
const Quantity = styled('span')``;

const DesktopTemplate = ({ className, id, name, image, quantity }) => {
  return (
    <Row className={className}>
      <Avatar image={image} />
      <Name>{name}</Name>
      <Id>{id}</Id>
      <FormattedMessage
        capitalize
        component={Quantity}
        id="%NUMBER%_ITEMS"
        values={{ number: quantity }}
      />
    </Row>
  );
};

DesktopTemplate.propTypes = {
  className: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  image: PropTypes.string,
  name: PropTypes.string,
  quantity: PropTypes.number,
};

export default styled(DesktopTemplate)`
  align-items: center;
  display: grid;
  grid-gap: 0 1.2rem;
  grid-template-columns: auto 2fr auto 1fr;
  height: 5.6rem;

  ${Avatar} {
    border-radius: 0.6rem;
    height: 4rem;
    max-height: 4rem;
    max-width: 4rem;
    min-height: 4rem;
    min-width: 4rem;
  }

  ${Id}, ${Quantity} {
    ${theme('--font-small')}
    ${theme('--font-weight-medium')}
    ${theme('--font-opacity-40')}
  }

  ${Name} {
    ${theme('--font-small')}
    ${theme('--font-weight-medium')}
  }

  ${Quantity} {
    text-align: right;
  }
`;
