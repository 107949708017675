import { createService } from '@redux-act';
import request from 'Providers/request';
import moment from 'moment';

const download = createService(
  async ({ account, from, surveyId, to }, token) => {
    const data = await request.read(
      `/ofa/${account}/surveys/${surveyId}/export`,
      {
        from,
        to,
      },
      {},
      { responseType: 'blob', token },
    );

    return data;
  },
);

const get = createService(async ({ account, limit, offset }) => {
  return request(`/ofa/${account}/surveys`, 'get', {
    limit,
    offset,
  });
});

const getById = createService(async ({ account, from, id, to }) => {
  const data = await request(`/ofa/${account}/surveys/${id}`, 'get', {
    from,
    to,
  });

  return data;
});

const customers = createService(
  async ({ account, from, search, surveyId, to, limit, offset }) => {
    const params = {
      from,
      limit,
      offset,
      to,
      ...(search && { search }),
    };

    const { collection: list, steps, total = list.length } = await request(
      `/ofa/${account}/surveys/${surveyId}/customers`,
      'get',
      params,
    );

    return {
      collection: list.map(({ updatedAt, ...customer }) => {
        const validDate = moment(updatedAt);
        return {
          ...customer,
          ...(!validDate.isSame(moment('0')) && { updatedAt }),
        };
      }),
      steps,
      total,
    };
  },
);

const getCustomerAnswers = createService(
  async ({ account, customerId, surveyId }) => {
    return request(
      `/ofa/${account}/surveys/${surveyId}/customers/${customerId}/responses`,
      'get',
      {},
    );
  },
);

export default {
  customers,
  download,
  get,
  getById,
  getCustomerAnswers,
};
