import styled, { theme } from '@styled-components';

export default styled('section')`
  display: grid;
  grid-gap: 2.4rem;
  grid-template: auto / 1fr;
  overflow: auto;
  padding: 2rem;

  @media (${theme('--column-8')}) {
    grid-template: 1fr / repeat(8, 1fr);
    padding: 1.6rem 3.2rem;
  }

  @media (${theme('--column-12')}) {
    grid-gap: 3.2rem;
    grid-template: 1fr / repeat(12, 1fr);
    padding: 3.2rem 6.4rem;
  }

  @media (${theme('--level-3')}) {
    margin: 0 auto;
    max-width: 160rem;
  }
`;
