import React from 'react';
import PropTypes from '@prop-types';
import { compose } from 'underscore';

import ContextMenu from 'Components/ContextMenu';
import { withRouter } from 'Components/Router';
import withPermissions from 'Components/with/permissions';

import C from 'Constants';

const {
  ROLE: { ADMIN, SUPER_ADMIN },
} = C;

const Toolbar = ({ history: { push }, location: { pathname } }) => (
  <ContextMenu
    right
    options={{
      EDIT_CUSTOMER: () => push(`${pathname}/edit`),
    }}
  />
);

Toolbar.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default compose(
  withRouter,
  withPermissions([SUPER_ADMIN, ADMIN]),
)(Toolbar);
