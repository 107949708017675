import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '@react-intl';
import styled, { theme, withStyles as css } from '@styled-components';
import { compose } from 'underscore';

import C from 'Constants';

import Button from 'Components/Button';
import Filters from 'Components/Filters';
import RangePicker, { END_DATE } from 'Components/RangePicker';

import { getDateFilter } from 'Modules/dates';

import Modal from './Modal';
import Header from './Header';

const Heading = styled('header')``;
const Footer = styled('footer')``;
const Title = styled('h3')``;
const Done = styled(Button)``;

const {
  FILTERS: { CUSTOM },
} = C;

class CustomRangePicker extends Component {
  static propTypes = {
    className: PropTypes.string,
    filter: PropTypes.string,
    from: PropTypes.string.isRequired,
    intl: intlShape,
    onRangeChange: PropTypes.func,
    showAllTime: PropTypes.bool,
    to: PropTypes.string.isRequired,
  };

  state = {
    cursorPosition: END_DATE,
    endDefined: true,
    filter: this.props.filter,
    from: this.props.from,
    isOpen: false,
    to: this.props.to,
  };

  handleChange = ({ startDate, endDate }) => {
    const { endDefined, from, to } = this.state;
    const selected = endDate || startDate;

    if (endDefined) {
      this.setState({
        endDefined: false,
        filter: CUSTOM,
        from: selected.format(),
        to: selected.format(),
      });
    } else {
      const fromValue =
        moment(from).diff(selected) > 0 ? selected.format() : from;
      const toValue = moment(to).diff(selected) < 0 ? selected.format() : to;

      this.setState({
        endDefined: true,
        from: fromValue,
        to: toValue,
      });
    }
  };

  handleFilterChange = filter => {
    const { from, to } = getDateFilter(filter);
    this.setState({
      endDefined: true,
      filter,
      from,
      to,
    });
  };

  handleSubmit = () => {
    const { filter, from, to } = this.state;
    const { onRangeChange } = this.props;

    onRangeChange(
      filter,
      moment(from)
        .startOf('day')
        .format(),
      moment(to).format(),
    );

    this.togglePanel();
  };

  handleCursorChange = () => {
    this.setState({
      filter: CUSTOM,
    });
  };

  setHeader = header => {
    this.header = header;
  };

  togglePanel = () => {
    this.setState(({ isOpen }) => ({
      endDefined: true,
      isOpen: !isOpen,
      // Cursor is positioned at the beginning when the panel is closed
    }));
  };

  formatDate(date) {
    if (!date) return 'undef. date';
    const { intl } = this.props;
    return intl.formatDate(new Date(date), {
      day: 'numeric',
      month: 'short',
      weekday: 'short',
      year: 'numeric',
    });
  }

  render() {
    const { className, showAllTime } = this.props;
    const { filter, from, to, cursorPosition, isOpen } = this.state;
    const {
      handleFilterChange,
      handleChange,
      handleCursorChange,
      handleSubmit,
    } = this;

    const fromContent = this.formatDate(from);
    const toContent = this.formatDate(to);

    return (
      <div>
        <Header
          fromContent={fromContent}
          onClick={this.togglePanel}
          toContent={toContent}
        />
        <Modal className={className} in={isOpen} onClose={this.handleSubmit}>
          <Heading>
            <Title>Select a period to display</Title>
            <Header fromContent={fromContent} toContent={toContent} />
          </Heading>
          <Filters
            active={filter}
            onChange={handleFilterChange}
            showAllTime={showAllTime}
          />
          <RangePicker
            open
            cursorPosition={cursorPosition}
            filter={filter}
            from={new Date(from)}
            onChange={handleChange}
            onCursorChange={handleCursorChange}
            to={new Date(to)}
          />
          <Footer>
            <FormattedMessage
              capitalize
              primary
              component={Done}
              id="ACTIONS.DONE"
              onClick={handleSubmit}
            >
              done
            </FormattedMessage>
          </Footer>
        </Modal>
      </div>
    );
  }
}

export default compose(
  css`
    ${Modal} {
      background: ${theme('--color-blueish-white')};

      height: 100%;

      ${Heading} {
        display: flex;
        justify-content: space-between;
        padding: 1.6rem;

        ${Title} {
          ${theme('--font-medium')}
          ${theme('--font-weight-medium')}
          color: ${theme('--color-dark-night-60')};
          display: none;
          padding: 0.8rem 0 0 0.8rem;
        }
      }

      ${Filters} {
        width: 100%;
      }

      ${Footer} {
        bottom: 0;
        padding: 2rem;
        position: absolute;
        width: 100%;

        ${Done} {
          width: 100%;
        }
      }

      @media screen and (${theme('--screen-small')}) {
        background: none;

        ${Heading} {
          border-bottom: 1px solid ${theme('--color-dark-night-10')};
        }

        ${Footer} {
          padding: 1.6rem;
          position: relative;
          text-align: right;

          ${Done} {
            width: 16rem;
          }
        }
      }

      @media (${theme('--column-12')}) {
        ${Heading} ${Title} {
          display: block;
        }
      }
    }
  `,
  injectIntl,
)(CustomRangePicker);
