import React from 'react';
import PropTypes from 'prop-types';
import styled from '@styled-components';
import { intersection } from 'underscore';

import List, { Item } from 'Components/List';
import Icon from 'Components/Icon';

const PLATFORMS = [
  'LINKEDIN',
  'LINE',
  'FB_MESSENGER',
  'INSTAGRAM',
  'FACEBOOK',
  'TWITTER',
];

const Platform = ({ icon, disabled }) => (
  <Icon disabled={disabled} name={icon.toLowerCase()}>
    {icon}
  </Icon>
);

Platform.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.string,
};

export const Platforms = ({ className, data = [] }) => {
  const active = intersection(PLATFORMS, data);
  const items = PLATFORMS.map(platform => ({
    disabled: !active.includes(platform),
    icon: platform,
  }));
  return <List className={className} items={items} template={Platform} />;
};

Platforms.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.string),
};

export default styled(Platforms)`
  align-items: center;
  display: grid;
  grid-gap: 0.8rem;
  grid-template-columns: repeat(3, 2.4rem);

  ${Item} {
    align-items: center;
    display: flex;
    justify-content: center;
  }
`;
