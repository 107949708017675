import { connect } from 'react-redux';

import { me } from 'Selectors';
import Dashboard from './Dashboard';

const mapStateToProps = state => ({
  user: me(state),
});

export default connect(mapStateToProps)(Dashboard);
