import React from 'react';
import PropTypes from '@prop-types';
import { connect } from 'react-redux';
import { compose } from 'underscore';

import DataProvider from 'Containers/DataProvider';

import Modal from 'Components/Modal';

import { currency as currentCurrency, currentAccount } from 'Selectors';

import service from 'Services/customers';

import View from './EditCustomer';

const mapStateToProps = state => ({
  account: currentAccount(state),
  currency: currentCurrency(state),
});

const EditCustumer = ({
  account,
  currency,
  order,
  dataKey,
  depth,
  id,
  in: modalIn,
  onClose,
  onBack,
  onReload,
  previous,
}) => {
  return (
    <Modal
      depth={depth}
      in={modalIn}
      onBack={onBack}
      onClose={onClose}
      order={order}
      title="EDIT_CUSTOMER"
    >
      <DataProvider
        key={dataKey}
        onReload={onReload}
        onSuccess={onBack}
        params={{ account, currency, id }}
        previous={previous}
        query={service.findOne}
        template={View}
      />
    </Modal>
  );
};

EditCustumer.propTypes = {
  account: PropTypes.number,
  currency: PropTypes.string,
  dataKey: PropTypes.string,
  depth: PropTypes.number,
  id: PropTypes.any.isRequired,
  in: PropTypes.bool,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  onReload: PropTypes.func,
  order: PropTypes.number,
  previous: PropTypes.string,
};

export default compose(connect(mapStateToProps))(EditCustumer);
