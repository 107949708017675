import React, { useState, useCallback } from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from '@prop-types';
import { FormattedMessage } from '@react-intl';
import { useField } from 'spooner';

import SelectBrandContent from 'Components/SelectBrandContent';
import Button from 'Components/Button';

import VideoThumbnail from './VideoThumbnail';

const Preview = styled('section')`
  background-image: url(${props => props.source});
  background-size: cover;
  border: 0.2rem solid ${theme('--color-dark-night-20')};

  &[data-ispreview='true'] {
    background-image: url('/images/placeholder.svg');
    background-size: auto;
    border: 0.2rem dashed ${theme('--color-primary')};
  }
`;
const Play = styled('img').attrs({ src: '/images/play.svg' })``;
const Block = styled('article')``;
const Wrapper = styled('section')``;
const FileName = styled('h4')``;
const Instructions = styled('p')``;
const ThumbnailInstructions = styled('p')``;

const getFileName = source => {
  if (!source) return '';
  const match = /\/[^/]*$/.exec(source);
  if (!match) return '';

  return match[0].slice(1);
};

const AddMedia = ({ className }) => {
  const { value: selectedItem = {}, onChange: setSelectedItem } = useField({
    name: 'media',
  });

  const [brandContentSelectorOpen, setBrandContentSelectorOpen] = useState(
    false,
  );
  const openBrandContentSelector = useCallback(
    () => setBrandContentSelectorOpen(true),
    [setBrandContentSelectorOpen],
  );
  const closeBrandContentSelector = useCallback(
    () => setBrandContentSelectorOpen(false),
    [setBrandContentSelectorOpen],
  );

  const handleSubmitSelection = useCallback(
    ([selection]) => {
      setSelectedItem(selection);
      closeBrandContentSelector();
    },
    [setSelectedItem, closeBrandContentSelector],
  );

  const { source, type, id } = selectedItem;
  const isVideo = type === 'video';

  return (
    <article className={className}>
      <Block>
        <Preview data-ispreview={!source} source={source}>
          {isVideo && <Play />}
        </Preview>
        <Wrapper>
          <FileName>{getFileName(source)}</FileName>
          <FormattedMessage
            capitalize
            component={Instructions}
            id="INSTRUCTIONS.FILE_FORMAT"
          />
          <FormattedMessage
            capitalize
            secondary
            component={Button}
            id="CHANGE_FILE"
            onClick={openBrandContentSelector}
          />
        </Wrapper>
      </Block>
      {isVideo && (
        <VideoThumbnail
          previewingId={id}
          selectedItems={[selectedItem]}
          setSelectedItems={handleSubmitSelection}
        />
      )}
      <SelectBrandContent
        single
        in={brandContentSelectorOpen}
        onClose={closeBrandContentSelector}
        onSubmit={handleSubmitSelection}
      />
    </article>
  );
};

AddMedia.propTypes = {
  className: PropTypes.string,
};

export default styled(AddMedia)`
  background: ${theme('--color-blueish-white')};
  border-radius: 0.6rem;
  margin-top: 0.8rem;
  padding: 1.6rem;

  ${ThumbnailInstructions}{
    ${theme('--font-medium')}
    ${theme('--font-weight-book')}
    margin-bottom: 0.8rem;
    margin-top: 3.2rem;
  }

  ${Block}{
    display: grid;
    grid-gap: 1.6rem;
    grid-template: auto / auto 1fr;

    ${Preview} {
      align-items: center;
      background-color: ${theme('--color-primary-10')};
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 0.6rem;
      display: flex;
      height: 11rem;
      justify-content: center;
      width: 11rem;
    }

    ${Wrapper} {
      ${FileName}{
        ${theme('--font-medium')}
        ${theme('--font-weight-book')}
        color: ${theme('--color-dark-night-80')};
        margin-bottom: 1.6rem;
        word-break: break-all;
        word-wrap: break-word;
      }

      ${Instructions} {
        ${theme('--font-small')}
        ${theme('--font-weight-book')}
        color: ${theme('--color-dark-night-60')};
        margin-bottom: 0.8rem;
      }

      ${Button} {
        background: ${theme('--color-light')};
        width: 100%;
      }
    }
  }

  @media (${theme('--screen-small')}){
    ${Block} ${Preview}{
      height: 19rem;
      width: 19rem;
    }
  }
`;
