import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@react-intl';
import styled, { theme } from '@styled-components';

import Icon from 'Components/Icon';
import Card from 'Components/Card';
import Chip from 'Components/Chip';

import Definition, { Detail, Term } from 'Components/Definition';
import { FormattedDate } from 'react-intl';

const ExternalLink = styled('a').attrs(({ to }) => ({
  href: to,
  target: '_blank',
}))``;

const DeepLink = styled(Detail)``;
const Type = styled(Detail)``;
const Platform = styled(Detail)``;
const CreationDate = styled(Detail)``;
const Status = styled(Detail)``;

const Details = ({
  className,
  createdAt,
  deeplink,
  platform,
  status,
  type,
}) => (
  <Card as={Definition} className={className}>
    <Term translate>DEEPLINK</Term>
    <DeepLink>
      <FormattedMessage
        capitalize
        component={ExternalLink}
        id="LINK_TO_SURVEY"
        to={deeplink}
      />
    </DeepLink>
    <Term translate>TYPE</Term>
    <Type>{type}</Type>
    <Term translate>PLATFORM</Term>
    <Platform>
      {platform && <Icon name={platform.toLowerCase()}>{platform}</Icon>}
    </Platform>
    <Term translate>CREATION_DATE</Term>
    <CreationDate>
      <FormattedDate
        day="2-digit"
        month="long"
        value={createdAt}
        year="numeric"
      />
    </CreationDate>
    <Term translate>STATUS</Term>
    <Status>
      <Chip>{status}</Chip>
    </Status>
  </Card>
);

Details.propTypes = {
  className: PropTypes.string,
  createdAt: PropTypes.string,
  deeplink: PropTypes.string,
  platform: PropTypes.string,
  status: PropTypes.string,
  type: PropTypes.string,
};

export default styled(Details)`
  ${theme('--font-medium')}
  align-items: center;
  display: grid;
  grid-template: 1.6rem repeat(2, auto) / 1fr auto;
  justify-content: space-between;
  padding: 2rem 2.4rem;
  
  ${Detail} {
    ${theme('--font-weight-book')}
  }

  ${Term} {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  ${DeepLink} {
    grid-column: 1;
    grid-row: 1;
  }

  ${Platform} {
    align-items: flex-start;
    display: flex;
    grid-column: 2;
    grid-row: 1;
    justify-content: flex-end;
  }

  ${Type} {
    grid-column: 1 / span 2;
    grid-row: 2;
    margin: 0.8rem 0 0.4rem;
  }

  ${CreationDate} {
    grid-column: 1;
    grid-row: 3;
  }

  ${Status} {
    grid-column: 2;
    grid-row: 3;
  }

  ${ExternalLink} {
    color: ${theme('--color-primary')};
  }

  ${Chip} {
    background: #eaccff;
    border: none;
    border-radius: 0.6rem;
    color: ${theme('--color-main')};
  }
  
  ${CreationDate}, ${Type} {
    color: ${theme('--color-dark-night-60')};
  }

  @media (${theme('--screen-small')}) {
    grid-template: auto / 6fr 2fr 1fr 3fr auto ;

    ${DeepLink}, ${Platform}, ${Type}, ${CreationDate}, ${Status} {
      display: inline;
      grid-column: auto;
      grid-row: auto;
      margin: 0;
    }

    ${Detail} {
      grid-column: auto;
      grid-row: auto;
    }
  }
`;
