import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from '@styled-components';

import Button from 'Components/Button';

import DataText from './DataText';
import Tags from 'Components/Form/Input/AutoSuggest/Tags';
import CheckBox from 'Components/Form/Input/CheckBox';
import Preview from './Preview';
import SectionHeadline from './SectionHeadline';

const Actions = styled('div')``;
const OverviewBlock = styled('div')``;
const OverviewHeadline = styled(SectionHeadline)``;
const MessageWrapper = styled('div')``;

const Overview = ({
  account,
  className,
  customerTags,
  customers,
  date,
  message,
  name,
  onBack,
  onError,
  onSubmit,
  onTest,
  submitButtonLabel,
  type,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const messageWithTags = message?.replaceAll(/<%=(\w+)%>/g, '<span>$1</span>');
  const dateTime = date?.dateTime
    ? moment(date.dateTime).format('ddd, MMM D, YYYY hh:mm A')
    : '';
  const timezone = date?.timezone || '';

  return (
    <div className={className}>
      <SectionHeadline
        heading="INSTRUCTIONS.CREATE_CAMPAIGN.STEP_8"
        title="YOUR_CAMPAIGN"
      />
      <OverviewBlock>
        <OverviewHeadline title="CAMPAING_INFORMATION" />
        <DataText heading={name} title="NAME" />
        <DataText heading={type} title="TYPE" />
        <DataText title="CUSTOMER_LIST" />
        <Tags data={customers} />
        <DataText title="CAMPAING_TAGS_CUSTOMERS" />
        <Tags data={customerTags} />
        <DataText title="MESSAGE" />
        <MessageWrapper dangerouslySetInnerHTML={{ __html: messageWithTags }} />
        <DataText heading={`${dateTime} ${timezone}`} title="DATE" />
      </OverviewBlock>
      <OverviewBlock>
        <CheckBox
          label="CAMPAIGN_TEST"
          onChange={checked => setIsChecked(checked)}
        />
        {isChecked && (
          <Preview
            account={account}
            campaignName={name}
            message={message}
            onError={onError}
            onTest={onTest}
          />
        )}
      </OverviewBlock>
      <Actions>
        <Button capitalize primary translate onClick={onSubmit}>
          {submitButtonLabel}
        </Button>
        <Button capitalize primary secondary translate onClick={onBack}>
          ACTIONS.BACK
        </Button>
      </Actions>
    </div>
  );
};

Overview.propTypes = {
  account: PropTypes.number.isRequired,
  className: PropTypes.string,
  customerTags: PropTypes.array,
  customers: PropTypes.array,
  date: PropTypes.shape({
    dateTime: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired,
  }),
  message: PropTypes.string,
  name: PropTypes.string,
  onBack: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onTest: PropTypes.func.isRequired,
  submitButtonLabel: PropTypes.string,
  type: PropTypes.string,
};

export default styled(Overview)`
  ${OverviewBlock} {
    background: #fff;
    border-radius: 0.6rem;
    padding: 1.6rem;

    &:first-child {
      margin-bottom: 1.6rem;
    }
  }

  ${Actions} {
    margin-top: 4rem;

    ${Button} {
      width: 15.2rem;

      &:first-child {
        margin-right: 2.3rem;
      }
    }
  }

  ${Tags} {
    align-items: start;
    border: none;
    margin: 0 0 0 -0.4rem;
    padding: 0;
  }

  ${SectionHeadline} {
    margin-bottom: 2.4rem;
  }

  ${OverviewHeadline} {
    margin-bottom: 1.2rem;
  }

  ${MessageWrapper} {
    color: #666f75;
    font-size: 1.4rem;
    line-height: 2.4rem;
    margin-bottom: 1.6rem;

    span {
      background: #ccdfff;
      border-radius: 0.4rem;
      box-shadow: none;
      color: #005dff;
      display: inline-block;
      font-size: 0.9em;
      height: 2.4rem;
      margin: 0 0.1rem;
      padding: 0 0.5rem;
      text-align: center;
      vertical-align: baseline;
    }
  }
`;
