import PropTypes from 'prop-types';
import React from 'react';
import styled from '@styled-components';

import { Input } from 'Components/Form';

import Actions from './Actions';
import SectionHeadline from './SectionHeadline';

const Name = ({
  activateActions,
  className,
  name = '',
  onBack,
  onChange,
  onNext,
}) => {
  const handleChange = value => onChange({ name: value });

  return (
    <div className={className}>
      <SectionHeadline
        heading="INSTRUCTIONS.CREATE_CAMPAIGN.STEP_1"
        title="INSTRUCTIONS.SELECT_CAMPAIGN_NAME"
      />
      <Input
        label="CAMPAIGN_NAME"
        name="name"
        onChange={handleChange}
        value={name}
      />
      {activateActions && (
        <Actions disabled={!name.length} onBack={onBack} onNext={onNext} />
      )}
    </div>
  );
};

Name.propTypes = {
  activateActions: PropTypes.bool,
  className: PropTypes.string,
  name: PropTypes.string,
  onBack: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default styled(Name)`
  ${SectionHeadline} {
    margin-bottom: 2.4rem;
  }
`;
