import React, { Fragment } from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';
import { FormattedMessage } from '@react-intl';

import Price from './Price';

const Title = styled('div')``;
const Value = styled('div')``;
const ValueGrey = styled('div')``;

const Summary = ({
  className,
  discount,
  shippingCost,
  subtotal,
  totalCost,
  totalTax,
}) => (
  <div className={className}>
    <Title>
      <FormattedMessage capitalize id="SUBTOTAL" />
    </Title>
    <ValueGrey>
      <Price {...subtotal} />
    </ValueGrey>
    {shippingCost && (
      <>
        <Title>
          <FormattedMessage capitalize id="SHIPPING_AND_HANDLING" />
        </Title>
        <ValueGrey>
          <Price {...shippingCost} />
        </ValueGrey>
      </>
    )}
    {totalTax && (
      <>
        <Title>
          <FormattedMessage capitalize id="ESTIMATED_TAX" />
        </Title>
        <ValueGrey>
          <Price {...totalTax} />
        </ValueGrey>
      </>
    )}
    {discount &&
      discount.breakdown &&
      discount.breakdown.map(item => (
        <Fragment key={item.type}>
          <Title>
            <FormattedMessage capitalize id={`DISCOUNT.${item.type}`} />
          </Title>
          <Value>
            <Price currency={discount.currency} value={item.value} />
          </Value>
        </Fragment>
      ))}
    <Title>
      <FormattedMessage capitalize id="TOTAL" />
    </Title>
    <Value>
      <Price {...totalCost} />
    </Value>
  </div>
);

Summary.propTypes = {
  className: PropTypes.string,
  discount: PropTypes.object,
  shippingCost: PropTypes.object,
  subtotal: PropTypes.object,
  totalCost: PropTypes.object,
  totalTax: PropTypes.object,
};

export default styled(Summary)`
  display: grid;
  grid-template-columns: 3fr 2fr;

  ${Title} {
    color: #000f19;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.7rem;
  }

  ${Value} {
    color: #000f19;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.7rem;
    text-align: right;
  }

  ${ValueGrey} {
    color: #666f75;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.7rem;
    text-align: right;
  }
`;
