import React from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from 'prop-types';

import Chip from 'Components/Chip';

const TagsBlock = styled('div')``;
const Wrapper = styled('span')``;

const GroupTags = ({ className, groups }) => {
  return (
    <TagsBlock className={className}>
      {groups.map(group => (
        <Chip>
          <Wrapper>{group.name}</Wrapper>
        </Chip>
      ))}
    </TagsBlock>
  );
};

GroupTags.propTypes = {
  className: PropTypes.string,
  groups: PropTypes.array.isRequired,
};

export default styled(GroupTags)`
  display: inline-flex;
  justify-content: center;

  ${Chip} {
    background: #eaccff; /* --color-main-20 opacity 1 */
    border: none;
    border-radius: 0.6rem;
    color: ${theme('--color-main')};
    margin-left: 1.2rem;
    padding: 0.4rem;
    text-align: left;
    user-select: none;

    ${Wrapper} {
      margin-top: 0.2rem;
    }
  }
`;
