import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@react-intl';
import styled, { theme } from '@styled-components';

import C from 'Constants';

import Avatar from 'Components/Avatar';
import Button from 'Components/Button';
import Form, { Radio } from 'Components/Form';

const Actions = styled('div')``;
const Paragraph = styled('p')``;
const Title = styled('h3')``;
const Profile = styled('div')``;
const MemberInfo = styled('div')``;
const MemberName = styled('h3')``;
const Email = styled('p')``;
const Label = styled('label')``;

const {
  ROLE: { ADMIN, READER, WRITER },
  STATUS: { ACTIVE },
} = C;

const Box = styled('div')``;
const Role = styled('p')``;
const Roles = styled('div')``;

const EditRole = ({
  className,
  data: {
    email,
    firstName,
    lastName,
    images: { large } = {},
    role,
    status,
  } = {},
  onSubmit,
  setStatus,
}) => {
  useEffect(() => {
    setStatus(status);
  }, [setStatus, status]);
  if (!role) return null;

  return (
    <div className={className}>
      <Form
        defaultValue={{
          email,
          firstName,
          lastName,
          role,
        }}
        onSubmit={onSubmit}
      >
        <FormattedMessage capitalize component={Title} id="PERSONAL_DATA">
          Personal data
        </FormattedMessage>
        {status === ACTIVE ? (
          <FormattedMessage
            capitalize
            component={Paragraph}
            id="INSTRUCTIONS.CHANGE_ROLE"
          >
            Here you can change the team member role and check his personal data
          </FormattedMessage>
        ) : (
          <FormattedMessage
            capitalize
            component={Paragraph}
            id="INSTRUCTIONS.INVITE_CHANGE_ROLE"
          >
            here you may change the role you invite your new team member for
          </FormattedMessage>
        )}
        <Profile>
          {status === ACTIVE && <Avatar image={large} />}
          <MemberInfo>
            <MemberName>
              {firstName} {lastName}
            </MemberName>
            <Email>{email}</Email>
          </MemberInfo>
        </Profile>
        <FormattedMessage capitalize component={Role} id="SELECT_ROLE">
          Select team member role
        </FormattedMessage>
        <Roles>
          <Box>
            <Radio content={ADMIN} id="admin" name="role" />
            <div>
              <FormattedMessage
                capitalize
                component={Label}
                htmlFor="admin"
                id="ROLE.ADMIN"
              >
                Admin
              </FormattedMessage>
              <FormattedMessage
                capitalize
                component={Paragraph}
                htmlFor="admin"
                id="INSTRUCTIONS.ROLE.ADMIN"
              >
                User can manage all the data, create new team members and
                accounts
              </FormattedMessage>
            </div>
          </Box>
          <Box>
            <Radio content={WRITER} id="writer" name="role" />
            <div>
              <FormattedMessage
                capitalize
                component={Label}
                htmlFor="writer"
                id="ROLE.WRITER"
              >
                Writer
              </FormattedMessage>
              <FormattedMessage
                capitalize
                component={Paragraph}
                htmlFor="writer"
                id="INSTRUCTIONS.ROLE.WRITER"
              >
                User can read all the account data and see marketing campaigns
                results
              </FormattedMessage>
            </div>
          </Box>
          <Box>
            <Radio content={READER} id="reader" name="role" />
            <div>
              <FormattedMessage
                capitalize
                component={Label}
                htmlFor="reader"
                id="ROLE.READER"
              >
                Reader
              </FormattedMessage>
              <FormattedMessage
                capitalize
                component={Paragraph}
                htmlFor="reader"
                id="INSTRUCTIONS.ROLE.READER"
              >
                User can read all the dashboard data and his profile information
              </FormattedMessage>
            </div>
          </Box>
        </Roles>
        <Actions>
          <Button capitalize primary translate type="submit">
            ACTIONS.SAVE_CHANGES
          </Button>
        </Actions>
      </Form>
    </div>
  );
};

EditRole.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    images: PropTypes.shape({ large: PropTypes.string }),
    lastName: PropTypes.string,
    role: PropTypes.string,
    status: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
  setStatus: PropTypes.func,
};

export default styled(EditRole)`
  display: flex;
  flex-wrap: wrap;

  ${Form} {
    margin: 1.2rem auto 0;

    ${Profile} {
      display: flex;
      flex-direction: row;
      padding: 1.6rem 0 2.8rem;

      ${Avatar} {
        border: 0.8rem solid #fff;
        box-shadow: 0 0.2rem 0.8rem rgba(0,15,25,0.05);
        margin-right: 2.4rem;
        min-width: 13.6rem;

      }
      ${MemberInfo} {
        align-items: start;
        display: flex;
        flex-direction: column;
        justify-content: start;
        margin-top: 2rem;

        ${MemberName} {
          ${theme('--font-medium')}
          ${theme('--font-weight-demi')}
          margin-bottom: 0.8rem;
        }

        ${Email} {
          ${theme('--font-medium')}
          ${theme('--font-opacity-40')}
          ${theme('--font-weight-book')}
        }
      }
    }

    ${Box} {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      margin-bottom: 0.8rem;
      
      ${Radio} {
        margin-right: 0.8rem;
      }

      ${Label}, span {
        ${theme('--font-small')}
        ${theme('--font-opacity-60')}
        ${theme('--font-weight-book')}
        display: block;
        margin-bottom: 0.4rem;
      }
      ${Paragraph} {
        ${theme('--font-small')}
        ${theme('--font-opacity-40')}
        ${theme('--font-weight-book')}
        margin-bottom: 0.8rem;
      }
    }

    ${Role} {
      ${theme('--font-small')}
      ${theme('--font-opacity-40')}
      margin-bottom: 0.8rem;
    }

    ${Title} {
      ${theme('--font-medium')}
      ${theme('--font-weight-medium')}
      margin-bottom: 1.2rem;
    }

    ${Paragraph} {
      ${theme('--font-medium')}
      ${theme('--font-opacity-40')}
      ${theme('--font-weight-book')}
      margin-bottom: 0.8rem;
    }

    ${Radio} {
      margin-bottom: 1.6rem;
    }

    ${Actions} {
      display: flex;
      justify-content: space-between;
      margin-top: 4rem;

      ${Button} {
        width: 100%;
      }
    }
  }

  @media (${theme('--level-2')}) {
    ${Form} {
      margin: 0.8rem auto 0;
      max-width: 32rem;

      ${Title} {
        margin-bottom: 0.8rem;
      }

      ${Profile} {
        padding: 2.4rem 0 2.8rem;
      }

    }
  }

  @media (${theme('--column-12')}) {
    ${Form} ${Actions} ${Button} {
      max-width: 15rem;
    }
  }
  
`;
