import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate } from '@react-intl';
import styled, { theme } from '@styled-components';

import Chip from 'Components/Chip';
import Icon from 'Components/Icon';
import Link from 'Components/Link';
import { PlaceholderNoData as Placeholder } from 'Containers/Placeholder';
import { Row } from 'Components/ResponsiveTable';

const Name = styled(Placeholder)``;
const CustomerEnrolled = styled(Placeholder)``;
const Type = styled(Placeholder)``;
const Platform = styled(Placeholder)``;
const Launched = styled(Placeholder)``;
const Status = styled(Placeholder)``;

const Campaign = ({
  className,
  empty,
  id,
  name,
  launchDate,
  platform: constant = '',
  status,
  totalCustomers,
  totalSuccess,
  type,
}) => {
  const platform = constant.toLowerCase();

  return (
    <Link as={Row} className={className} empty={empty} to={`campaigns/${id}`}>
      <Name placeholder="campaign name">{name}</Name>
      <CustomerEnrolled placeholder="Customer Enrolled">
        {totalSuccess}/{totalCustomers}
      </CustomerEnrolled>
      <Platform placeholder="platform">
        <Icon name={platform}>{constant}</Icon>
      </Platform>
      <Type placeholder="type">{type}</Type>
      <Launched placeholder="date">
        <FormattedDate
          day="2-digit"
          month="long"
          value={new Date(Number(launchDate))}
          year="numeric"
        />
      </Launched>
      <Status placeholder="status">
        <Chip>{status}</Chip>
      </Status>
    </Link>
  );
};

Campaign.propTypes = {
  className: PropTypes.string,
  empty: PropTypes.bool,
  id: PropTypes.number,
  launchDate: PropTypes.string,
  name: PropTypes.string,
  platform: PropTypes.string,
  status: PropTypes.string,
  totalCustomers: PropTypes.string,
  totalSuccess: PropTypes.string,
  type: PropTypes.string,
};

export default styled(Campaign)`
  align-items: center;
  cursor: pointer;
  display: grid;
  grid-template: repeat(3, 1fr) / 1fr auto;
  padding: 1.2rem 2.4rem;

  &:hover {
    background: ${theme('--color-primary-5')};
  }

  ${Type}, ${Launched} {
    ${theme('--font-small')}
    ${theme('--font-opacity-40')}
    ${theme('--font-weight-book')}
    grid-column: 1;
  }

  ${Name} {
    ${theme('--font-medium')}
    ${theme('--font-weight-medium')}
    color: ${theme('--color-primary')};
    grid-column: 1;
    grid-row: 1;
  }

  ${Type} {
    grid-row: 2;
  }

  ${Launched} {
    grid-row: 3;
  }

  ${Platform} {
    grid-column: 2;
    grid-row: 1;
    text-align: right;

    ${Icon} {
      height: 1.6rem;
      width: 1.6rem;
    }
  }

  ${Status} {
    align-self: flex-end;
    grid-column: 2;
    grid-row: span 2;

    ${Chip} {
      ${theme('--font-weight-demi')}
      background: ${theme('--color-main-20')};
      border: none;
      border-radius: 0.6rem;
      color: ${theme('--color-main')};
    }
  }
`;
