import React from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';

import ViewHeadline from 'Components/ViewHeadline';

import Detail from './components/Details';
import Questions from './components/Questions';
import FreeResponses from './components/FreeResponses';
import Customers from './components/Customers';

const Survey = ({
  account,
  className,
  data: survey = {},
  dates,
  handleDateChange,
  surveyId,
}) => {
  return (
    <section className={className}>
      <ViewHeadline
        heading="INSTRUCTIONS.CAMPAIGN.PRELAUNCH"
        title="INSTRUCTIONS.CAMPAIGN"
        values={{ name: survey.name }}
      />
      <Detail {...survey} />
      <Questions
        account={account}
        handleDateChange={handleDateChange}
        surveyId={surveyId}
        {...dates}
        {...survey}
      />
      {survey.freeResponses && survey.freeResponses.length !== 0 && (
        <FreeResponses freeResponses={survey.freeResponses} />
      )}
      <Customers account={account} surveyId={surveyId} {...dates} />
    </section>
  );
};

Survey.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  dates: PropTypes.object,
  handleDateChange: PropTypes.func,
  surveyId: PropTypes.number,
};

export default styled(Survey)`
  display: grid;
  grid-gap: 3.2rem;
  grid-template: auto / auto;

  @media (${theme('--level-3')}) {
    margin: 0 auto;
    max-width: 160rem;
  }
`;
