import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate } from '@react-intl';
import styled, { theme } from '@styled-components';

import Currency from 'Components/Currency';

import { Row } from 'Components/ResponsiveTable';
import Placeholder from 'Containers/Placeholder';

const Wrapper = styled('div')``;
const Dates = styled(Placeholder)``;
const Sales = styled(Placeholder)``;

const Revenues = ({ className, currency, date, empty, sales }) => {
  return (
    <Row className={className} empty={empty}>
      <Wrapper>
        <Dates placeholder="revenue period">
          <FormattedDate
            day="2-digit"
            month="long"
            value={date}
            year="numeric"
          />
        </Dates>

        <Sales placeholder="period sales">
          <Currency currency={currency}>{sales}</Currency>
        </Sales>
      </Wrapper>
    </Row>
  );
};

Revenues.propTypes = {
  className: PropTypes.string,
  currency: PropTypes.string,
  date: PropTypes.string,
  empty: PropTypes.bool,
  sales: PropTypes.number,
};

export default styled(Revenues)`
  border: none;
  border-radius: 0;
  padding: 0 2.4rem;

  &:focus {
    border-radius: 0;
  }

  &:hover {
    background: ${theme('--color-primary-5')};
  }

  ${Wrapper} {
    align-items: center;
    box-shadow: inset 0 -0.1rem 0 rgba(0, 15, 25, 0.03);
    display: flex;
    height: 8rem;
    justify-content: space-between;
    padding: 2rem 0;
  }

  ${Dates} {
    ${theme('--font-medium')}
    ${theme('--font-weight-medium')}
  }

  ${Sales} {
    ${theme('--font-small')}
    ${theme('--font-opacity-40')}
    ${theme('--font-weight-book')}
  }
`;
