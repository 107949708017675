import React from 'react';
import PropTypes from '@prop-types';
import styled, { theme } from '@styled-components';
import Icon from 'Components/Icon';
import { FormattedMessage } from '@react-intl';
import Link from 'Components/Link';
import withPermissions from 'Components/with/permissions';

import C from 'Constants';

const {
  ROLE: { ADMIN, SUPER_ADMIN },
} = C;

const Title = styled('h2')``;
const Content = withPermissions([SUPER_ADMIN, ADMIN])(styled('p')``);
const Actions = styled('nav')``;
const Button = withPermissions([SUPER_ADMIN, ADMIN])(styled(Link)``);

const EmptyTemplate = ({ className }) => (
  <article className={className}>
    <Icon name="customers-empty">CUSTOMERS_EMPTY</Icon>
    <FormattedMessage
      capitalize
      component={Title}
      id="EMPTY_CUSTOMERS.ADD_CUSTOMERS"
    >
      Oops! we did not find any customer yet
    </FormattedMessage>
    <FormattedMessage
      capitalize
      component={Content}
      id="INSTRUCTIONS.EMPTY_CUSTOMERS.ADD_CUSTOMERS"
    >
      You can add customers and they would be listed here
    </FormattedMessage>
    <Actions>
      <FormattedMessage
        capitalize
        component={Button}
        id="ACTIONS.ADD_CUSTOMER"
        to="/customers/add"
      >
        add customer
      </FormattedMessage>
    </Actions>
  </article>
);

EmptyTemplate.propTypes = {
  className: PropTypes.string,
};

export default styled(EmptyTemplate)`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  min-height: 40rem;

  ${Icon} {
    height: 10.4rem;
    margin-bottom: 2.4rem;
    width: 10.4rem;
  }

  ${Title} {
    ${theme('--font-large')}
    ${theme('--font-weight-book')}
    ${theme('--font-opacity-100')}
    margin-bottom: 0.4rem;
  }

  ${Content} {
    ${theme('--font-medium')}
    ${theme('--font-weight-book')}
    ${theme('--font-opacity-40')}
    margin-bottom: 2.4rem;
  }

  ${Actions} {
    display: flex;

    ${Button} {
      ${theme('--font-medium')}
      ${theme('--font-weight-demi')}
      align-items: center;
      background-color: ${theme('--color-primary')};
      border-radius: 0.6rem;
      color: ${theme('--color-light')};
      display: flex;
      height: 4rem;
      justify-content: center;
      margin: 0 0.8rem;
      width: 12.4rem;
    }
  }
`;
