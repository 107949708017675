import React from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from '@prop-types';
import { FormattedMessage } from '@react-intl';

import Card from 'Components/RadioCard';

import AddMedia from './AddMedia';
import AddSingleMedia from './AddSingleMedia';

const Headline = styled('h3')``;
const Wrapper = styled('div')``;
const SectionTitle = styled('h5')``;
const Carousel = styled('article')``;

const CarouselPicker = ({ className, titleId = 'CAROUSEL', single }) => {
  return (
    <div className={className}>
      <FormattedMessage capitalize component={Headline} id={titleId} />
      <Wrapper>
        <FormattedMessage capitalize component={SectionTitle} id="PICK_RATIO" />
        <Carousel>
          <Card hint="(1:1)" id="square" name="ratio" />
          <Card hint="(1:91)" id="portrait" name="ratio" />
        </Carousel>
        <FormattedMessage
          capitalize
          component={SectionTitle}
          id={single ? 'INSTRUCTIONS.ADD_IMAGE_OR_VIDEO' : 'ADD_MEDIA'}
        />
        {single ? <AddSingleMedia /> : <AddMedia />}
      </Wrapper>
    </div>
  );
};

CarouselPicker.propTypes = {
  className: PropTypes.string,
  single: PropTypes.bool,
  titleId: PropTypes.string,
};

export default styled(CarouselPicker)`
  ${Headline} {
    ${theme('--font-medium')}
    ${theme('--font-weight-demi')}
    color: ${theme('--color-dark-night')};
    padding: 4rem 0 1.6rem 0;
  }

  ${Wrapper} {
    background: ${theme('--color-light')};
    border-radius: 0.6rem;
    padding: 1.6rem;
    position: relative;
    z-index: 2;

    ${SectionTitle}{
      ${theme('--font-medium')}
      ${theme('--font-weight-medium')}
      color: ${theme('--color-dark-night-80')};
    }

    ${Carousel}{
      display: grid;
      grid-gap: 1.6rem;
      grid-template-columns: 1fr 1fr;
      margin-bottom: 2.4rem;
      padding: 0.8rem 0;

      ${Card}{
        height: 17rem;
        width: 100%;
      }
    }
  }

  @media (${theme('--screen-small')}) {
    ${Wrapper} {
      ${Carousel}{
        ${Card}{
          height: 24rem;
        }
      }
    }
  }
`;
