import React from 'react';
import PropTypes from '@prop-types';
import { FormattedMessage } from '@react-intl';
import styled, { theme } from '@styled-components';

import { Consumer } from 'Containers/DataProvider';
import LoadingBar from 'Components/LoadingBar';
import EmptyBar from 'Components/EmptyBar';

const NoData = styled('span')`
  ${theme('--font-opacity-20')}
`;

const checkIsHtml = Element => {
  if (typeof Element === 'string') return true;
  return false;
};

const filterProps = (Element, { forwardProps, ...props }, context) => {
  const isHtml = checkIsHtml(Element);

  const mergedProps = { ...props, ...context };

  if (isHtml && !forwardProps) return {};
  if (!forwardProps) return mergedProps;

  return Object.entries(mergedProps).reduce((acc, [key, value]) => {
    return forwardProps.includes(key) ? { ...acc, [key]: value } : acc;
  }, {});
};

export const withFetchingState = Component => {
  return props => (
    <Consumer>
      {({ loading, empty }) => (
        <Component {...props} empty={empty} loading={loading} />
      )}
    </Consumer>
  );
};

export const withPlaceholder = Element => {
  const Placeholder = ({
    className,
    placeholder,
    noEmptyState,
    children,
    fill,
    loader: Loader,
    emptyTemplate: Empty,
    ...props
  }) => {
    return (
      <Consumer>
        {context => {
          const propPack = filterProps(Element, props, context);

          if (context.loading) {
            return (
              <Loader
                className={className}
                fill={fill}
                loading={context.loading}
                {...props}
              >
                {placeholder}
              </Loader>
            );
          }

          if ((placeholder || fill) && context.empty && !noEmptyState) {
            return (
              <Empty
                className={className}
                empty={context.empty}
                fill={fill}
                loading={context.loading}
                {...props}
              >
                {placeholder}
              </Empty>
            );
          }

          return (
            <Element className={className} {...propPack}>
              {children}
            </Element>
          );
        }}
      </Consumer>
    );
  };

  Placeholder.propTypes = {
    accent: PropTypes.bool,
    children: PropTypes.children,
    className: PropTypes.string,
    emptyTemplate: PropTypes.component,
    fill: PropTypes.bool,
    loader: PropTypes.component,
    noEmptyState: PropTypes.bool,
    placeholder: PropTypes.string,
  };

  Placeholder.defaultProps = {
    emptyTemplate: EmptyBar,
    loader: LoadingBar,
  };

  return Placeholder;
};

export const withPlaceholderNoData = Element => {
  const Placeholder = ({
    className,
    placeholder,
    noEmptyState,
    children,
    fill,
    loader: Loader,
    emptyTemplate: Empty,
    ...props
  }) => {
    return (
      <Consumer>
        {context => {
          const propPack = filterProps(Element, props, context);
          if (context.loading) {
            return (
              <Loader
                className={className}
                fill={fill}
                loading={context.loading}
                {...props}
              >
                {placeholder}
              </Loader>
            );
          }

          if ((placeholder || fill) && context.empty && !noEmptyState) {
            return (
              <Empty
                className={className}
                empty={context.empty}
                fill={fill}
                loading={context.loading}
                {...props}
              >
                {placeholder}
              </Empty>
            );
          }

          if (placeholder && !children) {
            return (
              <Element className={className} {...propPack}>
                <FormattedMessage capitalize component={NoData} id="NO_DATA">
                  No data
                </FormattedMessage>
              </Element>
            );
          }

          return (
            <Element className={className} {...propPack}>
              {children}
            </Element>
          );
        }}
      </Consumer>
    );
  };

  Placeholder.propTypes = {
    accent: PropTypes.bool,
    children: PropTypes.children,
    className: PropTypes.string,
    emptyTemplate: PropTypes.component,
    fill: PropTypes.bool,
    loader: PropTypes.component,
    noEmptyState: PropTypes.bool,
    placeholder: PropTypes.string,
  };

  Placeholder.defaultProps = {
    emptyTemplate: EmptyBar,
    loader: LoadingBar,
  };

  return Placeholder;
};

const Default = withPlaceholder('span');
Default.a = withPlaceholder('a');
Default.article = withPlaceholder('article');
Default.section = withPlaceholder('section');
Default.h1 = withPlaceholder('h1');
Default.h2 = withPlaceholder('h2');
Default.h3 = withPlaceholder('h3');
Default.h4 = withPlaceholder('h4');
Default.h5 = withPlaceholder('h5');
Default.p = withPlaceholder('p');
Default.pre = withPlaceholder('pre');
Default.ul = withPlaceholder('ul');
Default.img = withPlaceholder('img');
Default.div = withPlaceholder('div');

const DefaultNoData = withPlaceholderNoData('span');
DefaultNoData.a = withPlaceholder('a');
DefaultNoData.article = withPlaceholder('article');
DefaultNoData.section = withPlaceholder('section');
DefaultNoData.h1 = withPlaceholder('h1');
DefaultNoData.h2 = withPlaceholder('h2');
DefaultNoData.h3 = withPlaceholder('h3');
DefaultNoData.h4 = withPlaceholder('h4');
DefaultNoData.h5 = withPlaceholder('h5');
DefaultNoData.p = withPlaceholder('p');
DefaultNoData.ul = withPlaceholder('ul');
DefaultNoData.img = withPlaceholder('img');
DefaultNoData.div = withPlaceholder('div');

export { DefaultNoData as PlaceholderNoData };

export default Default;
