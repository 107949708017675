import debug from 'debug';
import * as Sentry from '@sentry/browser';

Sentry.init({
  dsn: process.env.DSN,
  environment: process.env.BRANCH,
  integrations: [
    new Sentry.Integrations.Breadcrumbs({
      console: false,
      dom: true,
      location: true,
      sentry: true,
      xhr: true,
    }),
  ],
  logger: 'mark13',
  release: process.env.COMMIT,
  sampleRate: 1,
  tags: {
    locale: navigator.language,
  },
});

export default {
  /*
    Action must be taken immediately.

    Example: Entire website down, database unavailable, etc. This should
    trigger the SMS alerts and wake you up.
   */
  alert(message, config = {}) {
    this.log(message, { ...config, level: 'alert' });
  },

  context(ctx) {
    Sentry.configureScope(scope => {
      scope.setUser(ctx);
    });
  },

  /*
    Critical conditions.

    Example: Application component unavailable, unexpected exception.
   */
  critical(message, config = {}) {
    this.log(message, { ...config, level: 'critical' });
  },

  /* Detailed debug information. */
  debug(message, config = {}) {
    debug('mark13:debug')(message, { ...config, level: 'debug' });
  },

  /* System is unusable. */
  emergency(message, config = {}) {
    this.log(message, { ...config, level: 'emergency' });
  },

  /*
    Runtime errors that do not require immediate action but should typically
    be logged and monitored.
   */
  error(message, config) {
    this.log(message, { ...config, level: 'error' });
  },

  /*
    Interesting events.

    Example: User logs in, SQL logs.
   */
  info(message, config = {}) {
    debug('mark13:info')(message, { ...config, level: 'info' });
  },

  /* Logs with an arbitrary level. */
  log(message, config = {}) {
    debug(`mark13:${config.level || 'log'}`)(message, config);
    Sentry.withScope(scope => {
      scope.setExtra('config', config);
      scope.setLevel(config.level);
      Sentry.captureException(message);
    });
  },

  /* Normal but significant events. */
  notice(message, config = {}) {
    debug('mark13:info')(message, { ...config, level: 'notice' });
  },

  /*
    Exceptional occurrences that are not errors.

    Example: Use of deprecated APIs, poor use of an API, undesirable things
    that are not necessarily wrong.
   */
  warning(message, config = {}) {
    this.log(message, { ...config, level: 'warning' });
  },
};
