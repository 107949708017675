import React, { useEffect, useRef, useState } from 'react';
import PropTypes from '@prop-types';
import styled, { theme } from '@styled-components';

const PADDING = 24;
const HEIGHT = 32;

const Tooltip = ({
  className,
  children,
  height = HEIGHT,
  index,
  length,
  ...props
}) => {
  const tooltip = useRef();
  const [width, setWidth] = useState();

  useEffect(() => {
    const { width: w } = tooltip.current.getBBox();
    setWidth(w);
  }, [tooltip, setWidth]);

  return (
    <g className={className} {...props}>
      <rect
        height={height + PADDING / 2}
        rx={4}
        width={width + PADDING}
        x={
          width &&
          `calc(100% / ${length} * ${index} + ((100% / ${length} - ${width}px) / 2) - ${PADDING /
            2}px)`
        }
        y={`calc(-1 * (50% + ${height / 2}px + ${PADDING / 3}px))`}
      />
      <text
        ref={tooltip}
        height={height}
        style={{
          transform: `translate(${width &&
            `calc(100% / ${length} * ${index} + ((100% / ${length} - ${width}px) / 2))`}, ${`calc(-50%)`})`,
        }}
      >
        {children}
      </text>
    </g>
  );
};

Tooltip.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  height: PropTypes.number,
  index: PropTypes.number,
  length: PropTypes.number,
};

export default styled(Tooltip)`
  opacity: ${props => props.opacity};
  pointer-events: none;
  transition: opacity 250ms ease-out;

  rect {
    fill: ${theme('--color-dark-night')};
  }

  text {
    fill: ${theme('--color-light')};
  }
`;
