import React from 'react';
import PropTypes from '@prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles as css, theme } from '@styled-components';

import Card from 'Components/Card';

import DataProvider from 'Containers/DataProvider';
import service from 'Services/customers';

import { currentAccount } from 'Selectors';

import Template from './Template';
import EmptyTemplate from './EmptyTemplate';

const mapStateToProps = state => ({
  account: currentAccount(state),
});

const PlatformChart = ({ className, account, id }) => (
  <Card className={className} title="USAGE_PER_PLATFORM">
    <DataProvider
      emptyTemplate={EmptyTemplate}
      params={{ account, id }}
      query={service.getPlatformUsageByUser}
      template={Template}
    />
  </Card>
);

PlatformChart.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
  id: PropTypes.number,
};

export default compose(
  connect(mapStateToProps),
  css`
    display: grid;
    grid-column: span 1;
    grid-row: span 1;
    grid-template: auto / 1fr;
    height: 42rem;

    @media (${theme('--screen-small')}) {
      height: 100%;
    }
  `,
)(PlatformChart);
