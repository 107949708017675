import React from 'react';
import PropTypes from '@prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import Modal from 'Components/Modal';

import * as logger from 'Actions/logger';

import DataProvider from 'Containers/DataProvider/index';

import { currentAccount } from 'Selectors';
import service from 'Services/faqs';

import View from './ImportFaq';

const mapStateToProps = state => ({
  account: currentAccount(state),
});

const ImportFaq = ({
  account,
  error,
  success,
  depth,
  in: modalIn,
  onBack,
  onClose,
  order,
}) => {
  return (
    <Modal
      depth={depth}
      in={modalIn}
      onBack={onBack}
      onClose={onClose}
      order={order}
      title="IMPORT_FAQS"
    >
      <DataProvider
        account={account}
        error={error}
        onClose={onClose}
        params={{ account }}
        query={service.locales}
        success={success}
        template={View}
      />
    </Modal>
  );
};

ImportFaq.propTypes = {
  account: PropTypes.string,
  depth: PropTypes.number,
  error: PropTypes.func,
  in: PropTypes.bool,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  order: PropTypes.number,
  success: PropTypes.func,
};

export default compose(
  connect(mapStateToProps, { error: logger.error, success: logger.success }),
)(ImportFaq);
