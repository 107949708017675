import React from 'react';
import PropTypes from '@prop-types';
import styled, { theme } from '@styled-components';
import { FormattedMessage } from '@react-intl';

import Address from 'Components/Address';
import { withPlaceholder } from 'Containers/Placeholder';

import EmptyTemplate from './EmptyTemplate';

const Title = styled('h4')``;
const Placeholder = styled(withPlaceholder('section')).attrs({
  placeholder: 'address placeholder',
})``;

const Template = ({ className, address }) => {
  const hasAddress = Object.keys(address).length;

  return (
    <section className={className}>
      <FormattedMessage capitalize component={Title} id="ADDRESS">
        address
      </FormattedMessage>
      <Placeholder>
        {hasAddress ? <Address {...address} /> : <EmptyTemplate />}
      </Placeholder>
    </section>
  );
};

Template.propTypes = {
  address: PropTypes.object,
  className: PropTypes.string,
};

export default styled(Template)`
  ${Title} {
    ${theme('--font-large')}
    ${theme('--font-weight-demi')}
    ${theme('--font-opacity-80')}
    margin-bottom: 0.8rem;
  }

  ${Address} {
    ${theme('--font-large')}
    ${theme('--font-weight-book')}
    ${theme('--font-opacity-40')}
  }
`;
