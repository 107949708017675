import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'spooner';
import styled, { theme } from '@styled-components';

import Icon from 'Components/Icon';

const Display = styled('div')``;
const SelectHTML = styled('select')``;
const Value = styled('output')``;

export const Select = ({ className, options = [{}], ...props }) => {
  const {
    disabled = false,
    name,
    onBlur = () => {},
    onChange = () => {},
    onFocus = () => {},
    value,
    ...fieldProps
  } = useField(props);

  const handleBlur = () => {
    onBlur();
  };

  const handleChange = event => {
    const { value: needle } = event.target;

    event.stopPropagation();

    const after =
      options.find(({ name: option }) => option === needle) || options[0];

    onChange(after.value);
  };

  const handleFocus = () => {
    onFocus();
  };

  useEffect(() => {
    if (value === undefined) {
      const after = (options[0] || {}).value;

      onChange(after);
    }
  }, [onChange, options, value]);

  const display = (options.find(({ value: option }) => option === value) || {})
    .name;

  return (
    <div
      className={className}
      data-disabled={disabled}
      data-name={name}
      data-value={value}
    >
      <Display>
        <Value>{display}</Value>
        <Icon name="vertical-arrows">ACTIONS.SELECT_OPTION</Icon>
      </Display>
      <SelectHTML
        {...fieldProps}
        disabled={disabled}
        name={name}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        value={display}
      >
        {options.map(({ name: option, value: ignore, ...optionProps }) => (
          <option key={option} {...optionProps}>
            {option}
          </option>
        ))}
      </SelectHTML>
    </div>
  );
};

Select.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      name: PropTypes.string,
      value: PropTypes.any,
    }),
  ),
  value: PropTypes.any,
};

export default styled(Select)`
  ${theme('--font-medium')}
  position: relative;

  &:focus-within {
    ${Display} {
      border-color: ${theme('--color-primary')};
    }
  }

  ${Display} {
    align-items: center;
    background: ${theme('--color-light')};
    border: 0.1rem solid ${theme('--color-dark-night-10')};
    border-radius: 0.6rem;
    color: ${theme('--color-dark-night-80')};
    display: flex;
    height: 4.8rem;
    justify-content: space-between;
    padding: 0.8rem;
    position: relative;
    transition: border-color 250ms ease-out;
    width: 100%;

    ${Value} {
      flex: 1;
      padding: 0.8rem;
    }

    ${Icon} {
      flex-shrink: 0;
    }
  }

  ${SelectHTML} {
    border: none;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;

    &:focus {
      outline: 0;
    }
  }
`;
