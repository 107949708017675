import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from '@styled-components';

import Icon from 'Components/Icon';

import Control from './Control';
import Pages from './Pages';

const INITIAL = 0;

const Controls = ({
  className,
  cursor,
  onChange = () => {},
  pages = 1,
  total,
}) => {
  const first = useCallback(() => {
    onChange(INITIAL);
  }, [onChange]);

  const last = useCallback(() => {
    onChange(total - 1);
  }, [onChange, total]);

  const next = useCallback(() => {
    onChange(Math.min(cursor + 1, total - 1));
  }, [cursor, onChange, total]);

  const previous = useCallback(() => {
    onChange(Math.max(cursor - 1, INITIAL));
  }, [cursor, onChange]);

  const handlePageChange = useCallback(
    page => {
      onChange(page - 1);
    },
    [onChange],
  );

  const isFirst = cursor === INITIAL;
  const isLast = cursor === total - 1;

  const currentPage = cursor + 1;
  const initialPage = Math.max(
    INITIAL + 1,
    Math.min(total - pages + 1, cursor - Math.floor(pages / 2) + 1),
  );

  return (
    <ul className={className} role="navigation">
      <Control disabled={isFirst} onClick={first}>
        <Icon name="chevronfirst">FIRST</Icon>
      </Control>
      <Control disabled={isFirst} onClick={previous}>
        <Icon name="chevronleft">PREVIOUS</Icon>
      </Control>

      <Pages
        current={currentPage}
        from={initialPage}
        onChange={handlePageChange}
        to={initialPage + Math.min(pages, total)}
      />

      <Control disabled={isLast} onClick={next}>
        <Icon name="chevronright">NEXT</Icon>
      </Control>
      <Control disabled={isLast} onClick={last}>
        <Icon name="chevronlast">LAST</Icon>
      </Control>
    </ul>
  );
};

Controls.propTypes = {
  className: PropTypes.string,
  cursor: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  pages: PropTypes.number,
  total: PropTypes.number.isRequired,
};

export default styled(Controls)`
  display: flex;
  height: 100%;
  list-style: none;

  ${Control} {
    margin: 0 0.4rem;
    ${Icon} {
      display: inline-block;
    }
  }
`;
