import React from 'react';
import PropTypes from 'prop-types';
import styled from '@styled-components';

import { ContextMenu } from 'Components/ContextMenu';

const Options = ({
  className,
  onDelete: handleDelete = () => {},
  onEdit: handleEdit = () => {},
  onGetLink: handleGetLink = () => {},
}) => (
  <ContextMenu
    right
    className={className}
    options={{
      'ACTIONS.EDIT': handleEdit,
      'ACTIONS.GET_LINK': handleGetLink,
      // eslint-disable-next-line sort-keys
      'ACTIONS.DELETE': handleDelete,
    }}
  />
);

Options.propTypes = {
  className: PropTypes.string,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onGetLink: PropTypes.func,
};

export default styled(Options)``;
