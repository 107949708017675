import React from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';

const Head = ({ className, template: Template, ...props }) => (
  <Template className={className} {...props} />
);

Head.propTypes = {
  className: PropTypes.string,
  template: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default styled(Head)`
  border-bottom: 0.1rem solid ${theme('--color-dark-night-10')};

  &:after {
    display: none !important;
  }
`;
