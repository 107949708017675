import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlDefault, IntlProvider, intlShape, withIntl } from '@react-intl';

const intlErrorHandler = ({ message }) => {
  if (process.env.NODE_ENV === 'production') return;

  const isMissingTranslation =
    message.includes('Missing message') ||
    message.includes('Cannot format message');

  if (isMissingTranslation) {
    const [, code] = message.match(/"([^"]+)"./);

    if (code !== code.toUpperCase() || code.includes('/')) {
      return;
    }
  }

  console.error(message); //eslint-disable-line
};

export class L10n extends Component {
  static load(file) {
    return import(`Translations/domains/${file}.json`).catch(() => ({}));
  }

  static async fetch(domain) {
    return {
      ...(await L10n.load('retail')),
      ...(await L10n.load(domain.toLowerCase())),
    };
  }

  static merge(domain, messages) {
    return Object.keys(domain).reduce(
      (dictionary, key) => ({
        ...dictionary,
        [key]: messages[domain[key]],
      }),
      messages,
    );
  }

  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    domain: PropTypes.string,
    // Indeed is used
    // eslint-disable-next-line react/no-unused-prop-types
    intl: intlShape,
  };

  static defaultProps = {
    domain: 'retail',
    intl: intlDefault,
  };

  state = {};

  componentDidMount() {
    this.getMessages();
  }

  componentDidUpdate(prevProps) {
    const { domain } = this.props;

    if (domain !== prevProps.domain) {
      this.getMessages();
    }
  }

  async getMessages() {
    const {
      domain,
      intl: { messages = {} },
    } = this.props;

    const keys = await L10n.fetch(domain);

    this.setState({ domain, messages: L10n.merge(keys, messages) });
  }

  render() {
    const {
      children,
      intl: { locale },
    } = this.props;
    const { domain, messages } = this.state;

    if (!messages) {
      return null;
    }

    return (
      <IntlProvider
        key={domain}
        locale={locale}
        messages={messages}
        onError={intlErrorHandler}
      >
        {children}
      </IntlProvider>
    );
  }
}

export default withIntl(L10n);
