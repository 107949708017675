import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormattedMessage,
  intlDefault,
  intlShape,
  withIntl,
} from '@react-intl';
import { useField } from 'spooner';
import styled, {
  keyframes,
  theme,
  withStyles as css,
} from '@styled-components';
import { v4 as uuid } from 'uuid';
import { compose } from 'underscore';

const autofilling = keyframes``;

const Error = styled('span')``;
const Label = styled('label')``;
const InputHTML = styled('input')``;

const Input = ({
  className,
  intl = intlDefault,
  label,
  placeholder,
  ...props
}) => {
  const prevId = useRef(uuid());
  const {
    disabled = false,
    error,
    id = prevId.current,
    name,
    onBlur = () => {},
    onChange = () => {},
    onFileSelect = () => {},
    onFocus = () => {},
    type = 'text',
    value = '',
    capitalize,
    ...fieldProps
  } = useField(props);
  const [autofill] = useState(false);

  const handleBlur = () => {
    onBlur();
  };

  const handleChange = event => {
    const { value: rvalue } = event.target;

    if (type === 'file') onFileSelect(event);

    event.stopPropagation();

    onChange(rvalue);
  };

  const handleFocus = () => {
    onFocus();
  };

  return (
    <div
      className={className}
      data-autofill={autofill}
      data-disabled={disabled}
      data-error={!!error}
      data-name={name}
      data-value={value}
    >
      <InputHTML
        {...fieldProps}
        disabled={disabled}
        id={id}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        placeholder={
          label !== undefined &&
          placeholder &&
          intl.formatMessage({ capitalize: true, id: placeholder })
        }
        type={type}
        value={value}
      />
      {(label || placeholder) && (
        <FormattedMessage
          capitalize
          component={Label}
          htmlFor={id}
          id={label || placeholder}
        >
          {label || placeholder}
        </FormattedMessage>
      )}
      {error && (
        <FormattedMessage capitalize component={Error} id={`ERRORS.${error}`}>
          {error}
        </FormattedMessage>
      )}
    </div>
  );
};

Input.propTypes = {
  className: PropTypes.string,
  intl: intlShape,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

export default compose(
  css`
    ${theme('--font-opacity-100')}
    height: 4.8rem;
    outline: 0;
    position: relative;
    transition: height 350ms ease-out;

    &:focus-within {
      ${InputHTML} {
        border-color: ${theme('--color-primary')};
      }

      ${Label} {
        color: ${theme('--color-primary')};
        top: 0.8rem;
      }
    }

    &[data-disabled='true'] {
      ${InputHTML}, ${Label} {
        color: ${theme('--color-dark-night-20')};
      }
    }

    &[data-error='true'] {
      height: 7.2rem;

      &:focus-within ${Label} {
        color: ${theme('--color-alert')};
      }

      ${InputHTML} {
        border-color: ${theme('--color-alert')};
        caret-color: ${theme('--color-alert')};
      }
    }

    &[data-autofill='false'] ${InputHTML}[value='']:not([placeholder]):not(:focus) + ${Label} {
      color: ${theme('--color-dark-night-60')};
      transform: translateY(0.8rem) scale(1.16);
    }

    ${InputHTML} {
      ${theme('--font-medium')}
      ${theme('--font-opacity-80')}
      -webkit-appearance:none;
      background: ${theme('--color-light')};
      border: 0.1rem solid ${theme('--color-dark-night-10')};
      border-radius: 0.6rem;
      caret-color: ${theme('--color-primary')};
      height: 4.8rem;
      outline: 0;
      padding: ${props => (props.label ? '2.4rem' : '0.8rem')} 1.6rem 0.8rem;
      transition: border-color 250ms ease-out;
      width: 100%;

      &:-webkit-autofill {
        animation-name: ${autofilling};
        -webkit-box-shadow: 0 0 0 100rem ${theme('--color-light')} inset;
        -webkit-text-fill-color: ${theme('--color-dark-night-80')};
      }

      &::placeholder {
        ${theme('--font-opacity-60')}
      }
    }

    ${Label} {
      ${theme('--font-small')}
      color: ${theme('--color-dark-night-40')};
      height: 1.6rem;
      left: 1.6rem;
      pointer-events: none;
      position: absolute;
      top: 0.8rem;
      transform-origin: 0 0;
      transition: color 250ms ease-out, transform 250ms ease-out;
    }

    ${Error} {
      ${theme('--font-small')}
      color: ${theme('--color-alert')};
      display: inline-block;
      height: 1.6rem;
      left: 0;
      margin-top: 0.8rem;
    }
  `,
  withIntl,
)(Input);
