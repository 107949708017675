import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate } from '@react-intl';
import styled, { theme } from '@styled-components';

import { PlaceholderNoData as Placeholder } from 'Containers/Placeholder';
import { Row } from 'Components/ResponsiveTable';

import Button from 'Components/Button';
import Currency from 'Components/Currency';

const Name = styled(Placeholder)``;
const Launched = styled(Placeholder)``;
const Revenue = styled(Placeholder)``;

const Wrapper = styled('div')``;

const Campaign = ({
  className,
  campaign,
  createdAt,
  currency,
  empty,
  id,
  onClick,
  value,
}) => {
  return (
    <Button as={Row} className={className} empty={empty} onClick={onClick(id)}>
      <Wrapper>
        <Name placeholder="campaign name">{campaign}</Name>
        <Launched placeholder="createdAt">
          {createdAt && (
            <FormattedDate
              day="2-digit"
              month="long"
              value={createdAt}
              year="numeric"
            />
          )}
        </Launched>
        <Revenue placeholder="currency">
          {value !== undefined && (
            <Currency currency={currency}>{value}</Currency>
          )}
        </Revenue>
      </Wrapper>
    </Button>
  );
};

Campaign.defaultProps = {
  onClick() {},
};

Campaign.propTypes = {
  campaign: PropTypes.string,
  className: PropTypes.string,
  createdAt: PropTypes.string,
  currency: PropTypes.string,
  empty: PropTypes.bool,
  id: PropTypes.number,
  onClick: PropTypes.func,
  value: PropTypes.number,
};

export default styled(Campaign)`
  border: none;
  border-radius: 0;
  height: 8rem;
  padding: 0 2.4rem;
  width: 100%;

  &:focus {
    border-radius: 0;
  }

  &:hover {
    background: ${theme('--color-primary-5')};
  }

  ${Wrapper} {
    box-shadow: inset 0 -0.1rem 0 rgba(0, 15, 25, 0.03);
    display: grid;
    grid-gap: 0.8rem 0;
    grid-template: repeat(2, 1fr) / auto;
    justify-content: space-between;
    padding: 2rem 0;
  }

  ${Name} {
    ${theme('--font-medium')}
    ${theme('--font-weight-medium')}
    color: ${theme('--color-primary')};
    grid-column: 1;
    grid-row: 1;
    text-align: left;
  }

  ${Launched}, ${Revenue} {
    ${theme('--font-small')}
    ${theme('--font-opacity-40')}
    ${theme('--font-weight-book')}
    grid-row: 2;
    text-align: left;
  }

  ${Launched} {
    grid-column: 1;
  }

  ${Revenue} {
    grid-column: 2;
  }
`;
