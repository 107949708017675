import React, { useState } from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from '@prop-types';
import { FormattedMessage } from '@react-intl';

import { File } from 'Components/Form';
import Icon from 'Components/Icon';

const Wrapper = styled('section')``;
const Title = styled('h4')``;
const Instructions = styled('p')``;
const Filename = styled('div')``;
const FilenameWrapper = styled('div')``;
const FileWrapper = styled('div')``;

const MediaUploader = ({ className }) => {
  const [fileName, setFileName] = useState('');

  const handleChange = file => {
    setFileName(file.name);
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
  };

  return (
    <article className={className}>
      <Wrapper>
        <FormattedMessage
          capitalize
          component={Title}
          id="SELECT_CAMPAIGN_IMAGE"
          values={{ file: 'document' }}
        />
        <FormattedMessage
          capitalize
          component={Instructions}
          id="INSTRUCTIONS.UPLOAD_KNOWLEDGE_BASE"
        />
        <FileWrapper>
          <File accept="application/pdf" name="file" onChange={handleChange} />
          {fileName && (
            <FilenameWrapper>
              <Icon name="file" />
              <Filename>{fileName}</Filename>
            </FilenameWrapper>
          )}
        </FileWrapper>
      </Wrapper>
    </article>
  );
};

MediaUploader.propTypes = {
  className: PropTypes.string,
};

export default styled(MediaUploader)`
  height: 18rem;
  padding: 1.5rem 0;

  ${Wrapper} {
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${Title} {
      ${theme('--font-medium')}
      ${theme('--font-weight-medium')}
      margin-bottom: 2rem;
    }

    ${Instructions} {
      ${theme('--font-small')}
      ${theme('--font-weight-book')}
      color: ${theme('--color-dark-night-60')};
      margin-bottom: 0.8rem;
    }

    ${FileWrapper} {
      display: flex;
      margin-bottom: 5rem;

      ${FilenameWrapper} {
        display: flex;

        ${Icon} {
          height: 40px;
          margin: auto 0 auto 2rem;
        }

        ${Filename} {
          color: ${theme('--color-primary-80')};
          display: flex;
          margin: auto 0 auto 0.5rem;
        }
      }
    }

    ${File} {
      ${theme('--font-medium')}
      ${theme('--font-weight-medium')}
      background: ${theme('--color-light')};
      border: 0.1rem solid ${theme('--color-primary')};
      border-radius: 0.6rem;
      color: ${theme('--color-primary')};
      cursor: pointer;
      padding: 1.2rem;
      text-align: center;
      width: 14.4rem;
    }
  }
`;
