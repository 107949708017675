import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@styled-components';

import { currentAccount } from 'Selectors';

import service from 'Services/audience';

import DataProvider from 'Containers/DataProvider/index';
import WrapperComponent from './UsersByCountry';
import EmptyTemplate from './EmptyTemplate';

const mapStateToProps = state => ({
  account: currentAccount(state),
});

const UsersByCountry = ({ account, className }) => (
  <DataProvider
    className={className}
    emptyTemplate={EmptyTemplate}
    params={{ account }}
    query={service.getUsersByCountry}
    template={WrapperComponent}
  />
);

UsersByCountry.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
};

export default compose(connect(mapStateToProps), withStyles``)(UsersByCountry);
