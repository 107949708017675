const getText = ({ text }) => text;
const wrapText = text => ({ text });
const removeEmpty = arr => arr.filter(val => val && val.length !== 0);
const removeDuplicates = arr => Array.from(new Set(arr));

export const removeDuplicatedQuestions = arr =>
  removeDuplicates(removeEmpty(arr.map(getText))).map(wrapText);

export default {
  removeDuplicatedQuestions,
};
