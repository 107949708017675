import React from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';

import Button from 'Components/Button';
import Icon from 'Components/Icon';

const Avatar = styled('div')``;
const Image = styled('div')`
  background: url('${props => props.url}');
  background-position: center;
  background-size: cover;
  border: 0.1rem solid ${theme('--color-dark-night-10')};
  border-radius: 0.4rem;
  height: 4rem;
  width: 4rem;
`;
const Name = styled('span')``;
const Id = styled('span')``;
const Price = styled('span')``;

const Remove = styled(Icon).attrs({ name: 'close' })`
  opacity: 0.4;
`;

const Product = ({
  className,
  id,
  image,
  name,
  onClick: handleClick = () => {},
  price,
}) => (
  <div className={className}>
    <Avatar>
      <Image url={image} />
      <Name>{name}</Name>
    </Avatar>
    <Id>{id}</Id>
    <Price>{price}</Price>
    <Button onClick={handleClick}>
      <Remove />
    </Button>
  </div>
);

Product.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  image: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  price: PropTypes.string,
};

export default styled(Product)`
  ${theme('--font-small')}
  align-items: center;
  background: ${theme('--color-light')};
  border-radius: 0.6rem;
  display: flex;
  justify-content: space-between;
  padding: 0.8rem 1.2rem;
  z-index: 1;

  ${Avatar} {
    align-items: center;
    display: flex;
    width: 25rem;
  }
  ${Image} {
    height: 4rem;
    margin-right: 1.2rem;
    width: 4rem;
  }

  ${Id}, ${Price} {
    ${theme('--font-opacity-80')}
  }

  ${Button} {
    margin: 0;
  }
`;
