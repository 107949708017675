import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';
import { useHistory } from 'react-router-dom';

import Button from 'Components/Button';
import Card from 'Components/RadioCard';
import Modal from 'Components/Modal';
import ViewHeadline, { Heading, Wrapper } from 'Components/ViewHeadline';

const Carousel = styled('article')``;
const Actions = styled('div')``;

const options = ['multiproducts', 'media', 'signup'];

const AddMicroapp = ({
  className,
  order,
  depth,
  in: modalIn,
  onClose,
  onBack,
}) => {
  const [selected, setSelected] = useState(null);
  const { push } = useHistory();

  const makeSelect = useCallback(option => () => setSelected(option), []);
  const onSubmit = useCallback(() => {
    push(`/builder/${selected}/add`);
  }, [push, selected]);

  return (
    <Modal
      className={className}
      depth={depth}
      in={modalIn}
      onBack={onBack}
      onClose={onClose}
      order={order}
      title="NEW_TEMPLATE"
    >
      <ViewHeadline
        heading="INSTRUCTIONS.MICRO_APP_TEMPLATE"
        title="MICRO_APP_TEMPLATE"
      />
      <Carousel>
        {options.map(option => {
          return (
            <Card
              key={option}
              withDescription
              id={option}
              onSelect={makeSelect(option)}
              selected={selected}
            />
          );
        })}
      </Carousel>
      <Actions>
        <Button
          capitalize
          primary
          translate
          disabled={!selected}
          onClick={onSubmit}
        >
          ACTIONS.NEXT
        </Button>
      </Actions>
    </Modal>
  );
};

AddMicroapp.propTypes = {
  className: PropTypes.string,
  depth: PropTypes.number,
  in: PropTypes.bool,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  order: PropTypes.number,
};

export default styled(AddMicroapp)`
  ${Carousel} {
    display: grid;
    grid-auto-rows: 28rem;
    grid-gap: 2.4rem;
    grid-template-columns: 100%;
    margin: 3.2rem 0;
    margin-bottom: 8.4rem;
  }

  ${Wrapper} {
    ${Heading} {
      ${theme('--font-opacity-60')}
    }
  }

  ${Actions} {
    background: ${theme('--color-blueish-white')};
    border-top: 0.1rem solid ${theme('--color-dark-night-10')};
    bottom: 0;
    left: 0;
    padding: 2rem;
    position: fixed;
    width: 100vw;

    ${Button} {
      width: 100%;
    }
  }

  @media (${theme('--screen-small')}) {
    ${Carousel} {
      grid-gap: 3.2rem;
      grid-template: 28rem / repeat(4, 23.2rem);
      margin-bottom: 4rem;
    }

    ${Actions} {
      border: none;
      padding: 0;
      position: static;
      width: auto;

      ${Button} {
        width: 14.4rem;
      }
    }
  }
`;
