import React, { useEffect, useState } from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';
import { FormattedMessage } from '@react-intl';

import Button from 'Components/Button';
import Card from 'Components/Card';

import Confirmation from './StatusConfirmation';

const Title = styled('h3')``;
const ButtonRed = styled(Button)``;

const Status = ({ className, handleOrderUpdate, orderStatus }) => {
  const [state, setState] = useState({
    confirmation: false,
    question: null,
    status: null,
    statusSelected: null,
  });

  const handleAccept = () => {
    setState({
      confirmation: false,
      status: state.statusSelected,
      statusSelected: null,
    });
    handleOrderUpdate({ status: state.statusSelected });
  };

  const handleCnacel = () => {
    setState({ ...state, confirmation: false, statusSelected: null });
  };

  const handleStateClick = status => () => {
    if (state.status === 'ORDER_SHIPPED' && status === 'ORDER_SHIPPED') {
      setState({
        ...state,
        confirmation: true,
        question: 'ORDER_SHIPPED_RESET',
        statusSelected: 'ORDER_ACCEPTED',
      });
    } else if (
      state.status === 'ORDER_ACCEPTED' &&
      status === 'ORDER_ACCEPTED'
    ) {
      setState({
        ...state,
        confirmation: true,
        question: 'ORDER_ACCEPTED_RESET',
        statusSelected: 'ORDER_CREATED',
      });
    } else {
      setState({
        ...state,
        confirmation: true,
        question: status,
        statusSelected: status,
      });
    }
  };

  useEffect(() => {
    if (!state.status && orderStatus) {
      setState({ ...state, status: orderStatus });
    }
  }, [orderStatus, state]);

  return (
    <Card className={className}>
      <FormattedMessage component={Title} id="ORDER_STATUS" />
      <Button
        capitalize
        primary
        translate
        className={
          (state.status === 'ORDER_ACCEPTED' ||
            state.status === 'ORDER_SHIPPED') &&
          'active'
        }
        onClick={handleStateClick('ORDER_ACCEPTED')}
      >
        ACTIONS.PAYMENT_ACCEPTED
      </Button>
      <Button
        capitalize
        primary
        translate
        className={state.status === 'ORDER_SHIPPED' && 'active'}
        onClick={handleStateClick('ORDER_SHIPPED')}
      >
        ACTIONS.ORDER_SHIPPED
      </Button>
      <ButtonRed
        capitalize
        primary
        translate
        className={state.status === 'ORDER_CANCELLED' && 'active'}
        onClick={handleStateClick('ORDER_CANCELLED')}
      >
        ACTIONS.ORDER_CANCELLED
      </ButtonRed>
      <Confirmation
        in={state.confirmation}
        onAccept={handleAccept}
        onCancel={handleCnacel}
        question={state.question}
      />
    </Card>
  );
};

Status.propTypes = {
  className: PropTypes.string,
  handleOrderUpdate: PropTypes.func,
  orderStatus: PropTypes.string,
};

export default styled(Status)`
  ${Button} {
    background: none;
    border: solid 1px rgb(0, 93, 255);
    color: rgb(26, 26, 26);
    display: block;
    font-size: 1.4rem;
    font-weight: 600;
    height: 4.1rem;
    line-height: 1.7rem;
    margin-bottom: 2.4rem;
    text-align: center;
    width: 15.7rem;

    &.active {
      background-color: #f2f3f4;
      border: 0.1rem solid #a8a9b1;
      color: rgba(0, 15, 25, 0.4);
    }
  }

  ${ButtonRed} {
    background: none;
    border: solid 1px rgb(216, 0, 0);
    color: rgb(26, 26, 26);
  }

  ${Title} {
    color: #000f19;
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 2.9rem;
    margin-bottom: 2.4rem;
  }
`;
