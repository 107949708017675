import React from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';
import { FormattedMessage } from '@react-intl';
import { createPortal } from 'react-dom';

import Bubble, { Arrow } from 'Components/Tip/Bubble';

const Tooltip = ({
  askedCount = 0,
  askedUsersPercentage = 0,
  className,
  visible = true,
}) => {
  return createPortal(
    <div className={className}>
      <Arrow data-anchor="left-bottom" data-visible={visible} />
      <Bubble anchor="left-bottom" data-visible={visible}>
        <p>
          <FormattedMessage
            capitalize
            id="ASKED_TIMES"
            values={{ count: askedCount }}
          />
        </p>
        <p>
          <FormattedMessage
            capitalize
            id="USERS_ASKED_THIS_QUESTION"
            values={{ percents: askedUsersPercentage }}
          />
        </p>
      </Bubble>
    </div>,
    document.getElementById('tooltip'),
  );
};

Tooltip.propTypes = {
  className: PropTypes.string,
};

export default styled(Tooltip)`
  left: ${({ left }) => left}px;
  position: fixed;
  top: ${({ top }) => top - 14}px;
  z-index: 1005;

  ${Arrow} {
    left: -1.6rem;
    position: absolute;
  }

  ${Bubble} {
    color: #ffffff;
    position: initial;
    width: 27rem;
  }
`;
