import React from 'react';
import styled from '@styled-components';
import PropTypes from '@prop-types';
import { Fieldset } from 'spooner';

const StyledFieldset = ({ className, onAdd, children, ...props }) => (
  <Fieldset className={className} {...props}>
    {children}
  </Fieldset>
);

StyledFieldset.propTypes = {
  children: PropTypes.children,
  className: PropTypes.string,
  onAdd: PropTypes.func,
};

export default styled(StyledFieldset)`
  border: none;
  margin: 0;
  padding: 0;
  width: 100%;
`;
