import styled, { theme } from '@styled-components';

const Button = styled('button').attrs({ type: 'button' })`
  ${theme('--font-medium')}
  ${theme('--font-weight-medium')}
  background: ${theme('--color-white-40')};
  border: none;
  border-radius: 0.6rem;
  color: ${theme('--color-light')};
  cursor: pointer;
  margin-top: 2.4rem;
  outline: none;
  padding: 1.2rem 0;
  text-align: center;
  width: 100%;
`;

export default Button;
