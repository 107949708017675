import React from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';

const Item = ({ className, children }) => (
  <section className={className}>{children}</section>
);

Item.propTypes = {
  children: PropTypes.children,
  className: PropTypes.string,
};

export default styled(Item)``;
