import { v4 as uuid } from 'uuid';

const defaultFactory = () => ({ key: uuid() });

export const ArrayOfObjects = (length, factory) =>
  Array(length)
    .fill(null)
    .map(() => ({
      ...defaultFactory(),
      ...(factory ? factory() : {}),
    }));

export const ArrayOf = (length, cb) =>
  Array(length)
    .fill(null)
    .map(cb);

export const createIsEmpty = field => ({ [field]: list }) =>
  !list || list.length === 0;

export default {
  ArrayOf,
  ArrayOfObjects,
};
