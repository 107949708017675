import React from 'react';
import PropTypes from 'prop-types';
import styled, { withStyles, theme } from '@styled-components';
import { FormattedMessage } from '@react-intl';

import Card from 'Components/Card';
import Button from 'Components/Button';
import L from 'Components/Link';

const Title = styled('h1')``;
const Logo = styled('img')``;
const Description = styled('p')``;
const Link = Button.withComponent(L);

const AddIntegration = ({ className }) => (
  <Card className={className}>
    <Logo src="/images/integration.svg" />
    <FormattedMessage capitalize component={Title} id="ADD_NEW_INTEGRATION">
      add new integration
    </FormattedMessage>
    <FormattedMessage
      capitalize
      component={Description}
      id="INSTRUCTIONS.ADD_INTEGRATION"
    >
      select and integration
    </FormattedMessage>
    <FormattedMessage
      capitalize
      component={Link}
      id="ACTIONS.ADD"
      to="/integrations/add"
    >
      add
    </FormattedMessage>
  </Card>
);

AddIntegration.propTypes = {
  className: PropTypes.string,
};

export default withStyles`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 28rem;
  justify-content: space-between;
  padding: 1.6rem;
  width: 100%;

  @media (${theme('--column-8')}) {
    width: 28rem;
  }
  
  ${Title} {
    ${theme('--font-large')}
    margin-bottom: 1.6rem;
  }

  ${Description} {
    ${theme('--font-small')}
    ${theme('--font-opacity-40')}
    margin-bottom: 3.2rem;
  }
  
  & ${Logo} {
    height: 8rem;
    margin-bottom: 2rem;
    margin-top: 2.6rem;
  }

  & ${Link} {
    background: ${theme('--color-primary')};
    color: ${theme('--color-light')};
     ${theme('--font-medium')}
    padding: 1.2rem;
    text-align: center;
    width: 100%;
  }


`(AddIntegration);
