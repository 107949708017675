import { createAction } from '@redux-act';

export const change = createAction(
  '@Ranges/CHANGE_RANGES: Change the persisted ranges of the date picker',
  (name, options) => ({ name, options }),
);

export default {
  change,
};
