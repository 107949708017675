import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from '@styled-components';

import Constants from 'Constants';

import service from 'Services/faqs';

import Button from 'Components/Button';
import CustomRangePicker from 'Components/CustomRangePicker';
import Dialog, { Wrapper } from 'Components/Dialog';
import { Select } from 'Components/Form';

const Actions = styled('div')``;
const Link = styled('a')``;

const {
  FILTERS: { LAST_YEAR },
} = Constants;

const fileTypeOptions = [
  { name: 'TSV', value: 'tsv' },
  { name: 'CSV', value: 'csv' },
];

const DonwloadDialog = ({
  account,
  className,
  from = moment()
    .subtract(1, 'year')
    .format(),
  in: isIn,
  locale,
  onClose,
  to = moment().format(),
  type,
}) => {
  const ref = useRef();
  const [date, setDate] = useState({ from, to });
  const [fileType, setFileType] = useState();

  const handleDownload = async () => {
    const data = await service.download({
      ...date,
      account,
      fileType,
      locale,
      type,
    });

    ref.current.download = `faqs_${moment(date.from).format(
      'DDMMYYYY',
    )}_${moment(date.to).format('DDMMYYYY')}.${fileType}`;
    ref.current.href = `data:text/tab-separated-values,${encodeURIComponent(
      data,
    )}`;
    ref.current.click();
    onClose();
  };

  const handleDateChange = (_, dateFrom, dateTo) =>
    setDate({
      from: dateFrom,
      to: dateTo,
    });

  return (
    <Dialog
      className={className}
      in={isIn}
      onDismiss={onClose}
      title="DOWNLOAD"
    >
      <Link ref={ref} download href="/#" />
      <Select onChange={setFileType} options={fileTypeOptions} />
      <CustomRangePicker
        showAllTime
        filter={LAST_YEAR}
        from={from}
        onRangeChange={handleDateChange}
        to={to}
      />
      <Actions>
        <Button capitalize primary translate onClick={handleDownload}>
          ACTIONS.DONE
        </Button>
      </Actions>
    </Dialog>
  );
};

DonwloadDialog.propTypes = {
  account: PropTypes.string.isRequired,
  className: PropTypes.string,
  from: PropTypes.string,
  in: PropTypes.bool.isRequired,
  locale: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  to: PropTypes.string,
  type: PropTypes.string.isRequired,
};

export default styled(DonwloadDialog)`
  ${Wrapper} {
    margin: 2.4rem;

    ${Actions} {
      margin-top: 2.4rem;

      ${Button} {
        width: 100%;
      }
    }

    ${Select} {
      margin-bottom: 2.4rem !important;
      margin-left: 0 !important;
    }
  }
`;
