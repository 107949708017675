import React, { useCallback, useEffect, useState } from 'react';
import { ArrayOfObjects } from '@array-utils';
import styled, { theme } from '@styled-components';
import PropTypes from 'prop-types';

import Card from './components/Card';
import RemoveMedia from './components/RemoveMedia';
import EmptyTemplate from './components/EmptyTemplate';
import EmptyTemplateFilters from './components/EmptyTemplateFilters';

const Layout = styled('div')``;
const PlayIcon = styled('img')``;

const BrandContent = ({
  account,
  className,
  cursor,
  data: {
    collection,
    media = collection.length ? collection : ArrayOfObjects(9),
  },
  error,
  filters,
  onCursorChange: handleCursorChange = () => {},
  onRemove,
  success,
}) => {
  const [state, setState] = useState({ isConfirmOpen: false });
  const handleOpenConfirm = useCallback(
    id => () => {
      setState({
        id,
        isConfirmOpen: true,
      });
    },
    [],
  );

  const handleClose = useCallback(() => {
    setState({
      id: undefined,
      isConfirmOpen: false,
    });
  }, []);

  const handleRemove = useCallback(async () => {
    await onRemove(state.id);
    handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, error, state, success]);

  const handleCursor = useCallback(
    ({ target }) => {
      if (target.scrollTop === target.scrollHeight - target.offsetHeight) {
        handleCursorChange(cursor + 1);
      }
    },
    [cursor, handleCursorChange],
  );

  useEffect(() => {
    const content = document.querySelector('main');

    content.addEventListener('scroll', handleCursor);
    return () => content.removeEventListener('scroll', handleCursor);
  }, [handleCursor]);

  const filtered = media.filter(({ empty }) => !empty);

  return (
    <>
      <RemoveMedia
        in={state.isConfirmOpen}
        onAccept={handleRemove}
        onCancel={handleClose}
      />
      <div className={className}>
        <Layout>
          {filtered.map(({ id, name, source, type: t }) => (
            <Card
              key={id}
              name={name}
              onClick={handleOpenConfirm(id)}
              source={source}
              type={t}
            />
          ))}
        </Layout>
        {!media.length &&
          (filters ? <EmptyTemplateFilters /> : <EmptyTemplate />)}
      </div>
    </>
  );
};

BrandContent.propTypes = {
  account: PropTypes.string,
  className: PropTypes.string,
  cursor: PropTypes.number,
  data: PropTypes.object,
  error: PropTypes.func,
  filters: PropTypes.bool,
  onCursorChange: PropTypes.func,
  onRemove: PropTypes.func,
  success: PropTypes.func,
};

export default styled(BrandContent)`
  ${Layout} {
    display: grid;
    grid-column: 1;
    grid-gap: 1.6rem;
    grid-row: 2;
    grid-template-columns: repeat(1, 1fr);
  }

  @media (${theme('--screen-large')}) {
    ${Layout} {
      grid-gap: 3.2rem;
      grid-template-columns: repeat(3, 1fr);
      ${Card} {
        height: 25rem;
        ${PlayIcon} {
          height: 6.4rem;
        }
      }
    }
  }
`;
