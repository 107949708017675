import React from 'react';
import PropTypes from 'prop-types';
import styled from '@styled-components';

import Loader from 'Components/Loader';
import Table from 'Components/ResponsiveTable';

import Template from './Template';
import DesktopTemplate from './DesktopTemplate';
import EmptyTemplate from './EmptyTemplate';
import Header from './Header';

const Answers = ({ className, collection = [] }) => {
  return (
    <Table
      className={className}
      desktopRows={8}
      desktopTemplate={DesktopTemplate}
      emptyTemplate={EmptyTemplate}
      header={Header}
      items={collection}
      rows={5}
      template={Template}
    />
  );
};

Answers.propTypes = {
  className: PropTypes.string,
  collection: PropTypes.arrayOf(
    PropTypes.shape({
      answer: PropTypes.string,
      question: PropTypes.string,
    }),
  ),
};

export default styled(Answers)`
  height: 100%;
  overflow: hidden;

  ${Loader} {
    align-items: center;
  }
`;
