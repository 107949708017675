import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';

import { currentAccount } from 'Selectors';

import * as logger from 'Actions/logger';

import Modal from 'Components/Modal';
import { Redirect, withRouter } from 'Components/Router';

import DataProvider from 'Containers/DataProvider';
import { useMultiDataProviderContext } from 'Containers/MultiDataProvider';

import service from 'Services/campaigns';

import Toolbar from './components/Toolbar';
import View from './Campaign';
import Confirmation from './components/Confirmation';

const mapStateToProps = state => ({ account: currentAccount(state) });

const mapDispatch = dispatch =>
  bindActionCreators(
    { error: logger.error, success: logger.success },
    dispatch,
  );

const query = ({ account, id }) =>
  Promise.all([
    service.getById({ account, id }),
    service.getFunnel({ account, id }),
  ]);

const Campaign = ({
  account,
  dataKey,
  depth,
  error,
  history,
  id,
  in: modalIn,
  onClose,
  onBack,
  order,
  success,
  ...props
}) => {
  const [type, setType] = useState(null);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [stopVisible, setStopVisible] = useState(false);
  const showDelete = useCallback(() => setDeleteVisible(true), []);
  const hideDelete = useCallback(() => setDeleteVisible(false), []);
  const showStop = useCallback(() => setStopVisible(true), []);
  const hideStop = useCallback(() => setStopVisible(false), []);
  const { refetch } = useMultiDataProviderContext('campaigns');

  const handleDelete = useCallback(async () => {
    try {
      await service.remove({ account, id });
      success('CAMPAIGN_REMOVED');
      refetch();
      onClose();
    } catch (e) {
      error('CAMPAIGN_REMOVED');
    }
  }, [account, error, id, onClose, refetch, success]);

  const handleStop = useCallback(() => {
    service
      .updateStatus({ account, campaignId: id, status: 'PAUSED' })
      .then(() => success('CAMPAIGN_STOPPED'))
      .catch(() => error('CAMPAIGN_STOPPED'))
      .finally(hideStop);
  }, [account, error, hideStop, id, success]);

  return (
    <>
      <Confirmation
        acceptId="ACTIONS.DELETE"
        confirmationQuestion="QUESTIONS.DELETE_CAMPAIGN"
        in={deleteVisible}
        onAccept={handleDelete}
        onCancel={hideDelete}
        title="DELETE_CAMPAIGN"
      />
      <Confirmation
        acceptId="ACTIONS.STOP"
        confirmationQuestion="QUESTIONS.STOP_CAMPAIGN"
        in={stopVisible}
        onAccept={handleStop}
        onCancel={hideStop}
        title="STOP_CAMPAIGN"
      />
      <Modal
        key={dataKey}
        depth={depth}
        in={modalIn}
        onBack={onBack}
        onClose={onClose}
        order={order}
        show={modalIn}
        title="CAMPAIGN"
        toolbar={() => (
          <Toolbar
            id={id}
            showDelete={showDelete}
            showStop={showStop}
            type={type}
          />
        )}
      >
        <DataProvider
          account={account}
          emptyTemplate={() => <Redirect to="/campaigns" />}
          getType={setType}
          onError={error}
          params={{ account, id }}
          query={query}
          template={View}
          {...props}
        />
      </Modal>
    </>
  );
};

Campaign.defaultProps = {
  onClose() {},
};

Campaign.propTypes = {
  account: PropTypes.number,
  dataKey: PropTypes.string,
  depth: PropTypes.number,
  error: PropTypes.func,
  history: PropTypes.object,
  id: PropTypes.string.isRequired,
  in: PropTypes.bool,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  order: PropTypes.number,
  success: PropTypes.func,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatch),
)(Campaign);
