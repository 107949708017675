import React from 'react';
import PropTypes from '@prop-types';
import DataProvider from 'Containers/DataProvider';
import View from './FaqForm';

import service from 'Services/faqs';

const FaqForm = ({ account, ...props }) => {
  return (
    <DataProvider
      account={account}
      params={{ account }}
      query={service.locales}
      template={View}
      {...props}
    />
  );
};

FaqForm.propTypes = {
  account: PropTypes.number,
};

export default FaqForm;
