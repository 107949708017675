import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@styled-components';

import { getUnit } from 'Modules/dates';

import { currency as currentCurrency, currentAccount } from 'Selectors';

import AudienceService from 'Services/audience';
import RevenuesService from 'Services/revenues';

import Card from 'Components/Card';
import DataProvider from 'Containers/DataProvider';
import LineChartLoader from 'Components/LineChartLoader';
import View from './VisitsAndRevenues';

const mapStateToProps = state => ({
  account: currentAccount(state),
  currency: currentCurrency(state),
});

const query = async ({ account, currency, from, to, offset, limit }) => {
  const unit = getUnit(from, to);

  const [{ visits, total }, { sales }] = await Promise.all([
    AudienceService.getVisitsByTime({
      account,
      currency,
      from,
      limit,
      offset,
      to,
      unit,
    }),
    RevenuesService.getByTime({
      account,
      currency,
      from,
      limit,
      offset,
      to,
      unit,
    }),
  ]);

  return {
    sales,
    total,
    visits,
  };
};

const VisitsAndRevenues = ({
  account,
  className,
  currency,
  from,
  limit,
  to,
  ...props
}) => (
  <DataProvider
    className={className}
    limit={limit}
    params={{
      account,
      currency,
      from,
      to,
    }}
    query={query}
    template={({
      data: { visits = {}, sales = {}, total } = {},
      limit: l,
      loading,
    }) => (
      <View
        {...props}
        className={className}
        currency={currency}
        data={[
          Object.keys(visits),
          Object.values(sales),
          Object.values(visits),
          sales,
          visits,
        ]}
        isLoading={loading}
        limit={l}
        loader={() => (
          <Card className={className} title="$VISITS_AND_SALES">
            <LineChartLoader />
          </Card>
        )}
        total={total}
        unit={getUnit(from, to)}
      />
    )}
  />
);

VisitsAndRevenues.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
  currency: PropTypes.string,
  from: PropTypes.string,
  limit: PropTypes.number,
  to: PropTypes.string,
};

VisitsAndRevenues.defaultProps = {
  from: moment()
    .subtract(1, 'year')
    .format(),
  to: moment().format(),
};

export default compose(
  connect(mapStateToProps),
  withStyles``,
)(VisitsAndRevenues);
