import React from 'react';
import PropTypes from '@prop-types';
import styled, { theme } from '@styled-components';
import { FormattedMessage } from '@react-intl';

import Icon from 'Components/Icon';

const Title = styled('h2')``;
const Wrapper = styled('div')``;

const EmptyTemplate = ({ className }) => (
  <article className={className}>
    <Wrapper>
      <Icon name="campaigns-empty">ANSWERS_EMPTY</Icon>
      <FormattedMessage capitalize component={Title}>
        Oops! we did not find any answers yet
      </FormattedMessage>
    </Wrapper>
  </article>
);

EmptyTemplate.propTypes = {
  className: PropTypes.string,
};

export default styled(EmptyTemplate)`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 45rem;
  justify-content: center;
  overflow-y: scroll;

  ${Wrapper} {
    display: grid;
    grid-gap: 0.4rem;
    grid-template-rows: minmax(0, calc(100% - 2.4rem)) 2.4rem;
    height: 100%;
    max-height: 30rem;
    text-align: center;

    ${Icon} {
      height: 100%;
      margin: 0 auto;
    }

    ${Title} {
      ${theme('--font-large')}
      ${theme('--font-weight-book')}
      ${theme('--font-opacity-100')}
    }
  }

  @media screen and (${theme('--screen-small')}) {
    height: 62rem;
  }
`;
