import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Icon from 'Components/Icon';
import GroupTags from './Tags';

const Input = styled('input')``;
const InputButton = styled(Icon)``;

const Header = ({
  className,
  groupsSelected,
  inputValue,
  isListOpened,
  onInputChange,
  onListOpen,
  onListToggle,
}) => {
  const handleChevronClick = event => {
    event.stopPropagation();
    onListToggle();
  };

  const handleInputChange = ({ target: { value } }) => onInputChange(value);

  return (
    /* eslint-disable-next-line */
    <div className={className} onClick={onListOpen}>
      <GroupTags groups={groupsSelected} />
      <Input
        onChange={handleInputChange}
        placeholder={groupsSelected.length ? '' : 'Search Customer Tag Lists'}
        type="text"
        value={inputValue}
      />
      <InputButton
        cursor="pointer"
        name={isListOpened ? 'chevronup' : 'chevrondown'}
        onClick={handleChevronClick}
      />
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  groupsSelected: PropTypes.array.isRequired,
  inputValue: PropTypes.string.isRequired,
  isListOpened: PropTypes.bool,
  onInputChange: PropTypes.func.isRequired,
  onListOpen: PropTypes.func.isRequired,
  onListToggle: PropTypes.func.isRequired,
};

export default styled(Header)`
  align-items: center;
  background-color: #fff;
  border: 1px solid #e5e7e8;
  border-radius: 0.6rem;
  display: flex;
  height: 4.8rem;
  justify-content: start;

  ${Input} {
    background: none;
    border: none;
    font-size: 1.4rem;
    height: 4.8rem;
    padding: 0 1.2rem 0 1.2rem;
    width: 100%;

    &:focus-visible {
      outline: none;
    }
  }

  ${InputButton} {
    height: 3.2rem;
    margin-right: 0.8rem;
    min-width: 3.2rem;

    &:active {
      background: linear-gradient(
        180deg,
        rgba(0, 132, 255, 0.2) 0%,
        rgba(0, 132, 255, 0.1) 100%
      );
      border-radius: 0.6rem;
    }
  }
`;
