import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from '@prop-types';
import { FormattedMessage } from '@react-intl';
import styled, { withStyles as css, theme } from '@styled-components';
import { compose } from 'underscore';

import Overlay, { Background } from 'Components/Overlay';
import withTransition from 'Components/with/transition';

import { medium, small } from './animations';
import IconButton from 'Components/IconButton';

const Content = styled('div')``;
const Header = styled('header')``;
const Title = styled('h2')``;
const Wrapper = styled('article')``;

const Modal = ({ children, className, onClose }) => {
  const element = useRef(document.getElementById('overlay'));

  useEffect(() => {
    element.current.className = className;
  }, [className]);

  return createPortal(
    <Overlay in className={className} onDismiss={onClose}>
      <Wrapper>
        <Header>
          <FormattedMessage capitalize component={Title} id="SELECT_PERIOD">
            select a period to display
          </FormattedMessage>
          <IconButton name="close" onClick={onClose} />
        </Header>
        <Content>{children}</Content>
      </Wrapper>
    </Overlay>,
    element.current,
  );
};

Modal.propTypes = {
  children: PropTypes.children,
  className: PropTypes.string,
  onClose: PropTypes.func,
};

export default compose(
  css`
    z-index: 10000;

    ${Wrapper} {
      background-color: ${theme('--color-light')};
      border-radius: 0.6rem;
      height: 100%;
      left: 0;
      margin: 0 auto;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 10000;

      ${Header} {
        align-items: center;
        background-color: ${theme('--color-light')};
        border-bottom: 0.1rem solid ${theme('--color-dark-night-10')};
        display: flex;
        height: 8rem;
        justify-content: space-between;
        padding: 0 2rem;

        ${Title} {
          ${theme('--font-large')}
          ${theme('--font-weight-demi')}
        }

        ${IconButton} {
          position: absolute;
          right: 0.8rem;
        }
      }

      ${Content} {
        height: 100%;
        overflow-y: scroll;
      }
    }

    ${small(Background, Wrapper)}

    @media screen and (${theme('--column-12')}) {
      ${Wrapper} {
        height: auto;
        left: auto;
        max-width: 102.4rem;
        right: 4.8rem;
        top: 9.7rem;
        width: 76rem;

        ${Header} {
          display: none;
        }
      }

      ${medium(Background, Wrapper)}
    }

    @media (min-width: 1700px) {
      /* 1700 is the exact point to make this dialog look perfect*/
      ${Wrapper} {
        right: calc((100vw - 159.5rem) / 2);
      }
    }
  `,
  withTransition(350),
)(Modal);
