import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import CheckBox from 'Components/Form/Input/CheckBox';
import CustomerTooltip from './Tooltip';
import RawAvatar from 'Components/Avatar';
import { withPlaceholderNoData } from 'Containers/Placeholder';

const Avatar = styled(withPlaceholderNoData(RawAvatar))``;
const CustomerInfo = styled('div')``;

const Contact = ({
  avatar,
  className,
  customerId,
  firstName,
  isChecked,
  lastName,
  onChange,
  phone,
}) => {
  const handleChange = checked => onChange({ checked, customerId });

  return (
    <div className={className}>
      <CustomerInfo>
        <CheckBox onChange={handleChange} value={isChecked} />
        <CustomerInfo style={{ opacity: !phone && 0.4 }}>
          <Avatar fill image={avatar} />
          {firstName} {lastName}
        </CustomerInfo>
      </CustomerInfo>
      {!phone && <CustomerTooltip tipText="CUSTOMERS_PHONE_TIP" />}
    </div>
  );
};

Contact.propTypes = {
  avatar: PropTypes.string,
  className: PropTypes.string,
  customerId: PropTypes.number.isRequired,
  firstName: PropTypes.string,
  isChecked: PropTypes.bool,
  lastName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  phone: PropTypes.string,
};

export default styled(Contact)`
  align-items: center;
  box-shadow: inset 0px -1px 0px rgba(0, 15, 25, 0.03);
  color: #666f75;
  display: inline-flex;
  font-size: 1.4rem;
  height: 4.8rem;
  justify-content: space-between;
  line-height: 2.4rem;
  padding: 0 1rem 0 0.6rem;
  width: 50rem;

  &:hover {
    background: #f2f7ff;
    color: #000f19;
  }

  ${Avatar} {
    border-radius: 0.6rem;
    height: 4rem;
    margin: 0 2.4rem 0 0.8rem;
    width: 4rem;
  }

  ${CustomerInfo} {
    align-items: center;
    display: inline-flex;
  }
`;
