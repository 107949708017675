import React, { useState, useCallback, useMemo } from 'react';
import { name as localeNames } from '@langs';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';
import { useIntl } from '@react-intl';

import Button from 'Components/Button';
import Card from 'Components/Card';
import Empty from 'Components/Empty';
import { AutoSuggest, Select, Input } from 'Components/Form';

import TableDataProvider from 'Containers/TableDataProvider';
import Toolbar, { SoftLink } from 'Containers/CardToolbar';
import TagAutoSuggest from 'Containers/FaqForm/components/TagAutoSuggest';

import service from 'Services/faqs';

import Table from './components/Table';
import ContextMenu from './components/ContextMenu';

const Menu = styled('menu')``;
const ConfirmRemove = styled('p')``;

const BotBuilder = ({ account, className, locales = [], role }) => {
  const [locale, setLocale] = useState(locales[0]);
  const [tagFilters, setTagFilter] = useState([]);
  const [searchBy, setSearch] = useState();
  const [sortBy, setSortBy] = useState(null);
  const [type, setType] = useState('ANSWERED');

  const intl = useIntl();

  const isAdmin = role.includes('ADMIN');

  const handleSearch = useCallback(
    search => {
      setSearch(search);
    },
    [setSearch],
  );

  const handleSortBy = useCallback(
    t => {
      setSortBy(t);
    },
    [setSortBy],
  );

  const handleType = useCallback(
    t => {
      setTagFilter([]);
      setType(t);
    },
    [setTagFilter, setType],
  );

  const filterOptions = useMemo(
    () => [
      {
        name: intl.formatMessage({ capitalize: true, id: 'ANSWERED' }),
        value: 'ANSWERED',
      },
      {
        name: intl.formatMessage({ capitalize: true, id: 'UNANSWERED' }),
        value: 'UNANSWERED',
      },
    ],
    [intl],
  );

  const sortByOptions = useMemo(
    () => [
      {
        name: intl.formatMessage({ capitalize: true, id: 'ACTIONS.SORT_BY' }),
        value: null,
      },
      {
        name: intl.formatMessage({ capitalize: true, id: 'MOST_ASKED' }),
        value: 'MOST_ASKED',
      },
      {
        name: intl.formatMessage({ capitalize: true, id: 'LAST_EDITED' }),
        value: 'LAST_EDITED',
      },
      {
        name: intl.formatMessage({ capitalize: true, id: 'LEAST_POPULAR' }),
        value: 'LEAST_POPULAR',
      },
      {
        name: intl.formatMessage({ capitalize: true, id: 'OLDEST_POST' }),
        value: 'OLDEST_POST',
      },
    ],
    [intl],
  );

  let filterAmmount = 0;

  if (searchBy) {
    filterAmmount += 1;
  }

  if (tagFilters.length) {
    filterAmmount += 1;
  }

  const localeAmmount = locales.length;
  const hasLocaleSelector = localeAmmount > 1;

  const options = {
    account,
    isAdmin,
    locale,
    role,
  };

  return (
    <div className={className}>
      {hasLocaleSelector && (
        <Menu>
          <Select
            onChange={setLocale}
            options={locales.map(key => ({
              name: localeNames(key),
              value: key,
            }))}
          />
        </Menu>
      )}
      <Card>
        <Toolbar
          contextMenu={() => (
            <ContextMenu account={account} locale={locale} type={type} />
          )}
          filterAmmount={filterAmmount}
          title="FREQUENTLY_ASKED_QUESTIONS"
        >
          <Input
            capitalize
            label="SEARCH"
            name="search"
            onChange={handleSearch}
            type="search"
          />
          <Select
            capitalize
            translate
            label="TYPE"
            name="type"
            onChange={handleType}
            options={filterOptions}
            type="type"
          />
          <Select
            capitalize
            translate
            label="SORT"
            name="sort"
            onChange={handleSortBy}
            options={sortByOptions}
          />
          {type === 'ANSWERED' && (
            <TagAutoSuggest
              account={account}
              locale={locale}
              maxHeight="7.2rem"
              onChange={setTagFilter}
            />
          )}
        </Toolbar>
        <TableDataProvider
          account={account}
          filterAmmount={filterAmmount}
          isUnanswered={type === 'UNANSWERED'}
          limit={9}
          name="faqs"
          options={options}
          params={{
            account,
            filterBy: tagFilters.map(({ name }) => name),
            locale,
            searchBy,
            sortBy,
            type,
          }}
          query={service.faqs}
          role={role}
          setLocale={setLocale}
          template={Table}
        />
      </Card>
    </div>
  );
};

BotBuilder.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
  locales: PropTypes.arrayOf(PropTypes.string),
  role: PropTypes.string,
};

export default styled(BotBuilder)`
  display: grid;
  grid-gap: 1.6rem;
  grid-template-rows: auto minmax(0, 1fr);

  ${Menu} {
    display: none;
    grid-column: 11 / span 2;
    justify-content: flex-end;
    margin: 0;
    padding: 0;

    ${Select} {
      width: 16rem;
    }

    ${Button} {
      align-items: center;
      display: flex;
      height: 4.8rem;
      justify-content: center;
      margin-left: 1.6rem;
      width: 16rem;
    }
  }

  ${Card} {
    display: grid;
    grid-column: span 4;
    grid-template-rows: auto minmax(0, 1fr);
    padding: 0;

    ${Toolbar} {
      ${AutoSuggest}, ${Input}, ${Select} {
        display: inline-block;
        height: 4.8rem;
        margin: 0.4rem 0;
        width: 100%;
      }
    }

    ${Empty} {
      height: calc(100vh - (18.4rem + 2 * 3.2rem));
    }
  }

  ${SoftLink} {
    position: absolute;
    visibility: hidden;
  }

  ${ConfirmRemove} {
    ${theme('--font-medium')}
    ${theme('--font-weight-book')}
    ${theme('--font-opacity-60')}
  }

  @media (${theme('--column-8')}) {
    ${Menu} {
      display: flex;
    }

    ${TagAutoSuggest} {
      display: block;
    }

    ${Card} {
      grid-column: span 8;
      overflow: visible;

      ${Toolbar} {
        ${AutoSuggest}, ${Input}, ${Select} {
          margin: 0 0 0 1.6rem;
          width: 24rem;
        }

        ${AutoSuggest}{
          ${Input} {
            margin: 0;
          }
        }
      }
    }

    ${SoftLink} {
      position: relative;
      visibility: visible;
    }
  }

  @media (${theme('--column-12')}) {

    ${Card} {
      grid-column: span 12;
    }
  }
`;
