import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import ResizeObserver from 'resize-observer-polyfill';
import styled, { theme, withStyles as css } from '@styled-components';
import { compose, debounce } from 'underscore';

import Button from 'Components/Button';
import Carousel, { Item } from 'Components/Carousel';
import IconButton from 'Components/IconButton';
import Icon from 'Components/Icon';

import Img from './Image';

const SCREEN_SMALL = 600;

const Actions = styled('div')``;
const Description = styled('p')``;
const Frame = styled('div')``;
const Header = styled('header')``;
const Image = styled('div')``;
const Name = styled('h4')``;

const ITEMS = 11;
const MOBILE_ITEMS = 3;

class Gallery extends Component {
  element = createRef();

  resize = debounce(() => {
    const {
      current: { offsetWidth },
    } = this.element;
    this.setState({ items: offsetWidth > SCREEN_SMALL ? ITEMS : MOBILE_ITEMS });
  }, 250);

  resizer = new ResizeObserver(this.resize);

  static propTypes = {
    className: PropTypes.string,
    data: PropTypes.shape({
      description: PropTypes.string,
      images: PropTypes.arrayOf(PropTypes.string),
      name: PropTypes.string,
    }),
    goBack: PropTypes.func,
  };

  state = {
    items: ITEMS,
    offset: 0,
    selected: 0,
  };

  componentDidMount() {
    this.resize();

    setTimeout(() => {
      if (this.element.current) {
        this.resizer.observe(this.element.current);
      }
    }, 0);
  }

  componentWillUnmount() {
    this.resizer.unobserve(this.element.current);
  }

  handleIncrement = () => {
    const {
      data: { images },
    } = this.props;
    const { selected: prevSelected } = this.state;
    const selected = Math.max(Math.min(prevSelected + 1, images.length - 1));

    this.handleOffsetCalculation(selected);
  };

  handleDecrement = () => {
    const { selected: prevSelected } = this.state;
    const selected = Math.max(0, prevSelected - 1);

    this.handleOffsetCalculation(selected);
  };

  handleSelect = selected => () => {
    this.handleOffsetCalculation(selected);
  };

  handleOffsetCalculation = selected => {
    this.setState(prevState => ({
      offset: Math.floor(selected / prevState.items) * prevState.items,
      selected,
    }));
  };

  handleOffset = offset => {
    this.setState({
      offset,
    });
  };

  render() {
    const {
      className,
      data: { description, images = [], name } = {},
      goBack,
    } = this.props;
    const { items, offset, selected } = this.state;

    return (
      <section className={className}>
        <Header>
          <IconButton name="chevronleft" onClick={goBack}>
            ACTIONS.BACK
          </IconButton>
          <div>
            <Name>{name}</Name>
            <Description>{description}</Description>
          </div>
        </Header>
        <Frame>
          <Image style={{ backgroundImage: `url(${images[selected]})` }} />
          <Actions>
            <Button disabled={selected === 0} onClick={this.handleDecrement}>
              <Icon name="chevronleft">ACTIONS.DECREASE</Icon>
            </Button>
            <Button
              disabled={selected + 1 >= images.length}
              onClick={this.handleIncrement}
            >
              <Icon name="chevronright">ACTIONS.INCREASE</Icon>
            </Button>
          </Actions>
        </Frame>
        <div ref={this.element}>
          <Carousel
            ref={items}
            items={items}
            offset={offset}
            onChange={this.handleOffset}
            step={items}
          >
            {images.map((image, index) => (
              <Item key={image}>
                <Img
                  active={selected === index}
                  image={image}
                  onChange={this.handleSelect(index)}
                />
              </Item>
            ))}
          </Carousel>
        </div>
      </section>
    );
  }
}

export default compose(
  css`
    padding: 0 1.2rem;

    ${Header} {
      align-items: center;
      display: flex;
      margin-bottom: 2rem;

      ${Name} {
        ${theme('--font-medium')}
        ${theme('--font-opacity-60')}
        margin-bottom: 0.4rem;
      }

      ${Description} {
        ${theme('--font-small')}
        ${theme('--font-opacity-40')}
      }
    }

    ${Frame} {
      background-color: ${theme('--color-light')};
      padding: 0.8rem;
    }

    ${Image} {
      background-color: ${theme('--color-dark-night')};
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      border: 0.1rem solid rgba(0, 0, 0, 0.05);
      border-radius: 0.6rem;
      height: calc(100vh - 41.6rem);
    }

    ${Actions} {
      align-items: flex-end;
      background: ${theme('--color-light')};
      display: flex;
      height: 4rem;
      justify-content: flex-end;

      ${Button} {
        outline: none;

        &:focus {
          box-shadow: none;
        }

        &:disabled {
          ${Icon} {
            opacity: 0.6;
          }
        }

        ${Icon} {
          display: block;
          outline-color: #999fa3;
        }
      }
    }

    ${Carousel} {
      margin-top: 3.2rem;
    }
  `,
)(Gallery);
