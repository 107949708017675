import request from 'Providers/request';

async function get() {
  const accounts = await request.read('ofa_list');

  if (accounts.length < 1) {
    throw new Error('NOT_ASSOCIATED_TO_ANY_ACCOUNT'); // TODO Create error
  }

  return accounts;
}

async function getOfaSettings({ account }) {
  const {
    name,
    colors: [primaryColor, secondaryColor],
  } = await request.read(`/ofa/${account}/settings`);

  return {
    name,
    primaryColor,
    secondaryColor,
  };
}

function putSettings({
  account,
  companyName,
  logo,
  primaryColor,
  secondaryColor,
}) {
  return request(`/ofa/${account}/settings`, 'PUT', {
    colors: [primaryColor, secondaryColor],
    logo,
    name: companyName,
  });
}

async function putAgent({
  avatar,
  firstName,
  lastName,
  email,
  password,
  confirmedPassword,
  locale,
  ofaId,
  profileId,
}) {
  return request(
    '/ofa_agent',
    'PUT',
    {
      avatar,
      confirmedPassword,
      email,
      firstName,
      lastName,
      locale,
      password,
    },
    {},
    {
      headers: {
        'content-type': 'multipart/form-data',
        ofaId,
        profileId,
      },
    },
  );
}

export default { get, getOfaSettings, putAgent, putSettings };
