import React from 'react';
import { connect } from 'react-redux';
import PropTypes from '@prop-types';
import { withStyles } from '@styled-components';
import { compose } from 'underscore';

import { currentAccount } from 'Selectors';

import DataProvider from 'Containers/DataProvider';
import MicroappMediaForm from 'Containers/MicroappMediaForm';

import service from 'Services/microapps';

const mapStateToProps = state => ({
  account: currentAccount(state),
});

const EditMicroappMedia = ({
  account,
  className,
  order,
  depth,
  id,
  in: modalIn,
  onClose,
  onBack,
}) => {
  return (
    <DataProvider
      editMode
      className={className}
      depth={depth}
      in={modalIn}
      modalTitle="EDIT_TEMPLATE"
      onBack={onBack}
      onClose={onClose}
      order={order}
      params={{ account, id }}
      query={service.getById}
      template={MicroappMediaForm}
    />
  );
};

EditMicroappMedia.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
  depth: PropTypes.number,
  id: PropTypes.string,
  in: PropTypes.bool,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  order: PropTypes.number,
};

export default compose(
  connect(mapStateToProps),
  withStyles`
    margin: 0 auto;
  `,
)(EditMicroappMedia);
