import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Fetcher from 'Containers/Fetcher';

import { currentAccount } from 'Selectors';

import services from 'Services/notifications';

import View from './Notifications';

const mapStateToProps = state => ({
  account: currentAccount(state),
});

const Notifications = ({ account }) => (
  <Fetcher params={{ account }} query={services.get}>
    {({ data: { notifications = [] } = {}, loading }) => (
      <View
        isLoading={loading}
        notifications={notifications}
        update={services.update}
      />
    )}
  </Fetcher>
);

Notifications.propTypes = {
  account: PropTypes.number,
};

export default connect(mapStateToProps)(Notifications);
