import React from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import View from './Table';

const QuestionTitle = styled('h4')``;

const Question = ({ className, question = {} }) => {
  return (
    <div className={className}>
      <TransitionGroup>
        <CSSTransition key={question.id} classNames="question" timeout={400}>
          <QuestionTitle>{question.question}</QuestionTitle>
        </CSSTransition>
      </TransitionGroup>
      <View answers={question.answers} />
    </div>
  );
};

Question.propTypes = {
  className: PropTypes.string,
  question: PropTypes.array,
};

export default styled(Question)`
  position: relative;

  ${QuestionTitle} {
    color: ${theme('--color-dark-night')};
    ${theme('--font-large')}
    ${theme('--font-weight-medium')}
    height: 4.8rem;
    margin: 0 0 0.3rem;
    padding: 0 2.4rem;
  }

  .question-enter {
    opacity: 0;
  }
  .question-enter-active {
    opacity: 1;
    transition: opacity 400ms linear;
  }
  .question-exit {
    opacity: 1;
    position: absolute;
    top: 0;
  }
  .question-exit-active {
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity 400ms linear;
  }
`;
