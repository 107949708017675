import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import CustomerTooltip from './Tooltip';
import Icon from 'Components/Icon';

const Title = styled('div')``;

const Header = ({
  className,
  initialCustomers,
  isOpen,
  isSelected,
  name,
  onClick,
  totalContacts,
  withPhone,
}) => {
  return (
    /* eslint-disable-next-line */
    <div className={className} onClick={onClick}>
      {isSelected ? (
        <Icon name={isOpen ? 'chevrondown' : 'chevronright'} />
      ) : (
        <div />
      )}
      <Title>{name}</Title>
      <div>
        <FormattedMessage id="ENROLLED" /> ({withPhone}/
        {totalContacts > initialCustomers ? totalContacts : initialCustomers})
      </div>
      {totalContacts !== withPhone && (
        <CustomerTooltip tipText="CUSTOMERS_GROUP_PHONE_TIP" />
      )}
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  initialCustomers: PropTypes.number,
  isOpen: PropTypes.bool,
  isSelected: PropTypes.bool,
  name: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  totalContacts: PropTypes.number,
  withPhone: PropTypes.number,
};

export default styled(Header)`
  align-items: center;
  box-shadow: inset 0px -1px 0px rgba(0, 15, 25, 0.03);
  color: #666f75;
  cursor: pointer;
  display: grid;
  font-size: 1.4rem;
  grid-template-columns: 24px 1fr 144px 24px;
  height: 4.8rem;
  justify-content: space-between;
  line-height: 2.4rem;
  padding: 0 1rem 0 1rem;
  user-select: none;

  &:hover {
    background: #f2f7ff;
    color: #000f19;
  }

  ${Icon} {
    height: 2.4rem;
    width: 2.4rem;
  }
`;
