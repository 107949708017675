import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate } from 'react-intl';
import styled, { theme } from '@styled-components';
import { useLocation } from 'react-router-dom';

import { PlaceholderNoData as Placeholder } from 'Containers/Placeholder';

import Chip from 'Components/Chip';
import Icon from 'Components/Icon';
import Stepper from 'Components/Stepper';

import { Row } from 'Components/ResponsiveTable';

const Tag = styled(Placeholder)``;
const NameTag = styled(Tag)``;
const Steps = styled(Tag)``;

export const Campaign = ({
  className,
  empty,
  id,
  interaction = new Date(),
  name,
  platform,
  status,
  step,
  steps = [],
  type,
}) => {
  const { pathname } = useLocation();

  return (
    <Row className={className} empty={empty} to={`${pathname}/campaigns/${id}`}>
      <NameTag placeholder="campaign name">{name}</NameTag>
      <Tag placeholder="interaction">
        <FormattedDate
          day="numeric"
          month="long"
          value={interaction}
          year="numeric"
        />
      </Tag>
      <Steps fill>
        <Stepper
          currentStep={steps.findIndex(({ key }) => key === step)}
          steps={steps.map(({ displayName }) => displayName)}
        />
      </Steps>
      <Tag placeholder="platform">
        {platform && <Icon name={platform.toLowerCase()}>{platform}</Icon>}
      </Tag>
      <Tag placeholder="chip">
        <Chip>{status}</Chip>
      </Tag>
      <Tag placeholder="type">{type}</Tag>
    </Row>
  );
};

Campaign.propTypes = {
  className: PropTypes.string,
  empty: PropTypes.bool,
  id: PropTypes.number,
  interaction: PropTypes.instanceOf(Date),
  name: PropTypes.string,
  platform: PropTypes.string,
  status: PropTypes.string,
  step: PropTypes.number,
  steps: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.string,
};

export default styled(Campaign)`
  ${theme('--font-medium')}
  grid-template-columns: 1fr 15% 20rem 6rem 9rem 6rem;
  height: 5.6rem;

  ${Tag} {
    ${theme('--font-medium')}
    ${theme('--font-weight-book')}
    ${theme('--font-opacity-40')}

    ${Chip} {
      ${theme('--font-weight-demi')}
      background: ${theme('--color-main-20')};
      border: none;
      border-radius: 0.6rem;
      color: ${theme('--color-main')};
    }
  }

  ${NameTag} {
    ${theme('--font-weight-medium')}
    color: ${theme('--color-primary')};
  }

  ${Steps} {
    height: 2rem;
    padding-right: 2.4rem;
    width: 100%;
  }
`;
