import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, withIntl } from '@react-intl';

import { compose } from 'redux';

import styled, { withStyles, theme } from '@styled-components';

import Link from 'Components/Link';

const Title = styled('h4')``;
const Main = styled('div')``;
const Contact = styled('p')``;

const Signup = ({ className }) => (
  <section className={className}>
    <FormattedMessage capitalize component={Title} id="SIGNUP">
      sign up
    </FormattedMessage>
    <Main>
      <FormattedMessage capitalize id="TRY_JOINEDAPP">
        try Joinedapp for free
      </FormattedMessage>
    </Main>
    <Contact>
      <FormattedMessage
        id="INSTRUCTIONS.SIGNUP"
        values={{
          a: (...chunks) => <a href="mailto:info@joinedapp.com">{chunks}</a>,
        }}
      />
    </Contact>
    <FormattedMessage capitalize id="QUESTIONS.ALREADY_HAVE_ACCOUNT">
      already have an account?
    </FormattedMessage>
    <FormattedMessage capitalize component={Link} id="LOGIN" to="/login">
      log in
    </FormattedMessage>
  </section>
);

Signup.propTypes = {
  className: PropTypes.string,
};

export default compose(
  withStyles`
    ${theme('--font-opacity-40')}
    ${theme('--font-small')}
    
    ${Title} {
      ${theme('--font-opacity-100')}
      ${theme('--font-weight-book')}
      ${theme('--font-xlarge')}
      margin-bottom: 0.8rem;
    }
  
    ${Main} {
      ${theme('--font-medium')}
      margin-bottom: 3.2rem;
    }
  
    ${Contact} {
      ${theme('--font-medium')}
      line-height: 2.4rem;
      margin-bottom: 4rem;
    }
    
    ${Link}, a {
      ${theme('--font-weight-demi')}
      color: ${theme('--color-primary')};
    }
  `,
  withIntl,
)(Signup);
