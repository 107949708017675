import React from 'react';
import PropTypes from '@prop-types';
import { FormattedMessage, withIntl } from '@react-intl';
import styled, { theme, withStyles as css } from '@styled-components';
import { compose } from 'underscore';

import Chip from 'Components/Chip';
import RawLink from 'Components/Link';
import Table from 'Components/Table';
import Placeholder, { withPlaceholder } from 'Containers/Placeholder';
import Card, { Title } from 'Components/Card';

const ChipRed = styled(Chip)``;
const Heading = styled(Placeholder.h5)``;
const Subheading = styled(Placeholder.p)``;

const Link = styled(withPlaceholder(RawLink)).attrs({
  forwardProps: ['to'],
})``;

const MAGNITUDES = ['', 'K', 'M', 'G', 'T', 'P'];
const MAX_ITEMS = 3;

function formatNumber(number, pow = 0) {
  if (Number.isNaN(number) || number < 1000) {
    return [number, pow];
  }

  return formatNumber(Math.round(number / 10) / 100, pow + 1);
}

export const Summary = ({
  chipData,
  chipStatus,
  className,
  currency,
  items,
  heading = 0,
  highlight = false,
  intl,
  linkTo,
  options,
  percent = false,
  subheading,
  template: Template,
  title,
}) => {
  let indicator;

  if (heading !== undefined) {
    const [number, pow] = formatNumber(heading);
    const unit = MAGNITUDES[pow] || '';

    indicator = `${number}${unit}`;

    if (currency) {
      indicator = intl.formatNumber(number, {
        currency,
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
        style: 'currency',
      });

      indicator = indicator.replace(/[0-9,.]+/i, `$&${unit}`);
    }

    if (percent) {
      indicator = `${number}${unit}%`;
    }
  }

  return (
    <Card className={className} data-highlight={highlight} title={title}>
      {linkTo && (
        <FormattedMessage
          capitalize
          component={Link}
          id="ACTIONS.SEE_ALL"
          placeholder="see all"
          to={linkTo}
        >
          See all
        </FormattedMessage>
      )}

      <Heading noEmptyState highlight={highlight} placeholder="400K">
        {indicator}
        {chipStatus === 'red' && <ChipRed>{chipData}</ChipRed>}
        {chipStatus === 'green' && <Chip>{chipData}</Chip>}
      </Heading>
      {subheading && <Subheading>{subheading}</Subheading>}
      {items && (
        <Table
          items={items.slice(0, MAX_ITEMS)}
          options={options}
          scrollable={false}
          template={Template}
        />
      )}
    </Card>
  );
};

Summary.propTypes = {
  chipData: PropTypes.string,
  chipStatus: PropTypes.string,
  className: PropTypes.string,
  currency: PropTypes.string,
  heading: PropTypes.number,
  highlight: PropTypes.bool,
  intl: PropTypes.intl,
  items: PropTypes.arrayOf(PropTypes.object),
  linkTo: PropTypes.string,
  options: PropTypes.object,
  percent: PropTypes.bool,
  placeholders: PropTypes.shape({
    heading: PropTypes.string,
    subheading: PropTypes.string,
  }),
  subheading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  template: PropTypes.component,
  title: PropTypes.string,
};

Summary.defaultProps = {
  placeholders: {
    heading: 'summary',
  },
};

export default compose(
  css`
    max-height: 42rem;
    min-height: 14rem;

    &[data-highlight='true'] {
      background-image: linear-gradient(
        160deg,
        ${theme('--color-secondary')} 0%,
        ${theme('--color-trust')} 100%
      );
      color: ${theme('--color-light')};

      ${Title} {
        color: ${theme('--color-light')};
      }
    }

    ${Chip} {
      background: rgba(75, 204, 31, .2);
      border: none;
      border-radius: 0.6rem;
      color: ${theme('--color-success')};
      font-size: large;
      margin: 0 0.8rem 0.8rem 1rem;
    }

    ${ChipRed} {
      background: #eaccff;
      border: none;
      border-radius: 0.6rem;
      color: #9500ff;
      font-size: large;
      margin: 0 0.8rem 0.8rem 1rem;
    }

    ${Link} {
      position: absolute;
      right: 2.4rem;
      top: 2.4rem;
    }

    ${Heading} {
      ${theme('--font-xxlarge')}
      ${theme('--font-weight-medium')}
      align-items: center;
      display: flex;
    }

    ${Subheading} {
      ${theme('--font-medium')}
      ${theme('--font-opacity-40')}
      ${theme('--font-weight-book')}
    }

    ${Table} {
      height: 24rem;
      margin: 0 -2.4rem;
      margin-top: ${props => (props.subheading ? '2.4rem' : '1.6rem')};
    }
  `,
  withIntl,
)(Summary);
