import React from 'react';
import PropTypes from '@prop-types';
import { FormattedMessage } from '@react-intl';
import { compose } from 'redux';
import styled, { withStyles as css, theme } from '@styled-components';

import Button from 'Components/Button';
import Form from 'Components/Form';
import { withRouter } from 'Components/Router';

import { Answer, CounterQuestion, Questions, Score, Tags } from './components';

const Column = styled('div')``;
const Heading = styled('h5')``;

const getHeading = role => {
  switch (role) {
    case 'SUPER_ADMIN':
      return 'INSTRUCTIONS.FAQ_CONFIGURATION.SUPER_ADMIN';
    case 'ADMIN':
    case 'WRITER':
      return 'INSTRUCTIONS.FAQ_CONFIGURATION.ADMIN';
    default:
      return 'INSTRUCTIONS.FAQ_CONFIGURATION.READER';
  }
};

const FaqForm = ({
  account,
  className,
  defaultValue,
  data: locales,
  onSubmit,
  role,
}) => {
  const isSuperAdmin = role === 'SUPER_ADMIN';
  const isNotReader = role !== 'READER';

  if (!defaultValue || !locales) return null;

  const [locale] = locales;

  return (
    <Form
      className={className}
      constraint={{
        answer: {
          shape: { content: { required: { message: 'ANSWER_REQUIRED' } } },
        },
        questions: {
          arrayOf: {
            shape: {
              text: {
                required: {
                  message: 'QUESTION_REQUIRED',
                },
              },
            },
          },
        },
      }}
      defaultValue={defaultValue}
      onSubmit={onSubmit}
    >
      <FormattedMessage capitalize component={Heading} id={getHeading(role)}>
        examine faq configuration
      </FormattedMessage>
      <Column>
        <Questions role={role} />
      </Column>
      <Column>
        <Answer role={role} />
        <Tags account={account} locale={locale} role={role} />
        {isSuperAdmin && <Score />}
        {isSuperAdmin && <CounterQuestion />}
      </Column>
      {isNotReader && (
        <FormattedMessage
          capitalize
          primary
          component={Button}
          id="SAVE"
          type="submit"
        />
      )}
    </Form>
  );
};

FaqForm.propTypes = {
  account: PropTypes.number,
  campaign: PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
  className: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.string),
  defaultValue: PropTypes.shape({
    answer: PropTypes.shape({
      content: PropTypes.string,
      description: PropTypes.string,
      type: PropTypes.string,
    }),
    questions: PropTypes.arrayOf(PropTypes.object),
    score: PropTypes.number,
    tags: PropTypes.arrayOf(PropTypes.object),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  onSubmit: PropTypes.func,
  role: PropTypes.string,
};

export default compose(
  css`
    display: block;

    ${Heading} {
      ${theme('--font-medium')}
      ${theme('--font-weight-book')}
      ${theme('--font-opacity-40')}
      grid-column: span 2;
      margin-bottom: 1.6rem;
    }

    ${Column} {
      display: flex;
      flex-direction: column;

      > *:not(${Button}) {
        margin-bottom: 3.2rem;
      }
    }

    ${Heading} {
      margin-bottom: 1.6rem;
    }

    > ${Button} {
      width: 100%;
    }

    @media (${theme('--screen-small')}) {
      display: grid;
      grid-gap: 0 3.2rem;
      grid-template-columns: 7fr 5fr;

      ${Heading} {
        grid-column: span 2;
        grid-row: 1;
      }

      ${Column} {
        grid-row: 2;
      }

      > ${Button} {
        grid-row: 3;
        width: 16rem;
      }
    }
  `,
  withRouter,
)(FaqForm);
