import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import C from 'Constants';
import rootEffect from 'Effects';
import rootReducer from 'Reducers';

import storage from 'Providers/storage';

const DEVTOOL_COMPOSE = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__; // eslint-disable-line
const composer = DEVTOOL_COMPOSE || compose;
const KEY = C.STORE;

if (!storage.get(KEY)) {
  storage.set(KEY, { session: {}, settings: {} });
}

export function configureStore(initialState) {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware];

  const enhancer = composer(applyMiddleware(...middlewares));
  const store = createStore(rootReducer, initialState, enhancer);

  store.subscribe(() => {
    storage.set(KEY, store.getState());
  });

  sagaMiddleware.run(rootEffect);

  if (module.hot) {
    module.hot.accept('Reducers', () => {
      store.replaceReducer(rootReducer);
    });
  }

  return store;
}

export default configureStore(storage.get(KEY));
