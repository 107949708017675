import React from 'react';
import PropTypes from 'prop-types';
import styled, { withStyles as css, theme } from '@styled-components';
import { compose } from 'underscore';
import axios from 'axios';
import fileDownload from 'js-file-download';

import Chip from 'Components/Chip';
import ContextMenu from './ContextMenu';
import { useHistory } from 'react-router-dom';

const Content = styled('div')``;
const Content2 = styled('div')``;
const Header = styled('header')``;
const Name = styled('h3')``;
const Title = styled('p')``;
const Video = styled('video')``;

const PlayIcon = styled('img')``;
const Img = styled('img')``;
const AudioImg = styled('img')``;

const platforms = {
  FB_MESSENGER: '/images/icons/fb_messenger.svg',
  WHATSAPP: '/images/icons/whatsapp.png',
};

function extractNameFromUrl(url) {
  if (!url) return url;
  const arr = url.split('/');
  return arr[arr.length - 1];
}

const Card = ({
  id,
  key,
  className,
  onRowSelect: handleRowSelect = () => {},
  source,
  platform,
  tags,
  type,
}) => {
  const { push } = useHistory();
  const icon = platforms[platform];
  const name = extractNameFromUrl(source);
  const sourceUrl = source || '/images/placeholder.svg';
  const handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: 'blob',
      })
      .then(res => {
        fileDownload(res.data, filename);
      });
  };

  return (
    <section className={className}>
      {['audio', 'voice', 'video'].indexOf(type) < 0 && (
        <Header
          style={
            sourceUrl && {
              backgroundImage: `url(${sourceUrl})`,
              backgroundSize: 'cover',
            }
          }
        >
          {id && (
            <ContextMenu
              onDelete={() =>
                handleRowSelect({ id, mediaUrl: source }, 'delete')
              }
              onDownload={() => handleDownload(source, name)}
              onEdit={() => push(`/builder/usergeneratedcontent/edit/${id}`)}
              onGetLink={() => handleRowSelect({ id, url: source }, 'getLink')}
            />
          )}
        </Header>
      )}
      {['audio', 'voice', 'video'].indexOf(type) >= 0 && (
        <Header>
          <ContextMenu
            onDelete={() => handleRowSelect({ id, mediaUrl: source }, 'delete')}
            onDownload={() => handleDownload(source, name)}
            onEdit={() => push(`/builder/usergeneratedcontent/edit/${id}`)}
            onGetLink={() => handleRowSelect({ id, url: source }, 'getLink')}
          />
          {['audio', 'voice'].indexOf(type) >= 0 && (
            <AudioImg src="/images/audio.svg" />
          )}
          {type === 'video' && <Video autoPlay loop muted src={source} />}
        </Header>
      )}
      <Content>
        <Img src={icon} />
        <Name capitalize translate>
          {name}
        </Name>
      </Content>
      <Content2>
        {tags &&
          tags.map(tag => (
            <Chip key={`${tag}-${key}`} capitalize>
              {tag}
            </Chip>
          ))}
      </Content2>
    </section>
  );
};

Card.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  key: PropTypes.string,
  onRowSelect: PropTypes.func,
  platform: PropTypes.string,
  source: PropTypes.string,
  tags: PropTypes.object,
  type: PropTypes.string,
};

export default compose(
  css`
    border-radius: 0.6rem;
    box-shadow: inset 0 0.1rem 0 rgba(0, 0, 0, 0.1);
    position: relative;

    ${Header} {
      background-color: ${theme('--color-primary-10')};
      background-image: url(/images/blank.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: auto;
      border-radius: 0.6rem 0.6rem 0 0;
      height: 14.8rem;
      ${ContextMenu} {
        margin-left: 85%;
        margin-top: 1rem;
        position: absolute;
        z-index: 100;
        > button {
          background: ${theme('--color-light')};
          height: 3.2rem;
          margin-left: auto;
          width: 3.2rem;
        }
        li {
          ${theme('--font-weight-book')}
          ${theme('--font-medium')}
          color: ${theme('--color-dark-night-60')};

          &:last-of-type > button {
            color: ${theme('--color-alert')};
          }
        }

        article {
          top: 3.6rem;
        }
      }
    }

    ${AudioImg} {
      height: 9rem;
      margin-top: 10%;
      width: 28vh;
    }

    ${Video} {
      height: 100%;
      width: 100%;
    }

    ${Img} {
      float: left;
      height: 2rem;
      margin-bottom: 0.4rem;
      margin-right: 0.7rem;
    }

    ${Content} {
      background: ${theme('--color-light')};
      border-radius: 0 0 0 0;
      height: 5.5rem;
      padding: 1.6rem;

      ${Name} {
        ${theme('--font-opacity-80')}
        ${theme('--font-weight-demi')}
        margin-bottom: 0.2rem;
        margin-top: 0.3rem;
        overflow-wrap: break-word;
      }

      ${Title} {
        ${theme('--font-opacity-60')}
        ${theme('--font-weight-book')}
        margin-bottom: 1.2rem;
      }
    }

    ${Content2} {
      background: ${theme('--color-light')};
      border-radius: 0 0 0.6rem 0.6rem;
      height: 4.9rem;
      padding: 1.6rem;
      ${Chip} {
        background: ${theme('--color-main-20')};
        border: 0;
        border-radius: 0.6rem;
        color: ${theme('--color-main')};
        margin-right: 0.2rem;
      }
    }

    ${PlayIcon} {
      left: 0;
      margin: 0 auto;
      position: absolute;
      right: 0;
      top: calc(25%);
    }

  `,
)(Card);
