import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import styled, { withStyles as css, theme } from '@styled-components';
import { FormattedMessage } from '@react-intl';

import * as logger from 'Actions/logger';

import { currentAccount } from 'Selectors';

import services from 'Services/customers';

import Button from 'Components/Button';
import Icon from 'Components/Icon';
import Form, { AutoSuggest, File, Input, Radio } from 'Components/Form';
import Link from 'Components/Link';

const Actions = styled('div')``;
const Box = styled('div')``;
const Content = styled('div')``;
const Filename = styled('div')``;
const FilenameWrapper = styled('div')``;
const FileWrapper = styled('div')``;
const Label = styled('label')``;
const Legend = styled('legend')``;
const Loader = styled('div')``;
const Text = styled('p')``;

const mapStateToProps = state => ({
  account: currentAccount(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ success: logger.success }, dispatch);

export class AddCustomer extends Component {
  static propTypes = {
    account: PropTypes.number,
    className: PropTypes.string,
    error: PropTypes.func,
    groups: PropTypes.arrayOf(PropTypes.object),
    history: PropTypes.func,
    onAccept: PropTypes.func,
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    success: PropTypes.func,
  };

  static defaultProps = {
    onAccept() {},
    onSuccess() {},
    success() {},
  };

  state = {
    fileName: '',
    isLoading: false,
    name: '',
    value: 'new',
  };

  handleUpload = async ({ csv, groups = [], newGroupName, groupType }) => {
    const {
      account,
      error,
      history,
      onAccept: handleAccept,
      onSuccess: handleSuccess,
      success,
    } = this.props;
    const groupNames =
      groupType === 'existing'
        ? groups.map(({ name }) => name)
        : [].concat(newGroupName);

    this.setState({ isLoading: true });
    try {
      await services.importCustomersBackground({
        account,
        customers: csv,
        groupNames,
      });
      success('CUSTOMER_IMPORTED');
      handleSuccess();
      this.setState({ isLoading: false });

      if (history) {
        history.push('/customers');
      }
    } catch (e) {
      error('CUSTOMER_NOT_IMPORTED');
    }
    handleAccept();
  };

  onChange = checked => {
    this.setState({ value: checked });
  };

  handleChange = name => {
    this.setState({ name });
  };

  handleFileChange = file => {
    this.setState({ fileName: file.name });
  };

  render() {
    const { className, groups, onClose } = this.props;
    const { isLoading, name, fileName, value } = this.state;

    return (
      <Form className={className} onSubmit={this.handleUpload}>
        <Content>
          <FormattedMessage
            capitalize
            component={Legend}
            id="CHOOSE_CUSTOMER_FILE"
          >
            Choose customer file
          </FormattedMessage>
          <Text>
            <FormattedMessage capitalize id="INSTRUCTIONS.IMPORT_CUSTOMERS" />
            <FormattedMessage
              component={Link}
              id="IMPORT_CUSTOMERS.LINK"
              target="_blank"
              to="/files/customers.xls"
            />
          </Text>
          <FileWrapper>
            <File
              accept="csv/*"
              name="csv"
              onChange={this.handleFileChange}
              placeholder="CHOOSE_A_FILE"
              type="file"
            />
            {fileName && (
              <FilenameWrapper>
                <Icon name="file" />
                <Filename>{fileName}</Filename>
              </FilenameWrapper>
            )}
          </FileWrapper>
          <FormattedMessage
            capitalize
            component={Legend}
            id="CHOOSE_GROUP_TYPE"
          >
            Optional: Add to existing group or create new group
          </FormattedMessage>
          <Text>
            <FormattedMessage capitalize id="INSTRUCTIONS.CHOOSE_GROUP_TYPE" />
          </Text>
          <Box>
            <Radio
              content="new"
              id="new"
              name="groupType"
              onChange={checked => this.onChange(checked)}
              value={value}
            />
            <FormattedMessage
              component={Label}
              id="INSTRUCTIONS.RADIO_BUTTON.NEW_GROUP"
            >
              Create new group with these customers
            </FormattedMessage>
          </Box>
          {value === 'new' && (
            <Input
              label="PLACEHOLDER.IMPORT_CUSTOMERS.INPUT_GROUP_NAME"
              name="newGroupName"
              onChange={this.handleChange}
              value={name}
            />
          )}
          <Box>
            <Radio
              content="existing"
              id="existing"
              name="groupType"
              onChange={checked => this.onChange(checked)}
              value={value}
            />
            <FormattedMessage
              component={Label}
              id="INSTRUCTIONS.RADIO_BUTTON.EXISTING_GROUP"
            >
              Add to existing customer groups
            </FormattedMessage>
          </Box>
          {value === 'existing' && (
            <AutoSuggest name="groups" options={groups} placeholder="GROUPS" />
          )}
        </Content>
        {isLoading && (
          <div style={{ display: 'flex', marginTop: '2rem' }}>
            <Loader />
            <div className="uploadingText">Uploading...</div>
          </div>
        )}
        <Actions>
          <Button primary type="submit">
            <FormattedMessage capitalize id="IMPORT" />
          </Button>
          <Button secondary onClick={onClose}>
            <FormattedMessage capitalize id="ACTIONS.BACK" />
          </Button>
        </Actions>
      </Form>
    );
  }
}

export default compose(
  css`
    ${Legend} {
      ${theme('--font-medium')}
      ${theme('--font-opacity-100')}
      ${theme('--font-weight-demi')}
      margin-bottom: 0.8rem;
    }

    ${Text} {
      ${theme('--font-medium')}
      ${theme('--font-opacity-40')}
      ${theme('--font-weight-book')}
      margin-bottom: 2rem;

      ${Link} {
        margin-left: 0.4rem;
      }
    }

    ${FileWrapper} {
      display: flex;
      margin-bottom: 5rem;

      ${File} {
        width: 18rem;
      }

      ${FilenameWrapper} {
        display: flex;

        ${Icon} {
          height: 40px;
          margin: auto 0 auto 2rem;
        }

        ${Filename} {
          color: ${theme('--color-primary-80')};
          display: flex;
          margin: auto 0 auto 0.5rem;
        }
      }
    }

    ${AutoSuggest} {
      margin-bottom: 2rem;
    }

    ${Box} {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      margin-top: 1.5rem;

      ${Radio} {
        margin-right: 0.8rem;
      }

      ${Label}, span {
        ${theme('--font-medium')}
        ${theme('--font-opacity-60')}
        ${theme('--font-weight-book')}
        display: block;
        margin-bottom: 0.4rem;
      }
    }

    ${Loader} {
      -webkit-animation: spin 2s linear infinite; /* Safari */
      animation: spin 2s linear infinite;
      border: 3px solid #f3f3f3;
      border-radius: 50%;
      border-top: 3px solid #005dff;
      display: inline-block;
      height: 20px;
      width: 20px;
    }

    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }

    .uploadingText {
      color: #666f75;
      margin: auto 0 auto 5px;
    }

    @media (${theme('--column-12')}) {
      margin: 1.6rem auto;
      max-width: 50rem;

      ${Actions} {
        display: flex;
        margin-top: 4rem;

        ${Button} {
          width: 16rem;

          &:last-child{
            background-color: #FFF;
            margin-left: 1.6rem;
          }
        }
      }
    }
  `,
  connect(mapStateToProps, mapDispatchToProps),
)(AddCustomer);
