import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { range } from 'Selectors';

import { change } from 'Actions/ranges';

import Analytics from './Analytics';

const mapStateToProps = state => ({
  ...range(state, 'Analytics'),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onRangeChange: (filter, from, to) =>
        change('Analytics', { filter, from, to }),
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
