import React from 'react';
import PropTypes from 'prop-types';
import { Blocker, useField } from 'spooner';
import styled, { theme } from '@styled-components';

import Country, { codes } from './Country';
import Input from '../Input';

const Phone = ({ className, label, ...props }) => {
  const {
    disabled = false,
    error,
    name,
    onBlur = () => {},
    onChange = () => {},
    onFocus = () => {},
    placeholder,
    value = '',
    ...fieldProps
  } = useField(props);

  const handleBlur = () => {
    onBlur();
  };

  const handleFocus = () => {
    onFocus();
  };

  const normalize = () => {
    const prefix =
      codes
        .filter(item => (value || '').startsWith(item))
        .reduce((a, b) => (a.length > b.length ? a : b), '') || codes[0];
    const number = (value || '').substr(prefix.length);

    return [prefix, number];
  };

  const handlePrefixChange = prefix => {
    const [, number] = normalize();
    onChange(`${prefix}${number}`);
  };

  const handleNumberChange = number => {
    const [prefix] = normalize();
    onChange(`${prefix}${number}`);
  };

  const [prefix = codes[0], number] = normalize();

  return (
    <Blocker>
      <div className={className}>
        <Country
          disabled={disabled}
          name="prefix"
          onBlur={handleBlur}
          onChange={handlePrefixChange}
          onFocus={handleFocus}
          value={prefix}
        />
        <Input
          {...fieldProps}
          disabled={disabled}
          error={error}
          label={label}
          name="number"
          onBlur={handleBlur}
          onChange={handleNumberChange}
          onFocus={handleFocus}
          placeholder={placeholder}
          value={number}
        />
      </div>
    </Blocker>
  );
};

Phone.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
};

export default styled(Phone)`
  display: flex;
  height: 4.8rem;

  ${Country} {
    ${theme('--font-small')}
    height: 100%;
    margin-right: 0.8rem;
    width: 7.2rem;
  }

  ${Input} {
    height: 100%;
    width: 100%;
  }
`;
