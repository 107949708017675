import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@styled-components';

import TableDataProvider from 'Containers/TableDataProvider';

import { account as accountSelector } from 'Selectors';

import service from 'Services/products';

import Template from './Variants';

const mapStateToProps = state => ({
  ...accountSelector(state),
});

const Variants = ({ id: account, productId, ...props }) => {
  const [search, setSearch] = useState();

  const handleSearch = useCallback(text => {
    setSearch(text);
  }, []);

  let filterAmmount = 0;
  if (search) filterAmmount += 1;

  return (
    <TableDataProvider
      {...props}
      filterAmmount={filterAmmount}
      limit={5}
      name="variants"
      onSearch={handleSearch}
      params={{ account, id: productId, search }}
      query={service.getVariants}
      template={Template}
    />
  );
};

Variants.propTypes = {
  account: PropTypes.number,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  productId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.string,
};

export default compose(withStyles``, connect(mapStateToProps))(Variants);
