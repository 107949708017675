import React from 'react';
import { Gradient, Stop } from 'calvin-svg';
import PropTypes from 'prop-types';
import { FormattedNumber } from '@react-intl';
import { theme, withStyles as css, withTheme } from '@styled-components';
import { compose } from 'underscore';

import Chart, { Funnel, Grid } from 'Components/Chart';
import Definition, {
  Detail as DefinitionDetail,
  Term,
} from 'Components/Definition';
import List, { Item } from 'Components/List';
import { withPlaceholder } from 'Containers/Placeholder';

const Detail = ({ className, data = [], theme: palette }) => {
  const values = data.map(({ value }) => value);

  const [total] = values;

  return (
    <section className={className}>
      <div
        style={{
          maxWidth: 'auto',
          position: 'relative',
          width: `${180 * data.length}px`,
        }}
      >
        <List
          items={data.map((_, index) => index).slice(1)}
          template={({ item }) => `Step ${item}`}
        />
        <Chart>
          <Gradient right id="gradient">
            <Stop color={palette['--color-chart-purple-80']} offset={0} />
            <Stop color={palette['--color-chart-blue']} offset={100} />
          </Gradient>
          <Grid
            hideBorders
            color={palette['--color-dark-night-05']}
            columns={values.length - 1}
          />
          <Funnel
            color="url(#gradient)"
            data={values}
            padding={{ bottom: 24, top: 24 }}
            tooltip={() => {}}
          />
        </Chart>
        <Definition>
          {data.slice(1).map(step => (
            <>
              <DefinitionDetail>
                {/* eslint-disable react/style-prop-object */}
                <FormattedNumber
                  maximumFractionDigits={2}
                  minimumFractionDigits={2}
                  style="percent"
                  value={step.value / total || 0}
                />
              </DefinitionDetail>
              <Term>{step.displayName}</Term>
            </>
          ))}
        </Definition>
      </div>
    </section>
  );
};

Detail.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  theme: PropTypes.object,
};

export default compose(
  css`
    overflow: auto;
    padding-bottom: 2.4rem;

    ${List} {
      display: flex;
      position: absolute;
      width: 100%;

      ${Item} {
        color: ${theme('--color-dark-night-40')};
        flex: 1;
        text-align: center;
      }
    }

    ${Chart} {
      height: 36.8rem;
    }

    ${Definition} {
      grid-gap: 0 !important;
      grid-template: repeat(2, 1fr) / repeat(
          ${props => props.data.length - 1},
          1fr
        ) !important;
      width: 100% !important;

      ${DefinitionDetail}, ${Term} {
        text-align: center;
      }
    }
  `,
  withTheme,
  withPlaceholder,
)(Detail);
