import React from 'react';
import { withStyles } from '@styled-components';
import PropTypes from '@prop-types';
import PieChart from 'Components/Charts/PieChart';
import PieChartLoader from 'Components/PieChartLoader';
import { compose } from 'underscore';

const Template = ({ data = [], className }) => {
  const parsedData = data.reduce((acc, { count, value }) => {
    return { ...acc, [value]: count };
  }, {});

  return (
    <PieChart className={className} data={parsedData} loader={PieChartLoader} />
  );
};

Template.propTypes = {
  className: PropTypes.string,
  data: PropTypes.objectOf(PropTypes.number),
};

export default compose(
  withStyles`
    grid-template-rows: 20rem auto;
    padding-top: 7.2rem;
  `,
)(Template);
