import React, { Component } from 'react';
import symbol from 'currency-symbol-map';
import PropTypes from '@prop-types';
import { withTheme, withStyles as css } from '@styled-components';
import { intlDefault, intlShape, withIntl } from '@react-intl';
import { compose } from 'underscore';

import BarChart from 'Components/Charts/BarChart';

import Scrollable from './Scrollable';

class CampaingsChart extends Component {
  static propTypes = {
    className: PropTypes.string,
    currency: PropTypes.string,
    intl: intlShape,
    revenues: PropTypes.object,
    theme: PropTypes.Object,
  };

  static defaultProps = {
    intl: intlDefault,
  };

  handleTooltip = (ignore, value, index) => {
    const { intl, currency } = this.props;
    const { revenues } = this.props;
    const campaigns = Object.keys(revenues);
    return `${campaigns[index]} - ${intl.formatNumber(value, {
      currency,
      style: 'currency',
    })}`;
  };

  render() {
    const { className, currency, theme: colorTheme, revenues } = this.props;

    const axisX = Object.keys(revenues);
    const data = Object.values(revenues).map(({ value }) => value);

    const legends = axisX.map((el, index) => `C${index + 1}`);
    const colors = [colorTheme['--color-main']];

    const raw = legends.map((x, index) => ({
      x,
      y1: data[index],
    }));

    return (
      <Scrollable>
        <BarChart
          className={className}
          colors={colors}
          data={raw}
          options={{
            y: symbol(currency),
            yLegend: 'REVENUE',
            yTooltip: this.handleTooltip,
          }}
        />
      </Scrollable>
    );
  }
}

export default compose(
  css`
    display: grid;
    height: 100%;
  `,
  withIntl,
  withTheme,
)(CampaingsChart);
