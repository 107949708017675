import React from 'react';
import PropTypes from '@prop-types';
import { FormattedMessage } from '@react-intl';
import styled from '@styled-components';

import { Input } from 'Components/Form';

import { Heading, Card } from './Common';

const Tags = ({ className }) => (
  <div className={className}>
    <FormattedMessage capitalize component={Heading} id="SCORE">
      Answer
    </FormattedMessage>
    <Input
      label="SCORE"
      max={1}
      min={0}
      name="score"
      step={0.1}
      type="number"
    />
  </div>
);

Tags.propTypes = {
  className: PropTypes.string,
};

export default styled(Tags)`
  display: flex;
  flex-direction: column;
  position: relative;

  ${Heading} {
    margin-bottom: 0.8rem;
  }

  ${Card} {
    padding: 1.6rem;
  }
`;
