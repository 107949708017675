import React from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from '@prop-types';
import { FormattedDate } from '@react-intl';

import { Row } from 'Components/ResponsiveTable';
import Currency from 'Components/Currency';
import { PlaceholderNoData as Placeholder } from 'Containers/Placeholder';

const Tag = styled(Placeholder)``;
const NameTag = styled(Tag)``;

const DesktopTemplate = ({
  className,
  campaign,
  createdAt,
  currency,
  empty,
  id,
  onClick,
  value,
}) => {
  return (
    <Row className={className} empty={empty} onClick={onClick(id)}>
      <NameTag placeholder="campaign name">{campaign}</NameTag>
      <Tag placeholder="currency">
        {value !== undefined && (
          <Currency currency={currency}>{value}</Currency>
        )}
      </Tag>
      <Tag placeholder="createdAt">
        {createdAt && (
          <FormattedDate
            day="2-digit"
            month="long"
            value={createdAt}
            year="numeric"
          />
        )}
      </Tag>
    </Row>
  );
};

DesktopTemplate.defaultProps = {
  onClick() {},
};

DesktopTemplate.propTypes = {
  campaign: PropTypes.string,
  className: PropTypes.string,
  createdAt: PropTypes.string,
  currency: PropTypes.string,
  empty: PropTypes.bool,
  id: PropTypes.number,
  onClick: PropTypes.func,
  value: PropTypes.number,
};

export default styled(DesktopTemplate)`
  grid-template-columns: 1fr 15% 15%;
  height: 5.6rem;
  padding: 0 2.4rem;

  ${Tag} {
    ${theme('--font-medium')}
    ${theme('--font-weight-book')}
    ${theme('--font-opacity-40')}
    text-align: left;
  }

  ${NameTag} {
    ${theme('--font-weight-medium')}
    color: ${theme('--color-primary')};
  }
`;
