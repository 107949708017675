import React, { useState } from 'react';
import styled, { withStyles as css, theme } from '@styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import { compose, memoize } from 'underscore';

import Constants from 'Constants';

import {
  getDatesTransformedByUnit,
  getUnit as getUnitRaw,
} from 'Modules/dates';

import Card from 'Components/Card';
import ViewHeadline from 'Components/ViewHeadline';
import View from 'Components/View';

// Sales Analytic
import AverageValuePerPurchaseChart from 'Containers/AverageValuePerPurchaseChart';
import CustomerLifetimeChart from 'Containers/CustomerLifetimeChart';
import AverageItemsPerPurchaseChart from 'Containers/AverageItemsPerPurchaseChart';
import TotalRevenuesChart from 'Containers/TotalRevenuesChart';
import ConversionRatesChart from 'Containers/ConversionRatesChart';
import VisitsAndRevenues from 'Containers/VisitsAndRevenues';
import CustomRangePicker from 'Components/CustomRangePicker';
import PaymentMethods from 'Containers/PaymentMethodsChart';
import Transaction from 'Containers/Transaction';

// Campaign Analytic
import CampaignSummary from 'Containers/CampaignSummary';
import MessagesDelivered from 'Containers/MessagesDelivered';
import CustomersReached from 'Containers/CustomersReached';
import MessageDeliverySuccessRate from 'Containers/MessageDeliverySuccessRate';

const Datepicker = styled('div')``;
const Option = styled('div')``;
const OptionContainer = styled('div')``;

const {
  FILTERS: { LAST_YEAR },
} = Constants;

const getUnit = memoize(getUnitRaw, (...args) => JSON.stringify(args));
const getDates = memoize(getDatesTransformedByUnit, (...args) =>
  JSON.stringify(args),
);

const Analytics = ({
  className,
  filter = LAST_YEAR,
  from: fromInitial = moment()
    .subtract(1, 'year')
    .format(),
  onRangeChange,
  to: toInitial = moment().format(),
}) => {
  const unit = getUnit(fromInitial, toInitial);
  const { from, to } = getDates(unit, fromInitial, toInitial);
  const [analyticType, setAnalyticType] = useState('sales');

  const handleChange = value => () => setAnalyticType(value);

  return (
    <View className={className}>
      <ViewHeadline
        heading="INSTRUCTIONS.ANALYTICS_AREA"
        title="INSTRUCTIONS.STUDY_EXAMINE_DISCOVER"
      />
      <Datepicker>
        <CustomRangePicker
          filter={filter}
          from={from}
          onRangeChange={onRangeChange}
          to={to}
        />
      </Datepicker>

      <OptionContainer>
        <Option
          className={analyticType === 'sales' ? 'active' : ''}
          onClick={handleChange('sales')}
          style={{ borderRadius: '0.6rem 0 0 0.6rem' }}
        >
          <span>Sales</span>
        </Option>
        <Option
          className={analyticType === 'campaign' ? 'active' : ''}
          onClick={handleChange('campaign')}
          style={{ borderRadius: '0 0.6rem 0.6rem 0' }}
        >
          <span>Campaigns</span>
        </Option>
      </OptionContainer>

      {analyticType === 'sales' && (
        <>
          <VisitsAndRevenues from={from} to={to} />
          <PaymentMethods />
          <TotalRevenuesChart from={from} to={to} />
          <ConversionRatesChart from={from} to={to} />
          <AverageItemsPerPurchaseChart from={from} to={to} />
          <AverageValuePerPurchaseChart from={from} to={to} />
          <CustomerLifetimeChart from={from} to={to} />
          <Transaction from={from} to={to} />
        </>
      )}

      {analyticType === 'campaign' && (
        <>
          <CampaignSummary from={from} to={to} />
          <MessagesDelivered from={from} to={to} />
          <CustomersReached from={from} to={to} />
          <MessageDeliverySuccessRate from={from} to={to} />
        </>
      )}
    </View>
  );
};

Analytics.propTypes = {
  className: PropTypes.string,
  filter: PropTypes.string,
  from: PropTypes.string,
  onRangeChange: PropTypes.func,
  to: PropTypes.string,
};

export default compose(
  css`
    ${ViewHeadline} {
      grid-column: span 4;
      grid-row: 1;
    }

    ${Datepicker} {
      grid-column: span 4;
    }

    ${OptionContainer} {
      border: solid 1px transparent;
      border-radius: 0.6rem;
      color: ${theme('--color-dark-night-60')};
      display: flex;
      font-size: 1.2rem;
      grid-column: span 12;
      height: 4.8rem;
    }

    ${Option} {
      background-color: ${theme('--color-light')};
      border: solid 1px ${theme('--color-dark-night-10')};
      border-radius: 0.6rem;
      cursor: pointer;
      padding: 1.5rem;
      text-align: center;
      width: 20rem;
  
      &.active {
        background-color: #E5EFFF;
        border-color: transparent;
        color: ${theme('--color-primary')};
      }
    }

    ${ConversionRatesChart}, ${TotalRevenuesChart} {
      display: flex;
      grid-column: span 4;
      height: 43.6rem;
    }

    ${VisitsAndRevenues} {
      display: block;
    }

    ${CustomerLifetimeChart} {
      max-height: 53rem;
    }

    @media (${theme('--column-8')}) {
      ${Datepicker}, ${Card} {
        grid-column: span 8;
      }
    }

    @media (${theme('--column-12')}) {
      ${Card} {
        grid-column: span 6;
      }

      ${ViewHeadline} {
        grid-column: span 6;
      }

      ${Datepicker} {
        display: flex;
        grid-column: span 6;
        justify-content: flex-end;
      }

      ${CampaignSummary} {
        grid-column: span 3;
      }

      ${MessagesDelivered}, ${CustomersReached}, ${MessageDeliverySuccessRate} {
        grid-column: span 12;
      }

      ${VisitsAndRevenues} {
        grid-column: span 9;
      }

      ${PaymentMethods} {
        grid-column: span 3;
      }

      ${CustomerLifetimeChart}, ${Transaction} {
        grid-column: span 12;
      }
    }
  `,
)(Analytics);
