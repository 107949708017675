/* eslint-disable */
import { useEffect } from 'react';

const useClickOutsideElement = ({ onClickOutside, ref }) => {
  const body = document.querySelector('body');

  const catchClickOutside = () => {
    if (!ref.current.contains(event.target)) {
      onClickOutside();
    }
  };

  useEffect(() => {
    if (ref?.current) {
      body.addEventListener('click', catchClickOutside);

      return () => {
        body.removeEventListener('click', catchClickOutside);
      };
    }
  }, [onClickOutside]);
};

export default useClickOutsideElement;
/* eslint-enable */
