import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'underscore';
import {
  capitalize,
  FormattedMessage,
  intlShape,
  uppercase,
  withIntl,
} from '@react-intl';
import styled, { withStyles as css, theme } from '@styled-components';

import Button from 'Components/Button';
import Form, { Input, Select } from 'Components/Form';

import { withRouter } from 'Components/Router';

import schemas from './schema.json';

const Label = styled('label')``;
const Link = styled('a')``;

const Wrapper = styled('div')``;
const Description = styled('p')``;
const Field = styled('div')``;
const Fields = styled('div')``;
const FieldsTitle = styled('h3')``;
const FieldValue = styled('p')``;
const MadeBy = styled('p')``;
const Submit = styled('div')``;
const Title = styled('h1')``;

function toConstant(string) {
  return uppercase(string.replace(/([A-Z])/g, g => `${g[0].toLowerCase()}`));
}

function createConstraints(schema) {
  return Object.keys(schema).reduce(
    (acc, field) => ({
      ...acc,
      [field]: {
        ...(!schema[field].disabled &&
          !schema[field].optional && {
            required: {
              message: `${toConstant(field)}_REQUIRED`,
            },
          }),
      },
    }),
    {},
  );
}

class EditIntegration extends Component {
  static propTypes = {
    className: PropTypes.string,
    data: PropTypes.object,
    history: PropTypes.object,
    intl: intlShape,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    setDisplayName: PropTypes.func,
  };

  state = {};

  static getDerivedStateFromProps(props) {
    const { data, setDisplayName } = props;

    if (!data) return {};

    const { service, category, logo, status, displayName, ...fields } = data;
    if (displayName) setDisplayName(displayName);

    const schema = schemas[service] || {};
    const editableFields = Object.keys(schema).filter(
      field => !schema[field].disabled,
    );
    const textFields = editableFields
      .filter(field => schema[field].type === 'text')
      .reduce((acc, field) => ({ ...acc, [field]: fields[field] }), {});
    const selectFields = editableFields
      .filter(field => schema[field].type === 'select')
      .reduce(
        (acc, field) => ({
          ...acc,
          [field]: schema[field].options.find(option => fields[option]),
        }),
        {},
      );

    return {
      ...textFields,
      ...selectFields,
    };
  }

  handleChange = values => {
    this.setState({ ...values });
  };

  handleSubmit = values => {
    const {
      data: { service, category, logo, status, ...fields },
      onSubmit: handleSubmit,
      onClose,
    } = this.props;

    const notEditableValues = Object.keys(schemas[service])
      .filter(field => schemas[service][field].disabled)
      .reduce(
        (acc, key) => ({
          ...acc,
          [key]: fields[key],
        }),
        {},
      );
    // FIXME this is because the select in the backend is treated as a checbox
    const transformed = Object.keys(values).reduce((acc, key) => {
      if (schemas[service][key].type === 'select') {
        const { options } = schemas[service][key];
        return {
          ...acc,
          ...options.reduce(
            (a, option) => ({
              ...a,
              [option]: values[key] === option || false,
            }),
            {},
          ),
        };
      }
      return {
        ...acc,
        [key]: values[key],
      };
    }, {});
    handleSubmit({ ...transformed, ...notEditableValues });
    onClose();
  };

  render() {
    const {
      className,
      data: {
        displayName,
        service,
        category,
        logo,
        status,
        url,
        ...fields
      } = {},
      data,
      intl,
    } = this.props;

    if (!data) return null;

    const schema = schemas[service] || {};
    const disabledFields = Object.keys(schema).filter(
      field => schema[field].disabled,
    );
    const editableFields = Object.keys(schema).filter(
      field => !schema[field].disabled,
    );

    const buttonProps = url
      ? {
          component: Link,
          href: url,
          target: 'blank',
        }
      : {
          component: Button,
          primary: true,
        };

    return (
      <div className={className} title={capitalize(displayName || service)}>
        <Wrapper>
          <FormattedMessage
            capitalize
            component={Title}
            id={displayName || service}
          />
          {displayName && displayName.toLowerCase() !== service.toLowerCase() && (
            <MadeBy>
              by <FormattedMessage capitalize id={service} />
            </MadeBy>
          )}
          <FormattedMessage
            capitalize
            component={Description}
            id={`INSTRUCTIONS.EDIT_INTEGRATION.${service}`}
          />
          <FormattedMessage
            capitalize
            component={FieldsTitle}
            id="DETAILED_INFO"
          />
          <Fields>
            {disabledFields.map(field => (
              <Field key={field}>
                <FormattedMessage
                  capitalize
                  component={Label}
                  id={`PLACEHOLDER.${toConstant(field)}`}
                />
                :
                <FieldValue>
                  {fields[field]
                    ? fields[field]
                        .toString()
                        .split(',')
                        .join(', ')
                    : intl.formatMessage({ capitalize: true, id: 'NO_VALUE' })}
                </FieldValue>
              </Field>
            ))}
          </Fields>
          <Form
            className={className}
            constraint={createConstraints(schema)}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            value={this.state}
          >
            {editableFields.map(field => {
              const { type } = schema[field];
              if (type === 'select') {
                const options = schema[field].options.map(option => ({
                  name: intl.formatMessage({
                    capitalize: true,
                    id: `PLACEHOLDER.${toConstant(option)}`,
                  }),
                  value: option,
                }));

                return <Select key={field} name={field} options={options} />;
              }
              if (type === 'text' || type === 'password') {
                const label = intl.formatMessage({
                  capitalize: true,
                  id: `PLACEHOLDER.${toConstant(field)}`,
                });

                return (
                  <Input
                    key={field}
                    label={label}
                    name={field}
                    placeholder={fields[field] && fields[field].toString()}
                  />
                );
              }
              return null;
            })}
            <Submit>
              <FormattedMessage
                {...buttonProps}
                capitalize
                id="ACTIONS.UPDATE_INTEGRATION"
              >
                Update
              </FormattedMessage>
            </Submit>
          </Form>
        </Wrapper>
      </div>
    );
  }
}

export default compose(
  css`
    display: flex;
    flex-direction: column;

    ${Form}, ${Fields} {
      background: transparent;
    }

    ${Wrapper} {
      align-items: center;
      background-color: ${theme('--color-blueish-white')};
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      max-width: 44rem;

      > * {
        width: 100%;
      }

      ${Title} {
        ${theme('--font-large')}
        ${theme('--font-weight-medium')}
        ${theme('--font-opacity-100')}
        margin-bottom: 0.8rem;
      }

      ${MadeBy} {
        ${theme('--font-medium')}
        ${theme('--font-weight-book')}
        ${theme('--font-opacity-40')}
      }

      ${Description} {
        ${theme('--font-medium')}
        ${theme('--font-weight-book')}
        ${theme('--font-opacity-40')}
        margin: 2.4rem 0;
      }

      ${FieldsTitle} {
        ${theme('--font-small')}
        ${theme('--font-weight-demi')}

        margin-bottom: 0.8rem;
      }

      ${FieldValue} {
        ${theme('--font-small')}
        ${theme('--font-weight-book')}
        margin-top: 0.8rem;
        max-width: 44rem;
        word-break: break-all;
      }

      ${Field} {
        ${theme('--font-small')}
        ${theme('--font-opacity-40')}
        margin-bottom: 1.6rem;

        ${Label} {
          ${theme('--font-weight-medium')}
        }
      }

      ${Form} {
        margin-top: 0.8rem;
        ${Input} {
          margin: 0.8rem 0;
          width: auto;
        }
      }

      ${Select} {
        height: 4.8rem;
        width: 100%;
      }

      ${Submit} {
        background-color: ${theme('--color-blueish-white')};
        display: flex;
        margin-top: 1.6rem;
        text-align: left;

        ${Button}, ${Link} {
          width: 100%;
        }

        ${Link} {
          ${theme('--font-medium')}
          ${theme('--font-weight-medium')}
          background-color: ${theme('--color-primary')};
          border-radius: 0.6rem;
          color: ${theme('--color-light')};
          cursor: pointer;
          outline: 0;
          padding: 1.2rem;
          text-align: center;
        }
        :first-child {
          margin-right: 1.6rem;
        }
      }
    }

    @media (${theme('--screen-small')}) {
      ${Wrapper} ${Submit} {
        ${Button}, ${Link} {
          width: 16rem;
        }
      }
    }

    @media (${theme('--column-8')}) {
      ${Wrapper} {
        padding: 0.9rem 0;
      }
    }
  `,
  withIntl,
  withRouter,
)(EditIntegration);
