import React from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';

import Chip from 'Components/Chip';

const MAX_CHARS = 30;

function getChips(groups) {
  let counter = 0;
  let chips = [];
  groups.forEach(group => {
    counter += group.length;
    if (counter < MAX_CHARS) {
      chips = [...chips, group];
    }
  });
  if (counter >= MAX_CHARS) {
    chips = [...chips, '...'];
  }
  return chips;
}

const Categories = ({ categories, className }) => (
  <div className={className}>
    {getChips(categories).map(tag => (
      <Chip key={tag}>{tag}</Chip>
    ))}
  </div>
);

Categories.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
};

export default styled(Categories)`
  display: flex;
  flex-basis: 0;
  flex-grow: 2;
  text-align: left;

  ${Chip} {
    background-color: ${theme('--color-main-20')};
    border: none;
    color: ${theme('--color-main')};
    margin: 0.1rem;
  }
`;
