import React, { Component } from 'react';
import PropTypes from '@prop-types';
import styled, { theme } from '@styled-components';
import { FormattedMessage } from '@react-intl';

import Button from 'Components/Button';
import IconButton from 'Components/IconButton';
import Icon from 'Components/Icon';

import List from 'Components/List';

import Dropdown from './Dropdown';
import Item from './Item';

class ContextMenu extends Component {
  static propTypes = {
    className: PropTypes.string,
    options: PropTypes.objectOf(PropTypes.func),
  };

  state = { isOpen: false };

  toggle = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  };

  render() {
    const { className, options: rawOptions } = this.props;
    const { isOpen } = this.state;

    const options = Object.keys(rawOptions).map(key => ({
      callback: rawOptions[key],
      id: key,
    }));

    return (
      <section className={className} data-open={isOpen}>
        <IconButton
          outline
          active={isOpen}
          data-testid="open-context-menu"
          name="more"
          onClick={this.toggle}
        >
          ACTIONS.CONTEXT_MENU
        </IconButton>

        <Dropdown in={isOpen} onClick={this.toggle}>
          <List
            noWrapper
            data={options}
            template={({ id, callback }) => (
              <Item key={id}>
                <FormattedMessage
                  capitalize
                  component={Button}
                  id={id}
                  onClick={() => {
                    this.toggle();
                    callback();
                  }}
                >
                  {id}
                </FormattedMessage>
              </Item>
            )}
          />
        </Dropdown>
      </section>
    );
  }
}

export default styled(ContextMenu).attrs(({ right }) => ({
  right: right || false,
}))`
  position: relative;

  ${Dropdown} {
    left: ${props => (!props.right ? '0' : 'auto')};
    right: ${props => (props.right ? '0' : 'auto')};
    top: 5.6rem;
    width: 20rem;
    z-index: 1001;
  }

  ${IconButton} {
    align-items: center;
    display: flex;
    height: 4.8rem;
    justify-content: center;
    width: 4.8rem;

    ${Icon} {
      display: inline-block;
      height: 3.2rem;
      width: 3.2rem;
    }
  }

  ${List} {
    display: flex;
    flex-direction: column;
    max-height: 28.8rem;
    width: 20rem;

    ${Button} {
      ${theme('--font-small')}
      ${theme('--font-opacity-40')}
      ${theme('--font-weight-book')}
      border-radius: 0;
      display: block;
      height: 100%;
      padding: 1.6rem 2.4rem;
      text-align: left;
      width: 100%;

      &:focus {
        background: ${theme('--color-primary-10')};
        box-shadow: none;
      }
    }
  }

  &[data-open='true'] {
    & > ${Button} {
      background: linear-gradient(
        180deg,
        rgba(0, 132, 255, 0.2) 0%,
        rgba(0, 132, 255, 0.1) 100%
      );

      ${Icon} * {
        fill: ${theme('--color-primary')};
      }
    }
  }
`;
