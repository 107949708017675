import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, theme } from '@styled-components';

import Avatar from 'Components/Avatar';
import Button from 'Components/Button';
import Icon from 'Components/Icon';
import { Item } from 'Components/List';

// The data.image path is provisional. Waiting for the final shape to be defined
const DefaultHeaderTemplate = ({ className, data: { label, image } = {} }) => (
  <div className={className}>
    <Avatar image={image || '/images/default-company.svg'} />
    <span>{label}</span>
    <Icon name="vertical-arrows">SELECT_ACCOUNT</Icon>
  </div>
);

DefaultHeaderTemplate.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    image: PropTypes.string,
    label: PropTypes.string,
  }),
};

const activeMixin = ({ active, value }) =>
  active === value &&
  css`
    background: ${theme('--primary-background-color')};
    color: ${theme('--text-primary-color')};
  `;

export const HeaderTemplate = styled(DefaultHeaderTemplate)`
  ${theme('--font-large')}
  align-items: center;
  cursor: pointer;
  display: flex;
  text-align: right;
  ${activeMixin}

  ${Icon} {
    ${theme('--font-xlarge')}
    line-height: 3rem;
    margin-left: 2rem;
  }

  ${Avatar} {
    border-radius: 0.6rem;
    height: 4rem;
    margin-right: 2rem;
    min-width: 4rem;
  }

  ${Icon} {
    margin: 0;
  }
`;

const Wrapper = styled('div')``;

const DefaultDropdownTemplate = ({
  className,
  data: { label } = {},
  onClick = () => {},
  value,
}) => (
  <Item className={className}>
    <Button onClick={() => onClick(value)}>
      <Wrapper>{label}</Wrapper>
    </Button>
  </Item>
);

DefaultDropdownTemplate.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  onClick: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
};

export const DropdownTemplate = styled(DefaultDropdownTemplate)`
  height: 4.8rem;
  padding: 0;
  width: 100%;

  ${Button} {
    height: 100%;
    padding: 0 2.4rem;
    transition: background-color 0.35s ease-out;
    width: 100%;

    ${Wrapper} {
      ${theme('--font-small')}
      ${theme('--font-weight-book')}
      ${theme('--font-opacity-40')}
      align-items: center;
      border-bottom: 0.1rem solid ${theme('--color-dark-night-05')};
      display: flex;
      height: 100%;
    }

    &:hover {
      ${theme('--font-opacity-100')}
      background: #f2f7ff; /* PRIMARY 05 */
    }
  }
`;

export default {
  DropdownTemplate,
  HeaderTemplate,
};
