import React from 'react';
import { withStyles, withTheme } from '@styled-components';
import PropTypes from '@prop-types';
import { compose } from 'underscore';

import PieChart from 'Components/Charts/PieChart';

const EmptyTemplate = ({ className, theme }) => (
  <PieChart
    className={className}
    colors={[theme['--color-dark-night-05']]}
    data={{
      NO_PLATFORM: 1,
    }}
  />
);

EmptyTemplate.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.theme,
};

export default compose(
  withStyles`
    grid-template-rows: 20rem auto;
    padding-top: 7.2rem;
  `,
  withTheme,
)(EmptyTemplate);
