export const SEPARATOR = ',';

// Visist the following page for more info about international address formats
// https://msdn.microsoft.com/en-us/library/cc195167.aspx
export function address(code) {
  switch (code) {
    case 'US':
      return [
        ['name'],
        ['company'],
        ['street1'],
        ['street2'],
        ['city', SEPARATOR, 'state', 'postalCode'],
        ['country'],
      ];
    default:
      return [
        ['name'],
        ['company'],
        ['street1'],
        ['street2'],
        ['postalCode', 'city'],
        ['country'],
      ];
  }
}

export default {
  address,
};
