import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import styled from '@styled-components';

export const Currency = ({
  children,
  className,
  currency = 'USD',
  intl,
  precision = 2,
}) => {
  const number = Number.parseFloat(children);
  return (
    <span className={className}>
      {intl.formatNumber(number, {
        currency,
        maximumFractionDigits: precision,
        minimumFractionDigits: precision,
        style: 'currency',
      })}
    </span>
  );
};

Currency.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  currency: PropTypes.string,
  intl: PropTypes.object,
  precision: PropTypes.number,
};

export default styled(injectIntl(Currency))``;
