import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import styled, { withStyles, css, theme, prop } from '@styled-components';
import { FormattedMessage } from '@react-intl';
import { debounce, delay, isEqual } from 'underscore';

import Text from '../Input';

import Options from './Options';
import Tags, { Input } from './Tags';
import withField from '../with/field';

const Background = styled('div')``;
const Container = styled('div')``;
const Error = styled('span')``;
const Hidden = styled('div')``;
const Select = styled('select')``;
const NoVisible = styled(Tags)``;
const Visible = styled('div')``;
const Wrapper = styled('div')``;

export class AutoSuggest extends Component {
  container = createRef();

  input = createRef();

  list = createRef();

  hiddenList = createRef();

  static propTypes = {
    allowCreate: PropTypes.bool,
    className: PropTypes.string,
    closeAfterSelect: PropTypes.bool,
    error: PropTypes.bool,
    id: PropTypes.string,
    isAlwaysOpen: PropTypes.bool,
    isLoading: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onScrollEnd: PropTypes.func,
    onSearch: PropTypes.func,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        disabled: PropTypes.bool,
        name: PropTypes.string,
        value: PropTypes.any,
      }),
    ),
    placeholder: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.any),
  };

  static defaultProps = {
    allowCreate: false,
    closeAfterSelect: false,
    isAlwaysOpen: false,
    onScrollEnd() {},
    onSearch() {},
    options: [],
  };

  state = {
    isOptionsVisible: false,
    open: this.props.isAlwaysOpen,
    options: this.props.options, //eslint-disable-line
    search: '',
    visible: [],
  };

  static getDerivedStateFromProps(props, state) {
    if (!isEqual(props.options, state.options)) {
      return {
        options: props.options,
      };
    }
    return null;
  }

  componentDidMount() {
    const { value } = this.props;
    const {
      current: { offsetHeight },
    } = this.list;

    this.setState({
      height: offsetHeight,
      visible: this.getVisibleChips(value),
    });
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    const { value: prevValue } = prevProps;
    const {
      current: { offsetHeight },
    } = this.list;

    if (!isEqual(value, prevValue)) {
      this.setState({
        height: offsetHeight,
        visible: this.getVisibleChips(value),
      });
    }
  }

  open = () => {
    this.setState({ open: true }, () => {
      if (this.input.current) this.input.current.focus();
    });
  };

  close = () => {
    const { isAlwaysOpen } = this.props;

    if (!isAlwaysOpen) {
      this.setState({ open: false });
    }
  };

  add = option => {
    const { closeAfterSelect, onChange, value = [] } = this.props;

    if (closeAfterSelect) {
      this.setState({ open: false, search: '' });
    } else {
      this.setState({ search: '' }, () => {
        delay(this.open, 300);
      });
    }

    onChange([...value, option]);
  };

  handleChange = event => {
    const { onChange, options } = this.props;

    event.stopPropagation();

    const selected = Array.from(event.target.selectedOptions).map(
      option => option.value,
    );
    const after = options.filter(option => selected.includes(option.name));

    onChange(after);
  };

  handleCreation = event => {
    const { allowCreate } = this.props;
    const { options, search } = this.state;

    event.stopPropagation();

    if (event.keyCode === 9 || event.keyCode === 13) {
      event.preventDefault();

      const exists = options.find(
        ({ name: option = '' }) =>
          option.toLowerCase() === search.toLowerCase(),
      );
      const option = exists || { name: search };

      if ((!options.trim().isEmpty() && exists) || allowCreate) {
        this.add(option);
      }
    }
  };

  handleSearch = event => {
    event.stopPropagation();
    const { onSearch } = this.props;
    onSearch(event.target.value);
    this.setState({ search: event.target.value });
  };

  handleText = search => {
    const { onSearch } = this.props;
    onSearch(search);
    this.setState({ search });
  };

  handleTagsChange = value => {
    const { onChange } = this.props;

    onChange(value);
    if (value.length) {
      delay(this.open, 300);
    }
  };

  toggleOptions = () => {
    this.setState(prevState => ({
      isOptionsVisible: !prevState.isOptionsVisible,
    }));
  };

  getVisibleChips = (chips = []) => {
    const [dots, ...list] = this.hiddenList.current.querySelectorAll(
      'li > span',
    );

    const visibleElements = [...list]
      .reduce((acc, el) => {
        const limit = [...acc, el].reduce(
          (a, e) => a + e.offsetWidth + 8,
          dots.offsetWidth + 8,
        );
        return limit < this.container.current.offsetWidth - 24
          ? [...acc, el]
          : acc;
      }, [])
      .map(el => el.getAttribute('data-tag'));

    const all = visibleElements.map(tag =>
      chips.find(({ name }) => name === tag),
    );

    return chips.length > all.length ? [...all, { name: '...' }] : all;
  };

  render() {
    const {
      allowCreate,
      className,
      id,
      isAlwaysOpen,
      isLoading,
      label,
      onChange,
      onSearch,
      onScrollEnd,
      options: ignore,
      placeholder,
      type,
      value: raw = [],
      error,
      ...props
    } = this.props;
    const {
      open,
      isOptionsVisible,
      options,
      search,
      visible,
      height,
    } = this.state;

    const value = raw.map(option => option.name);
    const optionsAvailable = options
      .filter(item => !value.includes(item.name))
      .filter(
        ({ name: item = '' }) =>
          !search || item.toLowerCase().includes(search.toLowerCase()),
      );

    return (
      <div
        className={className}
        data-error={!!error}
        data-tags={Boolean(raw.length)}
      >
        <Wrapper
          onClick={!open && debounce(this.open, 300)}
          style={{ height: `${height}px` }}
        >
          {open && <Background onClick={debounce(this.close, 300)} />}
          <Container ref={this.container}>
            <Visible ref={this.list}>
              {!!raw.length && (
                <Tags
                  data={open ? raw : visible}
                  onChange={this.handleTagsChange}
                  open={open}
                >
                  {open && (
                    <Input
                      ref={this.input}
                      autoComplete="off"
                      name="search"
                      onBlur={debounce(this.toggleOptions, 300)}
                      onChange={this.handleSearch}
                      onFocus={this.toggleOptions}
                      onKeyDown={this.handleCreation}
                      value={search}
                    />
                  )}
                </Tags>
              )}
            </Visible>
            <Hidden ref={this.hiddenList}>
              <NoVisible
                data={[{ name: '...', visible: false }, ...raw]}
                onChange={onChange}
                open={false}
              />
            </Hidden>
          </Container>
          <Text
            autoComplete="off"
            id={id}
            label="AUTOSUGGEST"
            name="search"
            onBlur={debounce(this.toggleOptions, 300)}
            onChange={this.handleText}
            onFocus={this.toggleOptions}
            onKeyDown={this.handleCreation}
            placeholder={!raw.length ? placeholder : ''}
            type="text"
            value={search}
          />
          {isOptionsVisible && (
            <Options
              allowCreate={allowCreate}
              data={optionsAvailable}
              isLoading={isLoading}
              onDismiss={this.toggleOptions}
              onScrollEnd={onScrollEnd}
              onSelect={this.add}
              option={search}
              style={{ display: isOptionsVisible ? 'block' : 'none' }}
            />
          )}
          <Select
            {...props}
            multiple
            onChange={this.handleChange}
            value={value}
          >
            {options.map(({ name: option }) => (
              <option key={option}>{option}</option>
            ))}
          </Select>
        </Wrapper>
        {error && (
          <FormattedMessage capitalize component={Error} id={`ERRORS.${error}`}>
            {error}
          </FormattedMessage>
        )}
      </div>
    );
  }
}

const alwaysOpenMixin = ({ isAlwaysOpen }) => (isAlwaysOpen ? 'none' : 'block');
const maxHeightMixin = ({ maxHeight }) =>
  maxHeight &&
  css`
    ul {
      max-height: ${prop('maxHeight')};
      overflow: scroll;
    }
  `;

export default compose(
  withStyles`
    position: relative;
    transition: all 0.2s ease-out;

    &[data-error="true"] {
      margin-bottom: 2.4rem !important;

      ${Text} {
        border-color: ${theme('--color-alert')};

        input {
          caret-color: ${theme('--color-alert')};
        }
      }
    }

    ${Wrapper} {
      border: none;
      border-radius: 0.6rem;
      min-height: 4.8rem;
      position: relative;
    }

    ${Background} {
      display: ${alwaysOpenMixin};
      height: 100vh;
      left: 0;
      position: fixed;
      top: 0;
      width: 100%;
    }

    ${Container} {
      ${theme('--font-medium')}
      align-items: center;
      border-radius: 0.6rem;
      display: flex;
      min-height: 100%;
      outline: 0;
      overflow: hidden;
      position: relative;
      width: 100%;

      ${Visible} {
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;
      }

      ${maxHeightMixin}

      &:focus-within {
        overflow: visible;
      }

      ${Tags} {
        background: white;
        border-color: ${theme('--color-dark-night-10')};
        border-style: solid;
        border-width: 0.1rem;
        top: 0;
        transition: border-color 0.25s ease-out;

        &:focus-within {
          border-color: #005DFF;
          border-style: solid;
          border-width: 0.1rem;
        }
      }

      ${Hidden} {
        position: absolute;
        visibility: hidden;

        ${NoVisible}{
          flex-wrap: nowrap;
        }
      }
    }

    ${Text} {
      height: auto;
      position: absolute;
      top: 0;
      width: 100%;

      input {
        padding: 1.6rem;
      }

      label {
        display: none;
      }
    }

    ${Options} {
      background: white;
      height: 20rem;
      width: 100%;
      z-index: 100;

      &.scrollable {
        background-color: ${theme('--color-light')};
        border: 0.1rem solid ${theme('--color-dark-night-10')};
        border-radius: 0.5rem;
        left: 0;
        position: fixed;
        top: 0;
      }
    }

    ${Select} {
      display: none;
    }

    @media (${theme('--screen-medium')}) {
      ${Options} {
        &.scrollable {
          position: relative;
        }
      }
    }

    ${Error} {
      ${theme('--font-small')}
      color: ${theme('--color-alert')};
      display: inline-block;
      height: 1.6rem;
      left: 0;
      margin-top: 0.8rem;
      position: absolute;
      top: 100%;
    }
  `,
  withField(undefined, true),
)(AutoSuggest);
