import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose } from 'underscore';

import { boot } from 'Actions/session';

import { withRouter } from 'Components/Router';

import { isLoaded, isLoggedIn } from 'Selectors';

import Main from './Main';

const mapStateToProps = state => ({
  isLoaded: isLoaded(state),
  isLoggedIn: isLoggedIn(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({ boot }, dispatch);

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Main);
