import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import styled, { withStyles as css, theme } from '@styled-components';
import { compose } from 'underscore';

import Chip from 'Components/Chip';

import ContextMenu from './ContextMenu';

const Content = styled('div')``;
const Header = styled('header')``;
const Name = styled('h3')``;
const Title = styled('p')``;

const Card = ({
  className,
  id,
  image,
  name,
  onRowSelect: handleRowSelect = () => {},
  title,
  type,
  url,
}) => {
  const { push } = useHistory();

  return (
    <section className={className}>
      <Header
        style={
          image && { backgroundImage: `url(${image})`, backgroundSize: 'cover' }
        }
      >
        <ContextMenu
          onDelete={() => handleRowSelect({ id }, 'delete')}
          onEdit={() => push(`builder/${type.toLowerCase()}/edit/${id}`)}
          onGetLink={() => handleRowSelect({ id, url }, 'getLink')}
          url={url}
        />
      </Header>
      <Content>
        <Name capitalize translate>
          {name}
        </Name>
        <Title capitalize translate>
          {title}
        </Title>
        <Chip capitalize>{type}</Chip>
      </Content>
    </section>
  );
};

Card.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  image: PropTypes.string,
  name: PropTypes.string,
  onRowSelect: PropTypes.func,
  title: PropTypes.string,
  type: PropTypes.string,
  url: PropTypes.string,
};

export default compose(
  css`
    border-radius: 0.6rem;
    box-shadow: inset 0 0.1rem 0 rgba(0, 0, 0, 0.1);

    ${Header} {
      background-color: ${theme('--color-primary-10')};
      background-image: url(/images/placeholder.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: auto;
      border-radius: 0.6rem 0.6rem 0 0;
      height: 14.8rem;
      padding: 0.8rem;

      ${ContextMenu} {
        > button {
          background: ${theme('--color-light')};
          height: 3.2rem;
          margin-left: auto;
          width: 3.2rem;
        }
        li {
          ${theme('--font-weight-book')}
          ${theme('--font-medium')}
          color: ${theme('--color-dark-night-60')};

          &:last-of-type > button {
            color: ${theme('--color-alert')};
          }
        }

        article {
          top: 3.6rem;
        }
      }
    }

    ${Content} {
      background: ${theme('--color-light')};
      border-radius: 0 0 0.6rem 0.6rem;
      padding: 1.6rem;

      ${Name} {
        ${theme('--font-opacity-80')}
        ${theme('--font-weight-demi')}
        margin-bottom: 0.4rem;
      }

      ${Title} {
        ${theme('--font-opacity-60')}
        ${theme('--font-weight-book')}
        margin-bottom: 1.2rem;
      }

      ${Chip} {
        background: ${theme('--color-main-20')};
        border: 0;
        border-radius: 0.6rem;
        color: ${theme('--color-main')};
      }
    }
  `,
)(Card);
