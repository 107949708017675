import React, { Component, createRef } from 'react';
import PropTypes from '@prop-types';
import styled, { withStyles as css, theme } from '@styled-components';

import Loader from 'Components/Loader';
import { Redirect, Switch } from 'Components/Router';
import Notifications from 'Containers/Notifications';

import logger from 'Providers/logger';

import { compose } from 'underscore';
import Content from './components/Content';
import Header from './components/Header';
import Navigation from './components/Navigation';

const Wrapper = styled('div')``;

const sectionRegex = /^\/\w+/;
const getSection = str => {
  if (!str) return null;
  const match = str.match(sectionRegex);
  if (!match) return null;

  return match.shift();
};

class Main extends Component {
  element = createRef();

  static propTypes = {
    boot: PropTypes.func,
    children: PropTypes.children,
    className: PropTypes.string,
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    isLoaded: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  };

  static defaultProps = {
    boot() {},
  };

  state = { hasError: false };

  componentDidMount() {
    const { isLoggedIn, boot } = this.props;

    if (isLoggedIn) {
      boot();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      location: { pathname },
    } = this.props;

    const {
      location: { pathname: prevPathname },
    } = prevProps;

    const currentSection = getSection(pathname);
    const prevSection = getSection(prevPathname);
    const hasChangedSection = currentSection !== prevSection;

    if (hasChangedSection) {
      this.element.current.scrollTo(0, 0);
    }
  }

  componentDidCatch(e) {
    logger.error(e);

    this.setState({ hasError: true });
  }

  render() {
    const { children, className, isLoaded, isLoggedIn } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return <Redirect push to="/oops" />;
    }

    if (!isLoggedIn) {
      return <Redirect push to="/login" />;
    }

    if (!isLoaded) {
      return <Loader />;
    }

    return (
      <section className={className}>
        <Navigation />
        <Wrapper>
          <Header />
          <Content ref={this.element}>
            <Switch>{children}</Switch>
          </Content>
        </Wrapper>
        <Notifications />
      </section>
    );
  }
}

export default compose(
  css`
    display: flex;

    ${Navigation} {
      z-index: 2;
    }

    ${Wrapper} {
      flex: 1;
      min-width: 100%;
      z-index: 1;
    }

    @media (${theme('--screen-small')}) {
      ${Wrapper} {
        min-width: auto;
      }
    }
  `,
)(Main);
