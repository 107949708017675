import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
} from 'react';
import PropTypes from '@prop-types';
import { connect } from 'react-redux';

import { currentAccount } from 'Selectors';

const mapStateToProps = state => ({
  account: currentAccount(state),
});

export const Context = createContext({});

const { Provider, Consumer } = Context;

export const useMultiDataProviderContext = name => {
  const { providers } = useContext(Context);
  return providers[name] || {};
};

const MultiDataProvider = ({ children, account }) => {
  const [providers, setProvider] = useState({});
  const handleProvider = useCallback(({ name, unmount, ...provider } = {}) => {
    setProvider(prevProviders => {
      const currentProvider = {
        ...prevProviders,
        [name]: !unmount ? { ...prevProviders[name], ...provider } : undefined,
      };
      Object.keys(currentProvider).forEach(key =>
        currentProvider[key] === undefined ? delete currentProvider[key] : {},
      );
      return currentProvider;
    });
  }, []);

  useEffect(() => {
    setProvider({});
  }, [account, setProvider]);

  return (
    <Provider value={{ onChange: handleProvider, providers }}>
      {children}
    </Provider>
  );
};

MultiDataProvider.propTypes = {
  account: PropTypes.number,
  children: PropTypes.children,
};

export { Consumer, Provider };

export default connect(mapStateToProps)(MultiDataProvider);
