import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@react-intl';
import styled, { theme } from '@styled-components';
import C from 'Constants';

import Avatar from 'Components/Avatar';
import Button from 'Components/Button';
import Chip from 'Components/Chip';
import Icon from 'Components/Icon';
import Link from 'Components/Link';

import withPermissions from 'Components/with/permissions';
import { Row } from 'Components/ResponsiveTable';

const {
  ROLE: { ADMIN, SUPER_ADMIN },
} = C;

const User = styled('div')``;
const Label = styled('span')``;
const Email = styled(Label)``;
const Name = styled(Label)``;
const Role = styled(Chip)``;
const Status = styled(Chip)``;
const Actions = withPermissions([SUPER_ADMIN, ADMIN])(styled('span')``);

const Member = ({
  className,
  email,
  fullName,
  id,
  images: { small },
  isOwner,
  onClick,
  profileId,
  role,
  status,
  url,
}) => {
  return (
    <Row className={className}>
      <User>
        <Avatar image={small} name={fullName} />
        <Name>{fullName}</Name>
      </User>
      <Email>{email}</Email>
      <Label>
        <Status>{status}</Status>
      </Label>
      <Label>
        <FormattedMessage component={Role} id={role}>
          {role}
        </FormattedMessage>
      </Label>
      {profileId !== id && !isOwner ? (
        <Actions>
          <Button as={Link} to={`${url}/users/${id}`}>
            <Icon outline name="edit">
              ACTIONS.EDIT_MEMBER
            </Icon>
          </Button>
          <Button onClick={onClick(id, status)}>
            <Icon outline name="trash-bin">
              ACTIONS.REMOVE_MEMBER
            </Icon>
          </Button>
        </Actions>
      ) : (
        <Actions />
      )}
    </Row>
  );
};

Member.defaultProps = {
  images: { small: '/images/avatar.jpg' },
  onClick() {},
};

Member.propTypes = {
  className: PropTypes.string,
  email: PropTypes.string,
  fullName: PropTypes.string,
  id: PropTypes.number,
  images: PropTypes.shape({ small: PropTypes.string }),
  isOwner: PropTypes.bool,
  onClick: PropTypes.func,
  profileId: PropTypes.number,
  role: PropTypes.string,
  status: PropTypes.string,
  url: PropTypes.string,
};

export default styled(Member)`
  grid-template-columns: 1fr 1fr 9rem 9rem 9rem;
  height: 5.6rem;

  ${Label} {
    ${theme('--font-medium')}
    ${theme('--font-weight-book')}
    ${theme('--font-opacity-40')}
  }

  ${Email} {
    word-break: break-word;
  }

  ${Name} {
    ${theme('--font-weight-medium')}
    ${theme('--font-opacity-100')}
  }

  ${Actions} {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    min-width: 6.5rem;
  }

  ${Status} {
    background-color: ${theme('--color-main-20')};
    border: none;
    color: ${theme('--color-main')};
    max-width: 6rem;
    text-align: center;
  }

  ${User} {
    ${theme('--font-medium')}
    ${theme('--font-opacity-100')}
    ${theme('--font-weight-medium')}
    align-items: center;
    display: flex;
    flex-grow: 5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${Avatar} {
      height: 4rem;
      margin-right: 1.6rem;
      min-height: 4rem;
      min-width: 4rem;
      width: 4rem;
    }
  }

  ${Button} > ${Icon} {
    display: block;
  }
`;
