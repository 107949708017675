import React from 'react';
import { withTheme } from '@styled-components';
import PropTypes from '@prop-types';
import PieChart from 'Components/Charts/PieChart';

const EmptyTemplate = ({ className, theme }) => (
  <PieChart
    className={className}
    colors={[theme['--color-dark-night-05']]}
    data={{
      NO_GENDER: 1,
    }}
  />
);

EmptyTemplate.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.theme,
};

export default withTheme(EmptyTemplate);
