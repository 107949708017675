import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';

import service from 'Services/faqs';

import Placeholder from 'Containers/Placeholder';
import { useMultiDataProviderContext } from 'Containers/MultiDataProvider';

import { Row } from 'Components/ResponsiveTable';
import Button from 'Components/Button';
import Icon from 'Components/Icon';
import Link from 'Components/Link';

import DeleteFaq from '../DeleteFaq';

const Heading = styled(Placeholder.h3)``;
const Label = styled(Placeholder)``;
const QuestionWrapper = styled(Label)``;
const QHeading = styled(Heading)``;
const QuestionCount = styled(Heading)``;
const Actions = styled('div')``;

const DesktopTemplate = ({
  account,
  className,
  empty,
  id,
  questions = [],
  type,
}) => {
  const { refetch } = useMultiDataProviderContext('faqs');
  const [deleteVisible, setDeleteVisible] = useState(false);

  const showDelete = useCallback(() => setDeleteVisible(true), []);
  const hideDelete = useCallback(() => setDeleteVisible(false), []);

  const questionCount = questions.length;
  const question = questions[0] || {};

  const handleDelete = useCallback(async () => {
    await service.del({
      account,
      id,
      type,
    });
    refetch();
    hideDelete();
  }, [account, hideDelete, id, refetch, type]);
  return (
    <>
      <DeleteFaq
        in={deleteVisible}
        onAccept={handleDelete}
        onCancel={hideDelete}
      />
      <Row className={className} empty={empty}>
        <QHeading capitalize translate placeholder="placeholder">
          QUESTION
        </QHeading>
        <QuestionWrapper>{question.text}</QuestionWrapper>
        <QuestionCount placeholder="placeholder">{questionCount}</QuestionCount>
        <Actions>
          <Button as={Link} to={`/builder/faqstudio/${type}_${id}`}>
            <Icon outline name="edit">
              ACTIONS.EDIT_MEMBER
            </Icon>
          </Button>
          <Button onClick={showDelete}>
            <Icon outline name="trash-bin">
              ACTIONS.REMOVE_MEMBER
            </Icon>
          </Button>
        </Actions>
      </Row>
    </>
  );
};

DesktopTemplate.propTypes = {
  account: PropTypes.string,
  className: PropTypes.string,
  empty: PropTypes.bool,
  id: PropTypes.string,
  questions: PropTypes.array,
  type: PropTypes.string,
};

export default styled(DesktopTemplate)`
  border-bottom: 0.1rem solid ${theme('--color-dark-night-10')};
  display: grid;
  grid-gap: 0.8rem;
  grid-template-columns: 7rem 1fr auto;
  height: 12rem;
  margin: 0 2.4rem;
  padding: 1.2rem 0;

  ${Heading} {
    ${theme('--font-medium')}
    ${theme('--font-weight-book')}
    ${theme('--font-opacity-40')}
    grid-column: 1;
  }

  ${QHeading} {
    grid-row: 1;
  }

  ${QuestionCount} {
    grid-column: 3;
    grid-row: 1;
  }

  ${Actions} {
    grid-column: 1;
    grid-row: 2;
  }
`;
