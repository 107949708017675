import React, { useState, useCallback } from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from '@prop-types';
import { FormattedMessage } from '@react-intl';

import Button from 'Components/Button';
import CarouselPicker from 'Components/CarouselPicker';
import Form, { Input, Textarea } from 'Components/Form';
import ViewHeadline from 'Components/ViewHeadline';

import DataProvider from 'Containers/DataProvider';

import { useDeepCompareCallback } from 'Hooks';

import service from 'Services/products';

import Products from './Products';
import SectionHeadline from './SectionHeadline';
import Tabs from './Tabs';
import Tip from 'Components/Tip';

const Actions = styled('div')``;
const Description = styled('p')``;
const Fields = styled('div')``;
const Tooltip = styled('div')``;

const MicroappMediaForm = ({
  account,
  className,
  defaultValue: { templates: defaultValue = [] },
  onBack,
  onChange,
  onSubmit,
}) => {
  const [forms, setForm] = useState(defaultValue);
  const [currentFormIndex, setCurrentFormIndex] = useState(0);

  const tabs = forms.map(({ title }, index) => title || `item ${index + 1}`);

  const handleForm = useDeepCompareCallback(
    form => {
      const newFroms = [
        ...forms.slice(0, currentFormIndex),
        form,
        ...forms.slice(currentFormIndex + 1),
      ];
      setForm(newFroms);

      onChange({
        templates: newFroms,
        type: 'MEDIA',
      });
    },
    [forms, currentFormIndex, onChange],
  );

  const handleAddTab = useCallback(() => {
    setForm(peviousForms => [...peviousForms, {}]);
  }, [setForm]);

  const handleRemove = useCallback(
    index => {
      setCurrentFormIndex(0);
      setForm(peviousForms => [
        ...peviousForms.slice(0, index),
        ...peviousForms.slice(index + 1),
      ]);
    },
    [setForm, setCurrentFormIndex],
  );

  const handleChangeTab = useCallback(
    index => {
      setCurrentFormIndex(index);
    },
    [setCurrentFormIndex],
  );

  return (
    <Form
      className={className}
      onChange={handleForm}
      onSubmit={onSubmit}
      value={{ ratio: 'square', ...forms[currentFormIndex] }}
    >
      <ViewHeadline
        heading="INSTRUCTIONS.MICRO_APP_TEMPLATE_DESCRIPTION.MEDIA"
        title="INSTRUCTIONS.MICRO_APP_TEMPLATE.MEDIA"
      />
      <Tabs
        currentTab={currentFormIndex}
        onAdd={handleAddTab}
        onChange={handleChangeTab}
        onRemove={handleRemove}
        tabs={tabs}
      />
      <CarouselPicker single titleId="IMAGE_OR_VIDEO" />
      <Fields>
        <SectionHeadline heading="INSTRUCTIONS.TEXT_BLOCK" title="TEXT_BLOCK" />
        <Input label="TITLE" name="title" />
        <Input label="SUBTITLE" name="subtitle" />
        <Tip>
          <FormattedMessage component={Tooltip} id="INSTRUCTIONS.HTML_TEXT" />
        </Tip>
        <Textarea
          autoresize
          capitalize
          label="DESCRIPTION"
          name="description"
          rows={3}
        />
      </Fields>
      <SectionHeadline heading="INSTRUCTIONS.TEXT_BLOCK" title="PRODUCTS" />
      <Input capitalize label="TITLE" name="productTitle" />
      <DataProvider
        name="products"
        params={{ account }}
        query={service.get}
        template={Products}
      />
      <Actions>
        <Button capitalize primary translate type="submit">
          ACTIONS.CREATE
        </Button>
        <Button capitalize secondary translate onClick={onBack}>
          ACTIONS.BACK
        </Button>
      </Actions>
    </Form>
  );
};

MicroappMediaForm.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
  defaultValue: PropTypes.object,
  onBack: PropTypes.func,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default styled(MicroappMediaForm)`
  max-width: 49.6rem;

  ${Tabs} {
    margin: 3.2rem 0;
  }

  ${Fields} {
    margin-top: 4rem;
    position: relative;
  }

  ${Description}, ${Input}, ${Textarea} {
    margin-top: 1.6rem;
  }

  ${Description} {
    ${theme('--font-medium')}
    ${theme('--font-opacity-60')}
  }

  ${Tip}{
    margin-top: 1.6rem;
    position: absolute;
    right: -3.6rem;
  }

  ${Products} {
    margin-top: 1.6rem;

    ${Input}{
      margin: 0;
    }
  }

  ${Actions} {
    background: ${theme('--color-blueish-white')};
    border-top: 0.1rem solid ${theme('--color-dark-night-10')};
    bottom: 0;
    display: flex;
    left: 0;
    padding: 2rem;
    position: fixed;
    width: 100vw;

    ${Button} {
      flex-basis: 0;
      flex-grow: 1;
      margin-right: 0.4rem;

      &:last-child {
        margin-left: 0.4rem;
        margin-right: 0;
      }
    }
  }

  @media (${theme('--screen-small')}){
    padding-bottom: 0;

    ${Actions} {
      border: 0;
      display: inline-block;
      margin-top: 4rem;
      padding: 1.6rem 0;
      position: inherit;
      width: auto;

      ${Button} {
        margin: 0 1.6rem 0 0;
        width: 14.4rem;

        &:last-child {
          margin: 0 1.6rem 0 0;
        }
      }
    }
  }
`;
