import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isLoggedIn } from 'Selectors';

import { resetPassword } from 'Actions/session';
import { error } from 'Actions/logger';

import ResetPassword from './ResetPassword';

const mapDispatchToProps = dispatch =>
  bindActionCreators({ error, resetPassword }, dispatch);

const mapStateToProps = state => ({
  isLoggedIn: isLoggedIn(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
