import React, { useCallback } from 'react';
import PropTypes from '@prop-types';
import { connect } from 'react-redux';

import Modal from 'Components/Modal';
import { useMultiDataProviderContext } from 'Containers/MultiDataProvider';

import * as logger from 'Actions/logger';

import { currentAccount } from 'Selectors';

import Services from 'Services/members';

import View from './AddUser';

const mapStateToProps = state => ({
  account: currentAccount(state),
});

const mapDispatchToProps = { error: logger.error, success: logger.success };

const AddUser = ({
  depth,
  in: modalIn,
  onBack,
  onClose,
  order,
  account,
  success,
  error,
}) => {
  const { refetch } = useMultiDataProviderContext('settings');

  const handleSubmit = useCallback(
    async ({ email, firstName, lastName, role }) => {
      const parsedMember = {
        emails: [email],
        firstNames: [firstName],
        lastNames: [lastName],
        role,
      };

      try {
        await Services.sendInvitation({ account, member: parsedMember });
        refetch();
        success('INVITATION_SENT');
        onClose();
      } catch (e) {
        error('INVITATION_NOT_SENT');
        onClose();
      }
    },
    [account, error, onClose, refetch, success],
  );

  return (
    <Modal
      depth={depth}
      in={modalIn}
      onBack={onBack}
      onClose={onClose}
      order={order}
      title="INVITE_NEW_MEMBER"
    >
      <View onSubmit={handleSubmit} />
    </Modal>
  );
};

AddUser.propTypes = {
  account: PropTypes.string,
  depth: PropTypes.number,
  error: PropTypes.func,
  in: PropTypes.bool,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  order: PropTypes.number,
  success: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
