import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withStyles as css } from '@styled-components';
import { withIntl } from '@react-intl';
import _ from 'underscore';

import { Select } from 'Components/Form';

const FiltersType = ({ className, onChange, value }) => {
  const getOptions = _.memoize(() => {
    return [
      {
        name: 'All Types',
        value: 'ALL',
      },
      {
        name: 'Video',
        value: 'VIDEO',
      },
      {
        name: 'Audio',
        value: 'AUDIO',
      },
      {
        name: 'Image',
        value: 'IMAGE',
      },
    ];
  });

  const handleChange = val => {
    if (val) {
      onChange(val);
    }
  };

  const options = getOptions();

  const { name } =
    options.find(({ value: optionValue }) => optionValue === value) ||
    options[0];

  return (
    <Select
      className={className}
      name={name}
      onChange={handleChange}
      options={options}
      value={value}
    />
  );
};

FiltersType.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default compose(
  css`
    min-width: 20rem;
  `,
  withIntl,
)(FiltersType);
