import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';
import ResizeObserver from 'resize-observer-polyfill';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { DateRangePicker } from 'react-dates';
import { END_DATE, START_DATE } from 'react-dates/constants';

export { END_DATE, START_DATE };

const locale = navigator.language || 'en';
const [language] = locale.split(/[-_]/);
moment.locale(language);

const RESPONSIVE_THRESHOLD = 615;

function debounce(callback, timeout) {
  let id = null;

  return function debounced() {
    if (id) {
      clearTimeout(id);
    }

    id = setTimeout(callback, timeout);
  };
}

export class RangePicker extends Component {
  rangePicker = React.createRef();

  resize = debounce(() => {
    if (this.rangePicker.current) {
      const {
        rangePicker: { current: rangePicker },
      } = this;

      const { width } = rangePicker.getBoundingClientRect();

      if (width <= RESPONSIVE_THRESHOLD) {
        this.setState({ isCollapsible: true });
      } else {
        this.setState({ isCollapsible: false });
      }

      // We have to make this to force the isCollapsible to be defined on first render
      this.setState({ key: this.props.filter });
    }
  }, 0);

  resizer = new ResizeObserver(this.resize);

  static propTypes = {
    className: PropTypes.string,
    cursorPosition: PropTypes.string,
    filter: PropTypes.string,
    from: PropTypes.instanceOf(Date),
    onChange: PropTypes.func,
    onCursorChange: PropTypes.func,
    open: PropTypes.bool,
    to: PropTypes.instanceOf(Date),
  };

  static defaultProps = {
    cursorPosition: START_DATE,
    from: moment()
      .subtract(1, 'year')
      .startOf('month')
      .format(),
    onChange() {},
    onCursorChange() {},
    open: false,
    to: moment()
      .startOf('month')
      .format(),
  };

  state = {
    isCollapsible: false,
    key: undefined,
  };

  componentDidMount() {
    this.resize();

    if (this.rangePicker.current) {
      this.resizer.observe(this.rangePicker.current);
    }
  }

  componentDidUpdate({ filter: prevFilter }) {
    const { filter: nextFilter } = this.props;

    if (prevFilter !== nextFilter) {
      this.setState({ key: nextFilter });
    }
  }

  componentWillUnmount() {
    this.resizer.unobserve(this.rangePicker.current);
  }

  getInitialMonth = () => {
    const { to } = this.props;
    const { isCollapsible } = this.state;
    return isCollapsible ? moment(to) : moment(to).subtract(1, 'month');
  };

  renderDayContents = dateObject => {
    const classes = ['day'];
    const content = dateObject.date();
    const outsideRange = this.isOutsideRange(dateObject);
    if (outsideRange) classes.push('day_future');

    const firstOfMonth = content === 1;
    if (firstOfMonth) classes.push('day_first');

    const endOfMonth =
      content ===
      dateObject
        .clone()
        .endOf('month')
        .date();
    if (endOfMonth) classes.push('day_last');

    return (
      <div className={classes.join(' ')}>
        {!outsideRange && <div className="highlight" />}
        <div className="content">{content}</div>
      </div>
    );
  };

  isOutsideRange = dateObject =>
    moment()
      .endOf('day')
      .diff(dateObject) < 0;

  render() {
    const {
      props: { className, cursorPosition, onChange, from, to },
      state: { isCollapsible, key },
    } = this;

    const startDate = moment(from);
    const endDate = moment(to);

    return (
      <div ref={this.rangePicker} className={className}>
        <DateRangePicker
          key={key}
          hideKeyboardShortcutsPanel
          keepOpenOnDateSelect
          endDate={endDate}
          endDateId="end_date_input"
          focusedInput={cursorPosition}
          isOutsideRange={this.isOutsideRange}
          numberOfMonths={isCollapsible ? 1 : 2}
          onDatesChange={onChange}
          onFocusChange={() => null}
          renderDayContents={this.renderDayContents}
          startDate={startDate}
          startDateId="start_date_input"
          {...(endDate && { initialVisibleMonth: this.getInitialMonth })}
        />
      </div>
    );
  }
}

export default styled(RangePicker)`

  * {
    box-sizing: content-box;
  }

  .DateRangePickerInput {
    border: none;
  }

  .DateInput,
  .DateRangePickerInput_arrow {
    display: none;
  }

  .DateRangePicker_picker {
    left: auto !important;
    position: relative;
    top: auto !important;
  }

  .DayPicker__withBorder {
    border: none;
    box-shadow: none;
  }

  .DayPicker,
  .CalendarMonth,
  .CalendarMonthGrid,
  .DayPicker_transitionContainer,
  .CalendarDay {
    /* background: ${theme('--color-blueish-white')}; */
    position: relative;

    @media screen and (${theme('--screen-small')}) {
      background: ${theme('--color-light')};
    }
  }

  .CalendarDay__default {
    padding: 0;

    &:hover {
      background: none;
      border: none;
    }

    .day {
      background: rgba(0,0,0,0);
      border-radius: 0;
      height: 50%;
      padding: 1rem 0;
      transition: all 100ms ease-in;
      width: 100%;

      .content{
        color: ${theme('--color-dark-night-60')};
        left: 0;
        position: absolute;
        text-align: center;
        transition: all 300ms ease-in;
        width: 100%;
      }

      .highlight {
        background: ${theme('--color-primary')};
        border-radius: 50%;
        content: '';
        display: block;
        height: 100%;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        transform: scale(0.2);
        transition: all 250ms ease-out;
        width: 100%;
      }
    }

    &.CalendarDay__selected,
    &.CalendarDay__selected:hover,
    &.CalendarDay__selected:active {
      .day{
        background: #ccdfff;
      }
    }

    &.CalendarDay__selected_span,
    &.CalendarDay__selected_span:hover,
    &.CalendarDay__selected_span:active,
    &.CalendarDay__selected_start,
    &.CalendarDay__selected_end {
      background: none;
      color: auto;

      .day{
        background: ${theme('--color-primary-20')};
      }
    }

    &.CalendarDay__selected_end .day,
    &.CalendarDay__lastDayOfWeek .day,
    .day.day_last {
      border-bottom-right-radius: 50%;
      border-top-right-radius: 50%;
    }

    &.CalendarDay__firstDayOfWeek .day,
    &.CalendarDay__selected_start .day,
    .day.day_first {
        border-bottom-left-radius: 50%;
        border-top-left-radius: 50%;
    }

    &.CalendarDay__selected_start,
    &.CalendarDay__selected_end,
    &.CalendarDay__default:hover {
      .day:not(.day_future){
        .content{
          color: ${theme('--color-light')};
        }

        .highlight{
          opacity: 100;
          transform: scale(1);
        }
      }
    }

    .day_future .content {
      color: ${theme('--color-dark-night-20')};
    }
  }

  .CalendarMonth_table {
    border-collapse: separate;
    border-spacing: 0 0.8rem;
  }

  .CalendarMonth_caption {
    color: ${theme('--color-dark-night-80')};
    text-transform: capitalize;

    strong {
      ${theme('--font-medium')}
      ${theme('--font-weight-medium')}
    }
  }

  .DayPicker_weekHeader_li {
    color: ${theme('--color-dark-night-20')};
    font-size: 0;
    text-transform: uppercase;

    &::first-letter {
      font-size: 14px;
    }
  }

  .DateInput_input__focused {
    border-color: ${theme('--color-secondary')};
  }

  .DateRangePicker {
    display: flex;
    justify-content: center;
  }

  .DayPickerNavigation_button__horizontalDefault {
    background: none;
    border: none !important;
    top: 16rem;
  }

  .DayPickerNavigation_leftButton__horizontalDefault {
    left: -2.4rem;
  }

  .DayPickerNavigation_rightButton__horizontalDefault {
    right: -2.4rem;
  }

  /* FIXME React-dates changes the height according to the number weeks that are in every month. This is the only solution right now. */
  .DayPicker_transitionContainer {
    height: 36rem !important;
  }

  .CalendarDay {
    border: none;
  }
`;
