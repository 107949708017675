import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from '@styled-components';

import service from 'Services/customers';

import { AutoSuggest } from 'Components/Form';

import Actions from './Actions';
import SectionHeadline from './SectionHeadline';

const Tags = ({
  activateActions,
  account,
  className,
  customerTags: tags = [],
  onBack,
  onChange,
  onNext,
}) => {
  const [customerTags, setCustomerTags] = useState([]);

  const handleChange = value => onChange({ customerTags: value });

  useEffect(() => {
    service.tagsGet({ account }).then(data => setCustomerTags(data.tags));
  }, [account]);

  return (
    <div className={className}>
      <SectionHeadline
        heading="INSTRUCTIONS.TAGS_SELECT_HEADING"
        title="INSTRUCTIONS.TAGS.SELECT"
      />
      <AutoSuggest
        allowCreate
        name="customerTags"
        onChange={handleChange}
        options={customerTags}
        placeholder="REFERAL_ETC"
        value={tags}
      />
      {activateActions && (
        <Actions disabled={tags.length === 0} onBack={onBack} onNext={onNext} />
      )}
    </div>
  );
};

Tags.propTypes = {
  account: PropTypes.number,
  activateActions: PropTypes.bool,
  className: PropTypes.string,
  customerTags: PropTypes.array,
  onBack: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default styled(Tags)`
  ${SectionHeadline} {
    margin-bottom: 1.6rem;
  }
`;
