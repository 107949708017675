import React from 'react';
import PropTypes from '@prop-types';
import PieChart from 'Components/Charts/PieChart';
import PieChartLoader from 'Components/PieChartLoader';

const Template = ({ data = {}, className }) => {
  const translatedData = Object.keys(data).reduce(
    (result, key) => ({
      ...result,
      [key]: data[key],
    }),
    {},
  );

  return (
    <PieChart
      className={className}
      data={translatedData}
      loader={PieChartLoader}
    />
  );
};

Template.propTypes = {
  className: PropTypes.string,
  data: PropTypes.objectOf(PropTypes.number),
};

export default Template;
