import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css, theme, withStyles } from '@styled-components';
import { capitalize, FormattedMessage } from '@react-intl';
import { rgba } from 'polished';
import { compose } from 'underscore';

import Placeholder from 'Containers/Placeholder';

import B from 'Components/Button';
import Card from 'Components/Card';
import Chip from 'Components/Chip';
import IconButton from 'Components/IconButton';
import { withRouter } from 'Components/Router';

import withPermissions from 'Components/with/permissions';

import C from 'Constants';

const {
  ROLE: { ADMIN, SUPER_ADMIN },
} = C;

const ACTIVE = 'ACTIVE';
const ERROR = 'ERROR';

const Button = withPermissions([SUPER_ADMIN, ADMIN])(B);

const Header = styled('div')``;
const Foot = styled(Placeholder.div)``;
const Title = styled(Placeholder.h1)``;
const Subtitle = styled('h2')``;
const Logo = styled(Placeholder.img).attrs({
  forwardProps: ['src'],
})``;
const Status = styled(Chip)``;
const Category = styled(Placeholder)``;

function getStatusColor(props) {
  switch (props.status) {
    case ACTIVE:
      return css`
        background: ${rgba(props.theme['--color-success'], 0.2)};
        color: ${props.theme['--color-success']};
      `;
    case ERROR:
      return css`
        background: ${rgba(props.theme['--color-alert'], 0.2)};
        color: ${props.theme['--color-alert']};
      `;
    default:
  }
  return false;
}

class Integration extends Component {
  static toKebabCase(string) {
    return string
      .split('_')
      .join('-')
      .toLowerCase();
  }

  static propTypes = {
    category: PropTypes.string,
    className: PropTypes.string,
    company: PropTypes.string,
    history: PropTypes.object,
    id: PropTypes.number,
    logo: PropTypes.string,
    name: PropTypes.string,
    service: PropTypes.string,
    status: PropTypes.string,
  };

  handleIntegration = () => {
    const { category, history, id, service } = this.props;
    const url = Integration.toKebabCase(
      `/integrations/${id}/${category}/${service}`,
    );
    history.push(url);
  };

  render() {
    const { category, className, status, logo, name, company } = this.props;

    return (
      <Card className={className}>
        <Header>
          <Title placeholder="integration name">{capitalize(name)}</Title>
          {company && <Subtitle>{capitalize(company)}</Subtitle>}
          <IconButton outline name="edit" onClick={this.handleIntegration} />
        </Header>
        <Logo src={logo} />
        <FormattedMessage
          capitalize
          component={Category}
          id={category}
          placeholder="integration category"
        >
          {category}
        </FormattedMessage>
        <Foot placeholder="status">
          <Status status={status}>
            <FormattedMessage id={status}>{status}</FormattedMessage>
          </Status>
        </Foot>
      </Card>
    );
  }
}

export default compose(
  withStyles`
    align-items: center;
    box-shadow: 0 0.8rem 1.6rem rgba(0, 15, 25, 0.05), 0 0.1rem 0 rgba(0, 15, 25, 0.05);
    display: flex;
    flex-direction: column;
    height: 28rem;
    padding: 0;
    width: 100%;

    ${Header} {
      align-items: start;
      display: flex;
      flex-direction: row;
      height: 6.4rem;
      justify-content: space-between;
      padding: 1.6rem;
      position: relative;
      width: 100%;

      ${Title} {
        ${theme('--font-medium')}
        ${theme('--font-weight-medium')}
        ${theme('--font-opacity-60')}
      }

      ${Subtitle} {
        ${theme('--font-small')}
        ${theme('--font-weight-book')}
        ${theme('--font-opacity-40')}
        margin-top: 0.4rem;
      }

      ${IconButton} {
        height: 3.2rem;
        position: absolute;
        right: 1.2rem;
        top: 1.2rem;
        width: 3.2rem;
      }
    }

    ${Logo} {
      height: 9.6rem;
      margin: 0.8rem 0;
    }

    ${Category} {
      ${theme('--font-small')}
      ${theme('--font-weight-book')}
      ${theme('--font-opacity-40')}
      margin-bottom: 2.4rem;
      text-align: center;
    }

    ${Foot} {
      align-items: center;
      box-shadow: inset 0px 1px 0px rgba(0, 15, 25, 0.05);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 1.6rem 2.4rem 2.4rem;
      width: 100%;

      ${Status} {
        border: none;
        color: ${theme('--color-light')};
        display: block;
        font-size: 1.4rem;
        height: 2.6rem;
        padding: 0.6rem 1.3rem;
        text-align: center;
        text-transform: uppercase;
        width: 11.6rem;
        ${props => getStatusColor(props)}
      }

    }

    @media (${theme('--screen-small')}) {
      ${Header} ${Button} {
        cursor: pointer;
        visibility: hidden;
      }

      &:hover ${Header} ${Button} {
        visibility: visible;
      }
    }
  `,
  withRouter,
)(Integration);
