import React from 'react';
import PropTypes from '@prop-types';
import { FormattedMessage } from '@react-intl';
import styled, { theme } from '@styled-components';

import Button from 'Components/Button';

import Dialog, { Actions, Wrapper } from 'Components/Dialog';

export { Actions, Wrapper };

export const Confirm = ({
  className,
  children,
  in: isIn,
  onAccept,
  onCancel,
  cancelId,
  acceptId,
  title,
}) => (
  <Dialog
    noClose
    className={className}
    in={isIn}
    onDismiss={onCancel}
    title={title}
  >
    {children}
    <Actions>
      <FormattedMessage
        capitalize
        primary
        component={Button}
        id={acceptId || 'ACTIONS.ACCEPT'}
        onClick={onAccept}
      >
        Accept
      </FormattedMessage>
      <FormattedMessage
        capitalize
        secondary
        component={Button}
        id={cancelId || 'ACTIONS.CANCEL'}
        onClick={onCancel}
      >
        Cancel
      </FormattedMessage>
    </Actions>
  </Dialog>
);

Confirm.propTypes = {
  acceptId: PropTypes.string,
  cancelId: PropTypes.string,
  children: PropTypes.children,
  className: PropTypes.string,
  in: PropTypes.bool,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string,
};

export default styled(Confirm)`
  ${Wrapper} {
    max-width: 40.8rem;
  }

  ${Actions} {
    display: grid;
    grid-gap: 0.8rem;
    grid-template: auto auto / 1fr;
    margin-top: 4rem;

    ${Button} {
      margin: 0;
    }
  }

  @media (${theme('--screen-small')}) {
    ${Actions} {
      grid-template: auto / 1fr 1fr;
    }
  }
`;
