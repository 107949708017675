import React, { useState } from 'react';
import styled from '@styled-components';
import moment from 'moment-timezone';
import PropTypes from '@prop-types';

import { useMultiDataProviderContext } from 'Containers/MultiDataProvider';
import { FormattedMessage } from '@react-intl';

import services from 'Services/campaigns';

import Button from 'Components/Button';
import CheckBox from 'Components/Form/Input/CheckBox';
import Customers from 'Components/CampaignForm/components/Customers';
import Date from 'Components/CampaignForm/components/Date';
import EditConfirm from 'Components/Confirm';
import Message from 'Components/CampaignForm/components/Message';
import Name from 'Components/CampaignForm/components/Name';
import Preview from 'Components/CampaignForm/components/Preview';
import Tags from 'Components/CampaignForm/components/Tags';

const FormCampaign = styled('div')``;
const HeadMessage = styled(FormattedMessage)``;
const OverviewBlock = styled('div')``;
const UpdateButton = styled(Button)``;

const EditCampaign = ({
  account,
  campaignId,
  className,
  data: curData,
  handleUpdateSuccess,
  onError,
}) => {
  const date = curData.templateData?.date;
  const { refetch } = useMultiDataProviderContext('campaigns');
  const [isChecked, setIsChecked] = useState(false);
  const [editConfirmIsOpen, setEditConfirmIsOpen] = useState(false);

  const [data, setData] = useState({
    customerTags: curData.templateData?.customerTags || [],
    customers: curData.templateData?.customerGroups || [],
    date: !date
      ? null
      : {
          dateTime: moment(date?.dateTime || null),
          timezone: date?.timezone || null,
        },
    message: curData.templateData?.message || '',
    name: curData.name,
    type: curData.type,
  });

  const handleConfirmCancel = () => setEditConfirmIsOpen(false);

  const handleDataChange = params => setData({ ...data, ...params });

  const handleEdit = () => {
    services
      .update({
        account,
        campaign: {
          customerGroups: data.customers,
          platform: 'FB_MESSENGER', // API need it, what can I do.
          ...data,
        },
        campaignId,
      })
      .then(() => {
        setEditConfirmIsOpen(false);
        handleUpdateSuccess();
        refetch();
      })
      .catch(() => onError('CAMPAIGN_NOT_CREATED'));
  };

  return (
    <div className={className}>
      <EditConfirm
        acceptId="ACTIONS.YES"
        in={editConfirmIsOpen}
        onAccept={handleEdit}
        onCancel={handleConfirmCancel}
        title="EDIT_CAMPAIGN"
      >
        <FormattedMessage capitalize id="CAMPAIGN_EDIT_CONFIRM" />
      </EditConfirm>
      <HeadMessage capitalize id="EDIT_CAMPAIGN" />
      <FormattedMessage capitalize id="INSTRUCTIONS.EDIT_CAMPAIGN" />
      <FormCampaign>
        <Name name={data.name} onChange={handleDataChange} />
        <Customers
          account={account}
          customers={data.customers}
          onChange={handleDataChange}
        />
        <Tags
          account={account}
          customerTags={data.customerTags}
          onChange={handleDataChange}
        />
        <Message
          account={account}
          message={data.message}
          onChange={handleDataChange}
        />
        <Date date={data.date} onChange={handleDataChange} />
        <OverviewBlock>
          <CheckBox
            label="CAMPAIGN_TEST"
            onChange={checked => setIsChecked(checked)}
          />
          {isChecked && (
            <Preview
              account={account}
              campaignName={data.name}
              message={data.message}
              onError={onError}
            />
          )}
        </OverviewBlock>
        <UpdateButton
          capitalize
          primary
          translate
          onClick={() => setEditConfirmIsOpen(true)}
        >
          ACTIONS.UPDATE
        </UpdateButton>
      </FormCampaign>
    </div>
  );
};

EditCampaign.propTypes = {
  account: PropTypes.number.isRequired,
  campaignId: PropTypes.string.isRequired,
  className: PropTypes.string,
  data: PropTypes.object,
  handleUpdateSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default styled(EditCampaign)`
  margin: 0 auto;
  width: 50rem;

  ${OverviewBlock} {
    background: #fff;
    border-radius: 0.6rem;
    padding: 1.6rem;

    &:first-child {
      margin-bottom: 1.6rem;
    }
  }

  ${UpdateButton} {
    margin-top: -1.6rem;
    width: 15.2rem;
  }

  ${HeadMessage} {
    color: #000f19;
    display: block;
    font-size: 1.6rem;
    margin-bottom: 0.4rem;
  }

  ${FormattedMessage} {
    color: #666f75;
  }

  ${FormCampaign} {
    display: grid;
    margin-top: 7.8rem;
    row-gap: 3.8rem;
  }
`;
