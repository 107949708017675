import { createAction } from '@redux-act';

export const boot = createAction('@Session/BOOT');

export const login = createAction(
  '@Session/LOGIN',
  (email, password, remember) => ({
    email,
    password,
    remember,
  }),
);
login.success = createAction('@Session/LOGIN ✓', (id, token, email) => ({
  email,
  id,
  token,
}));

export const logout = createAction('@Session/LOGOUT');
logout.success = createAction('@Session/LOGOUT ✓');

export const recoverPassword = createAction(
  '@Session/RECOVER PASSWORD',
  email => ({ email }),
);

export const resetPassword = createAction(
  '@Session/RESET PASSWORD',
  (password, code, email) => ({ code, email, password }),
);
