import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import styled, { withStyles as css, theme } from '@styled-components';
import { useHistory } from 'react-router-dom';

import { currentAccount } from 'Selectors/index';

import service from 'Services/brand-content';

import { useMultiDataProviderContext } from 'Containers/MultiDataProvider';
import TableDataProvider from 'Containers/TableDataProvider';

import Button from 'Components/Button';
import { Input } from 'Components/Form';

import Template from './BrandContent';
import FiltersRatio from './components/FiltersRatio';
import FiltersType from './components/FiltersType';

const mapStateToProps = state => ({
  account: currentAccount(state),
});

const Header = styled('div')``;

const BrandContent = ({ className, account }) => {
  const [type, setType] = useState('ALL');
  const [ratio, setRatio] = useState('ALL');
  const [searchBy, setSearch] = useState();
  const { push } = useHistory();
  const { refetch } = useMultiDataProviderContext('brandContent');

  const handleTypeChange = filter => {
    setType(filter);
  };

  const handleRatioChange = filter => {
    setRatio(filter);
  };

  const handleSearch = useCallback(
    search => {
      setSearch(search);
    },
    [setSearch],
  );

  const handleRemove = useCallback(
    async id => {
      await service.remove({ account, id });
      refetch();
    },
    [account, refetch],
  );

  const handleOpenUploader = useCallback(
    () => push('/builder/brandcontent/add'),
    [push],
  );

  const filters = searchBy;

  return (
    <div className={className}>
      <Header>
        <Input
          capitalize
          label="SEARCH"
          name="search"
          onChange={handleSearch}
          type="search"
          value={searchBy}
        />
        <FiltersRatio onChange={handleRatioChange} value={ratio} />
        <FiltersType onChange={handleTypeChange} value={type} />
        <Button capitalize primary translate onClick={handleOpenUploader}>
          ACTIONS.UPLOAD
        </Button>
      </Header>
      <TableDataProvider
        filters={filters}
        name="brandContent"
        onRemove={handleRemove}
        params={{ account, ratio, searchBy, type }}
        query={service.get}
        ratio={ratio}
        template={Template}
        type={type}
      />
    </div>
  );
};

BrandContent.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
};

export default compose(
  connect(mapStateToProps),
  css`
    ${Header} {
      display: flex;
      flex-direction: column-reverse;
      margin-bottom: 1.6rem;
      ${Input}, ${FiltersType}, ${FiltersRatio} {
        order: 1;
        padding-bottom: 0.8rem;
      }
      ${Button} {
        height: 4rem;
        margin-bottom: 1.6rem;
        margin-left: auto;
        min-width: 100%;
        order: 2;
      }
    }

    @media (${theme('--column-12')}) {
      ${Header} {
        flex-direction: row;
        margin-bottom: 4rem;

        ${Input}, ${FiltersType}, ${FiltersRatio} {
          margin-right: 1.2rem;
        }

        ${Button} {
          height: 4.8rem;
          margin-bottom: 0;
          min-width: 18.8rem;
        }
      }
    }
  `,
)(BrandContent);
