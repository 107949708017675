import React from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';

import { withPlaceholder } from 'Containers/Placeholder';

import Card from 'Components/Card';
import Chip from 'Components/Chip';

import Address from './Address';
import Fields from './Fields';

const Wrapper = styled('article')``;
const List = styled(withPlaceholder('ul')).attrs({
  placeholder: 'placeholder',
})``;

const PersonalInformation = ({
  address,
  email,
  groups = [],
  phone,
  className,
}) => (
  <Card className={className} title="PERSONAL_INFORMATION">
    <Wrapper>
      <Address address={address} />
      <Fields email={email} phone={phone} />
      <List>
        {groups.map(({ id, name }) => (
          <Chip key={id}>{name}</Chip>
        ))}
      </List>
    </Wrapper>
  </Card>
);

PersonalInformation.propTypes = {
  address: PropTypes.object,
  className: PropTypes.string,
  email: PropTypes.string,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  phone: PropTypes.string,
};

PersonalInformation.defaultProps = {
  address: {},
};

export default styled(PersonalInformation)`
  height: 42rem;

  ${Wrapper} {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    ${List} {
      ${Chip} {
        background: #9500ff;
        border: none;
        border-radius: 0.6rem;
        color: #eaccff;
        margin: 0 0.8rem 0.8rem 0;
      }
    }
  }
`;
