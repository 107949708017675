import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles as css, theme } from '@styled-components';
import { compose } from 'underscore';

import { account as accountSelector } from 'Selectors';

import MostViewed from 'Containers/MostViewed';
import View from 'Components/View';
import ViewHeadline from 'Components/ViewHeadline';

import Template from './Products';

const mapStateToProps = state => ({
  ...accountSelector(state),
});

const Products = ({ className, id: account, ...props }) => {
  return (
    <View className={className}>
      <ViewHeadline heading="PRODUCTS_SUBHEADING" title="PRODUCTS_HEADING" />
      <Template {...props} account={account} />
      <MostViewed />
    </View>
  );
};

Products.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
  id: PropTypes.number,
};

export default compose(
  connect(mapStateToProps),
  css`
    display: grid;
    
    ${MostViewed} {
      height: 46rem;
      padding: 6.4rem 0 0;
    }
  
    @media (${theme('--column-8')}) {
      ${ViewHeadline}, ${Template}, ${MostViewed} {
        grid-column: span 8;
      }
    }
  
    @media (${theme('--column-12')}) {
      ${ViewHeadline} {
        grid-column: span 12;
  
       
      }
      ${Template} {
        grid-column: span 9;
      }
      ${MostViewed} {
        grid-column: span 3;
      }
    }
  `,
)(Products);
