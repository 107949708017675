import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@react-intl';
import styled, {
  withStyles as css,
  theme,
  withTheme,
} from '@styled-components';
import { compose } from 'underscore';

import Chart, { Grid, HAxis, Legend, Line, VAxis } from 'Components/Chart';

const Axes = styled('div')``;
const Axis = styled('span')``;

const LineChart = ({
  className,
  colors = [],
  data: raw,
  options = {},
  theme: palette,
}) => {
  const data = raw.map(datum => ({
    ...datum,
    y1: datum.y !== undefined ? datum.y : datum.y1,
  }));
  const {
    grid = [0, 4],
    xTickFormat,
    y,
    yLegend,
    yTickFormat,
    xTicks,
    yTooltip,
    yUnit,
    y1 = y,
    y1Legend = yLegend,
    y1TickFormat = yTickFormat,
    y1Tooltip = yTooltip,
    y1Unit = yUnit,
    y2,
    y2Legend,
    y2TickFormat,
    y2Tooltip,
    y2Unit,
  } = options;
  const [columns, rows] = grid;

  const first = data[0] || {};
  const hasY2 = first.y2 !== undefined;

  const dataX = data.map(datum => datum.x);
  const dataY1 = data.map(datum => datum.y1);
  const dataY2 = data.map(datum => datum.y2);

  return (
    <figure className={className}>
      <Axes>
        <Axis>
          {y1 && <FormattedMessage capitalize id={y1} />}
          {y1Unit && (y1 ? ` (${y1Unit})` : y1Unit)}
        </Axis>
        {hasY2 && (
          <Axis>
            {y2 && <FormattedMessage capitalize id={y2} />}
            {y2Unit && (y2 ? ` (${y2Unit})` : y2Unit)}
          </Axis>
        )}
      </Axes>
      <Chart right={hasY2 ? 0 : 10} top={10}>
        <HAxis
          color={palette['--color-dark-night-40']}
          name="x"
          tickFormat={xTickFormat}
          ticks={xTicks}
          values={dataX}
        />
        <VAxis
          color={palette['--color-dark-night-40']}
          from={0}
          name="y1"
          tickFormat={y1TickFormat}
          ticks={rows}
          to={Math.max(...dataY1)}
        />
        {hasY2 && (
          <VAxis
            right
            color={palette['--color-dark-night-40']}
            from={0}
            name="y2"
            tickFormat={y2TickFormat}
            ticks={rows}
            to={Math.max(...dataY2)}
          />
        )}
        <Grid
          color={palette['--color-dark-night-05']}
          columns={columns}
          rows={rows}
        />
        <Line
          color={colors[0]}
          data={dataY1}
          scales={['x', 'y1']}
          tooltip={y1Tooltip}
        />
        {hasY2 && (
          <Line
            color={colors[1]}
            data={dataY2}
            scales={['x', 'y2']}
            tooltip={y2Tooltip}
          />
        )}
      </Chart>
      <Legend
        series={[
          { color: colors[0], name: y1Legend || y1 },
          ...(hasY2 ? [{ color: colors[1], name: y2Legend || y2 }] : []),
        ]}
      />
    </figure>
  );
};

LineChart.propTypes = {
  className: PropTypes.string,
  colors: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      y: PropTypes.number,
      y1: PropTypes.number,
      y2: PropTypes.number,
    }),
  ).isRequired,
  options: PropTypes.shape({
    grid: PropTypes.arrayOf(PropTypes.number),
    x: PropTypes.string,
    xTickFormat: PropTypes.func,
    xTicks: PropTypes.number,
    y: PropTypes.string,
    y1: PropTypes.string,
    y1Legend: PropTypes.string,
    y1TickFormat: PropTypes.func,
    y1Tooltip: PropTypes.func,
    y1Unit: PropTypes.string,
    y2: PropTypes.string,
    y2Legend: PropTypes.string,
    y2TickFormat: PropTypes.func,
    y2Tooltip: PropTypes.func,
    y2Unit: PropTypes.string,
    yLegend: PropTypes.string,
    yTickFormat: PropTypes.func,
    yTooltip: PropTypes.func,
    yUnit: PropTypes.string,
  }),
  theme: PropTypes.object,
};

export default compose(
  css`
    display: grid;
    grid-template: auto 1fr auto / 1fr;

    ${Axes} {
      color: ${theme('--color-dark-night-60')};
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.2rem;
    }

    ${Legend} {
      margin-top: 1.6rem;
    }
  `,
  withTheme,
)(LineChart);
