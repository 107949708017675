import React from 'react';
import PropTypes from 'prop-types';
import styled, { withStyles as css, theme } from '@styled-components';
import Placeholder from 'Containers/Placeholder';

import Chip from 'Components/Chip';

import Media from './Media';

const Categories = styled(Placeholder.section)``;
const Description = styled(Placeholder.p)``;

const Groups = styled('div')``;

const Title = styled(Placeholder.h2)``;

export const Details = ({
  className,
  description,
  categories = [],
  id,
  name,
  images,
}) => (
  <section className={className}>
    <Title placeholder="product name">{name}</Title>
    <Media id={id} images={images} />
    <Description placeholder="long product description">
      {description}
    </Description>
    <Categories placeholder="product categories">
      <Groups>
        {categories.map(category => (
          <Chip key={category}>{category}</Chip>
        ))}
      </Groups>
    </Categories>
  </section>
);

Details.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.any,
  images: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
};

export default css`
  background: none;
  border: none;
  box-shadow: none;
  display: grid;
  flex-direction: column;
  grid-column-gap: 6.4rem;
  grid-template: 1fr / 1fr;
  justify-content: space-between;

  > * {
    margin-bottom: 2.4rem;
  }

  ${Title} {
    ${theme('--font-xlarge')}
  }

  ${Description} {
    color: ${theme('--color-dark-night-60')};
    ${theme('--font-medium')}
    ${theme('--font-weight-book')}
  }

  ${Media} {
    grid-column: auto;
    grid-row: auto;
  }

  ${Categories} {
    ${theme('--font-medium')}
    margin-bottom: 0.8rem;

    & ${Chip} {
      background: #eaccff;
      border: none;
      border-radius: 0.6rem;
      color: #9500ff;
    }
  }

  @media (${theme('--screen-small')}) {
    grid-template: auto / 1fr 28rem;

    > * {
      margin-bottom: 0;
    }

    ${Title} {
      margin-bottom: 1.2rem;
      max-width: 28rem;
    }

    ${Description} {
      margin-bottom: 3.2rem;
    }

    ${Categories} {
      margin-bottom: 0.8rem;
    }

    ${Media} {
      grid-column: 2 / 3;
      grid-row: 1 / 5;
    }
  }
`(Details);
