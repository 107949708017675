import React from 'react';
import symbol from 'currency-symbol-map';
import PropTypes from '@prop-types';
import { withIntl } from '@react-intl';
import styled, {
  withStyles as css,
  theme,
  withTheme,
} from '@styled-components';
import { compose } from 'underscore';

import { OPTIONS } from 'Modules/dates';

import Lanning, { Bar, Grid, HAxis, Legend, VAxis } from 'Components/Chart';
import { withPlaceholder } from 'Containers/Placeholder/index';

const Axes = styled('div')``;
const Axis = styled('span')``;

const Chart = ({ className, currency, data, intl, unit, theme: palette }) => {
  const colors = [
    palette['--color-chart-blue'],
    palette['--color-chart-purple'],
  ];

  const dataX = data.map(datum => datum.x);
  const dataY1 = data.map(datum => datum.y1);
  const dataY2 = data.map(datum => datum.y2);

  const max = [dataY1, dataY2]
    .map(serie => Math.max(...serie))
    .reduce((acc, serie) => Math.max(acc, serie), 0);

  const options = {
    ...OPTIONS[unit],
    ...(unit === 'hour' && { frecuency: 3 }),
  };

  const parser = value =>
    unit === 'hour'
      ? `${intl.formatTime(value, {
          hour: options.hour,
          hour12: true,
        })}-${intl.formatDate(value, { day: options.day })}`
      : intl.formatDate(value, options);

  const handleTooltip = (ignore, y) =>
    `${parser(ignore)} - ${intl.formatNumber(y, {
      currency,
      style: 'currency',
    })}`;

  return (
    <figure className={className}>
      <Axes>
        <Axis>{symbol(currency)}</Axis>
      </Axes>
      <Lanning style={{ display: 'block', verticalAlign: 'top' }} top={10}>
        <HAxis
          color={palette['--color-dark-night-40']}
          name="x"
          padding={{ left: 16, right: 16 }}
          tickFormat={parser}
          ticks={4}
          values={dataX}
        />
        <VAxis
          color={palette['--color-dark-night-40']}
          from={0}
          name="y1"
          ticks={4}
          to={max}
        />
        <Grid color={palette['--color-dark-night-05']} rows={4} />
        <Bar
          color={colors[0]}
          data={dataY1}
          left={4}
          right={16}
          scales={['x', 'y1']}
          tooltip={handleTooltip}
        />
        <Bar
          color={colors[1]}
          data={dataY2}
          left={16}
          right={4}
          scales={['x', 'y1']}
          tooltip={handleTooltip}
        />
      </Lanning>
      <Legend
        series={[
          { color: colors[0], name: 'REPEAT_SALES' },
          { color: colors[1], name: 'NEW_SALES' },
        ]}
      />
    </figure>
  );
};

Chart.propTypes = {
  className: PropTypes.string,
  currency: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.string,
      y1: PropTypes.number,
      y2: PropTypes.number,
    }),
  ),
  intl: PropTypes.intl,
  theme: PropTypes.object,
  unit: PropTypes.string,
};

export default compose(
  css`
    display: grid;
    grid-template: auto 1fr auto / 1fr;
    width: 100%;

    ${Axes} {
      color: ${theme('--color-dark-night-60')};
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.2rem;
    }

    ${Legend} {
      margin-top: 1.6rem;
    }
  `,
  withIntl,
  withTheme,
  withPlaceholder,
)(Chart);
