import React from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';
import { Row, Column } from 'Components/ResponsiveTable';

const Header = ({ className }) => (
  <Row className={className}>
    <Column field="fullName" id="CUSTOMER" />
    <Column id="ANSWER" />
  </Row>
);

Header.propTypes = {
  className: PropTypes.string,
};

export default styled(Header)`
  grid-template-columns: 35rem 1fr;
  padding: 0.2rem 2.4rem 0.8rem 2.4rem;
`;
