import React, { useRef, useCallback } from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from '@prop-types';
import { v4 as uuid } from 'uuid';
import { useField } from 'spooner';

const ColorGem = styled('div')``;
const ColorName = styled('span')``;

const ColorPicker = ({ className, ...props }) => {
  const inputRef = useRef(uuid());

  const { value, name, onChange = () => {} } = useField(props);

  const handleChange = useCallback(
    event => {
      const { value: rvalue } = event.target;

      event.stopPropagation();

      onChange(rvalue);
    },
    [onChange],
  );

  const color = value || '';

  return (
    <div ref={inputRef} className={className}>
      <input name={name} onChange={handleChange} type="color" value={value} />
      <ColorGem style={{ background: color }} />
      <ColorName>{color.toUpperCase()}</ColorName>
    </div>
  );
};

ColorPicker.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
};

export default styled(ColorPicker)`
  align-items: center;
  background: ${theme('--color-light')};
  border: 0.1rem solid ${theme('--color-dark-night-10')};
  border-radius: 0.6rem;
  display: flex;
  height: 4.8rem;
  position: relative;
  width: 100%;

  input {
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  ${ColorGem} {
    border: 0.2rem solid rgba(0, 15, 20, 0.2);
    border-radius: 50%;
    height: 2.4rem;
    margin: 1.2rem;
    width: 2.4rem;
  }

  ${ColorName} {
    ${theme('--font-medium')}
    ${theme('--font-weight-book')}
    ${theme('--font-opacity-80')}
  }
`;
