import React from 'react';
import PropTypes from '@prop-types';

import { capitalize } from '@react-intl';

import PieChart from 'Components/Charts/PieChart';
import PieChartLoader from 'Components/PieChartLoader';

const Template = ({ data, className }) => {
  const genders =
    data &&
    Object.keys(data).reduce(
      (acc, gender) => ({
        ...acc,
        [capitalize(gender)]: data[gender],
      }),
      {},
    );

  return (
    <PieChart className={className} data={genders} loader={PieChartLoader} />
  );
};

Template.propTypes = {
  className: PropTypes.string,
  data: PropTypes.objectOf(PropTypes.number),
};

export default Template;
