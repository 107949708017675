import React from 'react';
import PropTypes from '@prop-types';
import styled, { theme, withStyles as css } from '@styled-components';
import { FormattedMessage } from '@react-intl';
import { compose } from 'underscore';

import Icon from 'Components/Icon';
import { withRouter } from 'Components/Router';

const Title = styled('h2')``;
const Wrapper = styled('div')``;

const EmptyTemplate = ({ className }) => (
  <article className={className}>
    <Wrapper>
      <Icon name="faqs-empty">FAQS_EMPTY</Icon>
      <FormattedMessage capitalize component={Title} id="EMPTY_FAQS.FILTER_FAQ">
        Oops! we did not find any faq with this filters
      </FormattedMessage>
    </Wrapper>
  </article>
);

EmptyTemplate.propTypes = {
  className: PropTypes.string,
};

export default compose(
  css`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 45rem;
    justify-content: center;
    overflow-y: scroll;

    ${Wrapper} {
      display: grid;
      grid-gap: 0.4rem;
      /* grid-template-rows: minmax(0, calc(100% - 10.6rem)) 2.4rem 3.2rem 5rem; // Recover this line to be able to create campaigns */
      grid-template-rows: minmax(0, calc(100% - 2.4rem)) 2.4rem;
      height: 100%;
      max-height: 35rem;
      text-align: center;

      ${Icon} {
        align-self: center;
        margin: 0 auto;
      }

      ${Title} {
        ${theme('--font-large')}
        ${theme('--font-weight-book')}
        ${theme('--font-opacity-100')}
      }
    }

    @media screen and (${theme('--screen-small')}) {
      height: 61rem;
    }
  `,
  withRouter,
)(EmptyTemplate);
