import React from 'react';
import PropTypes from '@prop-types';
import { FormattedMessage } from '@react-intl';
import styled, { withStyles as css, theme } from '@styled-components';
import { compose } from 'underscore';

import IconButton from 'Components/IconButton';
import withTransition from 'Components/with/transition';

import Wrapper from './Wrapper';
import ModalLoading from './ModalLoading';

const Close = styled(IconButton)``;
const Actions = styled('div')``;
const Header = styled('header')``;
const Title = styled('h2')``;

const Modal = ({
  className,
  children,
  onBack,
  onClose,
  show = true,
  title,
  titleValues = {},
  toolbar: Toolbar,
}) => {
  return (
    <div className={className} data-testid="modal">
      <Header>
        {title && (
          <Title>
            <IconButton
              data-testid="back-button"
              name="chevronleft"
              onClick={onBack || onClose}
            />
            <FormattedMessage capitalize id={title} values={titleValues}>
              {title}
            </FormattedMessage>
          </Title>
        )}
        <Actions>
          <div id="toolbar" />
          {Toolbar && <Toolbar />}
          <Close data-testid="close-button" name="close" onClick={onClose} />
        </Actions>
      </Header>
      {show && <Wrapper loader={ModalLoading}>{children}</Wrapper>}
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.children,
  className: PropTypes.string,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  show: PropTypes.bool,
  title: PropTypes.string.isRequired,
  titleValues: PropTypes.object,
  toolbar: PropTypes.component,
};

const getDepth = props => (props.depth >= 0 ? props.depth : 0);

export { Wrapper };

export default compose(
  css`
    background: ${theme('--color-blueish-white')};
    height: 100vh;
    left: 0;
    margin: 0 auto;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: left 350ms ease-out, opacity 350ms ease-in;
    width: 100vw;
    z-index: 10;

    &.entered{
      opacity: 1;
    }

    &.exiting {
      opacity: 0.5;
    }

    ${Header} {
      align-items: center;
      background-color: ${theme('--color-light')};
      border-bottom: 0.1rem solid ${theme('--color-dark-night-10')};
      display: flex;
      height: 8rem;
      justify-content: space-between;
      padding: 0 3.2rem;
    
      ${Title} {
        ${theme('--font-large')}
        ${theme('--font-weight-demi')}
        align-items: center;
        display: flex;

        ${IconButton}{
          position: relative;
          right: 0.6rem;
        }
      }

      ${Actions} {
        align-items: center;
        display: flex;
      }

      ${Close} {
        align-items: center;
        display: none;
        height: 4.8rem;
        justify-content: center;
        width: 4.8rem;
      }
    }

    @media (${theme('--column-12')}) {
      ${Header} {
        ${Title} > ${IconButton} {
          display: ${props => {
            if (props.order === 0) return 'none';
            return props.onBack ? 'block' : 'none';
          }};
        }

        ${Actions} {
          ${Close} {
            display: flex;
          }
        }
      }
    }

    @media (${theme('--screen-large')}){
      left: calc((100vw - 100rem) / 2 - 2rem * ${getDepth}) !important;
      max-width: 100rem;
    }
  `,
  withTransition(350),
)(Modal);
