import React from 'react';
import PropTypes from 'prop-types';
import styled from '@styled-components';

import DataCard, { CHART } from 'Components/DataCard';

import Chart from './components/Chart';
import Table from './components/Table';

const Revenues = ({
  className,
  data: { sales = {}, total } = {},
  currency,
  unit,
}) => {
  const dates = Object.keys(sales);
  const revenues = Object.values(sales);

  const transformed = revenues.map((y, index) => ({
    x: dates[index],
    y1: y,
  }));

  return (
    <DataCard
      chartTemplate={Chart}
      className={className}
      currency={currency}
      initial={CHART}
      items={[dates, revenues]}
      sales={transformed}
      tableTemplate={Table}
      title="CAMPAIGN_REVENUES"
      total={total}
      unit={unit}
    />
  );
};

Revenues.propTypes = {
  className: PropTypes.string,
  currency: PropTypes.string,
  data: PropTypes.shape({
    sales: PropTypes.object,
    total: PropTypes.number,
  }),
  unit: PropTypes.string,
};

export default styled(Revenues)`
  grid-column: 1 / span 3;
  min-height: 40rem;
`;
