import moment from 'moment';
import request from 'Providers/request';
import { createService } from '@redux-act';
import {
  campaigns as campaignsSchema,
  campaignRevenueByTime as campaignRevenueByTimeSchema,
  funnel as funnelSchema,
} from 'Schemas';

async function add({ account, campaign }) {
  const response = await request(`/ofa/${account}/campaigns`, 'post', {
    ...campaign,
  });

  return response;
}

function addCustomer({ account, body, id }) {
  return request(`/ofa/${account}/campaigns/${id}/add`, 'post', body);
}

function sendTest({ campaign }) {
  return request('/send/campaign/test', 'post', campaign);
}

async function customers({ filterBy, id, search, limit = 5, offset }) {
  const params = {
    limit,
    offset,
    ...(filterBy && { filterBy }),
    ...(search && { search }),
  };

  const { collection: list, steps, total = list.length } = await request(
    `/campaigns/${id}/customers`,
    'get',
    params,
    {},
    { ttl: 0 },
  );

  return {
    collection: list.map(({ updatedAt, ...customer }) => {
      const validDate = moment(updatedAt);
      return {
        updatedAt,
        ...customer,
        ...(!validDate.isSame(moment('0')) && { updatedAt }),
      };
    }),
    steps,
    total,
  };
}

async function remove({ account, id }) {
  return request(
    `/ofa/${account}/campaigns/${id}`,
    'delete',
    {},
    {},
    { ttl: 0 },
  );
}

async function get({
  account,
  limit,
  offset,
  order,
  search,
  sortBy,
  status,
  platform,
  type,
}) {
  const { collection, total } = await request(
    `/campaigns`,
    'get',
    {
      limit,
      ofaId: account,
      offset,
      ...(order && { order }),
      ...(search && { search }),
      ...(sortBy && { sortBy }),
      ...(status && { status }),
      ...(platform && { platform }),
      ...(type && { type }),
    },
    campaignsSchema,
    { ttl: 0 },
  );
  return {
    collection,
    total,
  };
}

async function getById({ account, id }) {
  return request(
    `/ofa/${account}/campaigns/${id}`,
    'get',
    {},
    campaignsSchema,
    { ttl: 0 },
  );
}

async function getFunnel({ account, id }) {
  const campaign = await request(
    `/analytics/conversion_funnel`,
    'get',
    { campaignId: id, ofaId: account },
    funnelSchema,
    { ttl: 0 },
  );

  return campaign.funnel;
}

async function getRevenueByTime({ account, id, from, to, unit, currency }) {
  const { collection: revenues, total } = await request(
    `/analytics/campaigns_revenues`,
    'get',
    {
      campaignId: id,
      currency,
      from,
      ofaId: account,
      to,
      unit,
    },
    campaignRevenueByTimeSchema,
  );

  return {
    currency,
    sales: Object.keys(revenues).reduce(
      (result, key) => ({
        ...result,
        [key]: revenues[key],
      }),
      {},
    ),
    total,
  };
}

const update = ({ account, campaignId, campaign }) => {
  return request(`/ofa/${account}/campaigns/${campaignId}`, 'put', campaign);
};

const updateStatus = ({ account, campaignId, status }) => {
  return request(`/ofa/${account}/campaigns/${campaignId}/status`, 'patch', {
    status,
  });
};

const getSumarryAnalytics = createService(
  async ({ account, from, to, unit }, token) => {
    const data = await request.read(
      '/analytics/campaigns_overview/sumarry',
      {
        from,
        ofaId: account,
        to,
        unit,
      },
      {},
      { token },
    );

    return data;
  },
);

const getMessagesDeliveredAnalytics = createService(
  async ({ account, from, to, unit }, token) => {
    const data = await request.read(
      '/analytics/campaigns_overview/delivered',
      {
        from,
        ofaId: account,
        to,
        unit,
      },
      {},
      { token },
    );

    return data;
  },
);

const getCustomersReachedAnalytics = createService(
  async ({ account, from, to, unit }, token) => {
    const data = await request.read(
      '/analytics/campaigns_overview/reach',
      {
        from,
        ofaId: account,
        to,
        unit,
      },
      {},
      { token },
    );

    return data;
  },
);

const getSuccessRateAnalytics = createService(
  async ({ account, from, to, unit }, token) => {
    const data = await request.read(
      '/analytics/campaigns_overview/rate',
      {
        from,
        ofaId: account,
        to,
        unit,
      },
      {},
      { token },
    );

    return data;
  },
);

function upload(media) {
  return request.create(
    'campaigns/media/upload',
    {
      file: media.file,
      name: media.file.name,
    },
    {},
    {
      headers: {
        'content-type': 'form-data/multipart',
      },
    },
  );
}

function deleteMedia(filename) {
  return request.delete(
    'campaigns/media',
    {
      key: filename,
    },
    {},
    {
      headers: {
        'content-type': 'application/json',
      },
    },
  );
}

export default {
  add,
  addCustomer,
  customers,
  deleteMedia,
  get,
  getById,
  getCustomersReachedAnalytics,
  getFunnel,
  getMessagesDeliveredAnalytics,
  getRevenueByTime,
  getSuccessRateAnalytics,
  getSumarryAnalytics,
  remove,
  sendTest,
  update,
  updateStatus,
  upload,
};
