import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles as css } from '@styled-components';
import { compose, memoize } from 'underscore';

import IconButton from 'Components/IconButton';
import Loader from 'Components/Loader';
import List from 'Components/List';
import Modal from 'Components/Modal';

import Empty from './components/Empty';
import Notification from './components/Notification';
import Overlay from 'Components/Overlay';

const READ = 'READ';

class Notifications extends Component {
  computeSortedNotifications = memoize(notifications => {
    const readNotifications = notifications
      .filter(({ status }) => status === READ)
      .sort(
        ({ timestamp: a }, { timestamp: b }) =>
          new Date(b).getTime() - new Date(a).getTime(),
      );
    return [...this.getNewNotifications(notifications), ...readNotifications];
  });

  static propTypes = {
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    notifications: PropTypes.array,
    update: PropTypes.func,
  };

  static defaultProps = {
    notifications: [],
  };

  state = { isModalOpen: false };

  getNewNotifications = memoize(notifications =>
    notifications
      .filter(({ status }) => status !== READ)
      .sort(
        ({ timestamp: a }, { timestamp: b }) =>
          new Date(b).getTime() - new Date(a).getTime(),
      ),
  );

  componentDidMount() {
    const { notifications, update } = this.props;

    const notificationIds = this.getNewNotifications(notifications).map(
      ({ id }) => id,
    );
    if (notificationIds.length) update({ ids: notificationIds, status: READ });
  }

  toggleModal = () => {
    this.setState(prevState => ({ isModalOpen: !prevState.isModalOpen }));
  };

  render() {
    const { className, isLoading, notifications } = this.props;
    const { isModalOpen } = this.state;

    const sortedNotifications = this.computeSortedNotifications(notifications);
    const newNotifications = this.getNewNotifications(notifications);

    return (
      <nav className={className}>
        <IconButton
          data-testid="notification-button"
          name={newNotifications.length ? 'bell-dot' : 'bell'}
          onClick={this.toggleModal}
        />
        <Overlay in={isModalOpen} onDismiss={this.toggleModal}>
          <Modal
            depth={0}
            in={isModalOpen}
            onClose={this.toggleModal}
            order={1}
            title="NOTIFICATIONS"
          >
            {isLoading ? (
              <Loader />
            ) : (
              <List
                emptyTemplate={Empty}
                items={sortedNotifications}
                template={Notification}
              />
            )}
          </Modal>
        </Overlay>
      </nav>
    );
  }
}

export default compose(
  css`
    position: fixed;
    right: 1.6rem;
    top: 1.6rem;
    z-index: 3;

    ${Modal} {
      height: 100%;
      overflow: auto;

      ${Loader} {
        position: relative;
      }
    }
  `,
)(Notifications);
