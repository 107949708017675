import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@react-intl';
import styled, { theme } from '@styled-components';
import { debounce, defer, compose } from 'underscore';

import Button from 'Components/Button';
import Icon from 'Components/Icon';
import { withRouter } from 'Components/Router';

import AccountSwitcher from 'Containers/OfficialAccounts';

import withSurveys from 'Components/with/surveys';
import withVerticals from 'Components/with/verticals';

import Link from './Link';
import Lock from './Lock';

/* WARNING
  The withVerticals HoC makes use of Redux connect Hoc to subscribe to state changes.
  As Redux implements shouldComponentUpdate there's no indication that anything has changed if it isn't receiving props from the router.
  To solve that, just wrap the Redux connected component with withRouter HoC.
  Read the following link to know more about redux integration.
  https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/guides/redux.md
  */
const Products = compose(withRouter, withVerticals(['HOTEL', 'RETAIL']))(Link);
const Surveys = compose(withRouter, withSurveys)(Link);

const Header = styled('div')``;
const Handler = styled(Button)``;
const Nav = styled('nav')``;
const Overlay = styled('button')``;
const Spacer = styled('hr')``;
const Wrapper = styled('div')``;

const Navigation = ({ className }) => {
  const timeout = useRef({ cancel() {} });
  const [isCollapsed, setCollapsed] = useState(true);
  const [isLocked, setLocked] = useState(false);
  const [isSwitching, setIsSwitching] = useState(false);

  const turn = force => {
    setLocked(prevState => (force !== undefined ? force : !prevState));
  };

  const toggle = force => {
    if (!isLocked) {
      timeout.current.cancel();

      setCollapsed(prevState => (force !== undefined ? force : !prevState));
    }
  };

  const close = () => {
    toggle(true);
  };

  const open = () => {
    toggle(false);
  };

  const onEnter = () => {
    timeout.current.cancel();

    const timer = debounce(open, 500);

    timeout.current = timer;
    timer();
  };

  const onLeave = () => {
    if (isSwitching) return;

    timeout.current.cancel();

    const timer = debounce(close, 250);

    timeout.current = timer;
    timer();
  };

  const onNavigate = event => {
    if (event.target.tagName !== 'NAV') {
      defer(() => document.activeElement.blur());

      close();
    }
  };

  const openAccountSwitcher = () => {
    setIsSwitching(true);
  };

  const closeAccountSwitcher = () => {
    setIsSwitching(false);
    close();
  };

  return (
    <div
      className={className}
      data-collapsed={isCollapsed}
      data-locked={isLocked}
    >
      <Overlay aria-hidden="true" onClick={close}>
        <FormattedMessage capitalize id="ACTIONS.CLOSE">
          Close
        </FormattedMessage>
      </Overlay>
      <Handler aria-hidden="true" onClick={() => toggle()}>
        <Icon name="hamburger">ACTIONS.SELECT_OPTION</Icon>
      </Handler>
      <Wrapper
        onBlur={onLeave}
        onFocus={onEnter}
        onMouseEnter={onEnter}
        onMouseLeave={onLeave}
      >
        <Header>
          <AccountSwitcher
            onChange={closeAccountSwitcher}
            onClose={closeAccountSwitcher}
            onFocus={openAccountSwitcher}
            onOpen={openAccountSwitcher}
          />
          <Lock closed={isLocked} onClick={() => turn()} />
        </Header>
        <Nav onClick={onNavigate}>
          <Link exact href="/dashboard" icon="logo" name="HOME" />
          <Link href="/analytics" icon="stats" name="ANALYTICS" />
          <Surveys href="/surveys" icon="surveys" name="SURVEYS" />
          <Link href="/campaigns" icon="campaigns" name="CAMPAIGNS" />
          <Link href="/customers" icon="customers" name="CUSTOMERS" />
          <Products href="/products" icon="products" name="PRODUCTS" />
          <Link href="/builder" icon="botbuilder" name="BUILDER" />
          <Link href="/integrations" icon="integrations" name="INTEGRATIONS" />
          <Spacer />
          <Link href="/settings" icon="settings" name="SETTINGS" />
        </Nav>
      </Wrapper>
    </div>
  );
};

Navigation.propTypes = {
  className: PropTypes.string,
};

export default styled(Navigation)`
  background: ${theme('--color-blueish-white')};
  border-right: 0.2rem solid ${theme('--color-dark-night-05')};
  display: flex;
  flex-flow: column;
  flex-shrink: 0;
  height: 100vh;
  justify-content: flex-start;
  position: relative;
  transform: translateX(0);
  transition: transform 0.25s ease-out;
  width: 30rem;

  & + * {
    transition: transform 0.25s ease-out;
  }

  &[data-collapsed='true'] {
    transform: translateX(-30rem);

    & + * {
      transform: translateX(-30rem);
    }

    ${Overlay} {
      opacity: 0;
      pointer-events: none;
    }
  }

  ${Overlay} {
    background: ${theme('--color-dark')};
    border: 0;
    height: 100vh;
    left: 100%;
    opacity: 0.6;
    outline: none;
    pointer-events: auto;
    position: absolute;
    text-indent: -9999px;
    top: 0;
    transition: opacity 0.25s ease-out;
    width: 100vw;
    z-index: 1000;
  }

  ${Handler} {
    height: 4.8rem;
    left: calc(100% + 1.6rem);
    position: absolute;
    top: 1.6rem;
    width: 4.8rem;

    &:active {
      background-color: ${theme('--color-light')};
    }

    ${Icon} {
      display: block;
      margin: 0 auto;
    }
  }

  ${Wrapper} {
    display: flex;
    flex: 1;
    flex-flow: column;
  }

  ${Header} {
    background: ${theme('--color-light')};
    border-bottom: 0.1rem solid ${theme('--color-dark-night-05')};
    height: 8rem;
    padding: 2rem;

    ${Lock} {
      display: none;
    }
  }

  ${Nav} {
    display: flex;
    flex: 1;
    flex-flow: column;
    padding: 1.2rem;

    ${Link} {
      border-bottom: 0.1rem solid ${theme('--color-dark-night-05')};
      height: 6.4rem;
      position: relative;

      &:last-of-type {
        border: 0;
      }
    }

    ${Spacer} {
      border: 0;
      flex: 1;
    }
  }

  @media (${theme('--screen-small')}) {
    transition: width 350ms ease-out;
    width: 25rem;

    & + * {
      transition: none;
    }

    &[data-collapsed='true'] {
      transform: none;
      transition: width 350ms ease-out;
      width: 8rem;

      & + * {
        transform: none;
      }

      ${Header} ${Lock} {
        opacity: 0;
        pointer-events: none;
      }
    }

    ${Overlay} {
      display: none;
    }

    ${Handler} {
      display: none;
    }

    ${Wrapper} {
      overflow: hidden;
      width: 100%;

      > * {
        width: 100%;
      }
    }

    ${Header} ${Lock} {
      display: block;
      height: 4.8rem;
      opacity: 1;
      position: absolute;
      right: 0;
      top: 1.6rem;
      transition: opacity 250ms ease-out;
      width: 4.8rem;
    }

    ${Nav} {
      padding: 1.6rem 0;

      ${Link} {
        border-bottom: 0;

        &::before {
          background-color: transparent;
          border-radius: 0.05rem;
          content: '';
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          transition: background-color 250ms ease-out;
          width: 0.3rem;
        }

        &.active {
          ${theme('--font-weight-demi')}
          ${theme('--font-opacity-100')}

          &::before {
            background-color: ${theme('--color-secondary')};
          }
        }
      }
    }
  }
`;
