import React, { useCallback, useState } from 'react';
import { ArrayOfObjects } from '@array-utils';
import PropTypes from 'prop-types';
import { FormattedNumber } from '@react-intl';
import styled, { theme } from '@styled-components';

import Definition, { Detail, Term } from 'Components/Definition';
import Icon from 'Components/Icon';
import Loader from 'Components/Loader';
import Table, { Body } from 'Components/Table';

import Template from './Template';
import CustomTemplate from './CustomTemplate';
import FilterButton from '../FilterButton';

const Actions = styled('div')``;
const Header = styled('header')``;

const Funnel = ({
  campaign,
  className,
  onChange = () => {},
  step,
  steps = [],
}) => {
  const [isFilterActive, setFilter] = useState(
    steps.findIndex(({ step: s }) => s === step.step) > 0,
  );

  const handleFilter = useCallback(
    index => {
      onChange(index);
      setFilter(!!index);
    },
    [onChange, setFilter],
  );

  const tableData = steps.slice(1).map((s, i) => ({
    ...s,
    active: s.step === step.step,
    onClick: () =>
      s.step === step.step ? handleFilter(0) : handleFilter(i + 1),
  }));
  const values = steps.map(({ value }) => value);

  const [total, started] = values;
  const [completed] = values.reverse();

  const conversion = completed / started || 0;
  const { length } = values;

  const successRate =
    campaign.totalCustomers && Number(campaign.totalCustomers) !== 0
      ? campaign.totalSuccess / campaign.totalCustomers
      : 0;
  const ctr =
    campaign.totalSuccess && Number(campaign.totalSuccess) !== 0
      ? campaign.totalClicks / campaign.totalSuccess
      : 0;

  const resetFilter = useCallback(() => {
    onChange(0);
    setFilter(false);
  }, [onChange, setFilter]);

  return (
    <section className={className}>
      {campaign.type === 'SMS' || campaign.type === 'WHATSAPP' ? (
        <>
          <Header>
            <Definition>
              <Term capitalize translate>
                SUCCESS_RATE
              </Term>
              <Detail>
                {/* eslint-disable-next-line react/style-prop-object */}
                <FormattedNumber style="percent" value={successRate} />
              </Detail>
              {campaign.campaignLinkFlag && (
                <>
                  <Term capitalize translate>
                    CTR
                  </Term>
                  <Detail>
                    {/* eslint-disable-next-line react/style-prop-object */}
                    <FormattedNumber style="percent" value={ctr} />
                  </Detail>
                </>
              )}
              <Term capitalize translate>
                TOTAL_STEPS
              </Term>
              <Detail>{length - 1}</Detail>
              <Term capitalize translate>
                TOTAL_AUDIENCE
              </Term>
              <Detail>{campaign.totalCustomers || 0}</Detail>
              <Term capitalize translate>
                MESSAGES_DELIVERED
              </Term>
              <Detail>{campaign.totalSuccess || 0}</Detail>
              {campaign.type === 'WHATSAPP' && (
                <>
                  <Term capitalize translate>
                    MESSAGES_READ
                  </Term>
                  <Detail>{campaign.totalRead || 0}</Detail>
                </>
              )}
              {campaign.campaignLinkFlag && (
                <>
                  <Term capitalize translate>
                    USERS_CLICKED_LINK
                  </Term>
                  <Detail>{campaign.totalClicks || 0}</Detail>
                </>
              )}
            </Definition>
            <Actions>
              {isFilterActive && <FilterButton onClick={resetFilter} />}
            </Actions>
          </Header>
          <Table
            emptyTemplate={Loader}
            items={tableData}
            template={CustomTemplate}
          />
        </>
      ) : (
        <>
          <Header>
            <Definition>
              <Term capitalize translate>
                CONVERSION_RATE
              </Term>
              <Detail>
                {/*
                    Style is a property discourage to use from styled but
                    FormattedNumber needed
                  */}
                {/* eslint-disable-next-line react/style-prop-object */}
                <FormattedNumber style="percent" value={conversion} />
              </Detail>
              <Term capitalize translate>
                TOTAL_STEPS
              </Term>
              <Detail>{length}</Detail>
              <Term capitalize translate>
                TOTAL_CUSTOMERS
              </Term>
              <Detail>{total}</Detail>
              <Term capitalize translate>
                STARTED_CUSTOMERS
              </Term>
              <Detail>{started}</Detail>
              <Term capitalize translate>
                COMPLETED_CUSTOMERS
              </Term>
              <Detail>{completed}</Detail>
            </Definition>
            <Actions>
              {isFilterActive && <FilterButton onClick={resetFilter} />}
            </Actions>
          </Header>
          <Table emptyTemplate={Loader} items={tableData} template={Template} />
        </>
      )}
    </section>
  );
};

Funnel.propTypes = {
  campaign: PropTypes.object,
  className: PropTypes.string,
  onChange: PropTypes.func,
  step: PropTypes.object,
  steps: PropTypes.arrayOf(PropTypes.object),
};

Funnel.defaultProps = {
  steps: ArrayOfObjects(5),
};

export default styled(Funnel)`
  ${Header} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 3.2rem;
    padding: 0 2.4rem;
  }

  ${Table} {
    height: 46.4rem;
    margin-top: 0.8rem;

    ${Body} {
      overflow: scroll;
    }
  }

  ${Actions} {
    align-items: flex-start;
    display: flex;
    position: absolute;
    right: 5.4rem;
    top: 0.8rem;
  }

  ${Definition} {
    display: inline-grid;
    grid-auto-flow: column;
    grid-gap: 0.4rem 3.2rem;
    grid-template: repeat(2, 1fr) / repeat(5, auto);
    overflow: auto;
    width: 100%;
  }

  ${FilterButton} {
    ${theme('--font-small')}
    ${theme('--font-weight-demi')}
    align-items: center;
    border: none;
    border-radius: 0.6rem;
    cursor: pointer;
    display: flex;
    flex-grow: 0;
    height: 4.8rem;
    justify-content: space-evenly;
    order: 1;
    outline: none;
    transition: all 250ms ease-in;
    width: 4.8rem;

    span {
      display: none;
    }

    &:focus,
    &:hover {
      background: ${theme('--color-blueish-white')};
      box-shadow: none;
      color: ${theme('--color-primary-80')};

      ${Icon} * {
        stroke: ${theme('--color-primary')};
      }
    }

    &[data-active='true'] {
      background: linear-gradient(
        180deg,
        rgba(0, 132, 255, 0.2) 0%,
        rgba(0, 132, 255, 0.1) 100%
      );
      color: ${theme('--color-primary')};

      ${Icon} * {
        stroke: ${theme('--color-primary')};
      }
    }
  }

  @media (${theme('--screen-small')}) {
    ${Definition} {
      order: 1;
      width: auto;
    }

    ${Actions} {
      order: 2;
      position: relative;
      right: 0;
      top: 0;

      ${FilterButton} {
        height: 3.2rem;
        padding: 0 0.4rem 0 0.8rem;
        width: 7.6rem;

        span {
          display: inline;
        }

        ${Icon} {
          height: 3.2rem;
          width: 3.2rem;
        }
      }
    }
  }
`;
