import React from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';

import { FormattedMessage } from '@react-intl';

export const Title = styled('h4')``;

export const Card = ({ children, className, title, ...props }) => {
  return (
    <section className={className} data-testid="card" {...props}>
      {title && (
        <FormattedMessage capitalize component={Title} id={title}>
          {title}
        </FormattedMessage>
      )}
      {children}
    </section>
  );
};

Card.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default styled(Card)`
  background-color: ${theme('--color-light')};
  border-radius: 0.6rem;
  box-shadow: 0 2px 4px 0 rgba(0, 15, 25, 0.05);
  display: block;
  min-height: 5rem;
  overflow: auto;
  padding: ${props => (props.title ? '6.4rem' : '2.4rem')} 2.4rem 1.6rem;
  position: relative;

  ${Title} {
    ${theme('--font-medium')}
    ${theme('--font-weight-book')}
    color: ${theme('--color-dark-night-60')};
    left: 2.4rem;
    margin: 0;
    position: absolute;
    top: 2.4rem;
  }

  @media (${theme('--screen-small')}) {
    overflow: hidden;
  }
`;
