import React, { useContext } from 'react';
import PropTypes from '@prop-types';
import styled, { css, theme } from '@styled-components';

import { Link } from '../Router';

import { Context, Provider } from 'Containers/DataProvider';

const clickableStyles = css`
  cursor: pointer;
  transition: all 250ms ease-out;

  &:hover {
    background-color: ${theme('--color-primary-10')};
    box-shadow: none;
    outline: none;
  }
`;

const RegularWrapper = styled('div')``;
const Button = styled('button')`
  ${clickableStyles}
  background: none;
  border: none;
  outline: none;
  width: 100%;
`;

const ButtonWrapper = ({ onClick, children, className, ...props }) => (
  <Button onClick={onClick}>
    <RegularWrapper {...props} className={className}>
      {children}
    </RegularWrapper>
  </Button>
);

ButtonWrapper.propTypes = {
  children: PropTypes.children,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

const Row = ({ to, onClick, className, children, empty, ...props }) => {
  if (onClick && to) {
    throw new Error(
      'Dont pass "to" and "onClick" props to Row component at the same time',
    );
  }

  const { loading, empty: contextEmpty } = useContext(Context);

  const locked = loading || contextEmpty || empty;

  let Wrapper = RegularWrapper;

  if (!locked && onClick) {
    Wrapper = ButtonWrapper;
  }

  if (!locked && to) {
    Wrapper = Link;
  }

  const clickable = Boolean(!locked && (onClick || to));
  return (
    <Wrapper
      className={className}
      data-clickable={clickable}
      onClick={clickable ? onClick : undefined}
      to={to}
      {...props}
    >
      <Provider value={{ empty: loading ? contextEmpty : empty, loading }}>
        {children}
      </Provider>
    </Wrapper>
  );
};

Row.propTypes = {
  children: PropTypes.children,
  className: PropTypes.string,
  empty: PropTypes.bool,
  onClick: PropTypes.func,
  to: PropTypes.string,
};

export default styled(Row)`
  align-items: center;
  display: grid;
  grid-gap: 0.4rem;
  padding: 0 2.4rem;
  position: relative;

  &[data-clickable='true'] {
    ${clickableStyles}
  }

  &:after {
    border-bottom: 0.1rem solid ${theme('--color-dark-night-10')};
    content: '';
    display: block;
    height: 100%;
    left: 2.4rem;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: calc(100% - 2 * 2.4rem);
  }
`;
