import React from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from '@prop-types';
import { FormattedMessage } from '@react-intl';

const Title = styled('h4')`
  ${theme('--font-medium')}
  ${theme('--font-weight-medium')}
  padding-bottom: 2.4rem;
`;
const Paragraph = styled('p')`
  ${theme('--font-medium')}
  ${theme('--font-weight-book')}
  margin-bottom: 1.4rem;
`;

export const CompanyNameTooltip = ({ className }) => {
  return (
    <div className={className}>
      <FormattedMessage capitalize component={Title} id="COMPANY_NAME" />
      <FormattedMessage
        capitalize
        component={Paragraph}
        id="TOOLTIP.COMPANY_NAME_1"
      />
      <FormattedMessage
        capitalize
        component={Paragraph}
        id="TOOLTIP.COMPANY_NAME_2"
      />
    </div>
  );
};

CompanyNameTooltip.propTypes = {
  className: PropTypes.string,
};

export const StyleTooltip = ({ className }) => {
  return (
    <div className={className}>
      <FormattedMessage capitalize component={Title} id="THEME" />
      <FormattedMessage capitalize component={Paragraph} id="TOOLTIP.THEME_1" />
      <FormattedMessage capitalize component={Paragraph} id="TOOLTIP.THEME_2" />
    </div>
  );
};

StyleTooltip.propTypes = {
  className: PropTypes.string,
};

export const CompanyPictureTooltip = ({ className }) => {
  return (
    <div className={className}>
      <FormattedMessage capitalize component={Title} id="COMPANY_LOGO" />
      <FormattedMessage
        capitalize
        component={Paragraph}
        id="TOOLTIP.COMPANY_LOGO"
      />
    </div>
  );
};

CompanyPictureTooltip.propTypes = {
  className: PropTypes.string,
};

export default {
  CompanyNameTooltip,
  CompanyPictureTooltip,
  StyleTooltip,
};
