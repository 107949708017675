import React, { useState } from 'react';
import styled, { theme, withStyles as css } from '@styled-components';
import PropTypes from '@prop-types';
import { connect } from 'react-redux';
import { useField } from 'spooner';
import { compose } from 'underscore';

import DataProvider from 'Containers/DataProvider';

import withTransition from 'Components/with/transition';
import IconButton from 'Components/IconButton';
import Button from 'Components/Button';

import AddBrandContent from 'Modals/AddBrandContent';

import { currentAccount } from 'Selectors';

import service from 'Services/brand-content';

import EmptyTemplate from './EmptyTemplate';
import Template from './Template';

const Background = styled('button')``;
const Wrapper = styled('article')``;
const Close = styled(IconButton)``;
const Header = styled('header')``;
const Title = styled('h2')``;

const mapStateToProps = state => ({
  account: currentAccount(state),
});

const SelectBrandContent = ({
  account,
  className,
  onClose,
  onSubmit,
  single,
}) => {
  const [addBrandContentIn, setAddBrandContentIn] = useState(false);
  const { value: ratio } = useField('ratio');
  const emptyVerifier = result => result.length === 0;

  const handleOpenUploader = () => setAddBrandContentIn(true);

  const handleCloseUploader = event => {
    event.preventDefault();
    setAddBrandContentIn(false);
  };

  return (
    <div className={className}>
      <AddBrandContent
        account={account}
        in={addBrandContentIn}
        onClose={handleCloseUploader}
      />
      <Background onClick={onClose} type="button" />
      <Wrapper>
        <Header>
          <Title capitalize translate id="BRAND_CONTENT">
            BRAND_CONTENT
          </Title>
          <Button capitalize secondary translate onClick={handleOpenUploader}>
            ACTIONS.UPLOAD_NEW
          </Button>
          <Close name="close" onClick={onClose} />
        </Header>
        <DataProvider
          emptyTemplate={EmptyTemplate}
          emptyVerifier={emptyVerifier}
          onSubmit={onSubmit}
          params={{ account, addBrandContentIn, ratio }}
          query={service.get}
          single={single}
          template={Template}
        />
      </Wrapper>
    </div>
  );
};

SelectBrandContent.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  single: PropTypes.bool,
};

export default compose(
  connect(mapStateToProps),
  css`
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;

    &.entered {
      ${Background} {
        opacity: 0.6;
      }

      ${Wrapper} {
        right: 0rem;
      }
    }

    ${Background} {
      background-color: ${theme('--color-dark')};
      border: none;
      cursor: pointer;
      height: 100%;
      opacity: 0;
      transition: opacity 350ms ease;
      width: 100%;
    }

    ${Wrapper} {
      background: ${theme('--color-light')};
      border-radius: 1rem 1rem 0 0;
      height: calc(100vh - 3.6rem);
      position: fixed;
      right: -100vw;
      top: 3.6rem;
      transition: right 350ms ease;
      width: 100vw;

      ${Header} {
        align-items: center;
        background-color: ${theme('--color-light')};
        border-bottom: 0.1rem solid ${theme('--color-dark-night-10')};
        border-radius: 1rem 1rem 0 0;
        display: grid;
        grid-template-columns: 1fr auto auto;
        height: 8rem;
        padding: 0 2rem;

        ${Title} {
          ${theme('--font-large')}
          ${theme('--font-weight-demi')}

          grid-column: 1;
        }

        ${Button} {
          grid-column: 2;
        }

        ${Close} {
          grid-column: 3;
        }
      }
    }

    @media (${theme('--screen-small')}) {
      ${Wrapper} {
        border-radius: 0;
        height: 100vh;
        right: -108.8rem;
        top: 0;
        width: 108.8rem;
      }
    }
  `,
  withTransition(350),
)(SelectBrandContent);
