import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { ArrayOfObjects } from '@array-utils';
import styled from '@styled-components';

import Table from 'Components/Table';

import Event from './Event';

const placeHolderEventFactory = () => ({
  date: new Date('01/01/2019'),
  title: '',
});

export const Timeline = ({
  className,
  data: { events: raw, isLast } = {},
  onScrollEnd = () => {},
  timezone,
}) => {
  const data = raw
    ? raw.map((event, index) => {
        const current = moment(event.date).startOf('day');
        const previous = moment((raw[index - 1] || {}).date).startOf('day');

        return { ...event, isNewDate: !current.isSame(previous) };
      })
    : ArrayOfObjects(5, placeHolderEventFactory);

  return (
    <div className={className}>
      <Table
        endFade
        noWrapper
        scrollable
        items={data}
        onScrollEnd={!isLast ? onScrollEnd : undefined}
        options={{ timezone }}
        template={Event}
      />
    </div>
  );
};

Timeline.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    events: PropTypes.arrayOf(PropTypes.object),
    isLast: PropTypes.bool,
  }),
  onScrollEnd: PropTypes.func,
  timezone: PropTypes.string,
};

export default styled(Timeline)`
  height: 100%;

  ${Table} {
    grid-template-rows: 1fr;
    height: 100%;
  }
`;
