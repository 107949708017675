import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate } from '@react-intl';
import styled, { theme } from '@styled-components';

import Chip from 'Components/Chip';
import { Row } from 'Components/ResponsiveTable';
import RawPlatforms from 'Components/Platforms';

import {
  withPlaceholderNoData as withPlaceholder,
  PlaceholderNoData as Placeholder,
} from 'Containers/Placeholder';

const Tag = styled(Placeholder)``;
const NameTag = styled(Tag)``;
const Platforms = styled(withPlaceholder(RawPlatforms))``;

const Survey = ({
  className,
  empty,
  id,
  name,
  launchDate,
  platform,
  status,
  type,
}) => {
  return (
    <Row className={className} empty={empty} to={`surveys/${id}`}>
      <NameTag placeholder="really long campaign name really long campaign name">
        {name}
      </NameTag>
      <Tag placeholder="campaign type">{type}</Tag>
      <Platforms fill data={[platform]} />
      <Tag placeholder="campaign date">
        <FormattedDate
          day="2-digit"
          month="long"
          value={new Date(Number(launchDate))}
          year="numeric"
        />
      </Tag>
      <Tag placeholder="chip">
        <Chip>{status}</Chip>
      </Tag>
    </Row>
  );
};

Survey.propTypes = {
  className: PropTypes.string,
  empty: PropTypes.bool,
  id: PropTypes.number,
  launchDate: PropTypes.string,
  name: PropTypes.string,
  platform: PropTypes.string,
  status: PropTypes.string,
  type: PropTypes.string,
};

export default styled(Survey)`
  grid-template-columns: 6fr 1fr 1fr 2fr 1fr;
  height: 7.2rem;

  ${Tag} {
    ${theme('--font-medium')}
    ${theme('--font-opacity-40')}
    ${theme('--font-weight-book')}
  }

  ${NameTag} {
    color: ${theme('--color-primary')};
    ${theme('--font-weight-medium')}
  }

  ${Chip} {
    ${theme('--font-weight-demi')}
    background: ${theme('--color-main-20')};
    border: none;
    border-radius: 0.6rem;
    color: ${theme('--color-main')};
  }
`;
