import React, { Component } from 'react';
import cx from 'classnames';
import hoistNonReactStatics from 'hoist-non-react-statics';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

export const withTransition = (_duration = 250) => {
  return WrappedComponent => {
    const displayName = WrappedComponent.displayName || WrappedComponent.name;

    class Transition extends Component {
      static displayName = `withTransition(${displayName}`;

      static propTypes = {
        appear: PropTypes.bool,
        className: PropTypes.string,
        duration: PropTypes.number,
        in: PropTypes.bool,
        onEnter: PropTypes.func,
        onExit: PropTypes.func,
      };

      static defaultProps = {
        appear: true,
        duration: _duration,
        in: false,
        onEnter() {},
        onExit() {},
      };

      endEventListener = (node, done) => {
        node.addEventListener('animationend', done, false);
      };

      render() {
        const {
          appear,
          className,
          duration,
          in: isIn,
          onEnter,
          onExit,
          ...props
        } = this.props;

        return (
          <CSSTransition
            mountOnEnter
            unmountOnExit
            addEndListener={this.endEventListener}
            appear={appear}
            classNames={{
              appearActive: 'appear',
              enterActive: 'enter',
              exitActive: 'exit',
            }}
            in={isIn}
            onEntered={onEnter}
            onExited={onExit}
            timeout={duration}
          >
            {state => (
              <WrappedComponent
                {...props}
                className={cx(className, state)}
                duration={duration}
              />
            )}
          </CSSTransition>
        );
      }
    }

    // Warning - This may failed if WrappedComponent is a styled-component
    hoistNonReactStatics(Transition, WrappedComponent);

    return Transition;
  };
};

export default withTransition;
