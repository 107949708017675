import React from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from '@prop-types';
import { FormattedMessage } from '@react-intl';

const Title = styled('h4')`
  ${theme('--font-medium')}
  ${theme('--font-weight-medium')}
  color: ${theme('--color-light')};
  padding-bottom: 2.4rem;
`;
const Paragraph = styled('p')`
  ${theme('--font-medium')}
  ${theme('--font-weight-book')}
  color: ${theme('--color-dark-night-20')};
  margin-bottom: 1.4rem;
`;

const Hyphen = styled('li')`
  ${theme('--font-medium')}
  ${theme('--font-weight-book')}
  color: ${theme('--color-dark-night-20')};
  list-style-type: none;

  strong {
    ${theme('--font-weight-demi')}
  }

  &::before {
    content: '-';
    margin-right: 0.8rem;
  }
`;

export const AnswerTypeTooltip = ({ className }) => {
  return (
    <div className={className}>
      <FormattedMessage capitalize component={Title} id="ANSWER_TYPE" />
      <FormattedMessage
        capitalize
        component={Paragraph}
        id="TOOLTIP.ANSWER_TYPE_1"
      />
      <Hyphen>
        <FormattedMessage
          capitalize
          component="strong"
          id="TOOLTIP.ANSWER_TYPE_2.TITLE"
        />
        <FormattedMessage capitalize id="TOOLTIP.ANSWER_TYPE_2.DESCRIPTION" />
      </Hyphen>
      <Hyphen>
        <FormattedMessage
          capitalize
          component="strong"
          id="TOOLTIP.ANSWER_TYPE_3.TITLE"
        />
        <FormattedMessage capitalize id="TOOLTIP.ANSWER_TYPE_3.DESCRIPTION" />
      </Hyphen>
    </div>
  );
};

AnswerTypeTooltip.propTypes = {
  className: PropTypes.string,
};

export const ScoreTooltip = ({ className }) => {
  return (
    <div className={className}>
      <FormattedMessage capitalize component={Title} id="SCORE" />
      <FormattedMessage capitalize component={Paragraph} id="TOOLTIP.SCORE" />
    </div>
  );
};

ScoreTooltip.propTypes = {
  className: PropTypes.string,
};

export const TagTooltip = ({ className }) => {
  return (
    <div className={className}>
      <FormattedMessage capitalize component={Title} id="TAGS" />
      <FormattedMessage capitalize component={Paragraph} id="TOOLTIP.TAGS" />
    </div>
  );
};

TagTooltip.propTypes = {
  className: PropTypes.string,
};

export default {
  AnswerTypeTooltip: styled(AnswerTypeTooltip)``,
  ScoreTooltip: styled(ScoreTooltip)``,
  TagTooltip: styled(ScoreTooltip)``,
};
