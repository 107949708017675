import { createReducer } from '@redux-act';

import { set } from 'Actions/member';

export default createReducer(
  {
    [set]: (ignore, member) => member,
  },
  {},
);
