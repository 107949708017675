import React from 'react';
import PropTypes from '@prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import styled, { theme, withStyles as css } from '@styled-components';

import Summary from 'Components/Summary';

import InformationCard from 'Components/InformationCard';
import DataProvider from 'Containers/DataProvider/index';

import { currency as currentCurrency, currentAccount } from 'Selectors/index';

import service from 'Services/customers';

const Desktop = styled('article')``;
const Mobile = styled('article')``;

const getEntries = data => {
  let entries = [
    { label: 'TOTAL_CUSTOMERS' },
    { label: 'NEW_CUSTOMERS_THIS_MONTHS' },
    { label: 'MONTHLY_ACTIVE_USERS' },
    { label: 'AVERAGE_ORDER' },
  ];

  if (data) {
    const {
      totalCustomers,
      newCustomers,
      activeCustomers,
      averageOrder,
    } = data;

    entries = [
      {
        label: 'TOTAL_CUSTOMERS',
        value: totalCustomers.value,
      },
      {
        label: 'NEW_CUSTOMERS_THIS_MONTHS',
        value: newCustomers.value,
      },
      {
        label: 'MONTHLY_ACTIVE_USERS',
        value: activeCustomers.value,
      },
      {
        label: 'AVERAGE_ORDER',
        options: {
          currency: averageOrder.currency,
        },
        type: 'currency',
        value: averageOrder.value,
      },
    ];
  }

  return entries;
};

const CustomerStats = ({ account, className, currency }) => {
  const params = { account, currency };
  return (
    <section className={className}>
      <Desktop>
        <DataProvider
          params={params}
          query={service.getTotalCustomers}
          template={({ data }) => {
            return (
              <Summary
                highlight
                heading={data && data.value}
                title="TOTAL_CUSTOMERS"
              />
            );
          }}
        />
        <DataProvider
          params={params}
          query={service.getNewCustomers}
          template={({ data }) => {
            return (
              <Summary
                heading={data && data.value}
                title="NEW_CUSTOMERS_THIS_MONTHS"
              />
            );
          }}
        />
        <DataProvider
          params={params}
          query={service.getActiveCustomers}
          template={({ data }) => {
            return (
              <Summary
                heading={data && data.value}
                title="MONTHLY_ACTIVE_USERS"
              />
            );
          }}
        />
        <DataProvider
          params={params}
          query={service.getAverageOrder}
          template={({ data }) => {
            return (
              <Summary
                currency={data && data.currency}
                heading={data && data.value}
                title="AVERAGE_ORDER"
              />
            );
          }}
        />
      </Desktop>
      <Mobile>
        <DataProvider
          params={params}
          query={service.getCustomerData}
          template={({ data }) => {
            return <InformationCard entries={getEntries(data)} />;
          }}
        />
      </Mobile>
    </section>
  );
};

CustomerStats.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
  currency: PropTypes.string,
};

const mapStateToProps = state => ({
  account: currentAccount(state),
  currency: currentCurrency(state),
});

export default compose(
  connect(mapStateToProps),
  css`
    ${Desktop}, ${Mobile} {
      display: grid;
      grid-gap: 3.2rem;
      grid-template: auto / repeat(4, 1fr);
    }

    ${Desktop} {
      display: none;
      grid-template: auto / repeat(4, 1fr);
    }

    ${Mobile} {
      grid-template: auto / auto;
    }

    @media (${theme('--column-12')}) {
      ${Desktop} {
        display: grid;
      }

      ${Mobile} {
        display: none;
      }
    }
  `,
)(CustomerStats);
