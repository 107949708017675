import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';

import Button from 'Components/Button';
import Dialog, { Wrapper } from 'Components/Dialog';

const Input = styled('textarea')``;
const Question = styled('p')``;
const LinkBlock = styled('section')``;
const Actions = styled('div')``;

const GetLink = ({ className, in: isIn, onClose, url }) => {
  const element = useRef();

  const handleCopy = useCallback(() => {
    element.current.select();
    document.execCommand('copy');
  }, [element]);

  return (
    <Dialog
      className={className}
      in={isIn}
      onDismiss={onClose}
      title="SHARE_YOUR_TEMPLATE"
    >
      <Question capitalize translate>
        QUESTIONS.SHARE_YOUR_TEMPLATE
      </Question>
      <LinkBlock>
        <Input ref={element} value={url} />
        <Button capitalize secondary translate onClick={handleCopy}>
          ACTIONS.COPY_LINK
        </Button>
      </LinkBlock>
      <Actions>
        <Button capitalize primary translate onClick={onClose}>
          ACTIONS.DONE
        </Button>
      </Actions>
    </Dialog>
  );
};

GetLink.propTypes = {
  className: PropTypes.string,
  in: PropTypes.bool,
  onClose: PropTypes.func,
  url: PropTypes.string,
};

export default styled(GetLink)`
  ${Wrapper} {
    margin: 2.4rem;

    ${Question} {
      ${theme('--font-opacity-60')}
      margin: 1.6rem 0;
    }

    ${LinkBlock} {
      display: grid;
      grid-gap: 1.6rem;
      grid-template: 6.4rem 4rem / 1fr;
      margin-bottom: 4.8rem;

      ${Input} {
        border: 0.1rem solid ${theme('--color-dark-night-10')};
        border-radius: 0.6rem;
        display: inline-block;
        margin: 0;
        outline: 0;
        padding: 1.6rem;
        resize: none;
        width: 100%;
      }

      ${Button} {
        width: 100%;
      }
    }

    ${Actions} {
      ${Button} {
        width: 100%;
      }
    }
  }

  @media (${theme('--screen-small')}) {
    ${Wrapper} {
      margin: 0;
      max-width: 54rem;

      ${LinkBlock} {
        grid-gap: 0.8rem;
        grid-template: 4.8rem / 1fr 10rem;

        ${Input} {
          overflow-y: hidden;
          white-space: nowrap;
        }
      }
    }

    ${Actions} {
      ${Button} {
        width: 18.8rem;
      }
    }
  }
`;
