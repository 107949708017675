import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@react-intl';
import styled, { theme } from '@styled-components';

import { useMultiDataProviderContext } from 'Containers/MultiDataProvider';

import Card from 'Components/Card';
import Link from 'Components/Link';
import View from 'Components/View';
import Table from 'Components/ResponsiveTable';
import withPermissions from 'Components/with/permissions';

import C from 'Constants';

import Services from 'Services/members';

import CompanyCard from './components/CompanyCard';
import AccountCard from './components/AccountCard';
import RemoveMember from './components/RemoveMember';
import { DesktopTemplate, Template, Header } from './components/Table';

const {
  ROLE: { ADMIN, SUPER_ADMIN },
  STATUS: { ACTIVE },
} = C;

const InviteLink = withPermissions([SUPER_ADMIN, ADMIN])(styled(Link)``);

const Members = styled(Card)``;

const Settings = ({
  account: { id: companyId, logoType: logo, name },
  className,
  me,
  me: { id: profileId },
  members,
  url,
  error,
  success,
}) => {
  const [state, setState] = useState({ isConfirmOpen: false });
  const { refetch } = useMultiDataProviderContext('settings');

  const handleRemove = useCallback(async () => {
    const { id, status } = state;

    try {
      if (status === ACTIVE) {
        await Services.removeMember({ account: companyId, id });
        success('MEMBER_REMOVED');
      } else {
        await Services.removeInvitation({ account: companyId, id });
        success('INVITATION_REMOVED');
      }
      setState({ ...state, isConfirmOpen: false });
      refetch();
    } catch (e) {
      error(
        status === ACTIVE ? 'MEMBER_NOT_REMOVED' : 'INVITATION_NOT_REMOVED',
      );
      setState({ ...state, isConfirmOpen: false });
    }
  }, [companyId, error, refetch, state, success]);

  const handleOpenConfirm = useCallback(
    (id, status) => () => {
      setState({
        id,
        isConfirmOpen: true,
        status,
      });
    },
    [],
  );

  const handleClose = useCallback(() => {
    setState({
      id: undefined,
      isConfirmOpen: false,
      status: undefined,
    });
  }, []);

  const { isConfirmOpen, status } = state;

  return (
    <>
      <RemoveMember
        in={isConfirmOpen}
        isMember={status === ACTIVE}
        onAccept={handleRemove}
        onCancel={handleClose}
      />
      <View className={className}>
        <CompanyCard
          account={companyId}
          link={`/settings/company/${companyId}`}
          logo={logo}
        />
        <AccountCard
          account={profileId}
          company={name}
          link={`${url}/${profileId}`}
          me={me}
        />
        <Members title="TEAM_MEMBERS">
          <InviteLink to={`${url}/users/add`}>
            <FormattedMessage capitalize id="ADD_MEMBER" />
          </InviteLink>
          <Table
            desktopTemplate={DesktopTemplate}
            header={Header}
            items={members}
            options={{ onClick: handleOpenConfirm, profileId, url }}
            template={Template}
          />
        </Members>
      </View>
    </>
  );
};

Settings.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.string,
    logoType: PropTypes.string,
    name: PropTypes.string,
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string,
  }),
  className: PropTypes.string,
  error: PropTypes.func,
  me: PropTypes.object,
  members: PropTypes.arrayOf(PropTypes.object),
  success: PropTypes.func,
  url: PropTypes.string,
};

export default styled(Settings)`
  ${Members} {
    height: calc(100vh - 10rem);
    padding: 6.4rem 0 0 0;

    ${InviteLink} {
      ${theme('--font-medium')}
      ${theme('--font-weight-demi')}
      color: ${theme('--color-primary')};
      font-size: 1.2rem;
      position: absolute;
      right: 2.4rem;
      top: 2.4rem;
    }
  }

  @media (${theme('--screen-small')}) {
    margin: 0 auto;

    ${Members} {
      height: 42rem;
    }
  }

  @media (${theme('--column-8')}) {
    ${CompanyCard}, ${AccountCard}, ${Members} {
      grid-column: span 8;
    }
  }

  @media (${theme('--column-12')}) {
    grid-template: 42rem 42rem / 28rem 1fr;

    ${CompanyCard}{
      grid-column: 1;
      grid-row: 1;
    }

    ${AccountCard}{
      grid-column: 1;
      grid-row: 2;
    }

    ${Members} {
      grid-column: 2;
      grid-row: 1 / span 2;
      height: auto;
    }
  }
`;
