import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, theme } from '@styled-components';

import Icon from 'Components/Icon';

const DefaultHeaderTemplate = ({ className, data = {}, label }) => (
  <div className={className}>
    <span>{label || data.label}</span>
    <Icon name="chevrondown">ACTIONS.SELECT_OPTION</Icon>
  </div>
);

DefaultHeaderTemplate.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  label: PropTypes.string,
};

const activeMixin = ({ active, value }) =>
  active === value &&
  css`
    background: ${theme('--primary-background-color')};
    color: ${theme('--text-primary-color')};
  `;

export const HeaderTemplate = styled(DefaultHeaderTemplate)`
  ${theme('--font-large')}
  cursor: pointer;
  text-align: right;
  ${activeMixin}

  ${Icon} {
    ${theme('--font-xlarge')}
    line-height: 3rem;
    margin-left: 2rem;
  }
`;

const DefaultDropdownTemplate = ({
  className,
  data,
  onClick = () => {},
  value,
  label,
}) => (
  <button onClick={() => onClick(value)} type="button">
    <div className={className}>{label || data.label}</div>
  </button>
);

DefaultDropdownTemplate.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  label: PropTypes.string,
  onClick: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
};

export const DropdownTemplate = styled(DefaultDropdownTemplate)`
  ${theme('--font-small')}
  background: ${theme('--primary-background-color')};
  cursor: pointer;
  font-weight: 300;
  height: 3.2rem;
  line-height: 3rem;
  min-width: 11.5rem;
  overflow: hidden;
  padding: 0 1.6rem;
  text-align: left;
  text-overflow: ellipsis;
  transition: background-color 0.35s ease-out;

  &:hover {
    background: ${theme('--primary-background-color')};
  }
`;

export default {
  DropdownTemplate,
  HeaderTemplate,
};
