import React from 'react';
import PropTypes from 'prop-types';
import styled from '@styled-components';

import Empty from 'Components/Empty';
import Loader from 'Components/Loader';
import Table from 'Components/ResponsiveTable';

import DesktopTemplate from './DesktopTemplate';
import Header from './Header';
import Template from './Template';

const Revenues = ({
  className,
  items: [dates, sales],
  isLoading,
  currency,
}) => {
  const data = dates.map((date, index) => ({
    currency,
    date,
    sales: sales[index],
  }));
  return (
    <Table
      className={className}
      desktopTemplate={DesktopTemplate}
      emptyTemplate={isLoading ? Loader : Empty}
      header={Header}
      items={data}
      rows={5}
      template={Template}
    />
  );
};

Revenues.propTypes = {
  className: PropTypes.string,
  currency: PropTypes.string,
  isLoading: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.array),
};

export default styled(Revenues)`
  margin: 0 -2.4rem 0;
`;
