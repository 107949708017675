import React, { Component } from 'react';
import PropTypes from '@prop-types';
import styled, { theme } from '@styled-components';

import Icon from 'Components/Icon';
import IconButton from 'Components/IconButton';

import Item from './Item';

const Actions = styled('div')``;
const Wrapper = styled('ul')``;

export class Carousel extends Component {
  length = this.props.children ? this.props.children.length : 0;

  static propTypes = {
    children: PropTypes.children,
    className: PropTypes.string,
    items: PropTypes.number,
    offset: PropTypes.number,
    onChange: PropTypes.func,
    step: PropTypes.number,
  };

  static defaultProps = {
    items: 1,
    step: 11,
  };

  handleDecreaseOffset = () => {
    const { offset, onChange, step } = this.props;
    onChange(Math.max(offset - step, 0));
  };

  handleIncreaseOffset = () => {
    const { offset, onChange, step } = this.props;
    onChange(
      Math.min(
        offset + step,
        this.props.items <= this.length ? this.length - this.props.items : 0,
      ),
    );
  };

  render() {
    const { children, className, items, offset: current } = this.props;
    const offset = Math.min(
      current,
      items <= this.length ? this.length - items : 0,
    );
    const size = 100 / items;

    return (
      <div className={className}>
        <Wrapper
          role="slider"
          style={{ transform: `translate3d(${-offset * size}%, 0, 0)` }}
        >
          {children}
        </Wrapper>
        <Actions>
          <IconButton
            disabled={offset === 0}
            name="chevronleft"
            onClick={this.handleDecreaseOffset}
          />
          <IconButton
            disabled={offset + items >= this.length}
            name="chevronright"
            onClick={this.handleIncreaseOffset}
          />
        </Actions>
      </div>
    );
  }
}

export default styled(Carousel)`
  margin: 0;
  overflow: hidden;
  position: relative;

  ${Actions} {
    align-items: center;
    background: ${theme('--color-blueish-white')};
    display: flex;
    height: 100%;
    overflow: hidden;
    padding: 0 0.8rem;
    position: absolute;
    right: 0;
    top: 0;
    width: 6.6rem;
    z-index: 100;

    ${IconButton} {
      outline: none;

      &:focus {
        box-shadow: none;
      }

      &:disabled {
        ${Icon} {
          opacity: 0.3;
        }
      }

      ${Icon} {
        display: block;
        outline-color: #999fa3;
        width: 2.6rem;
      }
    }
  }

  ${Wrapper} {
    display: flex;
    height: calc(100% - 2rem);
    list-style: none;
    margin: 0;
    padding: 0;
    width: calc(100% - 6.2rem);
    &.animated {
      transition: transform 0.2s ease-out;
    }
  }

  ${Item} {
    flex-grow: 1;
    max-width: ${props => 100 / (props.items || 1)}%;
    min-width: ${props => 100 / (props.items || 1)}%;
  }
`;
