import React from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';

import Button from 'Components/Button';
import Confirm, { Actions, Wrapper } from 'Components/Confirm';

const Question = styled('span')``;

const Remove = ({ className, in: isIn, onAccept, onCancel }) => (
  <Confirm
    className={className}
    in={isIn}
    onAccept={onAccept}
    onCancel={onCancel}
    title="QUESTIONS.REMOVE_MICROAPP.TITLE"
  >
    <Question capitalize translate>
      QUESTIONS.REMOVE_MICROAPP
    </Question>
  </Confirm>
);

Remove.propTypes = {
  className: PropTypes.string,
  in: PropTypes.bool,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
};

export default styled(Remove)`
  ${Wrapper} {
    margin: 2.4rem;
  }

  ${Actions} {
    display: flex;
    flex-direction: column;

    ${Button}:first-child {
      margin-bottom: 0.8rem;
    }
  }

  @media (${theme('--screen-small')}) {
    ${Wrapper} {
      margin: 0;
    }

    ${Actions} {
      display: grid;
      ${Button} {
        margin: 0;
      }
    }
  }
`;
