import styled, { theme } from '@styled-components';

const Detail = styled('dd')`
  ${theme('--font-weight-medium')}
  margin: 0;
`;

const List = styled('dl')`
  margin: 0;
  padding: 0;
`;

const Term = styled('dt')`
  ${theme('--font-small')}
  ${theme('--font-weight-book')}
  color: ${theme('--color-dark-night-60')};
`;

export { Detail, Term };

export default List;
