import C from 'Constants';

import http from './http';

const { ERROR } = C;

export const OPERATION = {
  create: 'create',
  delete: 'delete',
  read: 'read',
  update: 'update',
};

export function method(operation) {
  const TO_HTTP_METHOD = {
    create: 'post',
    delete: 'delete',
    read: 'get',
    update: 'put',
  };

  return TO_HTTP_METHOD[operation] || operation;
}

export async function rest(resource, operation, content, options = {}) {
  const { data, status } = await http(
    resource,
    method(operation),
    content,
    options,
  );

  if (status === 400) {
    throw new Error(
      (data.error && data.error.message) ||
        data.message ||
        ERROR.MALFORMED_CONTENT,
    );
  }

  if (status === 401) {
    throw new Error(ERROR.UNAUTHORIZED);
  }

  if (status === 404) {
    throw new Error(ERROR.NOT_FOUND);
  }

  if ((status < 200 || status >= 300) && status !== 304) {
    throw new Error(ERROR.UNKNOWN);
  }

  if (typeof data === 'object' && 'error' in data) {
    throw new Error(data.error || data.message);
  }

  return data;
}

Object.values(OPERATION).forEach(operation => {
  rest[operation] = (resource, content, options) =>
    rest(resource, operation, content, options);
});

export { createToken } from './http';

export default rest;
