import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled, { theme } from '@styled-components';
import services from 'Services/campaigns';

import { FormattedMessage } from '@react-intl';
import Button from 'Components/Button';
import Phone from './Phone';

import Confirmation from './Confirmation';

const Heading = styled(FormattedMessage)``;
const Content = styled('div')``;
const WhiteBlock = styled('div')``;
const GridBlock = styled('div')``;

const Preview = ({ account, className, campaignName, message, onError }) => {
  const [confirmationIsOpen, setConfirmationOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleConfirm = () => setConfirmationOpen(false);

  const handleTest = () => {
    services
      .sendTest({
        account,
        campaign: { message, phoneNumber },
      })
      .then(() => setConfirmationOpen(true))
      .catch(() => onError('CAMPAIGN_NOT_TESTED'));
  };

  return (
    <div className={className}>
      <Content>
        <Confirmation
          campaignName={campaignName}
          in={confirmationIsOpen}
          onAccept={handleConfirm}
          phoneNumber={phoneNumber}
        />

        <Heading>INSTRUCTIONS.CAMPAIGN.PREVIEW</Heading>
        <GridBlock>
          <Phone label="number" onChange={setPhoneNumber} value={phoneNumber} />
          <Button capitalize primary secondary translate onClick={handleTest}>
            TEST_IT_NOW
          </Button>
        </GridBlock>
      </Content>
    </div>
  );
};

Preview.propTypes = {
  account: PropTypes.number.isRequired,
  campaignName: PropTypes.string.isRequired,
  className: PropTypes.string,
  message: PropTypes.string,
  onError: PropTypes.func.isRequired,
};

export { Content };

export default styled(Preview)`
  background: #f7f9fc;
  border-radius: 0.6rem;
  margin-top: 2rem;
  padding: 1.6rem;

  ${Content} {
    width: 46rem;
  }

  ${Heading} {
    ${theme('--font-small')}
    color: ${theme('--color-dark-night-60')};
  }

  ${WhiteBlock} {
    ${theme('--font-medium')}
    background: ${theme('--color-light')};
    border: 1px solid ${theme('--color-dark-night-10')};
    border-radius: 0.6rem;
  }

  ${GridBlock} {
    column-gap: 0.8rem;
    display: grid;
    grid-template-columns: 1fr 15.2rem;
    margin-top: 0.8rem;

    ${Button} {
      background: ${theme('--color-light')};
      height: 4.8rem;
    }
  }
`;
