import React from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';

import Toolbar from 'Containers/CardToolbar';

import Card from 'Components/Card';
import { Input } from 'Components/Form';
import Table from 'Components/ResponsiveTable';

import template from './Table';
import EmptyTemplate from './Table/EmptyTemplate';
import EmptyTemplateFilters from './Table/EmptyTemplateFilters';

export const Campaigns = ({
  className,
  data: { collection: campaigns } = {},
  filterAmmount,
  onCursorChange: handleCursorChange = () => {},
  onSearch: handleSearch = () => {},
}) => (
  <Card className={className}>
    <Toolbar filterAmmount={filterAmmount} title="CAMPAIGNS">
      <Input label="SEARCH" name="search" onChange={handleSearch} />
    </Toolbar>
    <Table
      {...template}
      emptyTemplate={filterAmmount ? EmptyTemplateFilters : EmptyTemplate}
      items={campaigns}
      onCursorChange={handleCursorChange}
      rows={5}
    />
  </Card>
);

Campaigns.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    campaigns: PropTypes.arrayOf(
      PropTypes.shape({
        interaction: PropTypes.instanceOf(Date),
        name: PropTypes.string,
        platforms: PropTypes.arrayOf(PropTypes.string),
        status: PropTypes.string,
        steps: PropTypes.arrayOf(
          PropTypes.shape({
            displayName: PropTypes.string,
          }),
        ),
        type: PropTypes.string,
      }),
    ),
    total: PropTypes.number,
  }),
  filterAmmount: PropTypes.number,
  isLoading: PropTypes.bool,
  onCursorChange: PropTypes.func,
  onSearch: PropTypes.func,
};

export default styled(Campaigns)`
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  padding: 0;

  @media (${theme('--breakpoint-small')}) {
    height: 100%;
  }

  @media (${theme('--screen-small')}) {
    ${Input} {
      width: 24rem;
    }
  }
`;
