import { createEffect } from '@redux-act';
import {
  all,
  call,
  put,
  select,
  takeMaybe,
  takeLatest,
} from 'redux-saga/effects';

import * as accounts from 'Actions/accounts';
import * as logger from 'Actions/logger';
import {
  boot,
  login,
  logout,
  recoverPassword,
  resetPassword,
} from 'Actions/session';

import { set } from 'Actions/member';

import { handleErrors } from 'Modules/handleErrors';

import log from 'Providers/logger';
import storage from 'Providers/storage';

import { account } from 'Selectors';

import service from 'Services/session';
import membersService from 'Services/members';

// Keep this order, contextEffect is used inside bootEffect
const contextEffect = createEffect(function* context() {
  const { email, id } = yield select(state => state.session);
  log.context({ email, id });
}, handleErrors);

const bootEffect = createEffect(
  function* bootSuccess() {
    yield put(accounts.get());
    yield takeMaybe(accounts.get.success);

    const current = yield select(account);

    const { collection: members } = yield membersService.get({
      account: current.id,
    });
    const me = members.find(({ status, you }) => you && status === 'ACTIVE');

    yield put(accounts.set(current.id));
    yield put(set(me));

    yield call(contextEffect);
  },
  function* bootError(error) {
    yield put(logger.error(error.message));
    yield put(logout());
  },
);

const loginEffect = createEffect(function* loginSuccess({
  email,
  password,
  remember,
}) {
  const { authKey: token, id } = yield service.login(email, password);
  yield put(login.success(id, token, email));
  if (!remember) {
    storage.setProvider('sessionStorage');
  }
  yield call(contextEffect);
},
handleErrors);

function* recoverPasswordEffect({ payload: { email } }) {
  try {
    yield service.recoverPassword(email);
  } catch (error) {
    if (error.message !== 'EMAIL_NOT_FOUND') yield handleErrors(error);
  }
}

const resetPasswordEffect = createEffect(function* resetSuccess(params) {
  const { password: newPassword, code, email } = params;
  const { resetId, password } = yield service.checkResetPassword(code, email);

  const { authKey, id } = yield service.resetPassword(
    email,
    newPassword,
    resetId,
    password,
  );
  yield put(login.success(id, authKey, email));
  yield put(logger.success('PASSWORD_MODIFIED'));
}, handleErrors);

const logoutEffect = createEffect(
  function* logoutSuccess() {
    yield service.logout();
    yield put(logout.success());
    storage.clear();
  },
  // We cannot use handleErrors, it'd be an infinite loop
  function* logoutError(error) {
    log.error(error);
    yield put(logout.success());
  },
);

export default function*() {
  yield all([
    takeLatest(boot, bootEffect),
    takeLatest(login, loginEffect),
    takeLatest(logout, logoutEffect),
    takeLatest(recoverPassword, recoverPasswordEffect),
    takeLatest(resetPassword, resetPasswordEffect),
  ]);
}
