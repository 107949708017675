import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@react-intl';
import { useField } from 'spooner';
import styled, { keyframes, theme, css } from '@styled-components';
import { v4 as uuid } from 'uuid';

const autofilling = keyframes``;

const Error = styled('span')``;
const Label = styled('label')``;
const Limit = styled('div')``;
const TextareaHTML = styled('textarea')``;

const Textarea = ({ autoresize, className, label, placeholder, ...props }) => {
  const prevId = useRef(uuid());
  const {
    disabled = false,
    error,
    id = prevId.current,
    limit,
    name,
    onBlur = () => {},
    onChange = () => {},
    onFocus = () => {},
    type = 'text',
    value,
    capitalize,
    ...fieldProps
  } = useField(props);
  const textarea = useRef(null);
  const [autofill] = useState(false);
  const [height, setHeight] = useState('inherit');

  const handleBlur = () => {
    onBlur();
  };

  const handleChange = event => {
    const { value: rvalue, scrollHeight } = event.target;

    if (autoresize) setHeight(scrollHeight);

    event.stopPropagation();

    if (limit && limit < rvalue.length) {
      onChange(rvalue.slice(0, limit));
    } else {
      onChange(rvalue);
    }
  };

  const handleFocus = () => {
    onFocus();
  };

  useEffect(() => {
    if (autoresize) setHeight(textarea.current.scrollHeight);
  }, [autoresize]);

  return (
    <div
      className={className}
      data-autofill={autofill}
      data-disabled={disabled}
      data-error={!!error}
      data-name={name}
      data-value={value}
    >
      <TextareaHTML
        {...fieldProps}
        ref={textarea}
        id={id}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        onInvalid={undefined}
        type={type}
        value={value}
        {...(autoresize && { style: { height } })}
      />
      {(label || placeholder) && (
        <FormattedMessage
          capitalize
          component={Label}
          htmlFor={id}
          id={label || placeholder}
        >
          {label || placeholder}
        </FormattedMessage>
      )}
      {limit && (
        <Limit>
          {value?.length || 0}/{limit}
        </Limit>
      )}
      {error && (
        <FormattedMessage capitalize component={Error} id={`ERRORS.${error}`}>
          {error}
        </FormattedMessage>
      )}
    </div>
  );
};

Textarea.propTypes = {
  autoresize: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  limit: PropTypes.number,
  placeholder: PropTypes.string,
};

const autoresizeMixin = ({ autoresize }) =>
  autoresize &&
  css`
    height: auto;
    padding-top: 2.4rem;

    ${TextareaHTML} {
      height: auto;
      max-height: 12rem;
      padding-top: 0;
      resize: none;
    }
  `;

export default styled(Textarea)`
  ${theme('--font-opacity-100')}
  background: ${theme('--color-light')};
  border-color: ${theme('--color-dark-night-10')};
  border-radius: 0.6rem;
  border-style: solid;
  border-width: 0.1rem;
  height: 12rem;
  outline: 0;
  position: relative;
  transition: all 0.2s ease-out;

  &::before {
    background-image: linear-gradient(180deg, ${theme(
      '--color-light',
    )} 70%, rgba(255, 255, 255, 0) 100%);
    border-radius: 0.6rem;
    content: '';
    display: block;
    height: 3.2rem;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &:focus-within {
    border-color: ${theme('--color-primary')};

    ${Label} {
      ${theme('--font-small')}
      color: ${theme('--color-primary')};
      top: 0.8rem;
    }

    ${Limit} {
      ${theme('--font-small')}
      bottom: 0.8rem;
      color: ${theme('--color-primary')};
    }
  }

  &[data-disabled="true"] ${Label} {
    ${theme('--font-opacity-10')}
  }

  /* The way to override styles with a high specificity is to simply add multiple && to avoid important */
  &&[data-error="true"] {
    border-color: ${theme('--color-alert')};
    margin-bottom: 2.4rem;

    ${TextareaHTML} {
      caret-color: ${theme('--color-alert')};
    }
  }

  &[data-error="true"]:focus-within {
    ${Label}, ${Limit} {
      color: ${theme('--color-alert')};
    }
  }

  &[data-autofill="false"][data-value=""] ${TextareaHTML}:not([placeholder]):not(:focus) + ${Label} {
    color: ${theme('--color-dark-night-60')};
    left: 2.6rem;
    transform: translateY(0.8rem) scale(1.16);
  }

  ${TextareaHTML} {
    ${theme('--font-medium')}
    ${theme('--font-opacity-80')}
    border: 0;
    border-radius: inherit;
    caret-color: ${theme('--color-primary')};
    height: 100%;
    outline: 0;
    padding: 2.8rem 1.6rem 1.6rem 1.6rem;
    width: 100%;

    &:-webkit-autofill {
      animation-name: ${autofilling};
      -webkit-text-fill-color: ${theme('--color-dark-night-80')};
    }

    &:focus {
      &::placeholder {
        ${theme('--font-opacity-20')}
      }
      &::-moz-placeholder {
        ${theme('--font-opacity-20')}
      }

      &::-webkit-input-placeholder {
        ${theme('--font-opacity-20')}
      }
    }

    &::placeholder {
      ${theme('--font-opacity-80')}
    }
    &::-moz-placeholder {
      ${theme('--font-opacity-80')}
    }

    &::-webkit-input-placeholder {
      ${theme('--font-opacity-80')}
    }
  }

  ${Label}, ${Limit} {
    ${theme('--font-small')}
    height: 1.6rem;
    left: 1.6rem;
    pointer-events: none;
    position: absolute;
    transition: all 0.2s ease-out;
  }

  ${Label} {
    color: ${theme('--color-dark-night-40')};
    top: 0.8rem;
  }

  ${Limit} {
    bottom: 0.8rem;
    color: ${theme('--color-dark-night-20')};
  }

  ${Error} {
    ${theme('--font-small')}
    color: ${theme('--color-alert')};
    display: inline-block;
    height: 1.6rem;
    left: 0;
    margin-top: 0.8rem;
    position: absolute;
    top: 100%;
  }

  ${autoresizeMixin}
`;
