import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Group from './Group';

const GroupList = ({
  account,
  className,
  groups,
  groupsSelected,
  onGroupAdd,
  onGroupCustomersChange,
}) => {
  const isGroupSelected = groupId =>
    groupsSelected.some(({ id }) => id === groupId);

  const getCustomersSelected = groupId =>
    groupsSelected.find(({ id }) => id === groupId)?.customers || [];

  return (
    <div className={className}>
      {groups.map(group => (
        <Group
          key={group.id}
          account={account}
          customersSelected={getCustomersSelected(group.id)}
          id={group.id}
          initialCustomers={group.data.initialCustomers || 0}
          isSelected={isGroupSelected(group.id)}
          name={group.name}
          onGroupAdd={onGroupAdd}
          onGroupCustomersChange={onGroupCustomersChange}
          totalContacts={group.totalContacts}
          withPhone={group.withPhone}
        />
      ))}
    </div>
  );
};

GroupList.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
  groups: PropTypes.array.isRequired,
  groupsSelected: PropTypes.array.isRequired,
  onGroupAdd: PropTypes.func.isRequired,
  onGroupCustomersChange: PropTypes.func.isRequired,
};

export default styled(GroupList)`
  background: #fff;
  border-radius: 0 0 0.6rem 0.6rem;
  box-shadow: 0px 1px 0px rgba(0, 15, 25, 0.05),
    0px 8px 16px rgba(0, 15, 25, 0.05);
  height: 28.8rem;
  overflow-y: scroll;
`;
