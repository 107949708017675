import React from 'react';
import PropTypes from 'prop-types';
import styled from '@styled-components';

import { ContextMenu } from 'Components/ContextMenu';

const Options = ({
  className,
  onDelete: handleDelete = () => {},
  onDownload: handleDownload = () => {},
}) => (
  <ContextMenu
    right
    className={className}
    options={{
      DOWNLOAD_DOCUMENT: handleDownload,
      REMOVE_DOCUMENT: handleDelete,
    }}
  />
);

Options.propTypes = {
  className: PropTypes.string,
  onDelete: PropTypes.func,
  onDownload: PropTypes.func,
};

export default styled(Options)``;
