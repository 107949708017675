import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { ArrayOfObjects } from '@array-utils';
import styled, { theme } from '@styled-components';

import Card from './components/Card';
import GetLink from './components/GetLink';
import EmptyTemplate from './components/EmptyTemplate';
import EmptyTemplateFilters from './components/EmptyTemplateFilters';
import Remove from './components/Remove';

const Layout = styled('div')``;

const MicroappStudio = ({
  className,
  cursor,
  data: {
    collection,
    microapps = collection.length ? collection : ArrayOfObjects(9),
  } = {},
  filters,
  onCursorChange: handleCursorChange = () => {},
  onDelete: handleDelete = () => {},
}) => {
  const [selected, setRowSelect] = useState();
  const [type, setType] = useState();

  const handleRowSelect = useCallback(
    (row, t) => {
      setRowSelect(row);
      setType(t);
    },
    [setRowSelect, setType],
  );

  const handleClose = useCallback(() => {
    setRowSelect();
  }, [setRowSelect]);

  const handleAccept = useCallback(() => {
    handleDelete(selected.id);
    handleClose();
  }, [handleClose, handleDelete, selected]);

  const handleCancel = useCallback(() => {
    handleClose();
    setType();
  }, [handleClose, setType]);

  const handleCursor = useCallback(
    ({ target }) => {
      if (target.scrollTop === target.scrollHeight - target.offsetHeight) {
        handleCursorChange(cursor + 1);
      }
    },
    [cursor, handleCursorChange],
  );

  useEffect(() => {
    const content = document.querySelector('main');

    content.addEventListener('scroll', handleCursor);
    return () => content.removeEventListener('scroll', handleCursor);
  }, [handleCursor]);

  const filtered = microapps.filter(({ empty }) => !empty);

  return (
    <>
      <Remove
        in={!!selected && type === 'delete'}
        onAccept={handleAccept}
        onCancel={handleCancel}
      />
      <GetLink
        in={!!selected && type === 'getLink'}
        onClose={handleClose}
        {...(selected && selected)}
      />
      <div className={className}>
        <Layout>
          {filtered.map(
            ({ key, id = key, image, name, title, type: t, url }) => (
              <Card
                id={id}
                image={image}
                name={name}
                onRowSelect={handleRowSelect}
                title={title}
                type={t}
                url={url}
              />
            ),
          )}
        </Layout>
        {!filtered.length &&
          (filters ? <EmptyTemplateFilters /> : <EmptyTemplate />)}
      </div>
    </>
  );
};

MicroappStudio.propTypes = {
  className: PropTypes.string,
  cursor: PropTypes.number,
  data: PropTypes.shape({
    microapps: PropTypes.arrayOf(PropTypes.object),
  }),
  filters: PropTypes.bool,
  onCursorChange: PropTypes.func,
  onDelete: PropTypes.func,
};

export default styled(MicroappStudio)`
  padding: 0;

  ${Layout} {
    display: grid;
    grid-gap: 3.2rem;
    grid-template-columns: repeat(1, 1fr);
  }

  @media (${theme('--column-8')}) {
    grid-column: span 8;
  }

  @media (${theme('--column-12')}) {
    grid-column: span 12;

    ${Layout} {
      grid-template-columns: repeat(3, 1fr);
    }
  }
`;
