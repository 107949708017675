import React, { useState } from 'react';
import PropTypes from '@prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { currentAccount } from 'Selectors';
import * as logger from 'Actions/logger';

import Confirmation from 'Components/CampaignForm/ConfirmationSave';
import Modal from 'Components/Modal';
import View from './EditCampaign';

import useCampaign from 'Queries/useCampaign';

const mapStateToProps = state => ({ account: currentAccount(state) });

const mapDispatch = dispatch =>
  bindActionCreators(
    { error: logger.error, success: logger.success },
    dispatch,
  );

const EditCampaign = ({
  account,
  depth,
  error,
  id,
  in: modalIn,
  onBack,
  order,
  success,
}) => {
  const { data } = useCampaign({ account, id });

  const [confirmationIsOpen, setConfirmationOpen] = useState(false);

  const handleUpdateSuccess = () => setConfirmationOpen(true);

  return (
    <>
      <Confirmation
        in={confirmationIsOpen}
        onAccept={onBack}
        title="YOUR_CAMPAIGN_HAS_BEEN_CREATED"
      />
      <Modal
        depth={depth}
        in={!confirmationIsOpen ? modalIn : false}
        onBack={onBack}
        onClose={onBack}
        order={order}
        title="EDIT_CAMPAIGN"
      >
        {data && (
          <View
            account={account}
            campaignId={id}
            data={data}
            handleUpdateSuccess={handleUpdateSuccess}
            onBack={onBack}
            onError={error}
            onSuccess={success}
          />
        )}
      </Modal>
    </>
  );
};

EditCampaign.propTypes = {
  account: PropTypes.number.isRequired,
  depth: PropTypes.number.isRequired,
  error: PropTypes.func.isRequired,
  id: PropTypes.any.isRequired,
  in: PropTypes.bool,
  onBack: PropTypes.func.isRequired,
  order: PropTypes.number,
  success: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatch)(EditCampaign);
