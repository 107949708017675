import React from 'react';
import { compose } from 'underscore';
import PropTypes from 'prop-types';
import { withStyles as css } from '@styled-components';
import service from 'Services/transactions';

import C from 'Constants';

import { ContextMenu } from 'Components/ContextMenu';
import { withRouter } from 'Components/Router';
import withPermissions from 'Components/with/permissions';

const {
  ROLE: { ADMIN, SUPER_ADMIN },
} = C;

const TransactionContextMenu = ({ account, className, from, platform, to }) => {
  const handleDownload = () => {
    const params = {
      account,
      from,
      limit: 50,
      platform,
      to,
    };

    service.download(params).then(({ url }) => {
      window.open(url);
    });
  };

  return (
    <ContextMenu
      right
      className={className}
      options={{ DOWNLOAD_CSV: handleDownload }}
    />
  );
};

TransactionContextMenu.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
  from: PropTypes.string,
  platform: PropTypes.string,
  to: PropTypes.string,
};

export default compose(
  css`
    margin-left: 2.4rem;
    margin-top: 0.24rem;
  `,
  withRouter,
  withPermissions([SUPER_ADMIN, ADMIN]),
)(TransactionContextMenu);
