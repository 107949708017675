import React, { useCallback, useReducer, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';

import Chip from 'Components/Chip';
import Tooltip from 'Components/Tooltip';

const OFFSET = 8;

const STATE = {
  COLLAPSED: 2,
  HIDE_TOOLTIP: 4,
  INITIAL: 1,
  SHOW_TOOLTIP: 3,
};

function reducer(state, action) {
  switch (action.type) {
    case STATE.INITIAL:
      return {
        ...state,
        hiddenTooltips: [],
        overflownTooltips: [],
        visible: false,
      };

    case STATE.COLLAPSED:
      return {
        ...state,
        hiddenTooltips: action.payload.hiddenTooltips,
        overflownTooltips: action.payload.overflownTooltips,
        tags: action.payload.tags,
      };

    case STATE.SHOW_TOOLTIP:
      return {
        ...state,
        activeTooltip: action.payload.activeTooltip,
        visible: true,
        x: action.payload.x,
        y: action.payload.y,
      };

    case STATE.HIDE_TOOLTIP:
      return {
        ...state,
        visible: false,
        x: undefined,
        y: undefined,
      };

    default:
      return state;
  }
}

const Tags = ({ tags: initialTags, className }) => {
  const [
    { activeTooltip, hiddenTooltips, overflownTooltips, tags, visible, x, y },
    dispatch,
  ] = useReducer(
    reducer,
    reducer(
      {
        tags: initialTags,
      },
      { type: STATE.INITIAL },
    ),
  );

  const ref = useRef();
  const dots = useRef();

  useEffect(() => {
    const { current } = ref;
    const { current: { offsetWidth: dotsWidth = 0 } = {} } = dots;

    const elements = current.querySelectorAll('span[data-collapsible="true"]');
    const hiddenTooltipsIndexes = [];
    const overflownTooltipsIndexes = [];

    const filtered = initialTags.reduce((acc, tag, index) => {
      const limit = [...acc, tag].reduce(
        (a, e) =>
          a +
          [...elements].find(({ innerHTML }) => innerHTML === e).offsetWidth +
          OFFSET,
        dotsWidth + OFFSET,
      );
      const { offsetWidth, scrollWidth } = [...elements].find(
        ({ innerHTML }) => innerHTML === tag,
      );
      if (scrollWidth > offsetWidth) {
        overflownTooltipsIndexes.push(index);
      }

      if (limit > current.offsetWidth) {
        hiddenTooltipsIndexes.push(index);
        return acc;
      }
      return [...acc, initialTags[index]];
    }, []);
    dispatch({
      payload: {
        hiddenTooltips: hiddenTooltipsIndexes,
        overflownTooltips: overflownTooltipsIndexes,
        tags: filtered,
      },
      type: STATE.COLLAPSED,
    });
  }, [initialTags]);

  const hide = useCallback(() => {
    dispatch({ type: STATE.HIDE_TOOLTIP });
  }, [dispatch]);

  const show = useCallback(
    tooltipIndex => e => {
      const rect = e.target.getBoundingClientRect();
      const scrollLeft = window.pageXOffset;
      const scrollTop = window.pageYOffset;

      dispatch({
        payload: {
          activeTooltip: tooltipIndex,
          x: rect.left + scrollLeft + rect.width / 2,
          y: rect.top + scrollTop + rect.height / 2,
        },
        type: STATE.SHOW_TOOLTIP,
      });
    },
    [dispatch],
  );

  const allTags = [...tags, '...'];
  return (
    <div ref={ref} className={className} data-dots={!!hiddenTooltips.length}>
      {allTags.map((tag, index) => (
        <Chip
          key={tag}
          data-collapsible
          {...([...hiddenTooltips, ...overflownTooltips].includes(index) && {
            onBlur: hide,
            onFocus: show(index),
            onMouseOut: hide,
            onMouseOver: show(index),
          })}
          {...(allTags.length - 1 === index && {
            'data-collapsible': false,
            ref: dots,
          })}
        >
          {tag}
          {visible &&
            [...overflownTooltips, ...hiddenTooltips].includes(index) &&
            index === activeTooltip && (
              <Tooltip in right x={x} y={y}>
                {allTags.length - 1 === index
                  ? hiddenTooltips.map(i => initialTags[i]).toString()
                  : tag}
              </Tooltip>
            )}
        </Chip>
      ))}
    </div>
  );
};

Tags.propTypes = {
  className: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
};

export default styled(Tags)`
  display: inline-flex;
  flex-wrap: nowrap;
  list-style: none;
  max-width: 100%;
  overflow: hidden;
  text-align: left;
  width: 100%;

  &[data-dots='true'] {
    ${Chip}:last-child {
      display: inline-block;
      text-align: center;
      visibility: visible;
      white-space: nowrap;
    }
  }

  ${Chip} {
    background-color: ${theme('--color-main-20')};
    border: none;
    color: ${theme('--color-main')};
    display: block;
    margin: 0 0.4rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:last-child {
      visibility: hidden;
    }
  }
`;
