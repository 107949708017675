import React, { useCallback, useState } from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from '@prop-types';
import { FormattedMessage } from '@react-intl';

import Button from 'Components/Button';

import ContentCard from './ContentCard';

const Content = styled('section')``;
const ContentGrid = styled('div')``;
const Select = styled(Button).attrs({ primary: true })``;

const SelectBrandContent = ({
  className,
  data: { collection: content = [] } = {},
  onSubmit,
  single,
}) => {
  const [selection, setSelected] = useState({});
  const makeSelect = useCallback(
    id => () => {
      if (single) {
        setSelected({ [id]: true });
      } else {
        setSelected({ ...selection, [id]: !selection[id] });
      }
    },
    [selection, single],
  );

  const handleSubmit = useCallback(() => {
    const selectedContent = content.filter(({ id }) => selection[id]);

    onSubmit(selectedContent);
  }, [content, onSubmit, selection]);

  return (
    <div className={className}>
      <Content>
        <ContentGrid>
          {content.map(item => (
            <ContentCard
              {...item}
              isSelected={selection[item.id]}
              onSelect={makeSelect(item.id)}
            />
          ))}
        </ContentGrid>
      </Content>
      <FormattedMessage
        capitalize
        component={Select}
        id="SELECT"
        onClick={handleSubmit}
      />
    </div>
  );
};

SelectBrandContent.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  onSubmit: PropTypes.func,
  single: PropTypes.bool,
};

export default styled(SelectBrandContent)`
  height: calc(100% - 8rem);

  ${Content} {
    height: calc(100% - 8rem);
    overflow-y: scroll;

    ${ContentGrid} {
      display: grid;
      grid-gap: 1.6rem;
      grid-template-columns: repeat(2, 1fr);
      justify-items: center;
      padding: 2rem;
    }
  }

  ${Select} {
    margin: 1.6rem 3.2rem;
    width: calc(100% - 6.4rem);
  }

  @media (${theme('--screen-small')}) {
    ${Content} {
      ${ContentGrid} {
        grid-gap: 3.2rem;
        grid-template-columns: repeat(4, 1fr);
        padding: 3.2rem;
      }
    }

    ${Select} {
      width: 14rem;
    }
  }
`;
