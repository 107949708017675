import React from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';
import { FormattedDate } from 'react-intl';

import Avatar from 'Components/Avatar';

const User = styled('span')``;
const Date = styled('time')``;
const Status = styled('div')``;
const DateStatus = styled('div')``;
const Description = styled('span')``;

const Notification = ({
  author: {
    firstName,
    image: { normal },
    lastName,
  },
  className,
  text,
  timestamp,
}) => (
  <article className={className}>
    <DateStatus>
      <FormattedDate
        component={Date}
        day="2-digit"
        month="long"
        value={timestamp}
        year="numeric"
      />
      <Status />
    </DateStatus>
    <Avatar image={normal} name={firstName} />
    <User>{`${firstName} ${lastName}`}</User>
    <Description>{text}</Description>
  </article>
);

Notification.propTypes = {
  author: PropTypes.shape({
    firstName: PropTypes.string,
    image: PropTypes.shape({
      normal: PropTypes.string,
    }),
    lastName: PropTypes.string,
  }),
  className: PropTypes.string,
  text: PropTypes.string,
  timestamp: PropTypes.string,
};

const backgroundColorMixin = props =>
  theme(
    props.status === 'READ' ? '--color-dark-night-10' : '--secondary-color',
  )(props);

export default styled(Notification)`
  ${theme('--font-small')}
  align-items: start;
  border-bottom: 0.1rem solid ${theme('--color-dark-night-10')};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1.6rem 0;

  ${Avatar} {
    margin-right: 1.6rem;
    order: -1;
    width: 4rem;
  }

  ${DateStatus} {
    ${theme('--font-opacity-40')}
    align-items: baseline;
    display: flex;
    flex-direction: row-reverse;
    margin-left: 5.6rem;
    margin-top: 0.8rem;
    order: 3;
  }

  ${User} {
    ${theme('--font-weight-medium')}
    align-items: start;
    display: flex;
    flex-basis: 4rem;
    flex-direction: column;
    flex-grow: 2.25;
    justify-content: center;
    min-height: 4rem;
  }

  ${Description} {
    ${theme('--font-opacity-40')}
    display: flex;
    flex-basis: 40rem;
    flex-direction: column;
    justify-content: center;
    margin-left: 5.6rem;
    min-height: 4rem;
    order: 2;
  }

  ${Status} {
    background: ${backgroundColorMixin};
    border-radius: 50%;
    height: 0.8rem;
    margin-right: 0.8rem;
    width: 0.8rem;
  }

  @media (${theme('--column-8')}) {
    ${Avatar} {
      order: 0;
    }

    ${DateStatus} {
      display: flex;
      flex-basis: 9rem;
      flex-direction: column;
      flex-grow: 1.25;
      margin-left: 0;
      order: 0;
    }

    ${User} {
      flex-basis: 8rem;
      flex-grow: 2.25;
      margin-bottom: 0;
    }

    ${Description} {
      flex-basis: 18rem;
      flex-grow: 4;
      margin-bottom: 0;
      margin-left: 4rem;
      order: 0;
    }

    ${Status} {
      margin-top: 1.2rem;
    }
  }
`;
