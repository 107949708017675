import React from 'react';
import { HAxis as LanningHAxis } from 'lanning';
import PropTypes from '@prop-types';
import { intlDefault, withIntl } from '@react-intl';

const HAxis = ({ intl = intlDefault, label: labelId, ...props }) => {
  const label =
    labelId && intl.formatMessage({ capitalize: true, id: labelId });

  return <LanningHAxis {...props} label={label} />;
};

HAxis.propTypes = {
  intl: PropTypes.intl,
  label: PropTypes.string,
};

export default withIntl(HAxis);
