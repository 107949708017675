import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled, { withStyles as css, theme } from '@styled-components';
import { compose } from 'underscore';
import { FormattedMessage } from '@react-intl';

import DataProvider from 'Containers/DataProvider';

import Button from 'Components/Button';
import CarouselPicker from 'Components/CarouselPicker';
import Form, { Input, Textarea } from 'Components/Form';
import ModalHeadline, { Wrapper, Heading } from 'Components/ViewHeadline';
import Tip from 'Components/Tip';

import { currentAccount } from 'Selectors';

import service from 'Services/products';

import Products from './Products';
import SectionHeadline from './SectionHeadline';

const mapStateToProps = state => ({
  account: currentAccount(state),
});

const Actions = styled('section')``;
const Tooltip = styled('div')``;

const MultiproductForm = ({
  account,
  className,
  defaultValue = {},
  onChange = () => {},
  onSubmit = () => {},
  onBack: handleBack = () => {},
}) => {
  const handleChange = useCallback(
    ({
      description,
      media,
      products,
      productsTitle,
      ratio,
      subtitle,
      title,
    }) => {
      onChange({
        description,
        media,
        products,
        productsTitle,
        ratio,
        subtitle,
        title,
        type: 'MULTIPRODUCT',
      });
    },
    [onChange],
  );

  return (
    <Form
      className={className}
      defaultValue={{
        media: [],
        ratio: 'square',
        ...defaultValue,
      }}
      onChange={handleChange}
      onSubmit={onSubmit}
    >
      <ModalHeadline
        heading="INSTRUCTIONS.MICRO_APP_TEMPLATE"
        title="MULTIPRODUCT"
      />
      <CarouselPicker titleId="IMAGE_OR_VIDEO" />
      <SectionHeadline heading="INSTRUCTIONS.TEXT_BLOCK" title="TEXT_BLOCK" />
      <Input capitalize label="TITLE" name="title" />
      <Input capitalize label="SUBTITLE" name="subtitle" />
      <Tip>
        <FormattedMessage component={Tooltip} id="INSTRUCTIONS.HTML_TEXT" />
      </Tip>
      <Textarea capitalize label="DESCRIPTION" name="description" />
      <SectionHeadline heading="INSTRUCTIONS.TEXT_BLOCK" title="PRODUCTS" />
      <Input capitalize label="TITLE" name="productsTitle" />
      <DataProvider
        name="products"
        params={{ account }}
        query={service.get}
        template={Products}
      />
      <Actions>
        <Button capitalize primary translate type="submit">
          ACTIONS.CREATE
        </Button>

        <Button capitalize secondary translate onClick={handleBack}>
          ACTIONS.BACK
        </Button>
      </Actions>
    </Form>
  );
};

MultiproductForm.propTypes = {
  account: PropTypes.string,
  className: PropTypes.string,
  defaultValue: PropTypes.object,
  onBack: PropTypes.func,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default compose(
  connect(mapStateToProps),
  css`
    padding-bottom: 6rem;
    position: relative;

    ${Wrapper} {
      ${Heading} {
        ${theme('--font-opacity-60')}
      }
    }

    ${Tip} {
      display: none;
      margin-top: 1.6rem;
      position: absolute;
      right: -3.6rem;
    }

    ${Input}, ${Textarea} {
      margin-top: 1.6rem;

      > * {
        resize: none;
      }
    }

    ${Products} {
      margin-top: 1.6rem;

      ${Input} {
        margin: 0;
      }
    }

    ${Actions} {
      background: ${theme('--color-blueish-white')};
      border-top: 0.1rem solid ${theme('--color-dark-night-10')};
      bottom: 0;
      display: flex;
      left: 0;
      padding: 2rem;
      position: fixed;
      width: 100vw;

      ${Button} {
        flex-basis: 0;
        flex-grow: 1;
        margin-right: 0.4rem;

        &:last-child {
          margin-left: 0.4rem;
          margin-right: 0;
        }
      }
    }

    @media (${theme('--screen-small')}) {
      padding-bottom: 0;

      ${Wrapper} ${Tip} {
        display: block;
      }

      ${Actions} {
        border: 0;
        display: inline-block;
        margin-top: 4rem;
        padding: 1.6rem 0;
        position: inherit;
        width: auto;

        ${Button} {
          margin: 0 1.6rem 0 0;
          width: 14.4rem;

          &:last-child {
            margin: 0 1.6rem 0 0;
          }
        }
      }
    }

    @media (${theme('--screen-medium')}) {
      max-width: 49.6rem;
    }
  `,
)(MultiproductForm);
