import { FormattedMessage } from '@react-intl';
import PropTypes from '@prop-types';
import React from 'react';
import styled, { theme } from '@styled-components';

import Confirmation from 'Components/Confirmation';

const Content = styled('div')``;
const Description = styled('p')``;
const Image = styled('img')``;
const Title = styled('p')``;

export const ConfirmationSave = ({
  className,
  in: isIn,
  isCreated,
  onAccept,
}) => {
  const title = isCreated
    ? 'YOUR_CAMPAIGN_HAS_BEEN_CREATED'
    : 'YOUR_CAMPAIGN_HAS_BEEN_UPDATED';

  return (
    <Confirmation className={className} in={isIn} onAccept={onAccept}>
      <Content>
        <Image
          alt="Campaign created"
          src="/images/campaignCreateConfirmation.svg"
        />
        <FormattedMessage capitalize primary component={Title} id={title} />
        <FormattedMessage
          capitalize
          primary
          component={Description}
          id="CUSTOMERS_WILL_RECEIVE_YOUR_MESSAGE_SOON"
        />
      </Content>
    </Confirmation>
  );
};

ConfirmationSave.propTypes = {
  className: PropTypes.string,
  in: PropTypes.bool,
  isCreated: PropTypes.bool,
  onAccept: PropTypes.func.isRequired,
};

export default styled(ConfirmationSave)`
  ${Content} {
    margin-top: -2rem;
    text-align: center;
  }

  ${Image} {
    margin-bottom: 0.7rem;
  }

  ${Title} {
    ${theme('--font-large')}
    color: ${theme('--color-dark-night-80')};
    line-height: 1.6rem;
    margin-bottom: 0.8rem;
  }

  ${Description} {
    ${theme('--font-small')}
    color: ${theme('--color-dark-night-80')};
  }
`;
