import React from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';

import { Row } from 'Components/ResponsiveTable';

import { PlaceholderNoData as Placeholder } from 'Containers/Placeholder';

const Question = styled(Placeholder)``;
const Reply = styled(Placeholder)``;

const Answer = ({ answer, className, empty, question }) => {
  return (
    <Row className={className} empty={empty}>
      <Question placeholder="campaign question">{question}</Question>
      <Reply placeholder="answer">{answer}</Reply>
    </Row>
  );
};

Answer.propTypes = {
  answer: PropTypes.string,
  className: PropTypes.string,
  empty: PropTypes.bool,
  question: PropTypes.string,
};

export default styled(Answer)`
  color: ${theme('--color-dark-night-80')};
  grid-template-columns: 1fr 1fr;
  height: 7.2rem;

  ${Question} {
    ${theme('--font-weight-demi')}
  }
`;
