import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import styled from '@styled-components';

import Card from 'Components/Card';
import Table from 'Components/ResponsiveTable';

import Template from './components/Product';

const BestSellers = ({
  className,
  data: { collection = [] } = {},
  onCursorChange: handleCursorChange = () => {},
}) => {
  const { push } = useHistory();
  const { pathname } = useLocation();

  const handleClick = useCallback(
    id => () => {
      push(`${pathname}/products/${id}`);
    },
    [pathname, push],
  );

  return (
    <Card className={className} title="BEST_SELLERS">
      <Table
        items={collection}
        onCursorChange={handleCursorChange}
        options={{ onClick: handleClick }}
        placeholders={{
          heading: '11K',
          subheading: '+11% sellings this month',
        }}
        rows={4}
        template={Template}
      />
    </Card>
  );
};

BestSellers.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    collection: PropTypes.array,
    trend: PropTypes.string,
  }),
  onCursorChange: PropTypes.func,
};

export default styled(BestSellers)`
  padding: 6.4rem 0 0;
`;
