import React from 'react';
import { withStyles } from '@styled-components';
import PropTypes from '@prop-types';

import { compose } from 'underscore';
import { connect } from 'react-redux';

import DataProvider from 'Containers/DataProvider/index';

import { currentAccount } from 'Selectors';

import service from 'Services/audience';

import EmptyTemplate from './EmptyTemplate';
import Template from './Template';

const mapStateToProps = state => ({
  account: currentAccount(state),
});

const UsersByGender = ({ account, className }) => (
  <DataProvider
    className={className}
    emptyTemplate={EmptyTemplate}
    params={{ account }}
    query={service.getGender}
    template={Template}
  />
);

UsersByGender.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
};

export default compose(connect(mapStateToProps), withStyles``)(UsersByGender);
