import React from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';
import { FormattedMessage } from '@react-intl';

import Card from 'Components/Card';

import Products from './Products';
import Summary from './Summary';

const Title = styled('h3')``;

const Cart = ({ className, items, summary }) => (
  <Card className={className}>
    <FormattedMessage component={Title} id="PRODUCT_DETAILS" />
    <Products items={items} />
    <Summary {...summary} />
  </Card>
);

Cart.propTypes = {
  className: PropTypes.string,
  items: PropTypes.object,
  summary: PropTypes.object,
};

export default styled(Cart)`
  ${Products} {
    margin-bottom: 1.6rem;
  }

  ${Summary} {
    margin: 0 0 0 auto;
    max-width: 30rem;
  }

  ${Title} {
    color: #000f19;
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 2.9rem;
    margin-bottom: 2.4rem;
  }
`;
