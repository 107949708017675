import React from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';

import Table, { Body } from 'Components/ResponsiveTable';

import DesktopTemplate from './DesktopTemplate';
import EmptyTemplate from './EmptyTemplate';
import EmptyTemplateFilters from './EmptyTemplateFilters';
import EmptyTemplateUnanswered from './EmptyTemplateUnanswered';

import Header from './Header';
import HeaderUnanswered from './HeaderUnanswered';

import Template from './Template';
import TemplateUnanswered from './TemplateUnanswered';
import DesktopTemplateUnanswered from './DesktopTemplateUnanswered';

const FaqTable = ({
  className,
  data: { collection: faqs = [] } = {},
  filterAmmount,
  isUnanswered,
  onCursorChange: handleCursorChange,
  options,
  role,
  ...props
}) => {
  return (
    <Table
      {...props}
      className={className}
      desktopRows={8}
      desktopTemplate={
        isUnanswered ? DesktopTemplateUnanswered : DesktopTemplate
      }
      emptyTemplate={
        // eslint-disable-next-line
        isUnanswered
          ? EmptyTemplateUnanswered
          : filterAmmount
          ? EmptyTemplateFilters
          : EmptyTemplate
      }
      header={isUnanswered ? HeaderUnanswered : Header}
      items={faqs}
      onCursorChange={handleCursorChange}
      options={options}
      overflow="visible"
      role={role}
      rows={5}
      template={isUnanswered ? TemplateUnanswered : Template}
    />
  );
};

FaqTable.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    faq: PropTypes.arrayOf(PropTypes.object),
  }),
  filterAmmount: PropTypes.number,
  isUnanswered: PropTypes.bool,
  onCursorChange: PropTypes.func,
  options: PropTypes.object,
  role: PropTypes.string,
};

export default styled(FaqTable)`
  ${Body} {
    overflow: visible;
  }
`;
