import React, { PureComponent } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import PropTypes from 'prop-types';
import { Field } from 'spooner';

export function withField(_defaultValue, _isolate = false) {
  return WrappedComponent => {
    const displayName = WrappedComponent.displayName || WrappedComponent.name;

    class inField extends PureComponent {
      static displayName = `Field(${displayName})`;

      static propTypes = {
        defaultError: PropTypes.any,
        defaultValue: PropTypes.any,
        error: PropTypes.any,
        isolate: PropTypes.bool,
        name: PropTypes.string.isRequired,
        onChange: PropTypes.func,
        value: PropTypes.any,
      };

      static defaultProps = {
        defaultValue: _defaultValue,
        isolate: _isolate,
        onChange() {},
      };

      render() {
        const {
          defaultError,
          defaultValue,
          error,
          isolate,
          name,
          onChange,
          value,
          ...props
        } = this.props;

        return (
          <Field
            defaultError={defaultError}
            defaultValue={defaultValue}
            error={error}
            isolate={isolate}
            name={name}
            onChange={onChange}
            value={value}
          >
            {state => <WrappedComponent {...props} {...state} name={name} />}
          </Field>
        );
      }
    }

    hoistNonReactStatics(inField, WrappedComponent);

    return inField;
  };
}

export default withField;
