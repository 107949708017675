import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedMessage } from '@react-intl';
import styled, { theme } from '@styled-components';
import { useLocation } from 'react-router-dom';

import RawAvatar from 'Components/Avatar';
import RawLink from 'Components/Link';
import Stepper from 'Components/Stepper';
import { Row } from 'Components/ResponsiveTable';

import {
  withPlaceholderNoData as withPlaceholder,
  PlaceholderNoData as Placeholder,
} from 'Containers/Placeholder';

const Label = styled(Placeholder)``;
const Interaction = styled(Label)``;
const Steps = styled(Label)``;
const TagList = styled(Placeholder.ul)``;
const Tag = styled('li')``;
const Avatar = styled(withPlaceholder(RawAvatar))``;
const Link = styled(withPlaceholder(RawLink))``;

const Template = ({
  className,
  avatar,
  empty,
  firstName,
  id,
  lastName,
  step,
  steps = [],
  type,
  updatedAt,
}) => {
  const { pathname } = useLocation();

  return (
    <Row className={className} empty={empty}>
      <Avatar fill image={avatar} name={`${firstName} ${lastName}`} />
      <Link
        placeholder="customer name"
        to={`${pathname}/customers/${id}`}
      >{`${firstName} ${lastName}`}</Link>
      <Interaction>
        {updatedAt && updatedAt !== '0' && (
          <FormattedDate
            day="2-digit"
            month="long"
            value={new Date(updatedAt)}
            year="numeric"
          />
        )}
      </Interaction>
      <Steps fill placeholder="customer steps">
        <Link to={`${pathname}/answers/${id}`}>
          <Stepper
            currentStep={steps.findIndex(({ key }) => key === step)}
            steps={steps.map(({ displayName }) => displayName)}
          />
        </Link>
      </Steps>
      <TagList placeholder="tag list">
        <FormattedMessage capitalize component={Tag} id={`USER_TYPE.${type}`} />
      </TagList>
    </Row>
  );
};

Template.propTypes = {
  avatar: PropTypes.string,
  className: PropTypes.string,
  empty: PropTypes.bool,
  firstName: PropTypes.string,
  id: PropTypes.number,
  lastName: PropTypes.string,
  step: PropTypes.string,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string,
      key: PropTypes.string,
    }),
  ),
  type: PropTypes.string,
  updatedAt: PropTypes.string,
};

export default styled(Template)`
  align-items: center;
  color: inherit;
  display: grid;
  grid-gap: 0.8rem 1.2rem;
  grid-template: 1.6rem 1.6rem 1fr / 4rem 1fr auto;
  height: 10rem;
  justify-content: space-around;
  padding: 1.2rem 2.4rem;

  ${Label} ,${Link} {
    ${theme('--font-medium')}
    ${theme('--font-opacity-40')}
    ${theme('--font-weight-book')}
  }

  ${Avatar} {
    grid-column: 1;
    grid-row: 1 / span 2;
    height: 100%;
  }

  ${Link} {
    ${theme('--font-weight-medium')}
    color: ${theme('--color-primary')};
    grid-column: 2 / span 2;
    grid-row: 1;
  }

  ${Interaction} {
    ${theme('--font-opacity-60')}
    ${theme('--font-weight-book')}
    grid-column: 2 / span 2;
    grid-row: 2;
  }

  ${Steps} {
    grid-column: 1 / span 2;
    grid-row: 3;
    margin-right: 2rem;
  }

  ${TagList} {
    grid-column: 3;
    grid-row: 3;

    ${Tag} {
      background: none;
      border: 1px solid ${theme('--color-dark-night-40')};
      border-radius: 0.6rem;
      color: ${theme('--color-dark-night-40')};
      display: inline-block;
      height: 2.4rem;
      padding: 0.4rem;
      text-transform: uppercase;
    }
  }
`;
