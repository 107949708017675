import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from '@react-intl';
import styled, { theme } from '@styled-components';
import Placeholder from 'Containers/Placeholder/index';

const Wrapper = styled('div')``;
const Name = styled(Placeholder)``;
const Step = styled(Placeholder)``;

const Funnel = ({
  className,
  active,
  displayName,
  onClick: handleClick = () => {},
  percentage,
}) => {
  return (
    <button
      className={className}
      data-active={active}
      onClick={handleClick}
      type="button"
    >
      <Wrapper>
        <Name placeholder="step name">{displayName}</Name>
        <FormattedNumber
          component={Step}
          maximumFractionDigits={0}
          minimumFractionDigits={0}
          placeholder="step value"
          style="percent" //eslint-disable-line
          value={percentage || 0}
        />
      </Wrapper>
    </button>
  );
};

Funnel.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  displayName: PropTypes.string,
  onClick: PropTypes.func,
  percentage: PropTypes.number,
};

export default styled(Funnel)`
  background: none;
  border: none;
  border-radius: 0;
  cursor: pointer;
  padding: 0 2.4rem;
  width: 100%;

  &[data-active="true"] {
    background: ${theme('--color-primary-5')};
  }

  &:focus {
    border-radius: 0;
  }

  &:hover {
    background: ${theme('--color-primary-10')};
  }

  ${Wrapper} {
    align-items: center;
    box-shadow: inset 0 -0.1rem 0 rgba(0, 15, 25, 0.03);
    display: flex;
    height: 8rem;
    justify-content: space-between;
    padding: 2rem 0;
  }

  ${Name} {
    ${theme('--font-medium')}
    ${theme('--font-weight-medium')}
  }

  ${Step} {
    ${theme('--font-small')}
    ${theme('--font-opacity-40')}
    ${theme('--font-weight-book')}
  }
`;
