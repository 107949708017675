import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@react-intl';
import styled, { theme } from '@styled-components';

import Button from 'Components/Button';
import Form from 'Components/Form';

import Personal from './components/Personal';
import Groups from './components/Groups';
import Delivery from './components/Delivery';

const Actions = styled('div')``;

export const CustomerForm = ({
  className,
  customer: {
    firstName,
    id,
    images: { large: image } = {},
    groups = [],
    lastName,
    deliveryAddress,
  } = {},
  customer,
  groupOptions,
  onSubmit = () => {},
  ...props
}) => {
  const all = groups.find(group => group.name === 'All');
  const rest = groups.filter(group => group.name !== 'All');
  const handleSubmit = value => {
    onSubmit({ ...value, groups: [...value.groups, all] });
  };
  return (
    <Form
      className={className}
      defaultValue={{
        firstName,
        groups: rest,
        lastName,
        ...deliveryAddress,
        ...customer,
        ...props,
      }}
      onSubmit={handleSubmit}
    >
      <Personal image={image} isEdit={!!id} />
      <Delivery />
      <Groups data={groupOptions} />
      <Actions>
        <FormattedMessage
          capitalize
          component={Button}
          id="ACTIONS.SAVE"
          type="submit"
        >
          save
        </FormattedMessage>
      </Actions>
    </Form>
  );
};

CustomerForm.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.shape({
    deliveryAddress: PropTypes.object,
    firstName: PropTypes.string,
    groups: PropTypes.array,
    id: PropTypes.number,
    images: PropTypes.object,
    lastName: PropTypes.string,
  }),
  deliveryAddress: PropTypes.shape({
    city: PropTypes.string,
    country: PropTypes.string,
    postalCode: PropTypes.string,
    region: PropTypes.string,
    street1: PropTypes.string,
  }),
  firstName: PropTypes.string,
  groupOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  id: PropTypes.string,
  images: PropTypes.shape({
    large: PropTypes.string,
  }),
  lastName: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default styled(CustomerForm)`
  padding: 0;

  ${Button} {
    width: 100%;
  }

  @media (${theme('--column-12')}) {
    margin: 1.6rem auto;
    max-width: 50rem;

    ${Actions} {
      ${Button} {
        width: 16rem;
      }
    }
  }
`;
