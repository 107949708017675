import React, { memo, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@react-intl';
import { useField } from 'spooner';
import styled, {
  withStyles as css,
  keyframes,
  theme,
} from '@styled-components';
import { v4 as uuid } from 'uuid';
import { compose } from 'underscore';

import Icon from '../../Icon';

const InputHTML = styled('input')``;
const Label = styled('label')``;
const Rect = styled('div')``;

const checking = keyframes`
  from {
    stroke-dasharray: 13.55px;
    stroke-dashoffset: 13.55px;
  }
  
  to {
    stroke-dasharray: 13.55px;
    stroke-dashoffset: 0;
  }
`;

const Checkbox = ({ className, label, ...props }) => {
  const prevId = useRef(uuid());
  const {
    id = prevId.current,
    name,
    onBlur = () => {},
    onChange = () => {},
    onFocus = () => {},
    value = false,
    ...fieldProps
  } = useField(props);

  const handleBlur = () => {
    onBlur();
  };

  const handleChange = event => {
    const { checked } = event.target;

    event.stopPropagation();

    onChange(checked);
  };

  const handleFocus = () => {
    onFocus();
  };

  return (
    <div
      className={className}
      data-checked={value}
      data-name={name}
      data-value={value}
    >
      <InputHTML
        {...fieldProps}
        checked={value}
        id={id}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        type="checkbox"
      />
      <Rect aria-hidden="true">
        <Icon color="--color-light" name="check">
          CHECK
        </Icon>
      </Rect>
      {label && (
        <FormattedMessage capitalize component={Label} htmlFor={id} id={label}>
          {label}
        </FormattedMessage>
      )}
    </div>
  );
};

Checkbox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
};

export default compose(
  css`
    align-items: center;
    display: inline-flex;
    position: relative;

    &[data-value='true'] ${Rect} {
      animation: ${checking} 350ms ease-out;
      background-color: ${theme('--color-primary')};
      border-color: ${theme('--color-primary')};
    }

    &:focus-within {
      ${Rect} {
        border-color: ${theme('--color-primary')};
      }

      &[data-value='true'] ${Rect} {
        background-color: ${theme('--color-primary-120')};
        border-color: ${theme('--color-primary-120')};
      }
    }

    ${InputHTML} {
      height: 1.6rem;
      opacity: 0;
      position: absolute;
      width: 1.6rem;
    }

    ${Rect} {
      align-items: center;
      background-color: ${theme('--color-light')};
      border: 0.1rem solid ${theme('--color-dark-night-10')};
      border-radius: 0.4rem;
      cursor: pointer;
      display: inline-flex;
      height: 1.6rem;
      justify-content: center;
      text-align: center;
      transition: background-color 250ms ease-out, border-color 250ms ease-out;
      width: 1.6rem;

      ${Icon} {
        height: 0.9rem;
        margin: 0.1rem 0 0;
        width: 1.3rem;
      }
    }

    ${Label} {
      ${theme('--font-small')}
      color: ${theme('--color-dark-night-60')};
      margin-left: 0.8rem;
    }
  `,
  memo,
)(Checkbox);
