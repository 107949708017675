import React from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from 'prop-types';

import { FormattedMessage } from '@react-intl';

import Chip from 'Components/Chip';
import Icon from 'Components/Icon';

const Data = styled('div')``;
const Message = styled('div')``;
const TypeBlock = styled('div')``;

const TagsValue = ({ dataValue }) => (
  <div>{dataValue && dataValue.map(item => <Chip>{item.name}</Chip>)}</div>
);

const TextValue = ({ dataValue }) => <div>{dataValue}</div>;

const HyperlinkValue = ({ dataValue, link }) => (
  <a href={link} target="_blanc">
    {dataValue}
  </a>
);

const TypeValue = ({ dataValue }) => (
  <TypeBlock>
    {dataValue}
    <Icon name={dataValue.toLowerCase()} />
  </TypeBlock>
);

const MessageValue = ({ dataValue }) => {
  const messageWithTags = dataValue.replaceAll(
    /<%=(\w+)%>/g,
    '<span>$1</span>',
  );

  return <Message dangerouslySetInnerHTML={{ __html: messageWithTags }} />;
};

const blockType = {
  hyperlink: HyperlinkValue,
  message: MessageValue,
  tags: TagsValue,
  text: TextValue,
  type: TypeValue,
};

const DataBlock = ({ className, dataValue, link, title, valueType }) => {
  const Value = blockType[valueType];

  return (
    <Data className={className}>
      <FormattedMessage capitalize id={title} />
      <Value dataValue={dataValue} link={link} />
    </Data>
  );
};

DataBlock.propTypes = {
  className: PropTypes.string,
  dataValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  link: PropTypes.string,
  title: PropTypes.string,
  valueType: PropTypes.string,
};

HyperlinkValue.propTypes = {
  dataValue: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

TagsValue.propTypes = {
  dataValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

TextValue.propTypes = {
  dataValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

TypeValue.propTypes = {
  dataValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

MessageValue.propTypes = {
  dataValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default styled(DataBlock)`
  align-items: center;
  box-shadow: inset 0px -1px 0px rgba(0, 15, 25, 0.03);
  color: #333f47;
  display: flex;
  justify-content: space-between;
  min-height: 5.6rem;

  ${Chip} {
    background: #eaccff;
    border: none;
    border-radius: 0.6rem;
    color: ${theme('--color-main')};
    margin-left: 0.4rem;
    padding: 0.4rem 0.8rem 0.4rem;
  }

  ${Message} {
    line-height: 2.4rem;
    margin: 2.1rem 0;
    max-width: 50%;
    text-align: right;

    span {
      background: #ccdfff;
      border-radius: 0.4rem;
      box-shadow: none;
      color: #005dff;
      display: inline-block;
      font-size: 0.9em;
      height: 2.4rem;
      margin: 0 0.1rem;
      padding: 0 0.5rem;
      text-align: center;
      vertical-align: baseline;
    }
  }

  ${Icon} {
    height: 2.4rem;
    margin-left: 2.8rem;
  }

  ${TypeBlock} {
    align-items: center;
    color: #999fa3;
    display: flex;
  }
`;
