import React from 'react';
import PropTypes from '@prop-types';
import styled, { theme, withStyles as css } from '@styled-components';
import Card, { Title } from 'Components/Card';
import MultiCard from 'Components/MultiCard';
import { injectIntl, intlShape } from '@react-intl';

import UsersByGender from 'Containers/UsersByGenderChart';
import UsersByLocaleChart from 'Containers/UsersByLocaleChart';
import UsersByOSChart from 'Containers/UsersByOSChart';
import UsersByPlatformChart from 'Containers/UsersByPlatformChart';

import PieChart from 'Components/Charts/PieChart';
import { compose } from 'underscore';

const Desktop = styled('article')``;
const Mobile = styled('article')``;

let content;

function generateContent(intl) {
  if (!content) {
    content = [
      {
        Component: UsersByGender,
        name: intl.formatMessage({ id: 'BY_GENDER' }),
        value: 'BY_GENDER',
      },
      {
        Component: UsersByLocaleChart,
        name: intl.formatMessage({ id: 'BY_LOCALE' }),
        value: 'BY_LOCALE',
      },
      {
        Component: UsersByOSChart,
        name: intl.formatMessage({ id: 'BY_OS' }),
        value: 'BY_OS',
      },
      {
        Component: UsersByPlatformChart,
        name: intl.formatMessage({ id: 'BY_PLATFORM' }),
        value: 'BY_PLATFORM',
      },
    ];
  }

  return content;
}

const UserCharts = ({ className, intl }) => (
  <section className={className}>
    <Desktop>
      <Card title="USERS_BY_GENDER">
        <UsersByGender />
      </Card>
      <Card title="USERS_BY_LOCALE">
        <UsersByLocaleChart />
      </Card>
      <Card title="USERS_BY_OS">
        <UsersByOSChart />
      </Card>
      <Card title="USERS_BY_PLATFORM">
        <UsersByPlatformChart />
      </Card>
    </Desktop>
    <Mobile>
      <MultiCard content={generateContent(intl)} title="USERS" />
    </Mobile>
  </section>
);

UserCharts.propTypes = {
  className: PropTypes.string,
  intl: intlShape,
};

export default compose(
  injectIntl,
  css`
    ${Desktop} {
      display: none;
      grid-gap: 3.2rem;
      grid-template: auto / repeat(4, 1fr);

      ${Card} {
        display: grid;
        grid-column: span 1;
        grid-row: span 1;

        ${Title} {
          ${theme('--font-medium')}
          ${theme('--font-weight-book')}
          color: ${theme('--color-dark-night-60')};
        }
      }
    }

    ${PieChart} {
      align-items: flex-end;
      grid-template: 20rem auto / 1fr;
      padding-top: 4.8rem;
    }

    ${Mobile} {
      grid-column: span 4;
    }

    ${Card}, ${MultiCard} {
      height: 42rem;
      position: relative;
    }

    @media (${theme('--column-12')}) {
      ${Desktop} {
        display: grid;
      }

      ${Mobile} {
        display: none;
      }
    }
  `,
)(UserCharts);
