import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@styled-components';

import Card from 'Components/Card';

import { getUnit } from 'Modules/dates';
import DataProvider from 'Containers/DataProvider';

import { currency as currentCurrency, currentAccount } from 'Selectors';

import service from 'Services/campaigns';
import LineChartLoader from 'Components/LineChartLoader';

import Chart from './Chart';

const mapStateToProps = state => ({
  account: currentAccount(state),
  currency: currentCurrency(state),
});

const CustomerLifetimeChart = ({
  account,
  className,
  from = moment()
    .subtract(1, 'year')
    .format(),
  to = moment().format(),
}) => {
  const unit = getUnit(from, to);

  return (
    <DataProvider
      params={{ account, from, to, unit }}
      query={service.getMessagesDeliveredAnalytics}
      template={({ data = {} }) => {
        return (
          <Card className={className} title="MESSAGES_DELIVERED">
            <Chart
              data={Object.entries(data).map(([date, MessagesDelivered]) => ({
                x: date,
                y1: MessagesDelivered,
              }))}
              loader={LineChartLoader}
              unit={unit}
            />
          </Card>
        );
      }}
    />
  );
};

CustomerLifetimeChart.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
  from: PropTypes.string,
  to: PropTypes.string,
};

export default compose(
  connect(mapStateToProps),
  withStyles``,
)(CustomerLifetimeChart);
