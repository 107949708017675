import React, { Component } from 'react';
import PropType from 'prop-types';
import styled, { css, theme } from '@styled-components';
import { isEqual } from 'underscore';

import Logger from 'Providers/logger';

import Step from './Step';

const NO_INTERACTION = -1;

const getStepPosition = ({ currentStep, steps }) =>
  currentStep < 0 || currentStep > steps.length ? 0 : currentStep;
const getStepsMaxIndex = ({ steps }) => steps.length - 1;

const verticalStyles = css`
  flex-direction: column;
  max-width: 1.6rem;
  min-height: 35rem;
  min-width: 1.6rem;

  &::before {
    border-bottom: none;
    border-left: 0.4rem dotted ${theme('--color-dark-night-10')};
    height: 100%;
    left: 0.6rem;
    top: 0;
    width: 1.6rem;
  }

  &::after {
    height: calc(
      (100% - 1.6rem) * ${getStepPosition} / ${getStepsMaxIndex} + 0.8rem
    );
    transition: height ease-out 300ms;
    width: 1.6rem;
  }

  ${Step} {
    box-shadow: inset 0 0 0 0.4rem ${theme('--color-primary')};
    height: 1.6rem;
    width: 1.6rem;
  }
`;

export class Stepper extends Component {
  static checkBounds(steps, currentStep) {
    if (currentStep < NO_INTERACTION || currentStep > steps.length) {
      Logger.error(`The step that you provided does not exist`);
    }
  }

  static propTypes = {
    className: PropType.string,
    currentStep: PropType.number,
    onChange: PropType.func,
    onClick: PropType.func,
    steps: PropType.array.isRequired,
    tooltip: PropType.bool,
  };

  static defaultProps = {
    onChange() {},
    onClick() {},
    tooltip: true,
  };

  componentDidMount() {
    const { steps = [], currentStep } = this.props;
    Stepper.checkBounds(steps, currentStep);
  }

  componentDidUpdate(prevProps) {
    const { steps: prevSteps, currentStep: prevStep } = prevProps;
    const { steps, currentStep } = this.props;

    if (!isEqual(prevSteps, steps) || prevStep !== currentStep) {
      Stepper.checkBounds(steps, currentStep);
    }
  }

  handleChange = (step, index) => () => {
    const { onClick, onChange } = this.props;
    onClick(step);
    onChange(step, index);
  };

  render() {
    const { className, steps, tooltip } = this.props;

    return (
      <ul className={className}>
        {steps.map((step, index) => (
          <Step
            key={step}
            name={step}
            onClick={this.handleChange(step, index)}
            tooltip={tooltip}
          />
        ))}
      </ul>
    );
  }
}

export default styled(Stepper)`
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;

  &::before {
    border-bottom: 0.4rem dotted ${theme('--color-dark-night-10')};
    content: '';
    display: block;
    position: absolute;
    top: calc(50% - 0.32rem / 2);
    width: 100%;
  }

  &::after {
    background: #ccdfff; /* we can't use the theme because we need a opaque color */
    border-radius: 0.8rem;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: width ease-out 300ms;
    width: calc(
      (100% - 0.8rem) * ${getStepPosition} / ${getStepsMaxIndex} + 0.4rem
    );
  }

  ${Step} {
    background-color: ${theme('--color-primary')};
    border-radius: 50%;
    box-shadow: inset 0 0 0 0.2rem ${theme('--color-primary')};
    height: 0.8rem;
    position: relative;
    width: 0.8rem;

    &:nth-child(${props => getStepPosition(props) + 1}) ~ * {
      background-color: ${theme('--color-light')};
    }
  }

  ${props => props.vertical && verticalStyles}
`;
