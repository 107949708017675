import React, { useCallback, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@styled-components';

import { usePrevious } from 'Hooks';

import Table from 'Components/Table';

import { useDesktop } from './hooks';

export * from 'Components/Table';

const ResponsiveTable = ({
  className,
  cursor: initialCursor = 0,
  header: Header,
  items = [],
  overflow = 'hidden',
  rows,
  desktopRows = rows,
  template: Template,
  desktopTemplate: DesktopTemplate = Template,
  onCursorChange = () => {},
  ...rest
}) => {
  const [cursor, setCursor] = useState(initialCursor);

  const table = useRef();
  const isDesktop = useDesktop(table);
  const currentRows = isDesktop ? desktopRows : rows;
  const previousRows = usePrevious(currentRows);

  const handleChange = useCallback(
    c => {
      onCursorChange(c, currentRows);
      setCursor(c);
    },
    [onCursorChange, setCursor, currentRows],
  );

  useEffect(() => {
    if (previousRows && currentRows !== previousRows) {
      const previousCursor = cursor;
      const currentCursor = Math.floor(
        (previousCursor * previousRows) / currentRows,
      );
      onCursorChange(currentCursor, currentRows);
      setCursor(currentCursor);
    }
  }, [cursor, currentRows, previousRows, onCursorChange, setCursor]);

  const props = isDesktop
    ? {
        header: Header,
        pages: 3,
        template: DesktopTemplate,
      }
    : {
        pages: 1,
        template: Template,
      };

  return (
    <div ref={table} style={{ overflow }}>
      <Table
        key={cursor}
        className={className}
        cursor={cursor}
        items={items}
        {...props}
        {...rest}
        isDesktop={isDesktop}
        onCursorChange={handleChange}
        rows={currentRows}
      />
    </div>
  );
};

ResponsiveTable.propTypes = {
  className: PropTypes.string,
  cursor: PropTypes.number,
  desktopRows: PropTypes.number,
  desktopTemplate: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  header: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  items: PropTypes.arrayOf(PropTypes.object),
  onCursorChange: PropTypes.func,
  overflow: PropTypes.string,
  rows: PropTypes.number,
  template: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default styled(ResponsiveTable)``;
