import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import styled, { withStyles as css, theme } from '@styled-components';

import { withRouter } from 'Components/Router';

const Tab = styled('button')``;

const Navbar = ({ className, current, tabs, makeSelect }) => {
  return (
    <nav className={className}>
      {tabs.map(tab => (
        <Tab
          key={tab}
          translate
          data-active={tab === current}
          onClick={makeSelect(tab)}
          type="button"
        >
          {tab}
        </Tab>
      ))}
    </nav>
  );
};

Navbar.propTypes = {
  className: PropTypes.string,
  current: PropTypes.string,
  makeSelect: PropTypes.func,
  tabs: PropTypes.arrayOf(PropTypes.string),
};

export default compose(
  css`
    display: flex;
    overflow: scroll;
    padding-bottom: 0.8rem;

    ${Tab} {
      ${theme('--font-large')}
      ${theme('--font-opacity-60')}
      ${theme('--font-weight-medium')}
      align-items: center;
      background: none;
      border: none;
      border-radius: 0.6rem;
      cursor: pointer;
      display: flex;
      line-height: 2.4rem;
      outline: none;
      padding: 0.8rem 1.6rem;
      transition: background-color ease-out 250ms, color ease-out 250ms;
      white-space: nowrap;

      &[data-active="true"] {
        ${theme('--font-opacity-80')}
        background-color: ${theme('--color-blueish-white-140')};
      }
    }

    @media (${theme('--screen-large')}) {
      flex-direction: column;
      padding: 0;

      ${Tab} {
        ${theme('--font-opacity-60')}
        background-color: ${theme('--color-blueish-white')};
        box-shadow: inset 0 -0.1rem 0 ${theme('--color-primary-5')};
        padding: 1.6rem 2rem;

        &:last-of-type {
          box-shadow: none;
        }

        &[data-active="true"] {
          ${theme('--font-opacity-80')}
          background-color: ${theme('--color-light')};
        }
      
      }
    }
  `,
  withRouter,
)(Navbar);
