import React from 'react';
import PropTypes from 'prop-types';
import styled from '@styled-components';

const Title = ({ className }) => <h1 className={className}>JoinedApp</h1>;

Title.propTypes = {
  className: PropTypes.string,
};

export default styled(Title)`
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
`;
