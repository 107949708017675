import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@react-intl';
import styled, { theme } from '@styled-components';

import Button from 'Components/Button';
import ButtonLoader from 'Components/ButtonLoader';
import Form, { Input } from 'Components/Form';
import { useMultiDataProviderContext } from 'Containers/MultiDataProvider';
import Services from 'Services/user-generated-content';

import MediaUploader from './MediaUploader';
import TypeSelector from './TypeSelector';
import PlatformSelector from './PlatformSelector';

const Actions = styled('div')``;
const Label = styled('label')``;
const SectionDescription = styled('p')``;

const EditUGC = ({ className, data: { collection } = {}, onClose }) => {
  const [formValues, setFormValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  let image;
  let type;
  let ofaId;
  let id;
  let platform;
  let tags = false;

  if (collection) {
    image = collection[0].image;
    type = collection[0].type.toUpperCase();
    ofaId = collection[0].ofaId;
    id = collection[0].id;
    platform = collection[0].platform.toUpperCase();
    tags = collection[0].tags ? collection[0].tags.join(', ') : '';
  }
  const { refetch } = useMultiDataProviderContext('userGeneratedContent');
  const handleFormChange = formData => setFormValues(formData);

  const handleClick = async () => {
    setIsLoading(true);
    await Services.update({ account: ofaId, data: formValues, id });
    onClose();
    setIsLoading(false);

    if (refetch) {
      refetch();
    }
  };

  if (formValues.tag !== undefined) tags = formValues.tag;

  const isDisabled =
    !formValues.file &&
    !formValues.tag &&
    !formValues.type &&
    !formValues.platform;

  return (
    <Form className={className} onChange={handleFormChange}>
      <Label>
        <MediaUploader image={image} />
        <FormattedMessage
          capitalize
          component={SectionDescription}
          id="INSTRUCTIONS.SELECT_MEDIA_TAG"
        />
        {tags && <Input label="TAG" name="tag" value={tags} />}
        {!tags && <Input label="TAG" name="tag" />}

        <FormattedMessage
          capitalize
          component={SectionDescription}
          id="INSTRUCTIONS.SELECT_TYPE"
        />
        <TypeSelector init={type} />
        <FormattedMessage
          capitalize
          component={SectionDescription}
          id="INSTRUCTIONS.SELECT_PLATFORM"
        />
        <PlatformSelector init={platform} />
        <Actions>
          <FormattedMessage
            capitalize
            primary
            component={ButtonLoader}
            disabled={isDisabled}
            id="ACTIONS.UPDATE"
            isLoading={isLoading}
            onClick={handleClick}
            params={{ data: formValues }}
          />
          <FormattedMessage
            capitalize
            secondary
            component={Button}
            id="ACTIONS.CANCEL"
            onClick={onClose}
          />
        </Actions>
      </Label>
    </Form>
  );
};

EditUGC.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  onClose: PropTypes.func,
};

export default styled(EditUGC)`
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;

  ${SectionDescription} {
    ${theme('--font-medium')}
    ${theme('--font-weight-book')}
    color: ${theme('--color-dark-night-60')};
  }

  ${Actions}{
    display: grid;
    grid-gap: 0.8rem;
    grid-template: auto / 1fr 1fr;

    ${Button}{
      margin-left: 1rem;
      padding: 1.4rem;
    }
  }

  @media (${theme('--screen-small')}){
    ${Actions}{
      display: block;

      ${Button}{
        padding: 1.4rem;
        width: 17rem;

        &:not(:last-child){
          margin-right: 1.6rem;
        }

      }
    }
  }
`;
