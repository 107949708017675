import React from 'react';
import PropTypes from '@prop-types';
import { FormattedMessage } from '@react-intl';
import styled, { theme } from '@styled-components';

import { Switch } from 'Components/Router';

import Logotype from 'Components/Logotype';
import Title from 'Components/Title';

const Header = styled('header')``;
const Main = styled('main')``;
const Footer = styled('footer')``;
const Wrapper = styled('section')``;
const Link = styled('a')``;

const Session = ({ children, className }) => (
  <section className={className}>
    <Wrapper>
      <Header>
        <Title />
        <Link to="/">
          <Logotype withText />
        </Link>
      </Header>
      <Main>
        <Switch>{children}</Switch>
      </Main>
    </Wrapper>
    <Footer>
      <FormattedMessage
        capitalize
        component={Link}
        href="mailto:info@joinedapp.com"
        id="CONTACT"
      />
      <FormattedMessage
        capitalize
        component={Link}
        href="https://joined.app"
        id="COPYRIGHT"
      />
    </Footer>
  </section>
);

Session.propTypes = {
  children: PropTypes.children,
  className: PropTypes.string,
};

export default styled(Session)`
  align-items: center;
  background: ${theme('--color-light')};
  display: flex;
  flex-direction: column;
  height: 100%;

  ${Wrapper} {
    height: 100%;
    max-width: 60rem;
    padding: 5.6rem 2rem;
    width: 100%;

    ${Header} {
      margin: 0 auto 6.8rem;
      ${Logotype} {
        height: 4.5rem;
      }
    }
    ${Main} {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  ${Footer} {
    ${theme('--font-small')}
    display: flex;
    justify-content: space-around;
    max-width: 60rem;
    padding: 0 5.5rem 2.4rem;
    width: 100%;

    ${Link} {
      ${theme('--font-opacity-60')}
    }
  }

  @media (${theme('--screen-medium')}) {
    align-items: center;
    background: linear-gradient(32.99deg, #02c8fa -15%, #00a5f7 79.88%);
    background-size: cover;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;

    ${Wrapper} {
      background: ${theme('--color-light')};
      border-radius: 0.6rem;
      box-shadow: 0px 8px 16px rgba(0, 15, 25, 0.05),
        0px 1px 0px rgba(0, 15, 25, 0.05);
      display: flex;
      flex-direction: column;
      height: auto;
      justify-content: center;
      max-width: 60rem;
      padding: 5.8rem 10.4rem 8rem;

      ${Header} {
        margin: 0 auto 4.8rem;
      }
    }

    ${Footer} {
      bottom: 4.8rem;
      padding: 0;
      position: fixed;
      width: 26.5rem;

      ${Link} {
        color: ${theme('--color-light')};
      }
    }
  }
`;
