import React, { useState } from 'react';
import styled, { theme, css } from '@styled-components';
import PropTypes from '@prop-types';
import { FormattedMessage } from '@react-intl';

import { File } from 'Components/Form';

const Video = styled('video')``;

const Preview = styled('div')`
  ${props =>
    props.image &&
    css`
      background-image: url(${props.image});
    `}
`;

const Wrapper = styled('section')``;
const Title = styled('h4')``;
const Instructions = styled('p')``;

const MediaUploader = ({ className, image }) => {
  const [preview, setPreview] = useState(null);
  const [video, setVideo] = useState(null);
  let imageUrl = preview;
  if (preview) {
    imageUrl = preview;
  } else if (image) {
    imageUrl = image;
  }

  const handleChange = file => {
    const fileReader = new FileReader();

    if (file.type.match('image')) {
      setVideo(null);
      fileReader.onload = () => setPreview(fileReader.result);
    } else {
      setPreview(null);
      fileReader.onload = () => setVideo(fileReader.result);
    }
    fileReader.readAsDataURL(file);
  };

  return (
    <article className={className}>
      <Preview image={imageUrl}>
        {video && <Video src={video} width="144" />}
      </Preview>
      <Wrapper>
        <FormattedMessage capitalize component={Title} id="SELECT_UGC" />
        <FormattedMessage
          capitalize
          component={Instructions}
          id="INSTRUCTIONS.FILE_FORMAT"
        />

        <File name="file" onChange={handleChange} />
      </Wrapper>
    </article>
  );
};

MediaUploader.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string,
};

export default styled(MediaUploader)`
  display: grid;
  grid-gap: 3.2rem;
  grid-template: 10rem / 10rem auto;
  height: 18rem;
  padding: 1.5rem 0;

  ${Preview} {
    background-color: ${theme('--color-primary-10')};
    background-size: cover;
    border: 0.2rem solid ${theme('--color-light')};
    box-shadow: 0 0.2rem 0.8rem rgba(0, 15, 25, 0.05);
    overflow: hidden;
  }

  ${Wrapper} {
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${Title} {
      ${theme('--font-medium')}
      ${theme('--font-weight-medium')}
      margin-bottom: 2rem;
    }

    ${Instructions} {
      ${theme('--font-small')}
      ${theme('--font-weight-book')}
      color: ${theme('--color-dark-night-60')};
      margin-bottom: 0.8rem;
    }

    ${File}{
      ${theme('--font-medium')}
      ${theme('--font-weight-medium')}
      background: ${theme('--color-light')};
      border: 0.1rem solid ${theme('--color-primary')};
      border-radius: 0.6rem;
      color: ${theme('--color-primary')};
      cursor: pointer;
      padding: 1.2rem;
      text-align: center;
      width: 14.4rem;
    }

   
  }

  @media (${theme('--screen-small')}) {
    grid-template: 14.4rem / 14.4rem auto;
  }
`;
