import React from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedNumber } from '@react-intl';

import Placeholder from 'Containers/Placeholder';

import Card from 'Components/Card';
import Summary from 'Components/Summary';

const Desktop = styled('article')``;
const Mobile = styled('article')``;

const Datum = styled('div')``;
const Label = styled(FormattedMessage).attrs({ capitalize: true })``;
const Value = styled(Placeholder).attrs({ placeholder: '4000' })``;

const DashboardRevenues = ({
  className,
  currency,
  revenues: {
    total_sales: totalSales = 0,
    itemCount,
    average_order: averageOrder = 0,
  } = {},
}) => (
  <div className={className}>
    <Desktop>
      <Summary
        highlight
        currency={currency}
        heading={totalSales}
        placeholders={{ heading: '$4K' }}
        title="TOTAL_SALES"
      />
      <Summary heading={itemCount} title="$ITEMS_SOLD" />
      <Summary
        currency={currency}
        heading={averageOrder}
        title="$AVERAGE_ITEM"
      />
    </Desktop>
    <Mobile>
      <Card>
        <Datum>
          <Label id="TOTAL_SALES">Total sales</Label>
          <FormattedNumber
            component={Value}
            currency={currency}
            maximumFractionDigits={2}
            minimumFractionDigits={2}
            style="currency" //eslint-disable-line
            value={totalSales}
          />
        </Datum>
        <Datum>
          <Label id="$ITEMS_SOLD">Items sold</Label>
          <Value>{itemCount}</Value>
        </Datum>
        <Datum>
          <Label id="$AVERAGE_ITEM">Average items</Label>
          <FormattedNumber
            component={Value}
            currency={currency}
            maximumFractionDigits={0}
            minimumFractionDigits={0}
            style="currency" //eslint-disable-line
            value={averageOrder}
          />
        </Datum>
      </Card>
    </Mobile>
  </div>
);

DashboardRevenues.propTypes = {
  className: PropTypes.string,
  currency: PropTypes.string,
  revenues: PropTypes.shape({
    average_order: PropTypes.number,
    itemCount: PropTypes.number,
    total_sales: PropTypes.number,
  }),
};

export default styled(DashboardRevenues)`
  ${Desktop}, ${Mobile} {
    display: grid;
    grid-gap: 3.2rem;
    grid-template: auto / repeat(4, 1fr);
  }
  ${Desktop} {
    display: none;
    grid-template: auto / repeat(4, 1fr);

    & ${Summary} {
      grid-column: span 1;
    }
  }

  ${Mobile}{
    grid-template: auto / auto;

    ${Card} {
      grid-column: span 1;
      grid-row: span 1;

      padding: 2.8rem 2.4rem 2.1rem;

      ${Datum}{
        display: flex;
        justify-content: space-between;
        padding-bottom: 1.6rem;

        &:last-child{
          padding-bottom: 0;
        }

        ${Label} {
          ${theme('--font-large')}
          color: ${theme('--color-dark-night-60')};
        }

        ${Value}{
          ${theme('--font-large')}
          ${theme('--font-weight-demi')}
          color: ${theme('--color-dark-night')};
          flex-basis: 0;
          flex-grow: 0;
        }
      }
    }
  }

  @media (${theme('--column-12')}) {
    ${Desktop} {
      display: grid;
    }
    ${Mobile} {
      display: none;
    }
  }
`;
