import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate } from '@react-intl';
import styled, { theme } from '@styled-components';

import Currency from 'Components/Currency';

import { Row } from 'Components/ResponsiveTable';
import Placeholder from 'Containers/Placeholder';

const Label = styled(Placeholder)``;
const DateLabel = styled(Label)``;

const Template = ({ className, currency, date, empty, sales }) => (
  <Row className={className} empty={empty}>
    <FormattedDate
      component={DateLabel}
      day="2-digit"
      month="long"
      placeholder="revenues period"
      value={date}
      year="numeric"
    />
    <Label placeholder="period revenues">
      <Currency currency={currency}>{sales}</Currency>
    </Label>
  </Row>
);

Template.propTypes = {
  className: PropTypes.string,
  currency: PropTypes.string,
  date: PropTypes.string,
  empty: PropTypes.bool,
  sales: PropTypes.number,
};

export default styled(Template)`
  grid-template-columns: 2fr 1fr;
  height: 5.6rem;
  padding: 0 2.4rem;

  ${Label} {
    ${theme('--font-medium')}
    ${theme('--font-opacity-60')}
    ${theme('--font-weight-book')}
    align-items: center;
    display: flex;
  }

  ${DateLabel} {
    ${theme('--font-opacity-100')}
    ${theme('--font-weight-medium')}
  }
`;
