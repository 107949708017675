import { connect } from 'react-redux';
import { withStyles } from '@styled-components';
import { compose } from 'underscore';

import SignupForm from 'Containers/SignupForm';

import { currentAccount } from 'Selectors';

const mapStateToProps = state => ({
  account: currentAccount(state),
});

export default compose(connect(mapStateToProps), withStyles``)(SignupForm);
