import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { currentAccount } from 'Selectors';

import DataProvider from 'Containers/DataProvider';

import service from 'Services/surveys';

import Modal from './Modal';

const mapState = state => ({ account: currentAccount(state) });

const Answers = ({
  account,
  dataKey,
  depth,
  in: modalIn,
  onClose,
  onBack,
  order,
  id,
}) => {
  const [surveyId, customerId] = id.split('-');

  return (
    <DataProvider
      dataKey={dataKey}
      depth={depth}
      modalIn={modalIn}
      onBack={onBack}
      onClose={onClose}
      order={order}
      params={{ account, customerId, surveyId }}
      query={service.getCustomerAnswers}
      template={Modal}
    />
  );
};

Answers.defaultProps = {
  onClose() {},
};

Answers.propTypes = {
  account: PropTypes.number,
  dataKey: PropTypes.string,
  depth: PropTypes.number,
  id: PropTypes.string,
  in: PropTypes.bool,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  order: PropTypes.number,
};

export default connect(mapState)(Answers);
