import { connect } from 'react-redux';
import { withStyles } from '@styled-components';
import { compose } from 'underscore';

import { currentAccount } from 'Selectors';

import MicroappMediaForm from 'Containers/MicroappMediaForm';

const mapStateToProps = state => ({
  account: currentAccount(state),
});

export default compose(
  connect(mapStateToProps),
  withStyles`
    margin: 0 auto;
  `,
)(MicroappMediaForm);
