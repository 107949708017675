import { combineReducers } from 'redux';

import { logout } from 'Actions/session';

import accounts from './accounts';
import logger from './logger';
import member from './member';
import session from './session';
import settings from './settings';

const appReducer = combineReducers({
  accounts,
  logger,
  member,
  session,
  settings,
});

export default (state, action) => {
  return appReducer(
    action.type !== logout.success.getType() ? state : undefined,
    action,
  );
};
