import React from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';
import { FormattedMessage } from '@react-intl';

import Bubble, { Arrow } from 'Components/Tip/Bubble';

const TooltipRow = ({
  askedCount = 0,
  askedUsersPercentage = 0,
  className,
  visible,
}) => (
  <div className={className}>
    <Arrow data-anchor="right-bottom" data-visible={visible} />
    <Bubble anchor="right-bottom" data-visible={visible}>
      <p>
        <FormattedMessage
          capitalize
          id="ASKED_TIMES"
          values={{ count: askedCount }}
        />
      </p>
      <p>
        <FormattedMessage
          capitalize
          id="USERS_ASKED_THIS_QUESTION"
          values={{ percents: askedUsersPercentage }}
        />
      </p>
    </Bubble>
  </div>
);

TooltipRow.propTypes = {
  askedCount: PropTypes.number,
  askedUsersPercentage: PropTypes.number,
  className: PropTypes.string,
  visible: PropTypes.bool,
};

export default styled(TooltipRow)`
  left: ${({ visible }) => (visible ? '-29rem' : '-200rem')};
  position: absolute;
  top: 0;

  ${Arrow} {
    left: unset;
    position: absolute;
    right: -1.6rem;
  }

  ${Bubble} {
    color: #ffffff;
    position: initial;
    width: 27rem;
  }
`;
