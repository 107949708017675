import request from 'Providers/request';
import {
  gender as genderSchema,
  location as locationSchema,
  platform as platformSchema,
  usersByOS as usersByOSSchema,
  visitsByTime as visitsByTimeSchema,
  usersByCountry as usersByCountrySchema,
} from 'Schemas';

async function getConversionRates({ account, from, to, unit }) {
  const eventsData = await request.read('/analytics/conversion_rate', {
    from,
    ofaId: account,
    to,
    unit,
  });

  return eventsData;
}

function getConversionRatesStats({ account, from, to }) {
  return request.read(
    '/analytics/conversion_rate/stats',
    {
      from,
      ofaId: account,
      to,
    },
    {},
  );
}

async function getGender({ account }) {
  const { data: gender } = await request.read(
    '/analytics/gender',
    { ofaId: account },
    genderSchema,
  );

  return gender.reduce(
    (result, item) => ({
      ...result,
      [item.value]: Number(item.count),
    }),
    {},
  );
}

async function getLocation({ account }) {
  const { data: location } = await request.read(
    '/analytics/locale',
    { ofaId: account },
    locationSchema,
  );
  return location.reduce(
    (result, item) => ({
      ...result,
      [item.value]: Number(item.count),
    }),
    {},
  );
}

async function getPaymentProduct({ account }) {
  const { data: payments } = await request.read(
    '/analytics/payment_product',
    { ofaId: account },
    locationSchema,
  );
  return payments.reduce(
    (result, item) => ({
      ...result,
      [item.value]: Number(item.count),
    }),
    {},
  );
}

async function getUserPlatform({ account }) {
  const { data: platform } = await request.read(
    '/analytics/platform',
    { ofaId: account },
    platformSchema,
  );
  return platform.reduce(
    (result, item) => ({
      ...result,
      [item.value]: Number(item.count),
    }),
    {},
  );
}

async function getUsersByOS({ account }) {
  const { devices } = await request.read(
    '/analytics/device_stats',
    { ofaId: account },
    usersByOSSchema,
  );
  return devices.reduce(
    (result, item) => ({
      ...result,
      [item.value]: Number(item.count),
    }),
    {},
  );
}

async function getVisitsByTime({ account, currency, from, to, unit }) {
  const { visits, total } = await request.read(
    `/analytics/visits`,
    {
      currency,
      from,
      ofaId: account,
      to,
      unit,
    },
    visitsByTimeSchema,
  );

  return {
    total,
    visits: Object.keys(visits)
      .reverse()
      .reduce(
        (result, key) => ({
          ...result,
          [key]: visits[key],
        }),
        {},
      ),
  };
}

async function getUsersByCountry({ account }) {
  const { countries } = await request.read(
    `/analytics/country`,
    { ofaId: account },
    usersByCountrySchema,
  );

  return countries;
}

export default {
  getConversionRates,
  getConversionRatesStats,
  getGender,
  getLocation,
  getPaymentProduct,
  getUserPlatform,
  getUsersByCountry,
  getUsersByOS,
  getVisitsByTime,
};
