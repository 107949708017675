import React from 'react';
import PropTypes from '@prop-types';
import styled, { theme, withStyles as css } from '@styled-components';
import { FormattedMessage } from '@react-intl';

import { compose } from 'redux';

import withTransition from '../with/transition';

const STATUS = {
  error: theme('--color-alert-20'),
  info: theme('--color-primary-10'),
  success: theme('--color-success-20'),
};

const TYPES = {
  error: 'ERRORS',
  info: 'INFO',
  success: 'SUCCESS',
};

const Content = styled('p')``;

const Snack = ({ className, children, type = 'success' }) => (
  <article className={className}>
    <FormattedMessage
      capitalize
      component={Content}
      id={`${TYPES[type]}.${children}`}
    >{`${TYPES[type]}.${children}`}</FormattedMessage>
  </article>
);

Snack.propTypes = {
  children: PropTypes.component,
  className: PropTypes.string,
  type: PropTypes.string,
};

export default compose(
  css`
    align-items: center;
    background-color: ${props => STATUS[props.type]};
    border-radius: 0.6rem;
    display: flex;
    height: 4.8rem;
    min-width: 31.2rem;
    padding: 1.6rem 2.4rem;

    ${Content} {
      ${theme('--font-medium')}
      ${theme('--font-weight-book')}
      color: ${theme('--color-dark-night-60')};
    }
  `,
  withTransition(),
)(Snack);
