import { css, keyframes } from '@styled-components';

const fade = {
  in: keyframes`
    from {
      opacity: 0;
    }
  `,
  out: keyframes`
    to {
      opacity: 0;
    }
  `,
};

const slide = {
  in: keyframes`
    from {
      opacity: 0;
      transform: translateX(100%);
    }
  `,
  out: keyframes`
    to {
      opacity: 0;
      transform: translateX(100%);
    }
  `,
};

const zoom = {
  in: keyframes`
    from {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }
  `,
  out: keyframes`
    50% {
      opacity: 0;
    }
    
    to {
      opacity: 0;
    }
  `,
};

export const small = (Background, Wrapper) => {
  return css`
    &.entering {
      ${Background} {
        animation: ${fade.in} 300ms ease-out;
      }

      ${Wrapper} {
        animation: ${slide.in} 300ms ease-out;
      }
    }

    &.exited {
      ${Background} {
        opacity: 0;
      }

      ${Wrapper} {
        transform: translateX(100%);
      }
    }

    &.exiting {
      ${Background} {
        animation: ${fade.out} 300ms ease-out;
      }

      ${Wrapper} {
        animation: ${slide.out} 300ms ease-out;
      }
    }
  `;
};

export const medium = (Background, Wrapper) => {
  return css`
    &.entering {
      ${Background} {
        animation: ${fade.in} 300ms ease-out;
      }

      ${Wrapper} {
        animation: ${zoom.in} 300ms ease-out;
      }

      & + * {
        animation: none;
      }
    }

    &.exited {
      ${Background} {
        opacity: 0;
      }

      ${Wrapper} {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
      }
    }

    &.exiting {
      ${Background} {
        animation: ${fade.out} 300ms ease-in;
      }

      ${Wrapper} {
        animation: ${zoom.out} 300ms ease-in;
      }

      & + * {
        animation: none;
      }
    }
  `;
};

export default { medium, small };
