import React, { useState } from 'react';
import PropTypes from '@prop-types';
import styled, { theme, withStyles as css } from '@styled-components';
import { compose } from 'underscore';

import Placeholder from 'Containers/Placeholder';

import { Row } from 'Components/ResponsiveTable';
import IconButton from 'Components/IconButton';
import TagList from 'Components/TagList';

import TooltipRow from './TooltipRow';

const Field = styled(Placeholder.div)``;
const QuestionField = styled(Field)``;
const AnswerTypeField = styled(Field)``;
const Label = styled(Placeholder)``;
const TagLabel = styled(Label)``;
const AnswerLabel = styled(Label)``;
const ViewMore = styled('span')``;

const DesktopTemplate = ({
  answer = {},
  askedCount,
  askedUsersPercentage,
  className,
  empty,
  id,
  questions = [],
  score,
  role,
  tags = [],
  type,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const isSuperAdmin = role === 'SUPER_ADMIN';

  const { content, description, type: aType } = answer;
  const questionList = questions.slice(0, 3);

  const onMouseOver = () => setIsHovered(true);

  const onMouseOut = () => setIsHovered(false);

  return (
    <Row
      className={className}
      empty={empty}
      onBlur={onMouseOut}
      onFocus={onMouseOver}
      onMouseOut={onMouseOut}
      onMouseOver={onMouseOver}
      to={`/builder/faqstudio/${type}_${id}`}
    >
      <QuestionField placeholder="placeholder">
        {questionList.map(({ text }) => (
          <Label placeholder="placeholder">{text}</Label>
        ))}
        {questions && questions.length > 3 && (
          <ViewMore>{`${questions.length - 3} more`}</ViewMore>
        )}
      </QuestionField>
      <AnswerTypeField>
        {aType === 'TEXT' ? (
          <Label
            capitalize
            component={Label}
            id="RESPONSE_TEXT"
            placeholder="placeholder"
          />
        ) : (
          <Label
            capitalize
            component={Label}
            id="RESPONSE_CUSTOM"
            placeholder="placeholder"
          />
        )}
      </AnswerTypeField>
      <AnswerLabel fill placeholder="placeholder">
        {aType === 'CMD' ? description : content && `"${content}"`}
      </AnswerLabel>
      {isSuperAdmin && <Label placeholder="0.0">{score}</Label>}
      <TagLabel fill placeholder="placeholder">
        <TagList tags={tags} />
      </TagLabel>

      <TooltipRow
        askedCount={askedCount}
        askedUsersPercentage={askedUsersPercentage}
        visible={isHovered}
      />
    </Row>
  );
};

DesktopTemplate.propTypes = {
  answer: PropTypes.object,
  askedCount: PropTypes.number,
  askedUsersPercentage: PropTypes.number,
  className: PropTypes.string,
  empty: PropTypes.bool,
  id: PropTypes.string,
  questions: PropTypes.array,
  role: PropTypes.string,
  score: PropTypes.number,
  tags: PropTypes.array,
  type: PropTypes.string,
};

export default compose(
  css`
    align-items: flex-start;
    grid-template-columns: ${({ role }) =>
      role === 'SUPER_ADMIN'
        ? '2fr 11rem 3fr 5.8rem 20.2rem'
        : '2fr 11rem 3fr 20.2rem'};
    min-height: 7.2rem;
    text-align: left;

    ${Field} {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;

      ${ViewMore} {
        ${theme('--font-small')}
        ${theme('--font-weight-book')}
        ${theme('--font-opacity-60')}
        display: block;
        padding-bottom: 1.2rem;
      }
    }

    ${Label} {
      ${theme('--font-medium')}
      ${theme('--font-weight-book')}
      ${theme('--font-opacity-80')}
      align-items: center;
      display: flex;
      height: 100%;
      min-height: 5.6rem;
      padding: 1.2rem 0.8rem 1.2rem 0;
      text-overflow: ellipsis;
      word-break: break-word;
    }

    ${TagLabel} {
      align-items: flex-start;
      height: 100%;
    }

    ${IconButton} {
      margin-top: 0.4rem;
    }

    ${TagList} {
      width: 100%;
    }
  `,
)(DesktopTemplate);
