import React from 'react';
import PropTypes from '@prop-types';
import styled, { theme } from '@styled-components';
import View from 'Components/View';
import ViewHeadline from 'Components/ViewHeadline';

import Template from './Surveys';

const Surveys = ({ className }) => (
  <View className={className}>
    <ViewHeadline heading="SURVEYS_SUBHEADING" title="SURVEYS_HEADING" />
    <Template />
  </View>
);
Surveys.propTypes = {
  className: PropTypes.string,
};

export default styled(Surveys)`
  @media (${theme('--column-8')}) {
    ${ViewHeadline}, ${Template} {
      grid-column: span 8;
    }
  }

  @media (${theme('--column-12')}) {
    ${ViewHeadline}, ${Template} {
      grid-column: span 12;
    }
  }
`;
