import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles as css } from '@styled-components';

import Card from 'Components/Card';
import LineChartLoader from 'Components/LineChartLoader';

import DataProvider from 'Containers/DataProvider';

import { getUnit } from 'Modules/dates';

import { currentAccount } from 'Selectors';

import service from 'Services/audience';

import View from './ConversionRatesChart';

const mapStateToProps = state => ({
  account: currentAccount(state),
});

const ConversionRatesChart = ({
  account,
  className,
  from = moment()
    .subtract(1, 'year')
    .format(),
  to = moment().format(),
}) => {
  const unit = getUnit(from, to);
  return (
    <DataProvider
      className={className}
      from={from}
      loader={() => (
        <Card className={className} title="CONVERSION_RATES">
          <LineChartLoader />
        </Card>
      )}
      params={{
        account,
        from,
        to,
        unit,
      }}
      query={service.getConversionRates}
      template={View}
      to={to}
      unit={unit}
    />
  );
};

ConversionRatesChart.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
  from: PropTypes.string,
  to: PropTypes.string,
};

export default compose(connect(mapStateToProps), css``)(ConversionRatesChart);
