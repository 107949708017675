import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@styled-components';
import { compose } from 'underscore';
import { currentAccount } from 'Selectors';

import CreateMicroapp from 'Containers/CreateMicroapp';

import Form from './SignupForm';

const mapStateToProps = state => ({
  account: currentAccount(state),
});

const AddMicroappMedia = ({
  className,
  data: defaultValue = {},
  modalTitle = 'NEW_TEMPLATE',
  ...props
}) => {
  return (
    <CreateMicroapp
      className={className}
      {...props}
      defaultValue={defaultValue}
      modalTitle={modalTitle}
      template={Form}
      type="SIGNUP"
    />
  );
};

AddMicroappMedia.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  modalTitle: PropTypes.string,
};

export default compose(
  connect(mapStateToProps),
  withStyles``,
)(AddMicroappMedia);
