import React, { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedCurrency } from '@react-intl';

const Price = ({
  component: Wrapper = 'span',
  currency,
  forwardedRef,
  value,
  ...props
}) => {
  if (currency === 'IDR' || currency === 'Rp' || currency === 'RP') {
    return (
      <Wrapper {...props} ref={forwardedRef}>
        Rp{`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
      </Wrapper>
    );
  }
  return <FormattedCurrency currency={currency} value={value} />;
};

Price.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.string,
  ]),
  currency: PropTypes.string,
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  value: PropTypes.number,
};

export default memo(
  // eslint-disable-next-line react/display-name
  forwardRef((props, forwardedRef) => (
    <Price {...props} forwardedRef={forwardedRef} />
  )),
);
