import React from 'react';
import PropTypes from '@prop-types';
import styled, { theme } from '@styled-components';

import { Row } from 'Components/ResponsiveTable';
import { PlaceholderNoData as Placeholder } from 'Containers/Placeholder';

const Label = styled(Placeholder)`
  color: ${props =>
    props.alert
      ? props.theme['--color-alert']
      : props.theme['--color-dark-night-40']};
`;
const NameLabel = styled(Label)``;
const Description = styled('div')``;
const Pic = styled(Placeholder.img).attrs({
  forwardProps: ['src'],
})``;

const DesktopTemplate = ({
  className,
  color: { name: colorName } = {},
  empty,
  id,
  images = [],
  name,
  onClick: handleClick = () => () => {},
  price,
  size,
}) => (
  <Row className={className} empty={empty} onClick={handleClick(id)}>
    <Description>
      <Pic
        key={images[0]}
        fill
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
      />
      <NameLabel placeholder="variant name">{name}</NameLabel>
      <Label placeholder="color name">{colorName}</Label>
    </Description>
    <Label placeholder="variant id">{id}</Label>
    <Label placeholder="variant size">{size}</Label>
    <Label placeholder="price">{price}</Label>
  </Row>
);

DesktopTemplate.propTypes = {
  className: PropTypes.string,
  color: PropTypes.object,
  empty: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  images: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
  onClick: PropTypes.func,
  price: PropTypes.string,
  size: PropTypes.string,
};

export default styled(DesktopTemplate)`
  grid-template-columns: 1fr 20rem 7rem 7rem;
  height: 8.8rem;

  ${Label} {
    ${theme('--font-small')}
    ${theme('--font-weight-medium')}
    align-items: center;
    display: flex;
    text-align: left;
  }

  ${NameLabel} {
    ${theme('--font-opacity-100')}
  }

  ${Description} {
    display: grid;
    flex-basis: 0;
    flex-grow: 3;
    grid-column-gap: 2rem;
    grid-row-gap: 0.8rem;
    grid-template: 1fr 1fr / 7.2rem auto;

    ${Pic} {
      background-color: ${theme('--color-dark-night-05')};
      background-image: url(${({ images }) => images && images[0]});
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border: 0.1rem solid ${theme('--color-dark-night-10')};
      border-radius: 0.6rem;
      grid-row: 1 / span 2;
      height: 7.2rem;
      width: 7.2rem;
    }

    ${Label} {
      align-items: flex-start;
    }

    ${NameLabel} {
      align-items: flex-end;
    }
  }
`;
