import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@react-intl';
import { useField } from 'spooner';
import styled, { theme } from '@styled-components';

const Circle = styled('div')``;
const Text = styled('span')``;

const Toggle = ({ className, ...props }) => {
  const {
    name,
    onBlur = () => {},
    onChange = () => {},
    onFocus = () => {},
    value = 'off',
    ...fieldProps
  } = useField(props);

  const handleBlur = () => {
    onBlur();
  };

  const handleChange = event => {
    event.stopPropagation();

    onChange(value === 'on' ? 'off' : 'on');
  };

  const handleFocus = () => {
    onFocus();
  };

  return (
    <button
      className={className}
      {...fieldProps}
      data-name={name}
      data-value={value}
      onBlur={handleBlur}
      onClick={handleChange}
      onFocus={handleFocus}
      type="button"
    >
      <FormattedMessage uppercase component={Text} id="ON" />
      <FormattedMessage uppercase component={Text} id="OFF" />
      <Circle />
    </button>
  );
};

Toggle.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOf(['off', 'on']),
};

export default styled(Toggle)`
  align-items: center;
  background-color: #f2f3f4;
  border: 0;
  border-radius: 2rem;
  cursor: pointer;
  display: inline-flex;
  height: 2.4rem;
  justify-content: space-between;
  padding: 0.4rem;
  position: relative;
  transition: background-color ease-out 300ms;
  width: 6rem;

  &:focus {
    outline: none;
  }

  &[data-value='on'] {
    background-color: ${theme('--color-success')};

    ${Text} {
      &:first-of-type {
        opacity: 1;
      }

      &:last-of-type {
        opacity: 0;
      }
    }

    ${Circle} {
      transform: translateX(3.6rem);
    }
  }

  ${Text} {
    ${theme('--font-small')}
    margin-top: 0.2rem;
    transition: opacity 250ms ease-out;

    &:first-of-type {
      color: ${theme('--color-light')};
      margin-left: 0.4rem;
      opacity: 0;
    }

    &:last-of-type {
      color: ${theme('--color-dark-night-40')};
      margin-right: 0.4rem;
    }
  }

  ${Circle} {
    background: ${theme('--color-light')};
    border-radius: 50%;
    height: 1.6rem;
    left: 0.4rem;
    position: absolute;
    top: 0.4rem;
    transition: transform 350ms ease-out;
    width: 1.6rem;
  }
`;
