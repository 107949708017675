import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { withStyles } from '@styled-components';

import * as logger from 'Actions/logger';

import Modal from 'Components/Modal';

import DataProvider from 'Containers/DataProvider';

import { currency as currentCurrency, currentAccount } from 'Selectors';

import service from 'Services/customers';

import View from './Customer';
import Toolbar from './components/Toolbar';

const mapStateToProps = state => ({
  account: currentAccount(state),
  currency: currentCurrency(state),
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({ success: logger.success }, dispatch);

const Customer = ({
  account,
  currency,
  dataKey,
  order,
  depth,
  id,
  in: modalIn,
  onClose,
  onBack,
}) => (
  <Modal
    depth={depth}
    in={modalIn}
    onBack={onBack}
    onClose={onClose}
    order={order}
    title="CUSTOMER"
    toolbar={() => <Toolbar id={id} />}
  >
    <DataProvider
      key={dataKey}
      emptyTemplate={() => <Redirect to="/oops" />}
      params={{ account, currency, id }}
      query={service.findOne}
      template={View}
    />
  </Modal>
);

Customer.propTypes = {
  account: PropTypes.number,
  currency: PropTypes.string,
  dataKey: PropTypes.string,
  depth: PropTypes.number,
  id: PropTypes.any.isRequired,
  in: PropTypes.bool,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  order: PropTypes.number,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles``,
)(Customer);
