import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles as css } from '@styled-components';

import Card from 'Components/Card';
import DataProvider from 'Containers/DataProvider';

import { currentAccount } from 'Selectors';

import service from 'Services/customers';

import View from './Timeline';

const mapStateToProps = state => ({
  account: currentAccount(state),
});

class Timeline extends Component {
  static propTypes = {
    account: PropTypes.number,
    className: PropTypes.string,
    id: PropTypes.number,
  };

  state = { limit: 25, offset: 0 };

  handleScrollEnd = () => {
    this.setState(prevState => ({
      limit: prevState.offset + prevState.limit + 25,
    }));
  };

  render() {
    const { className, account, id, ...props } = this.props;
    const { limit, offset } = this.state;

    return (
      <Card className={className} title="TIMELINE">
        <DataProvider
          emptyVerifier={({ events }) => !events || events.length === 0}
          onScrollEnd={this.handleScrollEnd}
          params={{ account, id, limit, offset }}
          query={service.timeline}
          template={View}
          {...props}
        />
      </Card>
    );
  }
}

export default compose(
  connect(mapStateToProps),
  css`
    height: 42rem;
    padding-top: 6.4rem;
  `,
)(Timeline);
