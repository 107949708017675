import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isLoggedIn } from 'Selectors';

import { login } from 'Actions/session';

import Login from './Login';

const mapDispatchToProps = dispatch => bindActionCreators({ login }, dispatch);

const mapStateToProps = state => ({
  isLoggedIn: isLoggedIn(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
