import React from 'react';
import PropTypes from '@prop-types';
import styled, { theme, withStyles as css } from '@styled-components';
import Icon from 'Components/Icon';
import {
  intlDefault,
  intlShape,
  FormattedMessage,
  withIntl,
} from '@react-intl';
import { compose } from 'underscore';

import Link from 'Components/Link';
import Card from 'Components/Card';
import View from 'Components/View';
import ViewHeadline from 'Components/ViewHeadline';

import Filters from './Filters';

const Title = styled('h2')``;
const Content = styled('p')``;
const Actions = styled('nav')``;
const Wrapper = styled('div')``;

const Button = styled(Link)``;

const Header = styled('div')``;

const EmptyTemplate = ({
  className,
  filterBy,
  intl = intlDefault,
  onFilterChange: handleFilterChange,
}) => (
  <View className={className}>
    <Header>
      <ViewHeadline
        heading="INTEGRATIONS_DESCRIPTION"
        title="INTEGRATIONS_TITLE"
      />
      <Filters onChange={handleFilterChange} value={filterBy} />
    </Header>
    <Card>
      <Wrapper>
        <Icon name="integrations-empty">INTEGRATIONS_EMPTY</Icon>
        {filterBy === 'ALL' ? (
          <FormattedMessage
            capitalize
            component={Title}
            id="EMPTY_INTEGRATIONS"
          >
            Oops! we did not find any integration
          </FormattedMessage>
        ) : (
          <FormattedMessage
            capitalize
            component={Title}
            id="%CATEGORY%EMPTY_INTEGRATIONS"
            values={{
              category: intl.formatMessage({ capitalize: true, id: filterBy }),
            }}
          >
            {`Oops! we did not find any integration for ${intl.formatMessage({
              capitalize: true,
              id: filterBy,
            })}`}
          </FormattedMessage>
        )}
      </Wrapper>
    </Card>
  </View>
);

EmptyTemplate.defaultProps = {
  intl: intlShape,
  onFilterChange() {},
};

EmptyTemplate.propTypes = {
  className: PropTypes.string,
  filterBy: PropTypes.string,
  intl: intlShape,
  onFilterChange: PropTypes.func,
};

export default compose(
  css`
    ${ViewHeadline}, ${Card} {
      grid-column: span 4;
    }

    ${Header} {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      ${Filters} {
        display: none;
      }
    }

    ${Card} {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: calc(100vh - 22rem);
      justify-content: center;
      overflow-y: scroll;

      ${Wrapper} {
        display: grid;
        grid-gap: 0.4rem;
        grid-template-rows: minmax(0, calc(100% - 2.4rem)) 2.4rem;
        height: calc(100% - 2.4rem);
        max-height: 30rem;
        text-align: center;

        ${Icon} {
          height: 100%;
          margin: 0 auto;
        }

        ${Title} {
          ${theme('--font-large')}
          ${theme('--font-weight-book')}
          ${theme('--font-opacity-100')}
        }

        ${Content} {
          ${theme('--font-medium')}
          ${theme('--font-weight-book')}
          ${theme('--font-opacity-40')}
        }

        ${Actions} {
          display: flex;
          justify-content: center;

          ${Button} {
            ${theme('--font-medium')}
            ${theme('--font-weight-demi')}
            align-items: center;
            background-color: ${theme('--color-primary')};
            border-radius: 0.6rem;
            color: ${theme('--color-light')};
            display: flex;
            height: 4rem;
            justify-content: center;
            margin: 0 0.8rem;
            width: 12.4rem;
          }
        }
      }
    }

    @media (${theme('--column-8')}) {
      ${ViewHeadline}, ${Card} {
        grid-column: span 8;
      }
      ${Header} {
        grid-column: span 8;

        ${Filters} {
          display: inline-flex;
        }
      }
    }

    @media (${theme('--column-12')}) {
      ${ViewHeadline}, ${Card} {
        grid-column: span 12;
      }

      ${Header} {
        grid-column: span 12;
      }
    }
  `,
  withIntl,
)(EmptyTemplate);
