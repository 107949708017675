import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';
import _ from 'underscore';

import Toolbar from 'Containers/CardToolbar';
import TableDataProvider from 'Containers/TableDataProvider';

import { AutoSuggest, Input, Select } from 'Components/Form';

import Card from 'Components/Card';

import service from 'Services/customers';

import CustomerContextMenu from './components/Options';
import Table from './components/Table';

const platforms = [
  { name: 'All', value: '' },
  { name: 'Facebook Messenger', value: 'FB_MESSENGER' },
  { name: 'Instagram', value: 'INSTAGRAM' },
  { name: 'Facebook', value: 'FACEBOOK' },
  { name: 'Line', value: 'LINE' },
  { name: 'Linkedin', value: 'LINKEDIN' },
  { name: 'Twitter', value: 'TWITTER' },
];

const SearchInput = styled(Input).attrs({ name: 'search' })``;

class CustomersTable extends Component {
  static propTypes = {
    account: PropTypes.number,
    className: PropTypes.string,
    data: PropTypes.shape({
      ofaGroups: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
        }),
      ),
    }),
    from: PropTypes.string,
    to: PropTypes.string,
  };

  state = {
    filterBy: '',
    order: null,
    searchBy: '',
    sortBy: null,
  };

  onOrderBy = (sortBy, order) => {
    this.setState({ order, sortBy });
  };

  onGroupBy = _.debounce(groups => {
    this.setState({ groupBy: groups });
  }, 250);

  onSearch = _.debounce(text => {
    this.setState({
      searchBy: text || null,
    });
  }, 250);

  onSelectPlatform = _.debounce(filterBy => {
    this.setState({ filterBy });
  }, 100);

  render() {
    const {
      account,
      className,
      data: { ofaGroups } = {},
      from,
      to,
    } = this.props;
    const { groupBy = [], order, sortBy, searchBy, filterBy } = this.state;

    const groupFilter = groupBy.map(({ name }) => name).join(',');

    let filterAmmount = 0;
    if (filterBy) filterAmmount += 1;
    if (searchBy) filterAmmount += 1;
    if (groupFilter.length !== 0) filterAmmount += 1;

    return (
      <Card className={className}>
        <Toolbar
          contextMenu={CustomerContextMenu}
          filterAmmount={filterAmmount}
          title="LIST_OF_CUSTOMERS"
        >
          <SearchInput
            capitalize
            label="SEARCH"
            onChange={this.onSearch}
            type="search"
          />
          <Select
            onChange={this.onSelectPlatform}
            options={platforms}
            value={filterBy}
          />
          <AutoSuggest
            capitalize
            name="group"
            onChange={this.onGroupBy}
            options={ofaGroups}
            placeholder="GROUP_BY"
          />
        </Toolbar>
        <TableDataProvider
          filterAmmount={filterAmmount}
          limit={5}
          name="customers"
          onOrderBy={this.onOrderBy}
          order={order}
          params={{
            account,
            filterBy,
            from,
            groupBy: groupFilter,
            order,
            searchBy,
            sortBy,
            to,
          }}
          query={service.find}
          sortBy={sortBy}
          template={Table}
        />
      </Card>
    );
  }
}

export default styled(CustomersTable)`
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  height: 100%;
  padding: 0;
  position: relative;

  @media (${theme('--screen-medium')}) {
    ${Input}, ${Select} {
      display: inline-block;
      margin-right: 1.6rem;
      width: 24rem;
    }

    ${AutoSuggest} {
      display: block;
      width: 24rem;
    }
  }
`;
