import React from 'react';
import styled, { theme, css } from '@styled-components';
import PropTypes from '@prop-types';

import Icon from 'Components/Icon';

const IconButton = ({
  className,
  children,
  onClick,
  name,
  outline,
  active,
  testid,
  ...props
}) => {
  return (
    <button
      className={className}
      data-active={active}
      data-testid={testid}
      onClick={onClick}
      type="button"
      {...props}
    >
      <Icon name={name} outline={outline}>
        {children}
      </Icon>
    </button>
  );
};

IconButton.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.children,
  className: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  outline: PropTypes.bool,
  testid: PropTypes.string,
};

export default styled(IconButton)`
  align-items: center;
  background: none;
  border: none;
  border-radius: 0.6rem;
  cursor: pointer;
  display: flex;
  height: 4.8rem;
  justify-content: center;
  outline: none;
  width: 4.8rem;

  ${Icon} {
    display: inline-block;
    height: 3.2rem;
    width: 3.2rem;
  }

  &:focus,
  &:hover {
    background: ${theme('--color-blueish-white')};

    ${({ fill }) =>
      fill &&
      css`
        ${Icon} * {
          fill: ${theme('--color-primary')};
        }
      `}
  }

  &[data-active='true'] {
    background: linear-gradient(
      180deg,
      rgba(0, 132, 255, 0.2) 0%,
      rgba(0, 132, 255, 0.1) 100%
    );
    ${Icon} * {
      fill: ${theme('--color-primary')};
    }
  }
`;
