import React from 'react';
import { FormattedTime, FormattedDate } from '@react-intl';
import styled, { theme } from '@styled-components';
import PropTypes from '@prop-types';

import RawAvatar from 'Components/Avatar';
import Link from 'Components/Link';
import Icon from 'Components/Icon';
import RawPlatforms from 'Components/Platforms';
import { Row } from 'Components/ResponsiveTable';

import {
  withPlaceholderNoData as withPlaceholder,
  PlaceholderNoData as Placeholder,
} from 'Containers/Placeholder';

const Platforms = styled(withPlaceholder(RawPlatforms))``;
const UserName = styled(Placeholder)``;
const Timezone = styled(Placeholder)``;
const Locale = styled(Placeholder)``;
const LastActivity = styled(Placeholder)``;

const Avatar = styled(withPlaceholder(RawAvatar))``;

const Template = ({
  className,
  empty,
  name,
  id,
  profile: { imageUrlNormal } = {},
  locale,
  platforms = [],
  timezone,
  updatedAt,
}) => (
  <Link as={Row} className={className} empty={empty} to={`transactions/${id}`}>
    <Avatar fill image={imageUrlNormal} name={name} />
    <UserName placeholder="customer name">{name}</UserName>
    {timezone && (
      <FormattedTime
        component={Timezone}
        timeZone={timezone}
        timeZoneName="short"
        value={new Date()}
      />
    )}
    <Locale placeholder="customer locale">{locale && name(locale)}</Locale>
    <Platforms fill data={platforms} />
    {updatedAt && (
      <FormattedDate
        component={LastActivity}
        placeholder="update at"
        value={updatedAt}
      />
    )}
  </Link>
);

Template.propTypes = {
  className: PropTypes.string,
  empty: PropTypes.bool,
  id: PropTypes.number,
  locale: PropTypes.string,
  name: PropTypes.string,
  platforms: PropTypes.arrayOf(PropTypes.string),
  profile: PropTypes.object,
  timezone: PropTypes.string,
  updatedAt: PropTypes.instanceOf(Date),
};
export default styled(Template)`
  align-items: center;
  cursor: pointer;
  display: grid;
  grid-column-gap: 1.6rem;
  grid-row-gap: 0.4rem;
  grid-template: 1.6rem 1.6rem auto / auto 1fr auto;
  height: 8rem;
  padding: 1.2rem 2.4rem;

  ${Avatar} {
    grid-column: 1;
    grid-row: 1 / span 3;
    height: 4rem;
    width: 4rem;
  }

  ${UserName} {
    ${theme('--font-medium')}
    ${theme('--font-weight-medium')}
    grid-column: 2;
    grid-row: 1;
  }

  ${Timezone} {
    ${theme('--font-small')}
    ${theme('--font-weight-book')}
    color: ${theme('--color-dark-night-40')};
    grid-column: 2;
    grid-row: 2;
  }

  ${Locale} {
    ${theme('--font-small')}
    ${theme('--font-weight-book')}
    color: ${theme('--color-dark-night-40')};
    grid-column: 2;
    grid-row: 3;
  }

  ${Platforms} {
    display: grid;
    grid-column: 3;
    grid-gap: 0.4rem;
    grid-row: 1 / span 2;
    grid-template-columns: repeat(3, auto);
    height: 100%;

    ${Icon} {
      height: 1.6rem;
      width: 1.6rem;
    }
  }

  ${LastActivity} {
    ${theme('--font-small')}
    ${theme('--font-weight-book')}
    color: ${theme('--color-dark-night-40')};
    grid-column: 3;
    grid-row: 3;
  }
`;
