import React, { useCallback } from 'react';
import styled, { theme, css } from '@styled-components';
import PropTypes from '@prop-types';
import { FormattedMessage } from '@react-intl';

import { Input } from 'Components/Form';

const placeholderImageURI = '/images/image-placeholder.svg';

const SectionTitle = styled('h5')``;
const Preview = styled('div')`
  background-image: url(${placeholderImageURI});
  border: 2px dashed ${theme('--color-primary')};

  ${props =>
    props.src &&
    css`
      background-image: url(${props.src});
      background-size: cover;
      border-style: solid;
    `}
`;
const Instruction = styled('p')``;
const Label = styled('label')``;

const VideoThumbnail = ({
  className,
  previewingId,
  selectedItems,
  setSelectedItems,
}) => {
  const selectedIndex = selectedItems.findIndex(
    item => item.id === previewingId,
  );
  const current = selectedItems[selectedIndex];

  const handleFileSelect = useCallback(
    event => {
      const file = event.target.files[0];
      const fileReader = new FileReader();

      fileReader.onload = () => {
        const itemsClone = [...selectedItems];
        const updatedItem = {
          ...current,
          file,
          source: fileReader.result,
        };

        itemsClone.splice(selectedIndex, 1, updatedItem);

        setSelectedItems(itemsClone);
      };

      fileReader.readAsDataURL(file);
    },
    [current, selectedIndex, selectedItems, setSelectedItems],
  );

  return (
    <div className={className}>
      <FormattedMessage
        capitalize
        component={SectionTitle}
        id="INSTRUCTIONS.SELECT_THUMBNAIL"
      />
      <FormattedMessage
        capitalize
        component={Instruction}
        id="INSTRUCTIONS.THUMBNAIL"
      />
      <Preview src={current.source} />
      <FormattedMessage
        capitalize
        component={Label}
        htmlFor="file"
        id="CHANGE_THUMBNAIL"
      />
      <Input
        accept="image/x-png,image/gif,image/jpeg"
        id="file"
        name="file"
        onFileSelect={handleFileSelect}
        type="file"
      />
    </div>
  );
};

VideoThumbnail.propTypes = {
  className: PropTypes.string,
  previewingId: PropTypes.number,
  selectedItems: PropTypes.arrayOf(
    PropTypes.shape({
      filePreview: PropTypes.string,
      id: PropTypes.number,
      source: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  setSelectedItems: PropTypes.func,
};

export default styled(VideoThumbnail)`
  grid-template: 1.6rem auto / 19.2rem 1fr;
  padding-top: 3.2rem;

  ${SectionTitle} {
    ${theme('--font-medium')}
    ${theme('--font-weight-medium')}
    color: ${theme('--color-dark-night')};
    margin-bottom: 0.4rem;
  }

  ${Instruction}{
    ${theme('--font-medium')}
    ${theme('--font-weight-book')}
    color: ${theme('--color-dark-night-60')};
    margin-bottom: 1.6rem;
  }

  ${Preview} {
    align-items: center;
    background-color: ${theme('--color-primary-10')};
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0.6rem;
    cursor: pointer;
    display: flex;
    grid-column: 1;
    grid-row: 2;
    height: 10.2rem;
    justify-content: center;
    margin-bottom: 1.6rem;
    width: 10.2rem;
  }

  ${Input} {
    display: none;
  }

  ${Label}{
    background: ${theme('--color-light')};
    border: 0.1rem solid ${theme('--color-primary')};
    border-radius: 0.6rem;
    color: ${theme('--color-primary')};
    cursor: pointer;
    display: inline-block;
    height: 4rem;
    padding: 1.2rem;
  }
`;
