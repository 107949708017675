import C from 'Constants';

import storage from 'Providers/storage';

const { CACHE } = C;

export class Cache {
  static now() {
    return Date.now();
  }

  data = {};

  constructor() {
    const data = storage.get(CACHE) || {};
    this.data = data;
  }

  get(key) {
    const data = this.data[key];
    const now = Cache.now();
    if (!data) {
      return false;
    }
    if (data.last + data.ttl < now) {
      return false;
    }
    this.data[key].last = now;
    storage.set(CACHE, this.data);

    return data.value;
  }

  clear() {
    this.data = {};
    storage.set(CACHE, this.data);
  }

  unset(key) {
    delete this.data[key];
  }

  keys() {
    return Object.keys(this.data);
  }

  set(key, value, ttl = 900000) {
    this.unset(key);
    if (ttl > 0) {
      this.data[key] = {
        last: Cache.now(),
        ttl,
        value,
      };
    }
    storage.set(CACHE, this.data);
  }
}

export default new Cache();
