import React, { useCallback, useContext, useReducer } from 'react';
import { createIsEmpty } from '@array-utils';
import PropTypes from '@prop-types';
import { v4 as uuid } from 'uuid';

import { Provider } from 'Containers/DataProvider';
import { Context } from 'Containers/MultiDataProvider';

import { useFetcher } from 'Components/Fetcher';

import { useDeepCompareEffect } from 'Hooks';

import Cache from './Cache';

export const STATE = {
  FETCH: 1,
  RESET: 2,
};

function reducer(state, action) {
  switch (action.type) {
    case STATE.RESET:
      return {
        ...state,
        id: action.id,
        length: action.length,
        offset: action.offset,
      };
    case STATE.FETCH:
      return {
        ...state,
        length: action.length,
        offset: action.offset,
      };
    default:
      return state;
  }
}

const TableDataProvider = ({
  params,
  limit = 4,
  name,
  template: Template,
  query,
  emptyVerifier = createIsEmpty('collection'),
  ...props
}) => {
  const initialOffset = 0;
  const [{ id, length, offset }, dispatch] = useReducer(reducer, {
    id: uuid(),
    length: limit,
    offset: initialOffset,
  });

  const { data, fetch, loading, ...rest } = useFetcher({
    params: {
      ...params,
      limit: length,
      offset,
    },
    query,
  });

  const empty = !loading && (data ? emptyVerifier(data) : true);
  const { onChange: handleChangeProvider } = useContext(Context);

  useDeepCompareEffect(() => {
    handleChangeProvider({
      data,
      fetch,
      loading,
      name,
      refetch: fetch,
      ...rest,
    });
  }, [data, fetch, handleChangeProvider, loading, name, rest]);

  useDeepCompareEffect(() => {
    return () => {
      handleChangeProvider({ name, unmount: true });
    };
  }, [handleChangeProvider, name]);

  useDeepCompareEffect(() => {
    dispatch({
      id: uuid(),
      length: limit,
      offset: initialOffset,
      type: STATE.RESET,
    });
  }, [dispatch, params]);

  const handleMiss = useCallback(
    ({ length: l, offset: o }) => {
      dispatch({ length: l, offset: o, type: STATE.FETCH });
    },
    [dispatch],
  );

  return (
    <Provider value={{ empty, loading, refetch: fetch }}>
      <Cache
        {...props}
        {...rest}
        data={data}
        id={id}
        length={length}
        loading={loading}
        onMiss={handleMiss}
        template={Template}
      />
    </Provider>
  );
};

TableDataProvider.propTypes = {
  emptyVerifier: PropTypes.func,
  limit: PropTypes.number,
  name: PropTypes.string,
  params: PropTypes.object,
  query: PropTypes.func,
  template: PropTypes.component.isRequired,
};

export default TableDataProvider;
