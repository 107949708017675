import React from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';
import { FormattedMessage } from '@react-intl';
import { Link } from 'react-router-dom';

const LinkExternal = styled('a')``;
const LinkStyled = styled(Link)``;
const Logo = styled('img')``;
const LogoWrapper = styled('div')``;
const Text = styled('p')``;
const Title = styled('p')``;

const Header = ({
  bannerImage,
  className,
  ofaId,
  ofaSecret,
  platformId,
  reseller,
  supportUrl,
  type,
  whatsApp,
}) => {
  const params = new URLSearchParams({
    ofaId,
    ofaSecret,
    platformId,
    type,
  });

  return (
    <div className={className}>
      <LogoWrapper>
        <Logo src={bannerImage} />
      </LogoWrapper>
      <Title>ORDER FULFILLMENT</Title>
      <Text>Reseller: {reseller}</Text>
      <Text>WhatsApp: {whatsApp}</Text>
      <FormattedMessage
        capitalize
        component={LinkExternal}
        href={supportUrl}
        id="ACTIONS.GO_TO_TICKET"
        target="blank"
      />
      <FormattedMessage
        capitalize
        component={LinkStyled}
        id="ACTIONS.VIEW_CONVERSATION_HISTORY"
        to={`/conversation?${params.toString()}`}
      />
    </div>
  );
};

Header.propTypes = {
  bannerImage: PropTypes.string,
  className: PropTypes.string,
  ofaId: PropTypes.string,
  ofaSecret: PropTypes.string,
  platformId: PropTypes.string,
  reseller: PropTypes.string,
  supportUrl: PropTypes.string,
  type: PropTypes.string,
  whatsApp: PropTypes.string,
};

export default styled(Header)`
  border-bottom: solid 0.1rem rgba(0, 0, 0, 0.1);
  margin-bottom: 2.4rem;
  padding-bottom: 2.4rem;

  ${LinkExternal}, ${LinkStyled} {
    background: #1a1a1a;
    border-radius: 0.4rem;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    margin-right: 3.2rem;
    margin-top: 1.6rem;
    padding: 1.2rem 2.4rem;
  }

  ${Logo} {
    max-height: 8rem;
    max-width: 100%;
  }

  ${LogoWrapper} {
    text-align: center;
  }

  ${Text} {
    color: #000000;
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 2.9rem;
  }

  ${Title} {
    color: #a8a9b1;
    font-size: 1.8rem;
    line-height: 2.1rem;
    margin-bottom: 1.6rem;
  }
`;
