import { v4 as uuid } from 'uuid';

export const POP = 'POP';
export const FADE = 'FADE';
export const FLUSH = 'FLUSH';
export const RELOAD = 'RELOAD';
export const SET_PATHNAME = 'SET_PATHNAME';

export const isId = str => /\d+/.test(str);
export const containsId = str => /\d+/.test(str);

const verbs = ['edit', 'add', 'import', 'invite'];

const isVerb = str => verbs.includes(str);
const containsVerb = str => verbs.some(verb => str.includes(verb));
const containsIntegration = str => str.includes('integration');

const removeIrrelevantFirst = ({ step, id }, index) => {
  if (index > 0) return true;
  if (id || containsVerb(step)) return true;
  return false;
};

const addPositionIndices = (step, index, arr) => {
  return {
    ...step,
    depth: arr.length - index - 1,
    order: index,
  };
};

export const getSteps = path => {
  if (containsIntegration(path)) {
    const [step, id, category, service] = path.split('/').slice(1);

    return {
      basePath: `/${step}`,
      steps: [`/${step}/${id}/${category}/${service}`],
    };
  }

  const steps = path
    .split('/')
    .slice(1)
    .reduce((acc, v) => {
      if (isId(v)) {
        const lastValue = acc[acc.length - 1];
        acc[acc.length - 1] = `${lastValue}/${v}`;
        return acc;
      }

      if (isVerb(v)) {
        const lastValue = acc[acc.length - 1];

        if (acc.length === 1 && !containsId(lastValue)) {
          return [`${lastValue}/${v}`];
        }

        return [...acc, `${lastValue}/${v}`];
      }

      return [...acc, `/${v}`];
    }, []);

  const basePath = `/${path.split('/')[1]}`;

  return { basePath, steps };
};

export const mapPathnameToState = url => {
  const { steps, basePath } = getSteps(url);
  return steps
    .reduce((acc, description, i) => {
      let id;
      let verb;
      let category;
      let service;

      const arr = description.split(/\//).slice(1);

      const [step, val1, val2, val3] = arr;

      // An step cant exist without an id or a verb OR gallery route
      if (i !== 0 && !val1 && !val2 && step !== 'gallery') return acc;

      const isIntegration = containsIntegration(step);

      if (isId(val1)) id = val1;
      if (isId(val2)) id = val2;
      if (isVerb(val1)) verb = val1;
      if (isVerb(val2)) verb = val2;
      if (isIntegration) {
        category = val2;
        service = val3;
      }

      const key = steps.slice(0, i + 1).join('');

      const previous = i === 0 ? basePath : steps.slice(0, i).join('');
      const previousStep = i !== 0 && acc[i - 1];
      const previousId = previousStep && previousStep.id;

      const newStep = {
        dataKey: key,
        key,
        previous,
        step: verb ? `${verb}${step}` : step,
        ...(id ? { id } : {}),
        ...(previousId ? { previousId } : {}),
        ...(isIntegration
          ? {
              category,
              service,
            }
          : {}),
      };

      return [...acc, newStep];
    }, [])
    .filter(removeIrrelevantFirst)
    .map(addPositionIndices);
};

export const compareStates = (oldState, newState) => {
  const maxLength = Math.max(oldState.length, newState.length);

  return Array(maxLength)
    .fill(null)
    .map((v, index) => {
      const oldStep = oldState[index];
      const newStep = newState[index];

      const depth = newState.length - 1 - index;
      const order = index;

      if (oldStep && !newStep) {
        return { ...oldStep, depth, in: false, order };
      }

      return {
        ...newStep,
        dataKey: oldStep ? oldStep.dataKey : newStep.dataKey,
        depth,
        in: true,
        order,
      };
    });
};

export const reducer = (state = [], action) => {
  switch (action.type) {
    case SET_PATHNAME:
      return compareStates(state, mapPathnameToState(action.url));
    case POP:
      return state.slice(0, state.length - 1).map(addPositionIndices);
    case FADE:
      return state.map(step => ({ ...step, in: false }));
    case FLUSH:
      return [];
    case RELOAD:
      return state.map(step => {
        if (action.target) {
          const regex = new RegExp(action.target);

          if (!step.key.match(regex)) return step;
        }

        return {
          ...step,
          dataKey: uuid(),
        };
      });
    default:
      throw new Error(`No action defined for ${JSON.stringify(action)}`);
  }
};

export default {
  getSteps,
  isId,
  mapPathnameToState,
};
