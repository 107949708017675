import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled, { theme } from '@styled-components';
import { FormattedMessage } from '@react-intl';

import DateTimePicker from 'Components/DateTimePicker';
import { Radio } from 'Components/Form';

import Actions from './Actions';
import SectionHeadline from './SectionHeadline';

const Box = styled('div')``;
const Label = styled('label')``;

const Date = ({
  activateActions,
  className,
  date,
  onBack,
  onChange,
  onNext,
}) => {
  const handleDate = value => onChange({ date: value });
  const [value, setValue] = useState('schedule');

  useEffect(() => {
    onChange({
      date: {
        dateTime: moment()
          .add(3, 'minutes')
          .format('L LT'),
        timezone: moment.tz.guess(true),
      },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeNow = checked => {
    setValue(checked);
    onChange({ date: undefined });
  };

  const onChangeSchedule = checked => {
    setValue(checked);
    onChange({
      date: {
        dateTime: moment()
          .add(3, 'minutes')
          .format('L LT'),
        timezone: moment.tz.guess(true),
      },
    });
  };

  return (
    <div className={className}>
      <SectionHeadline
        heading="SELECT_DATE_TIME_HEADING"
        title="SELECT_DATE_TIME"
      />
      <Box>
        <Radio
          content="now"
          id="now"
          name="date"
          onChange={checked => onChangeNow(checked)}
          value={value}
        />
        <FormattedMessage component={Label} id="SEND_NOW">
          Send now
        </FormattedMessage>
      </Box>
      {value === 'now' && (
        <div style={{ display: 'none' }}>
          <DateTimePicker
            defaultDateTime={undefined}
            defaultTimezone={date?.timezone}
          />
        </div>
      )}
      <Box>
        <Radio
          content="schedule"
          id="schedule"
          name="date"
          onChange={checked => onChangeSchedule(checked)}
          value={value}
        />
        <FormattedMessage component={Label} id="SCHEDULE">
          Schedule
        </FormattedMessage>
      </Box>
      {value === 'schedule' && (
        <DateTimePicker
          defaultDateTime={
            date?.dateTime ||
            moment()
              .add(3, 'minutes')
              .format('L LT')
          }
          defaultTimezone={date?.timezone}
          onChange={handleDate}
        />
      )}
      {activateActions && (
        <Actions disabled={!date} onBack={onBack} onNext={onNext} />
      )}
    </div>
  );
};

Date.propTypes = {
  activateActions: PropTypes.bool,
  className: PropTypes.string,
  date: PropTypes.object,
  onBack: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default styled(Date)`
  ${SectionHeadline} {
    margin-bottom: 1.6rem;
  }

  ${DateTimePicker} {
    width: 32rem;
  }

  ${Box} {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;

    ${Radio} {
      margin-right: 0.8rem;
    }

    ${Label}, span {
      ${theme('--font-medium')}
      ${theme('--font-opacity-60')}
      ${theme('--font-weight-book')}
      display: block;
      margin-bottom: 0.4rem;
    }
  }
`;
