import React from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';

import { AnswerTypeTooltip, ScoreTooltip, TagTooltip } from './Tooltips';

import { Row, Column } from 'Components/ResponsiveTable';

const HeaderTemplate = ({ className, options: { role }, ...common }) => (
  <Row className={className}>
    <Column id="QUESTION" {...common} />
    <Column id="ANSWER_TYPE" tooltip={AnswerTypeTooltip} />
    <Column id="ANSWER_DESCRIPTION" />
    {role === 'SUPER_ADMIN' && (
      <Column id="SCORE" {...common} tooltip={ScoreTooltip} />
    )}
    <Column id="TAGS" tooltip={TagTooltip} />
  </Row>
);

HeaderTemplate.propTypes = {
  className: PropTypes.string,
  options: PropTypes.shape({
    role: PropTypes.string,
  }),
};

export default styled(HeaderTemplate)`
  grid-template-columns: ${({ role }) =>
    role === 'SUPER_ADMIN'
      ? '2fr 11rem 3fr 5.8rem 20.2rem'
      : '2fr 11rem 3fr 20.2rem'};
  padding: 1.2rem 2.4rem 0.8rem 2.4rem;
`;
