import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from '@styled-components';
import { useHistory, useLocation } from 'react-router-dom';

import Card from 'Components/Card';
import Table from 'Components/ResponsiveTable';

import Template from './components/Product';

const MostViewed = ({
  className,
  data: { collection = [] } = {},
  onCursorChange: handleCursorChange = () => {},
}) => {
  const { push } = useHistory();
  const { pathname } = useLocation();

  const handleClick = useCallback(
    id => () => {
      push(`${pathname}/products/${id}`);
    },
    [pathname, push],
  );

  return (
    <Card className={className} title="$MOST_VIEWED_PRODUCTS">
      <Table
        items={collection}
        linkTo="/products"
        onCursorChange={handleCursorChange}
        options={{ onClick: handleClick }}
        placeholders={{
          heading: '170K',
          subheading: '-1.5% views this month',
        }}
        rows={4}
        template={Template}
      />
    </Card>
  );
};

MostViewed.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    collection: PropTypes.array,
    trend: PropTypes.string,
  }),
  onCursorChange: PropTypes.func,
};

export default styled(MostViewed)`
  padding: 6.4rem 0 0;
`;
