import React from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';
import { useField } from 'spooner';

import { AutoSuggest } from 'Components/Form';

import Product from 'Components/Products/Product';

const Products = ({
  className,
  data: { collection: products = [] } = {},
  name,
}) => {
  const { onChange = () => {}, value = [] } = useField({ name });

  const handleChange = ([{ id }]) => onChange([...value, id]);

  const handleRemove = uid => () => onChange(value.filter(id => id !== uid));

  const listSelectedComponents = value.map(uid => {
    const product = products.find(({ id }) => uid === id);

    return (
      product && (
        <Product key={product.id} {...product} onClick={handleRemove(uid)} />
      )
    );
  });

  return (
    <div className={className}>
      {listSelectedComponents}
      <AutoSuggest
        capitalize
        closeAfterSelect
        onChange={handleChange}
        options={products.filter(({ id }) => !products.includes(id))}
        placeholder={value.length ? 'ADD_MORE' : 'SEARCH'}
        value={[]}
      />
    </div>
  );
};

Products.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    products: PropTypes.arrayOf(PropTypes.object),
  }),
  name: PropTypes.string,
};

export default styled(Products)`
  ${AutoSuggest} {
    margin-bottom: 2.4rem;
  }

  ${Product} {
    margin-bottom: 1.6rem;
  }
`;
