import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'Components/Modal';

import View from './Answers';

const AnswersModal = ({
  data: { collection = [], customer = {}, survey = {} } = {},
  dataKey,
  depth,
  modalIn,
  onClose,
  onBack,
  order,
}) => {
  return (
    <Modal
      key={dataKey}
      depth={depth}
      in={modalIn}
      onBack={onBack}
      onClose={onClose}
      order={order}
      show={modalIn}
      title="ANSWERS_MODAL_TITLE"
      titleValues={{
        customerName: `${customer.firstName} ${customer.lastName}'s`,
        surveyName: survey.name,
      }}
    >
      <View collection={collection} customer={customer} survey={survey} />
    </Modal>
  );
};

AnswersModal.propTypes = {
  data: PropTypes.shape({
    collection: PropTypes.array,
    customer: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    survey: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  dataKey: PropTypes.string,
  depth: PropTypes.number,
  modalIn: PropTypes.bool,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  order: PropTypes.number,
};

export default AnswersModal;
