import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';

import { Context } from 'Containers/DataProvider';

import Button from 'Components/Button';

const Control = ({ disabled, ...props }) => {
  const { loading } = useContext(Context);

  return <Button {...props} disabled={disabled || loading} />;
};

Control.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default styled(Control)`
  ${theme('--font-weight-book')}
  border-radius: 0.6rem;
  height: 3.2rem;
  min-width: 3.2rem;

  &:hover:not([disabled]) {
    background-image: linear-gradient(
      180deg,
      rgba(0, 132, 255, 0.2) 0%,
      rgba(0, 132, 255, 0.1) 100%
    );
    color: ${theme('--color-primary')};
  }

  &[disabled] {
    opacity: 0.3;
  }
`;
