import React from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';

import DataProvider from 'Containers/DataProvider';

import Table from 'Components/Table';

import service from 'Services/audience';

import Template from './Template';

const Stats = ({ account, className, from, to }) => {
  return (
    <DataProvider
      className={className}
      params={{ account, from, to }}
      query={service.getConversionRatesStats}
      template={({ data = [] }) => <Table items={data} template={Template} />}
    />
  );
};

Stats.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
  from: PropTypes.string,
  to: PropTypes.string,
};

export default styled(Stats)`
  padding: 2.4rem 0;
`;
