/* eslint-disable */

import { rgba } from 'polished';
import { css } from '@styled-components';

export default {
  // @deprecated
  '--primary-color': '#005DFF',
  '--secondary-color': '#02C0F9',
  '--black-color': '#0C0F1C',
  '--white-color': '#FFF',

  '--accent-color': '#9500FF',

  '--error-color': '#ff3e56',

  '--logger-error-color': '#ff3e56',
  '--logger-success-color': '#43B581',
  '--logger-warning-color': '#FFC51A',
  '--primary-background-color': '#f5f8fc',
  '--secondary-background-color': '#ffffff',
  '--terciary-background-color': '#212224',
  '--dark-background-color': '#063454',
  '--light-background-color': '#F5F7FA',
  '--gradient-primary-dark-color': '#0095FF',
  '--gradient-primary-light-color': '#005DFF',
  '--gradient-secondary-dark-color': '#00A5F7',
  '--gradient-secondary-light-color': '#02C8FA',
  '--text-primary-color': '#4a4a4a',
  '--text-secondary-color': '#9b9b9b',
  '--text-accent-color': '#ffffff',
  '--disabled-text-color': '#6f737a',
  '--divider-color': '#eaeaea',
  '--funnel-dark-color': '#260a5c',

  /* @deprecated */
  '--opacity-10': rgba('#000f19', 0.1),
  '--opacity-20': rgba('#000f19', 0.2),
  '--opacity-40': rgba('#000f19', 0.4),
  '--opacity-60': rgba('#000f19', 0.6),
  '--opacity-80': rgba('#000f19', 0.8),
  '--opacity-100': '#000f19',

  /* @deprecated */
  '--weight-book': 400,
  '--weight-medium': 500,
  '--weight-demi': 600,

  /* @deprecated */
  '--font-extra-large': css`
    font-size: 4.4rem;
    line-height: 4.8rem;
  `,

  /** Fonts @deprecated */
  '--font-size-tiny': '0.8rem',
  '--font-size-small': '1rem',
  '--font-size-normal': '1.2rem',
  '--font-size-big': '1.4rem',
  '--font-size-bigger': '1.6rem',
  '--font-size-superbig': '1.8rem',
  '--font-size-biggest': '2.4rem',
  '--font-size-display': '3.2rem',

  '--font-weight-thin': '100',
  '--font-weight-light': '300',
  '--font-weight-regular': '400',
  // '--font-weight-medium': '500',
  '--font-weight-bold': '700',
  /** /Fonts */

  /** Spaces @deprecated */
  '--spacing?': '0',
  '--spacing-tiny': '0.4rem',
  '--spacing-small': '0.8rem',
  '--spacing-middle': '1.6rem',
  '--spacing-large': '3.2rem',
  '--spacing-huge': '6.4rem',

  '--spacing-avatar': '4rem',

  /** zIndex @deprecated */
  '--zIndex-highest': '300',
  '--zIndex-higher': '200',
  '--zIndex-high': '100',
  '--zIndex-normal': '1',
  '--zIndex-base': '0',
  '--zIndex-low': '-100',
  '--zIndex-lower': '-200',
};
