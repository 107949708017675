import styled, { theme } from '@styled-components';

import withTransition from 'Components/with/transition';
import Dropdown from 'Components/DropdownPanel';

export default withTransition(150)(styled(Dropdown)`
  background: ${theme('--color-light')};
  border-radius: 0.6rem;
  box-shadow: 0 0.8rem 1.6rem rgba(0, 15, 25, 0.05),
    0 0.1rem 0 rgba(0, 15, 25, 0.05);
  left: 0.8rem;
  opacity: 0;
  overflow: hidden;
  top: 0.8rem;
  transform: translateY(-5.6rem);
  transition: opacity 150ms ease-out, transform 150ms ease-out;
  white-space: nowrap;

  &.entered {
    opacity: 1;
    transform: translateY(0);
  }
`);
