import React from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';

import Price from './Price';

const Color = styled('p')``;
const Image = styled('img')``;
const Info = styled('div')``;
const PriceWrapper = styled('div')``;
const Quantity = styled('p')``;
const Title = styled('h4')``;
const Wrapper = styled('div')``;

const Products = ({ className, items = [] }) => (
  <div className={className}>
    {items.map(
      ({ image, options: { color = {}, price, quantity }, skuId, name }) => (
        <Wrapper key={skuId}>
          <Image src={image} />
          <Info>
            <Title>{name}</Title>
            <Color>{color.name}</Color>
            <Quantity>Quantity: {quantity}</Quantity>
          </Info>
          <PriceWrapper>
            <Price {...price} />
          </PriceWrapper>
        </Wrapper>
      ),
    )}
  </div>
);

Products.propTypes = {
  className: PropTypes.string,
  items: PropTypes.shape({
    image: PropTypes.string,
    options: PropTypes.object,
    skuId: PropTypes.string,
    title: PropTypes.string,
  }),
};

export default styled(Products)`
  ${Color} {
    color: #666f75;
    font-size: 1.4rem;
    font-weight: normal;
    line-height: 1.7rem;
  }

  ${Image} {
    border: solid 0.1rem rgba(0, 0, 0, 0.1);
    border-radius: 0.2rem;
    max-width: 100%;
  }

  ${PriceWrapper} {
    color: #000f19;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.9rem;
    text-align: right;
  }

  ${Quantity} {
    color: #666f75;
    font-size: 1.4rem;
    font-weight: normal;
    line-height: 1.7rem;
  }

  ${Title} {
    color: #000f19;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.9rem;
  }

  ${Wrapper} {
    border-bottom: solid 0.1rem rgba(0, 0, 0, 0.1);
    display: grid;
    grid-column-gap: 1.6rem;
    grid-template-columns: 8rem 1fr 10rem;
    padding: 1.2rem 0;
  }
`;
