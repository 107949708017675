import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate } from '@react-intl';
import styled, { theme } from '@styled-components';

import Chip from 'Components/Chip';
import Icon from 'Components/Icon';
import { Row } from 'Components/ResponsiveTable';
import { PlaceholderNoData as Placeholder } from 'Containers/Placeholder';

const Tag = styled(Placeholder)``;
const NameTag = styled(Tag)``;
const Desc = styled('span')``;

const Campaign = ({
  className,
  empty,
  id,
  name,
  launchDate,
  platform: constant = '',
  status,
  totalCustomers,
  totalSuccess,
  type,
}) => {
  // const platform = type !== 'SMS' ? constant.toLowerCase() : 'sms';

  let typeDesc;
  if (type === '') typeDesc = 'N/A';
  else if (type === 'SMS') typeDesc = 'SMS';
  else if (type === 'FB_MESSENGER') typeDesc = 'Messenger';
  else if (type === 'IG_MESSENGER') typeDesc = 'Instagram';
  else if (type === 'WHATSAPP') typeDesc = 'WhatsApp';
  else typeDesc = type;

  let platformDesc;
  if (constant === '') platformDesc = 'N/A';
  else if (constant === 'SMS') platformDesc = 'SMS';
  else if (constant === 'FB_MESSENGER') platformDesc = 'Messenger';
  else if (constant === 'IG_MESSENGER') platformDesc = 'Instagram';
  else if (constant === 'WHATSAPP') platformDesc = 'WhatsApp';
  else platformDesc = constant;

  const iconType = type ? `${type.toLowerCase()}-variant1` : '';
  const iconPlatform = `${constant.toLowerCase()}-variant1`;

  return (
    <Row className={className} empty={empty} to={`campaigns/${id}`}>
      <NameTag placeholder="really long campaign name really long campaign name">
        {name}
      </NameTag>
      <Tag placeholder="Customer Enrolled">
        {totalSuccess || 0}/{totalCustomers || 0}
      </Tag>
      <Tag placeholder="campaign type">
        <Icon name={iconType} />
        <Desc> {typeDesc}</Desc>
      </Tag>
      <Tag placeholder="campaign platform">
        <Icon name={iconPlatform} />
        <Desc> {platformDesc}</Desc>
      </Tag>
      <Tag placeholder="campaign date">
        <FormattedDate
          day="2-digit"
          month="long"
          value={new Date(Number(launchDate))}
          year="numeric"
        />
      </Tag>
      <Tag placeholder="chip">
        <Chip>{status}</Chip>
      </Tag>
    </Row>
  );
};

Campaign.propTypes = {
  className: PropTypes.string,
  empty: PropTypes.bool,
  id: PropTypes.number,
  launchDate: PropTypes.string,
  name: PropTypes.string,
  platform: PropTypes.string,
  status: PropTypes.string,
  totalCustomers: PropTypes.string,
  totalSuccess: PropTypes.string,
  type: PropTypes.string,
};

export default styled(Campaign)`
  grid-template-columns: 6fr 1.5fr 2fr 2fr 2fr 1.5fr;
  height: 7.2rem;

  ${Tag} {
    ${theme('--font-medium')}
    ${theme('--font-opacity-40')}
    ${theme('--font-weight-book')}
  }

  ${Desc} {
    vertical-align: super;
  }

  ${NameTag} {
    color: ${theme('--color-primary')};
    ${theme('--font-weight-medium')}
  }

  ${Chip} {
    ${theme('--font-weight-demi')}
    background: ${theme('--color-main-20')};
    border: none;
    border-radius: 0.6rem;
    color: ${theme('--color-main')};
  }
`;
