import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Sign = styled('div')``;
const Tip = styled('div')``;
const Triangle = styled('div')``;
const TextBlock = styled('div')``;

const CustomerTooltip = ({ className, tipText }) => (
  <div className={className}>
    <Sign>?</Sign>
    <Tip>
      <Triangle />
      <TextBlock>
        <FormattedMessage id={tipText} />
      </TextBlock>
    </Tip>
  </div>
);

CustomerTooltip.propTypes = {
  className: PropTypes.string,
  tipText: PropTypes.string,
};

export default styled(CustomerTooltip)`
  ${Tip} {
    display: none;
    position: relative;
    z-index: 100;
  }

  ${Triangle} {
    border-bottom: 0.8rem solid #666f75;
    border-left: 0.8rem solid transparent;
    border-right: 0.8rem solid transparent;
    height: 0;
    left: 0.4rem;
    position: absolute;
    top: 0.1rem;
    width: 0;
  }

  ${TextBlock} {
    background: #666f75;
    border-radius: 0.6rem;
    color: #cccfd1;
    font-size: 1.4rem;
    height: 6.9rem;
    line-height: 16px;
    padding: 1rem;
    position: absolute;
    right: 0rem;
    top: 0.8rem;
    width: 22.5rem;
  }

  ${Sign} {
    background: #e5e7e8;
    border-radius: 50%;
    color: #999fa3;
    text-align: center;
    user-select: none;
    width: 2.4rem;

    &:hover + ${Tip} {
      display: block;
    }
  }
`;
