import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@react-intl';
import styled, { theme } from '@styled-components';

import Button from 'Components/Button';
import Form, { Input } from 'Components/Form';
import { Redirect } from 'Components/Router';

import { recoverPassword as recoverPasswordRules } from 'Rules';

const H2 = styled('h2')``;
const Paragraph = styled('p')``;

class RecoverPassword extends Component {
  state = {
    emailSent: false,
  };

  static propTypes = {
    className: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    recoverPassword: PropTypes.func,
  };

  handleSubmit = ({ emailAddress }) => {
    const { recoverPassword } = this.props;
    const { emailSent } = this.state;

    recoverPassword(emailAddress);
    this.setState({ emailAddress, emailSent: !emailSent });
  };

  render() {
    const { className, isLoggedIn } = this.props;
    const { emailAddress, emailSent } = this.state;

    if (isLoggedIn) {
      return <Redirect push to="/" />;
    }

    return (
      <>
        {emailSent ? (
          <div className={className}>
            <FormattedMessage capitalize component={H2} id="EMAIL_SENT">
              email sent
            </FormattedMessage>
            <FormattedMessage
              capitalize
              component={Paragraph}
              id="INSTRUCTIONS.EMAIL_SENT1"
            >
              check your email inbox
            </FormattedMessage>
            <Paragraph>
              <FormattedMessage
                id="INSTRUCTIONS.EMAIL_SENT2"
                values={{
                  email: emailAddress,
                  strong: (...chunks) => <strong>{chunks}</strong>,
                }}
              >
                If there is an account registered for
                <strong>{emailAddress}</strong> you will receive the
                instructions to recover your password in a few minutes. Please,
                remember to check the spam folder
              </FormattedMessage>
            </Paragraph>
            <Paragraph>
              <FormattedMessage
                id="QUESTIONS.NEED_HELP"
                values={{
                  a: (...chunks) => (
                    <a href="mailto:info@joinedapp.com">{chunks}</a>
                  ),
                }}
              />
            </Paragraph>
          </div>
        ) : (
          <Form
            className={className}
            constraint={recoverPasswordRules}
            onSubmit={this.handleSubmit}
          >
            <FormattedMessage capitalize component={H2} id="PASSWORD_FORGOTTEN">
              password forgotten
            </FormattedMessage>
            <FormattedMessage
              capitalize
              component={Paragraph}
              id="INSTRUCTIONS.PASSWORD_FORGOTTEN"
            >
              access into your account
            </FormattedMessage>
            <Input
              capitalize
              label="EMAIL_ADDRESS"
              name="emailAddress"
              type="email"
            />
            <FormattedMessage
              capitalize
              primary
              component={Button}
              id="CONTINUE"
              type="submit"
            >
              continue
            </FormattedMessage>
          </Form>
        )}
      </>
    );
  }
}

export default styled(RecoverPassword)`
  ${H2} {
    ${theme('--font-weight-book')}
    ${theme('--font-xlarge')}
  }
  ${Paragraph} {
    ${theme('--font-medium')}
    ${theme('--font-opacity-40')}
    line-height: 2.4rem;
    margin: 0.8rem 0 3.2rem;
    &:nth-of-type(3) {
      margin-bottom: 0;
    }

    a {
      ${theme('--font-weight-demi')}
      color: ${theme('--color-primary')};
    }

    strong {
      ${theme('--font-opacity-100')}
    }
  }

  ${Input} {
    margin-bottom: 0.8rem;
  }

  ${Button} {
    margin-top: 3.2rem;
    width: 100%;
  }
`;
