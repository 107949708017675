import React from 'react';
import styled from '@styled-components';
import PropTypes from '@prop-types';
import { Row, Column, SortableColumn } from 'Components/ResponsiveTable';

const Header = ({ className, onSortBy, sortBy, order }) => {
  const common = { onSortBy, order, sortBy };

  return (
    <Row className={className}>
      <SortableColumn field="firstName" id="CUSTOMER" {...common} />
      <SortableColumn field="date" id="DATE" {...common} />
      <Column id="PLATFORM" />
      <Column id="QUANTITY" />
      <Column id="REVSHARE" />
      <Column id="REVSHARE_AMOUNT" />
      <Column id="TOTAL" />
    </Row>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  onSortBy: PropTypes.func,
  order: PropTypes.string,
  sortBy: PropTypes.string,
};

export default styled(Header)`
  display: inline-grid;
  grid-template-columns: 2fr 2fr 2fr 1fr 1fr 3fr 1fr 1fr;
  padding: 1.2rem 2.4rem 0.8rem;
`;
