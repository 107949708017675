import React from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';

import Card from 'Components/Card';
import Table from 'Components/ResponsiveTable';

import template from './Table';
import EmptyTemplate from './Table/EmptyTemplate';

export const Surveys = ({
  className,
  customerId,
  data: { collection: surveys } = {},
}) => (
  <Card className={className} title="SURVEYS">
    <Table
      {...template}
      emptyTemplate={EmptyTemplate}
      items={surveys}
      options={{ customerId }}
      rows={5}
    />
  </Card>
);

Surveys.propTypes = {
  className: PropTypes.string,
  customerId: PropTypes.number,
  data: PropTypes.shape({
    collection: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        platform: PropTypes.string,
        status: PropTypes.string,
        step: PropTypes.number,
        steps: PropTypes.arrayOf(
          PropTypes.shape({
            displayName: PropTypes.string,
            key: PropTypes.number,
          }),
        ),
        type: PropTypes.string,
        updatedAt: PropTypes.instanceOf(Date),
      }),
    ),
    total: PropTypes.number,
  }),
};

export default styled(Surveys)`
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);

  @media (${theme('--breakpoint-small')}) {
    height: 100%;
  }
`;
