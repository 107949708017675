import { createService } from '@redux-act';

import request from 'Providers/request';

const download = createService(
  async ({ account: ofaId, from, limit, to, filterBy: platform }, token) => {
    const body = {
      currency: 'USD',
      from,
      limit,
      ofaId,
      offset: 0,
      to,
    };

    if (platform) {
      body.platform = platform;
    }

    const response = await request.create(
      `/analytics/transactions_data/download?ofaId=${ofaId}`,
      body,
      {},
      { token, ttl: 0 },
    );

    return response;
  },
);

const find = createService(
  async ({ account: ofaId, from, limit, offset, to, platform }, token) => {
    const query = {
      currency: 'USD',
      from,
      limit,
      ofaId,
      offset,
      to,
    };

    if (platform) {
      query.platform = platform;
    }

    const { data, totalRows: total } = await request.read(
      '/analytics/transactions_data',
      query,
      {},
      { token, ttl: 0 },
    );

    return {
      collection: data,
      total,
    };
  },
);

export default {
  download,
  find,
};
