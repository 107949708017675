import React, { useState } from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from '@prop-types';
import { FormattedMessage } from '@react-intl';

import Button from 'Components/Button';
import Icon from 'Components/Icon';
import Tip from 'Components/Tip';

const Wrapper = styled('span')`
  ${theme('--font-small')}
  ${theme('--font-weight-medium')}
  ${theme('--font-opacity-100')}
  align-items: center;
  display: flex;
  height: 1.6rem;
  text-transform: capitalize;

  ${Tip} {
    height: 1.6rem;
    margin-left: 0.4rem;
    width: 1.6rem;
  }
`;

const Regular = ({ className, id, tooltip: Tooltip, tooltipOverlay }) => (
  <Wrapper className={className}>
    <FormattedMessage id={id} />
    {Tooltip && (
      <Tip buttonId="show more" overlay={tooltipOverlay}>
        <Tooltip />
      </Tip>
    )}
  </Wrapper>
);

Regular.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  tooltip: PropTypes.component,
  tooltipOverlay: PropTypes.component,
};

const getIcon = (isHover, isSelected, order) => {
  switch (true) {
    case isHover && !isSelected:
    case isHover && isSelected && order === 'descending':
      return 'sortdown-fill';
    case isHover && isSelected && order === 'ascending':
      return 'sortup-fill';
    case isSelected && order === 'descending':
      return 'sortdown';
    case isSelected && order === 'ascending':
      return 'sortup';
    default:
      return 'sortup';
  }
};

const Sortable = ({ className, id, onSortBy, field, sortBy, order }) => {
  const [isHover, setIsHover] = useState(false);
  const onHover = () => setIsHover(true);
  const onBlur = () => setIsHover(false);
  const isSelected = sortBy === field;
  const showIcon = isSelected || isHover;
  const icon = getIcon(isHover, isSelected, order);

  return (
    <Button
      className={className}
      onBlur={onBlur}
      onClick={() => onSortBy(field)}
      onFocus={onHover}
      onMouseOut={onBlur}
      onMouseOver={onHover}
    >
      <Wrapper>
        <FormattedMessage id={id} />
        {showIcon && <Icon name={icon}>{`ACTIONS.SORT_BY_${id}`}</Icon>}
      </Wrapper>
    </Button>
  );
};

Sortable.propTypes = {
  className: PropTypes.string,
  field: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onSortBy: PropTypes.func,
  order: PropTypes.string,
  sortBy: PropTypes.string,
};

export const Column = styled(Regular)``;
export const SortableColumn = styled(Sortable)`
  &:focus {
    box-shadow: none;
  }

  ${Icon} {
    margin-left: 0.4rem;
    margin-top: -0.3rem;
    pointer-events: none;
    position: relative;
  }
`;

export default {
  Column,
  SortableColumn,
};
