import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles as css } from '@styled-components';
import { compose } from 'underscore';

import Card from 'Components/Card';

import DataProvider from 'Containers/DataProvider';

import { getUnit } from 'Modules/dates';

import { currency as currentCurrency, currentAccount } from 'Selectors';

import BarChartLoader from 'Components/BarChartLoader';
import service from 'Services/orders';
import Chart from './Chart';

const mapStateToProps = state => ({
  account: currentAccount(state),
  currency: currentCurrency(state),
});

const AverageValuePerPurchaseChart = ({
  account,
  className,
  currency,
  from = moment()
    .subtract(1, 'year')
    .format(),
  to = moment().format(),
}) => {
  const unit = getUnit(from, to);

  return (
    <DataProvider
      params={{ account, currency, from, to, unit }}
      query={service.getAverageValueByTime}
      template={({ data: { orders = {} } = {} }) => (
        <Card className={className} title="$AVERAGE_ITEMS_VALUE">
          <Chart
            currency={currency}
            data={Object.entries(orders).map(([date, order]) => ({
              x: date,
              y1: order,
            }))}
            loader={BarChartLoader}
            unit={unit}
          />
        </Card>
      )}
    />
  );
};

AverageValuePerPurchaseChart.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
  currency: PropTypes.string,
  from: PropTypes.string,
  to: PropTypes.string,
};

export default compose(
  connect(mapStateToProps),
  css``,
)(AverageValuePerPurchaseChart);
