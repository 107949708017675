import React, { useState } from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';
import { Select } from 'Components/Form';
import Card from 'Components/Card';

export const MultiCard = ({ content, className, title }) => {
  const keys = content.map(({ name, value }) => ({
    name,
    value,
  }));

  const [option, setOption] = useState(keys[0].value);

  const handleChange = value => setOption(value);

  return (
    <Card className={className} title={title}>
      <Select
        data-testid="multicard-select"
        name="chart"
        onChange={handleChange}
        options={keys}
        value={option}
      />
      {content.map(({ value, Component }) => {
        return value === option ? <Component key={option} /> : null;
      })}
    </Card>
  );
};

MultiCard.propTypes = {
  className: PropTypes.string,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      Component: PropTypes.component.isRequired,
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  title: PropTypes.string.isRequired,
};

export default styled(MultiCard)`
  display: grid;
  grid-template: auto 1fr 1fr / 1fr;
  min-height: 42rem;
`;
