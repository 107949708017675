import React, { Component } from 'react';
import countries, { common } from '@countries';
import PropTypes from 'prop-types';
import { rgba } from 'polished';
import { intlDefault, intlShape, withIntl } from '@react-intl';
import styled, { withStyles, theme } from '@styled-components';
import _ from 'underscore';

import Icon from 'Components/Icon';

import Select from '../Select';

const Display = styled('div')``;
const Flag = styled('img').attrs(({ code = '' }) => ({
  src: `/images/flags/${code.toLowerCase()}.svg`,
}))``;

const US_CODE = 'US';

const list = countries.filter(country => country.idd.root);

export const codes = _.uniq([
  list.find(country => country.cca2 === US_CODE).idd.root,
  ...list.map(country => country.idd.root).filter(Boolean),
]).map(code => `+${code}`);

export class Country extends Component {
  options = _.memoize(() => {
    const {
      intl: { locale },
    } = this.props;

    const countriesList = list
      .map(country => ({
        code: country.cca2,
        name: `${common(country.cca2, locale)} +${country.idd.root}`,
        value: `+${country.idd.root}`,
      }))
      .sort((a, b) => a.code.localeCompare(b.code));

    return [
      countriesList.find(({ code }) => code === US_CODE),
      ...countriesList.filter(({ code }) => code !== US_CODE),
    ];
  });

  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.number,
    // Indeed is used (above)
    // eslint-disable-next-line react/no-unused-prop-types
    intl: intlShape,
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
  };

  static defaultProps = {
    intl: intlDefault,
    onChange() {},
  };

  render() {
    const { className, disabled, id, name, onChange, value } = this.props;
    const { code } = this.options().find(({ value: v }) => v === value);

    return (
      <div className={className}>
        <Display>
          <Flag code={code} />
          <Icon name="vertical-arrows">ACTIONS.SELECT_OPTION</Icon>
        </Display>
        <Select
          disabled={disabled}
          id={id}
          name={`${name}-select`}
          onChange={onChange}
          options={this.options()}
          value={value}
        />
      </div>
    );
  }
}

export default _.compose(
  withStyles`
    position: relative;

    &:focus-within {
      ${Display} {
        border: 0.1rem solid #005DFF;
      }
    }

    ${Display} {
      ${theme('--font-small')}
      align-items: center;
      background: #FFF; /* Light */
      border: 0.1rem solid ${rgba('#000F19', 0.1)};
      border-radius: 0.6rem;
      display: flex;
      height: 100%;
      justify-content: space-between;
      padding: 0 0.4rem 0 1rem;
      position: absolute;
      transition: border-color 0.2s ease-out, margin-bottom 0.2s ease-out;
      width: 100%;
      z-index: 1;

      ${Flag} {
        height: 2rem;
        opacity: ${props => (props.disabled ? 0.1 : 1)};
        width: 2.8rem;
      }

      ${Icon} {
        height: 2.4rem;
        margin-left: 0.4rem;
        opacity: ${props => (props.disabled ? 0.1 : 1)};
        width: 2.4rem;
      }
    }
    
    ${Select} {
      height: 100%;
      overflow:hidden;
    }
  `,
  withIntl,
)(Country);
