import React from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from '@prop-types';
import { FormattedMessage } from '@react-intl';

const Paragraph = styled('p')`
  ${theme('--font-medium')}
  ${theme('--font-weight-medium')}
  color: ${theme('--color-light')};
`;

export const SurveyStepTooltip = ({ className }) => {
  return (
    <div className={className}>
      <FormattedMessage
        capitalize
        component={Paragraph}
        id="TOOLTIP.SURVEY_STEP"
      />
    </div>
  );
};

SurveyStepTooltip.propTypes = {
  className: PropTypes.string,
};

export default styled(SurveyStepTooltip)``;
