import React from 'react';
import PropTypes from '@prop-types';

import Card from 'Components/Card';

const Loader = ({ chartTemplate, tableTemplate, loading, ...props }) => (
  <Card {...props} />
);

Loader.propTypes = {
  chartTemplate: PropTypes.component,
  loading: PropTypes.bool,
  tableTemplate: PropTypes.component,
};

export default Loader;
