import React, { useEffect, useState } from 'react';
import PropTypes from '@prop-types';
import { IntlProvider } from '@react-intl';

function load(file) {
  return import(`Translations/${file}.json`).catch(() => ({}));
}

async function fetch(locale) {
  const [language] = locale.split(/[-_]/);

  return {
    ...(await load('en')),
    ...(await load(language)),
    ...(await load(locale)),
  };
}

const I18n = ({ children, locale = navigator.language || 'en' }) => {
  const [messages, setMessages] = useState(null);

  useEffect(() => {
    let current = true;

    (async () => {
      const dictionary = await fetch(locale);

      if (current) {
        setMessages(dictionary);
      }
    })();

    return () => {
      current = false;
    };
  }, [locale]);

  return (
    messages && (
      <IntlProvider key={locale} locale={locale} messages={messages}>
        {children}
      </IntlProvider>
    )
  );
};

I18n.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  locale: PropTypes.string,
};

export default I18n;
