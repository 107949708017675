import React from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';
import { FormattedDate, FormattedMessage } from '@react-intl';

import Avatar from 'Components/Avatar';
import Currency from 'Components/Currency';
import Dialog from 'Components/Dialog';
import Table from 'Components/Table';

const Header = styled('div')``;
const Name = styled('span')``;
const Date = styled('span')``;
const Ids = styled('div')``;
const Id = styled('span')``;
const Items = styled('span')``;
const Layout = styled('div')``;
const Summary = styled('div')``;
const Title = styled('span')``;
const Total = styled('div')``;

const Details = ({
  className,
  customerAvatar,
  customerName,
  paymentId,
  orderId,
  itemCount,
  currency,
  total,
  date,
  onClose: handleClose,
  in: isIn,
}) => (
  <Dialog
    className={className}
    in={isIn}
    onDismiss={handleClose}
    title="Receipt"
  >
    <Layout>
      <Header>
        <Avatar fill image={customerAvatar} name={customerName} />
        <Name>{customerName}</Name>
        <FormattedDate
          component={Date}
          day="2-digit"
          month="long"
          value={new window.Date(date)}
          year="numeric"
        />
      </Header>
      <Ids>
        <FormattedMessage capitalize component={Title} id="PAYMENT_ID">
          Payment id
        </FormattedMessage>
        <FormattedMessage capitalize component={Title} id="ORDER_ID">
          Order id
        </FormattedMessage>
        <Id>{paymentId}</Id>
        <Id>{orderId}</Id>
      </Ids>
      <Summary>
        <Header>
          <FormattedMessage capitalize component={Title} id="SUMMARY">
            Summary
          </FormattedMessage>
          <FormattedMessage
            capitalize
            component={Items}
            id="%NUMBER%_ITEMS"
            values={{ number: itemCount }}
          />
        </Header>
        <Total>
          <FormattedMessage capitalize component={Title} id="TOTAL">
            Total
          </FormattedMessage>
          <Currency currency={currency}>{total}</Currency>
        </Total>
      </Summary>
    </Layout>
  </Dialog>
);

Details.propTypes = {
  className: PropTypes.string,
  currency: PropTypes.string,
  customerAvatar: PropTypes.string,
  customerName: PropTypes.string,
  date: PropTypes.string,
  in: PropTypes.bool,
  itemCount: PropTypes.number,
  onClose: PropTypes.func,
  orderId: PropTypes.string,
  paymentId: PropTypes.string,
  total: PropTypes.number,
};

export default styled(Details)`
  ${Layout} {
    display: grid;
    grid-gap: 1.8rem 0;
  }

  ${Title} {
    ${theme('--font-small')}
    ${theme('--font-weight-demi')}
  }

  ${Header} {
    border-bottom: 0.1rem solid ${theme('--color-dark-night-10')};
    display: grid;
    grid-gap: 0.4rem 0.8rem;
    grid-template-columns: auto 1fr;
    padding-bottom: 2rem;

    ${Avatar} {
      border-radius: 50%;
      grid-column: 1;
      grid-row: 1 / span 2;
      height: 4rem;
      max-height: 4rem;
      max-width: 4rem;
      min-height: 4rem;
      min-width: 4rem;
    }

    ${Name} {
      ${theme('--font-medium')}
      ${theme('--font-weight-medium')}
      grid-column: 2;
      grid-row: 1;
      max-width: 16rem;
      text-align: left;
    }

    ${Date} {
      ${theme('--font-opacity-40')}
      ${theme('--font-small')}
      ${theme('--font-weight-book')}
      grid-column: 2;
      grid-row: 2;
      text-align: left;
    }
  }

  ${Ids} {
    border-bottom: 0.1rem solid ${theme('--color-dark-night-10')};
    display: grid;
    grid-gap: 0.4rem;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 2rem;

    ${Id} {
      ${theme('--font-opacity-40')}
      ${theme('--font-small')}
      ${theme('--font-weight-book')}
    }
  }

  ${Summary} {
    display: grid;
    grid-gap: 1.2rem;

    ${Header} {
      border: 0;
      display: grid;
      grid-gap: 0.4rem;
      grid-template: 1fr / 1fr;
      padding: 0;

      ${Items} {
        ${theme('--font-opacity-40')}
        ${theme('--font-small')}
        ${theme('--font-weight-book')}
      }
    }

    ${Table} {
      margin: 0 -2.4rem;
    }

    ${Total} {
      display: flex;
      justify-content: space-between;

      ${Title}, ${Currency} {
        ${theme('--font-small')}
        ${theme('--font-weight-demi')}
      }
    }
  }
`;
