import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withStyles } from '@styled-components';

import * as logger from 'Actions/logger';

import Fetcher from 'Containers/Fetcher';
import Loader from 'Components/Loader';
import Modal from 'Components/Modal';

import { currentAccount } from 'Selectors';

import service from 'Services/customers';
import ImportCostumer from './ImportCustomer';

const mapStateToProps = state => ({
  account: currentAccount(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { error: logger.error, success: logger.success },
    dispatch,
  );

const EnhancedImportCostumer = ({
  account,
  depth,
  in: modalIn,
  order,
  onBack,
  onClose,
  ...props
}) => (
  <Modal
    depth={depth}
    in={modalIn}
    onBack={onBack}
    onClose={onClose}
    order={order}
    title="IMPORT_CUSTOMERS"
  >
    <Fetcher params={{ account }} query={service.getCustomerGroups}>
      {({ data: { ofaGroups: groups } = {}, loading }) => {
        return loading ? (
          <Loader />
        ) : (
          <ImportCostumer
            account={account}
            groups={groups}
            onClose={onClose}
            {...props}
          />
        );
      }}
    </Fetcher>
  </Modal>
);

EnhancedImportCostumer.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
  depth: PropTypes.number,
  history: PropTypes.object,
  in: PropTypes.bool,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  order: PropTypes.number,
  success: PropTypes.func,
};

export default compose(
  withStyles``,
  connect(mapStateToProps, mapDispatchToProps),
)(EnhancedImportCostumer);
