import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withStyles as css } from '@styled-components';

import * as logger from 'Actions/logger';

import Modal from 'Components/Modal';

import { currentAccount } from 'Selectors';

import service from 'Services/customers';

import CustomerForm from 'Containers/CustomerForm';

const mapStateToProps = state => ({
  account: currentAccount(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ success: logger.success }, dispatch);

export class AddCustomer extends Component {
  static propTypes = {
    account: PropTypes.number,
    className: PropTypes.string,
    depth: PropTypes.number,
    history: PropTypes.object,
    in: PropTypes.bool,
    onBack: PropTypes.func,
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    order: PropTypes.number,
    success: PropTypes.func,
  };

  static defaultProps = {
    onClose() {},
    success() {},
  };

  handleSubmit = async values => {
    const { account, history, success, onSuccess } = this.props;

    const {
      city,
      country,
      email,
      firstName,
      groups,
      lastName,
      notes,
      phone,
      postalCode,
      region,
      street1,
      street2,
    } = values;

    const customer = {
      deliveryAddress: {
        city,
        country,
        postalCode,
        region,
        street1,
        street2,
      },
      email,
      firstName,
      groups,
      lastName,
      notes,
      phone,
    };

    const { id } = await service.add({ account, customer });
    onSuccess();
    success('CUSTOMER_CREATED');

    history.push(`/customers/${id}`);
  };

  render() {
    const {
      account,
      depth,
      in: modalIn,
      order,
      onBack,
      className,
      onClose,
      ...props
    } = this.props;

    return (
      <Modal
        depth={depth}
        in={modalIn}
        onBack={onBack}
        onClose={onClose}
        order={order}
        title="ADD_NEW_CUSTOMER"
      >
        <CustomerForm {...props} onSubmit={this.handleSubmit} />
      </Modal>
    );
  }
}

export default compose(
  css``,
  connect(mapStateToProps, mapDispatchToProps),
)(AddCustomer);
