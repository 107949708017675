import React from 'react';
import PropTypes from 'prop-types';
import { withStyles as css } from '@styled-components';
import { compose } from 'underscore';

import { ContextMenu } from 'Components/ContextMenu';
import { withRouter } from 'Components/Router';
import withPermissions from 'Components/with/permissions';

import C from 'Constants';

const {
  ROLE: { ADMIN, SUPER_ADMIN },
} = C;

const Options = ({ className, history: { push } }) => (
  <ContextMenu
    right
    className={className}
    options={{
      ADD_NEW_CUSTOMER: () => push('/customers/add'),
      IMPORT_CUSTOMERS: () => push('/customers/import'),
    }}
  />
);

Options.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.shape({
    groupBy: PropTypes.arrayOf(PropTypes.string),
    orderBy: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
};

export default compose(
  css`
    margin-left: 2.4rem;
  `,
  withRouter,
  withPermissions([SUPER_ADMIN, ADMIN]),
)(Options);
