import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'spooner';
import styled, { withStyles as css, theme } from '@styled-components';
import { compose } from 'underscore';
import { v4 as uuid } from 'uuid';

const Circle = styled('div')``;
const InputHTML = styled('input')``;
const Label = styled('label')``;

const Radio = ({ className, label, ...props }) => {
  const prevId = useRef(uuid());
  const {
    content,
    id = prevId.current,
    name,
    onBlur = () => {},
    onChange = () => {},
    onFocus = () => {},
    value,
    ...fieldProps
  } = useField(props);

  const handleBlur = () => {
    onBlur();
  };

  const handleChange = event => {
    const { checked } = event.target;

    event.stopPropagation();

    onChange(checked ? content : value);
  };

  const handleFocus = () => {
    onFocus();
  };

  return (
    <div
      className={className}
      data-checked={content === value}
      data-name={name}
      data-value={value}
    >
      <InputHTML
        {...fieldProps}
        checked={content === value}
        id={id}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        type="radio"
      />
      <Circle />
      {label && (
        <Label capitalize translate htmlFor={id}>
          {label}
        </Label>
      )}
    </div>
  );
};

Radio.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
};

export default compose(
  css`
    align-items: center;
    display: inline-flex;
    position: relative;

    &[data-checked='true'] ${Circle} {
      background-color: ${theme('--color-primary')};
      border-color: ${theme('--color-primary')};

      &::after {
        opacity: 1;
      }
    }

    ${InputHTML} {
      height: 1.6rem;
      opacity: 0;
      position: absolute;
      width: 1.6rem;
    }

    ${Circle} {
      align-items: center;
      background-color: ${theme('--color-light')};
      border: 0.1rem solid ${theme('--color-dark-night-10')};
      border-radius: 50%;
      cursor: pointer;
      display: inline-flex;
      height: 1.6rem;
      justify-content: center;
      transition: background-color 250ms ease-out, border-color 250ms ease-out;
      width: 1.6rem;

      &::after {
        background-color: ${theme('--color-light')};
        border-radius: 50%;
        content: '';
        height: 0.6rem;
        opacity: 0;
        transition: opacity 250ms ease-out;
        width: 0.6rem;
      }
    }

    ${Label} {
      ${theme('--font-small')}
      ${theme('--font-weight-book')}
      color: ${theme('--color-dark-night-40')};
      margin-left: 0.8rem;
      vertical-align: middle;
    }
  `,
)(Radio);
