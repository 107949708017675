import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withStyles as css } from '@styled-components';
import { intlDefault, intlShape, withIntl } from '@react-intl';
import _ from 'underscore';

import { Select } from 'Components/Form';

class FiltersRatio extends Component {
  static propTypes = {
    className: PropTypes.string,
    intl: intlShape,
    onChange: PropTypes.func,
    value: PropTypes.string,
  };

  static defaultProps = {
    intl: intlDefault,
    onChange() {},
  };

  getOptions = _.memoize(() => {
    const { intl } = this.props;

    return [
      {
        disabled: true,
        name: intl.formatMessage({ capitalize: true, id: 'FILTER_BY' }),
      },
      {
        name: intl.formatMessage({ capitalize: true, id: 'ALL_RATIOS' }),
        value: 'ALL',
      },
      {
        name: intl.formatMessage({ capitalize: true, id: 'SQUARE' }),
        value: 'SQUARE',
      },
      {
        name: intl.formatMessage({ capitalize: true, id: 'PORTRAIT' }),
        value: 'PORTRAIT',
      },
    ];
  });

  handleChange = value => {
    const { onChange: handleChange } = this.props;
    if (value) {
      handleChange(value);
    }
  };

  render() {
    const { className, value } = this.props;

    const options = this.getOptions();
    const { name } =
      options.find(({ value: optionValue }) => optionValue === value) ||
      options[0];
    return (
      <Select
        className={className}
        name={name}
        onChange={this.handleChange}
        options={options}
        value={value}
      />
    );
  }
}

export default compose(
  css`
    min-width: 20rem;
  `,
  withIntl,
)(FiltersRatio);
