import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from '@styled-components';
import { useHistory, useLocation } from 'react-router-dom';

import Table from 'Components/ResponsiveTable';

import Header from './Header';
import DesktopTemplate from './DesktopTemplate';
import Template from './Template';

const Campaigns = ({ className, currency, revenues = {}, ...props }) => {
  const { push } = useHistory();
  const { pathname } = useLocation();

  const handleClick = useCallback(
    id => () => {
      push(`${pathname}/campaigns/${id}`);
    },
    [pathname, push],
  );

  const campaigns = Object.entries(revenues).map(([name, values]) => ({
    campaign: name,
    currency,
    ...values,
  }));

  return (
    <Table
      {...props}
      className={className}
      desktopRows={5}
      desktopTemplate={DesktopTemplate}
      header={Header}
      items={campaigns}
      options={{ onClick: handleClick }}
      rows={4}
      template={Template}
    />
  );
};

Campaigns.propTypes = {
  className: PropTypes.string,
  currency: PropTypes.string,
  revenues: PropTypes.object,
};

export default styled(Campaigns)``;
