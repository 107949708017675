import React, { forwardRef, memo } from 'react';
import PropTypes from '@prop-types';
import { FormattedRelativeTime as RelativeTime } from 'react-intl';

const DEFAULT_THRESHOLDS = {
  second: 45, // seconds to minute
  minute: 45, // minutes to hour
  hour: 22, // hours to day
  day: 5, // days to week
  week: 4, // weeks to month
  month: 12, // months to year
};
const MS_PER_SECOND = 1e3;
const SECS_PER_MIN = 60;
const SECS_PER_HOUR = SECS_PER_MIN * 60;
const SECS_PER_DAY = SECS_PER_HOUR * 24;
const SECS_PER_WEEK = SECS_PER_DAY * 7;

function selectUnit(from, to = Date.now(), thresholds = {}) {
  const resolvedThresholds = {
    ...DEFAULT_THRESHOLDS,
    ...(thresholds || {}),
  };

  const secs = (+from - +to) / MS_PER_SECOND;
  if (Math.abs(secs) < resolvedThresholds.second) {
    return {
      value: Math.round(secs),
      unit: 'second',
    };
  }

  const mins = secs / SECS_PER_MIN;
  if (Math.abs(mins) < resolvedThresholds.minute) {
    return {
      value: Math.round(mins),
      unit: 'minute',
    };
  }

  const hours = secs / SECS_PER_HOUR;
  if (Math.abs(hours) < resolvedThresholds.hour) {
    return {
      value: Math.round(hours),
      unit: 'hour',
    };
  }

  const days = secs / SECS_PER_DAY;
  if (Math.abs(days) < resolvedThresholds.day) {
    return {
      value: Math.round(days),
      unit: 'day',
    };
  }

  const weeks = secs / SECS_PER_WEEK;
  if (Math.abs(weeks) < resolvedThresholds.week) {
    return {
      value: Math.round(weeks),
      unit: 'week',
    };
  }

  const fromDate = new Date(from);
  const toDate = new Date(to);

  const years = fromDate.getFullYear() - toDate.getFullYear();

  const months = years * 12 + fromDate.getMonth() - toDate.getMonth();
  if (Math.abs(months) < resolvedThresholds.month) {
    return {
      value: Math.round(months),
      unit: 'month',
    };
  }

  return {
    value: Math.round(years),
    unit: 'year',
  };
}

const FormattedRelativeTime = ({
  component: Wrapper = 'span',
  forwardedRef,
  unit,
  value,
  ...props
}) => {
  const format = selectUnit(value, new Date());

  return (
    <RelativeTime {...props} {...format}>
      {text => (
        <Wrapper {...props} ref={forwardedRef}>
          {text}
        </Wrapper>
      )}
    </RelativeTime>
  );
};

FormattedRelativeTime.propTypes = {
  component: PropTypes.component,
  forwardedRef: PropTypes.ref,
  unit: PropTypes.string,
  value: PropTypes.any,
};

export default memo(
  // eslint-disable-next-line react/display-name
  forwardRef((props, forwardedRef) => (
    <FormattedRelativeTime {...props} forwardedRef={forwardedRef} />
  )),
);
