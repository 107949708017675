import React from 'react';
import PropTypes from '@prop-types';
import { FormattedMessage } from '@react-intl';
import styled, { theme } from '@styled-components';
import { useValue } from 'spooner';

import { Heading } from './Common';

import TagAutoSuggest from './TagAutoSuggest';
import Chip from 'Components/Chip';

const Wrapper = styled('div')``;

const renderContent = (role, value, account, locale) => {
  switch (role) {
    case 'READER':
      return (
        <Wrapper>
          {value.map(({ name }) => (
            <Chip key={name}>{name}</Chip>
          ))}
        </Wrapper>
      );
    default:
      return (
        <TagAutoSuggest
          allowCreate
          account={account}
          locale={locale}
          placeholder="ACTIONS.CREATE_A_TAG"
        />
      );
  }
};

const Tags = ({ role, account, locale, className }) => {
  const { value } = useValue('tags');

  return (
    <div className={className}>
      <FormattedMessage capitalize component={Heading} id="TAGS">
        Answer
      </FormattedMessage>
      {renderContent(role, value, account, locale)}
    </div>
  );
};

Tags.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
  locale: PropTypes.string,
  role: PropTypes.string,
};

export default styled(Tags)`
  display: flex;
  flex-direction: column;
  position: relative;

  ${Heading} {
    margin-bottom: 0.8rem;
  }

  ${Wrapper} {
    ${Chip} {
      background: #eaccff;
      border: none;
      border-radius: 0.6rem;
      color: #9500ff;
      margin: 0 0.8rem 0.8rem 0;
    }
  }

  @media (${theme('--screen-small')}) {
    ${TagAutoSuggest} {
      min-width: 38rem;
    }
  }
`;
