import React, { useState } from 'react';
import PropTypes from '@prop-types';

import DataProvider from 'Containers/DataProvider';

import Modal from 'Components/Modal';

import services from 'Services/integrations';

import { currentAccount } from 'Selectors';

import { connect } from 'react-redux';

import View from './EditIntegration';
import { capitalize } from '@react-intl';

const toSnakeCase = string => {
  return string
    .split('-')
    .join('_')
    .toUpperCase();
};

const EditIntegration = ({
  account,
  id,
  depth,
  in: modalIn,
  onBack,
  onClose,
  order,
  category,
  service,
}) => {
  const [displayName, setDisplayName] = useState(service);

  return (
    <Modal
      depth={depth}
      in={modalIn}
      onBack={onBack}
      onClose={onClose}
      order={order}
      title={capitalize(displayName)}
    >
      <DataProvider
        onClose={onClose}
        params={{
          account,
          category: toSnakeCase(category),
          id,
          service: toSnakeCase(service),
        }}
        query={services.getById}
        setDisplayName={setDisplayName}
        template={View}
      />
    </Modal>
  );
};

EditIntegration.propTypes = {
  account: PropTypes.string,
  category: PropTypes.string,
  depth: PropTypes.number,
  id: PropTypes.string,
  in: PropTypes.bool,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  order: PropTypes.number,
  service: PropTypes.string,
};

const mapStateToProps = state => ({
  account: currentAccount(state),
});

export default connect(mapStateToProps)(EditIntegration);
