import React, { useCallback } from 'react';
import PropTypes from '@prop-types';

import DataProvider from 'Containers/DataProvider/index';

import { useMultiDataProviderContext } from 'Containers/MultiDataProvider';

import Services from 'Services/user-generated-content';

import Dialog from 'Components/Dialog';

import * as logger from 'Actions/logger';

import View from './EditUserGeneratedContent';

import { currentAccount } from 'Selectors';

import { connect } from 'react-redux';

const EditUserGeneratedContent = ({
  className,
  account,
  in: modalIn,
  onClose,
  id,
}) => {
  const { refetch } = useMultiDataProviderContext('settings');

  const handleSubmit = useCallback(
    async data => {
      await Services.update({ account, data, id });
      refetch();
      onClose();
    },
    [account, refetch, onClose, id],
  );

  return (
    <Dialog
      className={className}
      in={modalIn}
      onDismiss={onClose}
      title="UPLOAD_MEDIA"
    >
      <DataProvider
        onClose={onClose}
        onSubmit={handleSubmit}
        params={{ account, id }}
        query={Services.get}
        template={View}
      />
    </Dialog>
  );
};

EditUserGeneratedContent.propTypes = {
  account: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  in: PropTypes.bool,
  onClose: PropTypes.func,
};

const mapStateToProps = state => ({
  account: currentAccount(state),
});

const mapDispatchToProps = { error: logger.error, success: logger.success };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditUserGeneratedContent);
