import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@styled-components';

import DataProvider from 'Containers/DataProvider';

import { currency as currentCurrency, currentAccount } from 'Selectors';

import service from 'Services/revenues';

import View from './DashboardRevenues';

const mapStateToProps = state => ({
  account: currentAccount(state),
  currency: currentCurrency(state),
});

const Revenues = ({ account, className, currency }) => (
  <DataProvider
    className={className}
    params={{ account, currency }}
    query={service.get}
    template={({ data }) => (
      <View className={className} currency={currency} revenues={data} />
    )}
  />
);

Revenues.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
  currency: PropTypes.string,
};

export default compose(connect(mapStateToProps), withStyles``)(Revenues);
