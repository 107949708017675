import C from 'Constants';
import request from 'Providers/request';

const { ERROR } = C;

async function login(email, password) {
  try {
    const { authKey, id } = await request.create(
      '/login_email',
      {},
      {},
      { headers: { email, password } },
    );

    if (!authKey || !id) {
      return Promise.reject(ERROR.UNAUTHORIZED);
    }
    return { authKey, id };
  } catch (error) {
    throw new Error('AUTHENTICATION_FAILURE');
  }
}

function recoverPassword(email) {
  return request.create('/forgotten_password', {}, {}, { headers: { email } });
}

function checkResetPassword(code, email) {
  return request.create(
    '/check_password_reset',
    {},
    {},
    {
      headers: {
        code,
        email,
      },
    },
  );
}

function resetPassword(email, newPassword, resetId, password) {
  return request.create(
    '/reset_password',
    {},
    {},
    {
      headers: {
        email,
        newPassword,
        password,
        resetId,
      },
    },
  );
}

function logout(id) {
  return request.create('/logout', {}, {}, { headers: { id } });
}

export default {
  checkResetPassword,
  login,
  logout,
  recoverPassword,
  resetPassword,
};
