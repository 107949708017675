import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@styled-components';

import TableDataProvider from 'Containers/TableDataProvider';

import { currentAccount } from 'Selectors';

import service from 'Services/customers';

import Template from './Surveys';

const mapStateToProps = state => ({
  account: currentAccount(state),
});

const Surveys = ({ account, customerId, ...props }) => {
  if (!customerId) return false;

  return (
    <TableDataProvider
      customerId={customerId}
      limit={5}
      name="customerSurveys"
      params={{ account, customerId }}
      query={service.surveys}
      template={Template}
      {...props}
    />
  );
};

Surveys.propTypes = {
  account: PropTypes.number,
  customerId: PropTypes.number,
};

export default compose(connect(mapStateToProps), withStyles``)(Surveys);
