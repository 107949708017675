import React from 'react';
import PropTypes from '@prop-types';
import { FormattedMessage } from '@react-intl';
import styled, { theme } from '@styled-components';
import { useValue } from 'spooner';

import { Textarea, Fieldset, Select } from 'Components/Form';

import { Heading, Card } from './Common';

const Paragraph = styled('p')``;

const selectOptions = [
  { name: 'Text', value: 'TEXT' },
  { name: 'Command', value: 'CMD' },
];

const renderContent = (role, content, description, type) => {
  const isCmd = type === 'CMD';

  switch (role) {
    case 'READER':
      return (
        <Fieldset name="answer">
          <FormattedMessage capitalize component={Heading} id="ANSWER">
            Answer
          </FormattedMessage>
          <Paragraph>{isCmd ? description : content}</Paragraph>
        </Fieldset>
      );
    case 'SUPER_ADMIN':
      return (
        <Fieldset name="answer">
          <FormattedMessage capitalize component={Heading} id="ANSWER">
            Answer
          </FormattedMessage>
          <Card>
            <Select name="type" options={selectOptions} />
            <Textarea
              autoresize
              capitalize
              label={isCmd ? 'COMMAND' : 'ANSWER'}
              name="content"
            />
            {isCmd && (
              <Textarea
                autoresize
                capitalize
                label="DESCRIPTION"
                name="description"
              />
            )}
          </Card>
        </Fieldset>
      );
    default:
      return type === 'CMD' ? (
        <Fieldset name="answer">
          <FormattedMessage capitalize component={Heading} id="DESCRIPTION">
            Description
          </FormattedMessage>
          <Paragraph>{description}</Paragraph>
        </Fieldset>
      ) : (
        <Fieldset name="answer">
          <FormattedMessage capitalize component={Heading} id="ANSWER">
            Answer
          </FormattedMessage>
          <Textarea autoresize capitalize label="ANSWER" name="content" />
        </Fieldset>
      );
  }
};

const Questions = ({ className, role }) => {
  const { value: { content, description, type } = {} } = useValue('answer');

  return (
    <div className={className}>
      {renderContent(role, content, description, type)}
    </div>
  );
};

Questions.propTypes = {
  className: PropTypes.string,
  role: PropTypes.string,
};

export default styled(Questions)`
  display: flex;
  flex-direction: column;
  position: relative;

  ${Paragraph} {
    ${theme('--font-medium')}
    ${theme('--font-weight-book')}
    ${theme('--font-opacity-80')}
  }

  ${Heading} {
    &:nth-of-type(2) {
      margin-top: 2.4rem;
    }
  }

  ${Card} {
    padding: 1.6rem;

    ${Select} {
      margin-bottom: 0.8rem;
      width: 16rem;
    }

    ${Textarea} {
      &:nth-child(3) {
        margin-top: 1.2rem;
      }
    }
  }
`;
