import React from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@react-intl';

import Confirm, { Actions } from 'Components/Confirm';

const Paragraph = styled('p')``;

const DeleteCampaign = ({
  className,
  onAccept,
  onCancel,
  in: isIn,
  question,
}) => (
  <Confirm
    acceptId="ACTIONS.UPDATE"
    className={className}
    in={isIn}
    onAccept={onAccept}
    onCancel={onCancel}
    title="UPDATE_ORDER_STATUS"
  >
    <FormattedMessage
      capitalize
      component={Paragraph}
      id={`QUESTIONS.MESSAGE_${question}`}
    />
  </Confirm>
);

DeleteCampaign.propTypes = {
  className: PropTypes.string,
  in: PropTypes.bool,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
  question: PropTypes.string,
};

export default styled(DeleteCampaign)`
  ${Actions} {
    & button:first-child {
      background: #1A1A1A;
      color: #ffffff;
      justify-self: right;
    }

    & button:last-child {
      background-color: #ffffff;
      border: solid 0.1rem #000000;
      color: #1A1A1A;
      justify-self: right;
    }
  }

  ${Paragraph} {
    ${theme('--font-medium')}
    ${theme('--font-weight-book')}
    color: ${theme('--color-dark-night-60')};
  }
`;
