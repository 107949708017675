import React, { useEffect, useState } from 'react';
import styled, { theme, css } from '@styled-components';
import PropTypes from '@prop-types';
import { FormattedMessage } from '@react-intl';

import service from 'Services/campaigns';

import { File } from 'Components/Form';

const Video = styled('video')``;

const Preview = styled('div')`
  ${props =>
    props.image &&
    css`
      background-image: url(${props.image});
    `}
`;
const Wrapper = styled('section')``;
const Title = styled('h4')``;
const Instructions = styled('p')``;
const MessageBox = styled('div')``;
const ValidationMessage = styled('div')`
  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `}
`;
const Loader = styled('div')``;

const MediaUploader = ({
  className,
  data = null,
  fileName,
  mediaType,
  onChange,
}) => {
  const [preview, setPreview] = useState(null);
  const [video, setVideo] = useState(null);
  const [validationMessage, setValidationMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    setIsHidden(false);

    if (data && data.name) {
      const fileReader = new FileReader();
      if (data.type.match('image')) {
        setVideo(null);
        fileReader.onload = () => setPreview(fileReader.result);
      } else {
        setPreview(null);
        fileReader.onload = () => setVideo(fileReader.result);
      }
      fileReader.readAsDataURL(data);
    } else {
      setPreview(null);
      setVideo(null);
      setValidationMessage('');
    }
  }, [data]);

  const handleChange = async file => {
    setIsHidden(false);
    const MAX_FILE_SIZE = 15728640; // 15 MB

    if (
      file !== undefined &&
      file.type
        .split('/')
        .shift()
        .toLowerCase() === mediaType.toLowerCase()
    ) {
      if (file.size < MAX_FILE_SIZE) {
        setIsLoading(true);
        const fileReader = new FileReader();
        if (fileName && fileName.length > 1)
          await service.deleteMedia(fileName);

        if (file.type.match('image')) {
          setVideo(null);
          fileReader.onload = () => setPreview(fileReader.result);
        } else {
          setPreview(null);
          fileReader.onload = () => setVideo(fileReader.result);
        }
        fileReader.readAsDataURL(file);

        await service.upload({ file }).then(({ mediaUrl }) => {
          setValidationMessage('Upload successful');
          setIsLoading(false);
          setIsSuccess(true);
          onChange({ file, fileName: mediaUrl.split('/').pop(), mediaUrl });
        });
      } else {
        setValidationMessage(
          'The file is too large. Allowed maximum size is 15 MB.',
        );
        setIsSuccess(false);
      }
    } else {
      setValidationMessage(
        `You can only upload ${mediaType.toLowerCase()} file.`,
      );
      setIsSuccess(false);
    }
    setTimeout(() => {
      setIsHidden(true);
    }, 3000);
  };

  let fileFormat = '';
  if (mediaType === 'IMAGE') {
    fileFormat = 'PNG or JPG';
  } else if (mediaType === 'VIDEO') {
    fileFormat = 'MP4';
  }

  return (
    <article className={className}>
      <Preview image={preview}>
        {video && <Video autoPlay loop muted src={video} />}
      </Preview>
      <Wrapper>
        <FormattedMessage
          capitalize
          component={Title}
          id="SELECT_CAMPAIGN_IMAGE"
          values={{ file: mediaType.toLowerCase() }}
        />
        <FormattedMessage
          capitalize
          component={Instructions}
          id="INSTRUCTIONS.FILE_FORMAT"
          values={{ fileFormat }}
        />

        <File
          accept={`${mediaType.toLowerCase()}/*`}
          name="file"
          onChange={handleChange}
        />
        <MessageBox>
          {isLoading ? (
            <div style={{ display: 'flex' }}>
              <Loader />
              <div className="uploadingText">Uploading...</div>
            </div>
          ) : (
            <ValidationMessage
              className={isHidden && 'hidden'}
              color={isSuccess ? 'green' : 'red'}
            >
              {validationMessage}
            </ValidationMessage>
          )}
        </MessageBox>
      </Wrapper>
    </article>
  );
};

MediaUploader.propTypes = {
  className: PropTypes.string,
  data: PropTypes.any,
  fileName: PropTypes.string,
  mediaType: PropTypes.string,
  onChange: PropTypes.func,
};

export default styled(MediaUploader)`
  display: grid;
  grid-gap: 3.2rem;
  grid-template: 10rem / 10rem auto;
  height: 18rem;
  padding: 1.5rem 0;

  ${Preview} {
    background-color: ${theme('--color-primary-10')};
    background-size: cover;
    border: 0.2rem solid ${theme('--color-light')};
    box-shadow: 0 0.2rem 0.8rem rgba(0, 15, 25, 0.05);
    overflow: hidden;

    ${Video} {
      height: 100%;
      width: 100%;
      z-index: 100;
    }
  }

  ${Wrapper} {
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${Title} {
      ${theme('--font-medium')}
      ${theme('--font-weight-medium')}
      margin-bottom: 2rem;
    }

    ${Instructions} {
      ${theme('--font-small')}
      ${theme('--font-weight-book')}
      color: ${theme('--color-dark-night-60')};
      margin-bottom: 0.8rem;
    }

    ${File} {
      ${theme('--font-medium')}
      ${theme('--font-weight-medium')}
      background: ${theme('--color-light')};
      border: 0.1rem solid ${theme('--color-primary')};
      border-radius: 0.6rem;
      color: ${theme('--color-primary')};
      cursor: pointer;
      padding: 1.2rem;
      text-align: center;
      width: 14.4rem;
    }

    ${MessageBox} {
      margin-top: auto;

      ${Loader} {
        -webkit-animation: spin 2s linear infinite; /* Safari */
        animation: spin 2s linear infinite;
        border: 3px solid #f3f3f3;
        border-radius: 50%;
        border-top: 3px solid #005dff;
        display: inline-block;
        height: 20px;
        width: 20px;
      }

      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }

      .uploadingText {
        color: #666f75;
        margin: auto 0 auto 5px;
      }

      .hidden {
        opacity: 0;
        transition: all 500ms linear 2s;
      }
    }
  }

  @media (${theme('--screen-small')}) {
    grid-template: 14.4rem / 14.4rem auto;
  }
`;
