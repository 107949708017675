import React from 'react';
import styled, { css, theme } from '@styled-components';
import PropTypes from 'prop-types';

const Title = styled('div')``;
const Description = styled('div')``;
const Video = styled('video')``;
const Preview = styled('div')`
  ${props =>
    props.image &&
    css`
      background-image: url(${props.image});
    `}
`;

const Header = ({ className, components, name, onClick }) => {
  const description = [];
  const image = [];
  components.forEach(data => {
    if (data.type === 'BODY') description.push(data.text);
    else if (
      data.type === 'HEADER' &&
      ['IMAGE', 'VIDEO'].includes(data.format) &&
      data.example &&
      data.example.header_handle
    )
      image.push(data.example.header_handle[0]);
  });
  return (
    /* eslint-disable-next-line */
    <div className={className} onClick={onClick}>
      <Title>{name}</Title>
      <Description>
        {image.length > 0 && (
          <Preview image={image}>
            {image && <Video autoPlay loop muted src={image} />}
          </Preview>
        )}
        <div>{description}</div>
      </Description>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  components: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default styled(Header)`
  align-items: center;
  box-shadow: inset 0px -1px 0px rgba(0, 15, 25, 0.03);
  color: #666f75;
  cursor: pointer;
  display: grid;
  font-size: 1.4rem;
  grid-template-columns: 1fr;
  line-height: 2.4rem;
  padding: 1rem;
  user-select: none;

  &:hover {
    background: #f2f7ff;
  }

  ${Title} {
    font-weight: bold;
    margin-bottom: 1rem;
  }

  ${Description} {
    display: grid;
    grid-template-columns: auto 5fr;
    justify-content: space-between;

    ${Preview} {
      background-color: ${theme('--color-primary-10')};
      background-size: cover;
      border: 0.2rem solid ${theme('--color-light')};
      box-shadow: 0 0.2rem 0.8rem rgba(0, 15, 25, 0.05);
      height: 75px;
      margin-right: 1rem;
      overflow: hidden;
      width: 75px;

      ${Video} {
        height: 100%;
        width: 100%;
        z-index: 100;
      }
    }
  }
`;
