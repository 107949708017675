import React, { useEffect, useRef, useState } from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';
import { FormattedMessage } from '@react-intl';

import Card from 'Components/Card';

const Button = styled('button')``;
const FileInput = styled('input')``;
const Image = styled('img')``;
const Title = styled('h3')``;

const Receipt = ({ className, handleOrderUpdate, imageUrl }) => {
  const fileInputRef = useRef(null);
  const [state, setState] = useState({ image: null, loading: false });

  const handleImageUpload = async ({ target: { files } }) => {
    const fileReader = new FileReader();

    setState({ ...state, loading: true });

    fileReader.onloadend = event => {
      setState({ image: event.target.result, loading: false });
    };

    fileReader.readAsDataURL(files[0]);

    await handleOrderUpdate({ filedata: files[0] });
  };

  const handleUploadClick = async () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (!state.image && imageUrl) {
      setState({ ...state, image: imageUrl });
    }
  }, [imageUrl, state]);

  return (
    <Card className={className}>
      <FormattedMessage component={Title} id="BANK_TRANSFER_RECEIPT" />
      <Button capitalize secondary translate onClick={handleUploadClick}>
        ACTIONS.UPLOAD_NEW_FILE
      </Button>
      <FileInput ref={fileInputRef} onChange={handleImageUpload} type="file" />
      {state.image && <Image src={state.image} />}
    </Card>
  );
};

Receipt.propTypes = {
  className: PropTypes.string,
  handleOrderUpdate: PropTypes.func,
  imageUrl: PropTypes.string,
};

export default styled(Receipt)`
  ${Button} {
    background-color: #ffffff;
    border: solid 0.1rem #000000;
    border-radius: 0.4rem;
    color: #1a1a1a;
    cursor: pointer;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.7rem;
    padding: 1.2rem 1.4rem;
  }

  ${FileInput} {
    visibility: hidden;
  }

  ${Image} {
    margin-top: 1.2rem;
    max-width: 100%;
  }

  ${Title} {
    color: #000f19;
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 2.9rem;
    margin-bottom: 2.4rem;
  }
`;
