import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import styled, { theme } from '@styled-components';

import Button from 'Components/Button';

import SectionHeadline from './SectionHeadline';

const Actions = styled('div')``;
const Option = styled('div')``;
const TextBlock = styled('div')``;

const Type = ({ className, onChange, onNext, type = 'SMS' }) => {
  useEffect(() => {
    onChange({ type });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const handleChange = value => () => onChange({ type: value });

  return (
    <div className={className}>
      <SectionHeadline
        heading="INSTRUCTIONS.CREATE_CAMPAIGN.STEP_2"
        title="INSTRUCTIONS.SELECT_CAMPAIGN_TYPE"
      />
      <Option
        className={type === 'SMS' ? 'active' : ''}
        onClick={handleChange('SMS')}
      >
        <TextBlock>SMS</TextBlock>
        <TextBlock>
          An SMS campaign lets you send an SMS to the customers targeted with a
          custom text and a deeplink so on click on that link customer will be
          redirected to the bot with the specific conversation selected.
        </TextBlock>
      </Option>
      <Option
        className={type === 'WHATSAPP' ? 'active' : ''}
        onClick={handleChange('WHATSAPP')}
      >
        <TextBlock>WhatsApp</TextBlock>
        <TextBlock>
          Send Whatsapp messages to targeted customers using one of your
          approved message templates to redirect them to the bot with the
          specific conversation selected.
        </TextBlock>
      </Option>
      <Actions>
        <Button capitalize primary translate onClick={onNext}>
          ACTIONS.NEXT
        </Button>
      </Actions>
    </div>
  );
};

Type.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  type: PropTypes.string,
};

export default styled(Type)`
  ${Actions} {
    margin-top: 4rem;

    ${Button} {
      width: 14.4rem;
    }
  }

  ${Option} {
    background-color: ${theme('--color-light')};
    border: solid 1px transparent;
    border-radius: 0.6rem;
    cursor: pointer;
    margin-top: 2.4rem;
    padding: 2rem 2.4rem 2.4rem;

    &.active {
      border-color: ${theme('--color-primary')};

      ${TextBlock}:first-child {
        color: ${theme('--color-primary')};
      }
    }

    & ${TextBlock} {
      color: ${theme('--color-dark-night-60')};

      &:last-child {
        margin-top: 0.8rem;
      }
    }
  }
`;
