import React, { useReducer, useEffect, useCallback } from 'react';
import styled from '@styled-components';
import PropTypes from '@prop-types';
import { Redirect, useLocation, useHistory } from 'react-router-dom';

import Overlay from 'Components/Overlay';

import AddBrandContent from 'Modals/AddBrandContent';
import AddCampaign from 'Modals/AddCampaign';
import AddCustumer from 'Modals/AddCustomer';
import AddFaq from 'Modals/AddFaq';
import AddKnowledgeBase from 'Modals/AddKnowledgeBase';
import AddMicroapp from 'Modals/AddMicroapp';
import AddMultiproduct from 'Modals/AddMultiproduct';
import AddMicroappMedia from 'Modals/AddMicroappMedia';
import AddUserGeneratedContent from 'Modals/AddUserGeneratedContent';
import AddUser from 'Modals/AddUser';
import AddSignup from 'Modals/AddSignup';
import Answers from 'Modals/Answers';
import Campaign from 'Modals/Campaign';
import Company from 'Modals/Company';
import Customer from 'Modals/Customer';
import EditCampaign from 'Modals/EditCampaign';
import EditCustomer from 'Modals/EditCustomer';
import EditSignup from 'Modals/EditSignup';
import EditMicroappMedia from 'Modals/EditMicroappMedia';
import EditUserGeneratedContent from 'Modals/EditUserGeneratedContent';
import Faq from 'Modals/Faq';
import Gallery from 'Modals/Gallery';
import ImportCostumer from 'Modals/ImportCustomer';
import ImportFaq from 'Modals/ImportFaq';
import Product from 'Modals/Product';
import Settings from 'Modals/Settings';
import Survey from 'Modals/Survey';
import EditUser from 'Modals/EditUser';
import EditMultiproduct from 'Modals/EditMultiproduct';
import EditIntegration from 'Modals/EditIntegration';

import { reducer, SET_PATHNAME, FLUSH, POP, getSteps, RELOAD } from './utils';

const modals = {
  addbrandcontent: AddBrandContent,
  addcampaign: AddCampaign,
  addcustomers: AddCustumer,
  addfaqstudio: AddFaq,
  addknowledgebase: AddKnowledgeBase,
  addmedia: AddMicroappMedia,
  addmicroapp: AddMicroapp,
  addmultiproducts: AddMultiproduct,
  addsignup: AddSignup,
  addusergeneratedcontent: AddUserGeneratedContent,
  addusers: AddUser,
  answers: Answers,
  campaigns: Campaign,
  company: Company,
  customers: Customer,
  editcampaigns: EditCampaign,
  editcustomers: EditCustomer,
  editmedia: EditMicroappMedia,
  editmultiproducts: EditMultiproduct,
  editsignup: EditSignup,
  editusergeneratedcontent: EditUserGeneratedContent,
  faqstudio: Faq,
  gallery: Gallery,
  importcustomers: ImportCostumer,
  importfaqstudio: ImportFaq,
  integrations: EditIntegration,
  products: Product,
  settings: Settings,
  surveys: Survey,
  users: EditUser,
};

const waitForAnimation = cb => setTimeout(cb, 350);

const Multimodal = ({ className }) => {
  const [state, dispatch] = useReducer(reducer, []);
  const { pathname } = useLocation();
  const { basePath } = getSteps(pathname);
  const { push } = useHistory();

  useEffect(() => {
    dispatch({ type: SET_PATHNAME, url: pathname });
  }, [dispatch, pathname]);

  const onDismiss = useCallback(() => {
    push(basePath);
    waitForAnimation(() => dispatch({ type: FLUSH }));
  }, [basePath, push]);

  const onBack = useCallback(
    previousRoute => {
      push(previousRoute);
      waitForAnimation(() => dispatch({ type: POP }));
    },
    [push],
  );

  const onReload = useCallback(target => {
    dispatch({ target, type: RELOAD });
  }, []);

  const overlayIn =
    state.length > 0 && state.some(({ in: modalIn }) => modalIn);

  return (
    <div className={className}>
      <Overlay in={overlayIn} onDismiss={onDismiss}>
        {state.map(
          ({
            step,
            id,
            key,
            dataKey,
            in: modalIn,
            depth,
            order,
            previous,
            previousId,
            service,
            category,
          }) => {
            const Component = modals[step];

            if (!Component) {
              return <Redirect to="/oops" />;
            }

            return (
              <Component
                key={key}
                category={category}
                dataKey={dataKey}
                depth={depth}
                id={id}
                in={modalIn}
                onBack={order > 0 ? () => onBack(previous) : onDismiss}
                onClose={onDismiss}
                onReload={onReload}
                order={order}
                previous={previous}
                previousId={previousId}
                service={service}
              />
            );
          },
        )}
      </Overlay>
    </div>
  );
};

Multimodal.propTypes = {
  className: PropTypes.string,
};

export default styled(Multimodal)``;
