import React from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from '@prop-types';

import { BUBBLE_WIDTH } from './constants';
import { getStyles } from './utils';

export { default as Arrow } from './Arrow';
export { BUBBLE_WIDTH, ARROW_SIZE } from './constants';

const Wrapper = styled('div')``;

const Bubble = ({ className, children, x, y, anchor, tipSize, ...props }) => (
  <article
    className={className}
    data-anchor={anchor}
    style={getStyles(anchor, x, y, tipSize)}
    {...props}
  >
    <Wrapper>{children}</Wrapper>
  </article>
);

Bubble.propTypes = {
  anchor: PropTypes.string,
  children: PropTypes.children,
  className: PropTypes.string,
  style: PropTypes.object,
  tipSize: PropTypes.number,
  x: PropTypes.number,
  y: PropTypes.number,
};

const widthInRems = BUBBLE_WIDTH / 10;

export default styled(Bubble)`
  background: ${theme('--color-dark-night-60')};
  border-radius: 0.6rem;
  color: #cccfd1;
  opacity: 0;
  position: fixed;
  text-align: left;
  transition: opacity 350ms, width 350ms;
  z-index: 1000;

  ${Wrapper} {
    max-width: 100%;
    overflow: hidden;
    padding: 2.4rem;
    transition: max-height 350ms, padding 350ms;

    > * {
      width: calc(${widthInRems}rem - 2 * 2.4rem);
    }
  }

  &[data-anchor='left-top'],
  &[data-anchor='right-top'],
  &[data-anchor='left-bottom'],
  &[data-anchor='right-bottom'] {
    width: 0;

    ${Wrapper} {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &[data-anchor='top'],
  &[data-anchor='bottom'] {
    ${Wrapper} {
      max-height: 0;
      padding-bottom: 0;
      padding-top: 0;
    }
  }

  &[data-visible='true'] {
    opacity: 1;
    width: ${BUBBLE_WIDTH / 10}rem;

    ${Wrapper} {
      max-height: 1000rem;
      padding: 2.4rem;
    }
  }
`;
