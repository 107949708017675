import React, { forwardRef, useMemo } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import PropTypes from '@prop-types';
import { useIntl as hook } from 'react-intl';

import FormattedMessage from './FormattedMessage';
import transform, { capitalize, uppercase } from './transform';

const intlShape = PropTypes.object.isRequired;
const intlDefault = {};

function useIntl() {
  const intl = hook();
  const { formatMessage } = intl;

  return useMemo(() => {
    return {
      ...intl,
      formatMessage({ capitalize, uppercase, ...options } = {}, values = {}) {
        const message = formatMessage(options, values);

        return transform(message, { capitalize, uppercase });
      },
    };
  }, [intl]);
}

function withIntl(WrappedComponent) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name;

  const Intl = ({ ...props }) => {
    const intl = useIntl();

    return <WrappedComponent intl={intl} {...props} />;
  };

  Intl.displayName = `withIntl(${displayName})`;

  hoistNonReactStatics(Intl, WrappedComponent);

  return Intl;
}

function withTranslation(WrappedComponent) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name;

  const Translatable = ({ children, forwardedRef, translate, ...props }) => {
    return translate && children ? (
      <FormattedMessage
        {...props}
        ref={forwardedRef}
        component={WrappedComponent}
        id={Array.isArray(children) ? children.join('') : children} // eslint-disable-line
      />
    ) : (
      <WrappedComponent {...props} ref={forwardedRef}>
        {children}
      </WrappedComponent>
    );
  };

  Translatable.displayName = `withTranslation(${displayName})`;

  Translatable.propTypes = {
    children: PropTypes.children,
    forwardedRef: PropTypes.ref,
    translate: PropTypes.bool,
  };

  // eslint-disable-next-line react/display-name
  const ForwardedTranslatable = forwardRef((props, forwardedRef) => (
    <Translatable {...props} forwardedRef={forwardedRef} />
  ));

  hoistNonReactStatics(ForwardedTranslatable, WrappedComponent);

  // eslint-disable-next-line react/display-name
  return ForwardedTranslatable;
}

export { IntlProvider } from 'react-intl';

export { default as FormattedCurrency } from './FormattedCurrency';
export { default as FormattedDate } from './FormattedDate';
export { default as FormattedNumber } from './FormattedNumber';
export { default as FormattedPlural } from './FormattedPlural';
// @deprecated
export { default as FormattedRelative } from './FormattedRelativeTime';
export { default as FormattedRelativeTime } from './FormattedRelativeTime';
export { default as FormattedTime } from './FormattedTime';

export {
  // @deprecated
  capitalize,
  FormattedMessage,
  intlDefault,
  intlShape,
  // @deprecated
  uppercase,
  useIntl,
  withIntl,
  withIntl as injectIntl,
  withTranslation,
};
