import React from 'react';
import styled from '@styled-components';
import PropTypes from '@prop-types';
import { Row, Column, SortableColumn } from 'Components/ResponsiveTable';

const Header = ({ className, onSortBy, sortBy, order }) => {
  const common = { onSortBy, order, sortBy };

  return (
    <Row className={className}>
      <SortableColumn field="firstName" id="CUSTOMER" {...common} />
      <SortableColumn field="location" id="TIMEZONE_AND_LOCATION" {...common} />
      <SortableColumn field="locale" id="LOCALE" {...common} />
      <SortableColumn field="createdAt" id="JOINED" {...common} />
      <Column id="LAST_ACTIVITY" />
      <Column id="PLATFORMS" />
      <Column id="GROUPS" />
    </Row>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  onSortBy: PropTypes.func,
  order: PropTypes.string,
  sortBy: PropTypes.string,
};

export default styled(Header)`
  grid-template-columns: 2fr 2fr 1fr 1fr 1fr 12rem 18rem;
  padding: 1.2rem 2.4rem 0.8rem;
`;
