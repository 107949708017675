import React from 'react';
import PropTypes from '@prop-types';
import { name } from '@langs';

import PieChart from 'Components/Charts/PieChart';
import PieChartLoader from 'Components/PieChartLoader';

const Template = ({ data = {}, className }) => {
  const locales = Object.keys(data).reduce(
    (acc, locale) => ({ ...acc, [name(locale)]: data[locale] }),
    {},
  );

  return (
    <PieChart className={className} data={locales} loader={PieChartLoader} />
  );
};

Template.propTypes = {
  className: PropTypes.string,
  data: PropTypes.objectOf(PropTypes.number),
};

export default Template;
