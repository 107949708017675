import React, { useState } from 'react';
import { theme, withStyles as css } from '@styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'underscore';
import { useIntl } from '@react-intl';

import Card from 'Components/Card';
import { Input, Select } from 'Components/Form';

import TableDataProvider from 'Containers/TableDataProvider';
import Toolbar from 'Containers/CardToolbar';

import { currentAccount } from 'Selectors';

import service from 'Services/campaigns';

import Table from './components/Table';

const Campaigns = ({ account, className }) => {
  const intl = useIntl();
  const [search, setSearch] = useState('');
  const [type, setType] = useState(null);

  const handleType = value => setType(value);

  const options = [
    {
      name: intl.formatMessage({ capitalize: true, id: 'SELECT_TYPE' }),
      value: null,
    },
    {
      name: intl.formatMessage({ capitalize: true, id: 'SOCIAL_CAMPAIGNS' }),
      value: 'SOCIAL',
    },
    {
      name: intl.formatMessage({ capitalize: true, id: 'EMAIL_CAMPAIGNS' }),
      value: 'EMAIL',
    },
    {
      name: intl.formatMessage({ id: 'SMS_CAMPAIGNS' }),
      value: 'SMS',
    },
  ];

  const filterAmmount = search.length ? 1 : 0;

  return (
    <Card className={className}>
      <Toolbar
        filterAmmount={filterAmmount}
        linkId="CREATE_NEW_CAMPAIGN"
        linkTo="/campaigns/campaign/add"
        title="ENGAGEMENT_CAMPAIGNS"
      >
        <Select
          translate
          label="TYPE"
          name="type"
          onChange={handleType}
          options={options}
          type="type"
        />
        <Input
          capitalize
          label="SEARCH"
          name="search"
          onChange={setSearch}
          type="search"
        />
      </Toolbar>
      <TableDataProvider
        account={account}
        limit={8}
        name="campaigns"
        params={{
          account,
          order: 'DESC',
          search,
          sortBy: 'launched',
          type,
        }}
        query={service.get}
        template={Table}
      />
    </Card>
  );
};

Campaigns.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
};

const mapStateToProps = state => ({
  account: currentAccount(state),
});

export default compose(
  css`
    border-radius: 0.6rem 0.6rem 0 0;
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
    padding: 0;

    ${Select} {
      margin-right: 3.6rem;
      width: 24rem;
    }

    @media (${theme('--column-12')}) {
      ${Input} {
        width: 24rem;
      }
    }
  `,
  connect(mapStateToProps),
)(Campaigns);
