import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';
import { FormattedMessage } from '@react-intl';

import FilterButton from './FilterButton';
import Tabs from './Tabs';

const ActionsWrapper = styled('div')``;
const Content = styled('div')``;
const Fixed = styled('div')``;
const Title = styled('div')``;

const CardNavigation = ({
  className,
  contextMenu,
  filters,
  onTabSelect,
  selectedTab,
  tabLabel,
  tabsCount,
  titleId,
}) => {
  const filterButtonRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () =>
    setIsOpen(prevIsOpen => {
      if (prevIsOpen) setTimeout(() => filterButtonRef.current.blur(), 0);
      return !prevIsOpen;
    });

  return (
    <div className={className} data-open={isOpen}>
      <Fixed>
        <Title>
          <FormattedMessage capitalize id={titleId} />
        </Title>
        <Tabs
          onTabSelect={onTabSelect}
          selectedTab={selectedTab}
          tabLabel={tabLabel}
          tabsCount={tabsCount}
          tabsPerRow={filters ? 6 : 8}
        />
        <ActionsWrapper>
          {filters && (
            <FilterButton
              ref={filterButtonRef}
              active={isOpen}
              onClick={toggleIsOpen}
            />
          )}
          {contextMenu || null}
        </ActionsWrapper>
      </Fixed>
      {filters && <Content>{filters}</Content>}
    </div>
  );
};

CardNavigation.propTypes = {
  className: PropTypes.string,
  contextMenu: PropTypes.node,
  filters: PropTypes.node,
  onTabSelect: PropTypes.func,
  selectedTab: PropTypes.number,
  tabLabel: PropTypes.func,
  tabsCount: PropTypes.number,
  titleId: PropTypes.string,
};

export default styled(CardNavigation)`
  align-items: center;
  height: 2.2rem;
  margin-bottom: 3rem;
  transition: all 500ms ease-out;

  ${ActionsWrapper} {
    align-items: center;
    display: flex;
    height: 2.2rem;
  }
  ${Fixed} {
    align-items: center;
    display: grid;
    grid-template-columns: 15rem 1fr 11rem;
    height: 2.2rem;
  }

  ${Title} {
    display: inline-block;
    width: 15rem;
  }

  ${Content} {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    opacity: 0;
    padding: 2rem 2.4rem 0;
    transition: all 250ms linear;
  }
  &[data-open='true'] {
    height: auto;
    ${Content} {
      opacity: 1;
    }
  }
  @media (${theme('--column-8')}) {
    ${Content} {
      flex-direction: row;
    }
    &[data-open='true'] {
      height: 9rem;
    }
  }
`;
