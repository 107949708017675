import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from '@prop-types';
import styled, { theme } from '@styled-components';
import moment from 'moment';
import axios from 'axios';
import fileDownload from 'js-file-download';

import { useMultiDataProviderContext } from 'Containers/MultiDataProvider';

import ContextMenu from './ContextMenu';
import EmptyTemplate from './EmptyTemplate';
import RemoveMedia from './RemoveMedia';

import Button from 'Components/Button';
import Card from 'Components/Card';
import Icon from 'Components/Icon';
import { Input, Select } from 'Components/Form';

import service from 'Services/knowledge-base';

import DataText from './DataText';
import SectionHeadline from './SectionHeadline';

const DesripctionCard = styled(Card)``;
const DocumentDesripctionCard = styled('div')``;
const InformationCard = styled(Card)``;
const OverviewBlock = styled('div')``;
const OverviewHeadline = styled(SectionHeadline)``;
const PdfCard = styled(Card)``;
const SaveButton = styled(Button)``;
const UploadButton = styled(Button)``;

const PdfViewer = ({ account, className, data, locale }) => {
  const [mainData, setMainData] = useState(data);
  const [edit, setEdit] = useState(false);
  const [description, setDescription] = useState(mainData.description);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  useEffect(() => {
    setMainData(data);
  }, [data]);

  const { refetch } = useMultiDataProviderContext('knowledgeBase');

  const handleSave = async () => {
    await service.editDescription({ account, description, id: mainData.id });

    if (refetch) {
      refetch();
    }
    setEdit(false);
  };

  const handleOpenConfirm = () => {
    setIsConfirmOpen(true);
  };

  const handleClose = () => {
    setIsConfirmOpen(false);
  };

  const handleDownload = () => {
    axios
      .get(mainData.url, {
        responseType: 'blob',
      })
      .then(res => {
        fileDownload(res.data, mainData.fileName);
      });
  };

  const handleDelete = async () => {
    await service.remove({ account, id: mainData.id });

    if (refetch) {
      await refetch();
    }
    handleClose();
  };

  const history = useHistory();

  return (
    <>
      <RemoveMedia
        in={isConfirmOpen}
        onAccept={handleDelete}
        onCancel={handleClose}
      />
      <div className={className}>
        <UploadButton
          capitalize
          primary
          translate
          onClick={() => {
            history.push({
              pathname: '/builder/knowledgebase/add',
              state: { locale },
            });
          }}
        >
          {mainData.url ? 'REPLACE_DOCUMENT' : 'UPLOAD_DOCUMENT'}
        </UploadButton>
        <PdfCard>
          <EmptyTemplate data={mainData} locale={locale} />
        </PdfCard>
        <InformationCard>
          <OverviewBlock>
            {mainData.url && (
              <ContextMenu
                onDelete={handleOpenConfirm}
                onDownload={handleDownload}
              />
            )}
            <OverviewHeadline title="DOCUMENT_INFO" />
            <DataText heading={mainData.fileName || 'N/A'} title="NAME" />
            <DataText
              heading={
                mainData.uploadDate
                  ? moment(mainData.uploadDate).format('LLL')
                  : 'N/A'
              }
              title="DATE_UPLOADED"
            />
            <DataText
              heading={
                mainData.fileType ? mainData.fileType.toUpperCase() : 'N/A'
              }
              title="FILE_TYPE"
            />
          </OverviewBlock>
        </InformationCard>
        <DesripctionCard>
          <div className="descriptionHeader">
            <SectionHeadline
              heading="INSTRUCTIONS.DESCRIPTION_KNOWLEDGE_BASE"
              title="DESCRIPTION"
            />
            {edit === false && mainData.url && (
              <Icon
                name="edit-outline"
                onClick={() => {
                  setDescription(mainData.description);
                  setEdit(true);
                }}
              />
            )}
          </div>

          {edit ? (
            <>
              <Input
                defaultValue={mainData.description}
                label="DESCRIPTION"
                name="description"
                onChange={e => setDescription(e)}
                value={description}
              />
              <SaveButton capitalize primary translate onClick={handleSave}>
                SAVE
              </SaveButton>
            </>
          ) : (
            <DocumentDesripctionCard>
              {mainData.description || 'N/A'}
            </DocumentDesripctionCard>
          )}
        </DesripctionCard>
      </div>
    </>
  );
};

PdfViewer.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
  data: PropTypes.object,
  locale: PropTypes.string,
};

export default styled(PdfViewer)`
  ${ContextMenu} {
    margin-left: 85%;
    position: absolute;
    z-index: 100;

    > button {
      background: ${theme('--color-light')};
      height: 3.2rem;
      margin-left: auto;
      width: 3.2rem;
    }

    li {
      ${theme('--font-weight-book')}
      ${theme('--font-medium')}
      color: ${theme('--color-dark-night-60')};

      &:last-of-type > button {
        color: ${theme('--color-alert')};
      }
    }

    article {
      top: 3.6rem;
    }
  }

  ${SaveButton} {
    margin-top: 1.6rem;
    width: 16rem;
  }

  ${SectionHeadline} {
    margin-bottom: 1.6rem;
  }

  .descriptionHeader {
    display: flex;
    justify-content: space-between;
  }

  ${Icon} {
    cursor: pointer;
  }

  ${DocumentDesripctionCard} {
    background-color: #f7f9fc;
    padding: 1.6rem;
  }

  @media (${theme('--column-8')}) {
    ${InformationCard} {
      grid-column: span 3;
    }
  }

  @media (${theme('--column-12')}) {
    grid-column: span 12;
    grid-template-columns: repeat(12, 1fr);

    ${Select} {
      width: 18rem;
    }

    ${UploadButton} {
      grid-column: 11 / span 2;
      height: 4rem;
      margin-top: -6rem;
      width: 16rem;
    }

    ${PdfCard} {
      grid-column: span 7;
      grid-row: 2 / span 10;
      height: 53rem;
    }

    ${InformationCard} {
      grid-column: span 5;
      grid-row: 2;
    }

    ${DesripctionCard} {
      display: block;
      grid-column: span 5;
      grid-row: 3;
    }
  }
`;
