import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled, { withStyles } from '@styled-components';
import { compose } from 'underscore';

import Button from 'Components/Button';
import { Input, Textarea } from 'Components/Form';
import Products from 'Components/Products';

import DataProvider from 'Containers/DataProvider';
import MultiproductForm from 'Containers/MultiproductForm';

import { currentAccount } from 'Selectors';

import service from 'Services/microapps';

const mapStateToProps = state => ({
  account: currentAccount(state),
});

const Wrapper = styled('div')``;
const Actions = styled('section')``;

const EditMultiproduct = ({
  account,
  className,
  order,
  depth,
  id,
  in: modalIn,
  onClose,
  onBack,
}) => {
  return (
    <DataProvider
      editMode
      className={className}
      depth={depth}
      in={modalIn}
      modalTitle="EDIT_TEMPLATE"
      onBack={onBack}
      onClose={onClose}
      order={order}
      params={{ account, id }}
      query={service.getById}
      template={MultiproductForm}
    />
  );
};

EditMultiproduct.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
  depth: PropTypes.number,
  id: PropTypes.string,
  in: PropTypes.bool,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  order: PropTypes.number,
};

export default compose(
  connect(mapStateToProps),
  withStyles`
    ${Wrapper}{
      margin: 0 auto;
      max-width: 50rem;

      ${Input}, ${Textarea}{
        margin-top: 1.6rem;

        > * {
          resize: none;

        }
      }

      ${Products} {
        margin-top: 1.6rem;

        ${Input}{
          margin: 0;
        }
      }

      ${Actions}{
        display: flex;
        margin-top: 4rem;
        padding: 1.6rem 0;

        ${Button}{
          margin-right: 1.6rem;
          width: 14.4rem;
        }
      }
    }
  `,
)(EditMultiproduct);
