import React from 'react';
import PropTypes from '@prop-types';
import styled, { theme } from '@styled-components';

import Chip from 'Components/Chip';

const Tooltip = styled('div').attrs({ 'data-type': 'tooltip' })``;

const Ellipsis = ({ className }) => {
  return (
    <Chip className={className} data-type="ellipsis">
      ...
      <Tooltip />
    </Chip>
  );
};

Ellipsis.propTypes = {
  className: PropTypes.string,
};

export default styled(Ellipsis)`
  ${Tooltip} {
    ${theme('--font-opacity-60')}
    ${theme('--font-small')}
    ${theme('--font-weight-book')}
    background: ${theme('--color-light')};
    border-radius: 0.6rem;
    box-shadow: 0 1rem 1.6rem rgba(0, 15, 25, 0.15),
      0 0.1rem 0 rgba(0, 15, 25, 0.05);
    height: 3.2rem;
    min-width: 2rem;
    padding: 0.8rem;
    position: absolute;
    right: 0;
    text-transform: uppercase;
    top: 2.8rem;
    visibility: hidden;
    white-space: nowrap;
    z-index: 100;
  }

  &:hover ${Tooltip} {
    visibility: visible;
  }
`;
