import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';

import defaultStore from './configuration';

const Store = ({ children, store = defaultStore }) => (
  <Provider store={store}>{children}</Provider>
);

Store.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  store: PropTypes.object,
};

export default Store;
