import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate } from 'react-intl';
import styled, { theme } from '@styled-components';
import { useLocation } from 'react-router-dom';

import { Row } from 'Components/ResponsiveTable';

import Link from 'Components/Link';
import Icon from 'Components/Icon';
import Stepper from 'Components/Stepper';

export const Interaction = styled('span')``;
export const Name = styled(Link)``;
export const Platform = styled('span')``;
export const Status = styled('span')``;
export const Steps = styled(Link)``;
export const Type = styled('span')``;

export const Campaign = ({
  className,
  customerId,
  empty,
  id,
  name,
  platform,
  status,
  step,
  steps = [],
  type,
  updatedAt,
}) => {
  const { pathname } = useLocation();

  return (
    <Row className={className} empty={empty}>
      <Name to={`${pathname}/surveys/${id}`}>{name}</Name>
      <Interaction>
        {updatedAt && (
          <FormattedDate
            day="numeric"
            month="long"
            value={updatedAt}
            year="numeric"
          />
        )}
      </Interaction>
      <Steps to={`${pathname}/answers/${id}-${customerId}`}>
        <Stepper
          currentStep={steps.findIndex(({ key }) => key === step)}
          steps={steps.map(({ displayName }) => displayName)}
        />
      </Steps>
      {platform && (
        <Platform>
          <Icon name={platform.toLowerCase()}>{platform}</Icon>
        </Platform>
      )}
      <Status>{status}</Status>
      <Type>{type}</Type>
    </Row>
  );
};

Campaign.propTypes = {
  className: PropTypes.string,
  customerId: PropTypes.number,
  empty: PropTypes.bool,
  id: PropTypes.number,
  name: PropTypes.string,
  platform: PropTypes.string,
  status: PropTypes.string,
  step: PropTypes.number,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string,
    }),
  ),
  type: PropTypes.string,
  updatedAt: PropTypes.instanceOf(Date),
};

export default styled(Campaign)`
  ${theme('--font-medium')}
  ${theme('--font-weight-book')}
  align-items: flex-start;
  color: ${theme('--color-dark-night-40')};
  display: grid;
  grid-gap: 0.4rem;
  padding: 1.2rem 2.4rem;

  ${Name} {
    ${theme('--font-medium')}
    ${theme('--font-weight-medium')}
    color: ${theme('--color-primary')};
    grid-column: 1;
    grid-row: 1;
  }

  ${Interaction} {
    grid-column: 1;
    grid-row: 2;
  }

  ${Status} {
    grid-column: 1;
    grid-row: 3;
    margin-bottom: 0;
    text-transform: lowercase;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  ${Steps} {
    align-items: center;
    display: flex;
    grid-column: 1;
    grid-row: 4;
    height: 100%;
  }

  ${Platform} {
    display: flex;
    grid-column: 2;
    grid-row: 1 / span 2;
    justify-content: flex-end;

    > * {
      width: 2.4rem;
    }
  }

  ${Type} {
    grid-column: 2;
    grid-row: 3 / span 2;
  }

  &:last-child {
    margin-bottom: 2.6rem;
  }
`;
