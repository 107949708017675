import React from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';
import { FormattedMessage } from '@react-intl';

import Card from 'Components/Card';

const CardTitle = styled('h3')``;
const SubTitle = styled('div')``;
const Text = styled('div')``;
const Title = styled('div')``;

const Invoice = ({
  city,
  className,
  country,
  email,
  firstName,
  invoiceNumber,
  lastName,
  phone,
  postalCode,
  state,
  street1,
}) => {
  return (
    <Card className={className}>
      <FormattedMessage
        component={CardTitle}
        id="INVOICE_NUMBER"
        values={{ invoiceNumber }}
      />
      <Title>Ship to</Title>
      <Text>
        {firstName} {lastName}
      </Text>
      <Text>{street1}</Text>
      <Text>
        {city}, {state}
      </Text>
      <Text>
        {country}, {postalCode}
      </Text>
      <SubTitle>Phone</SubTitle>
      <Text>{phone}</Text>
      <SubTitle>Email</SubTitle>
      <Text>{email}</Text>
    </Card>
  );
};

Invoice.propTypes = {
  city: PropTypes.string,
  className: PropTypes.string,
  country: PropTypes.string,
  email: PropTypes.string,
  firstName: PropTypes.string,
  invoiceNumber: PropTypes.string,
  lastName: PropTypes.string,
  phone: PropTypes.string,
  postalCode: PropTypes.string,
  state: PropTypes.string,
  street1: PropTypes.string,
};

export default styled(Invoice)`
  ${CardTitle} {
    color: #000f19;
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 2.9rem;
    margin-bottom: 2.4rem;
  }

  ${SubTitle} {
    color: #000f19;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 2.1rem;
    margin-top: 0.8rem;
  }

  ${Text} {
    color: #666f75;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 2.1rem;
  }

  ${Title} {
    color: #000f19;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 2.1rem;
    margin-bottom: 1.2rem;
  }
`;
