import axios from 'axios';

import C from 'Constants';

const { ERROR } = C;

export const createToken = axios.CancelToken.source;

export async function http(url, method = 'get', data, options = {}) {
  const hasBody = ['delete', 'patch', 'post', 'put'].includes(
    method.toLowerCase(),
  );
  let response;

  const source = options.token;

  try {
    response = await axios({
      [hasBody ? 'data' : 'params']: data,
      cancelToken: source ? source.token : undefined,
      method,
      url,
      ...options,
    });
  } catch (error) {
    if (axios.isCancel(error)) {
      throw new Error(ERROR.CANCELED);
    }

    if (!error.response) {
      throw new Error(ERROR.NETWORK);
    }

    ({ response } = error);
  }

  return response;
}

['get', 'patch', 'post', 'put'].forEach(method => {
  http[method] = (url, data, configuration) =>
    http(url, method, data, configuration);
});

export default http;
