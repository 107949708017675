import React from 'react';
import { FormattedDate, FormattedRelative, FormattedTime } from '@react-intl';
import { name } from '@langs';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';

import RawAvatar from 'Components/Avatar';
import Chip from 'Components/Chip';
import RawPlatforms from 'Components/Platforms';
import { Row } from 'Components/ResponsiveTable';
import {
  withPlaceholderNoData as withPlaceholder,
  PlaceholderNoData as Placeholder,
} from 'Containers/Placeholder';

const User = styled('section')``;
const Platforms = styled(withPlaceholder(RawPlatforms))``;
const Groups = styled(Placeholder)``;
const TimezoneAndLocation = styled('section')``;
const Label = styled(Placeholder)``;
const NameLabel = styled(Label)``;

const UserName = styled('span')``;
const Gender = styled('span')``;

const Avatar = styled(withPlaceholder(RawAvatar))``;

const MAX_CHARS = 30;

function getChips(groups) {
  let counter = 0;
  let chips = [];
  groups.forEach(({ name: group }) => {
    counter += group.length;
    if (counter < MAX_CHARS) {
      chips = [...chips, group];
    }
  });
  if (counter >= MAX_CHARS) {
    chips = [...chips, '...'];
  }
  return chips;
}

const Template = ({
  className,
  createdAt,
  empty,
  firstName,
  gender,
  groups = [],
  id,
  images: { small: image } = {},
  lastName,
  location,
  locale,
  platforms = [],
  timezone,
  updatedAt,
}) => (
  <Row className={className} empty={empty} to={`customers/${id}`}>
    <User>
      <Avatar fill image={image} name={`${firstName} ${lastName}`} />
      <NameLabel placeholder="customer name">{`${firstName} ${lastName}`}</NameLabel>
      <Label placeholder="customer gender">{gender}</Label>
    </User>
    <TimezoneAndLocation>
      {timezone && (
        <FormattedTime
          component={Label}
          placeholder="customer timezone"
          timeZone={timezone}
          timeZoneName="short"
          value={new Date()}
        />
      )}
      <Label placeholder="customer location">{location}</Label>
    </TimezoneAndLocation>
    <Label placeholder="customer locale">{locale && name(locale)}</Label>
    <Label placeholder="join date">
      {createdAt && (
        <FormattedDate
          day="2-digit"
          month="long"
          value={createdAt}
          year="numeric"
        />
      )}
    </Label>
    <Label placeholder="update at">
      <FormattedRelative value={updatedAt} />
    </Label>
    <Platforms fill data={platforms} />
    <Groups placeholder="customer groups">
      {getChips(groups).map(group => (
        <Chip key={group}>{group}</Chip>
      ))}
    </Groups>
  </Row>
);

Template.propTypes = {
  className: PropTypes.string,
  createdAt: PropTypes.instanceOf(Date),
  empty: PropTypes.bool,
  firstName: PropTypes.string,
  gender: PropTypes.string,
  groups: PropTypes.arrayOf(PropTypes.object),
  id: PropTypes.number,
  images: PropTypes.objectOf(PropTypes.string),
  lastName: PropTypes.string,
  locale: PropTypes.string,
  location: PropTypes.string,
  platforms: PropTypes.arrayOf(PropTypes.string),
  timezone: PropTypes.string,
  updatedAt: PropTypes.instanceOf(Date),
};

export default styled(Template)`
  grid-template-columns: 2fr 2fr 1fr 1fr 1fr 12rem 18rem;
  height: 8rem;

  ${Label} {
    ${theme('--font-small')}
    ${theme('--font-opacity-40')}
    ${theme('--font-weight-book')}
  }

  ${NameLabel} {
    ${theme('--font-weight-medium')}
    color: ${theme('--color-primary')};
  }

  ${User} {
    align-items: center;
    display: grid;
    grid-column-gap: 1.6rem;
    grid-row-gap: 0.8rem;
    grid-template: auto auto / auto 1fr;

    ${Avatar} {
      grid-column: 1;
      grid-row: 1 / span 2;
      height: 4rem;
      width: 4rem;
    }

    ${UserName} {
      ${theme('--font-small')}
      ${theme('--font-weight-medium')}
      align-items: flex-end;
      display: flex;
      height: 100%;
    }

    ${Gender} {
      ${theme('--font-small')}
      ${theme('--font-weight-book')}
      align-items: flex-start;
      color: ${theme('--color-dark-night-60')};
      display: flex;
      height: 100%;
    }
  }

  ${TimezoneAndLocation} {
    display: grid;
    grid-row-gap: 0.8rem;
    grid-template: auto auto / auto;
  }

  ${Platforms} {
    height: 100%;
    justify-content: center;
    padding: 0.8rem 0.8rem 0.8rem 0;
  }

  ${Groups} {
    display: flex;
    flex-wrap: wrap;
    max-height: calc(100% - 2.4rem);
    overflow: hidden;

    ${Chip} {
      background: ${theme('--color-main')};
      border: none;
      border-radius: 0.6rem;
      color: ${theme('--color-light')};
      margin: 0 0.8rem 0.8rem 0;
    }
  }
`;
