import React, { useMemo } from 'react';
import styled from '@styled-components';
import PropTypes from '@prop-types';
import { useIntl } from '@react-intl';
import { useField } from 'spooner';

import { Select } from 'Components/Form';

import CustomUrlForm from 'Containers/SignupForm/CustomUrlForm';
import ThankYouConfig from 'Containers/SignupForm/ThankYouConfig';

const FormRedirect = ({ className, handleAdditionalFormFields }) => {
  const { value: selectedValue } = useField({ name: 'redirect' });
  const intl = useIntl();

  const options = useMemo(
    () => [
      {
        name: intl.formatMessage({ capitalize: true, id: 'THANK_YOU' }),
        value: 'THANK_YOU',
      },
      {
        name: intl.formatMessage({ capitalize: true, id: 'CUSTOM_URL' }),
        value: 'CUSTOM_URL',
      },
    ],
    [intl],
  );

  return (
    <div className={className}>
      <Select name="redirect" options={options} />
      {selectedValue === 'THANK_YOU' ? (
        <ThankYouConfig onChange={handleAdditionalFormFields} />
      ) : (
        <CustomUrlForm onChange={handleAdditionalFormFields} />
      )}
    </div>
  );
};

FormRedirect.propTypes = {
  className: PropTypes.string,
  handleAdditionalFormFields: PropTypes.func,
};

export default styled(FormRedirect)`
  margin-top: 2.4rem;
`;
