import React, { useCallback, useState } from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';

import IconButton from 'Components/IconButton';
import Card from 'Components/Card';

export const CHART = 'chart';
export const TABLE = 'list';

const Content = styled('section')``;
const Actions = styled('div')``;

const DataCard = ({
  className,
  chartTemplate: Chart,
  tableTemplate: Table,
  initial,
  onChange: handleChange = () => {},
  title,
  toolbar: Toolbar,
  ...props
}) => {
  const [isTableActive, setIsTableActive] = useState(initial === TABLE);
  const onChange = useCallback(() => {
    setIsTableActive(!isTableActive);
    handleChange(!isTableActive ? TABLE : CHART);
  }, [handleChange, isTableActive, setIsTableActive]);

  return (
    <Card className={className} title={title}>
      <Actions>
        <Toolbar {...props} />
        <IconButton
          outline
          name={isTableActive ? CHART : TABLE}
          onClick={onChange}
        />
      </Actions>
      <Content>
        {isTableActive ? <Table {...props} /> : <Chart {...props} />}
      </Content>
    </Card>
  );
};

DataCard.defaultProps = {
  chartTemplate: () => {},
  initial: TABLE,
  tableTemplate: () => {},
  toolbar: () => null,
};
DataCard.propTypes = {
  chartTemplate: PropTypes.component,
  className: PropTypes.string,
  initial: PropTypes.string,
  onChange: PropTypes.func,
  tableTemplate: PropTypes.component,
  title: PropTypes.string,
  toolbar: PropTypes.element,
};

export default styled(DataCard)`
  position: relative;

  ${Content} {
    height: 100%;
    width: 100%;
  }

  ${Actions} {
    align-items: center;
    display: flex;
    position: absolute;
    right: 0.8rem;
    top: 0.8rem;

    ${IconButton} {
      margin-left: 0.8rem;
    }
  }
`;
