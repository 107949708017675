import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withStyles } from '@styled-components';

import * as logger from 'Actions/logger';

import { currentAccount } from 'Selectors';

import service from 'Services/customers';

import CustomerForm from 'Containers/CustomerForm';

const mapStateToProps = state => ({
  account: currentAccount(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ success: logger.success }, dispatch);

export class EditCustomer extends Component {
  static propTypes = {
    account: PropTypes.number,
    className: PropTypes.string,
    data: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
    onClose: PropTypes.func,
    onReload: PropTypes.func,
    onSuccess: PropTypes.func,
    previous: PropTypes.string,
    success: PropTypes.func,
  };

  handleSubmit = async values => {
    const {
      account,
      data: { id },
      success,
      onSuccess,
      onReload,
      previous,
    } = this.props;

    const {
      city,
      country,
      email,
      firstName,
      groups,
      history,
      lastName,
      notes,
      phone,
      postalCode,
      region,
      street1,
      street2,
    } = values;

    const customer = {
      deliveryAddress: {
        city,
        country,
        postalCode,
        region,
        street1,
        street2,
      },
      email,
      firstName,
      groups,
      lastName,
      notes,
      phone,
    };

    await service.edit({ account, customer, id });

    success('CUSTOMER_MODIFIED');
    onSuccess();
    onReload(`/customers/${id}`);
    history.push(previous);
  };

  render() {
    const { className, data, ...props } = this.props;

    if (!data) return null;

    return (
      <CustomerForm
        className={className}
        customer={data}
        onSubmit={this.handleSubmit}
        {...props}
      />
    );
  }
}

export default compose(
  withStyles``,
  connect(mapStateToProps, mapDispatchToProps),
)(EditCustomer);
