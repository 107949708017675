import React, { useState, useCallback } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import styled, { withStyles as css, theme } from '@styled-components';
import { FormattedMessage, capitalize, intlShape, withIntl } from '@react-intl';

import { useMultiDataProviderContext } from 'Containers/MultiDataProvider';

import Button from 'Components/Button';
import Form, { File } from 'Components/Form';

import service from 'Services/faqs';

const Advise = styled('p')``;
const ViewHeadline = styled('header')``;
const Title = styled('h4')``;
const Heading = styled('p')``;
const FileTile = styled('p')``;

const ImportFaq = ({
  className,
  intl,
  error,
  account,
  onClose,
  success,
  data: locales,
}) => {
  const { refetch } = useMultiDataProviderContext('faqs');
  const [fileName, setFileName] = useState(null);

  const handleChange = useCallback(file => {
    setFileName(file.name);
  }, []);

  if (!locales) return null;
  const locale = locales[0];

  const onSubmit = async ({ file }) => {
    try {
      await service.importFaq({ account, file, locale });
      success('FAQ_IMPORTED');
    } catch (e) {
      error('FAQ_IMPORTED');
    }
    onClose();
    refetch();
  };

  return (
    <Form className={className} onSubmit={onSubmit}>
      <ViewHeadline>
        <FormattedMessage capitalize component={Title} id="IMPORT_FILE" />
        {fileName ? (
          <>
            <FileTile>{fileName}</FileTile>
            <Advise>
              <FormattedMessage
                capitalize
                id="INSTRUCTIONS.IMPORT_FAQS.SHORT"
              />
              <FormattedMessage
                component="a"
                href="/files/SampleFAQ.xlsx"
                id="INSTRUCTIONS.IMPORT_FAQS.XLSX"
              />
            </Advise>
          </>
        ) : (
          <Heading>
            <FormattedMessage capitalize id="INSTRUCTIONS.IMPORT_FAQS.BODY" />
            <FormattedMessage
              component="a"
              href="/files/SampleFAQ.tsv"
              id="INSTRUCTIONS.IMPORT_FAQS.TSV"
            />
            <FormattedMessage
              component="a"
              href="/files/SampleFAQ.xlsx"
              id="INSTRUCTIONS.IMPORT_FAQS.XLSX"
            />
          </Heading>
        )}
      </ViewHeadline>
      <File
        name="file"
        onChange={handleChange}
        placeholder={capitalize(intl.formatMessage({ id: 'CHOOSE_A_FILE' }))}
        type="file"
      />
      <FormattedMessage
        component={Advise}
        id="INSTRUCTIONS.IMPORT_FAQS.ADVISE"
      />
      <Button primary type="submit">
        <FormattedMessage capitalize id="UPLOAD" />
      </Button>
    </Form>
  );
};

ImportFaq.propTypes = {
  account: PropTypes.string,
  campaign: PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
  className: PropTypes.string,
  data: PropTypes.array,
  error: PropTypes.func,
  intl: intlShape,
  onClose: PropTypes.func,
  success: PropTypes.func,
};

export default compose(
  css`
    margin: auto;
    max-width: 50rem;

    ${ViewHeadline} {
      ${Title} {
        ${theme('--font-medium')}
        ${theme('--font-weight-demi')}
        padding-bottom: 0.8rem;
      }

      ${FileTile} {
        ${theme('--font-medium')}
        ${theme('--font-weight-book')}
        ${theme('--font-opacity-80')}
        padding-bottom: 1.6rem;
      }

      ${Heading} {
        ${theme('--font-medium')}
        ${theme('--font-weight-book')}
        ${theme('--font-opacity-40')}
        padding-bottom: 1.6rem;
      }

      ${Advise} {
        margin-bottom: 0.8rem;
      }
    }

    ${File} {
      margin-bottom: 4rem;
      width: 14.4rem;
    }

    ${Advise} {
      ${theme('--font-small')}
      ${theme('--font-weight-book')}
      ${theme('--font-opacity-60')}
      margin-bottom: 2.4rem;
    }

    ${Button} {
      width: 16rem;
    }
  `,
  withIntl,
)(ImportFaq);
