import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { remove } from 'Actions/logger';

import Logger from 'Components/Snackbar';

const mapDispatchToProps = dispatch =>
  bindActionCreators({ onDismiss: remove }, dispatch);

const mapStateToProps = state => ({ snacks: state.logger });

export default connect(mapStateToProps, mapDispatchToProps)(Logger);
