import React, { Component } from 'react';
import PropTypes from '@prop-types';
import styled, { theme } from '@styled-components';
import { debounce } from 'underscore';

import List from 'Components/List';
import Options from './Options';

import Overlay, { Background } from 'Components/Overlay';

import {
  HeaderTemplate as DefaultHeaderTemplate,
  DropdownTemplate as DefaultDropdownTemplate,
} from './Template';

export const Header = styled('div')``;

const CustomList = styled(List)`
  height: 100%;
  max-height: ${props => (props.withScroll ? '21.6rem' : '100%')};
  overflow: scroll;
  width: 26rem;

  @media (${theme('--screen-small')}) {
    width: auto;
  }
`;

class Dropdown extends Component {
  static propTypes = {
    active: PropTypes.string,
    allData: PropTypes.array,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    className: PropTypes.string,
    data: PropTypes.array,
    dropdownTemplate: PropTypes.component,
    headerTemplate: PropTypes.component,
    isOpen: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    onFocus: PropTypes.func,
    onOpen: PropTypes.func,
    selected: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array,
    ]),
    withScroll: PropTypes.bool,
  };

  static defaultProps = {
    allData: [],
    data: [],
    dropdownTemplate: DefaultDropdownTemplate,
    headerTemplate: DefaultHeaderTemplate,
    isOpen: false,
    onBlur() {},
    onClose() {},
    withScroll: false,
  };

  state = {
    active: this.props.selected,
    isOpen: this.props.isOpen,
  };

  componentDidUpdate(prevProps) {
    const { selected } = this.props;

    if (selected !== prevProps.selected) {
      this.setState({ active: selected });
    }
  }

  setHeader = header => {
    this.header = header;
  };

  open = () => {
    const { onOpen = () => {} } = this.props;
    this.setState({ isOpen: true }, () => {
      onOpen();
    });
  };

  close = () => {
    const { onBlur = () => {}, onClose = () => {} } = this.props;
    this.setState({ isOpen: false }, () => {
      onClose();
      onBlur();
    });
  };

  handleChange = value => {
    const { onChange } = this.props;
    onChange(value);
    this.setState(() => ({ active: value }), debounce(this.close, 250));
  };

  render() {
    const {
      allData,
      children,
      className,
      data,
      headerTemplate: HeaderTemplate,
      dropdownTemplate: DropdownTemplate,
      onBlur,
      onFocus,
      withScroll,
    } = this.props;
    const { active, isOpen } = this.state;
    const selected = allData.find(({ value }) => value === active);

    /* eslint-disable jsx-a11y/no-noninteractive-tabindex */
    return (
      <div className={className} onBlur={onBlur} onFocus={onFocus} tabIndex={0}>
        <Header
          ref={this.setHeader}
          data-testid="dropdown-header"
          onClick={this.open}
        >
          <HeaderTemplate {...selected} />
        </Header>
        <Overlay in={isOpen} onDismiss={this.close}>
          <Options in={isOpen} onClick={this.close}>
            <CustomList
              noWrapper
              items={data}
              options={{
                onClick: this.handleChange,
              }}
              selected={active}
              template={DropdownTemplate}
              withScroll={withScroll}
            />
            {children}
          </Options>
        </Overlay>
      </div>
    );
  }
}

export default styled(Dropdown)`
  position: relative;

  &:focus {
    outline: 0;
  }

  ${Background} {
    background: none;
  }
`;
