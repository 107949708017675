import request from 'Providers/request';
import { createService } from '@redux-act';

const download = createService(async url => {
  const data = await request.read(
    url,
    {},
    {},
    {
      headers: { 'Access-Control-Allow-Origin': '*' },
      responseType: 'blob',
      withCredentials: true,
    },
  );

  return data;
});

async function get({ account, from, to, type, id }) {
  let mediaType = '';
  if (typeof type === 'string') {
    // eslint-disable-next-line
    type = [type];
  }
  if (type && type.indexOf('ALL') < 0) {
    type.forEach(t => {
      // eslint-disable-next-line
      t = t.toLowerCase();
      mediaType += t === 'audio' ? '&type=audio&type=voice' : `&type=${t}`;
    });
  }
  const idParam = id ? `&id=${id}` : '';
  const fromParam = from ? `&from=${from.split('T')[0]}` : '';
  const toParam = to ? `&to=${to.split('T')[0]}` : '';

  const media = await request.read(
    `ugc/data?ofaId=${account}${fromParam}${toParam}${mediaType}${idParam}`,
  );
  const { data } = media;

  return {
    collection: data.map(({ mediaUrl, ...rest }) => ({
      ...rest,
      from,
      image: mediaUrl,
      source: mediaUrl,
      to,
    })),
  };
}

async function remove({ account, id, mediaUrl }) {
  return request.delete(`ugc/delete?ofaId=${account}`, { id, mediaUrl });
}

function add({ account, media }) {
  // eslint-disable-next-line
  media.name = 'test'; // let it here for backend compatibility
  media.type = media.type.toLowerCase(); //eslint-disable-line
  return request.create(
    `ugc/upload?ofaId=${account}`,
    media,
    {},
    {
      headers: {
        'content-type': 'form-data/multipart',
      },
    },
  );
}

async function update({ account, id, data }) {
  const path = `ugc/edit?ofaId=${account}`;
  // eslint-disable-next-line
  data.name = 'test'; // let it here for backend compatibility
  data.id = id; // eslint-disable-line
  data.type = data.type ? data.type.toLowerCase() : false; // eslint-disable-line
  return request.update(
    path,
    data,
    {},
    {
      headers: {
        'content-type': 'form-data/multipart',
      },
    },
  );
}

export default {
  add,
  download,
  get,
  remove,
  update,
};
