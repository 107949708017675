import React from 'react';
import PropTypes from '@prop-types';
import styled, { theme, withStyles as css } from '@styled-components';
import { FormattedMessage } from '@react-intl';
import { compose } from 'underscore';

import Link from 'Components/Link';
import { withRouter } from 'Components/Router';

const Title = styled('h2')``;
const Content = styled('p')``;
const Actions = styled('nav')``;

const Button = styled(Link)``;

const EmptyTemplate = ({ className, match: { url } }) => (
  <article className={className}>
    <FormattedMessage
      capitalize
      component={Title}
      id="EMPTY_MICROAPPS.FILTER_MICROAPP"
    >
      Oops! we did not find any faq with this filters
    </FormattedMessage>
    <FormattedMessage
      capitalize
      component={Content}
      id="INSTRUCTIONS.EMPTY_MICROAPPS.CREATE_MICROAPP"
    >
      You can create some microapps and they would be listed here
    </FormattedMessage>
    <Actions>
      <FormattedMessage
        capitalize
        component={Button}
        id="ACTIONS.CREATE"
        to={`${url}/add`}
      >
        create
      </FormattedMessage>
    </Actions>
  </article>
);

EmptyTemplate.propTypes = {
  className: PropTypes.string,
  match: PropTypes.object,
};

export default compose(
  css`
    align-items: center;
    background: ${theme('--color-light')};
    border-radius: 0.6rem;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 39rem);
    justify-content: flex-start;
    overflow-y: scroll;
    padding: 2.4rem;
    padding-top: 4.8rem;

    ${Title} {
      ${theme('--font-large')}
      ${theme('--font-weight-book')}
      ${theme('--font-opacity-100')}
      margin-bottom: 0.4rem;
      text-align: center;
    }

    ${Content} {
      ${theme('--font-medium')}
      ${theme('--font-weight-book')}
      ${theme('--font-opacity-40')}
      margin-bottom: 6.4rem;
      text-align: center;
    }

    ${Actions} {
      display: flex;
      justify-content: center;

      ${Button} {
        ${theme('--font-medium')}
        ${theme('--font-weight-demi')}
        align-items: center;
        background-color: ${theme('--color-primary')};
        border-radius: 0.6rem;
        color: ${theme('--color-light')};
        display: flex;
        height: 4rem;
        justify-content: center;
        margin: 0 0.8rem;
        width: 12.4rem;
      }
    }

    @media screen and (${theme('--screen-small')}) {
      height: 40rem;
      justify-content: center;

      ${Title}{
        text-align: left;
      }
    }
  `,
  withRouter,
)(EmptyTemplate);
