import React, { useCallback } from 'react';
import PropTypes from '@prop-types';
import styled, { theme } from '@styled-components';

import Checkbox from '../CheckBox';

const Label = styled('span')``;

const Item = ({ className, label, name, onChange = () => {}, value }) => {
  const handleChange = useCallback(
    v => () => {
      onChange(name, v);
    },
    [name, onChange],
  );

  return (
    <li className={className}>
      <Label>{label}</Label>
      <Checkbox onChange={handleChange(!value)} value={value} />
    </li>
  );
};

Item.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.bool,
};

export default styled(Item)`
  align-items: center;
  display: flex;
  height: 4.8rem;
  justify-content: space-between;
  min-width: 11.5rem;
  padding: 0 1.6rem;
  transition: background-color 0.35s ease-out;

  &:hover {
    ${theme('--font-opacity-100')}
    background: ${theme('--color-primary-5')}; /* PRIMARY 05 */
  }


`;
