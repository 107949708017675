import moment from 'moment';
import { createService } from '@redux-act';

import request from 'Providers/request';

import {
  paymentMethods as paymentMethodsSchema,
  revenues as revenuesSchema,
  revenuesByTime as revenuesByTimeSchema,
  revenuesComparation as revenuesComparationSchema,
  revenuesPerCampaign as revenuesPerCampaignSchema,
  revenuesStatsPerPlatform as revenuesStatsPerPlatformSchema,
} from 'Schemas';

const get = createService(async ({ account, currency }, token) => {
  const {
    data: [revenue],
  } = await request.read(
    '/analytics/revenue_stats',
    { currency, ofaId: account },
    revenuesSchema,
    { token },
  );

  revenue.average_order = Number(revenue.average_order);

  return revenue;
});

async function getByTime({ account, currency, from, to, unit }) {
  const { sales, total } = await request(
    '/analytics/sales',
    'get',
    {
      currency,
      from,
      ofaId: account,
      to,
      unit,
    },
    revenuesByTimeSchema,
  );
  return {
    sales: Object.keys(sales)
      .reverse()
      .reduce(
        (result, key) => ({
          ...result,
          [key]: sales[key],
        }),
        {},
      ),
    total,
  };
}

async function getComparation({ account, currency, from, to, unit }) {
  const sales = await request.read(
    '/analytics/sales_comparation',
    {
      currency,
      from,
      ofaId: account,
      to,
      unit,
    },
    revenuesComparationSchema,
  );
  return sales;
}

async function getPerCampaign({ account, currency, limit, offset }) {
  const response = await request.read(
    '/analytics/campaigns_revenues',
    {
      currency,
      limit,
      ofaId: account,
      offset,
    },
    revenuesPerCampaignSchema,
    { ttl: 0 },
  );

  let { collection: revenues } = response;
  const { total } = response;

  revenues = revenues
    .sort((a, b) => moment(b.timestamp) - moment(a.timestamp))
    .reduce(
      (result, value) => ({
        ...result,
        [value.name]: {
          createdAt: value.timestamp,
          id: value.id,
          value: value.revenue,
        },
      }),
      {},
    );
  return {
    revenues,
    total,
  };
}

async function getPlatformRevenues({ account, currency }) {
  const { data: revenues } = await request.read(
    '/analytics/revenue_stats_by_platform',
    {
      currency,
      ofaId: account,
    },
    revenuesStatsPerPlatformSchema,
  );

  return revenues.reduce(
    (result, item) => ({
      ...result,
      [item.value]: Number(item.count),
    }),
    {},
  );
}

async function getPaymentMethods({ account }) {
  const response = await request.read(
    'analytics/payment_product',
    {
      ofaId: account,
    },
    paymentMethodsSchema,
  );

  return Promise.resolve(
    response.data.map(({ count, value }) => ({
      count: parseInt(count, 10),
      value,
    })),
  );
}

export default {
  get,
  getByTime,
  getComparation,
  getPaymentMethods,
  getPerCampaign,
  getPlatformRevenues,
};
