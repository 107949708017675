import React, { useCallback, useState } from 'react';
import PropTypes from '@prop-types';
import { FormattedNumber } from '@react-intl';
import styled, { theme } from '@styled-components';

import Button from 'Components/Button';
import Icon from 'Components/Icon';
import Definition, { Detail, Term } from 'Components/Definition';

import FilterButton from '../FilterButton';

import Detailed from './Detail';
import Summary from './Summary';

const Header = styled('header')``;
const Text = styled('span')``;
const Actions = styled('div')``;

const Chart = ({
  campaign,
  className,
  onChange = () => {},
  step,
  steps = [],
}) => {
  const [isSummary, setIsSummary] = useState(true);
  const [isFilterActive, setFilter] = useState(
    steps.findIndex(({ step: s }) => s === step.step) > 0,
  );

  const values = steps.map(({ value }) => value);
  const [total, started] = values;
  const [completed] = values.reverse();

  const conversion = completed / started || 0;
  const { length } = values;

  const successRate =
    campaign.totalCustomers && Number(campaign.totalCustomers) !== 0
      ? campaign.totalSuccess / campaign.totalCustomers
      : 0;
  const ctr =
    campaign.totalSuccess && Number(campaign.totalSuccess) !== 0
      ? campaign.totalClicks / campaign.totalSuccess
      : 0;

  const toggle = useCallback(() => {
    setIsSummary(prevState => !prevState);
  }, [setIsSummary]);

  const resetFilter = useCallback(() => {
    onChange(0);
    setFilter(false);
  }, [onChange, setFilter]);

  const handleChange = useCallback(
    index => {
      onChange(index);
      setFilter(!!index);
    },
    [onChange, setFilter],
  );

  return (
    <section className={className}>
      <Header>
        {campaign.type === 'SMS' || campaign.type === 'WHATSAPP' ? (
          <Definition>
            <Term capitalize translate>
              SUCCESS_RATE
            </Term>
            <Detail>
              {/* eslint-disable-next-line react/style-prop-object */}
              <FormattedNumber style="percent" value={successRate} />
            </Detail>
            {campaign.campaignLinkFlag && (
              <>
                <Term capitalize translate>
                  CTR
                </Term>
                <Detail>
                  {/* eslint-disable-next-line react/style-prop-object */}
                  <FormattedNumber style="percent" value={ctr} />
                </Detail>
              </>
            )}
            <Term capitalize translate>
              TOTAL_STEPS
            </Term>
            <Detail>{length - 1}</Detail>
            <Term capitalize translate>
              TOTAL_AUDIENCE
            </Term>
            <Detail>{campaign.totalCustomers || 0}</Detail>
            <Term capitalize translate>
              MESSAGES_DELIVERED
            </Term>
            <Detail>{campaign.totalSuccess || 0}</Detail>
            {campaign.type === 'WHATSAPP' && (
              <>
                <Term capitalize translate>
                  MESSAGES_READ
                </Term>
                <Detail>{campaign.totalRead || 0}</Detail>
              </>
            )}
            {campaign.campaignLinkFlag && (
              <>
                <Term capitalize translate>
                  USERS_CLICKED_LINK
                </Term>
                <Detail>{campaign.totalClicks || 0}</Detail>
              </>
            )}
          </Definition>
        ) : (
          <Definition>
            <Term capitalize translate>
              CONVERSION_RATE
            </Term>
            <Detail>
              {/*
                Style is a property discourage to use from styled but
                FormattedNumber needed
              */}
              {/* eslint-disable-next-line react/style-prop-object */}
              <FormattedNumber style="percent" value={conversion} />
            </Detail>
            <Term capitalize translate>
              TOTAL_STEPS
            </Term>
            <Detail>{length}</Detail>
            <Term capitalize translate>
              TOTAL_CUSTOMERS
            </Term>
            <Detail>{total}</Detail>
            <Term capitalize translate>
              STARTED_CUSTOMERS
            </Term>
            <Detail>{started}</Detail>
            <Term capitalize translate>
              COMPLETED_CUSTOMERS
            </Term>
            <Detail>{completed}</Detail>
          </Definition>
        )}
        <Actions>
          {isFilterActive ? (
            <FilterButton onClick={resetFilter} />
          ) : (
            <Button data-active={!isSummary} onClick={toggle}>
              <Text capitalize translate>
                FUNNEL_IN_DETAIL
              </Text>
              <Icon name={isSummary ? 'zoom-in' : 'zoom-out'} />
            </Button>
          )}
        </Actions>
      </Header>
      {isSummary ? (
        <Summary onChange={handleChange} step={step} steps={steps} />
      ) : (
        <Detailed data={steps} />
      )}
    </section>
  );
};

Chart.propTypes = {
  campaign: PropTypes.object,
  className: PropTypes.string,
  onChange: PropTypes.func,
  step: PropTypes.object,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
    }),
  ),
};

export default styled(Chart)`
  padding: 0 2.4rem 1.6rem;

  ${Header} {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-bottom: 3.2rem;
    position: relative;
  }

  ${Definition} {
    display: inline-grid;
    grid-auto-flow: column;
    grid-gap: 0.4rem 3.2rem;
    grid-template: repeat(2, 1fr) / repeat(5, auto);
    order: 2;
    overflow: auto;
    width: 100%;
  }

  ${Actions} {
    align-items: flex-start;
    display: flex;
    position: absolute;
    right: 3rem;
    top: -5.6rem;
  }

  ${FilterButton} {
    height: 3.2rem;
    width: 4.8rem;
  }

  ${Button}, ${FilterButton} {
    ${theme('--font-small')}
    ${theme('--font-weight-demi')}
    align-items: center;
    background: none;
    border: none;
    border-radius: 0.6rem;
    cursor: pointer;
    display: flex;
    flex-grow: 0;
    height: 4.8rem;
    justify-content: space-evenly;
    order: 1;
    outline: none;
    transition: all 250ms ease-in;

    &:focus,
    &:hover {
      background: ${theme('--color-blueish-white')};
      box-shadow: none;
      color: ${theme('--color-primary-80')};

      ${Icon} * {
        stroke: ${theme('--color-primary')};
      }
    }

    &[data-active='true'] {
      background: linear-gradient(
        180deg,
        rgba(0, 132, 255, 0.2) 0%,
        rgba(0, 132, 255, 0.1) 100%
      );
      color: ${theme('--color-primary')};

      ${Icon} * {
        stroke: ${theme('--color-primary')};
      }
    }

    ${Text}, span {
      display: none;
    }

    ${Icon} {
      height: 3.2rem;
      width: 3.2rem;
    }
  }

  @media (${theme('--screen-small')}) {
    ${Header} {
      justify-content: space-between;
    }

    ${Definition} {
      order: 1;
      width: auto;
    }

    ${Actions} {
      order: 2;
      position: relative;
      right: 0;
      top: 0;

      ${Button}, ${FilterButton} {
        height: 3.2rem;
        padding: 0 0.8rem;

        ${Text}, span {
          display: inline;
        }
      }

      ${FilterButton} {
        padding: 0 0.4rem 0 0.8rem;
        width: 7.6rem;
      }
    }
  }
`;
