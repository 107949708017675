import React from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from '@prop-types';

const Play = styled('img').attrs({ src: '/images/play.svg' })``;

const isVideo = type => type === 'video';
const FileName = styled('p')``;

const ContentCard = ({ className, name, isSelected, onSelect, type }) => {
  return (
    <button
      className={className}
      data-selected={isSelected}
      onClick={onSelect}
      type="button"
    >
      {isVideo(type) && <Play />}
      <FileName>{name}</FileName>
    </button>
  );
};

ContentCard.propTypes = {
  className: PropTypes.string,
  isSelected: PropTypes.boolean,
  name: PropTypes.string,
  onSelect: PropTypes.func,
  type: PropTypes.string,
};

export default styled(ContentCard)`
  align-items: center;
  background: url(${props => props.source}) no-repeat;
  background-position: center;
  background-size: cover;
  border: 0.1rem solid ${theme('--color-light')};
  border-radius: 0.6rem;
  box-shadow: 0 0.8rem 1.6rem rgba(0, 15, 25, 0.05);
  cursor: pointer;
  display: flex;
  height: 16rem;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;

  &[data-selected='true'] {
    border: 0.1rem solid ${theme('--color-primary')};
  }

  ${Play} {
    height: 5.2rem;
    width: 5.2rem;
  }

  ${FileName} {
    background: ${theme('--color-light')};
    border-top: 0.2rem solid ${theme('--color-dark-night-10')};
    bottom: -0.1rem;
    overflow: hidden;
    padding: 1.6rem;
    position: absolute;
    text-align: left;
    text-overflow: ellipsis;
    width: 100%;
  }

  @media (${theme('--screen-small')}) {
    height: 20rem;
    width: 23rem;
  }
`;
