import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { createIsEmpty } from '@array-utils';

import * as logger from 'Actions/logger';

import DataProvider from 'Containers/DataProvider';

import { currentAccount } from 'Selectors';

import service from 'Services/integrations';

import View from './Integrations';
import EmptyTemplate from './components/EmptyTemplate';

const mapStateToProps = state => ({
  account: currentAccount(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { error: logger.error, success: logger.success },
    dispatch,
  );

class Integrations extends Component {
  static propTypes = {
    account: PropTypes.number,
    error: PropTypes.func,
    success: PropTypes.func,
  };

  state = { filterBy: 'ALL', limit: 25, offset: 0 };

  handleFilterChange = filterBy => {
    this.setState({ filterBy });
  };

  handleScrollEnd = () => {
    this.setState(prevState => ({
      limit: prevState.offset + prevState.limit + 25,
    }));
  };

  render() {
    const { account, error, success } = this.props;
    const { filterBy } = this.state;

    return (
      <DataProvider
        account={account}
        emptyTemplate={() => (
          <EmptyTemplate
            filterBy={filterBy}
            onFilterChange={this.handleFilterChange}
          />
        )}
        emptyVerifier={createIsEmpty('integrations')}
        error={error}
        filterBy={filterBy}
        onFilterChange={this.handleFilterChange}
        onScrollEnd={this.handleScrollEnd}
        params={{
          account,
          filters: { ...(filterBy !== 'ALL' && { filterBy }) },
        }}
        query={service.get}
        success={success}
        template={View}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Integrations);
