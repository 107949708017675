import React from 'react';
import PropTypes from '@prop-types';
import { withIntl } from '@react-intl';
import { compose } from 'redux';
import { withStyles as css, withTheme } from '@styled-components';

import { OPTIONS } from 'Modules/dates';

import LineChart from 'Components/Charts/LineChart';
import { withPlaceholder } from 'Containers/Placeholder/index';

const Chart = ({ className, data, intl, theme, unit }) => {
  const colors = [theme['--color-chart-blue'], theme['--color-chart-purple']];
  const handleTooltip = (ignore, y) =>
    intl.formatNumber(y, { maximumFractionDigits: 2 });

  const options = {
    ...OPTIONS[unit],
    ...(unit === 'hour' && { frecuency: 3 }),
  };

  const parser = value =>
    unit === 'hour'
      ? `${intl.formatTime(value, {
          hour: options.hour,
          hour12: true,
        })}-${intl.formatDate(value, { day: options.day })}`
      : intl.formatDate(value, options);

  return (
    <LineChart
      className={className}
      colors={colors}
      data={data}
      options={{
        xTickFormat: parser,
        xTicks: 4,
        y1: '$ITEMS',
        yLegend: 'UTP',
        yTooltip: handleTooltip,
      }}
    />
  );
};

Chart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.string,
      y1: PropTypes.number,
    }),
  ),
  intl: PropTypes.intl,
  theme: PropTypes.object,
  unit: PropTypes.string,
};

export default compose(
  css`
    width: 100%;
  `,
  withIntl,
  withTheme,
  withPlaceholder,
)(Chart);
