import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';

import Details from './components/Details';
import Variants from './components/Variants';

class Product extends Component {
  static propTypes = {
    account: PropTypes.number,
    className: PropTypes.string,
    data: PropTypes.shape({
      categories: PropTypes.arrayOf(PropTypes.string),
      description: PropTypes.string,
      images: PropTypes.arrayOf(PropTypes.string),
      name: PropTypes.string,
      type: PropTypes.string,
    }),
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  state = { images: [] };

  static getDerivedStateFromProps(props, state) {
    const { images } = state;

    const { images: newImages } = props.data || {};

    if (images.length === 0 && newImages) return { images: newImages };

    return state;
  }

  handleChangeImages = images => {
    this.setState({ images });
  };

  render() {
    const {
      account,
      data: { categories = [], description, name, type } = {},
      className,
      id,
    } = this.props;

    const { images = [] } = this.state;

    return (
      <section className={className}>
        <Details
          account={account}
          categories={categories}
          description={description}
          extended={!images.length}
          id={id}
          images={images}
          name={name}
          type={type}
        />
        <Variants
          extended={!images.length}
          onChange={this.handleChangeImages}
          productId={id}
          type={type}
        />
      </section>
    );
  }
}

export default styled(Product)`
  display: grid;
  grid-gap: 2.4rem 1.6rem;
  grid-template: auto / 1fr;

  @media (${theme('--level-3')}) {
    margin: 0 auto;
    max-width: 160rem;
  }
`;
