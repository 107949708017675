import React from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';

import Message from './components/Message';

const Conversation = ({ accountName, className, data = {} }) => {
  const { conversation = [], user = {} } = data;
  const userName = `${user.firstName} ${user.lastName}`;

  return (
    <div className={className}>
      {conversation.map(({ _id, _source }) => (
        <Message
          key={_id}
          accountName={accountName}
          message={_source.message}
          timestamp={_source.timestamp}
          type={_source.message_type}
          userName={userName}
        />
      ))}
    </div>
  );
};

Conversation.propTypes = {
  accountName: PropTypes.string,
  className: PropTypes.string,
  data: PropTypes.object,
};

export default styled(Conversation)`
  background-color: #ffffff;
  border-radius: 0.6rem;
  box-shadow: 0 2px 4px 0 rgb(0 15 25 / 5%);
  margin: 3.2rem auto;
  max-width: 80rem;
  padding: 3.2rem 6.4rem;
`;
