import React from 'react';
import styled, { css, theme } from '@styled-components';
import PropTypes from '@prop-types';

const Content = styled('span')``;

const EmptyBar = ({ className, children }) => (
  <div className={className}>
    <Content>{children}</Content>
  </div>
);

EmptyBar.propTypes = {
  children: PropTypes.children,
  className: PropTypes.string,
};

const fillMixin = ({ fill }) =>
  fill &&
  css`
    ${Content} {
      display: block;
      height: 100%;
      width: 100%;
    }
  `;

export default styled(EmptyBar).attrs({ 'aria-hidden': 'true' })`
  max-width: 100%;

  ${Content} {
    background: ${theme('--color-dark-night-05')};
    border-radius: 0.6rem;
    color: rgba(0, 0, 0, 0) !important;
    display: table;
    max-width: 100%;
  }

  ${fillMixin}
`;
