import React from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';
import { FormattedDate, FormattedMessage } from '@react-intl';

import Placeholder, { withPlaceholder } from 'Containers/Placeholder';

import RawAvatar from 'Components/Avatar';
import RawCurrency from 'Components/Currency';
import { Row } from 'Components/ResponsiveTable';

const Items = styled(Placeholder).attrs({ placeholder: 'Id: 0000' })``;
const Date = styled(Placeholder).attrs({ placeholder: '17 feb' })``;
const Name = styled(Placeholder).attrs({ placeholder: 'Customer name' })``;

const Wrapper = styled('div')``;

const Avatar = styled(withPlaceholder(RawAvatar))``;
const Currency = styled(withPlaceholder(RawCurrency)).attrs({
  placeholder: '35$',
})``;

const Order = ({
  id,
  customerAvatar,
  className,
  currency,
  customerName,
  date,
  empty,
  itemCount,
  onClick,
  total,
}) => (
  <Row className={className} empty={empty} onClick={onClick(id)}>
    <Wrapper>
      <Avatar fill image={customerAvatar} name={customerName} />
      <Name>{customerName}</Name>
      <Date>
        {date && (
          <FormattedDate
            day="2-digit"
            month="short"
            value={new window.Date(date)}
          />
        )}
      </Date>
      <Items>
        {itemCount && (
          <FormattedMessage
            capitalize
            id="%NUMBER%_ITEMS"
            values={{ number: itemCount }}
          />
        )}
      </Items>
      <Currency currency={currency}>{total}</Currency>
    </Wrapper>
  </Row>
);

Order.defaultProps = {
  onClick() {},
};

Order.propTypes = {
  className: PropTypes.string,
  currency: PropTypes.string,
  customerAvatar: PropTypes.string,
  customerName: PropTypes.string,
  date: PropTypes.string,
  empty: PropTypes.bool,
  id: PropTypes.number,
  itemCount: PropTypes.number,
  onClick: PropTypes.func,
  total: PropTypes.number,
};

export default styled(Order)`
  align-items: center;
  display: flex;
  height: 8rem;

  ${Wrapper} {
    align-items: flex-start;
    display: grid;
    grid-gap: 0.8rem;
    grid-template-columns: auto 1fr auto;
    width: 100%;
  }

  ${Avatar} {
    border-radius: 50%;
    grid-column: 1;
    grid-row: 1 / span 2;
    height: 4rem;
    max-height: 4rem;
    max-width: 4rem;
    min-height: 4rem;
    min-width: 4rem;
  }

  ${Name} {
    ${theme('--font-medium')}
    ${theme('--font-weight-book')}
    color: ${theme('--color-primary')};
    grid-column: 2;
    grid-row: 1;
    max-width: 16rem;
    text-align: left;
  }

  ${Date} {
    ${theme('--font-opacity-40')}
    ${theme('--font-small')}
    ${theme('--font-weight-book')}
    grid-column: 3;
    grid-row: 1;
    text-align: right;
  }

  ${Items} {
    ${theme('--font-opacity-40')}
    ${theme('--font-small')}
    ${theme('--font-weight-book')}
    grid-column: 2;
    grid-row: 2;
    text-align: left;
  }

  ${Currency} {
    ${theme('--font-small')}
    ${theme('--font-weight-medium')}

    grid-column: 3;
    grid-row: 2;
    text-align: right;
  }

  ${Currency} {
    ${theme('--font-small')}
  }
`;
