import React, { forwardRef } from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from '@prop-types';
import { FormattedMessage } from '@react-intl';

import Icon from 'Components/Icon';

const Label = styled('span')``;

const FilterButton = ({ active, className, onClick }, ref) => (
  <button
    ref={ref}
    className={className}
    data-active={active}
    onClick={onClick}
    type="button"
  >
    <FormattedMessage capitalize component={Label} id="FILTER">
      FILTER
    </FormattedMessage>
    <Icon name="filter" />
  </button>
);

FilterButton.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default styled(forwardRef(FilterButton))`
  ${theme('--font-small')}
  ${theme('--font-weight-demi')}
  align-items: center;
  background: none;
  border: none;
  border-radius: 0.6rem;
  cursor: pointer;
  display: flex;
  flex-grow: 0;
  height: 2.4rem;
  justify-content: space-evenly;
  outline: none;
  padding: 0 1rem;
  transition: all 250ms ease-in;
  width: 6.6rem;
  ${Label} {
    color: ${theme('--color-dark-night-60')};
    transition: all 250ms ease-out;
  }
  ${Icon} * {
    transition: fill 250ms ease-out;
  }
  &:focus,
  &:hover {
    background: ${theme('--color-blueish-white')};
    color: ${theme('--color-primary-80')};
    ${Icon} * {
      fill: ${theme('--color-primary')};
    }
  }
  &[data-active='true'] {
    background: linear-gradient(
      180deg,
      rgba(0, 132, 255, 0.2) 0%,
      rgba(0, 132, 255, 0.1) 100%
    );
    color: ${theme('--color-primary')};
    ${Icon} * {
      fill: ${theme('--color-primary')};
    }
  }
  @media (${theme('--screen-small')}) {
    visibility: visible;
  }
`;
