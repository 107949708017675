import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@styled-components';

import DataProvider from 'Containers/DataProvider';

import { currentAccount } from 'Selectors';

import service from 'Services/customers';

import View from './CustomersTable';

const mapStateToProps = state => ({
  account: currentAccount(state),
});

const CustomerTable = ({ account, ...props }) => (
  <DataProvider
    account={account}
    params={{ account }}
    query={service.groups}
    template={View}
    {...props}
  />
);

CustomerTable.propTypes = {
  account: PropTypes.number,
};

export default compose(withStyles``, connect(mapStateToProps))(CustomerTable);
