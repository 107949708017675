import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled, { theme } from '@styled-components';

import CustomDatePicker from 'Components/CustomDatePicker';
import TimezonePicker from 'Components/TimezonePicker';
import { Input, Select } from 'Components/Form';

const Colon = styled('span')``;
const Hours = styled(Input)``;
const Minutes = styled(Input)``;
const Time = styled('div')``;

const selectOption = [
  { name: 'AM', value: 'AM' },
  { name: 'PM', value: 'PM' },
];

const getData = ({ cycle, date, hours, minutes, timezone }) => {
  const dateTime = `${date.format('MM/DD/YYYY')} ${hours}:${minutes} ${cycle}`;

  return { dateTime, timezone };
};

const DateTimePicker = ({
  className,
  defaultDateTime,
  defaultTimezone,
  id = 'date-time-picker',
  onChange,
}) => {
  const defaultDateTimeMoment = defaultDateTime
    ? moment(defaultDateTime)
    : null;
  const [data, setData] = useState({
    cycle: defaultDateTimeMoment?.format('A') || 'PM',
    date: defaultDateTimeMoment || moment(),
    hours: defaultDateTimeMoment?.format('hh') || '',
    minutes: defaultDateTimeMoment?.format('mm') || '',
    timezone: defaultTimezone || moment.tz.guess(true),
  });
  const { cycle, date, hours, minutes, timezone } = data;

  const handleDataChange = key => val => {
    const nextData = { ...data, [key]: val };

    setData(nextData);

    if (nextData.hours && nextData.minutes) {
      onChange(getData(nextData));
    }
  };

  const handleHours = v => {
    const value = v.replaceAll(/[^\d]/g, '');
    let val;

    if (value > 24) {
      val = 12;
    } else if (value > 12) {
      val = value > 21 ? value - 12 : `0${value - 12}`;
    } else {
      val = value;
    }

    handleDataChange('hours')(val);
  };

  const handleMinutes = v => {
    const val = v.replaceAll(/[^\d]/g, '');

    handleDataChange('minutes')(val > 59 ? 59 : val);
  };

  return (
    <div className={className}>
      <CustomDatePicker
        date={date}
        id={id}
        onChange={handleDataChange('date')}
      />
      <Time>
        <Hours
          label="Hr"
          onChange={handleHours}
          placeholder="00"
          value={hours}
        />
        <Minutes
          label="Min"
          onChange={handleMinutes}
          placeholder="00"
          value={minutes}
        />
        <Select
          onChange={handleDataChange('cycle')}
          options={selectOption}
          value={cycle}
        />
        <Colon>:</Colon>
      </Time>
      <TimezonePicker
        onChange={handleDataChange('timezone')}
        timezone={timezone}
      />
    </div>
  );
};

DateTimePicker.propTypes = {
  className: PropTypes.string,
  defaultDateTime: PropTypes.string,
  defaultTimezone: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
};

export default styled(DateTimePicker)`
  ${Colon} {
    ${theme('--font-medium')}
    align-self: center;
    grid-column: 1 / span 2;
    grid-row: 1;
    text-align: center;
  }

  ${Hours} {
    grid-column: 1;
    grid-row: 1;
  }

  ${Minutes} {
    grid-column: 2;
    grid-row: 1;
  }

  ${Time} {
    column-gap: 1.2rem;
    display: grid;
    grid-template-columns: 5.6rem 5.6rem 9rem;
    margin-bottom: 2.3rem;
    margin-top: 1.4rem;
  }
`;
