import React from 'react';
import PropTypes from '@prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import Modal from 'Components/Modal';
import DataProvider from 'Containers/DataProvider';

import * as logger from 'Actions/logger';

import service from 'Services/faqs';

import View from './AddFaq';

import { currentAccount, role as getRole } from 'Selectors';

const mapStateToProps = state => ({
  account: currentAccount(state),
  role: getRole(state),
});

const AddFaq = ({
  account,
  error,
  success,
  depth,
  in: modalIn,
  onBack,
  onClose,
  order,
  role,
}) => {
  return (
    <Modal
      depth={depth}
      in={modalIn}
      onBack={onBack}
      onClose={onClose}
      order={order}
      title="ADD_FAQ"
    >
      <DataProvider
        account={account}
        handleError={error}
        onClose={onClose}
        params={{ account }}
        query={service.locales}
        role={role}
        success={success}
        template={View}
      />
    </Modal>
  );
};

AddFaq.propTypes = {
  account: PropTypes.number,
  depth: PropTypes.number,
  error: PropTypes.func,
  in: PropTypes.bool,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  order: PropTypes.number,
  role: PropTypes.string,
  success: PropTypes.func,
};

export default compose(
  connect(mapStateToProps, { error: logger.error, success: logger.success }),
)(AddFaq);
