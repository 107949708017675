import React from 'react';
import PropTypes from '@prop-types';
import styled, { theme } from '@styled-components';

import Button from 'Components/Button';

import Icon from '../../../Icon';
import Chip from '../../../Chip';
import List, { Item } from '../../../List';

export const Input = styled('input')``;
const Wrapper = styled('span')``;

const Tag = ({ element, name, onClick = () => {}, open, visible = true }) =>
  element || (
    <Chip data-tag={name} data-visible={visible}>
      {open && (
        <Button onClick={onClick} type="button">
          <Icon name="close-tag">ACTIONS.REMOVE_TAG</Icon>
        </Button>
      )}
      <Wrapper>{name}</Wrapper>
    </Chip>
  );

Tag.propTypes = {
  element: PropTypes.bool,
  name: PropTypes.string,
  onClick: PropTypes.func,
  open: PropTypes.bool,
  visible: PropTypes.bool,
};

export const Tags = ({
  children,
  className,
  data: raw = [],
  onChange = () => {},
  open,
}) => {
  const handleChange = index => event => {
    event.stopPropagation();

    onChange(raw.filter((item, i) => i !== index));
  };

  const data = raw.map((item, index) => ({
    ...item,
    key: item.name,
    onClick: handleChange(index),
    open,
  }));

  return (
    <List
      className={className}
      items={!children ? data : [...data, { element: children, key: 'input' }]}
      template={Tag}
    />
  );
};

Tags.propTypes = {
  children: PropTypes.children,
  className: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  onChange: PropTypes.func,
  open: PropTypes.bool,
};

export default styled(Tags)`
  align-items: center;
  border: 0.1rem solid;
  border-radius: 0.6rem;
  display: flex;
  flex-wrap: wrap;
  min-height: 4.8rem;
  padding: 0.7rem 1.2rem;
  width: 100%;
  z-index: 1;

  ${Item} {
    margin: 0.4rem; /* WARNING in case you want to change this make sure you change the value inside getVisibleChips method. */

    &:last-child {
      flex: 1;
      & ${Input} {
        border: 0;
        display: block;
        height: 100%;
        min-width: 6rem;
        outline: 0;
        width: 100%;

        &:focus {
          caret-color: ${theme('--color-primary')};
        }
      }
    }
  }

  ${Chip} {
    background: #eaccff; /* --color-main-20 opacity 1 */
    border: none;
    border-radius: 0.6rem;
    color: ${theme('--color-main')};
    padding: 0.4rem 0.8rem 0.4rem;

    ${Button} {
      background: none;
      border: none;
      height: 1.6rem;
      margin-right: 0.4rem;
      outline: 0;
      padding: 0;
      width: 1.6rem;

      ${Icon} {
        height: 1.6rem;
        width: 1.6rem;
      }
    }

    ${Wrapper} {
      margin-top: 0.2rem;
    }
  }
`;
