import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';

import Card from 'Components/Card';
import { Input } from 'Components/Form';

import Table from 'Components/ResponsiveTable';

import Toolbar from 'Containers/CardToolbar';

import HOTEL from './Hospitality';
import RETAIL from './Retail';
import EmptyTemplate from './EmptyTemplate';
import EmptyTemplateFilters from './EmptyTemplateFilters';

const variantSets = {
  HOTEL,
  RETAIL,
  UNDEFINED: RETAIL,
};

const Variants = ({
  className,
  data: { collection: variants = [] } = {},
  filterAmmount,
  onChange,
  onCursorChange: handleCursorChange = () => {},
  onSearch,
  type = 'UNDEFINED',
}) => {
  const [selected, setSelected] = useState();

  const handleRowClick = useCallback(
    s => () => {
      const { images } =
        variants.find(({ id }) => s === id && s !== selected) || {};

      onChange(images);
      setSelected(selected !== s ? s : null);
    },
    [selected, onChange, variants],
  );

  const variant = variantSets[type];

  return (
    <Card className={className}>
      <Toolbar filterAmmount={filterAmmount} title="VARIANTS">
        <Input label="SEARCH" name="search" onChange={onSearch} type="search" />
      </Toolbar>
      <Table
        desktopRows={7}
        emptyTemplate={filterAmmount ? EmptyTemplateFilters : EmptyTemplate}
        items={variants}
        onCursorChange={handleCursorChange}
        options={{
          onClick: handleRowClick,
          selected,
        }}
        {...variant}
        rows={5}
      />
    </Card>
  );
};

Variants.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    collection: PropTypes.array,
    total: PropTypes.number,
  }),
  filterAmmount: PropTypes.number,
  onChange: PropTypes.func,
  onCursorChange: PropTypes.func,
  onSearch: PropTypes.func,
  type: PropTypes.string,
};

export default styled(Variants)`
  display: flex;
  flex-flow: column;
  padding: 0;

  @media (${theme('--screen-small')}) {
    ${Input} {
      width: 24rem;
    }
  }
`;
