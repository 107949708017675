import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Header from './Header';

const Group = ({
  className,
  components,
  customers,
  name,
  onGroupAdd,
  onListToggle,
}) => {
  const handleContactsToggle = () => {
    onGroupAdd(customers);
    onListToggle();
  };

  return (
    <div className={className}>
      <Header
        components={components}
        name={name}
        onClick={handleContactsToggle}
      />
    </div>
  );
};

Group.propTypes = {
  className: PropTypes.string,
  components: PropTypes.string.isRequired,
  customers: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  onGroupAdd: PropTypes.func.isRequired,
  onListToggle: PropTypes.func.isRequired,
};

export default styled(Group)``;
