import React from 'react';
import PropTypes from 'prop-types';
import styled, { withStyles as css, theme } from '@styled-components';
import { compose } from 'underscore';

import Button from 'Components/Button';
import Icon from 'Components/Icon';

const Content = styled('div')``;
const Header = styled('header')``;
const Name = styled('h3')``;
const Title = styled('p')``;

const PlayIcon = styled('img')``;

const Card = ({
  className,
  source,
  name,
  onClick: handleClick = () => {},
  type,
}) => {
  return (
    <section className={className}>
      <Header
        style={
          source && {
            backgroundImage: `url(${source})`,
            backgroundSize: 'cover',
          }
        }
      />
      <Content>
        <Name capitalize translate>
          {name}
        </Name>
      </Content>

      <Button onClick={handleClick}>
        <Icon outline name="trash-bin">
          ACTIONS.DELETE
        </Icon>
      </Button>
      {type === 'video' && <PlayIcon src="/images/play.svg" />}
    </section>
  );
};

Card.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  source: PropTypes.string,
  type: PropTypes.string,
};

export default compose(
  css`
    border-radius: 0.6rem;
    box-shadow: inset 0 0.1rem 0 rgba(0, 0, 0, 0.1);
    position: relative;

    ${Header} {
      background-color: ${theme('--color-primary-10')};
      background-image: url(/images/placeholder.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: auto;
      border-radius: 0.6rem 0.6rem 0 0;
      height: 14.8rem;
    }

    ${Content} {
      background: ${theme('--color-light')};
      border-radius: 0 0 0.6rem 0.6rem;
      padding: 1.6rem;

      ${Name} {
        ${theme('--font-opacity-80')}
        ${theme('--font-weight-demi')}
        margin-bottom: 0.4rem;
      }

      ${Title} {
        ${theme('--font-opacity-60')}
        ${theme('--font-weight-book')}
        margin-bottom: 1.2rem;
      }
    }

    ${Button} {
      background-color: white;
      height: 3.2rem;
      position: absolute;
      right: 1.2rem;
      top: 1.2rem;
      width: 3.2rem;
    }

    ${PlayIcon} {
      left: 0;
      margin: 0 auto;
      position: absolute;
      right: 0;
      top: calc(25%);
    }
  `,
)(Card);
