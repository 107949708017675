import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import styled, { withStyles } from '@styled-components';

import Summary from 'Components/Summary';

import { getUnit } from 'Modules/dates';
import DataProvider from 'Containers/DataProvider';

import { currency as currentCurrency, currentAccount } from 'Selectors';

import service from 'Services/campaigns';

const CustomerSummary = styled(Summary).attrs({ accent: true })``;

const mapStateToProps = state => ({
  account: currentAccount(state),
  currency: currentCurrency(state),
});

const CustomerLifetimeChart = ({
  account,
  className,
  currency,
  from = moment()
    .subtract(1, 'year')
    .format(),
  to = moment().format(),
}) => {
  const unit = getUnit(from, to);

  return (
    <DataProvider
      params={{ account, currency, from, to, unit }}
      query={service.getSumarryAnalytics}
      template={({ data = {} }) => {
        const chipData = {
          avgSuccessGrowth:
            data.growth && data.growth.avgSuccessGrowth
              ? parseFloat(data.growth.avgSuccessGrowth)
              : 0,
          deliveredGrowth:
            data.growth && data.growth.deliveredGrowth
              ? parseFloat(data.growth.deliveredGrowth)
              : 0,
          reachGrowth:
            data.growth && data.growth.reachGrowth
              ? parseFloat(data.growth.reachGrowth)
              : 0,
        };
        const chipStatus = {};

        if (chipData.avgSuccessGrowth < 0) {
          chipData.avgSuccessGrowth = Number.isInteger(
            chipData.avgSuccessGrowth,
          )
            ? `${chipData.avgSuccessGrowth}%`
            : `${chipData.avgSuccessGrowth.toFixed(1)}%`;
          chipStatus.avgSuccessGrowth = 'red';
        } else {
          chipData.avgSuccessGrowth = Number.isInteger(
            chipData.avgSuccessGrowth,
          )
            ? `+${chipData.avgSuccessGrowth}%`
            : `+${chipData.avgSuccessGrowth.toFixed(1)}%`;
          chipStatus.avgSuccessGrowth = 'green';
        }

        if (chipData.deliveredGrowth < 0) {
          chipData.deliveredGrowth = Number.isInteger(chipData.deliveredGrowth)
            ? `${chipData.deliveredGrowth}%`
            : `${chipData.deliveredGrowth.toFixed(1)}%`;
          chipStatus.deliveredGrowth = 'red';
        } else {
          chipData.deliveredGrowth = Number.isInteger(chipData.deliveredGrowth)
            ? `+${chipData.deliveredGrowth}%`
            : `+${chipData.deliveredGrowth.toFixed(1)}%`;
          chipStatus.deliveredGrowth = 'green';
        }

        if (chipData.reachGrowth < 0) {
          chipData.reachGrowth = Number.isInteger(chipData.reachGrowth)
            ? `${chipData.reachGrowth}%`
            : `${chipData.reachGrowth.toFixed(1)}%`;
          chipStatus.reachGrowth = 'red';
        } else {
          chipData.reachGrowth = Number.isInteger(chipData.reachGrowth)
            ? `+${chipData.reachGrowth}%`
            : `+${chipData.reachGrowth.toFixed(1)}%`;
          chipStatus.reachGrowth = 'green';
        }

        return (
          <>
            <CustomerSummary
              className={className}
              heading={data.totalCampaigns || 0}
              highlight="true"
              title="TOTAL_CAMPAIGNS"
            />
            <CustomerSummary
              chipData={chipData.deliveredGrowth}
              chipStatus={chipStatus.deliveredGrowth}
              className={className}
              heading={data.totalDelivered || 0}
              title="MESSAGES_DELIVERED"
            />
            <CustomerSummary
              chipData={chipData.reachGrowth}
              chipStatus={chipStatus.reachGrowth}
              className={className}
              heading={data.totalReach || 0}
              title="CUSTOMERS_REACHED"
            />
            <CustomerSummary
              percent
              chipData={chipData.avgSuccessGrowth}
              chipStatus={chipStatus.avgSuccessGrowth}
              className={className}
              heading={
                data.avgAllSuccessRate ? data.avgAllSuccessRate.toFixed(1) : 0
              }
              title="AVERAGE_SUCCESS_RATE"
            />
          </>
        );
      }}
    />
  );
};

CustomerLifetimeChart.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
  currency: PropTypes.string,
  from: PropTypes.string,
  to: PropTypes.string,
};

export default compose(
  connect(mapStateToProps),
  withStyles``,
)(CustomerLifetimeChart);
