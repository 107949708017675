import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@react-intl';
import styled, { theme } from '@styled-components';

import { withPlaceholder } from 'Containers/Placeholder';

import AvatarSelector from 'Components/AvatarSelector';
import Button from 'Components/Button';
import Form, { Input } from 'Components/Form';
import Tip from 'Components/Tip';

import { editCompanyData as editCompanyDataRules } from 'Rules';

import ColorPicker from './components/ColorPicker';
import {
  CompanyNameTooltip,
  StyleTooltip,
  CompanyPictureTooltip,
} from './components/Tooltips';

const Actions = styled('div')``;
const Paragraph = styled('p')``;
const Title = styled('h3')``;
const ThemeTitle = styled(Title)``;
const Subtitle = styled('h3')``;
const SectionHeading = styled('div')``;

const EditCompany = ({
  className,
  logo,
  data: { name: companyName, primaryColor, secondaryColor } = {},
  onSubmit = () => {},
}) => (
  <div className={className}>
    <Form
      constraint={editCompanyDataRules}
      defaultValue={{ companyName, logo, primaryColor, secondaryColor }}
      onSubmit={onSubmit}
    >
      <FormattedMessage capitalize component={Title} id="EDIT_COMPANY.TITLE">
        Company and chatbot customization
      </FormattedMessage>
      <FormattedMessage
        capitalize
        component={Paragraph}
        id="INSTRUCTIONS.EDIT_COMPANY"
      >
        use this page to personalice your chatbot
      </FormattedMessage>
      <AvatarSelector
        image={logo}
        label="COMPANY_LOGO"
        name="logo"
        tooltip={CompanyPictureTooltip}
      />
      <SectionHeading>
        <FormattedMessage capitalize component={Subtitle} id="COMPANY_NAME">
          Company name
        </FormattedMessage>
        <Tip>
          <CompanyNameTooltip />
        </Tip>
      </SectionHeading>
      <Input label="PLACEHOLDER_COMPANY_NAME" name="companyName" type="text" />
      <SectionHeading>
        <FormattedMessage capitalize component={ThemeTitle} id="THEME">
          Theme
        </FormattedMessage>
        <Tip>
          <StyleTooltip />
        </Tip>
      </SectionHeading>

      <FormattedMessage
        capitalize
        component={Paragraph}
        id="INSTRUCTIONS.CONFIGURE_COLORS"
      >
        configure your company brand colors
      </FormattedMessage>
      <FormattedMessage capitalize component={Subtitle} id="COLOR_PRIMARY">
        primary color
      </FormattedMessage>
      <ColorPicker label="COLOR_PRIMARY" name="primaryColor" type="color" />
      <FormattedMessage capitalize component={Subtitle} id="COLOR_SECONDARY">
        secondary color
      </FormattedMessage>
      <ColorPicker label="COLOR_SECONDARY" name="secondaryColor" type="color" />
      <Actions>
        <Button capitalize primary translate type="submit">
          ACTIONS.SAVE_CHANGES
        </Button>
      </Actions>
    </Form>
  </div>
);

EditCompany.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    name: PropTypes.string,
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string,
  }),
  logo: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default withPlaceholder(styled(EditCompany)`
  display: flex;
  flex-wrap: wrap;

  & ${Form} {
    margin: 0 auto;
    max-width: 38.4rem;

    ${Title} {
      ${theme('--font-large')}
      ${theme('--font-weight-medium')}
      margin-bottom: 1.2rem;
    }

    ${ThemeTitle} {
      margin-top: 5rem;
    }

    ${Subtitle} {
      ${theme('--font-medium')}
      ${theme('--font-weight-medium')}
      margin-bottom: 1.2rem;
      margin-top: 2.4rem;
    }

    ${Paragraph} {
      ${theme('--font-medium')}
      ${theme('--font-opacity-40')}
    }

    ${AvatarSelector} {
      padding: 2.4rem 0 0 0;
      ${Input} {
        margin: 0;
      }
    }

    ${SectionHeading} {
      position: relative;

      ${Tip} {
        bottom: 0rem;
        position: absolute;
        right: 0rem;
      }
    }

    ${Input} {
      margin-bottom: 1.6rem;
    }

    ${Actions} {
      display: flex;
      justify-content: space-between;
      margin-top: 4rem;

      ${Button} {
        text-align: center;
        width: 14rem;
      }
    }
  }

  @media (${theme('--level-2')}) {
    ${Title} {
      margin-bottom: 0.8rem;
    }
  }
`);
