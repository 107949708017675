import React from 'react';
import PropTypes from '@prop-types';
import { FormattedMessage } from '@react-intl';
import styled, { theme, withStyles as css } from '@styled-components';
import { compose } from 'underscore';

import { withPlaceholder } from 'Containers/Placeholder';

import Avatar from 'Components/Avatar';
import Button from 'Components/Button';
import Card from 'Components/Card';
import Icon from 'Components/Icon';
import Link from 'Components/Link';

import Color from './Color';

const Colors = styled('div')``;
const Info = styled('div')``;
const Name = styled('h2')``;

const CompanyCard = ({
  className,
  link,
  logo,
  data: { name, primaryColor, secondaryColor } = {},
}) => {
  return (
    <Card className={className} title="COMPANY_INFORMATION">
      <Button as={Link} to={link}>
        <Icon outline name="edit">
          ACTIONS.EDIT_PROFILE
        </Icon>
      </Button>
      <Avatar image={logo} name={name} />
      <Info>
        <Name>{name}</Name>
      </Info>
      <Colors>
        <FormattedMessage capitalize component={Name} id="THEME" />
        <Color color={primaryColor} id="COLOR_PRIMARY" />
        <Color color={secondaryColor} id="COLOR_SECONDARY" />
      </Colors>
    </Card>
  );
};

CompanyCard.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    name: PropTypes.string,
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string,
  }),
  link: PropTypes.string,
  logo: PropTypes.string,
};

export default compose(
  withPlaceholder,
  css`
    align-items: center;
    display: flex;
    flex-direction: column;

    ${Name} {
      ${theme('--font-medium')}
      ${theme('--font-weight-medium')}
    }

    ${Info} {
      border-bottom: 0.1rem solid ${theme('--color-dark-night-10')};
      padding-bottom: 2.4rem;
      width: 100%;

      ${Name} {
        text-align: center;
      }
    }

    ${Link} {
      border-radius: 0.6rem;
      box-shadow: 0 0.8rem 1.6rem rgba(0, 15, 25, 0.05),
        0 0.1rem 0 rgba(0, 15, 25, 0.05);
      height: 3.2rem;
      position: absolute;
      right: 2.2rem;
      top: 2.2rem;
      width: 3.2rem;
    }

    ${Avatar} {
      border: 0.8rem solid #fff;
      box-shadow: 0 0.2rem 0.8rem rgba(0, 15, 25, 0.05);
      margin: 0 0 3.2rem;
      min-height: 13.6rem;
      width: 13.6rem;
    }

    ${Button} {
      box-shadow: none;
    }

    ${Colors} {
      padding-top: 2.8rem;
      width: 100%;

      ${Name} {
        padding-bottom: 1.6rem;
      }

      ${Color} {
        padding-bottom: 0.8rem;
      }
    }
  `,
)(CompanyCard);
