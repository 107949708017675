import React, { useCallback, useEffect, useState } from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from '@prop-types';
import { FormattedMessage } from '@react-intl';
import { useField } from 'spooner';

import { Textarea } from 'Components/Form';
import Tip from 'Components/Tip';

import Toggle from './Toggle';
import SectionHeadline from './SectionHeadline';

const toggle = [
  'FIRST_NAME',
  'LAST_NAME',
  'ADDRESS',
  'PHONE',
  'EMAIL',
  'TERMS_AND_CONDITIONS',
];

const Wrapper = styled('div')``;
const SelectAllLabel = styled('span')``;
const Checkbox = styled('button').attrs({ type: 'button' })``;
const TermsAndCondition = styled('section')``;
const Tooltip = styled('div')``;

const FormConfig = ({ className, defaultValue = [] }) => {
  const [fields, setFields] = useState(
    toggle.reduce(
      (acc, field) => ({
        ...acc,
        [field]: defaultValue.includes(field),
      }),
      {},
    ),
  );
  const [selectedAll, setSelectedAll] = useState(false);
  const { onChange } = useField('fields');
  const hasTermsAndConditions = fields.TERMS_AND_CONDITIONS;

  const handleChange = useCallback(
    field => v => {
      setFields(prevFields => ({ ...prevFields, [field]: v === 'on' }));
    },
    [setFields],
  );

  const handleToggleAll = () => {
    const selected = !selectedAll;

    setSelectedAll(selected);
    setFields(
      toggle.reduce(
        (acc, field) => ({
          ...acc,
          [field]: selected,
        }),
        {},
      ),
    );
  };

  useEffect(() => {
    onChange(Object.keys(fields).filter(item => fields[item]));
  }, [fields, onChange]);

  return (
    <div className={className}>
      <Wrapper>
        <Checkbox
          data-checked={selectedAll === true}
          onClick={handleToggleAll}
        />
        <SelectAllLabel capitalize translate onClick={handleToggleAll}>
          SELECT_ALL
        </SelectAllLabel>
      </Wrapper>
      {toggle.map(name => (
        <Toggle
          id={`PLACEHOLDER.${name}`}
          name={name}
          onChange={handleChange}
          value={fields[name] ? 'on' : 'off'}
        />
      ))}
      {hasTermsAndConditions && (
        <TermsAndCondition>
          <SectionHeadline
            heading="INSTRUCTIONS.TEXT_BLOCK"
            title="TERMS_AND_CONDITIONS_BLOCK"
          />
          <Tip>
            <FormattedMessage component={Tooltip} id="INSTRUCTIONS.HTML_TEXT" />
          </Tip>
          <Textarea
            capitalize
            label="PLACEHOLDER.TERMS_AND_CONDITIONS"
            name="terms"
          />
        </TermsAndCondition>
      )}
    </div>
  );
};

FormConfig.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.array,
};

export default styled(FormConfig)`
  background: ${theme('--color-light')};
  border-radius: 0.6rem;
  display: grid;
  grid-auto-rows: auto;
  grid-gap: 0.8rem;
  grid-template: auto / 1fr 1fr;
  margin-top: 1.8rem;
  padding: 1.6rem;

  ${Wrapper} {
    grid-column: span 2;
    margin-bottom: 0.8rem;

    ${Checkbox} {
      background: ${theme('--color-light')};
      background-image: url('/images/icons/check.svg');
      background-position: center;
      background-repeat: no-repeat;
      border: 0.1rem solid ${theme('--color-dark-night-10')};
      border-radius: 0.4rem;
      height: 1.6rem;
      position: relative;
      top: 0.2rem;
      transition: background-color 150ms ease, border-color 150ms ease;
      width: 1.6rem;

      &[data-checked='true'] {
        background-color: ${theme('--color-primary')};
        border-color: ${theme('--color-primary')};
      }
    }

    ${SelectAllLabel} {
      cursor: pointer;
      margin-left: 0.8rem;
    }
  }

  ${TermsAndCondition} {
    grid-column: span 2;
    position: relative;

    ${SectionHeadline} {
      padding-top: 2.4rem;
    }

    ${Tip} {
      position: absolute;
      right: 0;
      top: 2rem;
    }
  }
`;
