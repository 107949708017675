import request from 'Providers/request';
import { createService } from '@redux-act';

const locales = createService(({ account }, token) =>
  request.read(`/ofa/${account}/faq_locales`, {}, {}, { token }),
);

async function get({ account, locale }, token) {
  const response = await request.read(
    `/crm/${account}/qna/knowledgebase`,
    { locale },
    {},
    { token },
  );

  return response;
}

function add({ account, locale, media }) {
  return request.create(
    `/crm/${account}/importKb?locale=${locale}`,
    media,
    {},
    {
      headers: {
        'content-type': 'form-data/multipart',
      },
    },
  );
}

async function editDescription({ account, description, id }) {
  const response = await request(
    `/crm/${account}/qna/knowledgebase/${id}`,
    'put',
    { description },
  );

  return response;
}

async function remove({ account, id }) {
  const response = await request(`/ofa/${account}/faq/${id}`, 'delete', {});

  return response;
}

export default {
  add,
  editDescription,
  get,
  locales,
  remove,
};
