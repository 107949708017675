import React from 'react';
import styled from '@styled-components';
import PropTypes from '@prop-types';

import RadioType from './RadioType';

const PlatformSelector = ({ className, init }) => {
  return (
    <div className={className}>
      <RadioType id="FB_MESSENGER" init={init} name="platform" />
      <RadioType id="WHATSAPP" init={init} name="platform" />
    </div>
  );
};

PlatformSelector.propTypes = {
  className: PropTypes.string,
  init: PropTypes.string,
};

export default styled(PlatformSelector)`
  display: grid;
  grid-gap: 0.8rem;
  grid-template: 5.4rem 5.4rem / 1fr;
  padding: 0.8rem 0;
`;
