import { createSelector } from 'reselect';

export const accounts = state => state.accounts;

export const currentAccount = state => state.settings.account;

export const account = createSelector(
  // I need to move this here because use from before. Forgive me GOD
  accounts,
  currentAccount,
  (all = [], current) => all.find(({ id }) => current === id) || all[0],
);

export const currency = state => state.settings.currency;

export const currentEmail = state => state.session.email;

export const domain = createSelector(
  account,
  current => current && current.type,
);
export const role = createSelector(account, current => current && current.role);

export const integrations = state => state.integrations;

export const isLoggedIn = createSelector(
  state => state.session.id,
  state => state.session.token,
  (id, token) => Boolean(id && token),
);

export const me = state => state.member;

export const isLoaded = createSelector(me, ({ email }) => Boolean(email));

export const ranges = state => state.settings.ranges;

export const range = ({ settings }, name) => settings[name] || {};

export const settings = state => state.settings;
