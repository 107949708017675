import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import styled from 'styled-components';

import { Select } from 'Components/Form';

const TimezonePicker = ({ className, onChange, timezone }) => {
  const timezoneNames = moment.tz.names();
  const timezoneOffsets = timezoneNames.map(i => {
    return moment.tz(i).format('Z');
  });

  const timezoneOptionsUnsorted = timezoneNames.map((city, index) => {
    const value = `(UTC ${timezoneOffsets[index]}) ${city.replaceAll(
      '_',
      ' ',
    )}`;

    return {
      name: value,
      techName: city,
      value,
    };
  });

  const timezoneOptions = timezoneOptionsUnsorted.sort((a, b) =>
    a.name.localeCompare(b.name),
  );

  const handleTimezone = value => {
    onChange(timezoneOptions.find(option => option.value === value).techName);
  };

  const currentTimezone = timezoneOptions.find(
    option => option.techName === timezone,
  ).value;

  return (
    <Select
      className={className}
      onChange={handleTimezone}
      options={timezoneOptions}
      value={currentTimezone}
    />
  );
};

TimezonePicker.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  timezone: PropTypes.string,
};

export default styled(TimezonePicker)`
  width: 32rem;
`;
