import styled from '@styled-components';

const Content = styled('main')`
  flex-grow: 1;
  height: calc(100vh - 8rem);
  overflow: auto;
  width: 100%;
`;

export default Content;
