import React from 'react';
import PropTypes from '@prop-types';
import styled, { theme, withStyles as css } from '@styled-components';
import { FormattedMessage } from '@react-intl';
import { compose } from 'underscore';

import Icon from 'Components/Icon';
import Link from 'Components/Link';
import { withRouter } from 'Components/Router';

const Title = styled('h2')``;
const Content = styled('p')``;
const Actions = styled('nav')``;

const Button = styled(Link)``;

const EmptyTemplate = ({ className }) => (
  <article className={className}>
    <Icon name="campaigns-empty">CAMPAIGNS_EMPTY</Icon>
    <FormattedMessage
      capitalize
      component={Title}
      id="EMPTY_CAMPAINGS.CREATE_CAMPAINGS"
    >
      Oops! we did not find any campaign yet
    </FormattedMessage>
    {/* <FormattedMessage
      capitalize
      component={Content}
      id="INSTRUCTIONS.EMPTY_CAMPAINGS.CREATE_CAMPAINGS"
    >
      You can create some campaigns and they would be listed here
    </FormattedMessage>
    <Actions>
      <FormattedMessage
        capitalize
        component={Button}
        id="ACTIONS.CREATE"
        to="/campaigns/create"
      >
        create
      </FormattedMessage>
    </Actions> */}
  </article>
);

EmptyTemplate.propTypes = {
  className: PropTypes.string,
};

export default compose(
  css`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    min-height: 28rem;

    ${Icon} {
      height: 10.4rem;
      margin-bottom: 2.4rem;
      width: 10.4rem;
    }

    ${Title} {
      ${theme('--font-large')}
      ${theme('--font-weight-book')}
      ${theme('--font-opacity-100')}
      margin-bottom: 0.4rem;
    }

    ${Content} {
      ${theme('--font-medium')}
      ${theme('--font-weight-book')}
      ${theme('--font-opacity-40')}
      margin-bottom: 2.4rem;
    }

    ${Actions} {
      display: flex;

      ${Button} {
        ${theme('--font-medium')}
        ${theme('--font-weight-demi')}
        align-items: center;
        background-color: ${theme('--color-primary')};
        border-radius: 0.6rem;
        color: ${theme('--color-light')};
        display: flex;
        height: 4rem;
        justify-content: center;
        margin: 0 0.8rem;
        width: 12.4rem;
      }
    }
  `,
  withRouter,
)(EmptyTemplate);
