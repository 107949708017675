import React, { Component } from 'react';
import countries, { common } from '@countries';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, withIntl, intlShape } from '@react-intl';
import styled, { withStyles, theme } from '@styled-components';
import _ from 'underscore';

import { Input, Select } from 'Components/Form';

const Address = styled(Input)``;
const City = styled(Input)``;
const Country = styled(Select)``;
const Legend = styled('legend')``;
const PostalCode = styled(Input)``;
const State = styled(Input)``;

class DeliveryAddress extends Component {
  options = _.memoize(() => {
    const {
      intl,
      intl: { locale },
    } = this.props;

    let options = countries.map(country => ({
      name: common(country.cca2, locale),
      value: country.cca2,
    }));
    options = options.sort((a, b) => a.name.localeCompare(b.name));

    options.unshift({
      disabled: true,
      name: intl.formatMessage({ capitalize: true, id: 'DEFAULT_COUTRY' }),
      value: '',
    });

    return options;
  });

  static propTypes = {
    className: PropTypes.string,
    intl: intlShape,
  };

  render() {
    const { className } = this.props;

    return (
      <div className={className}>
        <FormattedMessage capitalize component={Legend} id="DELIVERY_ADDRESS" />
        <Address label="PLACEHOLDER.ADDRESS_1" name="street1" />
        <Address label="PLACEHOLDER.ADDRESS_2" name="street2" />
        <City label="PLACEHOLDER.CITY" name="city" />
        <State label="PLACEHOLDER.STATE" name="region" />
        <PostalCode label="PLACEHOLDER.POSTAL_CODE" name="postalCode" />
        <Country name="country" options={this.options()} />
      </div>
    );
  }
}

export default compose(
  withStyles`
    display: grid;
    grid-gap: 1.6rem;
    grid-template: auto / auto;
    margin-bottom: 4.8rem;

    ${Legend} {
      ${theme('--font-small')}
      ${theme('--font-opacity-100')}
      ${theme('--font-weight-demi')}
      grid-column: span 4;
    }

    ${Address}, ${City}, ${State}, ${PostalCode}, ${Country} {
      grid-column: span 4;
    }

    @media (${theme('--column-12')}) {
      grid-template: 1fr / repeat(12, 1fr);
  
      ${Legend} {
        grid-column: span 12;
      }

      ${Address} {
        grid-column: span 12;
      }

      ${City}, ${State}, ${PostalCode}, ${Country} {
        grid-column: span 6;
      }
    }
    
  `,
  withIntl,
)(DeliveryAddress);
