import { put } from 'redux-saga/effects';
import C from 'Constants';

import * as session from 'Actions/session';

import * as logger from 'Actions/logger';

import log from 'Providers/logger';

const { ERROR } = C;

export function* handleErrors(error) {
  yield put(logger.error(error.message));

  switch (error.message) {
    case ERROR.TOKEN_EXPIRED:
    case ERROR.UNAUTHORIZED:
      yield put(session.logout());
      log.warning(error);
      break;
    default:
      log.error(error);
      break;
  }
}

export default {
  handleErrors,
};
