import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate } from '@react-intl';
import styled, { theme, css } from '@styled-components';
import Placeholder, { withFetchingState } from 'Containers/Placeholder';

const Time = styled(Placeholder.div)``;
const Title = styled(Placeholder.h4)``;

export const Event = ({ className, date = new Date(), title, timezone }) => (
  <article className={className}>
    <Time placeholder="event datetime">
      <FormattedDate
        day="2-digit"
        hour="numeric"
        minute="numeric"
        month="long"
        timeZone={timezone}
        timeZoneName="short"
        value={date}
        year="numeric"
      />
    </Time>
    <Title placeholder="event long description">{title}</Title>
  </article>
);

Event.propTypes = {
  className: PropTypes.string,
  date: PropTypes.instanceOf(window.Date).isRequired,
  timezone: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default withFetchingState(styled(Event)`
  margin-left: 0.8rem;
  padding-bottom: 2.4rem;
  padding-left: 2.2rem;
  position: relative;

  ${Time} {
    ${theme('--font-small')}
    ${theme('--font-weight-book')}
    color: ${theme('--color-dark-night-60')};
    position: relative;

    &::before {
      background-color: ${theme('--color-primary')};
      border-radius: 50%;
      content: '';
      display: block;
      height: 1.6rem;
      left: -3rem;
      position: absolute;
      width: 1.6rem;
    }
  }

  ${Title} {
    ${theme('--font-medium')}
    ${theme('--font-weight-demi')}
    color: ${theme('--color-dark-night')};
    margin-top: 0.8rem;
  }

  &::after {
    background-color: ${theme('--color-primary-20')};
    border-radius: 0.8rem;
    content: '';
    display: block;
    height: calc(100% + 1.6rem);
    left: -0.8rem;
    position: absolute;
    top: 0;
    width: 1.6rem;
  }

  ${({ loading, empty }) =>
    (loading || empty) &&
    css`
      ${Time}::before {
        background-color: ${theme('--color-dark-night-10')};
      }

      &::after {
        background-color: ${theme('--color-dark-night-10')};
      }
    `}
`);
