import React from 'react';
import PropTypes from '@prop-types';
import styled, { theme } from '@styled-components';
import { FormattedTime } from '@react-intl';

const Header = styled('div')``;
const Sender = styled('p')``;
const Time = styled(FormattedTime)``;

const Message = ({
  accountName,
  className,
  message,
  timestamp,
  type,
  userName,
}) => {
  const userMessage = type === 'USER_MESSAGE';

  return (
    <div className={className} data-highlight={userMessage}>
      <Header>
        <Sender>{userMessage ? userName : accountName}</Sender>
        <Time hour12 value={new Date(timestamp)} />
      </Header>
      <p>{message}</p>
    </div>
  );
};

Message.propTypes = {
  accountName: PropTypes.string,
  className: PropTypes.string,
  message: PropTypes.string,
  timestamp: PropTypes.string,
  type: PropTypes.string,
  userName: PropTypes.string,
};

export default styled(Message)`
  background-color: #ffffff;
  border-radius: 0.6rem;
  box-shadow: 0 2px 4px 0 rgb(0 15 25 / 5%);
  margin: 1.2rem;
  max-width: 50rem;
  padding: 1.2rem 1.6rem;

  &[data-highlight='true'] {
    background-image: linear-gradient(
      160deg,
      ${theme('--color-secondary')} 0%,
      ${theme('--color-trust')} 100%
    );
    color: ${theme('--color-light')};
    margin-left: auto;

    & p {
      text-align: right;
    }

    ${Sender} {
      grid-column: 2;
    }

    ${Time} {
      grid-column: 1;
      grid-row: 1;
      margin-left: -0.5rem;
      margin-top: -0.5rem;
    }
  }

  &[data-highlight='false'] {
    background-image: linear-gradient(
      160deg,
      ${theme('--color-dark-night-05')} 0%,
      ${theme('--color-dark-night-10')} 100%
    );
    color: ${theme('--color-dark-night')};

    ${Time} {
      margin-right: -0.5rem;
      margin-top: -0.5rem;
      text-align: right;
    }
  }

  ${Header} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  ${Sender} {
    font-weight: bold;
  }

  ${Time} {
    font-size: 1rem;
  }
`;
