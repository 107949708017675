import { connect } from 'react-redux';
import { domain } from 'Selectors';

import L10n from './L10n';

const mapStateToProps = state => ({
  domain: domain(state),
});

export default connect(mapStateToProps)(L10n);
