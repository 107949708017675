import React from 'react';
import PropTypes from 'prop-types';
import styled from '@styled-components';

export const Toolbar = ({ className, children }) => (
  <section className={className}>{children}</section>
);

Toolbar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
};

export default styled(Toolbar)`
  display: flex;
  height: 100%;
  width: 100%;
`;
