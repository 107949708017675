import lands from '@geo-maps/countries-land-10km/map.geo.json';
import { CCA3 as CCA3FromLocale } from '@langs';
import countries from 'world-countries';

const countriesByCode = countries.reduce(
  (acc, country) => ({
    ...acc,
    [country.cca2]: country,
    [country.cca3]: country,
  }),
  {},
);

export function CCA2(code = '') {
  const country = countriesByCode[code.toUpperCase()];

  return country ? country.cca2 : code;
}

const landsByCodes = lands.features.reduce((acc, _land) => {
  const { A3 } = _land.properties;

  return { ...acc, [CCA2(A3)]: _land, [A3]: _land };
}, {});

export function CCA3(code = '') {
  const country = countriesByCode[code.toUpperCase()];

  return country ? country.cca3 : code;
}

export function common(code, locale = 'en') {
  const A3 = CCA3FromLocale(locale);
  const country = countriesByCode[code.toUpperCase()];

  if (!country) {
    return code;
  }

  return country.translations[A3]
    ? country.translations[A3].common
    : country.name.common;
}

export function land(code = '') {
  return landsByCodes[code.toUpperCase()];
}

export default countries;
