import breakpoints from './breakpoints';
import colors from './colors';
import deprecated from './deprecated';
import fonts from './fonts';
import opacity from './opacity';

export default {
  /* Breakpoints */
  ...breakpoints,

  /* Colors */
  ...colors,

  /* Fonts */
  ...fonts,

  /* opacity */
  ...opacity,

  deprecated,
};
