import React from 'react';
import PropTypes from 'prop-types';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { role as roleSelector } from 'Selectors';

export function checkPermissions(roles = []) {
  return WrappedComponent => {
    const RoleComponent = ({ dispatch, role, ...props }) =>
      roles.includes(role) && <WrappedComponent {...props} />;

    RoleComponent.displayName = `checkRole(${WrappedComponent.displayName ||
      WrappedComponent.name})`;

    RoleComponent.propTypes = {
      dispatch: PropTypes.func,
      role: PropTypes.string,
    };

    return hoistNonReactStatics(RoleComponent, WrappedComponent);
  };
}

export default function withPermissions(permissions) {
  return function hoc(WrappedComponent) {
    const ComposedComponent = compose(
      connect(state => ({ role: roleSelector(state) })),
      checkPermissions(permissions),
    )(WrappedComponent);

    ComposedComponent.displayName = `compose(${WrappedComponent.displayName ||
      WrappedComponent.name})`;

    function WithPermission(props) {
      return <ComposedComponent {...props} />;
    }
    WithPermission.displayName = `withPermisions(${ComposedComponent.displayName})`;
    return hoistNonReactStatics(WithPermission, ComposedComponent);
  };
}
