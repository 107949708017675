import { FormattedMessage } from '@react-intl';
import PropTypes from '@prop-types';
import React from 'react';
import styled from '@styled-components';

import Button from 'Components/Button';
import Dialog, { Actions, Wrapper } from 'Components/Dialog';

export const Confirmation = ({
  children,
  className,
  in: isIn,
  onAccept,
  title,
}) => (
  <Dialog className={className} in={isIn} onDismiss={onAccept} title={title}>
    {children}
    <Actions>
      <FormattedMessage
        capitalize
        primary
        component={Button}
        id="FINISH"
        onClick={onAccept}
      />
    </Actions>
  </Dialog>
);

Confirmation.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  in: PropTypes.bool,
  onAccept: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default styled(Confirmation)`
  ${Wrapper} {
    max-width: 40.8rem;
  }

  ${Actions} {
    margin-top: 4rem;

    ${Button} {
      margin: 0;
    }
  }
`;
