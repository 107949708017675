import React from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from '@prop-types';

import { Input } from 'Components/Form';
import FormSocialMedia from 'Containers/SignupForm/FormSocialMedia';

const Title = styled('h4')``;

const ThankYouConfig = ({ className, onChange }) => {
  const handleChange = value => onChange({ learnMoreTitle: value });

  return (
    <div className={className}>
      <Title capitalize translate id="LEARN_MORE_TITLE">
        LEARN_MORE_TITLE
      </Title>
      <Input
        capitalize
        label="TITLE"
        name="learnMoreTitle"
        onChange={handleChange}
      />
      <FormSocialMedia />
    </div>
  );
};

ThankYouConfig.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
};

export default styled(ThankYouConfig)`
  background: ${theme('--color-light')};
  border-radius: 0.6rem;
  margin-top: 1.6rem;
  padding: 1.6rem;

  ${Title} {
    ${theme('--font-medium')}
    ${theme('--font-weight-medium')}
    color: ${theme('--color-dark-night-80')};
  }
`;
