import styled, { css } from '@styled-components';

import Dropdown from 'Components/DropdownPanel';

const outMixin = ({ in: isIn }) =>
  !isIn &&
  css`
    opacity: 0;
    transform: translateY(-100%);
  `;

export default styled(Dropdown).attrs(({ in: isIn }) => ({
  in: isIn || false,
}))`
  ${outMixin}
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  white-space: nowrap;
`;
