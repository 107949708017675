import React from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';
import { useIntl } from 'react-intl';

const Name = styled('span')``;
const Value = styled('span')``;

const Template = ({ className, id, value }) => {
  const intl = useIntl();
  return (
    <div className={className}>
      <Name capitalize translate>
        {id}
      </Name>
      <Value>
        {intl.formatNumber(value, {
          maximumFractionDigits: 2,
          style: 'percent',
        })}
      </Value>
    </div>
  );
};

Template.propTypes = {
  className: PropTypes.string,
  id: PropTypes.number,
  value: PropTypes.number,
};

export default styled(Template)`
  display: flex;
  justify-content: space-between;
  padding: 2.4rem;
`;
