import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@styled-components';

import Card from 'Components/Card';

import CardNavigation from '../CardNavigation';
import Question from './Question';

const Shadow = styled('div')``;

const FreeResponses = ({ className, freeResponses = [] }) => {
  const [questionIndex, setQuestionIndex] = useState(0);

  return (
    <Card className={className}>
      <CardNavigation
        onTabSelect={setQuestionIndex}
        selectedTab={questionIndex}
        tabLabel={index => `FR${index + 1}`}
        tabsCount={freeResponses.length}
        titleId="FREE_RESPONSES"
      />
      <Question question={freeResponses[questionIndex]} />
      <Shadow />
    </Card>
  );
};

FreeResponses.propTypes = {
  className: PropTypes.string,
  freeResponses: PropTypes.array,
};

export default styled(FreeResponses)`
  height: 48rem;
  padding-left: 0px;
  padding-right: 0px;

  ${CardNavigation} {
    padding: 0 2.4rem;
  }

  ${Shadow} {
    background: linear-gradient(rgba(0, 0, 0, 0), #fff);
    bottom: 0;
    height: 14rem;
    left: 0;
    position: absolute;
    right: 0;
  }
`;
