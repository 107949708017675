import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from 'Components/Button';
import Icon from 'Components/Icon';

const Lock = ({ className, closed: isLocked = false, ...props }) => {
  const [isOver, setIsOver] = useState(false);

  const onEnter = () => {
    setIsOver(true);
  };

  const onLeave = () => {
    setIsOver(false);
  };

  let name = isLocked ? 'expand' : 'collapse';

  if (isOver) {
    name += '-fill';
  }

  return (
    <Button
      className={className}
      {...props}
      onBlur={onLeave}
      onFocus={onEnter}
      onMouseOut={onLeave}
      onMouseOver={onEnter}
    >
      <Icon name={name}>ACTIONS.PIN</Icon>
    </Button>
  );
};

Lock.propTypes = {
  className: PropTypes.string,
  closed: PropTypes.bool,
};

export default styled(Lock)`
  &:active,
  &:focus {
    box-shadow: none;
  }
`;
