import React, { Component } from 'react';
import PropTypes from '@prop-types';
import styled, { css } from '@styled-components';

const beforeMixin = ({ position }) =>
  position &&
  position.scrollLeft > 0 &&
  css`
    &:before {
      box-shadow: 0 0 1rem #4a4a4a;
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      width: 0.1rem;
    }
  `;

const afterMixin = ({ position }) =>
  position &&
  position.scrollLeft + position.offsetWidth < position.scrollWidth &&
  css`
    &:after {
      box-shadow: 0 0 1rem #4a4a4a;
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      width: 0.1rem;
    }
  `;

const Scroll = styled('section')`
  ${beforeMixin}
  ${afterMixin}
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0 2.4rem 1.6rem;
`;

export default class Scrollable extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
  };

  state = {};

  componentDidMount() {
    const { offsetWidth, scrollLeft, scrollWidth } = this.scrollable;
    this.setState({
      scrollabePosition: { offsetWidth, scrollLeft, scrollWidth },
    });
  }

  handleScroll = () => {
    const { offsetWidth, scrollLeft, scrollWidth } = this.scrollable;
    this.setState({
      scrollabePosition: { offsetWidth, scrollLeft, scrollWidth },
    });
  };

  setScrollable = scrollable => {
    this.scrollable = scrollable;
  };

  render() {
    const { children } = this.props;
    const { scrollabePosition } = this.state;

    return (
      <Scroll
        ref={this.setScrollable}
        onScroll={this.handleScroll}
        position={scrollabePosition}
      >
        {children}
      </Scroll>
    );
  }
}
