import React from 'react';

import { QueryClient, QueryClientProvider } from 'react-query';

import Dashboard from 'Views/Dashboard';
import Analytics from 'Views/Analytics';
import Campaigns from 'Views/Campaigns';
import Customers from 'Views/Customers';
import Integrations from 'Views/Integrations';
import Products from 'Views/Products';
import Settings from 'Views/Settings';
import Builder from 'Views/Builder';
import Conversation from 'Views/Conversation';
import CustomError from 'Views/Error';
import Login from 'Views/Login';
import Logout from 'Views/Logout';
import Order from 'Views/Order';
import RecoverPassword from 'Views/RecoverPassword';
import ResetPassword from 'Views/ResetPassword';
import Signup from 'Views/Signup';
import Surveys from 'Views/Surveys';

import I18n from 'Components/I18n';
import L10n from 'Containers/L10n';
import Logger from 'Containers/Logger';
import Main from 'Containers/Main';
import MultiDataProvider from 'Containers/MultiDataProvider';
import MultiModal from 'Containers/Multimodal';

import Layout from './Layout';
import Router, { Route, Redirect } from './Router';
import Session from './Session';
import Store from './Store';
import Theme, { Styles } from './Theme';

const queryClient = new QueryClient();

const App = () => (
  <QueryClientProvider client={queryClient}>
    <Store>
      <I18n>
        <L10n>
          <Router>
            <Theme>
              <MultiDataProvider>
                {/* Styles by theme */}
                <Styles />
                {/* Snackbar */}
                <Logger />
                <MultiModal />

                <Layout>
                  {/* Before login */}
                  <Session>
                    <Route component={Login} path="/login" />
                    <Route
                      component={RecoverPassword}
                      path="/recover-password"
                    />
                    <Route
                      component={ResetPassword}
                      path="/reset-password(/:token)?"
                    />
                    <Route component={Signup} path="/signup" />
                  </Session>

                  {/* Error route */}
                  <Route exact component={CustomError} path="/oops" />

                  {/* After login */}
                  <Main>
                    <Route component={Logout} path="/logout" />
                    <Route component={Analytics} path="/analytics" />
                    <Route component={Campaigns} path="/campaigns" />
                    <Route component={Surveys} path="/surveys" />
                    <Route component={Customers} path="/customers" />
                    <Route component={Products} path="/products" />
                    <Route component={Integrations} path="/integrations" />
                    <Route component={Settings} path="/settings" />
                    <Route component={Builder} path="/builder" />
                    <Route component={Dashboard} path="/dashboard" />
                    <Route component={Order} path="/order" />
                    <Route component={Conversation} path="/conversation" />
                    <Route
                      exact
                      path="/"
                      render={() => <Redirect to="/dashboard" />}
                    />
                    <Route render={() => <Redirect to="/oops" />} />
                  </Main>
                </Layout>
              </MultiDataProvider>
            </Theme>
          </Router>
        </L10n>
      </I18n>
    </Store>
  </QueryClientProvider>
);

export default App;
