import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import DataProvider from 'Containers/DataProvider';

import service from 'Services/knowledge-base';

import { currentAccount, role as getRole } from 'Selectors';

import Template from './KnowledgeBase';

const mapStateToProps = state => ({
  account: currentAccount(state),
  role: getRole(state),
});

const KnowledgeBase = ({ account, role }) => (
  <DataProvider
    params={{ account }}
    query={service.locales}
    template={({ data: locales = [], loading }) => {
      if (loading || !locales.length) return null;
      return <Template account={account} locales={locales} role={role} />;
    }}
  />
);

KnowledgeBase.propTypes = {
  account: PropTypes.number,
  role: PropTypes.string,
};

export default compose(connect(mapStateToProps))(KnowledgeBase);
