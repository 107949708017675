import React from 'react';
import styled from '@styled-components';
import PropTypes from '@prop-types';
import { Row, SortableColumn, Column } from 'Components/ResponsiveTable';

const HeaderTemplate = ({ className, ...common }) => (
  <Row className={className}>
    <SortableColumn field="name" id="$VARIANT" {...common} />
    <Column field="name" id="VARIANT_ID" />
    <SortableColumn field="price" id="PRICE" {...common} />
    <Column field="name" id="MAX_OCCUPANCY" />
  </Row>
);

HeaderTemplate.propTypes = {
  className: PropTypes.string,
};

export default styled(HeaderTemplate)`
  grid-template-columns: 1fr 7rem 7rem 7rem;
  padding: 1.2rem 2.4rem 0.8rem 2.4rem;
`;
