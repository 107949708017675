import React from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@react-intl';

import Confirm from 'Components/Confirm';

const Paragraph = styled('p')``;

const DeleteCampaign = ({
  acceptTitle,
  className,
  campaign: { name } = {},
  confirmationQuestion,
  in: isIn,
  onAccept,
  onCancel,
  title,
}) => (
  <Confirm
    acceptId={acceptTitle}
    className={className}
    in={isIn}
    onAccept={onAccept}
    onCancel={onCancel}
    title={title}
  >
    <FormattedMessage
      capitalize
      component={Paragraph}
      id={confirmationQuestion}
      values={{ campaign: name }}
    />
  </Confirm>
);

DeleteCampaign.propTypes = {
  acceptTitle: PropTypes.string,
  campaign: PropTypes.shape({
    name: PropTypes.string,
  }),
  className: PropTypes.string,
  confirmationQuestion: PropTypes.string,
  in: PropTypes.bool,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string,
};

export default styled(DeleteCampaign)`
  ${Paragraph} {
    ${theme('--font-medium')}
    ${theme('--font-weight-book')}
    color: ${theme('--color-dark-night-60')};
  }
`;
