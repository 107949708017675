import React from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';

import { Row, Column } from 'Components/ResponsiveTable';

const HeaderTemplate = ({ className, ...common }) => (
  <Row className={className}>
    <Column id="QUESTION" {...common} />
  </Row>
);

HeaderTemplate.propTypes = {
  className: PropTypes.string,
  options: PropTypes.shape({
    role: PropTypes.string,
  }),
};

export default styled(HeaderTemplate)`
  padding: 1.2rem 2.4rem 0.8rem 2.4rem;
`;
