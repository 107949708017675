import React from 'react';
import PropTypes from '@prop-types';
import styled, { theme } from '@styled-components';

import { FormattedMessage } from '@react-intl';
import Avatar from 'Components/Avatar';

export const Title = styled('h4')``;
export const Heading = styled('p')``;
export const Wrapper = styled('div')``;

const ViewHeadline = ({
  avatar,
  image,
  className,
  title,
  heading,
  values,
  customHeading: Content,
}) => (
  <header className={className}>
    {avatar && <Avatar image={image} />}
    <Wrapper>
      <FormattedMessage capitalize component={Title} id={title} values={values}>
        {title}
      </FormattedMessage>
      {Content ? (
        <Heading>
          <Content />
        </Heading>
      ) : (
        <FormattedMessage
          capitalize
          component={Heading}
          id={heading}
          values={values}
        >
          {heading}
        </FormattedMessage>
      )}
    </Wrapper>
  </header>
);

ViewHeadline.propTypes = {
  avatar: PropTypes.bool,
  className: PropTypes.string,
  customHeading: PropTypes.component,
  heading: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  values: PropTypes.object,
};

export default styled(ViewHeadline)`
  align-items: center;
  display: flex;

  ${Avatar} {
    margin-right: 1.6rem;
    width: 6.4rem;
  }

  ${Wrapper} {
    ${Title} {
      ${theme('--font-large')}
      ${theme('--font-weight-book')}
      padding-bottom: 0.8rem;
    }

    ${Heading} {
      ${theme('--font-medium')}
      ${theme('--font-weight-book')}
      ${theme('--font-opacity-40')}
    }
  }
`;
