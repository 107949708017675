import React from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';

import DataProvider from 'Containers/DataProvider';

import services from 'Services/accounts';

import View from './CompanyCard';

const CompanyCard = ({ className, account, link, logo }) => {
  return (
    <DataProvider
      className={className}
      link={link}
      logo={logo}
      params={{ account }}
      query={services.getOfaSettings}
      template={View}
    />
  );
};

CompanyCard.propTypes = {
  account: PropTypes.string,
  className: PropTypes.string,
  link: PropTypes.string,
  logo: PropTypes.string,
};

export default styled(CompanyCard)``;
