import React from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';
import { FormattedMessage } from '@react-intl';

import Icon from 'Components/Icon';

const Title = styled('h4')``;
const Instructions = styled('p')``;

const Empty = ({ className }) => (
  <section className={className}>
    <Icon name="notifications-empty">EMPTY_NOTIFICATIONS</Icon>
    <FormattedMessage capitalize component={Title} id="NO_NOTIFICATIONS">
      You don&apos;t have any notifications
    </FormattedMessage>
    <FormattedMessage
      capitalize
      component={Instructions}
      id="INSTRUCTIONS.NOTIFICATIONS"
    >
      It seems like you don&apos;t have notifications yet. Here will be recorded
      the actions made on the project.
    </FormattedMessage>
  </section>
);

Empty.propTypes = {
  className: PropTypes.string,
};

export default styled(Empty)`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - (3.4rem + 2 * 3.2rem));
  justify-content: center;
  text-align: center;

  ${Title} {
    ${theme('--font-xlarge')}
    ${theme('--font-weight-demi')}
    margin-bottom: 1.6rem;
  }

  ${Instructions} {
    ${theme('--font-large')}
    ${theme('--font-opacity-40')}
    ${theme('--font-weight-book')}
    width: 38rem;
  }

  ${Icon} {
    height: 10.4rem;
    margin-bottom: 2rem;
    width: 10.4rem;
  }

  @media (${theme('--screen-small')}) {
    height: calc(100vh - (11.4rem + 2 * 3.2rem));
  }

  @media (${theme('--screen-medium')}) {
    height: calc(100vh - (14.4rem + 2 * 3.2rem));
  }
`;
