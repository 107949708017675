import React from 'react';
import { rgba } from 'polished';
import PropTypes from '@prop-types';
import styled, { withStyles as css } from '@styled-components';
import FocusTrap from 'focus-trap-react';
import { compose } from 'underscore';
import withTransition from 'Components/with/transition';

export const Background = styled('button')``;

const Wrapper = styled('div')``;

const Overlay = ({ className, children, onDismiss }) => (
  <FocusTrap focusTrapOptions={{ initialFocus: '#overlay' }}>
    <section className={className} data-testid="overlay">
      <Background data-testid="overlay-background" onClick={onDismiss} />
      <Wrapper>{children}</Wrapper>
    </section>
  </FocusTrap>
);

Overlay.defaultProps = {
  onDismiss() {},
};

Overlay.propTypes = {
  children: PropTypes.children,
  className: PropTypes.string,
  onDismiss: PropTypes.func,
};

export default compose(
  css`
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: opacity 350ms ease-out;
    width: 100vw;
    z-index: 1000;

    &.entered {
      opacity: 1;
    }

    ${Background} {
      background-color: ${props =>
        rgba(props.theme['--color-dark'] || '#222222', 0.6)};
      border: none;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    ${Wrapper} {
      z-index: 10;
    }
  `,
  withTransition(350),
)(Overlay);
