import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withStyles as css } from '@styled-components';

import * as logger from 'Actions/logger';

import Loader from 'Components/Loader';

import Fetcher from 'Containers/Fetcher';

import { currentAccount } from 'Selectors';

import service from 'Services/customers';

import Form from './Form';

const mapStateToProps = state => ({
  account: currentAccount(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ success: logger.success }, dispatch);

const CustomerForm = ({ account, className, onSubmit, ...props }) => (
  <Fetcher params={{ account }} query={service.groups}>
    {({ data: { ofaGroups: groups } = {}, loading }) =>
      loading ? (
        <Loader />
      ) : (
        <Form {...props} groupOptions={groups} onSubmit={onSubmit} />
      )
    }
  </Fetcher>
);

CustomerForm.defaultProps = {
  onSubmit() {},
};

CustomerForm.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default compose(
  css``,
  connect(mapStateToProps, mapDispatchToProps),
)(CustomerForm);
