import React, { useCallback } from 'react';
import PropTypes from '@prop-types';
import styled, { theme } from '@styled-components';

import Button from 'Components/Button';
import Chip from 'Components/Chip';
import Icon from 'Components/Icon';

const Tabs = ({
  className,
  currentTab,
  onAdd: handleAdd = () => {},
  onChange: handleChange = () => {},
  onRemove: handleRemove = () => {},
  tabs = [],
}) => {
  const handleRemoveTab = useCallback(
    index => e => {
      e.stopPropagation();
      handleRemove(index);
    },
    [handleRemove],
  );

  const handleTab = useCallback(
    index => () => {
      handleChange(index);
    },
    [handleChange],
  );

  return (
    <ul className={className}>
      {tabs.map((tab, index) => (
        <Chip
          key={tab}
          data-active={index === currentTab}
          data-removable={!!index}
          onClick={handleTab(index)}
        >
          {tabs.length > 1 && index === currentTab && (
            <Button onClick={handleRemoveTab(index)}>
              <Icon name="close" />
            </Button>
          )}
          {tab}
        </Chip>
      ))}
      <Button onClick={handleAdd}>
        <Icon outline name="plus" />
      </Button>
    </ul>
  );
};

Tabs.propTypes = {
  className: PropTypes.string,
  currentTab: PropTypes.string,
  onAdd: PropTypes.func,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  tabs: PropTypes.arrayOf(PropTypes.string),
};

export default styled(Tabs)`
  align-items: center;
  display: flex;

  ${Chip} {
    cursor: pointer;
    margin-right: 0.8rem;
    text-transform: capitalize;

    &[data-active='true'] {
      background: ${theme('--color-primary-20')};
      border: none;
      color: ${theme('--color-primary')};
      justify-content: space-between;
      padding: 0 0.8rem 0 0;

      ${Icon} {
        height: 2.4rem;
        width: 2.4rem;

        > * {
          stroke: ${theme('--color-primary')};
        }
      }
    }
    &[data-removable='false'] {
      justify-content: center;
      padding: 0 0.8rem;
    }
  }

  ${Icon} {
    > * {
      stroke: ${theme('--color-dark-night-60')};
    }
  }

  ${Button} {
    display: flex;
    width: 24px;
  }
`;
