import React from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';
import { FormattedMessage } from '@react-intl';

import Placeholder, { withPlaceholder } from 'Containers/Placeholder';

import { Row } from 'Components/ResponsiveTable';
import RawAvatar from 'Components/Avatar';

const Avatar = styled(withPlaceholder(RawAvatar))``;

const Views = styled(Placeholder).attrs({ placeholder: 'SOTO Tank' })``;
const Name = styled(Placeholder).attrs({ placeholder: '120 views' })``;

const Wrapper = styled('div')``;

const Product = ({
  className,
  description,
  empty,
  id,
  image,
  name,
  onClick = () => null,
  view,
}) => (
  <Row className={className} empty={empty} onClick={onClick(id)}>
    <Wrapper>
      <Avatar fill image={image} name={description} />
      <Name>{name}</Name>
      <Views>
        {view && (
          <>
            {` ${view} `}
            <FormattedMessage id="VIEWS" />
          </>
        )}
      </Views>
    </Wrapper>
  </Row>
);

Product.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  empty: PropTypes.bool,
  id: PropTypes.string,
  image: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  view: PropTypes.number,
};

export default styled(Product)`
  align-items: center;
  display: flex;
  height: 8rem;

  ${Wrapper} {
    align-items: flex-start;
    display: grid;
    grid-gap: 0.8rem;
    grid-template-columns: auto 1fr;
    width: 100%;
  }
  ${Avatar} {
    border-radius: 0.6rem;
    grid-column: 1;
    grid-row: 1 / span 2;
    height: 4rem;
    max-height: 4rem;
    max-width: 4rem;
    min-height: 4rem;
    min-width: 4rem;
  }

  ${Name} {
    ${theme('--font-medium')}
    ${theme('--font-weight-book')}
    color: ${theme('--color-primary')};
    grid-column: 2;
    grid-row: 1;
    max-width: 16rem;
    text-align: left;
  }

  ${Views} {
    ${theme('--font-opacity-40')}
    ${theme('--font-small')}
    ${theme('--font-weight-book')}
    grid-column: 2;
    grid-row: 2;
    text-align: left;
  }
`;
