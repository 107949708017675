import { createReducer } from '@redux-act';
import { v4 as uuid } from 'uuid';

import { clear, error, info, remove, success } from 'Actions/logger';

export default createReducer(
  {
    [clear]: () => [],
    [error]: (state, { message }) => [
      ...state,
      { message, type: 'error', uid: uuid() },
    ],
    [info]: (state, { message }) => [
      ...state,
      { message, type: 'info', uid: uuid() },
    ],
    [remove]: (state, { uid }) => [
      ...state.filter(({ uid: id }) => uid !== id),
    ],
    [success]: (state, { message }) => [
      ...state,
      { message, type: 'success', uid: uuid() },
    ],
  },
  [],
);
