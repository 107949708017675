import React from 'react';
import styled, { prop, theme } from '@styled-components';
import { FormattedMessage } from '@react-intl';
import PropTypes from '@prop-types';

const Bullet = styled('span')`
  background-color: ${prop('color')};
`;
const Title = styled('span')``;

const Color = ({ className, color, id }) => {
  return (
    <div className={className}>
      <Bullet color={color} />
      <FormattedMessage capitalize component={Title} id={id} />
    </div>
  );
};

Color.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  id: PropTypes.string,
};

export default styled(Color)`
  align-items: center;
  display: flex;

  ${Bullet} {
    border-radius: 50%;
    box-shadow: 0 0 0.4rem 0 ${theme('--color-dark-night-20')};
    height: 0.8rem;
    margin: 0.2rem;
    width: 0.8rem;
  }

  ${Title} {
    ${theme('--font-small')}
    ${theme('--font-weight-book')}
    ${theme('--font-opacity-60')}
    margin-left: 0.8rem;
    margin-top: 0.3rem;
  }
`;
