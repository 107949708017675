import React from 'react';
import styled from '@styled-components';
import PropTypes from '@prop-types';

const ModalLoading = ({ className }) => {
  return <div className={className} data-testid="loading-modal" />;
};

ModalLoading.propTypes = {
  className: PropTypes.string,
};

export default styled(ModalLoading)`
  display: flex;
  font-size: 3rem;
  justify-content: center;
  width: 100rem;
`;
