import React, { useCallback } from 'react';
import PropTypes from '@prop-types';
import { Redirect, useHistory } from 'react-router-dom';

import FaqForm from 'Containers/FaqForm';
import { removeDuplicatedQuestions } from 'Containers/FaqForm/utils';
import { useMultiDataProviderContext } from 'Containers/MultiDataProvider';

import service from 'Services/faqs';

const JSON_ERROR = 'Unexpected end of JSON input';

const AddFaq = ({
  account,
  data: locales = [],
  success,
  handleError,
  onClose,
  role,
}) => {
  const { location: { state: { locale = 'en_US' } = {} } = {} } = useHistory();
  const { refetch } = useMultiDataProviderContext('faqs');

  const onSubmit = useCallback(
    async ({
      answer: { type: aType, content, description },
      questions,
      counterQuestion,
      score,
      tags,
    }) => {
      try {
        const answer =
          aType === 'CMD'
            ? { cmds: JSON.parse(content), counterQuestion, description }
            : { counterQuestion, text: content };

        await service.post({
          account,
          faq: {
            answer,
            questions: removeDuplicatedQuestions(questions),
            score,
            tags,
          },
          locale,
          type: 'ANSWERED',
        });
        onClose();
        success('FAQ_CREATED');
        refetch();
      } catch ({ message }) {
        if (message === JSON_ERROR) {
          handleError('JSON_MALFORMED');
          return;
        }
        handleError('FAQ_CREATED');
      }
    },
    [account, handleError, locale, onClose, refetch, success],
  );

  if (role === 'READER') return <Redirect to="/faqstudio" />;
  if (!locales) return null;

  return (
    <FaqForm
      account={account}
      defaultValue={{
        answer: { content: '', type: 'TEXT' },
        questions: [{ text: '' }],
        tags: [],
      }}
      locale={locale}
      onClose={onClose}
      onSubmit={onSubmit}
      role={role}
    />
  );
};

AddFaq.propTypes = {
  account: PropTypes.number,
  data: PropTypes.array,
  handleError: PropTypes.func,
  onClose: PropTypes.func,
  role: PropTypes.string,
  success: PropTypes.func,
};

export default AddFaq;
