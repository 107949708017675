import React from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';

const PieChartLoader = ({ className }) => {
  return <div className={className} />;
};

PieChartLoader.propTypes = {
  className: PropTypes.string,
};

export default styled(PieChartLoader)`
  background: white url('/images/pieChartLoader.svg') no-repeat;
  background-position: center;
  background-size: contain;
  margin: auto;
  margin-top: 2.4rem;
  width: 100%;
`;
