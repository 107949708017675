import React from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from '@prop-types';

import { FormattedMessage } from '@react-intl';

const Title = styled('h4')``;
const Heading = styled('p')``;

const SectionHeadline = ({ className, title, values, heading }) => {
  return (
    <div className={className}>
      <FormattedMessage capitalize component={Title} id={title} values={values}>
        {title}
      </FormattedMessage>
      {heading ? (
        <FormattedMessage
          capitalize
          component={Heading}
          id={heading}
          values={values}
        >
          {heading}
        </FormattedMessage>
      ) : null}
    </div>
  );
};

SectionHeadline.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string,
  title: PropTypes.string,
  values: PropTypes.object,
};

export default styled(SectionHeadline)`
  ${Title} {
    ${theme('--font-medium')}
    ${theme('--font-weight-demi')}
    ${theme('--font-opacity-80')}
    padding-bottom: 0.8rem;
  }

  ${Heading} {
    ${theme('--font-medium')}
    ${theme('--font-weight-book')}
    ${theme('--font-opacity-60')}
  }
`;
