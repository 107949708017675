import { createReducer } from '@redux-act';

import { login, logout } from 'Actions/session';

export default createReducer(
  {
    [login.success]: (ignore, { email, id, token }) => ({ email, id, token }),
    [logout.success]: ({ id, token, ...state }) => ({ ...state }),
  },
  {},
);
