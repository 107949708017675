import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import moment from 'moment';
import styled, { withStyles as css, theme } from '@styled-components';
import { useHistory } from 'react-router-dom';

import { currentAccount } from 'Selectors/index';

import service from 'Services/user-generated-content';

import { useMultiDataProviderContext } from 'Containers/MultiDataProvider';
import TableDataProvider from 'Containers/TableDataProvider';

import Button from 'Components/Button';
// import { Input } from 'Components/Form';

import Template from './UserGeneratedContent';
import FiltersType from './components/FilterType';
import CustomRangePicker from 'Components/CustomRangePicker';
import { memoize } from 'underscore';
import { change } from 'Actions/ranges';
import Constants from 'Constants';
import {
  getDatesTransformedByUnit,
  getUnit as getUnitRaw,
} from 'Modules/dates';

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onRangeChange: (filter, from, to) =>
        change('UserGeneratedContent', { filter, from, to }),
    },
    dispatch,
  );

const mapStateToProps = state => ({
  account: currentAccount(state),
});

const Datepicker = styled('div')``;
const Header = styled('div')``;

const {
  FILTERS: { LAST_YEAR },
} = Constants;

const getUnit = memoize(getUnitRaw, (...args) => JSON.stringify(args));
const getDates = memoize(getDatesTransformedByUnit, (...args) =>
  JSON.stringify(args),
);

const UserGeneratedContent = ({
  className,
  account,
  filter = LAST_YEAR,
  from: fromInitial = moment()
    .subtract(1, 'year')
    .format(),
  to: toInitial = moment().format(),
}) => {
  const unit = getUnit(fromInitial, toInitial);
  const { from, to } = getDates(unit, fromInitial, toInitial);
  const [type, setType] = useState('ALL');
  const [startDate, setStartDate] = useState(from);
  const [endDate, setEndDate] = useState(to);
  const { push } = useHistory();
  const { refetch } = useMultiDataProviderContext('userGeneratedContent');
  const handleDelete = useCallback(
    async (id, mediaUrl) => {
      await service.remove({ account, id, mediaUrl });
      refetch();
    },
    [account, refetch],
  );

  const onRangeChange = (filters, fromParam, toParam) => {
    setStartDate(fromParam);
    setEndDate(toParam);
  };
  const handleTypeChange = filters => {
    setType(filters);
  };

  const handleRemove = useCallback(
    async id => {
      await service.remove({ account, id });
      refetch();
    },
    [account, refetch],
  );

  const handleOpenUploader = useCallback(
    () => push('/builder/usergeneratedcontent/add'),
    [push],
  );

  return (
    <div className={className}>
      <Header>
        <Datepicker>
          <CustomRangePicker
            filter={filter}
            from={startDate}
            onRangeChange={onRangeChange}
            to={endDate}
          />
        </Datepicker>
        <FiltersType onChange={handleTypeChange} value={type} />
        <Button capitalize primary translate onClick={handleOpenUploader}>
          ACTIONS.UPLOAD
        </Button>
      </Header>
      <TableDataProvider
        name="userGeneratedContent"
        onDelete={handleDelete}
        onRemove={handleRemove}
        params={{ account, from: startDate, to: endDate, type }}
        query={service.get}
        template={Template}
      />
    </div>
  );
};

UserGeneratedContent.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
  filter: PropTypes.string,
  from: PropTypes.string,
  to: PropTypes.string,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  css`
    ${Header} {
      display: flex;
      flex-direction: column-reverse;
      margin-bottom: 1.6rem;
      ${Datepicker}, ${FiltersType} {
        margin-right: 1rem;
        order: 1;
        padding-bottom: 0.8rem;
      }
      ${Button} {
        align-items: right;
        height: 4rem;
        margin-bottom: 1.6rem;
        margin-left: auto;
        min-width: 100%;
        order: 2;
      }
    }

    @media (${theme('--column-12')}) {
      ${Header} {
        flex-direction: row;
        margin-bottom: 4rem;

        ${Datepicker} {
          margin-right: 1.2rem;
        }

        ${Button} {
          height: 4.8rem;
          margin-bottom: 0;
          min-width: 18.8rem;
        }
      }
    }
  `,
)(UserGeneratedContent);
