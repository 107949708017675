import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from '@react-intl';
import styled, { theme } from '@styled-components';

import Button from 'Components/Button';
import Form, { Checkbox, Input } from 'Components/Form';
import Link from 'Components/Link';
import { Redirect } from 'Components/Router';

import { login as loginRules } from 'Rules';

const H2 = styled('h2')``;
const Links = styled('div')``;
const Paragraph = styled('p')``;
const Row = styled('div')``;

class Login extends Component {
  static propTypes = {
    className: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    location: PropTypes.object,
    login: PropTypes.func,
  };

  handleSubmit = ({ emailAddress, password, remember }) => {
    const { login } = this.props;
    login(emailAddress, password, remember);
  };

  render() {
    const { className, isLoggedIn, location } = this.props;

    const email =
      location.search &&
      location.search.split('=')[0] === '?email' &&
      location.search.split('=')[1];

    if (isLoggedIn) {
      return <Redirect push to="/" />;
    }

    return (
      <Form
        className={className}
        constraint={loginRules}
        defaultValue={{ emailAddress: email, remember: true }}
        onSubmit={this.handleSubmit}
      >
        <FormattedMessage capitalize component={H2} id="SIGN_IN">
          sign in
        </FormattedMessage>
        <FormattedMessage
          capitalize
          component={Paragraph}
          id="INSTRUCTIONS.SIGN_IN"
        >
          access into your account
        </FormattedMessage>
        <Input label="EMAIL" name="emailAddress" type="email" />
        <Input label="PASSWORD" name="password" type="password" />
        <Row>
          <Checkbox label="QUESTIONS.REMEMBER_ME" name="remember" />
          <FormattedMessage
            capitalize
            component={Link}
            id="QUESTIONS.FORGOT_PASSWORD"
            to="/recover-password"
          >
            forgot password?
          </FormattedMessage>
        </Row>
        <FormattedMessage
          capitalize
          primary
          component={Button}
          id="SIGN_IN"
          type="submit"
        >
          sign in
        </FormattedMessage>

        <Links>
          <FormattedMessage capitalize id="QUESTIONS.DONT_HAVE_ACCOUNT">
            don&apos;t have an account?
          </FormattedMessage>
          <FormattedMessage
            capitalize
            component={Link}
            id="SIGNUP"
            to="/signup"
          >
            signup
          </FormattedMessage>
        </Links>
      </Form>
    );
  }
}

export default styled(Login)`
  ${H2} {
    ${theme('--font-weight-book')}
    ${theme('--font-xlarge')}
  }
  ${Paragraph} {
    ${theme('--font-medium')}
    ${theme('--font-opacity-40')}
    margin: 0.8rem 0 3.2rem;
  }

  ${Input} {
    margin-bottom: 1.6rem;

    &:nth-of-type(2) {
      margin-bottom: 0.8rem;
    }
  }

  ${Checkbox} {
    margin-bottom: 0;
  }

  ${Link} {
    ${theme('--font-small')}
    ${theme('--font-weight-demi')}
    color: ${theme('--color-primary')};
  }

  ${Row} {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
  }

  ${Button} {
    margin-top: 3.2rem;
    width: 100%;
  }

  ${Links} {
    ${theme('--font-opacity-60')}
    ${theme('--font-small')}
    margin-top: 1.6rem;
    text-align: center;
  }
`;
