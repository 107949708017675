import React from 'react';
import PropTypes from 'prop-types';
import styled from '@styled-components';

import Table, { Body, Foot } from 'Components/ResponsiveTable';

import Template from './Template';
import DesktopTemplate from './DesktopTemplate';
import Header from './Header';

const AnswersTable = ({ answers, className }) => {
  return (
    <Table
      className={className}
      desktopTemplate={DesktopTemplate}
      header={Header}
      items={answers}
      rows={answers.length}
      template={Template}
    />
  );
};

AnswersTable.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
};

export default styled(AnswersTable)`
  ${Body} {
    height: 33rem;
    overflow: scroll;
    padding-bottom: 5rem;
  }

  ${Foot} {
    display: none;
  }
`;
