import React from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';

const Img = styled('img')``;

export const Logotype = ({ className, inverse: isInverse, withText }) => {
  const inverse = isInverse ? '-inverse' : '';
  const text = withText ? '-text' : '';
  const name = `logotype${text}${inverse}`;

  return (
    <picture className={className}>
      <Img src={`/images/${name}.svg`} />
    </picture>
  );
};

Logotype.propTypes = {
  className: PropTypes.string,
  inverse: PropTypes.bool,
  withText: PropTypes.bool,
};

export default styled(Logotype)`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;

  & ${Img} {
    fill: ${theme('--color-light')};
    max-height: 100%;
    max-width: 100%;
  }
`;
