import React from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';

import Breadcrumb from 'Components/Breadcrumb';
import { Item, Toolbar } from 'Components/Toolbar';

const Header = ({ className }) => (
  <section className={className}>
    <Breadcrumb />
  </section>
);

Header.propTypes = {
  className: PropTypes.string,
};

export default styled(Header)`
  align-items: center;
  background-color: ${theme('--color-light')};
  border-bottom: 0.1rem solid ${theme('--color-dark-night-05')};
  display: grid;
  grid-template-columns: 1fr auto;
  height: 8rem;
  width: 100%;

  ${Breadcrumb} {
    ${theme('--font-large')}
    margin-left: 6rem;
    text-transform: capitalize;

    @media (${theme('--screen-small')}) {
      ${theme('--font-xlarge')}
      margin-left: 2rem;
      margin-top: 2.9rem;
    }
  }

  ${Toolbar} {
    align-items: center;
    display: flex;
    text-align: right;

    ${Item} {
      margin-right: 1.1rem;

      @media (${theme('--screen-small')}) {
        margin: 0 0.8rem;
      }
    }
  }
`;
