import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@react-intl';
import styled, { theme, prop } from '@styled-components';
import Link from 'Components/Link';
import {
  withPlaceholderNoData as withPlaceholder,
  PlaceholderNoData as Placeholder,
} from 'Containers/Placeholder';

import { Row } from 'Components/ResponsiveTable';

import RawCategoriesList from './Categories';

const Label = styled(Placeholder)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const NameLabel = styled(Label)``;
const Merchant = styled(Label)``;
const Price = styled(Label)``;
const Sales = styled(Label)``;
const Visits = styled(Label)``;
const CategoriesList = styled(withPlaceholder(RawCategoriesList))``;

export const Thumbnail = styled(Placeholder.img).attrs({
  forwardProps: ['src'],
})`
  background-color: ${theme('--color-dark-night-10')};
  background-image: url(${prop('image')});
  background-position: center;
  background-size: cover;
  border-radius: 0.6rem;
  height: 4rem;
  min-width: 4rem;
`;

const MobileTemplate = ({
  categories = [],
  className,
  empty,
  id,
  image,
  merchantId,
  name,
  price,
  sales,
  visits,
}) => {
  return (
    <Link as={Row} className={className} empty={empty} to={`/products/${id}`}>
      <Thumbnail fill src={image} />
      <NameLabel placeholder="product name">{name}</NameLabel>
      <Merchant placeholder="merchant id">{merchantId}</Merchant>
      <Sales placeholder="sales">
        {sales && <FormattedMessage id="%SALES%_SALES" values={{ sales }} />}
      </Sales>
      <Visits placeholder="visits">
        {visits && (
          <FormattedMessage id="%VISITS%_VISITS" values={{ visits }} />
        )}
      </Visits>
      <Price placeholder="product price">{price}</Price>
      <CategoriesList
        fill
        categories={categories}
        placeholder="product categories"
      />
    </Link>
  );
};

MobileTemplate.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  empty: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  image: PropTypes.string,
  merchantId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  price: PropTypes.string,
  sales: PropTypes.number,
  visits: PropTypes.number,
};

export default styled(MobileTemplate)`
  align-items: flex-start;
  cursor: pointer;
  display: grid;
  gap: 0.4rem 0.8rem;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: repeat(3, auto);
  height: 10.4rem;
  padding: 1.6rem 2.4rem;

  ${Label} {
    ${theme('--font-small')}
    ${theme('--font-weight-medium')}
    ${theme('--font-opacity-40')}
  }

  ${NameLabel} {
    ${theme('--font-medium')}
    ${theme('--font-opacity-100')}
    grid-column: 2 / span 2;
    grid-row: 1;
  }

  ${Thumbnail} {
    grid-column: 1;
    grid-row: 1 / span 3;
    width: 4rem;
  }

  ${Merchant} {
    grid-column: 2;
    grid-row: 2;
  }

  ${Price} {
    grid-column: 2;
    grid-row: 3;
  }

  ${Sales} {
    grid-column: 3;
    grid-row: 2;
  }

  ${Visits} {
    grid-column: 3;
    grid-row: 3;
  }

  ${CategoriesList} {
    grid-column: 2 / span 2;
    grid-row: 4;
    margin-top: 0.8rem;
  }
`;
