import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles as css, theme } from '@styled-components';
import { compose, debounce } from 'underscore';

import { currentAccount } from 'Selectors';

import Card from 'Components/Card';
import { Input } from 'Components/Form';

import TableDataProvider from 'Containers/TableDataProvider';
import Toolbar from 'Containers/CardToolbar';

import service from 'Services/surveys';

import View from './Table';

const mapStateToProps = state => ({ account: currentAccount(state) });

const Customers = ({ account, className, from, surveyId, to }) => {
  const [search, setSearch] = useState();

  const handleSearch = useCallback(
    debounce(query => {
      setSearch(query);
    }, 255),
    [],
  );

  let filterAmmount = 0;
  if (search) filterAmmount += 1;

  return (
    <Card className={className}>
      <Toolbar filterAmmount={filterAmmount} title="LIST_OF_CUSTOMERS">
        <Input capitalize label="SEARCH" onChange={handleSearch} />
      </Toolbar>
      <TableDataProvider
        limit={5}
        name="surveyCustomers"
        offset={0}
        params={{ account, from, search, surveyId, to }}
        query={service.customers}
        surveyId={surveyId}
        template={View}
      />
    </Card>
  );
};

Customers.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
  from: PropTypes.object,
  surveyId: PropTypes.number,
  to: PropTypes.object,
};

export default compose(
  css`
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
    padding: 0;

    @media (${theme('--screen-small')}) {
      ${Input} {
        width: 24rem;
      }
    }
  `,
  connect(mapStateToProps),
)(Customers);
