import React, { useCallback, useState } from 'react';
import PropTypes from '@prop-types';

import Modal from 'Components/Modal';
import DataProvider from 'Containers/DataProvider/index';

import { useMultiDataProviderContext } from 'Containers/MultiDataProvider';

import Services from 'Services/members';

import * as logger from 'Actions/logger';

import View from './EditUser';

import C from 'Constants';

import { currentAccount } from 'Selectors';

import { connect } from 'react-redux';

const {
  STATUS: { ACTIVE },
} = C;

const EditUser = ({
  account,
  depth,
  in: modalIn,
  onBack,
  onClose,
  order,
  id,
  error,
  success,
}) => {
  const [status, setStatus] = useState(ACTIVE);
  const { refetch } = useMultiDataProviderContext('settings');

  const handleSubmit = useCallback(
    async member => {
      try {
        if (status === ACTIVE) {
          await Services.updateProfile({
            account,
            member: { id, role: member.role },
          });
          refetch();
          success('MEMBER_MODIFIED');
          onClose();
        } else {
          await Services.updateInvite({
            account,
            member: { ...member, id },
          });
          refetch();
          success('INVITE_MODIFIED');
          onClose();
        }
      } catch (e) {
        error('INVITE_NOT_MODIFIED');
        onClose();
      }
    },
    [account, error, id, onClose, refetch, status, success],
  );

  return (
    <Modal
      depth={depth}
      in={modalIn}
      onBack={onBack}
      onClose={onClose}
      order={order}
      title={status === ACTIVE ? 'EDIT_MEMBER' : 'EDIT_INVITE'}
    >
      <DataProvider
        onSubmit={handleSubmit}
        params={{ account, id }}
        query={Services.getById}
        setStatus={setStatus}
        template={View}
      />
    </Modal>
  );
};

EditUser.propTypes = {
  account: PropTypes.string,
  depth: PropTypes.number,
  error: PropTypes.func,
  id: PropTypes.string,
  in: PropTypes.bool,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  order: PropTypes.number,
  success: PropTypes.func,
};

const mapStateToProps = state => ({
  account: currentAccount(state),
});

const mapDispatchToProps = { error: logger.error, success: logger.success };

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
