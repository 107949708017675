import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';

import Form, { Input, Textarea } from 'Components/Form';
import ModalHeadline from 'Components/ViewHeadline';
import CarouselPicker from 'Components/CarouselPicker';
import Button from 'Components/Button';

import SectionHeadline from './SectionHeadline';
import FormConfig from './FormConfig';
import FormRedirect from './FormRedirect';

const Actions = styled('section')``;

const SignupForm = ({
  className,
  defaultValue,
  handleAdditionalFormFields,
  onBack,
  onChange,
  onSubmit,
}) => {
  const handleChange = useCallback(
    ({
      description,
      fields,
      fieldsTitle,
      media,
      ratio,
      redirect,
      social,
      terms,
    }) => {
      onChange({
        description,
        fields,
        fieldsTitle,
        media,
        ratio,
        redirect,
        social,
        terms,
        type: 'SIGNUP',
      });
    },
    [onChange],
  );

  return (
    <Form
      className={className}
      defaultValue={{
        media: [],
        ratio: 'square',
        redirect: 'THANK_YOU',
        ...defaultValue,
      }}
      onChange={handleChange}
      onSubmit={onSubmit}
    >
      <ModalHeadline heading="INSTRUCTIONS.NEW_SIGNUP" title="SIGNUP" />
      <CarouselPicker titleId="IMAGE_OR_VIDEO" />
      <SectionHeadline heading="INSTRUCTIONS.TEXT_BLOCK" title="TEXT_BLOCK" />
      <Input capitalize label="TITLE" name="title" />
      <Textarea capitalize label="DESCRIPTION" name="description" />
      <SectionHeadline heading="INSTRUCTIONS.TEXT_BLOCK" title="FORM_BLOCK" />
      <Input capitalize label="TITLE" name="fieldsTitle" />
      <FormConfig defaultValue={defaultValue?.fields} />
      <SectionHeadline heading="INSTRUCTIONS.TEXT_BLOCK" title="REDIRECT" />
      <FormRedirect handleAdditionalFormFields={handleAdditionalFormFields} />
      <Actions>
        <Button capitalize primary translate type="submit">
          ACTIONS.CREATE
        </Button>
        <Button capitalize secondary translate onClick={onBack}>
          ACTIONS.CANCEL
        </Button>
      </Actions>
    </Form>
  );
};

SignupForm.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.object,
  handleAdditionalFormFields: PropTypes.func,
  onBack: PropTypes.func,
  onChange: PropTypes.func,
  onSubmit: PropTypes.number,
};

export default styled(SignupForm)`
  padding-bottom: 6rem;

  ${Input}, ${Textarea} {
    margin-top: 1.6rem;
    > * {
      resize: none;
    }
  }

  ${Actions} {
    background: ${theme('--color-blueish-white')};
    border-top: 0.1rem solid ${theme('--color-dark-night-10')};
    bottom: 0;
    display: flex;
    left: 0;
    padding: 2rem;
    position: fixed;
    width: 100vw;

    ${Button} {
      flex-basis: 0;
      flex-grow: 1;
      margin-right: 0.4rem;

      &:last-child {
        margin-left: 0.4rem;
        margin-right: 0;
      }
    }
  }

  @media (${theme('--screen-small')}) {
    padding-bottom: 0;

    ${Actions} {
      border: 0;
      display: inline-block;
      margin-top: 4rem;
      padding: 1.6rem 0;
      position: inherit;
      width: auto;

      ${Button} {
        margin: 0 1.6rem 0 0;
        width: 14.4rem;

        &:last-child {
          margin: 0 1.6rem 0 0;
        }
      }
    }
  }
`;
