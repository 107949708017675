import React, { Component } from 'react';
import { FormattedDate } from '@react-intl';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';

import Avatar from 'Components/Avatar';
import Icon from 'Components/Icon';
import { ContextMenu } from 'Components/ContextMenu';
import { Row } from 'Components/ResponsiveTable';
import { PlaceholderNoData as Placeholder } from 'Containers/Placeholder';

import Details from '../Details';

const User = styled('section')``;
const Launched = styled(Placeholder)``;
const Label = styled(Placeholder)``;
const NameLabel = styled(Label)``;

class Template extends Component {
  static propTypes = {
    className: PropTypes.string,
    customerId: PropTypes.number,
    date: PropTypes.instanceOf(Date),
    empty: PropTypes.bool,
    name: PropTypes.string,
    orderId: PropTypes.number,
    paymentId: PropTypes.string,
    platform: PropTypes.string,
    profile: PropTypes.shape({
      imageUrlMini: PropTypes.string,
      imageUrlNormal: PropTypes.string,
    }),
    quantity: PropTypes.number,
    revShareAmount: PropTypes.string,
    revSharePercent: PropTypes.string,
    total: PropTypes.number,
  };

  state = { details: null, isModalOpen: false };

  handleClose = () => {
    this.setState({ isModalOpen: false });
  };

  handleClick = details => {
    this.setState({ details, isModalOpen: true });
  };

  render() {
    const {
      className,
      empty,
      name,
      date,
      customerId,
      orderId,
      paymentId,
      platform: constant = '',
      profile: { imageUrlNormal } = {},
      quantity,
      revSharePercent,
      revShareAmount,
      total,
    } = this.props;
    const platform = constant.toLowerCase();
    let { details } = this.state;
    const { isModalOpen } = this.state;

    return (
      <>
        <Row className={className} empty={empty}>
          <User className={className} to={`/customers/${customerId}`}>
            <Avatar fill image={imageUrlNormal} name={name} />
            <NameLabel placeholder="customer name">{name}</NameLabel>
          </User>
          <Launched placeholder="date">
            <FormattedDate
              day="2-digit"
              month="long"
              value={new Date(date)}
              year="numeric"
            />
          </Launched>
          {platform ? <Icon name={platform}>{constant}</Icon> : <span />}
          <Label>{quantity}</Label>
          <Label>{revSharePercent}</Label>
          <Label>${revShareAmount}</Label>
          <Label>${total}</Label>
          <ContextMenu
            right
            className={className}
            options={{
              VIEW_RECEIPT: () => {
                details = {
                  currency: 'USD',
                  customerAvatar: imageUrlNormal,
                  customerName: name,
                  date,
                  itemCount: quantity,
                  orderId,
                  paymentId,
                  total,
                };
                this.handleClick(details);
              },
            }}
          />
        </Row>
        <Details in={isModalOpen} {...details} onClose={this.handleClose} />
      </>
    );
  }
}

export default styled(Template)`
  display: inline-grid;
  grid-template-columns: 2fr 2fr 2fr 1fr 1fr 3fr 1fr 1fr;
  height: 8rem;

  ${Label} {
    ${theme('--font-small')}
    ${theme('--font-opacity-40')}
    ${theme('--font-weight-book')}
  }

  ${User} {
    align-items: center;
    display: grid;
    grid-column-gap: 1.6rem;
    grid-template-columns: 4rem 1fr;

    ${Avatar} {
      height: 4rem;
      width: 4rem;
    }

    ${NameLabel} {
      ${theme('--font-weight-medium')}
      color: ${theme('--color-primary')};
    }
  }

  ${ContextMenu} {
    align-items: center;
  }
`;
