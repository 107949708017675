import React, { createContext, useContext } from 'react';
import PropTypes from '@prop-types';

import Fetcher from 'Components/Fetcher';

export const Context = createContext({});

const { Provider, Consumer } = Context;

export const useDataProviderContext = () => {
  return useContext(Context);
};

const isEmpty = data => {
  if (!data) return true;
  if (Array.isArray(data) && data.length === 0) return true;
  if (typeof data === 'object' && Object.entries(data).length === 0)
    return true;
  return false;
};

const DataProvider = ({
  className,
  query,
  params,
  template: Template,
  emptyTemplate: EmptyTemplate = Template,
  emptyVerifier = isEmpty,
  ...props
}) => {
  return (
    <Fetcher params={params} query={query}>
      {({ data, loading, fetch, ...rest }) => {
        const empty = !loading && (data ? emptyVerifier(data) : true);

        return (
          <Provider value={{ empty, loading, refetch: fetch }}>
            {loading || !empty ? (
              <Template
                className={className}
                data={data}
                {...props}
                {...rest}
              />
            ) : (
              <EmptyTemplate className={className} {...props} {...rest} />
            )}
          </Provider>
        );
      }}
    </Fetcher>
  );
};

DataProvider.propTypes = {
  className: PropTypes.string,
  emptyTemplate: PropTypes.component,
  emptyVerifier: PropTypes.func,
  params: PropTypes.object,
  query: PropTypes.func.isRequired,
  template: PropTypes.component.isRequired,
};

export { Consumer, Provider };

export default DataProvider;
