import { all, takeLatest, put, select } from 'redux-saga/effects';

import { get, set } from 'Actions/accounts';
import * as session from 'Actions/session';

import { handleErrors } from 'Modules/handleErrors';
import service from 'Services/accounts';

function* getEffect() {
  try {
    const officialAccounts = yield service.get();
    yield put(get.success(officialAccounts));
  } catch (error) {
    yield handleErrors(error);
    yield put(session.logout());
  }
}

function* setEffect({ payload: account }) {
  try {
    const accounts = yield select(state => state.accounts);
    const { currencyCode: currency } = accounts.find(
      ({ id }) => id === account,
    );
    yield put(set.currency(currency));
  } catch (e) {
    // No error
  }
}

export default function*() {
  yield all([takeLatest(get, getEffect), takeLatest(set, setEffect)]);
}
