import React from 'react';
import PropTypes from '@prop-types';

import { connect } from 'react-redux';

import Modal from 'Components/Modal';

import * as logger from 'Actions/logger';

import { currentAccount, settings } from 'Selectors';

import MemberServices from 'Services/members';

import DataProvider from 'Containers/DataProvider';

import View from './Settings';

const mapStateToProps = state => ({
  account: currentAccount(state),
  settings: settings(state),
});

const mapDispatchToProps = { error: logger.error, success: logger.success };

const Settings = ({
  account,
  depth,
  in: modalIn,
  onBack,
  onClose,
  order,
  settings: { account: ofaId },
  success,
  error,
}) => {
  return (
    <Modal
      depth={depth}
      in={modalIn}
      onBack={onBack}
      onClose={onClose}
      order={order}
      title="EDIT_PROFILE"
    >
      <DataProvider
        account={account}
        ofaId={ofaId}
        onClose={onClose}
        params={{ account }}
        query={MemberServices.get}
        showError={error}
        success={success}
        template={View}
      />
    </Modal>
  );
};

Settings.propTypes = {
  account: PropTypes.number,
  depth: PropTypes.number,
  error: PropTypes.func,
  in: PropTypes.bool,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  order: PropTypes.number,
  settings: PropTypes.shape({
    account: PropTypes.number,
  }),
  success: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
