import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';
import { useField } from 'spooner';

import { AutoSuggest as Autosuggest } from 'Components/Form';

import Product from './Product';

const Products = ({
  className,
  data: { collection: products = [] } = {},
  name,
  onScrollEnd: handleScrollEnd = () => {},
  onSearch: handleSearch = () => {},
}) => {
  const [selected, setSelected] = useState(products);
  const { onChange = () => {} } = useField({ name });

  useEffect(() => {
    onChange(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selected), onChange]);

  const handleChange = useCallback(
    ([{ id }]) => {
      setSelected(prevSelected => [...prevSelected, id]);
    },
    [setSelected],
  );

  const handleRemove = useCallback(
    uid => () => {
      setSelected(prevSelected => [...prevSelected.filter(id => id !== uid)]);
    },
    [setSelected],
  );

  return (
    <div className={className}>
      <Autosuggest
        capitalize
        onChange={handleChange}
        onScrollEnd={handleScrollEnd}
        onSearch={handleSearch}
        options={products.filter(({ id }) => !products.includes(id))}
        placeholder="SEARCH"
        value={[]}
      />
      {selected.map(uid => {
        const product = products.find(({ id }) => uid === id);

        return (
          <Product key={product.id} {...product} onClick={handleRemove(uid)} />
        );
      })}
    </div>
  );
};

Products.propTypes = {
  className: PropTypes.string,
  currency: PropTypes.string,
  data: PropTypes.shape({
    products: PropTypes.arrayOf(PropTypes.object),
  }),
  name: PropTypes.string,
  onScrollEnd: PropTypes.func,
  onSearch: PropTypes.func,
};

export default styled(Products)`
  ${Autosuggest} {
    margin-bottom: 2.4rem;
  }

  ${Product} {
    margin-bottom: 1.6rem;
  }
`;
