import React from 'react';
import PropTypes from '@prop-types';
import PieChart from 'Components/Charts/PieChart';
import PieChartLoader from 'Components/PieChartLoader';
import { withIntl, intlShape } from '@react-intl';

const Template = ({ data = {}, className, intl }) => {
  const translatedData = Object.keys(data).reduce(
    (result, key) => ({
      ...result,
      [intl.formatMessage({ id: key })]: data[key],
    }),
    {},
  );

  return (
    <PieChart
      className={className}
      data={translatedData}
      loader={PieChartLoader}
    />
  );
};

Template.propTypes = {
  className: PropTypes.string,
  data: PropTypes.objectOf(PropTypes.number),
  intl: intlShape,
};

export default withIntl(Template);
