import React from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';

import ViewHeadline from 'Components/ViewHeadline';
import Card from 'Components/Card';

import Table from './components/Table';

const Answers = ({ className, collection, customer, survey }) => {
  return (
    <section className={className}>
      <ViewHeadline
        heading="ANSWERS_SUBHEADING"
        title="ANSWERS_HEADING"
        values={{
          customerName: `${customer.firstName} ${customer.lastName}'s`,
          surveyName: survey.name,
        }}
      />
      <Card title="Answers">
        <Table collection={collection} />
      </Card>
    </section>
  );
};

Answers.propTypes = {
  className: PropTypes.string,
  collection: PropTypes.array,
  customer: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  survey: PropTypes.shape({
    name: PropTypes.string,
  }),
};

export default styled(Answers)`
  display: grid;
  grid-gap: 3.2rem;
  grid-template: auto / auto;

  @media (${theme('--level-3')}) {
    margin: 0 auto;
    max-width: 160rem;
  }
`;
