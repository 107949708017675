import React from 'react';
import PropTypes from 'prop-types';
import styled from '@styled-components';

import Loader from 'Components/Loader';
import Table from 'Components/ResponsiveTable';

import Template from './Template';
import DesktopTemplate from './DesktopTemplate';
import EmptyTemplate from './EmptyTemplate';
import EmptyTemplateFilters from './EmptyTemplateFilters';
import Header from './Header';

const TableComponent = ({
  className,
  data: { collection: customers = [] } = {},
  filterAmmount,
  onCursorChange: handleCursorChange = () => {},
  onRowClick: handleRowClick = () => {},
}) => {
  return (
    <Table
      className={className}
      desktopTemplate={DesktopTemplate}
      emptyTemplate={filterAmmount ? EmptyTemplateFilters : EmptyTemplate}
      header={Header}
      items={customers}
      onCursorChange={handleCursorChange}
      options={{ onClick: handleRowClick }}
      rows={5}
      template={Template}
    />
  );
};

TableComponent.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    customers: PropTypes.arrayOf(PropTypes.object),
    total: PropTypes.number,
  }),
  filterAmmount: PropTypes.number,
  onCursorChange: PropTypes.func,
  onRowClick: PropTypes.func,
};

export default styled(TableComponent)`
  height: 100%;
  overflow: hidden;

  ${Loader} {
    align-items: center;
  }
`;
