import React from 'react';
import SmsTemplate from './SmsTemplate';
import DefaultTemplate from './DefaultTemplate';
import PropTypes from 'prop-types';

const detailsTemplates = { SMS: SmsTemplate, WHATSAPP: SmsTemplate };

const Details = ({ type, ...props }) => {
  const DetailTemplate = detailsTemplates[type] || DefaultTemplate;

  return <DetailTemplate type={type} {...props} />;
};

Details.propTypes = {
  type: PropTypes.string,
};

export default Details;
