import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@react-intl';
import styled, { theme } from '@styled-components';

const P = styled('p')``;

const Empty = ({ className }) => (
  <FormattedMessage capitalize className={className} component={P} id="NO_DATA">
    No data
  </FormattedMessage>
);

Empty.propTypes = {
  className: PropTypes.string,
};

export default styled(Empty)`
  ${theme('--font-weight-demi')}
  align-items: center;
  display: flex;
  ${theme('--font-large')}
  height: 100%;
  justify-content: center;
  margin: 0;
  width: 100%;
`;
