import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'spooner';
import styled, { theme } from '@styled-components';

import { useDeepCompareEffect } from 'Hooks';

import Icon from 'Components/Icon';
import List from 'Components/List';

import Dropdown from './Dropdown';
import Item from './Item';

const Display = styled('div')``;
const Overlay = styled('button')``;
const SelectHTML = styled('select')``;
const Value = styled('output')``;

export const MultiSelect = ({
  className,
  options: initial = [{}],
  ...props
}) => {
  const { disabled = false, name, onChange = () => {}, value } = useField(
    props,
  );
  const [isOpen, setOpen] = useState(false);
  const [options, setOptions] = useState(
    initial.map(option => ({
      ...option,
      value: true,
    })),
  );

  useDeepCompareEffect(() => {
    onChange(
      options.filter(({ value: v }) => Boolean(v)).map(({ name: n }) => n),
    );
  }, [options, onChange]);

  const handleChange = useCallback((pName, v) => {
    setOptions(prevOptions =>
      prevOptions.map(({ name: n, value: same, ...rest }) => ({
        ...rest,
        name: n,
        value: pName === n ? v : same,
      })),
    );
  }, []);

  const handleToggle = () => {
    setOpen(status => !status);
  };

  const { label } = options.find(({ value: v }) => Boolean(v)) || {};

  return (
    <div
      className={className}
      data-disabled={disabled}
      data-name={name}
      data-value={value}
    >
      <Display onClick={handleToggle}>
        <Value>{label}</Value>
        <Icon name="vertical-arrows">ACTIONS.SELECT_OPTION</Icon>
      </Display>
      <Dropdown in={isOpen}>
        <List
          noWrapper
          data={options}
          options={{ onChange: handleChange }}
          template={Item}
        />
      </Dropdown>
      {isOpen && <Overlay onClick={handleToggle} />}
    </div>
  );
};

MultiSelect.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      name: PropTypes.string,
      value: PropTypes.any,
    }),
  ),
  value: PropTypes.any,
};

export default styled(MultiSelect)`
  cursor: pointer;
  ${theme('--font-medium')}
  position: relative;

  &[data-disabled='true'] {
    opacity: 0.6;
    pointer-events: none;
  }

  &:focus-within {
    ${Display} {
      border-color: ${theme('--color-primary')};
    }
  }

  ${Display} {
    align-items: center;
    background: ${theme('--color-light')};
    border: 0.1rem solid ${theme('--color-dark-night-10')};
    border-radius: 0.6rem;
    color: ${theme('--color-dark-night-80')};
    display: flex;
    height: 4.8rem;
    justify-content: space-between;
    padding: 0.8rem;
    position: relative;
    transition: border-color 250ms ease-out;
    width: 100%;

    ${Value} {
      flex: 1;
      padding: 0.8rem;
    }

    ${Icon} {
      flex-shrink: 0;
    }
  }

  ${Overlay} {
    background-color: Transparent;
    border: none;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    transition: opacity 350ms ease-out;
    width: 100%;
    z-index: 1;
  }

  ${SelectHTML} {
    border: none;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;

    &:focus {
      outline: 0;
    }
  }
`;
