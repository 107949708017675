import { createReducer } from '@redux-act';

import * as accounts from 'Actions/accounts';
import * as ranges from 'Actions/ranges';
import * as session from 'Actions/session';

export default createReducer(
  {
    [accounts.set]: (state, id) => ({ ...state, account: id }),
    [accounts.set.currency]: (state, currency) => ({ ...state, currency }),
    [ranges.change]: (state, { name, options: { filter, from, to } }) => ({
      ...state,
      [name]: {
        filter,
        from,
        to,
      },
    }),
    [session.logout.success]: () => ({}),
  },
  {},
);
