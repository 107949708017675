import React, { useCallback, useEffect, useState } from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from 'prop-types';

import Card from './components/Card';
import RemoveMedia from './components/RemoveMedia';
import EmptyTemplate from './components/EmptyTemplate';
import EmptyTemplateFilters from './components/EmptyTemplateFilters';
import GetLink from './components/GetLink';

const Layout = styled('div')``;
const PlayIcon = styled('img')``;

const UserGeneratedContent = ({
  className,
  cursor,
  data: {
    collection,
    media = collection.length ? collection : [{ id: 1 }, { id: 2 }, { id: 3 }],
  },
  filters,
  onDelete: handleDelete = () => {},
  onCursorChange: handleCursorChange = () => {},
}) => {
  const [selected, setRowSelect] = useState();
  const [type, setType] = useState();

  const handleRowSelect = useCallback(
    (row, t) => {
      setRowSelect(row);
      setType(t);
    },
    [setRowSelect, setType],
  );

  const handleClose = useCallback(() => {
    setRowSelect();
  }, [setRowSelect]);

  const handleAccept = useCallback(() => {
    handleDelete(selected.id, selected.mediaUrl);
    handleClose();
  }, [handleClose, handleDelete, selected]);

  const handleCursor = useCallback(
    ({ target }) => {
      if (target.scrollTop === target.scrollHeight - target.offsetHeight) {
        handleCursorChange(cursor + 1);
      }
    },
    [cursor, handleCursorChange],
  );

  useEffect(() => {
    const content = document.querySelector('main');

    content.addEventListener('scroll', handleCursor);
    return () => content.removeEventListener('scroll', handleCursor);
  }, [handleCursor]);

  const filtered = media.filter(({ empty }) => !empty);

  return (
    <>
      <GetLink
        in={!!selected && type === 'getLink'}
        onClose={handleClose}
        {...(selected && selected)}
      />
      <RemoveMedia
        in={!!selected && type === 'delete'}
        onAccept={handleAccept}
        onCancel={handleClose}
      />
      <div className={className}>
        <Layout>
          {filtered.map(
            ({ id, platform, source, type: t, image, from, to, tags }) => (
              <Card
                key={id}
                from={from}
                id={id}
                image={image}
                onRowSelect={handleRowSelect}
                platform={platform}
                source={source}
                tags={tags}
                to={to}
                type={t}
              />
            ),
          )}
        </Layout>
        {!media.length &&
          (filters ? <EmptyTemplateFilters /> : <EmptyTemplate />)}
      </div>
    </>
  );
};

UserGeneratedContent.propTypes = {
  className: PropTypes.string,
  cursor: PropTypes.number,
  data: PropTypes.object,
  filters: PropTypes.string,
  onCursorChange: PropTypes.func,
  onDelete: PropTypes.func,
};

export default styled(UserGeneratedContent)`
  ${Layout} {
    display: grid;
    grid-column: 1;
    grid-gap: 1.6rem;
    grid-row: 2;
    grid-template-columns: repeat(1, 1fr);
  }

  @media (${theme('--screen-large')}) {
    ${Layout} {
      grid-gap: 3.2rem;
      grid-template-columns: repeat(3, 1fr);
      ${Card} {
        height: 25.2rem;
        width: 28rem;
        ${PlayIcon} {
          height: 6.4rem;
        }
      }
    }
  }
`;
