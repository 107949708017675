import PropTypes from 'prop-types';

import useFetcher from './hooks/fetcher';

export { useFetcher };

const Fetcher = ({
  children = () => {},
  query = Promise.resolve(),
  params = {},
}) => {
  const { refetch, ...state } = useFetcher({ params, query });

  return children({
    ...state,
    fetch: refetch,
    refetch,
  });
};

Fetcher.propTypes = {
  children: PropTypes.func,
  params: PropTypes.objectOf(PropTypes.any),
  query: PropTypes.func,
};

export default Fetcher;
