import moment from 'moment';
import C from 'Constants';

const {
  FILTERS: { ALL_TIME, TODAY, YESTERDAY, LAST_WEEK, LAST_MONTH, LAST_YEAR },
} = C;

export function getUnit(from, to) {
  const diff = moment.duration(moment(to).diff(moment(from)));

  if (diff.years() >= 1 || diff.months() > 4) return 'month';
  if (diff.months() > 0 || diff.days() / 7 >= 1) return 'day';
  return 'hour';
}

export function getDateFilter(filter) {
  switch (filter) {
    case ALL_TIME:
      return {
        from: moment('2000-01-01').format(),
        to: moment().format(),
      };
    case TODAY:
      return {
        from: moment()
          .startOf('day')
          .format(),
        to: moment().format(),
      };
    case YESTERDAY:
      return {
        from: moment()
          .subtract(1, 'day')
          .startOf('day')
          .format(),
        to: moment()
          .subtract(1, 'day')
          .endOf('day')
          .format(),
      };
    case LAST_WEEK:
      return {
        from: moment()
          .subtract(1, 'weeks')
          .format(),
        to: moment().format(),
      };
    case LAST_MONTH:
      return {
        from: moment()
          .subtract(1, 'month')
          .format(),
        to: moment().format(),
      };
    case LAST_YEAR:
      return {
        from: moment()
          .subtract(1, 'year')
          .format(),
        to: moment().format(),
      };
    default:
      return {};
  }
}

export function getDatesTransformedByUnit(unit, from, to) {
  let dates = {};

  switch (unit) {
    case 'day':
      dates = {
        from: moment(from)
          .startOf('day')
          .format(),
        to: moment(to).format(),
      };
      break;
    case 'hour':
      dates = {
        from: moment(from).format(),
        to: moment(to).format(),
      };
      break;
    case 'month':
      dates = {
        from: moment(from)
          .startOf('month')
          .startOf('day')
          .format(),
        to: moment(to).format(),
      };
      break;
    default:
      return {};
  }

  return dates;
}

export const OPTIONS = {
  day: {
    day: '2-digit',
    frecuency: 4,
    month: 'short',
    year: '2-digit',
  },
  hour: {
    day: '2-digit',
    frecuency: 2,
    hour: 'numeric',
  },
  month: {
    frecuency: 2,
    month: 'short',
    year: '2-digit',
  },
};
