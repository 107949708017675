import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled, { withStyles as css, theme } from '@styled-components';

import { compose } from 'underscore';

import Button from 'Components/Button';
import Card from 'Components/Card';
import Icon from 'Components/Icon';
import Link from 'Components/Link';

import Placeholder from 'Containers/Placeholder';
import { useLocation } from 'react-router-dom';

const Image = styled(Placeholder.img).attrs({
  forwardProps: ['src'],
})`
  background-color: ${theme('--color-light')};
  background-image: url(${props => props.image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;
const Actions = styled('div')``;

const Media = ({ className, images }) => {
  const [selected, setSelected] = useState(0);
  const { pathname } = useLocation();

  const handleIncremet = useCallback(
    () => setSelected(selected < images.length - 1 ? selected + 1 : selected),
    [images.length, selected],
  );
  const handleDecrement = useCallback(
    () => setSelected(selected > 0 ? selected + -1 : selected),
    [selected],
  );

  return (
    <Card className={className}>
      <Link to={`${pathname}/gallery`}>
        <Image
          key={images[selected]}
          fill
          image={images[selected]}
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
        />
      </Link>
      <Actions>
        <Button disabled={selected === 0} onClick={handleDecrement}>
          <Icon name="chevronleft">ACTIONS.DECREASE</Icon>
        </Button>
        <Button
          disabled={selected + 1 >= images.length}
          onClick={handleIncremet}
        >
          <Icon name="chevronright">ACTIONS.INCREASE</Icon>
        </Button>
      </Actions>
    </Card>
  );
};

Media.propTypes = {
  className: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.string),
};

export default compose(
  css`
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    padding: 0.8rem;

    ${Link} {
      width: 100%;
    }

    ${Image} {
      align-self: center;
      border-radius: 0.6rem;
      height: 19rem;
      object-fit: contain;
      width: 100%;
    }

    ${Actions} {
      align-items: flex-end;
      background: ${theme('--color-light')};
      display: flex;
      height: 4rem;
      justify-content: flex-end;

      ${Button} {
        outline: none;

        &:focus {
          box-shadow: none;
        }

        &:disabled {
          ${Icon} {
            opacity: 0.6;
          }
        }

        ${Icon} {
          display: block;
          outline-color: #999fa3;
        }
      }
    }

    @media (${theme('--screen-small')}) {
      height: 25rem;
    }
  `,
)(Media);
