import { createService } from '@redux-act';

import request from 'Providers/request';

const edit = createService(
  ({ account, category, id, service, integration }, token) =>
    request.update(
      `/ofa/${account}/integrations/${id}/${category}/${service}`,
      integration,
      {},
      { token },
    ),
);

const get = createService(
  async ({ account, filters: { available, filterBy } = {}, limit }, token) => {
    const integrations = await request(
      `/ofa/${account}/integrations`,
      'get',
      { available, filterBy, limit },
      {},
      { token, ttl: 0 },
    );

    return {
      integrations,
    };
  },
);

const getById = createService(({ account, category, id, service }, token) =>
  request(
    `/ofa/${account}/integrations/${id}/${category}/${service}`,
    'get',
    {},
    {},
    { token, ttl: 0 },
  ),
);

const getOauth = createService(
  async ({ account, id, token: authkey }, token) => {
    const { url } = await request(
      `/oauth/google/calendar`,
      'get',
      { authkey, id, ofaId: account, redirect: 0 },
      {},
      { headers: {}, token, ttl: 0 },
    );

    return url;
  },
);

export default {
  edit,
  get,
  getById,
  getOauth,
};
