import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@styled-components';

import TableDataProvider from 'Containers/TableDataProvider';

import { currentAccount } from 'Selectors';

import service from 'Services/customers';

import Template from './Campaigns';

const mapStateToProps = state => ({
  account: currentAccount(state),
});

const Campaigns = ({ account, id, ...props }) => {
  const [searchBy, setSearchBy] = useState('');

  const handleSearch = useCallback(search => {
    setSearchBy(search);
  }, []);

  if (!id) return false;
  let filterAmmount = 0;
  if (searchBy) filterAmmount += 1;

  return (
    <TableDataProvider
      filterAmmount={filterAmmount}
      limit={5}
      name="customerCampaigns"
      onSearch={handleSearch}
      params={{ account, id, searchBy }}
      query={service.campaigns}
      template={Template}
      {...props}
    />
  );
};

Campaigns.propTypes = {
  account: PropTypes.number,
  id: PropTypes.number,
};

export default compose(connect(mapStateToProps), withStyles``)(Campaigns);
