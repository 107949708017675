import React from 'react';
import styled, { css } from '@styled-components';
import PropTypes from '@prop-types';

const Content = styled('span')``;

const LoadingBar = ({ className, children }) => (
  <div className={className}>
    <Content>{children}</Content>
  </div>
);

LoadingBar.propTypes = {
  children: PropTypes.children,
  className: PropTypes.string,
};

const highlightMixin = ({ highlight }) =>
  highlight &&
  css`
    ${Content} {
      background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.6) 0%,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0.6) 100%
      );
    }
  `;

const fillMixin = ({ fill }) =>
  fill &&
  css`
    ${Content} {
      display: block;
      height: 100%;
      width: 100%;
    }
  `;

export default styled(LoadingBar).attrs({ 'aria-hidden': 'true' })`
  max-width: 100%;

  ${Content} {
    animation: MoveGradient 3s ease-in-out infinite;
    background: linear-gradient(
      90deg,
      rgba(51, 63, 71, 0.2) 0%,
      rgba(51, 63, 71, 0.05) 50%,
      rgba(51, 63, 71, 0.2) 100%
    );
    background-size: 200%;
    border-radius: 0.6rem;
    color: rgba(0, 0, 0, 0) !important;
    display: table;
    max-width: 100%;
  }

  ${highlightMixin}
  ${fillMixin}

  @keyframes MoveGradient {
    0% {
      background-position-x: 200%;
    }
    100% {
      background-position-x: 0%;
    }
  }
`;
