import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@styled-components';
import { compose } from 'underscore';

import { currentAccount } from 'Selectors';

import CreateMicroapp from 'Containers/CreateMicroapp';

import Form from './MultiproductForm';

const mapStateToProps = state => ({
  account: currentAccount(state),
});

const MultiproductForm = ({ className, data: defaultValue = {}, ...props }) => {
  return (
    <CreateMicroapp
      className={className}
      {...props}
      defaultValue={defaultValue}
      template={Form}
      type="MULTIPRODUCT"
    />
  );
};

MultiproductForm.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
};

export default compose(
  connect(mapStateToProps),
  withStyles``,
)(MultiproductForm);
