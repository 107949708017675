import React from 'react';
import PropTypes from '@prop-types';
import styled, { theme } from '@styled-components';
import { FormattedMessage } from '@react-intl';

const P = styled('p')``;

const EmptyTemplate = ({ className }) => (
  <article className={className}>
    <FormattedMessage capitalize component={P} id="EMPTY_ADDRESS">
      Address is missing for this customer
    </FormattedMessage>
  </article>
);

EmptyTemplate.propTypes = {
  className: PropTypes.string,
};

export default styled(EmptyTemplate)`
  align-items: center;
  background: ${theme('--color-blueish-white')};
  border-radius: 0.6rem;
  display: flex;
  flex-direction: column;
  height: 8rem;
  justify-content: center;
  padding: 2.4rem 1.6rem;
  width: 100%;

  ${P} {
    ${theme('--font-opacity-60')}
    max-width: 23rem;
    text-align: center;
  }
`;
