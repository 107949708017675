import { createReducer } from '@redux-act';

import { get } from 'Actions/accounts';

export default createReducer(
  {
    [get.success]: (ignore, accounts) => [...accounts],
  },
  [],
);
