import React from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';

import Table from 'Components/ResponsiveTable';

import EmptyTemplate from './EmptyTemplate';
import EmptyTemplateFilters from './EmptyTemplateFilters';

import Hospitality from './Hospitality';
import Retail from './Retail';

const Templates = {
  HOTEL: Hospitality,
  RETAIL: Retail,
};

const ProductsTable = ({
  className,
  data: { collection: products } = {},
  filterAmmount,
  onOrderBy: handleOrderBy = () => {},
  onCursorChange: handleCursorChange = () => {},
  order,
  sortBy,
  type,
}) => {
  const { Header, DesktopTemplate, Template } = Templates[type];

  return (
    <Table
      className={className}
      desktopRows={8}
      desktopTemplate={DesktopTemplate}
      emptyTemplate={filterAmmount ? EmptyTemplateFilters : EmptyTemplate}
      header={Header}
      items={products}
      onCursorChange={handleCursorChange}
      onOrderBy={handleOrderBy}
      order={order}
      rows={5}
      sortBy={sortBy}
      template={Template}
    />
  );
};

ProductsTable.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    products: PropTypes.arrayOf(PropTypes.object),
    total: PropTypes.number,
  }),
  filterAmmount: PropTypes.number,
  onCursorChange: PropTypes.func,
  onOrderBy: PropTypes.func,
  order: PropTypes.string,
  sortBy: PropTypes.func,
  type: PropTypes.string,
};

export default styled(ProductsTable)``;
