import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@styled-components';

import Card from 'Components/Card';
import Table from 'Components/ResponsiveTable';

import Template from './components/Order';
import Details from './components/Details';

class Orders extends Component {
  static propTypes = {
    className: PropTypes.string,
    data: PropTypes.shape({
      collection: PropTypes.array,
      trend: PropTypes.string,
    }),
    onCursorChange: PropTypes.func,
  };

  static defaultProps = {
    onCursorChange() {},
  };

  state = { isModalOpen: false };

  handleClick = id => () => {
    const {
      data: { collection = [] },
    } = this.props;
    this.setState({
      isModalOpen: true,
      order: collection.find(({ id: orderId }) => orderId === id),
    });
  };

  handleClose = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    const {
      className,
      data: { collection = [] } = {},
      onCursorChange: handleCursorChange,
    } = this.props;
    const { isModalOpen, order } = this.state;

    return (
      <>
        <Card className={className} title="LAST_ORDERS">
          <Table
            items={collection}
            onCursorChange={handleCursorChange}
            options={{ onClick: this.handleClick }}
            placeholders={{
              heading: '86',
              subheading: '+1.5% orders this month',
            }}
            rows={4}
            template={Template}
          />
        </Card>
        <Details in={isModalOpen} {...order} onClose={this.handleClose} />
      </>
    );
  }
}

export default styled(Orders)`
  padding: 6.4rem 0 0;
`;
