import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';

import TableDataProvider from 'Containers/TableDataProvider';
import Toolbar from 'Containers/CardToolbar';

import { AutoSuggest, Input, Select } from 'Components/Form';
import Card from 'Components/Card';

import ContextMenu from './ContextMenu';
import Table from './components/Table';

import service from 'Services/transactions';

const platforms = [
  { name: 'All', value: null },
  { name: 'Facebook Messenger', value: 'FB_MESSENGER' },
  { name: 'Instagram', value: 'INSTAGRAM' },
  { name: 'Facebook', value: 'FACEBOOK' },
  { name: 'Line', value: 'LINE' },
  { name: 'Linkedin', value: 'LINKEDIN' },
  { name: 'Twitter', value: 'TWITTER' },
];

const Transactions = ({ account, className, from, to }) => {
  const [platform, setPlatform] = useState(null);
  const filterAmmount = platform ? 1 : 0;

  const handleSelectPlatform = value => setPlatform(value);

  return (
    <>
      <Card className={className}>
        <Toolbar
          contextMenu={() => (
            <ContextMenu
              account={account}
              from={from}
              platform={platform}
              to={to}
            />
          )}
          filterAmmount={filterAmmount}
          title="LIST_OF_TRANSACTIONS"
        >
          <Select
            onChange={handleSelectPlatform}
            options={platforms}
            value={platform}
          />
        </Toolbar>
        <TableDataProvider
          filterAmmount={filterAmmount}
          limit={5}
          params={{ account, from, platform, to }}
          query={service.find}
          template={Table}
        />
      </Card>
    </>
  );
};

Transactions.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
  from: PropTypes.string,
  to: PropTypes.string,
};

export default styled(Transactions)`
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  height: 100%;
  padding: 0;
  position: relative;

  @media (${theme('--screen-medium')}) {
    ${Input}, ${Select} {
      display: inline-block;
      margin-right: 1.6rem;
      width: 24rem;
    }

    ${AutoSuggest} {
      display: block;
      width: 24rem;
    }
  }
`;
