import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';

import Icon from 'Components/Icon';

const Button = styled('button')`
  background-color: ${props => (props.active ? '#99BEFF' : '#fff')};
  grid-column: ${props => props.column};
`;

const Arrows = styled('div')`
  left: calc(${props => props.left} * 8.8rem);
  ${props => props.back && 'transform: rotate(-180deg);'}
`;

const ArrowsIcon = styled(Icon)``;

const Tabs = ({
  className,
  onTabSelect,
  selectedTab,
  tabLabel,
  tabsCount,
  tabsPerRow,
}) => {
  const [tab, setTab] = useState(0);
  const fromIndex = tab * tabsPerRow;
  const toIndex = fromIndex + tabsPerRow;
  const btnsVisibleCount =
    tabsCount >= toIndex ? tabsPerRow : tabsPerRow - (toIndex - tabsCount);
  const isLastTab = toIndex >= tabsCount;
  const arr = Array.from(Array(tabsCount).keys());

  const handleArrows = () => setTab(isLastTab ? tab - 1 : tab + 1);
  const handleBtn = index => () => onTabSelect(index);

  return (
    <div className={className}>
      {arr.map(index => {
        const isActive = (!selectedTab && index === 0) || selectedTab === index;
        const isVisible = index >= fromIndex && index < toIndex;
        const isMargin =
          isLastTab && !isVisible && tabsCount - tabsPerRow <= index;
        const hideClass = isMargin ? 'move' : 'hidden';

        return (
          <Button
            key={index}
            active={isActive}
            className={isVisible ? 'visible' : hideClass}
            column={(index % tabsPerRow) + 1}
            onClick={handleBtn(index)}
          >
            {tabLabel(index)}
          </Button>
        );
      })}
      {tabsCount > tabsPerRow && (
        <Arrows back={isLastTab} left={btnsVisibleCount} onClick={handleArrows}>
          <ArrowsIcon name="expand" />
        </Arrows>
      )}
    </div>
  );
};

Tabs.defaultProps = {
  tabLabel: index => index + 1,
  tabsCount: 0,
  tabsPerRow: 8,
};

Tabs.propTypes = {
  className: PropTypes.string,
  onTabSelect: PropTypes.func,
  selectedTab: PropTypes.number,
  tabLabel: PropTypes.func,
  tabsCount: PropTypes.number,
  tabsPerRow: PropTypes.number,
};

export default styled(Tabs)`
  display: inline-grid;
  grid-column-gap: 0.8rem;
  grid-template-columns: repeat(${props => props.tabsPerRow}, 8rem);
  position: relative;

  ${Button} {
    border: 0.1rem solid #99beff;
    border-radius: 0.4rem;
    color: ${theme('--color-primary')};
    cursor: pointer;
    ${theme('--font-small')}
    grid-row: 1;
    height: 2.2rem;
    line-height: 2.2rem;
    margin-left: 0rem;
    outline: 0;
    text-align: center;
    transition: all 0.4s linear;
    width: 8rem;

    &.visible {
      opacity: 1;
      visibility: visible;
    }
    &.hidden {
      opacity: 0;
      visibility: hidden;
    }
    &.move {
      animation: buttonHideMarginAnimation 0.4s linear;
      margin-left: 100rem;
      opacity: 1;
    }
  }

  ${Arrows} {
    cursor: pointer;
    height: 2.2rem;
    overflow: hidden;
    position: absolute;
    text-align: center;
    transition: all 0.4s linear;
    width: 4rem;
  }

  ${ArrowsIcon} {
    margin-top: -0.5rem;
  }

  @keyframes buttonHideMarginAnimation {
    0% {
      margin-left: 0rem;
      opacity: 1;
    }
    99% {
      margin-left: 0rem;
      opacity: 0;
    }
    100% {
      margin-left: 100rem;
      opacity: 1;
    }
  }
`;
