import React from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from 'prop-types';

import DataCard from 'Components/DataCard';

import Table from './components/Table';
import Chart from './components/Chart';

const RevenuePerCampaign = ({
  className,
  currency,
  data: { revenues } = {},
  ...props
}) => (
  <DataCard
    {...props}
    chartTemplate={Chart}
    className={className}
    currency={currency}
    revenues={revenues}
    tableTemplate={Table}
    title="REVENUES_PER_CAMPAIGN"
  />
);

RevenuePerCampaign.propTypes = {
  className: PropTypes.string,
  currency: PropTypes.string,
  data: PropTypes.shape({
    revenues: PropTypes.object,
  }),
};

export default styled(RevenuePerCampaign)`
  height: 100%;
  padding: 6.4rem 0 0;

  @media (${theme('--screen-small')}) {
    min-width: 40rem;
  }
`;
