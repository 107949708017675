import React from 'react';
import PropTypes from '@prop-types';
import { FormattedMessage } from '@react-intl';
import styled from '@styled-components';

import { Fieldset, Input } from 'Components/Form';

import { Heading, Card } from './Common';

const Tags = ({ className }) => (
  <div className={className}>
    <FormattedMessage capitalize component={Heading} id="COUNTER_QUESTION">
      Counter question
    </FormattedMessage>
    <Fieldset name="answer">
      <Input label="COUNTER_QUESTION" name="counterQuestion" type="text" />
    </Fieldset>
  </div>
);

Tags.propTypes = {
  className: PropTypes.string,
};

export default styled(Tags)`
  display: flex;
  flex-direction: column;
  position: relative;

  ${Heading} {
    margin-bottom: 0.8rem;
  }

  ${Card} {
    padding: 1.6rem;
  }
`;
