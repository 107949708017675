import React from 'react';
import PropTypes from '@prop-types';
import styled, { theme, withStyles as css } from '@styled-components';
import { FormattedMessage } from '@react-intl';
import { compose } from 'underscore';

import Icon from 'Components/Icon';
import Link from 'Components/Link';
import { withRouter } from 'Components/Router';

const Title = styled('h2')``;
const Content = styled('p')``;
const Actions = styled('nav')``;
const Wrapper = styled('div')``;

const Button = styled(Link)``;

const EmptyTemplate = ({
  className,
  // match: { url }
}) => (
  <article className={className}>
    <Wrapper>
      <Icon name="campaigns-empty">CAMPAIGNS_EMPTY</Icon>
      <FormattedMessage
        capitalize
        component={Title}
        id="EMPTY_CAMPAINGS.CREATE_CAMPAINGS"
      >
        Oops! we did not find any campaign yet
      </FormattedMessage>
      {/* <FormattedMessage
        capitalize
        component={Content}
        id="INSTRUCTIONS.EMPTY_CAMPAINGS.CREATE_CAMPAINGS"
      >
        You can create some campaigns and they would be listed here
      </FormattedMessage>
      <Actions>
        <FormattedMessage
          capitalize
          component={Button}
          id="ACTIONS.CREATE"
          to={`${url}/create`}
        >
          create
        </FormattedMessage>
      </Actions> */}
    </Wrapper>
  </article>
);

EmptyTemplate.propTypes = {
  className: PropTypes.string,
  // match: PropTypes.object,
};

export default compose(
  css`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 45rem;
    justify-content: center;
    overflow-y: scroll;

    ${Wrapper} {
      display: grid;
      grid-gap: 0.4rem;
      /* grid-template-rows: minmax(0, calc(100% - 10.6rem)) 2.4rem 3.2rem 5rem; // Recover this line to be able to create campaigns */
      grid-template-rows: minmax(0, calc(100% - 2.4rem)) 2.4rem;
      height: 100%;
      max-height: 30rem;
      text-align: center;

      ${Icon} {
        height: 100%;
        margin: 0 auto;
      }

      ${Title} {
        ${theme('--font-large')}
        ${theme('--font-weight-book')}
        ${theme('--font-opacity-100')}
      }

      ${Content} {
        ${theme('--font-medium')}
        ${theme('--font-weight-book')}
        ${theme('--font-opacity-40')}
      }

      ${Actions} {
        display: flex;
        justify-content: center;

        ${Button} {
          ${theme('--font-medium')}
          ${theme('--font-weight-demi')}
          align-items: center;
          background-color: ${theme('--color-primary')};
          border-radius: 0.6rem;
          color: ${theme('--color-light')};
          display: flex;
          height: 4rem;
          justify-content: center;
          margin: 0 0.8rem;
          width: 12.4rem;
        }
      }
    }

    @media screen and (${theme('--screen-small')}) {
      height: 62rem;
    }
  `,
  withRouter,
)(EmptyTemplate);
