import React from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from '@prop-types';

import Icon from 'Components/Icon';

const AddButton = ({ className, onClick }) => {
  return (
    <button className={className} onClick={onClick} type="button">
      <Icon outline name="plus" />
    </button>
  );
};

AddButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default styled(AddButton)`
  align-items: center;
  background: ${theme('--color-primary-10')};
  border: 2px dashed ${theme('--color-primary')};
  border-radius: 0.6rem;
  cursor: pointer;
  display: flex;
  justify-content: center;

  ${Icon} > * {
    stroke: ${theme('--color-primary')};
  }
`;
