import React, { memo, forwardRef } from 'react';
import PropTypes from '@prop-types';
import styled, { withStyles as css } from '@styled-components';
import { identity, compose } from 'underscore';
import { v4 as uuid } from 'uuid';

const Item = styled('li')``;

const getUniqueKey = item => {
  if (typeof item !== 'object') return uuid();
  if (item.key) return item.key;
  if (item.id) return item.id;
  return uuid();
};

function wrap(children, key) {
  return <Item key={key}>{children}</Item>;
}

const List = (
  {
    className,
    emptyTemplate: Empty = () => false,
    data = [],
    items = data,
    noWrapper = false,
    options = {},
    template: Template,
    withScroll,
    ...props
  },
  ref,
) => {
  return (
    <ul ref={ref} className={className} {...props}>
      {items.map((item, index) => {
        const wrapper = noWrapper ? identity : wrap;
        const key = getUniqueKey(item);

        return wrapper(
          <Template
            key={key}
            {...options}
            index={index}
            {...(typeof item === 'object' ? item : { item })}
          />,
          key,
        );
      })}
      {items.length === 0 && <Empty {...options} />}
    </ul>
  );
};

List.propTypes = {
  className: PropTypes.string,
  data: PropTypes.deprecate(
    PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.any.isRequired,
        }),
      ),
      PropTypes.any,
    ]),
    '<List />: `data` property is deprecated, instead use `items`',
  ),
  emptyTemplate: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  items: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.any.isRequired,
      }),
    ),
    PropTypes.any,
  ]),
  noWrapper: PropTypes.bool,
  options: PropTypes.objectOf(PropTypes.any),
  template: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  withScroll: PropTypes.bool,
};

export { Item, List };

export default compose(
  css`
    list-style: none;
    margin: 0;
    padding: 0;
  `,
  memo,
  forwardRef,
)(List);
