import React from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from '@prop-types';

import { FormattedMessage } from '@react-intl';

const Title = styled('p')``;
const Heading = styled('p')``;

const DataText = ({ className, title, values, heading }) => {
  return (
    <div className={className}>
      <FormattedMessage capitalize component={Title} id={title} values={values}>
        {title}
      </FormattedMessage>
      {heading ? (
        <FormattedMessage
          capitalize
          component={Heading}
          id={heading}
          values={values}
        >
          {heading}
        </FormattedMessage>
      ) : null}
    </div>
  );
};

DataText.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string,
  title: PropTypes.string,
  values: PropTypes.object,
};

export default styled(DataText)`
  ${Title} {
    ${theme('--font-small')}
    color: ${theme('--color-dark-night')};
    font-weight: 700;
    margin-bottom: 0.8rem;
  }

  ${Heading} {
    ${theme('--font-medium')}
    color: ${theme('--color-dark-night-60')};
    margin-bottom: 1.6rem;
  }
`;
