import React from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';

import Fetcher from 'Containers/Fetcher';

import { AutoSuggest } from 'Components/Form';

import service from 'Services/faqs';

const TagAutoSuggest = ({
  account,
  className,
  locale,
  onChange,
  placeholder = 'FILTER_BY_TAGS',
  ...props
}) => (
  <Fetcher params={{ account, locale }} query={service.tags}>
    {({ data }) => (
      <AutoSuggest
        allowCreate
        className={className}
        name="tags"
        onChange={onChange}
        options={data}
        placeholder={placeholder}
        {...props}
      />
    )}
  </Fetcher>
);

TagAutoSuggest.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
  locale: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

export default styled(TagAutoSuggest)``;
