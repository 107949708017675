import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@react-intl';
import styled, { theme } from '@styled-components';

import { Row } from 'Components/ResponsiveTable';
import Currency from 'Components/Currency';
import FormattedDateUnit from 'Components/FormattedDateUnit';

import Placeholder from 'Containers/Placeholder';

const Dates = styled(Placeholder)``;
const Sales = styled(Placeholder)``;
const Visits = styled(Placeholder)``;

const VisitsAndRevenues = ({
  className,
  currency,
  empty,
  date,
  sales,
  visits,
  unit,
}) => (
  <Row className={className} empty={empty}>
    <Dates placeholder="there is no date">
      {date && (
        <FormattedDateUnit date={new Date(date.slice(0, -5))} unit={unit} />
      )}
    </Dates>
    <Visits placeholder="empty visits">
      <FormattedMessage
        component={Visits}
        id="%VIEWS%_VIEWS"
        values={{ views: visits }}
      />
    </Visits>
    <Sales placeholder="empty sales">
      <Currency currency={currency}>{sales}</Currency>
    </Sales>
  </Row>
);

VisitsAndRevenues.propTypes = {
  className: PropTypes.string,
  currency: PropTypes.string,
  date: PropTypes.string,
  empty: PropTypes.bool,
  sales: PropTypes.string,
  unit: PropTypes.string,
  visits: PropTypes.string,
};

export default styled(VisitsAndRevenues)`
  grid-template-columns: 3fr 2fr 2fr;
  height: 5.6rem;
  padding: 0 2.4rem;


  &:hover {
    background: ${theme('--color-primary-5')};
  }

  ${Dates}, ${Visits}, ${Sales} {
    ${theme('--font-medium')}
  }

  ${Dates} {
    ${theme('--font-weight-medium')}
  }

  ${Visits}, ${Sales} {
    ${theme('--font-opacity-40')}
    ${theme('--font-weight-book')}
  }
`;
