import React from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';

import DataCard, { CHART } from 'Components/DataCard';

import Loader from './Loader';
import Chart from './components/Chart';
import Table from './components/Table';

const Subtitle = styled('h5')``;

const Funnel = ({
  campaign,
  className,
  // onChange: handleChange = () => {},
  step,
  steps,
}) => (
  <DataCard
    campaign={campaign}
    chartTemplate={Chart}
    className={className}
    initial={CHART}
    loader={Loader}
    // onChange={handleChange}
    step={step}
    steps={steps}
    tableTemplate={Table}
    title="FUNNEL"
  />
);

Funnel.propTypes = {
  campaign: PropTypes.object,
  className: PropTypes.string,
  // onChange: PropTypes.func,
  step: PropTypes.object,
  steps: PropTypes.arrayOf(PropTypes.object),
};

export default styled(Funnel)`
  height: 51.6rem;
  padding: 6.4rem 0 0;

  ${Subtitle} {
    ${theme('--font-medium')}
    ${theme('--font-opacity-60')}
    ${theme('--font-weight-book')}
  }
`;
