export const login = {
  emailAddress: {
    email: { message: 'EMAIL_ADDRESS_FORMAT' },
    presence: { allowEmpty: false, message: 'EMAIL_ADDRESS_REQUIRED' },
  },
  password: {
    presence: { allowEmpty: false, message: 'PASSWORD_REQUIRED' },
  },
};

export const recoverPassword = {
  emailAddress: {
    email: { message: 'EMAIL_ADDRESS_FORMAT' },
    presence: { message: 'EMAIL_ADDRESS_REQUIRED' },
  },
};

export const resetPassword = {
  confirmedPassword: {
    equality: { attribute: 'password', message: 'PASSWORD_EQUALITY' },
    presence: { message: 'PASSWORD_CONFIRMATION_REQUIRED' },
  },
  password: {
    presence: { message: 'PASSWORD_REQUIRED' },
  },
};

export const signUp = {
  emailAddress: {
    email: { message: 'EMAIL_ADDRESS_FORMAT' },
    presence: { message: 'EMAIL_ADDRESS_REQUIRED' },
  },
  password: {
    presence: { message: 'PASSWORD_REQUIRED' },
  },
};

export const editCompanyData = {
  companyName: {
    presence: { message: 'NAME_REQUIRED' },
  },
};

export const editProfile = {
  confirmedPassword: {
    equality: { attribute: 'password', message: 'PASSWORD_EQUALITY' },
  },
  firstName: {
    required: { message: 'NAME_REQUIRED' },
  },
  lastName: {
    required: { message: 'LASTNAME_REQUIRED' },
  },
};

export const inviteMember = {
  email: {
    email: { message: 'EMAIL_ADDRESS_FORMAT' },
    required: { message: 'EMAIL_ADDRESS_REQUIRED' },
  },
  firstName: {
    required: { message: 'NAME_REQUIRED' },
  },
  lastName: {
    required: { message: 'LASTNAME_REQUIRED' },
  },
};

export const addCampaign = {
  groups: {
    required: { message: 'TAGS_REQUIRED' },
  },
  message: {
    format: {
      message: 'MESSAGE_FORMAT',
      pattern: /(.|\s)*<%=messengerUrl%>(.|\s)*/,
    },
    required: { message: 'MESSAGE_REQUIRED' },
  },
  name: {
    required: {
      message: 'NAME_REQUIRED',
    },
  },
};

export const testCampaign = {
  phone: {
    format: /(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{10}$)/,
    presence: {
      allowEmpty: false,
      message: 'PHONE_REQUIRED',
    },
  },
};
