import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { useIntl } from '@react-intl';
import { CSSTransition } from 'react-transition-group';
import { OPTIONS } from 'Modules/dates';

import styled, {
  withStyles as css,
  theme,
  withTheme,
} from '@styled-components';

import {
  VictoryChart,
  VictoryLine,
  VictoryScatter,
  VictoryAxis,
  VictoryGroup,
  VictoryTooltip,
} from 'victory';
import VictoryTheme from './VictoryTheme';
import VictoryTooltipLabel from './VictoryTooltipLabel';

const Axes = styled('div')``;
const Axis = styled('span')``;
const LegendItem = styled('div')``;
const LegendTitle = styled('div')``;

const LegendWrapper = styled('div')`
  visibility: ${props => (props.hidden ? 'hidden' : 'visible')};
`;

const Circle = styled('div')`
  background-color: ${props => props.color};
`;

const Chart = ({
  className,
  theme: palette,
  eventsData,
  eventsEnabled,
  unit,
}) => {
  const intl = useIntl();
  const [size, setSize] = useState(null);
  const wrapperRef = useRef(null);
  const legendRef = useRef(null);

  const eventsInfo = {
    ADD_TO_CART: {
      color: palette['--color-chart-blue'],
      title: intl.formatMessage({ capitalize: true, id: 'ADD_TO_CART' }),
    },
    CONTACT_LIVE_AGENT: {
      color: palette['--color-chart-purple'],
      title: intl.formatMessage({ capitalize: true, id: 'CONTACT_LIVE_AGENT' }),
    },
    ENTER_CHATBOT: {
      color: palette['--color-chart-yellow'],
      title: intl.formatMessage({ capitalize: true, id: 'ENTER_CHATBOT' }),
    },
    HIT_FAQ: {
      color: palette['--color-chart-orange'],
      title: intl.formatMessage({ capitalize: true, id: 'HIT_FAQ' }),
    },
    PURCHASE_SUCCESSFUL: {
      color: palette['--color-chart-dark-blue'],
      title: intl.formatMessage({
        capitalize: true,
        id: 'PURCHASE_SUCCESSFUL',
      }),
    },
    VIEW_PRODUCT_DETAIL: {
      color: palette['--color-chart-dark-blue-60'],
      title: intl.formatMessage({
        capitalize: true,
        id: 'VIEW_PRODUCT_DETAIL',
      }),
    },
  };

  const options = {
    ...OPTIONS[unit],
    ...(unit === 'hour' && { frecuency: 3 }),
  };
  const parser = value =>
    unit === 'hour'
      ? `${intl.formatTime(value, {
          hour: options.hour,
          hour12: true,
        })}-${intl.formatDate(value, { day: options.day })}`
      : intl.formatDate(value, options);

  useEffect(() => {
    const height =
      wrapperRef.current.clientHeight - legendRef.current.clientHeight - 65;
    const width = wrapperRef.current.clientWidth;

    setSize({ height, width });
  }, []);

  return (
    <figure ref={wrapperRef} className={className}>
      <Axes>
        <Axis>%</Axis>
      </Axes>
      {size && (
        <div style={{ height: '100%', maxHeight: `${size.height}px` }}>
          <VictoryChart
            domainPadding={{ x: [10, 10] }}
            height={size.height}
            padding={{ bottom: 30, left: 45, right: 0, top: 10 }}
            theme={VictoryTheme}
            width={size.width}
          >
            <VictoryAxis dependentAxis tickValues={[0, 25, 50, 75, 100]} />
            <VictoryAxis
              fixLabelOverlap
              style={{
                grid: {
                  stroke: 'none',
                },
                ticks: {
                  size: 5,
                },
              }}
              tickFormat={parser}
            />
            {Object.keys(eventsData).map(event => {
              const isEnabled = eventsEnabled.some(el => el === event);

              return (
                <VictoryGroup
                  key={event}
                  animate={{ duration: 400 }}
                  color={eventsInfo[event].color}
                  data={eventsData[event].map(({ date, percents }) => ({
                    x: +new Date(date),
                    y: percents,
                  }))}
                >
                  <VictoryLine
                    style={{
                      data: {
                        opacity: isEnabled ? 0.2 : 0,
                        strokeWidth: 3,
                      },
                    }}
                  />
                  <VictoryScatter
                    labelComponent={
                      <VictoryTooltip
                        flyoutStyle={{
                          fill: 'none',
                          stroke: 'none',
                          strokeWidth: 0,
                        }}
                        labelComponent={
                          <VictoryTooltipLabel
                            circleColor={eventsInfo[event].color}
                          />
                        }
                      />
                    }
                    labels={({ datum }) => datum.y}
                    size={3}
                    style={{
                      data: {
                        fill: '#fff',
                        opacity: isEnabled ? 1 : 0,
                        stroke: eventsInfo[event].color,
                        strokeWidth: 2,
                      },
                    }}
                  />
                </VictoryGroup>
              );
            })}
          </VictoryChart>
        </div>
      )}
      <LegendWrapper ref={legendRef} hidden={!size}>
        {Object.keys(eventsInfo).map(event => (
          <CSSTransition
            key={event}
            classNames="legend"
            in={eventsEnabled.some(el => el === event)}
            timeout={400}
          >
            <LegendItem>
              <Circle color={eventsInfo[event].color} />
              <LegendTitle>{eventsInfo[event].title}</LegendTitle>
            </LegendItem>
          </CSSTransition>
        ))}
      </LegendWrapper>
    </figure>
  );
};

Chart.propTypes = {
  className: PropTypes.string,
  eventsData: PropTypes.object,
  eventsEnabled: PropTypes.array,
  theme: PropTypes.object,
  unit: PropTypes.string,
};

export default compose(
  css`
    ${Axes} {
      color: ${theme('--color-dark-night-60')};
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.2rem;
    }

    ${LegendWrapper} {
      margin: 0 4.7rem 1.6rem;
      text-align: center;
    }

    ${LegendItem} {
      display: inline-block;
      margin-right: 1.6rem;
      transition: opacity 400ms linear;

      &.legend-enter {
        opacity: 0;
      }
      &.legend-enter-active {
        opacity: 1;
      }
      &.legend-exit {
        opacity: 1;
      }
      &.legend-exit-active {
        opacity: 0;
      }
      &.legend-exit-done {
        display: none;
      }
    }

    ${LegendTitle} {
      color: ${theme('--color-dark-night-40')};
      display: inline-block;
      ${theme('--font-small')}
      margin-left: 0.9rem;
    }

    ${Circle} {
      border-radius: 0.4rem;
      display: inline-block;
      height: 0.8rem;
      width: 0.8rem;
    }
  `,
  withTheme,
)(Chart);
