import React from 'react';
import PropTypes from 'prop-types';
import styled, { theme, css } from '@styled-components';

const Image = styled('img')``;
const Radio = styled('input').attrs({ type: 'radio' })``;

export const Item = ({ className, image, name, onChange }) => (
  <div className={className}>
    <Radio aria-label={name} content={name} onChange={onChange} />
    <Image
      alt={name}
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
      style={{ backgroundImage: `url(${image})` }}
    />
  </div>
);

Item.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
};

const activeMixin = ({ active }) =>
  active &&
  css`
    box-shadow: inset 0 0 0 0.2rem ${theme('--color-primary')},
      inset 0 0 0 0.4rem ${theme('--color-light')};
  `;

export default styled(Item)`
  align-items: center;
  border-radius: 0.6rem;
  display: inline-flex;
  height: 7.2rem;
  justify-content: center;
  position: relative;
  width: 7.2rem;

  ${Radio} {
    border: none;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;
  }

  ${Image} {
    background-position: center;
    background-size: cover;
    border-radius: 0.4rem;
    box-shadow: inset 0 0 0 0.1rem ${theme('--color-dark-night-20')};
    height: 100%;
    width: 100%;

    ${activeMixin}
  }
`;
