import React from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@react-intl';

import Confirm from 'Components/Confirm';

const Paragraph = styled('p')``;

const DeleteFaq = ({ className, onAccept, onCancel, in: isIn }) => (
  <Confirm
    acceptId="ACTIONS.DELETE"
    className={className}
    in={isIn}
    onAccept={onAccept}
    onCancel={onCancel}
    title="ACTIONS.DELETE_FAQ"
  >
    <FormattedMessage
      capitalize
      component={Paragraph}
      id="QUESTIONS.DELETE_FAQ"
    />
  </Confirm>
);

DeleteFaq.propTypes = {
  campaign: PropTypes.shape({
    name: PropTypes.string,
  }),
  className: PropTypes.string,
  in: PropTypes.bool,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
};

export default styled(DeleteFaq)`
  ${Paragraph} {
    ${theme('--font-medium')}
    ${theme('--font-weight-book')}
    color: ${theme('--color-dark-night-60')};
  }
`;
