import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@react-intl';
import styled, { theme } from '@styled-components';

import C from 'Constants';

import Button from 'Components/Button';
import Form, { Input, Radio } from 'Components/Form';

import { inviteMember as inviteMemberRules } from 'Rules';

const {
  ROLE: { ADMIN, READER, WRITER },
} = C;

const Actions = styled('div')``;
const Label = styled('label')``;

const Paragraph = styled('p')``;
const Title = styled('h3')``;

const Box = styled('div')``;
const Role = styled('p')``;
const Roles = styled('div')``;

const AddUser = ({
  className,
  email,
  firstName,
  lastName,
  onSubmit = () => {},
}) => {
  return (
    <div className={className}>
      <Form
        constraint={inviteMemberRules}
        defaultValue={{
          email,
          firstName,
          lastName,
          role: READER,
        }}
        onSubmit={onSubmit}
      >
        <FormattedMessage capitalize component={Title} id="PERSONAL_DATA">
          Personal data
        </FormattedMessage>
        <FormattedMessage
          capitalize
          component={Paragraph}
          id="INSTRUCTIONS.SEND_INVITATION"
        >
          Complete the personal data of the new team member. The invitation will
          be sent by email.
        </FormattedMessage>
        <Input
          capitalize
          label="PLACEHOLDER.FIRST_NAME"
          name="firstName"
          type="text"
        />
        <Input
          capitalize
          label="PLACEHOLDER.LAST_NAME"
          name="lastName"
          type="text"
        />
        <Input capitalize label="PLACEHOLDER.EMAIL" name="email" type="text" />
        <FormattedMessage capitalize component={Role} id="SELECT_ROLE">
          Select team member role
        </FormattedMessage>
        <Roles>
          <Box>
            <Radio content={ADMIN} id="admin" name="role" />
            <div>
              <FormattedMessage
                capitalize
                component={Label}
                htmlFor="admin"
                id="ROLE.ADMIN"
              >
                Admin
              </FormattedMessage>
              <FormattedMessage
                capitalize
                component={Paragraph}
                id="INSTRUCTIONS.ROLE.ADMIN"
              />
            </div>
          </Box>
          <Box>
            <Radio content={WRITER} id="writer" name="role" />
            <div>
              <FormattedMessage
                capitalize
                component={Label}
                htmlFor="writer"
                id="ROLE.WRITER"
              >
                Writer
              </FormattedMessage>
              <FormattedMessage
                capitalize
                component={Paragraph}
                id="INSTRUCTIONS.ROLE.WRITER"
              />
            </div>
          </Box>
          <Box>
            <Radio content={READER} id="reader" name="role" />
            <div>
              <FormattedMessage
                capitalize
                component={Label}
                htmlFor="reader"
                id="ROLE.READER"
              >
                Reader
              </FormattedMessage>
              <FormattedMessage
                capitalize
                component={Paragraph}
                id="INSTRUCTIONS.ROLE.READER"
              />
            </div>
          </Box>
        </Roles>
        <Actions>
          <Button capitalize primary translate type="submit">
            ACTIONS.INVITE
          </Button>
        </Actions>
      </Form>
    </div>
  );
};

AddUser.propTypes = {
  className: PropTypes.string,
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default styled(AddUser)`
  display: flex;
  flex-wrap: wrap;

  ${Form} {
    margin: 1.2rem auto 0;

    ${Title} {
      ${theme('--font-medium')}
      ${theme('--font-weight-medium')}
      margin-bottom: 1.2rem;
    }

    ${Paragraph} {
      ${theme('--font-medium')}
      ${theme('--font-opacity-40')}
      ${theme('--font-weight-book')}
      margin-bottom: 1.6rem;
    }

    ${Input} {
      margin-bottom: 1.6rem;
    }

    ${Box} {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      margin-bottom: 0;
    
      ${Radio} {
        margin-right: 0.8rem;
      }

      ${Label}, span {
        ${theme('--font-small')}
        ${theme('--font-opacity-60')}
        ${theme('--font-weight-book')}
        display: block;
        margin-bottom: 0.4rem;
      }
      ${Paragraph} {
        ${theme('--font-small')}
        ${theme('--font-opacity-40')}
        ${theme('--font-weight-book')}
        margin-bottom: 0.8rem;
      }
    }

    ${Role} {
      ${theme('--font-small')}
      ${theme('--font-opacity-40')}
      ${theme('--font-weight-book')}
      margin-bottom: 0.8rem;
    }

    ${Actions} {
      display: flex;
      justify-content: space-between;
      margin-top: 4rem;

      ${Button} {
        width: 100%;
      }
    }
  }

  @media (${theme('--level-2')}) {
    ${Form} {
      margin: 0.8rem auto 0;
      max-width: 32rem;

      ${Title} {
        margin-bottom: 0.8rem;
      }
    }
  }

  @media (${theme('--column-12')}) {
    ${Form} ${Actions} ${Button} {
      max-width: 15rem;
    }
  }
`;
