import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';
import { FormattedMessage } from '@react-intl';

import { Dropdown, Options } from 'Components/Dropdown';
import Link from 'Components/Link';
import Button from 'Components/Button';

import { HeaderTemplate, DropdownTemplate } from './components/Template';

const Actions = styled('nav')`
  ${Link} {
    border-top: 0.1rem solid ${theme('--color-dark-night-10')};
    display: block;
    padding: 1.2rem 2.4rem;
    text-align: left;
  }
`;

const Input = styled('input')`
   {
    display: block;
    padding: 1.2rem 2.4rem;
    position: absolute;
    text-align: left;
    top: 0;
  }
`;

class AccountSwitcher extends Component {
  static propTypes = {
    account: PropTypes.number,
    className: PropTypes.string,
    get: PropTypes.func,
    officialAccounts: PropTypes.array,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    onFocus: PropTypes.func,
    onOpen: PropTypes.func,
    set: PropTypes.func,
  };

  state = {
    data: [],
    keyword: '',
  };

  componentDidMount() {
    const { get, account, officialAccounts } = this.props;
    this.setState({
      data: officialAccounts.map(({ id, name, logoType }) => ({
        data: { image: logoType, label: name },
        key: id,
        value: id,
      })),
    });
    get(account);
  }

  setInitialAccountList() {
    const { officialAccounts } = this.props;
    this.setState({
      data: officialAccounts.map(({ id, name, logoType }) => ({
        data: { image: logoType, label: name },
        key: id,
        value: id,
      })),
      keyword: '',
    });
  }

  handleChange = value => {
    const { set, onChange } = this.props;
    set(Number(value));
    onChange();
    this.setInitialAccountList();
  };

  render() {
    const {
      className,
      account,
      officialAccounts,
      onBlur = () => {},
      onClose: handleClose,
      onOpen: handleOpen,
      onFocus = () => {},
    } = this.props;

    if (!officialAccounts.length) return false;

    const handleSearch = e => {
      this.setState({ keyword: e.target.value });
      if (e.target.value === '') {
        this.setInitialAccountList();
      } else {
        this.setState({
          data: officialAccounts
            .map(({ id, name, logoType }) => ({
              data: { image: logoType, label: name },
              key: id,
              value: id,
            }))
            .filter(user =>
              user.data.label
                .toLowerCase()
                .includes(e.target.value.toLowerCase()),
            ),
        });
      }
    };

    const { data, keyword } = this.state;

    const allData = officialAccounts.map(({ id, name, logoType }) => ({
      data: { image: logoType, label: name },
      key: id,
      value: id,
    }));

    return (
      <Dropdown
        withScroll
        allData={allData}
        className={className}
        data={data}
        dropdownTemplate={DropdownTemplate}
        headerTemplate={HeaderTemplate}
        onBlur={onBlur}
        onChange={this.handleChange}
        onClose={handleClose}
        onFocus={onFocus}
        onOpen={handleOpen}
        selected={account}
      >
        <Input
          autoFocus
          autoComplete="off"
          name="search"
          onChange={handleSearch}
          placeholder="Search"
          type="search"
          value={keyword}
        />
        <Actions>
          <FormattedMessage
            capitalize
            component={Link}
            id="ACTION.LOGOUT"
            to="/logout"
          >
            logout
          </FormattedMessage>
        </Actions>
      </Dropdown>
    );
  }
}

export default styled(AccountSwitcher)`
  ${Options} {
    background: #ffffff;
    border-radius: 0.6rem;
    box-shadow: 0 0.8rem 1.6rem rgba(0, 15, 25, 0.05),
      0 0.1rem 0 rgba(0, 15, 25, 0.05);
    padding-top: 4.6rem;
    top: 4rem;
  }

  ${Button} {
    border-radius: 0;
  }

  ${HeaderTemplate} {
    white-space: nowrap;

    span {
      display: inline-block;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
      vertical-align: text-top;
      white-space: nowrap;
      width: 15.3rem;
    }
  }

  @media (${theme('--screen-small')}) {
    ${HeaderTemplate} {
      ${theme('--font-small')}
      margin-right: 1.6rem;

      span {
        vertical-align: sub;
        width: 9rem;
      }

      > i {
        ${theme('--font-small')}
        margin-left: 0.5rem;
      }
    }
  }
`;
