import React, { memo, useEffect, useState } from 'react';
import PropTypes from '@prop-types';
import { withIntl } from '@react-intl';
import { withStyles as css } from '@styled-components';

import { compose } from 'underscore';

import logger from 'Providers/logger';

async function load(file) {
  return (await import(`../../../assets/images/icons/${file}.svg`)).default;
}

const Icon = ({
  className,
  disabled,
  intl,
  name,
  children = name.toUpperCase(),
  outline,
  ...props
}) => {
  const [SVG, setSVG] = useState(null);

  useEffect(() => {
    let current = true;

    try {
      let promise = load(name);

      if (outline) {
        promise = load(`${name}-outline`).catch(() => promise);
      }

      if (disabled) {
        promise = load(`${name}-disabled`).catch(() => promise);
      }

      (async () => {
        const svg = await promise;

        if (current) {
          setSVG(() => svg);
        }
      })();

      return () => {
        current = false;
      };
    } catch (error) {
      logger.warning(`Missing icon: ${name}`);

      return () => {
        current = false;
      };
    }
  }, [disabled, name, outline]);

  return (
    SVG && (
      <SVG
        aria-label={intl.formatMessage({ id: children })}
        className={className}
        role="img"
        {...props}
      />
    )
  );
};

Icon.propTypes = {
  children: PropTypes.children,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  intl: PropTypes.intl,
  name: PropTypes.string,
  outline: PropTypes.bool,
};

export default compose(css``, memo, withIntl)(Icon);
