import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import useClickOutsideElement from 'Hooks/useClickOutsideElement';

import GroupsList from './GroupsList';
import Header from './Header';

const GroupsPicker = ({
  account,
  className,
  groups,
  onChange,
  groupsSelected = [],
}) => {
  const [isOpen, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const groupsPickerRef = useRef();

  const groupsFiltered = groups.filter(({ name }) =>
    name.toLowerCase().includes(inputValue.toLowerCase()),
  );

  const handleInputChange = value => setInputValue(value);

  const handleListOpen = () => setOpen(true);

  const handleListToggle = () => setOpen(!isOpen);

  const handleGroupAdd = group => {
    if (!groupsSelected.some(({ id }) => id === group.id)) {
      const groupsSelectedNext = [].concat(group);
      onChange(groupsSelectedNext);
    }
    setInputValue('');
  };

  const handleGroupCustomersChange = ({ groupId, customers }) => {
    const groupsSelectedNext = groupsSelected.map(group =>
      group.id === groupId ? { ...group, customers } : group,
    );

    onChange(groupsSelectedNext);
  };

  useClickOutsideElement({
    onClickOutside: () => setOpen(false),
    ref: groupsPickerRef,
  });

  return (
    <div ref={groupsPickerRef} className={className}>
      <Header
        groupsSelected={groupsSelected}
        inputValue={inputValue}
        isListOpened={isOpen}
        onInputChange={handleInputChange}
        onListOpen={handleListOpen}
        onListToggle={handleListToggle}
      />
      {isOpen && (
        <GroupsList
          account={account}
          groups={groupsFiltered}
          groupsSelected={groupsSelected}
          onGroupAdd={handleGroupAdd}
          onGroupCustomersChange={handleGroupCustomersChange}
        />
      )}
    </div>
  );
};

GroupsPicker.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
  groups: PropTypes.array.isRequired,
  groupsSelected: PropTypes.arrayOf(
    PropTypes.shape({
      customers: PropTypes.arrayOf(PropTypes.number),
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  onChange: PropTypes.func.isRequired,
};

export default styled(GroupsPicker)`
  width: 50rem;
`;
