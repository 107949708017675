import React, { useCallback, useState } from 'react';
import PropTypes from '@prop-types';
import { connect } from 'react-redux';

import * as logger from 'Actions/logger';

import Modal from 'Components/Modal';

import DataProvider from 'Containers/DataProvider';
import { useMultiDataProviderContext } from 'Containers/MultiDataProvider';

import { currentAccount, role as getRole } from 'Selectors';

import service from 'Services/faqs';

import View from './Faq';
import Toolbar from './components/Toolbar';
import DeleteFaq from './components/DeleteFaq';

const mapStateToProps = state => ({
  account: currentAccount(state),
  role: getRole(state),
});

const mapDispatchToProps = { error: logger.error, success: logger.success };

const Faq = ({
  account,
  order,
  depth,
  id: composed,
  in: modalIn,
  onClose,
  onBack,
  onReload,
  previous,
  role,
  success,
  error,
}) => {
  const [type, id] = composed.split('_');
  const { refetch } = useMultiDataProviderContext('faqs');
  const [deleteVisible, setDeleteVisible] = useState(false);

  const showDelete = useCallback(() => setDeleteVisible(true), []);
  const hideDelete = useCallback(() => setDeleteVisible(false), []);

  const handleDelete = useCallback(async () => {
    await service.del({
      account,
      id,
      type,
    });

    onClose();
    refetch();
  }, [account, id, onClose, refetch, type]);

  const isNotReader = role !== 'READER';

  return (
    <>
      <DeleteFaq
        in={deleteVisible}
        onAccept={handleDelete}
        onCancel={hideDelete}
      />
      <Modal
        depth={depth}
        in={modalIn}
        onBack={onBack}
        onClose={onClose}
        order={order}
        title="FAQ_DETAILS"
        // FIXME: Use with permissions in the toolbar to hide it
        {...(isNotReader
          ? { toolbar: () => <Toolbar handleDelete={showDelete} /> }
          : {})}
      >
        <DataProvider
          account={account}
          id={id}
          onClose={onClose}
          onReload={onReload}
          params={{ account, id, type }}
          previous={previous}
          query={service.get}
          role={role}
          showError={error}
          success={success}
          template={View}
        />
      </Modal>
    </>
  );
};

Faq.propTypes = {
  account: PropTypes.number,
  dataKey: PropTypes.string,
  depth: PropTypes.number,
  error: PropTypes.func,
  id: PropTypes.any.isRequired,
  in: PropTypes.bool,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  onReload: PropTypes.func,
  order: PropTypes.number,
  previous: PropTypes.string,
  role: PropTypes.string,
  success: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Faq);
