import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@react-intl';
import styled, { theme } from '@styled-components';

import { withPlaceholder } from 'Containers/Placeholder';
import { useMultiDataProviderContext } from 'Containers/MultiDataProvider';

import AvatarSelector from 'Components/AvatarSelector';
import Button from 'Components/Button';
import Form, { Input, Select } from 'Components/Form';

import { editProfile as editProfileRules } from 'Rules';

import AccountServices from 'Services/accounts';

const Actions = styled('div')``;
const Paragraph = styled('p')``;
const Title = styled('h3')``;

const Settings = ({
  className,
  data: { collection: members },
  ofaId,
  success,
  showError: error,
  onClose,
}) => {
  const me = members.find(({ you }) => you);

  const {
    id: profileId,
    firstName,
    lastName,
    email,
    images: { large },
    locale,
  } = me;

  const { refetch } = useMultiDataProviderContext('settings');

  const handleSubmit = useCallback(
    async formData => {
      try {
        await AccountServices.putAgent({
          ofaId,
          profileId,
          ...formData,
        });
        success('PROFILE_MODIFIED');
        refetch();
        onClose();
      } catch (e) {
        if (e.message === 'POOR_PASSWORD_STRENGTH') {
          error(e.message);
        } else {
          error('PROFILE_NOT_MODIFIED');
          onClose();
        }
      }
    },
    [error, ofaId, onClose, profileId, refetch, success],
  );

  return (
    <div className={className}>
      <Form
        constraint={editProfileRules}
        defaultValue={{
          confirmedPassword: '',
          email,
          firstName,
          lastName,
          locale,
          password: '',
        }}
        onSubmit={handleSubmit}
      >
        <FormattedMessage capitalize component={Title} id="PERSONAL_DATA">
          Personal data
        </FormattedMessage>
        <FormattedMessage
          capitalize
          component={Paragraph}
          id="INSTRUCTIONS.PERSONAL_DATA"
        >
          It will only be visible by your team members
        </FormattedMessage>
        <AvatarSelector image={large} label="PROFILE_PICTURE" />
        <Input label="PLACEHOLDER.FIRST_NAME" name="firstName" type="text" />
        <Input
          capitalize
          label="PLACEHOLDER.LAST_NAME"
          name="lastName"
          type="text"
        />
        <Input
          capitalize
          label="PLACEHOLDER.EMAIL_ADRESS"
          name="email"
          type="text"
        />
        <Input
          capitalize
          autoComplete="new-password"
          label="PLACEHOLDER.NEW_PASSWORD"
          name="password"
          type="password"
        />
        <Input
          label="PLACEHOLDER.CONFIRM_PASSWORD"
          name="confirmedPassword"
          type="password"
        />
        <Actions>
          <Button capitalize primary translate type="submit">
            ACTIONS.SAVE_CHANGES
          </Button>
        </Actions>
      </Form>
    </div>
  );
};

Settings.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    collection: PropTypes.arrayOf(
      PropTypes.shape({
        email: PropTypes.string,
        firstName: PropTypes.string,
        images: PropTypes.objectOf(PropTypes.string),
        lastName: PropTypes.string,
        locale: PropTypes.string,
      }),
    ),
  }),
  images: PropTypes.shape({ large: PropTypes.string }),
  ofaId: PropTypes.number,
  onClose: PropTypes.func,
  showError: PropTypes.func,
  success: PropTypes.func,
};

export default withPlaceholder(styled(Settings)`
  display: flex;
  flex-wrap: wrap;

  ${Form} {
    margin: 1.2rem auto 0;

    ${Title} {
      ${theme('--font-medium')}
      ${theme('--font-weight-medium')}
      margin-bottom: 0.8rem;
    }

    ${Paragraph} {
      ${theme('--font-medium')}
      ${theme('--font-opacity-40')}
      ${theme('--font-weight-book')}
    }

    ${AvatarSelector} {
      padding: 2.4rem 0 2rem;
      ${Input} {
        margin: 0;
      }
    }

    ${Input} {
      margin-bottom: 1.6rem;
    }

    ${Select}{
      margin-top: 1.6rem;
    }

    ${Actions} {
      display: flex;
      justify-content: space-between;
      margin-top: 4rem;

      ${Button} {
        width: 100%;
      }
    }
  }
  @media (${theme('--level-2')}) {
    ${Form} {
      margin: 0.8rem auto 0;
      max-width: 32rem;

      ${Title} {
        margin-bottom: 0.8rem;
      }
    }
    
  }

  @media (${theme('--column-12')}) {
    ${Form} ${Actions} ${Button} {
      max-width: 15rem;
    }
  }
`);
