export const accounts = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  items: {
    properties: {
      currencyCode: { type: 'string' },
      id: { type: 'number' },
      name: { type: 'string' },
      permission: { type: 'string' },
      type: { type: 'string' },
    },
    type: 'object',
  },
  name: 'accounts',
  type: 'array',
};

export const averageItemsByTime = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  name: 'averageItemsByTime',
  properties: {
    items: {
      patternProperties: {
        '^.*$': { type: 'number' },
      },
    },
  },
  type: 'object',
};

export const averageOrdersByTime = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  name: 'averageOrdersByTime',
  properties: {
    currency: { type: 'string' },
    orders: {
      patternProperties: {
        '^.*$': { type: 'number' },
      },
    },
  },
  type: 'object',
};

export const averagePurchaseValueByTime = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  name: 'averagePurchaseValueByTime',
  properties: {
    average_customer_value: {
      patternProperties: {
        '^.*$': { type: 'number' },
      },
    },
    currency: { type: 'string' },
  },
  type: 'object',
};

export const bestSellers = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  name: 'bestSellers',
  properties: {
    items: {
      items: {
        properties: {
          currency: { type: 'string' },
          description: { type: 'string' },
          image: { type: 'string' },
          name: { type: 'string' },
          saleCount: { type: 'number' },
          unitPrice: { type: 'number' },
        },
        type: 'object',
      },
      type: 'array',
    },
  },
  type: 'object',
};

export const conversionRates = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  name: 'conversionRates',
  properties: {
    conversion_rate: {
      patternProperties: {
        '^.*$': {
          patternProperties: {
            '^.*$': {
              type: 'number',
            },
          },
          type: 'object',
        },
      },
    },
  },
  type: 'object',
};

export const campaignCustomers = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  name: 'campaignCustomers',
  properties: {
    data: {
      items: {
        properties: {
          events: {
            items: {
              properties: {
                click_info: {
                  properties: {
                    date: { type: 'string' },
                  },
                  type: 'object',
                },
                label: { type: 'string' },
                value: { type: 'boolean' },
              },
              type: 'object',
            },
            type: 'array',
          },
          info: {
            properties: {
              existing_user: { type: 'boolean' },
              interaction: { type: 'boolean' },
              new_user: { type: 'boolean' },
              return_user: { type: 'boolean' },
            },
            type: 'object',
          },
          user: {
            date: { type: 'string' },
            properties: {
              avatar: { type: ['string', 'null'] },
              email: { type: ['string', 'null'] },
              firstName: { type: 'string' },
              lastName: { type: 'string' },
              phone: { type: ['string', 'null'] },
              type: { type: 'string' },
            },
            type: 'object',
          },
        },
        type: 'object',
      },
      type: 'array',
    },
    headerLabels: {
      items: {
        type: 'string',
      },
      type: 'array',
    },
  },
  type: 'object',
};

export const campaignRevenueByTime = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  name: 'campaignRevenueByTime',
  properties: {
    currency: { type: 'string' },
    revenues: {
      patternProperties: {
        '^.*$': { type: 'number' },
      },
    },
  },
  type: 'object',
};

export const campaigns = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  name: 'campaigns',
  properties: {
    campaigns: {
      items: {
        properties: {
          id: { type: 'number' },
          name: { type: 'string' },
          platform: { type: 'string' },
          status: { type: 'string' },
          token: { type: 'string' },
          type: { type: 'string' },
        },
        type: 'object',
      },
      type: 'array',
    },
  },
  type: 'object',
};

export const customers = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  name: 'customers',
  properties: {
    customers: {
      items: {
        properties: {
          createdAt: { type: 'string' },
          firstName: { type: 'string' },
          gender: { type: 'string' },
          id: { type: 'number' },
          imageURL_normal: { type: 'string' },
          imageURL_small: { type: 'string' },
          imageURL_thumb: { type: 'string' },
          isPrivate: { type: 'boolean' },
          lastName: { type: 'string' },
          locale: { type: 'string' },
          middleName: { type: 'string' },
          profileCacheKeys: {
            type: 'array',
          },
          timezone: { type: 'number' },
          type: { type: 'string' },
          userId: { type: 'number' },
        },
        type: 'object',
      },
      type: 'array',
    },
  },
  type: 'object',
};

export const customerDetails = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  name: 'customerDetails',
  properties: {
    avatar: { type: 'string' },
    devices: {
      items: {
        type: 'string',
      },
      type: 'array',
    },
    devices_w_duration: {
      items: {
        patternProperties: {
          '^.*$': { type: 'number' },
        },
        type: 'object',
      },
      type: 'array',
    },
    firstName: { type: 'string' },
    geoInfo: {
      items: {
        properties: {
          city: { type: 'string' },
          country: { type: 'string' },
          lat: { type: 'number' },
          lng: { type: 'number' },
          region: { type: 'string' },
          zip: { type: 'number' },
        },
        type: 'object',
      },
      type: 'array',
    },
    id: { type: 'number' },
    join: { type: 'string' },
    lastName: { type: 'string' },
    locale: { type: 'string' },
    timezone: { type: 'number' },
  },
  type: 'object',
};

export const gender = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  name: 'gender',
  properties: {
    data: {
      items: {
        properties: {
          click_info: { type: 'object' },
          count: { type: 'number' },
          value: { type: 'string' },
        },
        type: 'object',
      },
      type: 'array',
    },
    total: { type: 'number' },
  },
  type: 'object',
};

export const funnel = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  name: 'funnel',
  properties: {
    campaignId: { type: 'number' },
    ofaId: { type: 'number' },
    result: {
      properties: {
        campaign_type: { type: 'string' },
        funnel: {
          items: {
            properties: {
              description: { type: 'string' },
              displayName: { type: 'string' },
              name: { type: 'string' },
              other: { type: 'object' },
              percentage: { type: 'number' },
              value: { type: 'number' },
            },
            type: 'object',
          },
          type: 'array',
        },
        type: { type: 'string' },
      },
      type: 'object',
    },
  },
  type: 'object',
};

export const location = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  name: 'location',
  properties: {
    data: {
      items: {
        properties: {
          click_info: { type: 'object' },
          count: { type: 'number' },
          value: { type: 'string' },
        },
        type: 'object',
      },
      type: 'array',
    },
    total: { type: 'number' },
  },
  type: 'object',
};

export const members = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  items: {
    properties: {
      alias: { type: 'string' },
      data: {
        properties: {
          imageURL_mini: { type: 'string' },
          imageURL_normal: { type: 'string' },
          imageURL_thumb: { type: 'string' },
        },
        type: 'object',
      },
      firstName: { type: 'string' },
      isOwner: { type: 'boolean' },
      lastName: { type: 'string' },
      permission: { type: 'string' },
      profileId: { type: 'number' },
      role: { type: 'string' },
      status: { type: 'string' },
      type: { type: 'string' },
      you: { type: 'boolean' },
    },
    type: 'object',
  },
  name: 'members',
  type: 'array',
};

export const memberAgents = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  items: {
    properties: {
      alias: { type: 'string' },
      data: {
        properties: {
          imageURL_mini: { type: 'string' },
          imageURL_normal: { type: 'string' },
          imageURL_thumb: { type: 'string' },
        },
        type: 'object',
      },
      firstName: { type: 'string' },
      id: { type: 'number' },
      isOwner: { type: 'boolean' },
      lastName: { type: 'string' },
      permission: { type: 'string' },
      role: { type: 'string' },
      type: { type: 'string' },
      you: { type: 'boolean' },
    },
    type: 'object',
  },
  name: 'memberAgents',
  type: 'array',
};

export const mostViewed = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  name: 'mostViewed',
  properties: {
    items: {
      items: {
        properties: {
          description: { type: 'string' },
          id: { type: 'number' },
          image: { type: 'string' },
          name: { type: 'string' },
        },
        type: 'object',
      },
      type: 'array',
    },
  },
  type: 'object',
};

export const notifications = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  name: 'notifications',
  properties: {
    notifications: {
      items: {
        properties: {
          author: {
            properties: {
              alias: { type: 'string' },
              firstName: { type: 'string' },
              image: {
                mini: { type: 'string' },
                normal: { type: 'string' },
                thumb: { type: 'string' },
                type: 'object',
              },
              lastName: { type: 'string' },
              timestamp: { type: 'string' },
              url: { type: 'string' },
            },
            type: 'object',
          },
          id: { type: 'number' },
          status: { type: 'string' },
          text: { type: 'string' },
        },
        type: 'object',
      },
      type: 'array',
    },
  },
  type: 'object',
};

export const notificationsUpdated = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  items: {
    properties: {
      message: { type: 'string' },
      updated: {
        items: {
          properties: {
            id: { type: 'number' },
            status: { type: 'string' },
          },
          type: 'object',
        },
        type: 'array',
      },
    },
    type: 'object',
  },
  name: 'notificationsUpdated',
  type: 'array',
};

export const paymentMethods = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  items: {
    properties: {
      message: { type: 'string' },
      updated: {
        items: {
          properties: {
            id: { type: 'number' },
            status: { type: 'string' },
          },
          type: 'object',
        },
        type: 'array',
      },
    },
    type: 'object',
  },
  name: 'paymentMethods',
  type: 'array',
};

export const recentOrders = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  name: 'recentOrders',
  properties: {
    orders: {
      items: {
        properties: {
          currency: { type: 'string' },
          customerAvatar: { type: 'string' },
          customerName: { type: 'string' },
          date: { type: 'string' },
          id: { type: 'number' },
          itemCount: { type: 'number' },
          items: {
            items: {
              properties: {
                description: { type: 'string' },
                id: { type: 'number' },
                image: { type: 'string' },
                name: { type: 'string' },
                quantity: { type: 'number' },
              },
              type: 'object',
            },
            type: 'array',
          },
          total: { type: 'number' },
        },
        type: 'object',
      },
      type: 'array',
    },
  },
  type: 'object',
};

export const revenues = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  name: 'revenues',
  properties: {
    data: {
      items: {
        properties: {
          average_order: { type: 'number' },
          currency: { type: 'string' },
          discount: { type: 'number' },
          itemCount: { type: 'number' },
          revenue: { type: 'number' },
          shipping: { type: 'number' },
          tax: { type: 'number' },
          timestamp: { type: 'number' },
          total_sales: { type: 'number' },
        },
        type: 'object',
      },
      type: 'array',
    },
  },
  type: 'object',
};

export const revenuesComparation = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  name: 'revenuesComparation',
  properties: {
    currency: { type: 'string' },
    newSales: {
      patternProperties: {
        '^.*$': { type: 'number' },
      },
    },
    repeatedSales: {
      patternProperties: {
        '^.*$': { type: 'number' },
      },
    },
  },
  type: 'object',
};

export const revenuesByTime = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  name: 'revenuesByTime',
  properties: {
    currency: { type: 'string' },
    sales: {
      patternProperties: {
        '^.*$': { type: 'number' },
      },
    },
  },
  type: 'object',
};

export const revenuesPerCampaign = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  name: 'revenuesPerCampaign',
  properties: {
    currency: { type: 'string' },
    revenues: {
      patternProperties: {
        '^.*$': { type: 'number' },
      },
    },
  },
  type: 'object',
};

export const revenuesStatsPerPlatform = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  name: 'revenuesStatsPerPlatform',
  properties: {
    currency: { type: 'string' },
    data: {
      items: {
        properties: {
          count: { type: 'number' },
          value: { type: 'string' },
        },
        type: 'object',
      },
      type: 'array',
    },
  },
  type: 'object',
};

export const platform = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  name: 'platform',
  properties: {
    data: {
      items: {
        properties: {
          click_info: { type: 'object' },
          count: { type: 'number' },
          displayName: { type: 'string' },
          value: { type: 'string' },
        },
        type: 'object',
      },
      type: 'array',
    },
    total: { type: 'number' },
  },
  type: 'object',
};

// TODO: Adapt when endpoint is done
export const products = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  name: 'products',
  properties: {
    products: {
      items: {
        properties: {
          categories: {
            items: {
              type: 'string',
            },
            type: 'array',
          },
          currency: { type: 'string' },
          id: { type: 'number' },
          merchantId: { type: 'string' },
          name: { type: 'string' },
          shortDescription: { type: 'string' },
          thumbnail: { type: 'string' },
          unitPrice: { type: 'number' },
        },
        type: 'object',
      },
      type: 'array',
    },
  },
  type: 'object',
};

export const usersByCountry = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  name: 'usersByCountry',
  properties: {
    devices: {
      items: {
        properties: {
          count: { type: 'number' },
          value: { type: 'string' },
        },
        type: 'object',
      },
      type: 'array',
    },
  },
  type: 'object',
};

export const usersByOS = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  name: 'usersByOS',
  properties: {
    devices: {
      items: {
        properties: {
          count: { type: 'number' },
          value: { type: 'string' },
        },
        type: 'object',
      },
      type: 'array',
    },
  },
  type: 'object',
};

export const visitsByTime = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  name: 'visitsByTime',
  properties: {
    visits: {
      patternProperties: {
        '^.*$': { type: 'number' },
      },
    },
  },
  type: 'object',
};
