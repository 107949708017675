import { createService } from '@redux-act';

import rest from 'Providers/rest';

import request from 'Providers/request';

import {
  members as membersSchema,
  memberAgents as memberAgentsSchema,
} from 'Schemas';

const get = createService(async ({ account }, token) => {
  const { collection: members } = await request.read(
    'ofa_agents_and_invites',
    {},
    membersSchema,
    { headers: { ofaId: account }, token },
  );

  return {
    collection: members.map(member => ({
      email: member.alias,
      firstName: member.firstName,
      fullName: `${member.firstName} ${member.lastName}`,
      gender: member.data && member.data.gender,
      id: member.profileId || member.inviteId,
      images: {
        large: member.data && member.data.imageURL_normal,
        medium: member.data && member.data.imageURL_mini,
        small: member.data && member.data.imageURL_thumb,
      },
      isMember: member.profileId !== undefined,
      isOwner: member.isOwner,
      key: member.alias,
      language: member.data && member.data.language,
      lastName: member.lastName,
      role: member.role,
      status: member.isMember ? 'ACTIVE' : member.status,
      you: member.you,
    })),
  };
});

const getById = createService(async ({ account, id: profileId }) => {
  const { collection: members = [] } = await get({ account });
  const member = members.find(({ id }) => id === Number(profileId));
  return member;
});

// FIXME it seems that this is not used anymore
async function getInvites(account) {
  const members = await rest(
    'ofa_agent_invites',
    'get',
    {},
    { headers: { ofaId: account } },
  );

  return members.map(member => ({
    email: member.email,
    role: member.role,
    status: member.status,
  }));
}

const updateProfile = createService(
  async ({ account, member: { id, ...member } }, token) => {
    return request.update('ofa_agent', member, memberAgentsSchema, {
      headers: {
        'content-type': 'form-data/multipart',
        ofaId: account,
        profileId: id,
      },
      token,
    });
  },
);

// created to modify invitations:
const updateInvite = createService(
  async ({ account, member: { id, ...member } }, token) => {
    return request.update('ofa_agent_invites', member, membersSchema, {
      headers: {
        'content-type': 'application/json',
        ofaId: account,
        profileId: id,
      },
      token,
    });
  },
);

const sendInvitation = async ({ account, member }, token) => {
  return request.create('ofa_agent_invites', member, membersSchema, {
    headers: {
      'content-type': 'application/json',
      ofaId: account,
    },
    token,
  });
};

const removeInvitation = createService(async ({ account, id }, token) =>
  request(
    'ofa_agent_invite',
    'delete',
    {},
    {},
    {
      headers: {
        id,
        inviteId: id,
        ofaId: account,
        type: 'EMAIL',
      },
      token,
    },
  ),
);

const removeMember = createService(async ({ account, id }, token) =>
  request(
    'ofa_agent',
    'delete',
    {},
    {},
    {
      headers: {
        id,
        ofaId: account,
        profileId: id,
      },
      token,
    },
  ),
);

export default {
  get,
  getById,
  getInvites,
  removeInvitation,
  removeMember,
  sendInvitation,
  updateInvite,
  updateProfile,
};
