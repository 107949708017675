import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@styled-components';

import Card from 'Components/Card';

import DataProvider from 'Containers/DataProvider';

import { getUnit } from 'Modules/dates';

import { currentAccount } from 'Selectors';

import service from 'Services/orders';

import LineChartLoader from 'Components/LineChartLoader';
import Chart from './Chart';

const mapStateToProps = state => ({
  account: currentAccount(state),
});

const AverageItemsPerPurchaseChart = ({
  account,
  className,
  from = moment()
    .subtract(1, 'year')
    .format(),
  to = moment().format(),
}) => {
  const unit = getUnit(from, to);
  return (
    <DataProvider
      params={{ account, from, to, unit }}
      query={service.getAverageItemsByTime}
      template={({ data: items = {} }) => (
        <Card className={className} title="$AVERAGE_ITEMS_PER_BASKET">
          <Chart
            data={Object.entries(items).map(([date, item]) => ({
              x: date,
              y1: item,
            }))}
            loader={LineChartLoader}
            unit={unit}
          />
        </Card>
      )}
    />
  );
};

AverageItemsPerPurchaseChart.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
  from: PropTypes.string,
  to: PropTypes.string,
};

export default compose(
  connect(mapStateToProps),
  withStyles``,
)(AverageItemsPerPurchaseChart);
