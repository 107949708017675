import React from 'react';
import PropTypes from '@prop-types';
import styled, { theme } from '@styled-components';

import { FormattedMessage } from '@react-intl';

import Button from 'Components/Button';
import Icon from 'Components/Icon';

const Content = styled('p')``;
const Title = styled('h2')``;
const Wrapper = styled('div')``;

const EmptyTemplate = ({ className }) => (
  <article className={className}>
    <Wrapper>
      <Icon name="media-empty">MEDIA_EMPTY</Icon>
      <FormattedMessage capitalize component={Title} id="MEDIA_EMPTY.ADD_MEDIA">
        Add media content
      </FormattedMessage>
      <FormattedMessage
        capitalize
        component={Content}
        id="INSTRUCTIONS.EMPTY_MEDIA.CREATE_MEDIA"
      >
        You can create some faq and they would be listed here
      </FormattedMessage>
    </Wrapper>
  </article>
);

EmptyTemplate.propTypes = {
  className: PropTypes.string,
};

export default styled(EmptyTemplate)`
  align-items: center;
  background: ${theme('--color-light')};  display: flex;
  flex-direction: column;
  height: 50rem;
  justify-content: center;
  overflow-y: scroll;

  ${Wrapper} {
    display: grid;
    grid-gap: 0.4rem;
    grid-template-rows: minmax(0, calc(100% - 2.4rem)) 2.4rem;
    height: calc(100% - 2.4rem);
    max-height: 40rem;
    text-align: center;
    ${Icon} {
      height: 65%;
      margin: 0 auto;
    }
    ${Title} {
      ${theme('--font-large')}
      ${theme('--font-weight-book')}
      ${theme('--font-opacity-100')}
    }
    ${Content} {
      margin-top: 0.4rem;
      opacity: 0.6;
    }
    ${Button} {
      margin-top: 2.4rem;
      width: 14rem;
    }
  }
`;
