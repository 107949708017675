import React from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from '@prop-types';

import { PlaceholderNoData as Placeholder } from 'Containers/Placeholder';

import Button from 'Components/Button';
import { Row } from 'Components/ResponsiveTable';

const Wrapper = styled('article')``;

const Label = styled(Placeholder)`
  color: ${props =>
    props.alert
      ? props.theme['--color-alert']
      : props.theme['--color-dark-night-40']};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const Name = styled(Label)``;
const Pic = styled(Placeholder.img).attrs({
  forwardProps: ['src', 'key'],
})`
  border: 0.1rem solid ${theme('--color-dark-night-10')};
  border-radius: 0.6rem;
  object-fit: cover;
`;
const Variant = styled(Label)``;
const Color = styled(Label)``;
const Price = styled(Label)``;
const Size = styled(Label)``;

const Template = ({
  className,
  color,
  empty,
  id,
  images = [],
  name,
  onClick: handleClick = () => () => {},
  price,
  size,
}) => (
  <Button
    as={Row}
    className={className}
    empty={empty}
    onClick={handleClick(id)}
  >
    <Wrapper>
      <Pic key={images[0]} fill src={images[0]} />
      <Name placeholder="variant name">{name}</Name>
      <Variant placeholder="variant id">{id}</Variant>
      <Color placeholder="color name">{color}</Color>
      <Price placeholder="price">{price}</Price>
      <Size placeholder="variant size">{size}</Size>
    </Wrapper>
  </Button>
);

Template.propTypes = {
  className: PropTypes.string,
  color: PropTypes.object,
  empty: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  images: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
  onClick: PropTypes.func,
  price: PropTypes.string,
  size: PropTypes.string,
};

export default styled(Template)`
  width: 100%;

  ${Wrapper} {
    display: grid;
    grid-column-gap: 0.8rem;
    grid-row-gap: 0.4rem;
    grid-template: auto / 4rem 1fr auto;
    padding: 1.6rem 0 1.2rem 0;

    ${Label} {
      ${theme('--font-small')}
      ${theme('--font-weight-medium')}
      text-align: left;
    }

    ${Pic} {
      grid-column: 1;
      grid-row: 1 / span 3;
      height: 4rem;
      width: 4rem;
    }

    ${Name} {
      ${theme('--font-medium')}
      ${theme('--font-weight-medium')}
      ${theme('--font-opacity-100')}
      grid-column: 2 / span 2;
      grid-row: 1;
    }
  }
`;
