import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { currentAccount } from 'Selectors';
import { set } from 'Actions/accounts';

import DataProvider from 'Containers/DataProvider';

import service from 'Services/orders';

import Empty from './components/Empty';
import View from './Order';

const mapStateToProps = state => ({ account: currentAccount(state) });

const mapDispatchToProps = dispatch =>
  bindActionCreators({ setOfa: set }, dispatch);

const Order = ({ account, setOfa, ...props }) => {
  // eslint-disable-next-line no-restricted-globals
  const urlParams = new URLSearchParams(location.search);
  const platformId = urlParams.get('platformId');
  const ofaId = Number(urlParams.get('ofaId')) || account;
  const ofaSecret = urlParams.get('ofaSecret');
  const shoppingCartId = urlParams.get('shoppingCartId');
  const type = urlParams.get('type');

  const handleOrderUpdate = async data => {
    await service.update({
      data,
      ofaId,
      ofaSecret,
      platformId,
      shoppingCartId,
      type,
    });
  };

  useEffect(() => {
    if (account !== ofaId) setOfa(ofaId);
  }, [account, ofaId, setOfa]);

  return (
    <DataProvider
      emptyTemplate={Empty}
      handleOrderUpdate={handleOrderUpdate}
      ofaId={ofaId}
      ofaSecret={ofaSecret}
      params={{
        ofaId,
        ofaSecret,
        platformId,
        shoppingCartId,
        type,
      }}
      platformId={platformId}
      query={service.getById}
      template={View}
      type={type}
      {...props}
    />
  );
};

Order.propTypes = {
  account: PropTypes.number,
  location: PropTypes.object,
  setOfa: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Order);
