import { all, fork } from 'redux-saga/effects';

import accounts from './accounts';
import session from './session';

const sagas = [accounts, session];

export default function* root() {
  yield all(sagas.map(saga => fork(saga)));
}
