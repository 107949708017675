import React from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';
import { FormattedDate, FormattedTime } from '@react-intl';
import { name } from '@langs';

import Placeholder, { withPlaceholder } from 'Containers/Placeholder';

import RawAvatar from 'Components/Avatar';
import Icon from 'Components/Icon';
import RawPlatforms from 'Components/Platforms';
import { Row } from 'Components/ResponsiveTable';

const Avatar = styled(withPlaceholder(RawAvatar))``;
const Platforms = styled(withPlaceholder(RawPlatforms))``;

const Name = styled(Placeholder).attrs({ placeholder: 'Customer' })``;
const Date = styled(Placeholder).attrs({ placeholder: 'Date' })``;
const Locale = styled(Placeholder).attrs({ placeholder: 'Locale' })``;
const Time = styled(Placeholder).attrs({ placeholder: 'Local time' })``;

const Wrapper = styled('div')``;

const Customer = ({
  className,
  createdAt: date,
  customerId,
  empty,
  fullName,
  images: { small: image } = {},
  locale,
  onClick,
  platforms,
  timeZone,
}) => (
  <Row className={className} empty={empty} onClick={onClick(customerId)}>
    <Wrapper>
      <Avatar fill image={image} name={fullName} />
      <Name>{fullName}</Name>
      <Time>
        {timeZone && (
          <FormattedTime
            hour12
            timeZone={timeZone}
            timeZoneName="short"
            value={new window.Date()}
          />
        )}
      </Time>
      <Locale>{name(locale)}</Locale>
      <Platforms fill data={platforms} />
      <FormattedDate
        component={Date}
        day="2-digit"
        month="short"
        value={new window.Date(date)}
      />
    </Wrapper>
  </Row>
);

Customer.defaultProps = {
  onClick() {},
};

Customer.propTypes = {
  className: PropTypes.string,
  createdAt: PropTypes.string,
  customerId: PropTypes.number,
  empty: PropTypes.bool,
  fullName: PropTypes.string,
  images: PropTypes.objectOf(PropTypes.string),
  locale: PropTypes.string,
  onClick: PropTypes.func,
  platforms: PropTypes.arrayOf(PropTypes.string),
  timeZone: PropTypes.string,
};

export default styled(Customer)`
  align-items: center;
  display: flex;
  height: 8rem;

  ${Wrapper} {
    align-items: center;
    border: none;
    color: inherit;
    display: grid;
    grid-gap: 0.4rem 0.8rem;
    grid-template-columns: auto 1fr 6rem;
    width: 100%;
  }

  ${Avatar} {
    border-radius: 50%;
    grid-column: 1;
    grid-row: 1 /span 3;
    height: 4rem;
    min-height: 4rem;
    min-width: 4rem;
  }

  ${Name} {
    color: ${theme('--color-primary')};
    ${theme('--font-weight-medium')}
    grid-column: 2;
    grid-row: 1;
    text-align: left;
  }

   ${Locale}, ${Time}, ${Date} {
    ${theme('--font-small')}
    ${theme('--font-opacity-40')}
    ${theme('--font-weight-book')}
  }

  ${Locale} {
    grid-column: 2;
    grid-row: 3;
    text-align: left;
  }
   ${Time}{
    grid-column: 2;
    grid-row: 2;
    text-align: left;
   }
   
   ${Date} {
    grid-column: 3;
    grid-row: 3;
    text-align: right;
   }

  ${Platforms} {
    grid-column: 3;
    grid-gap: 0.4rem;
    grid-row: 1 /span 2;
    grid-template-columns: repeat(3, 1fr);
    height: 100%;
    text-align: right;

    ${Icon} {
      cursor: pointer;
      height: 1.6rem;
      width: 1.6rem;
    }
  }
`;
