import React, { useRef } from 'react';
import PropTypes from '@prop-types';
import { FormattedMessage } from '@react-intl';
import { Table as RawTable, useField } from 'spooner';
import styled, { theme } from '@styled-components';

import Button from 'Components/Button';
import withScroll from 'Components/with/scroll';

import { Heading, Card } from '../Common';
import EditableQuestion from './EditableQuestion';

const TableHeader = styled('div')``;
const AlternativePhrassing = styled(Button)``;
const Table = withScroll(styled('div')`
  height: 100%;
  overflow: scroll;
`);

const Question = styled('div')``;

const ENTER = 13;

const Questions = ({ className, role }) => {
  const isReader = role === 'READER';
  const c = useField({ name: 'questions' });
  const ref = useRef(null);

  const onAdd = () => {
    c.onChange([...c.value, { text: '' }]);

    window.requestAnimationFrame(() => {
      const element = Array.from(ref.current.querySelectorAll('input')).pop();
      element.focus();
    });
  };

  const onKeyDown = e => {
    if (e.keyCode === ENTER) {
      onAdd();
    }
  };

  return (
    <div ref={ref} className={className}>
      <FormattedMessage capitalize component={Heading} id="QUESTIONS">
        Questions
      </FormattedMessage>
      <Card>
        {!isReader && (
          <TableHeader>
            <FormattedMessage
              component={AlternativePhrassing}
              id="INSTRUCTIONS.ADD_ALTERNATIVE_PHRASING"
              onClick={onAdd}
              type="button"
            >
              + Add alternative phrasing
            </FormattedMessage>
          </TableHeader>
        )}
        <Table>
          <RawTable name="questions">
            {({ $add, $remove, array, index, ...props }) => {
              if (isReader) {
                const { text } = c.value[index];
                return <Question>{text}</Question>;
              }

              return (
                <EditableQuestion
                  onAdd={index === 0 && onAdd}
                  onKeyDown={onKeyDown}
                  onRemove={array.length > 1 ? $remove : null}
                  {...props}
                />
              );
            }}
          </RawTable>
        </Table>
      </Card>
    </div>
  );
};

Questions.propTypes = {
  className: PropTypes.string,
  role: PropTypes.string,
};

export default styled(Questions)`
  display: flex;
  flex-direction: column;
  position: relative;

  ${Card} {
    height: calc(100vh - 34rem);
    min-height: 34rem;

    ${TableHeader} {
      border-bottom: 0.1rem solid ${theme('--color-dark-night-20')};
      display: flex;
      height: 4.8rem;
      justify-content: flex-end;
      margin-bottom: 1.2rem;
      padding: 1.6rem;
      width: 100%;

      ${AlternativePhrassing} {
        ${theme('--font-small')}
        ${theme('--font-weight-demi')}
        color: ${theme('--color-primary')};
        position: absolute;
      }
    }

    ${Table} {
      height: calc(100% - 6rem);
      position: relative;

      ${Question} {
        ${theme('--font-medium')}
        ${theme('--font-weight-book')}
        ${theme('--font-opacity-80')}
        align-items: center;
        border-bottom: 0.1rem solid ${theme('--color-dark-night-10')};
        display: flex;
        height: 4.8rem;
        margin: 0 1.6rem;
        width: calc(100% - 3.2rem);
      }
    }
  }
`;
