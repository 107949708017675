import React from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';
import { Row, SortableColumn, Column } from 'Components/ResponsiveTable';

const HeaderTemplate = ({ className, onSortBy, sortBy, order }) => {
  const common = { onSortBy, order, sortBy };

  return (
    <Row className={className}>
      <SortableColumn field="name" id="$ITEM_CATEGORY" {...common} />
      <Column field="name" id="MERCHANT_ID" />
      <SortableColumn field="price" id="PRICE" {...common} />
      <SortableColumn field="sales" id="SALES" {...common} />
      <SortableColumn field="visits" id="VISITS" {...common} />
      <Column field="nam" id="CATEGORIES" />
    </Row>
  );
};

HeaderTemplate.propTypes = {
  className: PropTypes.string,
  onSortBy: PropTypes.func,
  order: PropTypes.string,
  sortBy: PropTypes.func,
};

export default styled(HeaderTemplate)`
  grid-template-columns: 1fr 15rem 7rem 7rem 7rem 20rem;
  padding: 1.2rem 2.4rem 0.8rem 2.4rem;
`;
