import React, { useState } from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from '@prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from '@react-intl';
import { useHistory } from 'react-router-dom';

import { useMultiDataProviderContext } from 'Containers/MultiDataProvider';

import Dialog from 'Components/Dialog';
import Form, { Input } from 'Components/Form';
import Button from 'Components/Button';
import ButtonLoader from 'Components/ButtonLoader';

import { currentAccount } from 'Selectors';

import service from 'Services/knowledge-base';

import MediaUploader from './MediaUploader';

const SectionDescription = styled('p')``;
const Actions = styled('div')``;

const mapStateToProps = state => ({
  account: currentAccount(state),
});

const AddKnowledgeBase = ({ account, className, in: isIn, onClose }) => {
  const [formValues, setFormValues] = useState({});
  const [isSubmitable, setIsSubmitable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { refetch } = useMultiDataProviderContext('knowledgeBase');

  const { location: { state: { locale = 'en_US' } = {} } = {} } = useHistory();

  const handleClick = async () => {
    setIsSubmitable(false);
    setIsLoading(true);
    await service.add({ account, locale, media: formValues });
    setIsSubmitable(true);
    setIsLoading(false);
    onClose();

    if (refetch) {
      refetch();
    }
  };

  const handleFormChange = formData => setFormValues(formData);

  const isDisabled = !formValues.file || !isSubmitable;

  return (
    <Dialog
      className={className}
      disabled={isLoading}
      in={isIn}
      onDismiss={onClose}
      title="UPLOAD_DOCUMENT"
    >
      <Form onChange={handleFormChange}>
        <MediaUploader />
        <FormattedMessage
          capitalize
          component={SectionDescription}
          id="DESCRIPTION_OPTIONAL"
        />
        <Input
          label="INSTRUCTIONS.DESCRIPTION_KNOWLEDGE_BASE"
          name="description"
        />
        <Actions>
          <FormattedMessage
            capitalize
            primary
            component={ButtonLoader}
            disabled={isDisabled}
            id="ACTIONS.UPLOAD"
            isLoading={isLoading}
            onClick={handleClick}
          />
          <FormattedMessage
            capitalize
            secondary
            component={Button}
            id="ACTIONS.CANCEL"
            onClick={onClose}
          />
        </Actions>
      </Form>
    </Dialog>
  );
};

AddKnowledgeBase.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
  in: PropTypes.bool,
  onClose: PropTypes.func,
};

export default connect(mapStateToProps)(styled(AddKnowledgeBase)`
  ${Dialog} {
    &[disabled] {
      border-color: ${theme('--color-dark-night-20')};
      ${theme('--font-opacity-40')}
    }
  }

  ${Input} {
    margin-top: 0.8rem;
  }

  ${SectionDescription} {
    ${theme('--font-medium')}
    ${theme('--font-weight-book')}
    color: ${theme('--color-dark-night-60')};
  }

  ${Actions} {
    display: grid;
    grid-gap: 0.8rem;
    grid-template: auto / 1fr 1fr;
    margin-top: 4.8rem;

    ${Button} {
      padding: 1.4rem;
    }
  }

  @media (${theme('--screen-small')}) {
    ${Actions} {
      display: block;

      ${(Button, ButtonLoader)} {
        padding: 1.4rem;
        width: 17rem;

        &:not(:last-child) {
          margin-right: 1.6rem;
        }
      }
    }
  }
`);
