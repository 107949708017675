import React from 'react';
import PropTypes from '@prop-types';
import styled, { theme } from '@styled-components';

import Icon from 'Components/Icon';
import Button from 'Components/Button';

const Wrapper = styled('div')``;

const DateContainer = styled('div')``;
const CalendarIcon = styled(Icon).attrs({ name: 'calendar-outline' })``;
const ArrowsIcon = styled(Icon).attrs({ name: 'horizontal-arrows' })``;
const DateSpan = styled('span')``;

const Header = ({ className, fromContent, toContent, onClick }) => (
  <Button className={className} onClick={onClick}>
    <Wrapper>
      <DateContainer>
        <CalendarIcon name="calendar-outline">CALENDAR</CalendarIcon>
        <DateSpan>{fromContent}</DateSpan>
        <ArrowsIcon>SELECT_DATE</ArrowsIcon>
      </DateContainer>
      <DateContainer>
        <DateSpan>{toContent}</DateSpan>
        <ArrowsIcon>SELECT_DATE</ArrowsIcon>
      </DateContainer>
    </Wrapper>
  </Button>
);

Header.propTypes = {
  className: PropTypes.string,
  fromContent: PropTypes.string,
  onClick: PropTypes.func,
  toContent: PropTypes.string,
};

export default styled(Header)`
  width: 100%;

  ${Wrapper} {
    background: ${theme('--color-light')};
    border: 1px solid ${theme('--color-dark-night-10')};
    border-radius: 0.6rem;
    cursor: pointer;
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 4.8rem;

    ${DateContainer} {
      ${theme('--font-small')}
      ${theme('--font-weight-book')}
      align-items: center;
      color: ${theme('--color-dark-night-60')};
      display: flex;
      height: 100%;
      padding-left: 1.6rem;
      position: relative;

      ${CalendarIcon} {
        display: none;
        left: 0.6rem;
        position: absolute;
      }

      ${ArrowsIcon} {
        position: absolute;
        right: 0.6rem;
      }
    }

    ${Icon} {
      ${theme('--font-xlarge')}
    }
  }

  @media (${theme('--column-12')}) {
    width: 40rem;
  }

  @media (${theme('--screen-small')}) {
    ${Wrapper} {
      ${DateContainer} {
        padding-left: 5.6rem;

        &:last-child {
          padding-left: 1.6rem;
        }

        ${CalendarIcon} {
          display: block;
        }
      }
    }
  }
`;
