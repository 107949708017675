import styled, { theme } from '@styled-components';

const Title = styled('h2')`
  ${theme('--font-medium')}
  ${theme('--font-weight-medium')}
  color: ${theme('--color-light')};
  margin-bottom: 2.4rem;
`;

export default Title;
