import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import {
  FormattedMessage,
  intlDefault,
  intlShape,
  withIntl,
} from '@react-intl';
import styled, {
  withStyles as css,
  withTheme,
  theme,
} from '@styled-components';

import { common } from '@countries';

import Card from 'Components/Card';
import Map, { Country } from 'Components/Map';
import Placeholder from 'Containers/Placeholder/index';

const Info = styled('section')``;
const Title = styled('h3')``;
const Datum = styled('div')``;
const Label = styled(Placeholder)``;
const Value = styled(Placeholder)``;

const Scrollable = styled('div')``;

const MOBILE_THRESHOLD = 600;

export const UsersByCountry = ({
  className,
  intl: { locale },
  theme: colorTheme,
  data,
}) => {
  const [zoomLevel, setZoomLevel] = useState(2);
  const total = data.reduce((result, { count }) => result + count, 0);
  const users = data.map(({ count, value: key }) => ({
    count,
    key,
    value: count / total,
  }));

  useEffect(() => {
    if (document.documentElement.clientWidth < MOBILE_THRESHOLD) {
      setZoomLevel(0.2);
    }
  }, []);

  return (
    <Card className={className} title="USERS_BY_COUNTRY">
      <Map
        doubleClickZoom={false}
        lat={45}
        lng={0}
        scrollWheelZoom={false}
        zoom={zoomLevel}
        zoomControl={false}
      >
        {users.map(({ count, key, value }) => (
          <Country
            key={key}
            code={key}
            color={colorTheme['--color-secondary']}
            opacity={value}
          >
            <FormattedMessage
              defaultMessage="{count} users from {country}"
              id="%COUNT%_USERS_FROM_%COUNTRY%"
              values={{ count, country: common(key, locale) }}
            />
          </Country>
        ))}
      </Map>
      <Info>
        <FormattedMessage capitalize component={Title} id="TOP_COUNTRIES">
          top countries
        </FormattedMessage>
        <Scrollable>
          {users.map(({ count, key }) => (
            <Datum key={key}>
              <Label placeholder="country">{common(key, locale)}</Label>
              <Value placeholder="value">{count}</Value>
            </Datum>
          ))}
        </Scrollable>
      </Info>
    </Card>
  );
};

UsersByCountry.defaultProps = {
  data: [
    { count: 0, value: '1' },
    { count: 0, value: '2' },
    { count: 0, value: '3' },
    { count: 0, value: '4' },
    { count: 0, value: '5' },
    { count: 0, value: '6' },
  ],
  intl: intlDefault,
};

UsersByCountry.propTypes = {
  className: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  data: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.number,
      value: PropTypes.string,
    }),
  ),
  intl: intlShape,
  theme: PropTypes.object,
};

export default compose(
  withIntl,
  css`
    display: block;
    height: 100%;
    width: 100%;

    ${Map} {
      flex-basis: 0;
      flex-grow: 1;
      height: 17.5rem;
    }

    ${Info} {
      margin-right: -2.4rem;
      padding: 0;

      ${Title} {
        ${theme('--font-medium')}
        ${theme('--font-opacity-60')}
        ${theme('--font-weight-medium')}
        display: none;
        padding-bottom: 4rem;
      }

      ${Scrollable} {
        height: 50rem;
        overflow: scroll;
        padding-right: 2.4rem;
      }

      ${Datum} {
        align-items: center;
        border-bottom: 0.1rem solid ${theme('--color-dark-night-10')};
        display: flex;
        height: 8rem;
        justify-content: space-between;

        ${Label} {
          ${theme('--font-large')}
          ${theme('--font-weight-medium')}
        }

        ${Value} {
          ${theme('--font-small')}
          ${theme('--font-opacity-40')}
          ${theme('--font-weight-medium')}
        }
      }
    }

    @media (${theme('--screen-small')}) {
      display: flex;

      ${Map} {
        flex-basis: 0;
        flex-grow: 1;
        height: 46rem;
      }

      ${Info} {
        padding: 0 0 0 3.2rem;
        width: 33rem;

        ${Title} {
          display: block;
        }

        ${Datum} {
          height: 6.4rem;
        }
      }
    }

    @media (${theme('--level-3')}) {
      ${Map} {
        height: 56.6rem;
      }
    }
  `,
  withTheme,
)(UsersByCountry);
