import React, { forwardRef, memo } from 'react';
import PropTypes from '@prop-types';
import { FormattedNumber as Number } from 'react-intl';

const FormattedNumber = ({
  component: Wrapper = 'span',
  currency,
  forwardedRef,
  value,
  ...props
}) => (
  <Number currency={currency} style="currency" value={value} {...props}>
    {text => (
      <Wrapper {...props} ref={forwardedRef}>
        {text}
      </Wrapper>
    )}
  </Number>
);

FormattedNumber.propTypes = {
  component: PropTypes.component,
  currency: PropTypes.string,
  forwardedRef: PropTypes.ref,
  value: PropTypes.number,
};

export default memo(
  // eslint-disable-next-line react/display-name
  forwardRef((props, forwardedRef) => (
    <FormattedNumber {...props} forwardedRef={forwardedRef} />
  )),
);
