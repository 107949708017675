import React from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from '@prop-types';
import { FormattedMessage } from '@react-intl';
import { Row } from 'Components/ResponsiveTable';

const HeaderTemplate = ({ className }) => (
  <Row className={className}>
    <FormattedMessage capitalize id="QUESTION" />
    <FormattedMessage capitalize id="ANSWER" />
  </Row>
);

HeaderTemplate.propTypes = {
  className: PropTypes.string,
};

export default styled(HeaderTemplate)`
  ${theme('--font-weight-medium')}
  border-bottom: 0.1rem solid ${theme('--color-dark-night-10')};
  grid-template-columns: 1fr 1fr;
  padding: 1.2rem 2.4rem 0.8rem;
`;
