export default {
  CACHE: 'cache',
  ERROR: {
    CANCELED: 'CANCELED',
    MALFORMED_CONTENT: 'MALFORMED_CONTENT',
    NETWORK: 'NETWORK',
    NOT_FOUND: 'NOT_FOUND',
    SCHEMA: 'SCHEMA',
    TOKEN_EXPIRED: 'TOKEN_EXPIRED',
    UNAUTHORIZED: 'UNAUTHORIZED',
    UNKNOWN: 'UNKNOWN',
  },
  FILTERS: {
    ALL_TIME: 'ALL_TIME',
    CUSTOM: 'CUSTOM',
    LAST_MONTH: 'LAST_MONTH',
    LAST_WEEK: 'LAST_WEEK',
    LAST_YEAR: 'LAST_YEAR',
    TODAY: 'TODAY',
    YESTERDAY: 'YESTERDAY',
  },
  ROLE: {
    ADMIN: 'ADMIN',
    READER: 'READER',
    SUPER_ADMIN: 'SUPER_ADMIN',
    WRITER: 'WRITER',
  },
  STATUS: {
    ACTIVE: 'ACTIVE',
  },
  STORE: 'joinedapp',
};
