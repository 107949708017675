import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from '@react-intl';
import PropTypes from '@prop-types';
import styled, { theme } from '@styled-components';
import service from 'Services/campaigns';

import Button from 'Components/Button';
import Dialog, { Actions, Wrapper } from 'Components/Dialog';
import IconButton from 'Components/IconButton';
import { Select } from 'Components/Form';

const Content = styled('div')``;
const Title = styled('p')``;

const CampaignSelect = ({
  account,
  className,
  in: isIn,
  onAccept,
  onCancel,
}) => {
  const intl = useIntl();
  const [campaigns, setCampaigns] = useState([]);
  const [value, setValue] = useState(null);

  const options = [
    {
      name: intl.formatMessage({
        capitalize: true,
        id: 'INSTRUCTIONS.SELECT_CAMPAIGN',
      }),
      value: null,
    },
  ];

  campaigns.forEach(({ id, name }) =>
    options.push({ name: name.trim(), value: id }),
  );

  const handlerAccept = () =>
    onAccept({
      deeplink: campaigns.find(({ id }) => id === value)?.deeplink,
      id: value,
      platform: campaigns.find(({ id }) => id === value)?.platform,
    });

  useEffect(() => {
    service
      .get({ account, type: 'SOCIAL' })
      .then(({ collection = [] }) => setCampaigns(collection));
  }, [account]);

  return (
    <Dialog
      className={className}
      in={isIn}
      onDismiss={onCancel}
      title="LINK_TO_CAMPAIGN"
    >
      <Content>
        <Select
          name="campaign-id-select"
          onChange={setValue}
          options={options}
          value={value}
        />
        <Actions>
          <FormattedMessage
            capitalize
            primary
            component={Button}
            disabled={!value}
            id="ACTIONS.DONE"
            onClick={handlerAccept}
          />
        </Actions>
      </Content>
    </Dialog>
  );
};

CampaignSelect.propTypes = {
  account: PropTypes.number.isRequired,
  className: PropTypes.string,
  in: PropTypes.bool,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
};

export default styled(CampaignSelect)`
  ${Wrapper} {
    width: 56.4rem;

    ${IconButton} {
      top: 0.8rem !important;
    }
  }

  ${Content} {
    margin-top: -2rem;
    text-align: center;
  }

  ${Title} {
    ${theme('--font-large')}
    color: ${theme('--color-dark-night-80')};
    line-height: 1.6rem;
    margin-bottom: 0.8rem;
  }

  ${Actions} {
    margin-top: 4rem;

    ${Button} {
      margin: 0;
    }
  }

  ${Select} {
    margin-top: 2.4rem;
    text-align: left;
  }
`;
