import React from 'react';
import { common, CCA2 } from '@countries';
import PropTypes from 'prop-types';
import styled from '@styled-components';
import { v4 as uuid } from 'uuid';

import { address, SEPARATOR } from 'Providers/formatter';

const Field = styled('span')``;
const Separator = styled('br')``;

const makeMapLineToField = fields => (acc, field) => {
  if (!fields[field] && field !== Separator) return acc;

  return [...acc, <Field key={field}>{fields[field] || SEPARATOR}</Field>];
};

const makeMapFormatToLines = fields => (acc, entry) => {
  const newLine = entry.reduce(makeMapLineToField(fields), []);

  if (!newLine.length) return acc;

  newLine.push(<Separator key={uuid()} />);

  return [...acc, newLine];
};

export const Address = ({
  className,
  country: code,
  email,
  phone,
  ...props
}) => {
  const fields = { ...props, country: code ? common(code) : undefined };

  const format = address(CCA2(code));

  const lines = format.reduce(makeMapFormatToLines(fields), []);

  return (
    <address className={className}>
      {lines}
      {phone}
      {phone && email && ' - '}
      {email}
    </address>
  );
};

Address.propTypes = {
  city: PropTypes.string.isRequired,
  className: PropTypes.string,
  company: PropTypes.string,
  country: PropTypes.string.isRequired,
  email: PropTypes.string,
  name: PropTypes.string,
  phone: PropTypes.string,
  postalCode: PropTypes.string.isRequired,
  region: PropTypes.string,
  street1: PropTypes.string,
  street2: PropTypes.string,
};

export default styled(Address)`
  font-style: normal;

  ${Field} {
    margin-right: 0.5rem;
  }
`;
