import React from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from '@prop-types';

import Card from 'Components/RadioCard';
import RadioRatio from './RadioRatio';

const RatioSelector = ({ className }) => {
  return (
    <div className={className}>
      <Card hint="(1:1)" id="square" name="ratio" />
      <Card hint="(1:91)" id="portrait" name="ratio" />
      <RadioRatio hint="(1:91)" id="square" name="ratio" />
      <RadioRatio hint="(1:91)" id="portrait" name="ratio" />
    </div>
  );
};

RatioSelector.propTypes = {
  className: PropTypes.string,
};

export default styled(RatioSelector)`
  display: grid;
  grid-gap: 0.8rem;
  grid-template: 5.4rem 5.4rem / 1fr;
  padding: 1.4rem 0;

  ${Card} {
    display: none;
  }

  @media (${theme('--screen-small')}) {
    grid-gap: 1.6rem;
    grid-template: 24rem / 1fr 1fr;

    ${Card} {
      display: block;
    }

    ${RadioRatio} {
      display: none;
    }
  }
`;
