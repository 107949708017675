import React from 'react';
import PropTypes from 'prop-types';

import Confirm from 'Components/Confirm';

const RemoveMember = ({ isMember, onAccept, onCancel, in: isIn }) => (
  <Confirm
    in={isIn}
    onAccept={onAccept}
    onCancel={onCancel}
    title={isMember ? 'QUESTIONS.REMOVE_MEMBER' : 'QUESTIONS.REMOVE_INVITATION'}
  />
);

RemoveMember.propTypes = {
  in: PropTypes.bool,
  isMember: PropTypes.bool,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
};

export default RemoveMember;
