import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@react-intl';
import styled, { theme } from '@styled-components';

import Button from 'Components/Button';
import Form, { Input } from 'Components/Form';
import Link from 'Components/Link';
import { Redirect } from 'Components/Router';

import { resetPassword as resetPasswordRules } from 'Rules';

const H2 = styled('h2')``;
const Paragraph = styled('p')``;
const Links = styled('div')``;
const Group = styled('div')``;

class ResetPassword extends Component {
  static propTypes = {
    className: PropTypes.string,
    error: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
    resetPassword: PropTypes.func,
  };

  handleSubmit = ({ password }) => {
    const {
      error,
      location: { search = '' },
      resetPassword,
    } = this.props;

    const queryParams = search.split('&').map(param => param.split('=')[1]);
    const code = queryParams[0];
    const email = queryParams[1];
    if (code && email) {
      resetPassword(password, code, email);
    } else {
      error('EMAIL_CODE_REQUIRED');
    }
  };

  render() {
    const { className, isLoggedIn } = this.props;

    if (isLoggedIn) {
      return <Redirect push to="/" />;
    }

    return (
      <Form
        className={className}
        constraint={resetPasswordRules}
        onSubmit={this.handleSubmit}
      >
        <FormattedMessage capitalize component={H2} id="RESET_PASSWORD">
          Reset password
        </FormattedMessage>
        <FormattedMessage
          capitalize
          component={Paragraph}
          id="INSTRUCTIONS.RESET_PASSWORD"
        >
          Access into your account
        </FormattedMessage>
        <Input capitalize label="PASSWORD" name="password" type="password" />
        <Input
          capitalize
          label="PASSWORD_CONFIRMATION"
          name="confirmedPassword"
          type="password"
        />
        <FormattedMessage
          capitalize
          primary
          component={Button}
          id="ACTIONS.SUBMIT"
          type="submit"
        >
          Submit
        </FormattedMessage>
        <Links>
          <Group>
            <FormattedMessage capitalize id="QUESTIONS.FORGOT_PASSWORD">
              forgot password?
            </FormattedMessage>
            <FormattedMessage
              capitalize
              component={Link}
              id="RESET"
              to="/recover-password"
            >
              Reset
            </FormattedMessage>
          </Group>
          <Group>
            <FormattedMessage capitalize id="QUESTIONS.DONT_HAVE_ACCOUNT">
              Don&apos;t have an account?
            </FormattedMessage>
            <FormattedMessage
              capitalize
              component={Link}
              id="SIGNUP"
              to="/signup"
            >
              Signup
            </FormattedMessage>
          </Group>
        </Links>
      </Form>
    );
  }
}

export default styled(ResetPassword)`
  text-align: center;
  ${H2} {
    ${theme('--font-xlarge')}
    font-weight: normal;
    text-align: left;
  }
  ${Paragraph} {
    ${theme('--font-medium')}
    ${theme('--font-opacity-40')}
    margin: 0.8rem 0 3.2rem;
    text-align: left;
  }

  ${Input} {
    margin-bottom: 1.6rem;
  }

  ${Link} {
    color: ${theme('--color-primary')};
  }

  ${Button} {
    margin-top: 3.2rem;
    text-transform: none;
    width: 100%;
  }
  ${Links} {
    ${theme('--font-small')}
    ${theme('--font-opacity-40')}
    margin-top: 1.6rem;

    ${Group} {
      margin-bottom: 1.6rem;
    }
  }
`;
