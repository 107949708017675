import request from 'Providers/request';

async function get({ account, ratio, searchBy, type }) {
  const media = await request.read(`/ofa/${account}/brand-content`, {
    ratio,
    searchBy,
    type,
  });

  return {
    collection: media.map(({ image, ...rest }) => ({
      ...rest,
      image,
      source: image,
    })),
  };
}

async function remove({ account, id }) {
  return request.delete(`/ofa/${account}/brand-content/${id}`);
}

function add({ account, media }) {
  return request.create(
    `/ofa/${account}/brand-content`,
    media,
    {},
    {
      headers: {
        'content-type': 'form-data/multipart',
      },
    },
  );
}

export default {
  add,
  get,
  remove,
};
