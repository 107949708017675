import React, { useState } from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from '@prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from '@react-intl';

import { useMultiDataProviderContext } from 'Containers/MultiDataProvider';

import Dialog from 'Components/Dialog';
import Form, { Input } from 'Components/Form';
import Button from 'Components/Button';

import { currentAccount } from 'Selectors';

import service from 'Services/brand-content';

import MediaUploader from './MediaUploader';
import TypeSelector from './TypeSelector';
import RatioSelector from './RatioSelector';

const SectionDescription = styled('p')``;
const Actions = styled('div')``;

const mapStateToProps = state => ({
  account: currentAccount(state),
});

const AddBrandContent = ({ account, className, in: isIn, onClose }) => {
  const [formValues, setFormValues] = useState({});
  const [isSubmitable, setIsSubmitable] = useState(true);
  const { refetch } = useMultiDataProviderContext('brandContent');

  const handleClick = async () => {
    setIsSubmitable(false);
    await service.add({ account, media: formValues });
    setIsSubmitable(true);
    onClose();

    if (refetch) {
      refetch();
    }
  };

  const handleFormChange = formData => setFormValues(formData);

  const isDisabled =
    !formValues.file ||
    !formValues.name ||
    !formValues.type ||
    !formValues.ratio ||
    !isSubmitable;

  return (
    <Dialog
      className={className}
      in={isIn}
      onDismiss={onClose}
      title="UPLOAD_MEDIA"
    >
      <Form onChange={handleFormChange}>
        <FormattedMessage
          capitalize
          component={SectionDescription}
          id="INSTRUCTIONS.UPLOAD_BRAND_CONTENT"
        />
        <MediaUploader />
        <FormattedMessage
          capitalize
          component={SectionDescription}
          id="INSTRUCTIONS.SELECT_MEDIA_NAME"
        />
        <Input label="NAME" name="name" />
        <FormattedMessage
          capitalize
          component={SectionDescription}
          id="INSTRUCTIONS.SELECT_TYPE"
        />
        <TypeSelector />
        <FormattedMessage
          capitalize
          component={SectionDescription}
          id="INSTRUCTIONS.SELECT_RATIO"
        />
        <RatioSelector />
        <Actions>
          <FormattedMessage
            capitalize
            primary
            component={Button}
            disabled={isDisabled}
            id="ACTIONS.UPLOAD"
            onClick={handleClick}
          />
          <FormattedMessage
            capitalize
            secondary
            component={Button}
            id="ACTIONS.CANCEL"
            onClick={onClose}
          />
        </Actions>
      </Form>
    </Dialog>
  );
};

AddBrandContent.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
  in: PropTypes.bool,
  onClose: PropTypes.func,
};

export default connect(mapStateToProps)(styled(AddBrandContent)`
  ${SectionDescription} {
    ${theme('--font-medium')}
    ${theme('--font-weight-book')}
    color: ${theme('--color-dark-night-60')};
  }

  ${Input} {
    margin: 0.8rem 0 2rem;
  }

  ${Actions}{
    display: grid;
    grid-gap: 0.8rem;
    grid-template: auto / 1fr 1fr;
    padding-top: 2.4rem;

    ${Button}{
      padding: 1.4rem;
    }
  }

  @media (${theme('--screen-small')}){
    ${Actions}{
      display: block;
      padding-top: 3.2rem;

      ${Button}{
        padding: 1.4rem;
        width: 17rem;

        &:not(:last-child){
          margin-right: 1.6rem;
        }

      }
    }
  }
`);
