import React from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';
import {
  Transition,
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';

const AnswerItem = styled('div')``;

const AnswerTitle = styled('div')``;

const ProgressBar = styled('div')`
  width: calc((100% - 5.3rem) * ${props => props.width} / 100);
`;

const PercentsValue = styled('span')``;

const Answers = ({ answers = [], className }) => {
  return (
    <div className={className}>
      {answers.map((answer, index) => {
        const key = index;
        const percents = Math.round(answer.percents * 100) / 100;

        return (
          <AnswerItem key={key}>
            <TransitionGroup appear component={null}>
              <CSSTransition
                key={answer.id}
                classNames="answer"
                timeout={10000}
              >
                <AnswerTitle>{answer.answer}</AnswerTitle>
              </CSSTransition>
            </TransitionGroup>
            <div>
              <Transition
                appear
                in
                timeout={{
                  appear: 0,
                  enter: 400,
                  exit: 0,
                }}
              >
                {state => (
                  <ProgressBar width={state === 'entering' ? 0 : percents} />
                )}
              </Transition>
              <PercentsValue>{percents}%</PercentsValue>
            </div>
          </AnswerItem>
        );
      })}
    </div>
  );
};

Answers.propTypes = {
  answers: PropTypes.array,
  className: PropTypes.string,
};

export default styled(Answers)`
  height: 31rem;
  overflow: scroll;

  ${AnswerItem} {
    display: grid;
    grid-column-gap: 3.3rem;
    grid-template-columns: 23.5rem 1fr;
    margin: 0 0 3.2rem;
    position: relative;
  }

  ${AnswerTitle} {
    ${theme('--font-medium')}
    ${theme('--font-weight-medium')}
    transition: opacity 400ms linear;

    &.answer-appear {
      opacity: 0;
    }
    &.answer-appear-active {
      opacity: 1;
    }
    &.answer-enter {
      opacity: 0;
    }
    &.answer-enter-active {
      opacity: 1;
    }
    &.answer-exit {
      opacity: 1;
      position: absolute;
      top: 0;
    }
    &.answer-exit-active {
      opacity: 0;
      position: absolute;
      top: 0;
    }
  }

  ${ProgressBar} {
    background: ${theme('--color-secondary')};
    border-radius: 0.4rem;
    display: inline-block;
    height: 0.8rem;
    margin-right: 0.8rem;
    transition: width 1s linear;
  }

  ${PercentsValue} {
    ${theme('--font-medium')}
    ${theme('--font-weight-medium')}
  }
`;
