import { createGlobalStyle } from '@styled-components';

import configuration from 'Theme';
import Theme from './Theme';

export const Styles = createGlobalStyle`
  body {
    ${configuration['--font-medium']}
    background-color: ${configuration['--color-blueish-white']};
    color: ${configuration['--color-dark-night']};
  }
`;

export default Theme;
