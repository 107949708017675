import countries, { common } from '@countries';
import PropTypes from 'prop-types';
import React from 'react';
import { rgba } from 'polished';
import styled, { theme } from '@styled-components';
import { useIntl } from '@react-intl';

import Icon from 'Components/Icon';
import { Select } from 'Components/Form';

const Display = styled('div')``;
const Flag = styled('img').attrs(({ code = '' }) => ({
  src: `/images/flags/${code.toLowerCase()}.svg`,
}))``;

const list = countries.filter(country => country.idd.root);

const countryFindByPhone = phone => {
  const iddRoot = phone.substring(0, 2);
  const country = list.find(
    ({ idd }) =>
      idd.root === iddRoot &&
      idd.suffixes.some(suffix => phone.indexOf(suffix) === 2),
  );

  return country || list.find(({ cca2 }) => cca2 === 'US');
};

const Country = ({ className, onChange, value }) => {
  const { locale } = useIntl();

  const options = list
    .map(country => ({
      name: common(country.cca2, locale),
      value: country.cca2,
    }))
    .sort((a, b) => a.name.localeCompare(b.name));

  const handleChange = countryCode => {
    const { idd } = list.find(country => country.cca2 === countryCode);

    onChange({
      digitalCode: `${idd.root}${
        idd.suffixes.length === 1 ? idd.suffixes[0] : ''
      }`,
      stringCode: countryCode,
    });
  };

  return (
    <div className={className}>
      <Display>
        <Flag code={value} />
        <Icon name="vertical-arrows">ACTIONS.SELECT_OPTION</Icon>
      </Display>
      <Select
        name="prefix-select"
        onChange={handleChange}
        options={options}
        value={value}
      />
    </div>
  );
};

Country.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export { countryFindByPhone };

export default styled(Country)`
  position: relative;

  &:focus-within {
    ${Display} {
      border: 0.1rem solid #005dff;
    }
  }

  ${Display} {
    ${theme('--font-small')}
    align-items: center;
    background: #fff; /* Light */
    border: 0.1rem solid ${rgba('#000F19', 0.1)};
    border-radius: 0.6rem;
    display: flex;
    height: 100%;
    justify-content: space-between;
    padding: 0 0.4rem 0 1rem;
    position: absolute;
    transition: border-color 0.2s ease-out, margin-bottom 0.2s ease-out;
    width: 100%;
    z-index: 1;

    ${Flag} {
      height: 2rem;
      opacity: ${props => (props.disabled ? 0.1 : 1)};
      width: 2.8rem;
    }

    ${Icon} {
      height: 2.4rem;
      margin-left: 0.4rem;
      opacity: ${props => (props.disabled ? 0.1 : 1)};
      width: 2.4rem;
    }
  }

  ${Select} {
    height: 100%;
    overflow: hidden;
  }
`;
