import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@react-intl';
import styled, { theme } from '@styled-components';

import { AutoSuggest, Textarea } from 'Components/Form';

const Instructions = styled('p')``;
const Legend = styled('legend')``;

const Tags = styled(AutoSuggest)``;
const Notes = styled(Textarea)``;

const MoreInfo = ({ className, data }) => (
  <div className={className}>
    <FormattedMessage capitalize component={Legend} id="TAGS" />
    <FormattedMessage
      capitalize
      component={Instructions}
      id="INSTRUCTIONS.TAGS"
    />
    <Tags
      allowCreate
      name="groups"
      options={data}
      placeholder="PLACEHOLDER.TAGS"
    />
    <FormattedMessage capitalize component={Legend} id="NOTES" />
    <FormattedMessage
      capitalize
      component={Instructions}
      id="INSTRUCTIONS.NOTES"
    />
    <Notes label="PLACEHOLDER.NOTES" name="notes" />
  </div>
);

MoreInfo.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
};

export default styled(MoreInfo)`
  ${Legend} {
    ${theme('--font-small')}
    ${theme('--font-opacity-100')}
    ${theme('--font-weight-demi')}
    margin-bottom: 0.8rem;
  }

  ${Instructions} {
    ${theme('--font-small')}
    ${theme('--font-opacity-40')}
    ${theme('--font-weight-book')}
    margin-bottom: 1.6rem;
  }

  ${Tags}, ${Notes} {
    margin-bottom: 4rem;
  }
`;
