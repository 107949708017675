import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as session from 'Actions/session';
import * as logger from 'Actions/logger';

import WrappedComponent from 'Components/Fetcher';

import C from 'Constants';

import l from 'Providers/logger';

const { ERROR } = C;
const mapDispatchToProps = dispatch =>
  bindActionCreators({ log: logger.error, logout: session.logout }, dispatch);

export const Fetcher = ({
  children = () => {},
  log = () => {},
  logout = () => {},
  ...props
}) => (
  <WrappedComponent {...props}>
    {({ data, error, fetch, loading }) => {
      if (!loading && error !== undefined) {
        log(error.message);

        switch (error.message) {
          case ERROR.TOKEN_EXPIRED:
          case ERROR.UNAUTHORIZED:
            logout();
            break;

          default:
            l.error(error);
            break;
        }
      }

      return children({ data, error, fetch, loading });
    }}
  </WrappedComponent>
);

Fetcher.propTypes = {
  children: PropTypes.func,
  log: PropTypes.func,
  logout: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(Fetcher);
