import React, { useRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from '@prop-types';
import styled, { theme, withStyles as css } from '@styled-components';
import { compose } from 'underscore';

import Overlay from 'Components/Overlay';
import IconButton from 'Components/IconButton';
import withTransition from 'Components/with/transition';

import { Title, Paragraph, Button } from '../Components';

const Close = styled(IconButton)``;
const Wrapper = styled('article')``;

const TipOverlay = ({ className, children, requestClose }) => {
  const element = useRef(document.getElementById('overlay'));

  return createPortal(
    <Overlay className={className} onDismiss={requestClose}>
      <Wrapper>
        <Close name="close" onClick={requestClose} />
        {children}
      </Wrapper>
    </Overlay>,
    element.current,
  );
};

TipOverlay.propTypes = {
  children: PropTypes.children,
  className: PropTypes.string,
  requestClose: PropTypes.func,
};

export default compose(
  css`
    opacity: 1;
    transition: opacity 350ms;

    ${Wrapper} {
      background: ${theme('--color-dark-night-80')};
      color: ${theme('--color-light')};
      height: 100vh;
      opacity: 1;
      padding: 4rem;
      position: fixed;
      right: 0;
      transition: right 350ms, opacity 350ms;
      width: 58rem;
      z-index: 1;

      ${Close} {
        position: absolute;
        right: 1.6rem;
        top: 1.6rem;

        &:focus,
        &:hover {
          background: ${theme('--color-dark-night-40')};
        }
      }

      ${Title} {
        ${theme('--font-xlarge')}
        ${theme('--font-weight-medium')}
        color: ${theme('--color-light')};
      }

      ${Paragraph} {
        ${theme('--font-large')}
        ${theme('--font-weight-medium')}
        color: ${theme('--color-white-40')};
      }

      ${Button} {
        ${theme('--font-medium')}
        ${theme('--font-weight-demi')}
        background: ${theme('--color-trust')};
        color: ${theme('--color-light')};
      }
    }

    &.exited,
    &.exiting {
      opacity: 0;

      ${Wrapper} {
        opacity: 0.5;
        right: -58rem;
      }
    }
  `,
  withTransition(350),
)(TipOverlay);
