import React from 'react';
import PropTypes from 'prop-types';

import Confirm from 'Components/Confirm';
import { FormattedMessage } from '@react-intl';

const RemoveMedia = ({ in: isIn, onAccept, onCancel }) => (
  <Confirm
    in={isIn}
    onAccept={onAccept}
    onCancel={onCancel}
    title="QUESTIONS.REMOVE_MEDIA.TITLE"
  >
    <FormattedMessage capitalize id="QUESTIONS.REMOVE_MEDIA" />
  </Confirm>
);

RemoveMedia.propTypes = {
  in: PropTypes.bool,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
};

export default RemoveMedia;
