import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@react-intl';
import styled, { theme } from '@styled-components';

import List, { Item } from 'Components/List';

const Ball = styled('i')``;

const Legend = ({ className, series: rawSeries = [] }) => {
  const series = rawSeries.map(({ name, ...rest }) => ({
    ...rest,
    name: name || 'UNKNOWN',
  }));

  return (
    series.length > 0 && (
      <figcaption className={className}>
        <List
          noWrapper
          items={series}
          template={({ color, name }) => (
            <Item>
              <Ball style={{ backgroundColor: color }} />
              <FormattedMessage capitalize id={name} />
            </Item>
          )}
        />
      </figcaption>
    )
  );
};

Legend.propTypes = {
  className: PropTypes.string,
  series: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
};

export default styled(Legend)`
  ${theme('--font-small')}
  color: ${theme('--color-dark-night-40')};
  margin: 0 auto;
  max-width: 28rem;

  ${List} {
    display: grid;
    grid-gap: 0.8rem;
    grid-template: auto / repeat(2, 1fr);
    width: 100%;
  }

  ${Item} {
    align-items: center;
    display: inline-flex;
    justify-content: center;

    &:nth-of-type(odd):last-of-type {
      grid-column: span 2;
    }

    ${Ball} {
      background: none;
      border-radius: 50%;
      display: inline-block;
      height: 0.8rem;
      margin-right: 0.8rem;
      width: 0.8rem;
    }
  }
`;
