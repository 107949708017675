import React, { useMemo } from 'react';
import PropTypes from '@prop-types';
import { withStyles as css, withTheme } from '@styled-components';
import { compose } from 'underscore';

import Chart, { Legend, Pie } from 'Components/Chart';
import { withPlaceholder } from 'Containers/Placeholder/index';

const MAX = 6;
const REPLACER = '$$';

function groupBy(object, ranges) {
  const data = Object.entries(object);

  return {
    ...data
      .slice(0, ranges - 1)
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}),
    ...(data.length > ranges - 1 && {
      [REPLACER]: data
        .slice(ranges - 1)
        .reduce((acc, [, value]) => acc + value, 0),
    }),
  };
}

function minmax(object) {
  const data = Object.values(object);
  const total = data.reduce((acc, item) => acc + item, 0);

  return data.filter(item => item / total > 0.02).length;
}

function sortObject(object, reverse = false) {
  return Object.entries(object)
    .sort(([, a], [, b]) => (reverse ? a - b : b - a))
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
}

const PieChart = ({
  className,
  data: raw = [],
  onTooltip = v => v,
  theme,
  colors = [
    theme['--color-chart-blue'],
    theme['--color-chart-purple'],
    theme['--color-chart-yellow'],
    theme['--color-chart-orange'],
    theme['--color-chart-dark-blue'],
    theme['--color-chart-dark-blue-60'],
  ],
}) => {
  const data = useMemo(() => {
    let memory = raw;

    memory = sortObject(memory);
    memory = groupBy(memory, Math.min(minmax(memory) + 1, MAX));

    if (memory[REPLACER]) {
      memory.OTHER = memory[REPLACER];
      delete memory[REPLACER];
    }

    return memory;
  }, [raw]);
  const series = Object.entries(data).reduce(
    (acc, [key], index) => [...acc, { color: colors[index], name: key }],
    [],
  );

  const handleTooltip = (value, index) =>
    onTooltip(Object.values(data)[index], index);

  const total = Object.values(data).reduce((acc, item) => acc + item, 0);
  const chart = data;

  if (chart.OTHER) {
    chart.OTHER = Math.round(total * 0.05);
  }

  return (
    <figure className={className}>
      <Chart pie style={{ height: '100%', width: '100%' }}>
        <Pie
          colors={colors}
          data={Object.values(chart)}
          tooltip={handleTooltip}
        />
      </Chart>
      <Legend series={series} />
    </figure>
  );
};

PieChart.propTypes = {
  className: PropTypes.string,
  colors: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.objectOf(PropTypes.number),
  onTooltip: PropTypes.func,
  theme: PropTypes.object,
};

export default compose(
  css`
    align-items: flex-end;
    display: grid;
    grid-gap: 1.6rem;
    grid-template: 1fr auto / 1fr;
    width: 100%;

    ${Legend} {
      display: flex;
    }
  `,
  withTheme,
  withPlaceholder,
)(PieChart);
