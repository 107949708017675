import React from 'react';
import { createIsEmpty } from '@array-utils';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { currentAccount, currency as currenySelector } from 'Selectors';

import DataProvider from 'Containers/DataProvider';

import service from 'Services/campaigns';
import { getUnit } from 'Modules/dates';

import Revenues from './Revenues';

const mapStateToProps = state => ({
  account: currentAccount(state),
  currency: currenySelector(state),
});

const DataProvidedRevenues = ({ account, currency, id, from, to }) => (
  <DataProvider
    currency={currency}
    emptyVerifier={createIsEmpty('sales')}
    params={{
      account,
      currency,
      from,
      id,
      to,
      unit: getUnit(from, to),
    }}
    query={service.getRevenueByTime}
    template={Revenues}
    unit={getUnit(from, to)}
  />
);

DataProvidedRevenues.propTypes = {
  account: PropTypes.number,
  currency: PropTypes.string,
  from: PropTypes.string,
  id: PropTypes.number,
  to: PropTypes.string,
};

DataProvidedRevenues.defaultProps = {
  from: moment()
    .subtract(1, 'year')
    .format(),
  to: moment().format(),
};

export default connect(mapStateToProps)(DataProvidedRevenues);
