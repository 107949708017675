import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@react-intl';
import styled, { theme } from '@styled-components';

import Placeholder, { withPlaceholder } from 'Containers/Placeholder';

import RawAvatar from 'Components/Avatar';
import RawCurrency from 'Components/Currency';
import { Row } from 'Components/ResponsiveTable';

const Avatar = styled(withPlaceholder(RawAvatar))``;
const Currency = styled(withPlaceholder(RawCurrency)).attrs({
  placeholder: '35$',
})``;

const Items = styled(Placeholder).attrs({ placeholder: '35' })``;
const Name = styled(Placeholder).attrs({ placeholder: 'Product name' })``;

const Wrapper = styled('div')``;

const Product = ({
  className,
  currency,
  description,
  empty,
  id,
  image,
  name,
  saleCount,
  unitPrice,
  onClick,
}) => (
  <Row className={className} empty={empty} onClick={onClick(id)}>
    <Wrapper>
      <Avatar fill image={image} name={description} />
      <Name>{name}</Name>
      <Items>
        {saleCount && (
          <FormattedMessage
            capitalize
            id="$SOLD_%NUMBER%_TIMES"
            values={{ number: saleCount }}
          />
        )}
      </Items>
      <Currency currency={currency}>{unitPrice}</Currency>
    </Wrapper>
  </Row>
);

Product.propTypes = {
  className: PropTypes.string,
  currency: PropTypes.string,
  description: PropTypes.string,
  empty: PropTypes.bool,
  id: PropTypes.string,
  image: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  saleCount: PropTypes.number,
  unitPrice: PropTypes.number,
};

export default styled(Product)`
  align-items: center;
  display: flex;
  height: 8rem;

  ${Wrapper} {
    align-items: flex-start;
    display: grid;
    grid-gap: 0.8rem;
    grid-template-columns: auto 1fr auto;
    width: 100%;
  }

  ${Avatar} {
    border-radius: 0.6rem;
    grid-column: 1;
    grid-row: 1 / span 2;
    height: 4rem;
    max-height: 4rem;
    max-width: 4rem;
    min-height: 4rem;
    min-width: 4rem;
  }

  ${Name} {
    ${theme('--font-medium')}
    ${theme('--font-weight-book')}
    color: ${theme('--color-primary')};
    grid-column: 2 / span 2;
    grid-row: 1;
    text-align: left;
  }

  ${Items} {
    ${theme('--font-opacity-40')}
    ${theme('--font-small')}
    ${theme('--font-weight-book')}
    grid-column: 2;
    grid-row: 2;
    text-align: left;
  }

  ${Currency} {
    ${theme('--font-small')}
    ${theme('--font-weight-medium')}
    grid-column: 3;
    grid-row: 2;
    text-align: right;
  }
`;
