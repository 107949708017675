import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@react-intl';
import styled, { theme } from '@styled-components';

import Avatar from 'Components/Avatar';
import Icon from 'Components/Icon';
import Chip from 'Components/Chip';
import Link from 'Components/Link';

import withPermissions from 'Components/with/permissions';

import C from 'Constants';

const {
  ROLE: { ADMIN, SUPER_ADMIN },
} = C;

const Button = styled('button')``;
const Email = styled('span')``;
const Name = styled('span')``;
const Role = styled(Chip)``;
const Status = styled(Chip)``;
const StatusRole = styled('div')``;

const Actions = withPermissions([SUPER_ADMIN, ADMIN])(styled('div')``);

const Member = ({
  className,
  email,
  fullName,
  id,
  images: { small },
  isOwner,
  onClick,
  profileId,
  role,
  status,
  url,
}) => {
  return (
    <article className={className}>
      <Avatar image={small} name={fullName} />
      <Name>{fullName}</Name>
      <Email>{email}</Email>
      <StatusRole>
        <Status>{status}</Status>
        <FormattedMessage component={Role} id={role}>
          {role}
        </FormattedMessage>
      </StatusRole>
      {profileId !== id && !isOwner ? (
        <Actions>
          <Button as={Link} to={`${url}/users/${id}`}>
            <Icon outline name="edit">
              ACTIONS.EDIT_MEMBER
            </Icon>
          </Button>
          <Button onClick={onClick(id, status)}>
            <Icon outline name="trash-bin">
              ACTIONS.REMOVE_MEMBER
            </Icon>
          </Button>
        </Actions>
      ) : (
        <Actions />
      )}
    </article>
  );
};

Member.defaultProps = {
  images: { small: '/images/avatar.jpg' },
  onClick() {},
};

Member.propTypes = {
  className: PropTypes.string,
  email: PropTypes.string,
  fullName: PropTypes.string,
  id: PropTypes.number,
  images: PropTypes.shape({
    small: PropTypes.string,
  }),
  isOwner: PropTypes.bool,
  onClick: PropTypes.func,
  profileId: PropTypes.number,
  role: PropTypes.string,
  status: PropTypes.string,
  url: PropTypes.string,
};

export default styled(Member)`
  align-items: center;
  border-bottom: 0.1rem solid ${theme('--color-dark-night-10')};
  cursor: pointer;
  display: grid;
  grid-column-gap: 1.6rem;
  grid-row-gap: 0.4rem;
  grid-template: 1.6rem 1.6rem auto / 4rem 1fr 6.4rem;
  height: 10.4rem;
  margin: 0 2rem;
  padding: 1.6rem 0;

  ${Avatar} {
    border-radius: 50%;
    grid-column: 1;
    grid-row: 1 / span 2;
    height: 4rem;
    min-height: 4rem;
    min-width: 4rem;
    width: 4rem;
  }

  ${Name} {
    ${theme('--font-medium')}
    ${theme('--font-weight-medium')}
    grid-column: 2;
    grid-row: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ${Email} {
    ${theme('--font-small')}
    ${theme('--font-weight-book')}
    color: ${theme('--color-dark-night-40')};
    grid-column: 2;
    grid-row: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ${StatusRole} {
    display: flex;
    grid-column: 2 / span 2;
    grid-row: 3;
    margin-top: 1.2rem;
  }

  ${Actions} {
    display: flex;
    grid-column: 3;
    grid-row: 1 / span 2;

    ${Button} {
      background: none;
      border: none;
    }
  }

  ${StatusRole} {
    display: flex;
    margin-top: 0.8rem;
  }

  ${Role} {
    margin-left: 0.8rem;
  }

  ${Status} {
    background-color: ${theme('--color-main-20')};
    border: none;
    color: ${theme('--color-main')};
  }
`;
