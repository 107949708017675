import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@react-intl';
import styled, { theme } from '@styled-components';

import Button from 'Components/Button';
import Constants from 'Constants';

const {
  FILTERS,
  FILTERS: { CUSTOM },
} = Constants;

const FilterButton = styled(Button)``;

const Filters = ({
  active,
  className,
  onChange: handleChange,
  showAllTime,
}) => {
  const onClick = ev => {
    handleChange(ev.target.value);
  };

  if (!showAllTime) {
    delete FILTERS.ALL_TIME;
  }

  return (
    <div className={className}>
      {Object.keys(FILTERS).map(
        filter =>
          filter !== CUSTOM && (
            <FormattedMessage
              key={filter}
              capitalize
              component={FilterButton}
              data-active={active === filter}
              id={FILTERS[filter]}
              onClick={onClick}
              value={filter}
            />
          ),
      )}
    </div>
  );
};

Filters.defaultProps = {
  showAllTime: false,
};

Filters.propTypes = {
  active: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  showAllTime: PropTypes.bool,
};

export default styled(Filters)`
  display: grid;
  grid-gap: 0.8rem 0.4rem;
  grid-template: 2.4rem 2.4rem / repeat(4, 1fr);
  padding: 0.8rem 2rem 1.6rem;

  ${FilterButton} {
    ${theme('--font-small')}
    background: ${theme('--color-light')};
    border: 0.1rem solid ${theme('--color-primary-40')};
    color: ${theme('--color-primary')};
    height: 2.4rem;

    &:hover,
    &:focus,
    &[data-active='true'] {
      background: ${theme('--color-primary-20')};
      border-color: ${theme('--color-primary-60')};
    }
  }

  @media screen and (${theme('--screen-small')}) {
    display: flex;
    padding: 2.4rem;

    ${FilterButton} {
      height: auto;
      margin-right: 0.8rem;
      padding: 0.2rem 1.6rem;
    }
  }
`;
