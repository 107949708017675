import React from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from '@prop-types';
import { FormattedMessage } from '@react-intl';

import { Row } from 'Components/ResponsiveTable';
import { PlaceholderNoData as Placeholder } from 'Containers/Placeholder';
import Button from 'Components/Button';

const Wrapper = styled('article')``;

const Label = styled(Placeholder)`
  color: ${props =>
    props.alert
      ? props.theme['--color-alert']
      : props.theme['--color-dark-night-40']};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const Name = styled(Label)``;
const Pic = styled(Placeholder.img).attrs({
  forwardProps: ['src'],
})`
  border: 0.1rem solid ${theme('--color-dark-night-05')};
  border-radius: 0.6rem;
  object-fit: cover;
`;
const Price = styled(Label)``;

const Template = ({
  className,
  empty,
  maxOccupancy,
  id,
  name,
  price,
  images = [],
  onClick: handleClick = () => () => {},
}) => (
  <Button
    as={Row}
    className={className}
    empty={empty}
    onClick={handleClick(id)}
  >
    <Wrapper>
      <Pic key={images[0]} fill src={images[0]} />
      <Name placeholder="variant name">{name}</Name>
      <Label placeholder="variant id">{id}</Label>
      <Price placeholder="price">{price}</Price>
      <FormattedMessage
        component={Label}
        id="%NUMBER%_ADULTS"
        placeholder="max occupancy"
        values={{ number: maxOccupancy }}
      >
        {'{number} adults'}
      </FormattedMessage>
    </Wrapper>
  </Button>
);

Template.propTypes = {
  className: PropTypes.string,
  empty: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  images: PropTypes.arrayOf(PropTypes.string),
  maxOccupancy: PropTypes.number,
  name: PropTypes.string,
  onClick: PropTypes.func,
  price: PropTypes.string,
};

export default styled(Template)`
  width: 100%;

  ${Wrapper} {
    display: grid;
    grid-gap: 0.4rem 0.8rem;
    grid-template: auto / 4rem 1fr auto;
    padding: 1.6rem 0 1.2rem 0;

    ${Label} {
      ${theme('--font-small')}
      ${theme('--font-weight-medium')}
      grid-column: 2 / span 2;
      text-align: left;
    }

    ${Pic} {
      grid-column: 1;
      grid-row: 1 / span 2;
      height: 4rem;
      width: 4rem;
    }

    ${Name} {
      ${theme('--font-medium')}
      ${theme('--font-weight-medium')}
      ${theme('--font-opacity-100')}
    }
  }
`;
