import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from '@prop-types';
import styled, { withStyles as css, theme } from '@styled-components';
import { useIntl } from '@react-intl';
import { connect } from 'react-redux';
import { compose } from 'underscore';

import TableDataProvider from 'Containers/TableDataProvider';
import { useMultiDataProviderContext } from 'Containers/MultiDataProvider';

import Button from 'Components/Button';
import { Input, Select } from 'Components/Form';
import Link from 'Components/Link';

import service from 'Services/microapps';

import { currentAccount } from 'Selectors';

import Template from './MicroappStudio';

const Toolbar = styled('div')``;

const mapStateToProps = state => ({
  account: currentAccount(state),
});

const MicroappStudio = ({ className, account }) => {
  const [filter, setFilterBy] = useState();
  const [options, setOptions] = useState([{ name: 'ALL' }]);
  const [search, setSearch] = useState();
  const intl = useIntl();

  const {
    data: { collection: microapps = [] } = {},
    refetch,
  } = useMultiDataProviderContext('microapps');

  const filters = filter || search;

  const handleSearch = useCallback(
    searchBy => {
      setSearch(searchBy);
    },
    [setSearch],
  );

  const handleFilterBy = useCallback(
    filterBy => {
      setFilterBy(filterBy);
    },
    [setFilterBy],
  );

  const handleDelete = useCallback(
    async id => {
      const del = await service.delete({ account, id });

      if (del && refetch) {
        refetch();
      }
    },
    [account, refetch],
  );

  const mapMicroappToType = ({ type: t }) => t;
  const removeDuplicates = arr => [...new Set(arr)];
  const getUniqueTypes = m =>
    removeDuplicates(m.map(mapMicroappToType)).filter(Boolean);
  const types = getUniqueTypes(microapps);

  useEffect(() => {
    if (types.length >= options.length) {
      setOptions([
        { name: 'ALL' },
        ...types.map(t => ({
          name: intl.formatMessage({ capitalize: true, id: t }),
          value: t,
        })),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [types.length]);

  return (
    <div className={className}>
      <Toolbar>
        <Input
          capitalize
          label="SEARCH"
          name="search"
          onChange={handleSearch}
          type="search"
        />
        <Select
          label="FILTERBY"
          name="filterBy"
          onChange={handleFilterBy}
          options={options}
        />
        <Button
          capitalize
          primary
          translate
          as={Link}
          to="/builder/microapp/add"
        >
          ACTIONS.CREATE_NEW_TEMPLATE
        </Button>
      </Toolbar>
      <TableDataProvider
        account={account}
        filters={filters}
        limit={8}
        name="microapps"
        onDelete={handleDelete}
        params={{
          account,
          filter,
          search,
        }}
        query={service.get}
        template={Template}
      />
    </div>
  );
};

MicroappStudio.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
};

export default compose(
  connect(mapStateToProps),
  css`
    ${Toolbar} {
      display: flex;
      flex-direction: column;
      margin-bottom: 1.6rem;

      ${Button} {
        ${theme('--font-medium')}
        color: ${theme('--color-light')};
        font-weight: 500;
        height: 4rem;
        line-height: 1.6rem;
        margin-bottom: 1.6rem;
        order: 1;
        text-align: center;
      }

      ${Input}, ${Select} {
        margin-bottom: 0.8rem;
        order: 2;
      }

      ${Input} {
        margin-bottom: 0.8rem;
      }
    }

    @media (${theme('--column-12')}) {
      grid-column: span 12;

      ${Toolbar} {
        display: flex;
        flex-direction: row;
        margin-bottom: 4rem;

        ${Input}, ${Select} {
          height: 4.8rem;
          margin-right: 1.6rem;
          order: 1;
          width: 24rem;
        }

        ${Button} {
          height: 4.8rem;
          line-height: 2.4rem;
          margin-left: auto;
          order: 2;
          width: 19rem;
        }
      }

    }
  `,
)(MicroappStudio);
