import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css, theme } from '@styled-components';

import service from 'Services/customers';
import campaignService from 'Services/campaigns';

import Actions from '../Actions';
import SectionHeadline from '../SectionHeadline';
import GroupsPicker from './GroupsPicker';
import MyEditor from './SmsTextArea';
import CampaignSelect from './CampaignSelect';
import MediaUploader from './MediaUploader';

const SelectedMessageTemplate = styled('div')``;
const Title = styled('div')``;
const Description = styled('div')``;
const Video = styled('video')``;
const Preview = styled('div')`
  ${props =>
    props.image &&
    css`
      background-image: url(${props.image});
    `}
`;

const Message = ({
  activateActions,
  account,
  className,
  file,
  fileName,
  message,
  messageTemplatesValue = [],
  onBack,
  onChange,
  onNext,
  selectedMessageTemplate = {},
  type,
}) => {
  const [campaignSelect, setCampaignSelect] = useState({ in: false });
  const [customerGroups, setCustomerGroups] = useState([]);

  useEffect(() => {
    if (type === 'WHATSAPP') {
      service
        .messageTemplates({ account })
        .then(data => setCustomerGroups(data));
    }
  }, [account, type]);

  const handleCampaignModalClose = () => setCampaignSelect({ in: false });

  const handleButtonOptionCampaign = ({ cb }) => {
    setCampaignSelect({
      in: true,
      onAccept: campaignData => {
        handleCampaignModalClose();
        onChange({ messageCampaign: campaignData });
        cb();
      },
    });
  };

  const handleChange = value => onChange({ message: value });

  const handleChangeWhatsapp = (value, idx) => {
    const temp = messageTemplatesValue;
    temp[idx - 1] = value;
    onChange({ messageTemplatesValue: temp });
  };

  const buttonOptions = [
    { name: 'First Name', value: 'firstName' },
    { name: 'Last Name', value: 'lastName' },
    { name: 'Email address', value: 'emailAddress' },
    { name: 'Phone Number', value: 'phoneNumber' },
    {
      name: 'Link to campaign',
      onClick: handleButtonOptionCampaign,
      value: 'campaignLink',
    },
  ];

  const handleTemplate = async value => {
    if (fileName && fileName.length > 1)
      await campaignService.deleteMedia(fileName);

    onChange({
      file: {},
      fileName: '',
      mediaUrl: '',
      selectedMessageTemplate: value,
    });
  };

  const description = [];
  const image = [];
  const templateVariable = [];
  let mediaType = '';

  if (selectedMessageTemplate.components) {
    selectedMessageTemplate.components.forEach(data => {
      if (data.type === 'BODY') description.push(data.text);
      else if (
        data.type === 'HEADER' &&
        ['IMAGE', 'VIDEO'].includes(data.format) &&
        data.example &&
        data.example.header_handle
      ) {
        image.push(data.example.header_handle[0]);
        mediaType = data.format;
      }
    });

    // Check total variable {{}} in message template
    const reg = /(?<=\{\{)([^}]+)(?=\}\})/g;
    const params = description[0].match(reg);
    (params || []).forEach(p => {
      const a = p.substring(1, p.length - 1);
      if (a.indexOf('{') < 0) templateVariable.push(a);
    });
  }

  return (
    <div className={className}>
      <CampaignSelect
        {...campaignSelect}
        account={account}
        onCancel={handleCampaignModalClose}
      />
      {type === 'SMS' && (
        <>
          <SectionHeadline
            heading="INSTRUCTIONS.MESSAGE_HEADING"
            title="INSTRUCTIONS.MESSAGE"
          />
          <MyEditor
            defaultValue={message}
            limit={300}
            onChange={handleChange}
            options={buttonOptions}
            smsPlaceholder="SMS_INPUT_PLACEHOLDER"
            type={type}
          />
        </>
      )}
      {type === 'WHATSAPP' && (
        <>
          <SectionHeadline
            heading="INSTRUCTIONS.MESSAGE_HEADING_WHATSAPP"
            title="INSTRUCTIONS.MESSAGE_WHATSAPP"
          />
          <GroupsPicker
            groups={customerGroups}
            groupsSelected={selectedMessageTemplate}
            onChange={handleTemplate}
          />
          {selectedMessageTemplate.components && (
            <SelectedMessageTemplate>
              <Title>{selectedMessageTemplate.name}</Title>
              <Description>
                {image.length > 0 && (
                  <Preview image={image}>
                    {image && <Video autoPlay loop muted src={image} />}
                  </Preview>
                )}
                <div>{description}</div>
              </Description>
            </SelectedMessageTemplate>
          )}
          {templateVariable.map(idx => (
            <MyEditor
              defaultValue={messageTemplatesValue[idx - 1]}
              index={idx}
              limit={300}
              onChange={handleChangeWhatsapp}
              options={buttonOptions}
              smsPlaceholder="SMS_INPUT_PLACEHOLDER"
              type={type}
            />
          ))}
        </>
      )}
      {image.length > 0 && (
        <MediaUploader
          data={file}
          fileName={fileName}
          mediaType={mediaType}
          onChange={onChange}
        />
      )}
      {activateActions && (
        <Actions disabled={!message} onBack={onBack} onNext={onNext} />
      )}
    </div>
  );
};

Message.propTypes = {
  account: PropTypes.number.isRequired,
  activateActions: PropTypes.bool,
  className: PropTypes.string,
  file: PropTypes.any,
  fileName: PropTypes.string,
  message: PropTypes.string,
  messageTemplatesValue: PropTypes.string,
  onBack: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  selectedMessageTemplate: PropTypes.object,
  type: PropTypes.string,
};

export default styled(Message)`
  ${SectionHeadline} {
    margin-bottom: 1.6rem;
  }

  ${SelectedMessageTemplate} {
    background: #e5efff;
    border-radius: 0.6rem;
    color: #666f75;
    margin: 1.6rem 0;
    padding: 1.6rem;

    ${Title} {
      font-weight: bold;
      margin-bottom: 1rem;
    }

    ${Description} {
      display: grid;
      grid-template-columns: auto 5fr;
      justify-content: space-between;

      ${Preview} {
        background-color: ${theme('--color-primary-10')};
        background-size: cover;
        border: 0.2rem solid ${theme('--color-light')};
        box-shadow: 0 0.2rem 0.8rem rgba(0, 15, 25, 0.05);
        height: 75px;
        margin-right: 1rem;
        overflow: hidden;
        width: 75px;

        ${Video} {
          height: 100%;
          width: 100%;
          z-index: 100;
        }
      }
    }
  }
`;
