import React, { PureComponent } from 'react';

import { rgba } from 'polished';
import PropTypes from 'prop-types';
import styled from '@styled-components';

import List from 'Components/List';

const TopFade = styled('div')``;
const BottomFade = styled('div')``;

export default function withScroll(WrappedComponent) {
  class Scrollable extends PureComponent {
    beforeScrollLeft = 0;

    static displayName = `withScroll(${WrappedComponent.displayName})`;

    static propTypes = {
      className: PropTypes.string,
      endFade: PropTypes.bool,
      onScroll: PropTypes.func,
      onScrollEnd: PropTypes.func,
      style: PropTypes.objectOf(PropTypes.string),
    };

    static defaultProps = {
      onScroll() {},
      onScrollEnd() {},
    };

    state = { end: true, start: false };

    constructor(props) {
      super(props);

      this.element = React.createRef();
    }

    componentDidMount() {
      window.requestAnimationFrame(() => {
        if (!this.element.current) {
          return;
        }

        const { offsetHeight, scrollHeight, scrollTop } = this.element.current;

        this.setState({
          end: offsetHeight + scrollTop < scrollHeight - 1,
          start: scrollTop !== 0,
        });
      });
    }

    componentDidUpdate() {
      window.requestAnimationFrame(() => {
        if (!this.element.current) {
          return;
        }

        const { offsetHeight, scrollHeight, scrollTop } = this.element.current;

        this.setState({
          end: offsetHeight + scrollTop < scrollHeight - 1,
          start: scrollTop !== 0,
        });
      });
    }

    handleScroll = event => {
      const { onScroll, onScrollEnd } = this.props;

      const { offsetHeight, scrollHeight, scrollTop } = event.target;

      onScroll();

      if (offsetHeight + scrollTop >= scrollHeight) {
        onScrollEnd();
      }

      this.setState({
        end: offsetHeight + scrollTop < scrollHeight - 1,
        start: scrollTop !== 0,
      });
    };

    render() {
      const {
        className,
        onScroll,
        onScrollEnd,
        style,
        endFade,
        ...props
      } = this.props;
      const { end, start } = this.state;

      return (
        <div className={className}>
          <TopFade data-visible={start} />
          <WrappedComponent
            ref={this.element}
            onScroll={this.handleScroll}
            {...props}
            style={style}
          />
          <BottomFade data-visible={endFade || end} />
        </div>
      );
    }
  }

  return styled(Scrollable)`
    position: relative;

    ${List} {
      height: 100%;
      overflow: scroll;
    }

    ${TopFade}, ${BottomFade} {
      height: 10rem;
      left: 0;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      transition: opacity 250ms ease;
      width: 100%;
      z-index: 20;

      &[data-visible='true'] {
        opacity: 1;
      }
    }

    ${TopFade} {
      background: linear-gradient(
        to top,
        ${rgba(255, 255, 255, 0.0001)} 0%,
        #ffffff 100%
      );
      top: 0;
    }

    ${BottomFade} {
      background: linear-gradient(
        to bottom,
        ${rgba(255, 255, 255, 0.0001)} 0%,
        #ffffff 100%
      );
      bottom: 0;
    }
  `;
}
