import React from 'react';
import PropTypes from '@prop-types';
import {
  FormattedMessage,
  FormattedRelative,
  FormattedDate,
  intlDefault,
  intlShape,
  withIntl,
} from '@react-intl';
import { compose } from 'redux';
import styled, { withStyles as css, theme } from '@styled-components';
import { name as getName } from '@langs';

import Placeholder, { withPlaceholder } from 'Containers/Placeholder';

import RawAvatar from 'Components/Avatar';
import RawPlatforms from 'Components/Platforms';
import Summary from 'Components/Summary';

import InformationCard from 'Components/InformationCard';
import EmptyBar from 'Components/EmptyBar';
import Datum from './Datum';

const Avatar = styled(withPlaceholder(RawAvatar))``;
const Platforms = styled(withPlaceholder(RawPlatforms))``;

const Basic = styled('section')``;
const Details = styled('dl')``;

const Name = styled(Placeholder.h3).attrs({
  placeholder: 'first name and last name',
})``;
const Header = styled('section')``;
const Capitalize = styled(Placeholder).attrs({
  placeholder: 'firstame lastname',
})``;

const Desktop = styled('div')``;
const Mobile = styled('div')``;

const PlatformDatum = styled(Datum).attrs({
  definition: 'platform',
  title: 'PLATFORM',
})``;
const LocaleDatum = styled(Datum).attrs({
  definition: 'locale',
  title: 'LOCALE',
})``;
const TimezoneDatum = styled(Datum).attrs({
  definition: 'timezone',
  title: 'TIMEZONE',
})``;
const GenderDatum = styled(Datum).attrs({
  definition: 'gender',
  title: 'GENDER',
})``;
const ActivityDatum = styled(Datum).attrs({
  definition: 'last activity',
  title: 'LAST_ACTIVITY',
})``;

const CustomerSummary = styled(Summary).attrs({ accent: true })``;
const RegularSummary = styled('div')``;

export const Profile = ({
  className,
  createdAt = new Date(),
  gender,
  images: { large: image } = {},
  intl = intlDefault,
  locale,
  location,
  name,
  platform = [],
  stats: {
    averageOrder: { value: averageOrder = 0, currency = 'USD' } = {},
    orders: totalOrders = 0,
    lastOrderDate: lastOrder = 0,
    totalSales: { value: totalSales = 0, totalSalesCurrency = currency } = {},
  } = {},
  timezone,
  updatedAt,
}) => {
  const entries = [
    {
      label: 'LIFETIME_SPENT',
      options: {
        currency: totalSalesCurrency,
      },
      type: 'currency',
      value: totalSales,
    },
    {
      label: 'TOTAL_ORDERS',
      value: totalOrders,
    },
    {
      label: 'AVERAGE_ORDER',
      options: {
        currency,
      },
      type: 'currency',
      value: averageOrder,
    },
    {
      label: 'LAST_ORDER',
      options: {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
      },
      type: 'date',
      value: lastOrder || undefined,
    },
  ];
  const nameIsEmpty = name.replace(/\s/g, '').length === 0;

  return (
    <section className={className}>
      <Header>
        <Avatar fill image={image} name={name} />
        <Basic>
          <Name>
            {!nameIsEmpty ? name : <EmptyBar>customer name</EmptyBar>}
          </Name>
          {location ? (
            <Capitalize>{location}</Capitalize>
          ) : (
            <FormattedMessage component={Capitalize} id="UNKNOWN_LOCATION">
              No known location
            </FormattedMessage>
          )}
          <div>
            <FormattedMessage
              component={Capitalize}
              id="CUSTOMER_SINCE_%DATE%"
              values={{
                date: intl.formatDate(createdAt, {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric',
                }),
              }}
            >
              {'Customer from {date}'}
            </FormattedMessage>
          </div>
        </Basic>
      </Header>
      <Details>
        <PlatformDatum empty={platform.length === 0}>
          <Platforms fill data={platform} />
        </PlatformDatum>
        <LocaleDatum>{locale && getName(locale)}</LocaleDatum>
        <TimezoneDatum>
          {timezone && (
            <FormattedDate
              hour="numeric"
              minute="numeric"
              timeZone={timezone}
              timeZoneName="short"
              value={new Date()}
            />
          )}
        </TimezoneDatum>
        <GenderDatum>{gender}</GenderDatum>
        <ActivityDatum>
          <FormattedRelative value={updatedAt} />
        </ActivityDatum>
      </Details>
      <CustomerSummary
        currency={totalSalesCurrency}
        heading={totalSales}
        title="LIFETIME_SPENT"
      />
      <RegularSummary>
        <Desktop>
          <InformationCard entries={entries.slice(1)} />
        </Desktop>
        <Mobile>
          <InformationCard entries={entries} />
        </Mobile>
      </RegularSummary>
    </section>
  );
};

Profile.propTypes = {
  address: PropTypes.shape({
    address: PropTypes.string,
    city: PropTypes.string,
    company: PropTypes.string,
    country: PropTypes.string,
    name: PropTypes.string,
    region: PropTypes.string,
    zip: PropTypes.string,
  }),
  className: PropTypes.string,
  createdAt: PropTypes.instanceOf(Date),
  gender: PropTypes.string,
  images: PropTypes.shape({
    large: PropTypes.string,
  }),
  intl: intlShape,
  locale: PropTypes.string,
  location: PropTypes.string,
  name: PropTypes.string,
  platform: PropTypes.arrayOf(PropTypes.string),
  stats: PropTypes.shape({
    averageOrder: PropTypes.shape({
      currency: PropTypes.string,
      value: PropTypes.number,
    }),
    lastOrderDate: PropTypes.instanceOf(Date),
    orders: PropTypes.number,
    totalCustomers: PropTypes.number,
  }),
  timezone: PropTypes.string,
  updatedAt: PropTypes.instanceOf(Date),
};

export default compose(
  withIntl,
  css`
    display: grid;
    grid-gap: 2.4rem;

    ${Desktop} {
      display: none;
    }

    ${Header} {
      align-items: center;
      display: flex;
      grid-row: 1;

      ${Avatar} {
        border-radius: 50%;
        box-shadow: 0 0.8rem 1.6rem rgba(0, 15, 25, 0.05),
          0 0.1rem 0 rgba(0, 15, 25, 0.05);
        min-height: 8rem;
        min-width: 8rem;
      }

      ${Basic} {
        ${theme('--font-medium')}
        ${theme('--font-weight-book')}
        ${theme('--color-dark-night-60')}
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 1.6rem;

        ${Name} {
          ${theme('--font-xlarge')}
          ${theme('--font-weight-medium')}
          margin-bottom: 1.2rem;
          max-width: 20rem;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        ${Capitalize} {
          &::first-letter {
            text-transform: Capitalize;
          }

          &:first-child {
            margin-bottom: 0;
          }

          color: ${theme('--color-dark-night-40')};
          margin-bottom: 0.8rem;
          text-transform: Capitalize;
        }
      }
    }

    ${Details} {
      display: grid;
      grid-gap: 1.6rem 3.2rem;
      grid-row: 2;
      grid-template: auto auto/ 1fr 1fr;
      margin: 0;

      ${PlatformDatum} {
        grid-column: 1;
        grid-row: 1;
      }

      ${LocaleDatum} {
        grid-column: 2;
        grid-row: 1;
      }

      ${ActivityDatum} {
        grid-column: 1 / span 2;
        grid-row: 3;
      }

      ${GenderDatum} {
        grid-column: 1;
        grid-row: 2;
        text-transform: capitalize;
      }

      ${TimezoneDatum} {
        grid-column: 2;
        grid-row: 2;
      }
    }

    ${CustomerSummary} {
      display: none;
    }

    ${RegularSummary} {
      grid-row: 3;
    }

    ${InformationCard} {
      padding: 2.4rem;
    }

    @media (${theme('--screen-medium')}) {
      grid-gap: 3.2rem;
      grid-template-columns: repeat(3, 1fr);

      ${Desktop} {
        display: block;
      }

      ${Mobile} {
        display: none;
      }

      ${Header} {
        grid-column: 1 / span 2;

        ${Avatar} {
          border: 1rem solid white;
          min-height: 13.6rem;
          min-width: 13.6rem;
        }

        ${Basic} {
          margin-left: 3.2rem;

          ${Name} {
            ${theme('--font-xxlarge')}
            max-width: 45rem;
          }
        }
      }

      ${Details} {
        grid-column: 1 / span 2;
        grid-template: auto / repeat(4, 1fr);

        ${TimezoneDatum} {
          grid-column: 3 / span 2;
          grid-row: 1;
        }

        ${ActivityDatum} {
          grid-column: 2;
          grid-row: 2;
        }
      }

      ${CustomerSummary} {
        display: block;
        grid-column: 3;
        grid-row: 1;
      }

      ${RegularSummary} {
        grid-column: 3;
        grid-row: 2;
      }
    }
  `,
)(Profile);
