import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, theme } from '@styled-components';

import Placeholder from 'Containers/Placeholder';

import Controls from './Controls';

const Total = styled(Placeholder)``;
const NoData = styled('span')``;
const Empty = styled(({ className }) => (
  <NoData capitalize translate className={className}>
    NO_TABLE_DATA
  </NoData>
))``;

const Foot = ({
  className,
  cursor,
  isDesktop,
  onCursorChange = () => {},
  pages,
  rows = 4,
  total,
}) => {
  const from = cursor * rows + 1;
  const hasControls = total > rows;

  return (
    <section className={className} data-controls={hasControls && !isDesktop}>
      <Total
        translate
        emptyTemplate={Empty}
        placeholder="adding loading state"
        values={{
          from,
          to: Math.min(from + rows - 1, total),
          total,
        }}
      >
        %FROM%_%TO%_OUT_OF_%TOTAL%
      </Total>
      {hasControls && (
        <Controls
          cursor={cursor}
          onChange={onCursorChange}
          pages={pages}
          total={Math.ceil(total / rows)}
        />
      )}
    </section>
  );
};

Foot.propTypes = {
  className: PropTypes.string,
  cursor: PropTypes.number.isRequired,
  isDesktop: PropTypes.bool,
  onCursorChange: PropTypes.func,
  pages: PropTypes.number.isRequired,
  rows: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

const desktopMixin = ({ isDesktop = true }) =>
  isDesktop &&
  css`
    flex-direction: row;
    height: 4.8rem;
    justify-content: space-between;
    padding: 0.8rem 2.4rem;

    ${Total} {
      margin-bottom: 0;
    }
  `;

export default styled(Foot)`
  ${theme('--font-small')}
  ${theme('--font-opacity-40')}
  ${theme('--font-weight-book')}
  align-items: center;
  border-top: 0.1rem solid ${theme('--color-dark-night-10')};
  display: flex;
  flex-direction: column;
  height: 8rem;
  justify-content: center;
  padding: 1.1rem 2.4rem 1.3rem;

  &[data-controls='true'] {
    ${Total} {
      margin-bottom: 0.8rem;
    }
  }

  ${desktopMixin}
`;
