import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@styled-components';
import { uncontrollable } from 'uncontrollable';

import Body from './Body';
import Head from './Head';
import Foot from './Foot';

// This helper should return comparable values in form of strings/numbers
const parseValue = value => value;

class Table extends Component {
  static propTypes = {
    className: PropTypes.string,
    cursor: PropTypes.number,
    emptyTemplate: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    header: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    items: PropTypes.array,
    limit: PropTypes.number,
    onCursorChange: PropTypes.func,
    onOrderBy: PropTypes.func,
    order: PropTypes.string,
    pages: PropTypes.number,
    rows: PropTypes.number,
    sortBy: PropTypes.string,
    template: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    theme: PropTypes.func,
    total: PropTypes.number,
  };

  static defaultProps = {
    cursor: 0,
    items: [],
    onCursorChange() {},
  };

  state = {
    order: this.props.order,
    sortBy: this.props.sortBy,
  };

  onSortBy = key => {
    const { order, sortBy } = this.state;

    const alreadySortedBy = sortBy === key;
    const alreadyAscending = order === 'ascending';

    let mod = {};

    if (!sortBy || sortBy !== key) {
      mod = {
        order: 'descending',
        sortBy: key,
      };
    } else if (alreadySortedBy && !alreadyAscending) {
      mod = {
        order: 'ascending',
      };
    } else if (alreadySortedBy && alreadyAscending) {
      mod = {
        order: null,
        sortBy: null,
      };
    }

    this.setState({ ...mod }, () => {
      const { onOrderBy } = this.props;
      if (onOrderBy) onOrderBy(this.state.sortBy, this.state.order);
    });
  };

  getSortedData = () => {
    const { items } = this.props;
    const { sortBy, order } = this.state;

    return sortBy
      ? [...items].sort((a, b) => {
          const valueA = parseValue(a[sortBy]);
          const valueB = parseValue(b[sortBy]);
          const orderMod = order === 'ascending' ? -1 : 1;

          if (!valueA) return -orderMod;
          if (!valueB) return orderMod;

          return (valueA > valueB ? 1 : -1) * orderMod;
        })
      : items;
  };

  render() {
    const {
      className,
      cursor,
      header: Header,
      template,
      items,
      emptyTemplate,
      theme,
      onCursorChange: hadleCursorChange,
      onOrderBy: onOrderByIgnore,
      pages,
      rows: isPaginable,
      rows = items.length,
      sortBy: sortByIgnore,
      template: Template,
      ...props
    } = this.props;
    const { sortBy, order } = this.state;
    const { onSortBy } = this;

    const sorted = this.getSortedData();
    const total = sorted.length;

    const from = cursor * rows;
    const to = Math.min(from + rows, total);

    const paginated = sorted.slice(from, to);

    const currentItems = [
      ...paginated,
      ...(paginated.length < rows
        ? [...Array(rows - paginated.length).fill({ empty: true })]
        : []),
    ];

    return (
      <section className={className}>
        {Header && (
          <Head
            {...props}
            onSortBy={onSortBy}
            order={order}
            sortBy={sortBy}
            template={Header}
          />
        )}
        <Body
          {...props}
          emptyTemplate={emptyTemplate}
          items={currentItems}
          template={Template}
        />
        {isPaginable && (
          <Foot
            {...props}
            cursor={cursor}
            from={from}
            onCursorChange={hadleCursorChange}
            pages={pages}
            rows={rows}
            to={to}
            total={total}
          />
        )}
      </section>
    );
  }
}

export { default as Body } from './Body';
export { default as Head } from './Head';
export { default as Row } from './Row';
export { default as Foot } from './Foot';
export { Column, SortableColumn } from './Column';

export default styled(uncontrollable(Table, { cursor: 'onCursorChange' }))`
  display: grid;
  grid-template-rows: 1fr auto;
`;
