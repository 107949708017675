import React from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from '@prop-types';
import { FormattedMessage } from '@react-intl';
import EmptyBar from 'Components/EmptyBar';

import { withPlaceholder } from 'Containers/Placeholder';

const Definition = styled('dt')``;
const Description = styled(withPlaceholder('dd')).attrs({
  placeholder: 'datum value',
})``;

const Datum = ({ title, definition = title, className, empty, children }) => {
  const isEmpty = React.Children.toArray(children).length === 0;

  return (
    <div className={className}>
      <FormattedMessage capitalize component={Definition} id={title}>
        {definition}
      </FormattedMessage>
      {!isEmpty && !empty ? (
        <Description>{children}</Description>
      ) : (
        <EmptyBar>{title}</EmptyBar>
      )}
    </div>
  );
};

Datum.propTypes = {
  children: PropTypes.children,
  className: PropTypes.string.isRequired,
  definition: PropTypes.string,
  empty: PropTypes.bool,
  title: PropTypes.string.isRequired,
};
export default styled(Datum)`
  margin: 0;

  ${Definition} {
    ${theme('--font-medium')}
    color: ${theme('--color-dark-night-40')};
    margin-bottom: 1.2rem;
  }

  ${Description} {
    ${theme('--font-large')}
    ${theme('--font-weight-medium')}
    color: ${theme('--color-dark-night')};
    margin: 0;
  }
`;
