import request from 'Providers/request';

const TYPES = {
  FAST_SALE: 'FASTSALE',
  MEDIA: 'MEDIA',
  MULTI_PROD: 'MULTIPRODUCTS',
  SIGNUP: 'SIGNUP',
};

function create({ account, fields }) {
  return request.create(`/ofa/${account}/microapps`, fields);
}

function createTest({ account, fields }) {
  return request.create(`/ofa/${account}/microapps-test`, fields);
}

function del({ account, id }) {
  return request.delete(`/ofa/${account}/microapps/${id}`);
}

async function get({ account, filter, limit, offset, search }) {
  const { collection = [], total } = await request.read(
    `/ofa/${account}/microapps`,
    {
      limit,
      offset,
      ...(filter && { filter }),
      ...(search && { search }),
    },
  );

  return {
    collection: collection.map(({ type, ...rest }) => ({
      ...rest,
      type: TYPES[type],
    })),
    total,
  };
}

async function getById({ account, id }) {
  const { media, products, ratio, ...microapp } = await request.read(
    `/ofa/${account}/microapps/${id}`,
  );

  return {
    ...microapp,
    media: media?.map(({ image, ...rest }) => ({
      ...rest,
      source: image,
    })),
    products: products?.map(({ productId }) => Number(productId)),
    ratio: ratio?.toLowerCase(),
  };
}

function update({ account, fields }) {
  return request.update(`/ofa/${account}/microapps/${fields.id}`, fields);
}

export default {
  create,
  createTest,
  delete: del,
  get,
  getById,
  update,
};
