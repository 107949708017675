import React from 'react';
import PropTypes from 'prop-types';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { account } from 'Selectors';

export function checkPermissions() {
  return WrappedComponent => {
    const SurveyComponent = ({ surveysDisabled, ...props }) =>
      surveysDisabled !== true ? <WrappedComponent {...props} /> : false;

    SurveyComponent.displayName = `checkSurvey(${WrappedComponent.displayName ||
      WrappedComponent.name})`;
    SurveyComponent.propTypes = {
      surveysDisabled: PropTypes.bool,
    };
    return hoistNonReactStatics(SurveyComponent, WrappedComponent);
  };
}

export default function withSurveys(WrappedComponent) {
  const ComposedComponent = compose(
    connect(state => ({ surveysDisabled: account(state).surveysDisabled })),
    checkPermissions(),
  )(WrappedComponent);

  ComposedComponent.displayName = `compose(${WrappedComponent.displayName ||
    WrappedComponent.name})`;

  function WithSurveys(props) {
    return <ComposedComponent {...props} />;
  }
  WithSurveys.displayName = `withSurveys(${ComposedComponent.displayName})`;
  return hoistNonReactStatics(WithSurveys, ComposedComponent);
}
