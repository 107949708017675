/* eslint-disable */
import { rgba } from 'polished';

export default {
  // Primary pallete
  '--color-main': '#9500ff',
  '--color-main-20': rgba('#9500ff', 0.2),
  '--color-primary': '#005dff',
  '--color-primary-5': rgba('#005dff', 0.05),
  '--color-primary-10': rgba('#005dff', 0.1),
  '--color-primary-20': rgba('#005dff', 0.2),
  '--color-primary-40': rgba('#005dff', 0.4),
  '--color-primary-50': rgba('#005dff', 0.5),
  '--color-primary-80': rgba('#005dff', 0.8),
  '--color-primary-120': '#004dd1',
  '--color-secondary': '#02c0f9',

  // Secondary palette
  '--color-blueish-white': '#f5f7fa',
  '--color-blueish-white-120': '#eef1f6',
  '--color-blueish-white-140': '#e7eaef',
  '--color-dark': '#003566',
  '--color-dark-night': '#000f19',
  '--color-dark-night-05': '#f2f3f4',
  '--color-dark-night-10': '#e5e7e8',
  '--color-dark-night-20': '#cccfd1',
  '--color-dark-night-40': rgba('#000f19', 0.4),
  '--color-dark-night-60': '#666f75',
  '--color-dark-night-80': '#333f47',
  '--color-light': '#ffffff',

  // Facebook palette */
  '--color-alert': '#f03d25',
  '--color-alert-20': '#f6d4d2', // the opacity is problematic for snackbars
  '--color-success': '#4bcc1f',
  '--color-success-20': '#d7efd2', // the opacity is problematic for snackbars
  '--color-trust': '#005dff',

  // Chart palette */
  '--color-chart-blue': '#02c0f9',
  '--color-chart-purple': '#9500ff',
  '--color-chart-purple-80': '#ab33ff',
  '--color-chart-yellow': '#ffc532',
  '--color-chart-orange': '#f89c70',
  '--color-chart-dark-blue': '#003566',
  '--color-chart-dark-blue-60': '#683bfd',

  '--color-white-40': '#a3a9ac',
};
