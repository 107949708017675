import React from 'react';
import styled from '@styled-components';
import PropTypes from '@prop-types';

import RadioType from './RadioType';

const TypeSelector = ({ className }) => {
  return (
    <div className={className}>
      <RadioType id="VIDEO" name="type" />
      <RadioType id="IMAGE" name="type" />
    </div>
  );
};

TypeSelector.propTypes = {
  className: PropTypes.string,
};

export default styled(TypeSelector)`
  display: grid;
  grid-gap: 0.8rem;
  grid-template: 5.4rem 5.4rem / 1fr;
  margin-bottom: 2rem;
  padding: 0.8rem 0;
`;
