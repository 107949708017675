import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isLoggedIn } from 'Selectors';

import { recoverPassword } from 'Actions/session';

import RecoverPassword from './RecoverPassword';

const mapDispatchToProps = dispatch =>
  bindActionCreators({ recoverPassword }, dispatch);

const mapStateToProps = state => ({
  isLoggedIn: isLoggedIn(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecoverPassword);
