import C from 'Constants';

import memoryStorage from './memoryStorage';

const providers = {
  localStorage,
  sessionStorage,
};

export class Storage {
  provider = localStorage;

  constructor() {
    try {
      this.set('check', true);
      if (this.get('check') !== true) {
        this.provider = memoryStorage;
        this.remove('check');
      }
    } catch (e) {
      this.provider = memoryStorage;
    }
  }

  setProvider(provider) {
    this.provider = providers[provider] || localStorage;
    this.migrate();
  }

  migrate() {
    if (this.provider !== sessionStorage) return;
    const localStorageProvider = new Storage();
    this.set(C.STORE, localStorageProvider.get(C.STORE));
    localStorageProvider.clear();
  }

  clear() {
    this.provider.clear();
  }

  get(key) {
    try {
      const item = this.provider.getItem(key);
      if (item === undefined) return item;
      return JSON.parse(item);
    } catch (e) {
      return undefined;
    }
  }

  keys() {
    const keys = [];

    for (let i = 0; i < this.provider.length; i += 1) {
      keys.push(this.provider.key(i));
    }

    return keys;
  }

  remove(key) {
    this.provider.removeItem(key);
  }

  set(key, value) {
    this.provider.setItem(key, JSON.stringify(value));
  }
}

export default new Storage();
