import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from '@prop-types';
import styled, { theme } from '@styled-components';

import Bubble, { Arrow, BUBBLE_WIDTH } from './Bubble';
import OverlayWrapper from './Overlay';
import { Button } from './Components';

export { Title, Paragraph, Button } from './Components';

const Tip = ({ className, children, buttonId, overlay: Overlay }) => {
  const ref = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [layoutData, setLayoutData] = useState({});
  const [showOverlay, setShowOverlay] = useState(false);

  const openOverlay = useCallback(() => setShowOverlay(true), []);
  const closeOverlay = useCallback(() => setShowOverlay(false), []);

  const updatePosition = useCallback(() => {
    const { x, y, width: size } = ref.current.getBoundingClientRect();
    const { width, height } = document.body.getBoundingClientRect();
    const horizontalAnchor = x > width / 2 ? 'right' : 'left';
    const verticalAnchor = y > height / 2 ? 'bottom' : 'top';
    const pureVertical = x < width - BUBBLE_WIDTH / 2 && x > BUBBLE_WIDTH / 2;

    const nextAnchor = pureVertical
      ? verticalAnchor
      : `${horizontalAnchor}-${verticalAnchor}`;
    setLayoutData({ anchor: nextAnchor, tipSize: size, x, y });
  }, []);

  useEffect(updatePosition, []);

  const onMouseOver = () => {
    updatePosition();
    setIsHovered(true);
  };
  const onMouseOut = () => setIsHovered(false);

  return (
    <>
      <button
        ref={ref}
        className={className}
        onBlur={onMouseOut}
        onFocus={onMouseOver}
        onMouseOut={onMouseOut}
        onMouseOver={onMouseOver}
        type="button"
      >
        ?
        <Arrow data-anchor={layoutData.anchor} data-visible={isHovered} />
        <Bubble data-visible={isHovered} {...layoutData}>
          {children}
          {buttonId && Overlay && (
            <Button onClick={openOverlay}>{buttonId}</Button>
          )}
        </Bubble>
      </button>
      {showOverlay && (
        <OverlayWrapper requestClose={closeOverlay}>
          <Overlay />
        </OverlayWrapper>
      )}
    </>
  );
};

Tip.propTypes = {
  buttonId: PropTypes.string,
  children: PropTypes.children,
  className: PropTypes.string,
  overlay: PropTypes.component,
};

export default styled(Tip)`
  background: ${theme('--color-dark-night-10')};
  border: none;
  border-radius: 50%;
  color: ${theme('--color-dark-night-40')};
  font-size: 90%;
  height: 2.4rem;
  outline: none;
  padding: 0.3rem;
  position: relative;
  width: 2.4rem;
`;
