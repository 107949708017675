import React from 'react';
import PropTypes from 'prop-types';
import { withStyles as css, theme } from '@styled-components';
import { compose } from 'underscore';

import { withFetchingState } from 'Containers/Placeholder';

import Profile from './components/Profile';
import Timeline from './components/Timeline';
import PersonalInformation from './components/PersonalInformation';
import PlatformChart from './components/PlatformChart';
import Campaigns from './components/Campaigns';
import Surveys from './components/Surveys';
import Map from './components/Map';
import Notes from './components/Notes';

export const Customer = ({
  className,
  data: {
    createdAt,
    deliveryAddress,
    email,
    firstName,
    gender,
    geoInfo,
    groups,
    id,
    images,
    lastActivity,
    lastName,
    locale,
    location,
    notes,
    phone,
    platforms,
    purchaseStats,
    timezone,
  } = {},
  loading,
}) => (
  <section className={className}>
    <Profile
      createdAt={createdAt}
      gender={gender}
      images={images}
      locale={locale}
      location={location}
      name={`${firstName} ${lastName}`}
      platform={platforms}
      stats={purchaseStats}
      timezone={timezone}
      updatedAt={lastActivity}
    />
    {id && <Timeline id={id} timezone={timezone} />}
    <PersonalInformation
      address={deliveryAddress}
      email={email}
      groups={groups}
      location={location}
      phone={phone}
    />
    {(loading || notes) && <Notes notes={notes} />}
    <Campaigns id={id} />
    <Surveys customerId={id} />
    <Map markers={geoInfo} />
    {id && <PlatformChart id={id} />}
  </section>
);

Customer.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    deliveryAddress: PropTypes.shape({
      address: PropTypes.string,
      city: PropTypes.string,
      company: PropTypes.string,
      country: PropTypes.string,
      name: PropTypes.string,
      postalCode: PropTypes.string,
      region: PropTypes.string,
    }),
    email: PropTypes.string,
    firstName: PropTypes.string,
    gender: PropTypes.string,
    geoInfo: PropTypes.arrayOf(
      PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
      }),
    ),
    groups: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
    id: PropTypes.number,
    images: PropTypes.objectOf(PropTypes.string),
    lastActivity: PropTypes.instanceOf(Date),
    lastName: PropTypes.string,
    loading: PropTypes.bool,
    locale: PropTypes.string,
    location: PropTypes.string,
    notes: PropTypes.string,
    phone: PropTypes.string,
    platforms: PropTypes.arrayOf(PropTypes.string),
    purchaseStats: PropTypes.shape({
      averageOrder: PropTypes.shape({
        currency: PropTypes.string,
        value: PropTypes.number,
      }),
      lastOrder: PropTypes.instanceOf(Date),
      orders: PropTypes.number,
      totalSales: PropTypes.shape({
        currency: PropTypes.string,
        value: PropTypes.number,
      }),
    }),
    timezone: PropTypes.string,
  }),
  loading: PropTypes.bool,
};

export default compose(
  withFetchingState,
  css`
    display: grid;
    grid-gap: 2.4rem 1.6rem;
    grid-template: 1fr / 1fr;

    @media (${theme('--screen-small')}) {
      grid-gap: 3.2rem;
      grid-template-columns: repeat(3, 1fr);

      ${Profile} {
        grid-column: 1 / span 3;
      }

      ${Campaigns} {
        grid-column: 1 / span 3;
      }

      ${Surveys} {
        grid-column: 1 / span 3;
      }

      ${Timeline} {
        grid-column: 1 /
          ${props => (props.loading || props.notes ? 'span 1' : 'span 2')};
      }

      ${Map} {
        grid-column: 1 / span 2;
      }
    }
  `,
)(Customer);
