import React from 'react';
import PropTypes from 'prop-types';
import styled from '@styled-components';

import { Row, SortableColumn, Column } from 'Components/ResponsiveTable';

import withPermissions from 'Components/with/permissions';

import C from 'Constants';

const {
  ROLE: { ADMIN, SUPER_ADMIN },
} = C;

const Actions = withPermissions([SUPER_ADMIN, ADMIN])(styled('span')``);

const Header = ({ className, ...common }) => (
  <Row className={className}>
    <SortableColumn field="fullName" id="USER" {...common} />
    <SortableColumn field="email" id="EMAIL" {...common} />
    <Column id="STATUS" />
    <Column id="ROLE" />
    <Actions />
  </Row>
);

Header.propTypes = {
  className: PropTypes.string,
};

export default styled(Header)`
  grid-template-columns: 1fr 1fr 9rem 9rem 9rem;
  padding: 1.2rem 2.4rem 0.8rem 2.4rem;
`;
