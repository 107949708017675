import React from 'react';
import PropTypes from 'prop-types';
import styled from '@styled-components';

export const Item = ({ children, className, ...props }) => (
  <li className={className} {...props}>
    {children}
  </li>
);

Item.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
};

export default styled(Item)`
  margin: 0;
  padding: 0;
`;
