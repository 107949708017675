import React from 'react';
import PropTypes from '@prop-types';
import { FormattedDate, FormattedTime } from '@react-intl';

const FormattedDateUnit = ({ date, unit }) => {
  let DateFormatted = null;

  if (unit === 'hour') {
    DateFormatted = (
      <FormattedTime hour12 day="2-digit" month="long" value={date} />
    );
  } else if (unit === 'day') {
    DateFormatted = (
      <FormattedDate day="2-digit" month="long" value={date} year="numeric" />
    );
  } else {
    DateFormatted = <FormattedDate month="long" value={date} year="numeric" />;
  }

  return DateFormatted;
};

FormattedDateUnit.propTypes = {
  date: PropTypes.object,
  unit: PropTypes.string,
};

export default FormattedDateUnit;
