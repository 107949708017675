import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { get, set } from 'Actions/accounts';
import { accounts, currentAccount } from 'Selectors';

import OfficialAccounts from './OfficialAccounts';

const mapStateToProps = state => ({
  account: currentAccount(state),
  officialAccounts: accounts(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ get, set }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OfficialAccounts);
