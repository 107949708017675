import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import moment from 'moment';

import { currentAccount } from 'Selectors';

import * as logger from 'Actions/logger';

import Modal from 'Components/Modal';
import { Redirect, withRouter } from 'Components/Router';

import DataProvider from 'Containers/DataProvider';

import service from 'Services/surveys';

import View from './Survey';

const mapStateToProps = state => ({ account: currentAccount(state) });

const Survey = ({
  account,
  dataKey,
  depth,
  error,
  history,
  id,
  in: modalIn,
  onClose,
  onBack,
  order,
  success,
  ...props
}) => {
  const [dates, setDates] = useState({
    from: moment()
      .subtract(1, 'year')
      .format(),
    to: moment().format(),
  });

  const handleDateChange = (range, from, to) => setDates({ from, to });

  return (
    <Modal
      key={dataKey}
      depth={depth}
      in={modalIn}
      onBack={onBack}
      onClose={onClose}
      order={order}
      show={modalIn}
      title="Survey"
    >
      <DataProvider
        account={account}
        dates={dates}
        emptyTemplate={() => <Redirect to="/surveys" />}
        handleDateChange={handleDateChange}
        params={{ account, id, ...dates }}
        query={service.getById}
        surveyId={id}
        template={View}
        {...props}
      />
    </Modal>
  );
};

Survey.defaultProps = {
  onClose() {},
};

Survey.propTypes = {
  account: PropTypes.number,
  dataKey: PropTypes.string,
  depth: PropTypes.number,
  error: PropTypes.func,
  history: PropTypes.object,
  id: PropTypes.string.isRequired,
  in: PropTypes.bool,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  order: PropTypes.number,
  success: PropTypes.func,
};

export default compose(
  withRouter,
  connect(mapStateToProps, { error: logger.error, success: logger.success }),
)(Survey);
