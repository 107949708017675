import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@react-intl';
import styled, { withStyles as css, theme } from '@styled-components';
import { compose } from 'underscore';

import Icon from 'Components/Icon';
import { NavLink, withRouter } from 'Components/Router';

const Text = styled('span')``;

const Link = ({
  className,
  exact,
  href,
  icon,
  location: { pathname },
  name,
}) => {
  const selected = pathname.startsWith(href);

  return (
    <NavLink className={className} exact={exact} to={href}>
      <Icon disabled={!selected} name={icon}>
        {name}
      </Icon>
      <FormattedMessage capitalize component={Text} id={name}>
        {name}
      </FormattedMessage>
    </NavLink>
  );
};

Link.propTypes = {
  className: PropTypes.string,
  exact: PropTypes.bool,
  href: PropTypes.string,
  icon: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  name: PropTypes.string,
};

export default compose(
  css`
    align-items: center;
    box-shadow: inset 0 0 0 0.4rem transparent;
    display: grid;
    grid-gap: 2.4rem;
    grid-template: 1fr / 3.2rem 1fr;
    max-width: 100%;
    overflow: hidden;
    padding: 1.6rem 1.2rem;
    transition: background-color 250ms ease-out;

    &:hover {
      background-color: ${theme('--color-blueish-white-120')};
    }

    &:active,
    &:focus {
      background-color: ${theme('--color-blueish-white-140')};
      outline: none;
    }

    ${Icon} {
      height: 3.2rem;
      width: 3.2rem;
    }

    ${Text} {
      ${theme('--font-large')}
      ${theme('--font-opacity-80')}
      text-align: left;
      white-space: nowrap;
    }

    @media (${theme('--screen-small')}) {
      padding: 1.6rem 2.4rem;
    }
  `,
  withRouter,
)(Link);
