import React from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from '@prop-types';
import { Row, Column, SortableColumn } from 'Components/ResponsiveTable';

const Header = ({ className, ...common }) => (
  <Row className={className}>
    <SortableColumn field="name" id="NAME" {...common} />
    <Column capitalize id="LAST_INTERACTION_DATE" />
    <Column capitalize id="STEPS" />
    <Column capitalize id="PLATFORM" />
    <SortableColumn field="status" id="STATUS" {...common} />
    <Column capitalize id="TYPE" />
  </Row>
);

Header.propTypes = {
  className: PropTypes.string,
  onSortBy: PropTypes.func,
  order: PropTypes.string,
  sortBy: PropTypes.string,
};

export default styled(Header)`
  ${theme('--font-small')}
  ${theme('--font-weight-medium')}
  border-bottom: 0.1rem solid ${theme('--color-dark-night-10')};
  grid-template-columns: 1fr 15% 20rem 6rem 9rem 6rem;
  padding: 1.2rem 2.4rem 0.8rem;
`;
