import React, { useState } from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from 'prop-types';

import Stepper from 'Components/Stepper';
import ViewHeadline from 'Components/ViewHeadline';

import Customers from './components/Customers';
import Date from './components/Date';
import Message from './components/Message';
import Name from './components/Name';
import Overview from './components/Overview';
import Tags from './components/Tags';
import Type from './components/Type';

const Form = styled('div')``;
const Wrapper = styled('div')``;

const CampaignForm = ({
  activateActions = true,
  account,
  className,
  heading,
  title,
  onChange,
  onError,
  onSubmit,
  onTest,
  data,
  submitButtonLabel,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const steps = [Type, Name, Customers, Tags, Message, Date, Overview];
  const Step = steps[currentStep];
  const stepsControl = Array.from({ length: steps.length }, (_, i) => i);

  const handleStepBack = () => setCurrentStep(currentStep - 1);

  const handleStepNext = () => setCurrentStep(currentStep + 1);

  const handleStepper = step => setCurrentStep(step);

  return (
    <Wrapper className={className}>
      <ViewHeadline heading={heading} title={title} />
      <Stepper
        vertical
        currentStep={currentStep}
        onChange={handleStepper}
        steps={stepsControl}
        tooltip={false}
      />
      <Form>
        <Step
          account={account}
          activateActions={activateActions}
          onBack={handleStepBack}
          onChange={onChange}
          onError={onError}
          onNext={handleStepNext}
          onSubmit={onSubmit}
          onTest={onTest}
          submitButtonLabel={submitButtonLabel}
          {...data}
        />
      </Form>
    </Wrapper>
  );
};

CampaignForm.propTypes = {
  account: PropTypes.number,
  activateActions: PropTypes.bool,
  className: PropTypes.string,
  data: PropTypes.object,
  heading: PropTypes.string,
  onChange: PropTypes.func,
  onError: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  onTest: PropTypes.func,
  submitButtonLabel: PropTypes.string,
  title: PropTypes.string,
};

export default styled(CampaignForm)`
  @media (${theme('--column-12')}) {
    display: grid;
    grid-template: auto 1fr / repeat(12, 1fr);
  }

  ${Stepper} {
    display: none;
  }

  ${ViewHeadline} {
    grid-column: 4 / span 7;
    margin-bottom: 6.4rem;
  }

  ${Stepper} {
    display: flex;
    grid-column: 3 / span 1;
    grid-row: 2 / span 1;
    height: 33.6rem;
  }

  ${Form} {
    grid-column: 4 / span 7;
    grid-row: 2 / span 1;
  }
`;
