import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from '@styled-components';

const SVG = styled('img').attrs({ src: `/images/loader.svg` })``;

export const Loader = ({ className }) => (
  <figure className={className}>
    <SVG />
    <figcaption>Loading...</figcaption>
  </figure>
);

Loader.propTypes = {
  className: PropTypes.string,
};

/* Style */

const rotating = keyframes`
  to {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export default styled(Loader)`
  align-items: flex-end;
  display: flex;
  justify-content: space-around;
  left: 50%;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  & > figcaption {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    width: 1px;
  }

  ${SVG} {
    animation: ${rotating} 2s linear infinite;
  }
`;
