import { BUBBLE_WIDTH, ARROW_SIZE } from './constants';

export const getStyles = (anchor, x, y, tipSize) => {
  switch (anchor) {
    case 'top':
      return {
        bottom: null,
        left: `${x - BUBBLE_WIDTH / 2 + tipSize / 2}px`,
        right: null,
        top: `${y + tipSize + (ARROW_SIZE / 2) * 10}px`,
      };
    case 'bottom':
      return {
        bottom: `calc(100vh - ${y - (ARROW_SIZE / 2) * 10}px)`,
        left: `${x - BUBBLE_WIDTH / 2 + tipSize / 2}px`,
        right: null,
        top: null,
      };
    case 'left-top':
      return {
        bottom: null,
        left: `${x + tipSize + (ARROW_SIZE / 2) * 10 - 1}px`,
        right: null,
        top: `${y - tipSize}px`,
      };
    case 'left-bottom':
      return {
        bottom: `calc(100vh - ${y + 2 * tipSize}px)`,
        left: `${x + tipSize + (ARROW_SIZE / 2) * 10 - 1}px`,
        right: null,
        top: null,
      };
    case 'right-top':
      return {
        bottom: null,
        left: null,
        right: `calc(100vw - ${x - (ARROW_SIZE / 2) * 10 + 2}px)`,
        top: `${y - tipSize}px`,
      };
    case 'right-bottom':
      return {
        bottom: `calc(100vh - ${y + 2 * tipSize}px)`,
        left: null,
        right: `calc(100vw - ${x - (ARROW_SIZE / 2) * 10 + 2}px)`,
        top: null,
      };
    default:
      return {};
  }
};

export default {
  getStyles,
};
