import React, { useState } from 'react';
import { name as localeNames } from '@langs';
import PropTypes from 'prop-types';
import styled from '@styled-components';

import { Select } from 'Components/Form';

import TableDataProvider from 'Containers/TableDataProvider';

import service from 'Services/knowledge-base';

import PdfViewer from './PdfViewer';

const BotBuilder = ({ account, className, locales = [] }) => {
  const [locale, setLocale] = useState(locales[0]);

  return (
    <div className={className}>
      <Select
        onChange={setLocale}
        options={locales.map(key => ({
          name: localeNames(key),
          value: key,
        }))}
      />
      <TableDataProvider
        account={account}
        className={className}
        locale={locale}
        name="knowledgeBase"
        params={{
          account,
          locale,
        }}
        query={service.get}
        setLocale={setLocale}
        template={PdfViewer}
      />
    </div>
  );
};

BotBuilder.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
  locales: PropTypes.arrayOf(PropTypes.string),
};

export default styled(BotBuilder)`
  display: grid;
  grid-gap: 1.6rem;
  grid-template-rows: auto minmax(0, 1fr);
`;
