import React, { useEffect, useState, useCallback } from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from '@prop-types';
import { useField } from 'spooner';

import Button from 'Components/Button';
import { Input, Select } from 'Components/Form';
import Icon from 'Components/Icon';

const Actions = styled('div')``;
const Checkbox = styled('button').attrs({ type: 'button' })``;
const Label = styled('span')``;
const Item = styled('div')``;
const SocialMedia = styled('div')``;
const Title = styled('h4')``;
const Wrapper = styled('div')``;
const Template = styled('div')``;

const all = [
  { name: 'Facebook', value: 'FACEBOOK' },
  { name: 'Twitter', value: 'TWITTER' },
  { name: 'Linkedin', value: 'LINKEDIN' },
  { name: 'Instagram', value: 'INSTAGRAM' },
];

const FormRedirect = ({ className }) => {
  const [displaySocialMedia, setDisplaySocialMedia] = useState(false);
  const [channels, setChannel] = useState([{ id: 'FACEBOOK' }]);
  const { onChange = () => {} } = useField({ name: 'social' });

  const selectedChannels = channels.map(({ id }) => id);
  const availableChannels = all.filter(
    ({ value }) => !selectedChannels.includes(value),
  );

  const getOptions = useCallback(
    current =>
      all.filter(
        ({ value }) => !selectedChannels.includes(value) || value === current,
      ),
    [selectedChannels],
  );

  useEffect(() => {
    onChange(channels);
  }, [channels, onChange]);

  const handleDisplaySocialMedia = () => {
    setDisplaySocialMedia(!displaySocialMedia);
  };

  const handleChangeChannel = useCallback(
    index => id => {
      setChannel(prevChannels => [
        ...prevChannels.slice(0, index),
        { ...prevChannels[index], id },
        ...prevChannels.slice(index + 1),
      ]);
    },
    [],
  );

  const handleChangeLink = useCallback(
    index => url => {
      setChannel(prevChannels => [
        ...prevChannels.slice(0, index),
        { ...prevChannels[index], url },
        ...prevChannels.slice(index + 1),
      ]);
    },
    [],
  );

  const handleRemove = index => () => {
    setChannel(prevChannels => [
      ...prevChannels.slice(0, index),
      ...prevChannels.slice(index + 1),
    ]);
  };

  const handleSocialMediaAdd = () => {
    const [{ value: id }] = availableChannels;
    setChannel(prevChannels => [...prevChannels, { id }]);
  };

  return (
    <div className={className}>
      <Title capitalize translate>
        ADD_SOCIAL_MEDIA
      </Title>
      <Wrapper>
        <Checkbox
          data-checked={displaySocialMedia}
          onClick={handleDisplaySocialMedia}
        />
        <Label capitalize translate onClick={handleDisplaySocialMedia}>
          SOCIAL_MEDIA_THANK_YOU
        </Label>
        {displaySocialMedia && (
          <SocialMedia>
            {channels.map(({ id: channel, url }, index) => (
              <Item key={channel}>
                <Template>
                  <Icon name={channel.toLowerCase()}>{channel}</Icon>
                  <Icon name="vertical-arrows">ACTIONS.SELECT_OPTION</Icon>
                </Template>
                <Select
                  onChange={handleChangeChannel(index)}
                  options={getOptions(channel)}
                  value={channel}
                />
                <Input
                  capitalize
                  translate
                  label="SOCIAL_MEDIA_URL"
                  name="social_media_url"
                  onChange={handleChangeLink(index)}
                  value={url}
                />
                <Button onClick={handleRemove(index)}>
                  <Icon outline name="trash-bin">
                    ACTIONS.REMOVE_MEMBER
                  </Icon>
                </Button>
              </Item>
            ))}

            <Actions>
              {!!availableChannels.length && (
                <Button translate onClick={handleSocialMediaAdd}>
                  ACTIONS.LINK_LABEL_ADD_NEW
                </Button>
              )}
            </Actions>
          </SocialMedia>
        )}
      </Wrapper>
    </div>
  );
};

FormRedirect.propTypes = {
  className: PropTypes.string,
};

export default styled(FormRedirect)`
  ${Title}{
    ${theme('--font-medium')}
    ${theme('--font-weight-medium')}
    color: ${theme('--color-dark-night-80')};
    margin: 2.4rem 0 0.8rem;
  }
  
  ${Wrapper} {
    background: ${theme('--color-blueish-white')};
    border-radius: 0.6rem;
    padding: 1.6rem;
    z-index: 2;
    
    ${Checkbox} {
      background: ${theme('--color-light')};
      background-image: url('/images/icons/check.svg');
      background-position: center;
      background-repeat: no-repeat;
      border: 0.1rem solid ${theme('--color-dark-night-10')};
      border-radius: 0.4rem;
      height: 1.6rem;
      position: relative;
      top: 0.2rem;
      transition: background-color 150ms ease, border-color 150ms ease;
      width: 1.6rem;

      &[data-checked='true'] {
        background-color: ${theme('--color-primary')};
        border-color: ${theme('--color-primary')};
      }
    }

    ${Label} {
      cursor: pointer;
      margin-left: 0.8rem;
    }

    ${SocialMedia} {
      display: flex;
      flex-direction: column;
      margin-top: 2.4rem;

      ${Item} {
        display: flex;
        flex-direction: row;
        margin-bottom: 1.6rem;
        position: relative;

        ${Template} {
          align-items: center;
          background: ${theme('--color-light')};
          border: 0.1rem solid ${theme('--color-dark-night-10')};
          border-radius: 0.6rem;
          caret-color: ${theme('--color-primary')};
          display: flex;
          height: 4.8rem;
          justify-content: space-between;
          padding: 1.2rem 0.4rem 1.2rem 1.2rem;
          position: absolute;
          width: 8rem;
          z-index: 1;
        }

        ${Select} {
          height: 4.8rem;
          overflow: hidden;
          width: 8rem;
        }

        ${Input} {
          flex: 1;
          height: 4.8rem;
          margin: 0 0.8rem;
        }

        ${Button} {
          align-items: center;
          background: ${theme('--color-light')};
          border: 0.1rem solid ${theme('--color-primary')};
          border-radius: 0.6rem;
          display: flex;
          height: 4.8rem;
          justify-content: center;
          width: 4.8rem;

          ${Icon} * {
            stroke: ${theme('--color-primary')};
          }
        }
      }

      ${Actions} {
        ${Button} {
          color: ${theme('--color-primary')};
        }
      }
    }
  }
`;
