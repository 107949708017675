import React, { useState } from 'react';
import PropTypes from '@prop-types';
import styled, { theme, withStyles as css } from '@styled-components';
import { FormattedMessage } from '@react-intl';
import { compose } from 'underscore';

import Icon from 'Components/Icon';
import Link from 'Components/Link';
import { withRouter } from 'Components/Router';

import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Title = styled('h2')``;
const Content = styled('p')``;
const Actions = styled('nav')``;
const Wrapper = styled('div')``;
const Button = styled(Link)``;

const EmptyTemplate = ({ className, data }) => {
  const [totalPages, setTotalPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setTotalPages(numPages);
  };

  return (
    <article className={className}>
      {data.url ? (
        <div className="pdf-view">
          <Document file={data.url} onLoadSuccess={onDocumentLoadSuccess}>
            {/* eslint-disable-next-line prefer-spread */}
            {Array.apply(null, Array(totalPages))
              .map((x, i) => i + 1)
              .map(page => {
                return (
                  <div
                    style={{
                      border: '5px solid #EEF1F6',
                      marginBottom: '2.6rem',
                    }}
                  >
                    <Page
                      pageNumber={page}
                      renderAnnotationLayer={false}
                      renderTextLayer={false}
                    />
                    <p style={{ margin: '1.6rem 0', textAlign: 'center' }}>
                      Page {page} of {totalPages}
                    </p>
                  </div>
                );
              })}
          </Document>
        </div>
      ) : (
        <Wrapper>
          <Icon name="media-empty" />
          <div>
            <FormattedMessage
              capitalize
              component={Title}
              id="EMPTY_KNOWLEDGE.TITLE"
            >
              Whoops! No knowledge documents here...
            </FormattedMessage>
          </div>
          <div>
            <FormattedMessage
              capitalize
              component={Content}
              id="EMPTY_KNOWLEDGE.DESCRIPTION"
            >
              Upload a knowledge document and it will appear here. Your bot will
              refer to this document to answer customer questions.
            </FormattedMessage>
          </div>
        </Wrapper>
      )}
    </article>
  );
};

EmptyTemplate.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
};

export default compose(
  css`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .pdf-view {
      height: 50rem;
      overflow-y: auto;
    }

    canvas.react-pdf__Page__canvas {
      width: 100% !important;
    }

    ${Wrapper} {
      display: grid;
      grid-gap: 0.4rem;
      /* grid-template-rows: minmax(0, calc(100% - 10.6rem)) 2.4rem 3.2rem 5rem; // Recover this line to be able to create campaigns */
      grid-template-rows: minmax(0, calc(100% - 2.4rem)) 2.4rem;
      height: 100%;
      max-height: 35rem;
      text-align: center;

      ${Icon} {
        align-self: center;
        margin: 0 auto;
      }

      ${Title} {
        ${theme('--font-large')}
        ${theme('--font-weight-book')}
        ${theme('--font-opacity-100')}
      }

      ${Content} {
        ${theme('--font-medium')}
        ${theme('--font-weight-book')}
        ${theme('--font-opacity-40')}
        margin-bottom: 1.6rem;
      }

      ${Actions} {
        display: flex;
        justify-content: center;

        ${Button} {
          ${theme('--font-medium')}
          ${theme('--font-weight-demi')}
          align-items: center;
          background-color: ${theme('--color-primary')};
          border-radius: 0.6rem;
          color: ${theme('--color-light')};
          display: flex;
          height: 4.8rem;
          justify-content: center;
          margin: 0 0.8rem;
          width: 16rem;
        }
      }
    }
  `,
  withRouter,
)(EmptyTemplate);
