import React from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';

import View from 'Components/View';
import ViewHeadline from 'Components/ViewHeadline';

import ConversionRatesChart from 'Containers/ConversionRatesChart';
import RevenuesPerCampaign from 'Containers/RevenuesPerCampaign';
import DashboardRevenues from 'Containers/DashboardRevenues';
import UsersByCountry from 'Containers/UsersByCountry';
import VisitsAndRevenues from 'Containers/VisitsAndRevenues';
import Orders from 'Containers/Orders';
import BestSellers from 'Containers/BestSellers';
import MostViewed from 'Containers/MostViewed';
import NewCustomers from 'Containers/NewCustomers';
import PaymentMethods from 'Containers/PaymentMethodsChart';

import Charts from './components/Charts';

const Dashboard = ({
  className,
  user: {
    fullName,
    images: { small },
  },
}) => (
  <View className={className}>
    <ViewHeadline
      avatar
      heading="INSTRUCTIONS.DASHBOARD"
      image={small}
      title="WELCOME_BACK_%NAME%"
      values={{ name: fullName }}
    />
    <DashboardRevenues />
    <VisitsAndRevenues />
    <Charts />
    <PaymentMethods />
    <Orders />
    <BestSellers />
    <MostViewed />
    <NewCustomers />
    <ConversionRatesChart />
    <RevenuesPerCampaign />
    <UsersByCountry />
  </View>
);

Dashboard.propTypes = {
  className: PropTypes.string,
  user: PropTypes.shape({
    fullName: PropTypes.string,
    images: PropTypes.shape({
      small: PropTypes.string,
    }),
  }),
};

export default styled(Dashboard)`
  ${ViewHeadline},
  ${DashboardRevenues},
  ${UsersByCountry},
  ${Charts} ,
  ${VisitsAndRevenues},
  ${RevenuesPerCampaign},
  ${ConversionRatesChart},
  ${Orders},
  ${BestSellers},
  ${MostViewed},
  ${NewCustomers},
  ${PaymentMethods} {
    grid-column: span 4;
  }

  ${VisitsAndRevenues},
  ${Charts},
  ${PaymentMethods},
  ${UsersByCountry} {
    height: 43rem;
  }

  ${RevenuesPerCampaign} {
    height: 46.4rem;
  }

  ${UsersByCountry} {
    height: 72.4rem;
  }

  @media (${theme('--screen-small')}){
    ${UsersByCountry} {
      height: 54.4rem;
    }
  }

  @media (${theme('--column-8')}) {
    ${ViewHeadline},
    ${DashboardRevenues},
    ${UsersByCountry},
    ${Charts} ,
    ${VisitsAndRevenues},
    ${RevenuesPerCampaign},
    ${ConversionRatesChart},
    ${Orders},
    ${BestSellers},
    ${MostViewed},
    ${NewCustomers},
    ${PaymentMethods} {
      grid-column: span 8;
    }
  }

  @media (${theme('--column-12')}) {
    ${ViewHeadline},
    ${DashboardRevenues},
    ${UsersByCountry} {
      grid-column: span 12;
    }

    ${Orders},
    ${BestSellers},
    ${MostViewed},
    ${NewCustomers} {
      grid-column: span 3;
    }

    ${Charts},
    ${PaymentMethods} {
      grid-column: span 3;
    }

    ${VisitsAndRevenues} {
      grid-column: span 6;
    }

    ${RevenuesPerCampaign}, ${ConversionRatesChart} {
      grid-column: span 6;
    }

  }

  @media (${theme('--level-3')}) {
    ${UsersByCountry} {
      height: 65rem;
    }
  }
`;
