import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, withIntl } from '@react-intl';
import { ArrayOf } from '@array-utils';
import styled, {
  withStyles as css,
  withTheme,
  theme,
} from '@styled-components';

import Card from 'Components/Card';
import Map from 'Components/Map';
import Placeholder from 'Containers/Placeholder/index';

const Info = styled('section')``;
const Title = styled('h3')``;
const Datum = styled('div')``;
const Label = styled(Placeholder)``;
const Value = styled(Placeholder)``;

const CountryList = styled('div')``;

export const UsersByCountry = ({ className }) => (
  <Card className={className} title="USERS_BY_COUNTRY">
    <Map
      doubleClickZoom={false}
      lat={45}
      lng={0}
      scrollWheelZoom={false}
      zoom={2}
      zoomControl={false}
    >
      holaaa
    </Map>
    <Info>
      <FormattedMessage capitalize component={Title} id="TOP_COUNTRIES">
        top countries
      </FormattedMessage>
      <CountryList>
        {ArrayOf(5, () => (
          <Datum>
            <Label placeholder="country name" />
            <Value placeholder="users value" />
          </Datum>
        ))}
      </CountryList>
    </Info>
  </Card>
);

UsersByCountry.propTypes = {
  className: PropTypes.string,
};

export default compose(
  withIntl,
  css`
    display: block;
    height: 100%;
    width: 100%;

    ${Map} {
      flex-basis: 0;
      flex-grow: 1;
      height: 17.5rem;
    }

    ${Info} {
      padding: 0;
      width: 100%;

      ${Title} {
        ${theme('--font-medium')}
        ${theme('--font-opacity-60')}
        ${theme('--font-weight-medium')}
        display: none;
        padding-bottom: 6.4rem;
      }

      ${CountryList} {
        height: 46rem;
      }

      ${Datum} {
        align-items: center;
        border-bottom: 0.1rem solid ${theme('--color-dark-night-10')};
        display: flex;
        height: 8rem;
        justify-content: space-between;

        ${Label} {
          ${theme('--font-large')}
          ${theme('--font-weight-medium')}
        }

        ${Value} {
          ${theme('--font-small')}
          ${theme('--font-opacity-40')}
          ${theme('--font-weight-medium')}
        }
      }
    }

    @media (${theme('--screen-small')}) {
      display: flex;

      ${Map} {
        flex-basis: 0;
        flex-grow: 1;
        height: 46rem;
      }

      ${Info} {
        padding: 0 3.2rem 2.4rem;
        width: 33rem;

        ${Title} {
          display: block;
        }

        ${Datum} {
          height: 6.4rem;
        }
      }
    }
    
    @media (${theme('--level-3')}) {
      ${Map} {
        height: 56.6rem;
      }
    }
  `,
  withTheme,
)(UsersByCountry);
