import request from 'Providers/request';
import { createService } from '@redux-act';

const download = createService(
  ({ account, fileType, from, locale, to, type }, token) =>
    request.read(
      `/ofa/${account}/faq/export/${type}/${fileType}/download`,
      {
        from,
        locale,
        to,
      },
      {},
      { token },
    ),
);

const locales = createService(({ account }, token) =>
  request.read(`/ofa/${account}/faq_locales`, {}, {}, { token }),
);

const tags = createService(({ account, locale }, token) =>
  request.read(`/ofa/${account}/faq/tags`, { locale }, {}, { token }),
);

async function faqs({
  account,
  limit,
  locale,
  filterBy = [],
  offset,
  searchBy,
  sortBy,
  type,
}) {
  const { collection, total } = await request.read(`/ofa/${account}/faq`, {
    gradeBy: sortBy,
    limit,
    locale,
    offset,
    ...(filterBy && { filterBy: filterBy.toString() }),
    ...(searchBy && { searchBy }),
    ...(type && { type }),
  });

  return {
    collection,
    total,
  };
}

async function get({ account, id, type }) {
  const response = await request.read(`/ofa/${account}/faq/${id}`, {
    type,
  });

  return response;
}

async function post({ account, locale, faq, type }) {
  const response = await request(
    `/ofa/${account}/faq?locale=${locale}`,
    'post',
    {
      ...faq,
      type,
    },
  );

  return response;
}

async function put({ account, id, faq, type }) {
  const response = await request(`/ofa/${account}/faq/${id}`, 'put', {
    ...faq,
    type,
  });

  return response;
}

async function del({ account, id, type }) {
  const response = await request(`/ofa/${account}/faq/${id}`, 'delete', {
    type,
  });

  return response;
}

const importFaq = async ({ account, file, locale }) => {
  const response = await request(
    `/ofa/${account}/faq/import?locale=${locale}`,
    'post',
    { faq: file },
    {},
    {
      headers: {
        'content-type': 'application/form-data',
      },
    },
  );

  return response;
};

export default {
  del,
  download,
  faqs,
  get,
  importFaq,
  locales,
  post,
  put,
  tags,
};
