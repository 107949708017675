import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@react-intl';
import styled, { theme } from '@styled-components';

import Button from 'Components/Button';
import Tooltip from 'Components/Tooltip';

export class Step extends Component {
  static propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    onClick: PropTypes.func,
    tooltip: PropTypes.bool,
  };

  static defaultProps = {
    tooltip: false,
  };

  state = { in: false };

  toggle = e => {
    const rect = e.target.getBoundingClientRect();
    const scrollLeft = window.pageXOffset;
    const scrollTop = window.pageYOffset;

    this.setState(prevState => ({
      in: !prevState.in,
      x: rect.left + scrollLeft + rect.width / 2,
      y: rect.top + scrollTop + rect.height / 2,
    }));
  };

  render() {
    const { className, name, onClick: handleClick, tooltip } = this.props;
    const { in: visible, x, y } = this.state;
    return (
      <li className={className}>
        <Button
          onBlur={this.toggle}
          onClick={handleClick}
          onFocus={this.toggle}
          onMouseOut={this.toggle}
          onMouseOver={this.toggle}
        >
          {visible && tooltip && (
            <Tooltip in right x={x} y={y}>
              <FormattedMessage capitalize id={name} />
            </Tooltip>
          )}
        </Button>
      </li>
    );
  }
}

export default styled(Step)`
  background-color: ${theme('--color-primary')};
  border-radius: 50%;
  box-shadow: inset 0 0 0 0.2rem ${theme('--color-primary')};
  height: 0.8rem;
  width: 0.8rem;
  z-index: 2;

  ${Button} {
    height: 100%;
    width: 100%;
  }
`;
