import React from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';
import { Row, Column, SortableColumn } from 'Components/ResponsiveTable';
import SurveyStepTooltip from './SurveyStepTooltip';

const Header = ({ className, ...common }) => (
  <Row className={className}>
    <SortableColumn field="fullName" id="CUSTOMER" {...common} />
    <SortableColumn field="lastUpdate" id="LAST_INTERACTION_DATE" {...common} />
    <Column id="TYPE" />
    <Column id="STEP" tooltip={SurveyStepTooltip} />
  </Row>
);

Header.propTypes = {
  className: PropTypes.string,
};

export default styled(Header)`
  grid-template-columns: 1fr 15rem 10rem 20rem;
  padding: 1.2rem 2.4rem 0.8rem 2.4rem;
`;
