import { createAction } from '@redux-act';

export const clear = createAction('@Log/CLEAR');

export const error = createAction('@Log/ERROR', message => ({ message }));

export const info = createAction('@Log/INFO', message => ({ message }));

export const remove = createAction('@Log/REMOVE', uid => ({ uid }));

export const success = createAction('@Log/SUCCESS', message => ({ message }));
