import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@react-intl';
import styled, { theme } from '@styled-components';

import Avatar from 'Components/Avatar';
import { Input, Phone } from 'Components/Form';

const Legend = styled('legend')``;
const FirstName = styled(Input)``;
const LastName = styled(Input)``;
const Email = styled(Input)``;

const Wrapper = styled('div')``;

const Personal = ({ className, image, isEdit }) => (
  <div className={className}>
    <FormattedMessage capitalize component={Legend} id="PERSONAL_DATA">
      Personal data
    </FormattedMessage>
    <Wrapper>
      {isEdit && <Avatar image={image} />}
      <FirstName label="PLACEHOLDER.NAME" name="firstName" />
      <LastName label="PLACEHOLDER.LAST_NAME" name="lastName" />
      <Email label="PLACEHOLDER.EMAIL" name="email" />
      <Phone label="PLACEHOLDER.PHONE" name="phone" />
    </Wrapper>
  </div>
);

Personal.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string,
  isEdit: PropTypes.bool,
};

export default styled(Personal)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 4.8rem;

  ${Legend} {
    ${theme('--font-small')}
    ${theme('--font-opacity-100')}
    ${theme('--font-weight-demi')}
    margin-bottom: 1.6rem;
    width: 100%;
  }

  ${Wrapper} {
    display: grid;
    gap: 1.6rem 3.2rem;
    grid-template: auto / auto;
    width: 100%;

    ${Avatar} {
      border: 1rem solid ${theme('--color-light')};
      box-shadow: 0 0.2rem 0.8rem rgba(0, 15, 25, 0.05);
      height: 14rem;
      margin: 0 auto;
      width: 14rem;
    }
  }

  @media (${theme('--column-12')}) {
    ${Wrapper} {
      grid-template: repeat(4, 1fr) / ${({ isEdit }) =>
        isEdit ? 'auto 1fr' : 'auto'};

      ${Avatar} {
        grid-column: 1;
        grid-row: 1 / span 4;
      }
    }
  }
`;
