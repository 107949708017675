import React from 'react';
import PropTypes from 'prop-types';
import styled from '@styled-components';

import Table from 'Components/ResponsiveTable';

import Template from './Template';
import Header from './Header';

const VisitsAndRevenues = ({ className, currency, data, unit }) => {
  const dates = data[0];
  const sales = data[3];
  const visits = data[4];

  const tableData = dates.map(date => ({
    currency,
    date,
    sales: sales[Object.keys(sales).find(key => key === date)],
    unit,
    visits: visits[Object.keys(visits).find(key => key === date)],
  }));

  return (
    <Table
      className={className}
      desktopRows={4}
      header={Header}
      items={tableData}
      rows={5}
      template={Template}
    />
  );
};

VisitsAndRevenues.propTypes = {
  className: PropTypes.string,
  currency: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  unit: PropTypes.string,
};

export default styled(VisitsAndRevenues)``;
