import React from 'react';
import PropTypes from 'prop-types';
import styled, { theme, prop } from '@styled-components';

import Tags from 'Components/Tags';
import { Row } from 'Components/ResponsiveTable';
import {
  withPlaceholderNoData as withPlaceholder,
  PlaceholderNoData as Placeholder,
} from 'Containers/Placeholder';

const Wrapper = styled('div')``;
const Label = styled(Placeholder)``;
const NameLabel = styled(Label)``;
const Thumbnail = styled(Placeholder.img).attrs({
  forwardProps: ['src'],
})``;
const Categories = styled(withPlaceholder(Tags))``;

const DesktopTemplate = ({
  categories = [],
  className,
  empty,
  id,
  merchantId,
  name,
  price,
  sales,
  visits,
}) => {
  return (
    <Row className={className} empty={empty} to={`/products/${id}`}>
      <Wrapper>
        <Thumbnail
          fill
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
        />
        <NameLabel placeholder="product name">{name}</NameLabel>
      </Wrapper>
      <Label placeholder="merchant id">{merchantId}</Label>
      <Label placeholder="price">{price}</Label>
      <Label placeholder="sales">{sales}</Label>
      <Label placeholder="visits">{visits}</Label>
      <Wrapper>
        <Categories fill placeholder="categories" tags={categories} />
      </Wrapper>
    </Row>
  );
};

DesktopTemplate.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  empty: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  merchantId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  price: PropTypes.string,
  sales: PropTypes.number,
  visits: PropTypes.number,
};

export default styled(DesktopTemplate)`
  grid-template-columns: 1fr 15rem 7rem 7rem 7rem 20rem;
  height: 7.2rem;

  ${Label} {
    ${theme('--font-medium')}
    ${theme('--font-weight-medium')}
    ${theme('--font-opacity-40')}
  }

  ${NameLabel} {
    color: ${theme('--color-primary')};
  }

  ${Wrapper} {
    align-items: center;
    display: flex;
    overflow: hidden;
  }

  ${Thumbnail} {
    background-color: ${theme('--color-dark-night-05')};
    background-image: url(${prop('image')});
    background-position: center;
    background-size: cover;
    border: 0.1rem solid ${theme('--color-dark-night-10')};
    border-radius: 0.6rem;
    height: 4rem;
    margin-right: 1.6rem;
    object-fit: cover;
    width: 4rem;
  }

  ${Categories} {
    height: 2.4rem;
  }
`;
