/* eslint-disable */
import { rgba } from 'polished';
import { css } from '@styled-components';

export default {
  '--font-opacity-10': css`
    color: ${rgba('#000f19', 0.1)};
  `,
  '--font-opacity-20': css`
    color: ${rgba('#000f19', 0.2)};
  `,
  '--font-opacity-40': css`
    color: ${rgba('#000f19', 0.4)};
  `,
  '--font-opacity-60': css`
    color: ${rgba('#000f19', 0.6)};
  `,
  '--font-opacity-80': css`
    color: ${rgba('#000f19', 0.8)};
  `,
  '--font-opacity-100': css`
    color: #000f19;
  `,
};
