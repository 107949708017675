import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Container from 'lanning';
import ResizeObserver from 'resize-observer-polyfill';
import styled from '@styled-components';
import { debounce } from 'underscore';

const Chart = ({ className, pie = false, style, ...props }) => {
  const element = useRef(null);
  const isFirstPaint = useRef(true);
  const [size, setSize] = useState({ height: 0, width: 0 });

  const handleResize = useCallback(
    debounce(([entry]) => {
      const node = entry.target;

      const { height, width } = node.getBoundingClientRect();
      const current = size.width / size.height;
      const ratio = width / height;

      isFirstPaint.current = false;

      if (
        Math.abs(current - ratio) > 0.5 ||
        Math.floor(width) > size.width * 1.01 ||
        Math.floor(height) > size.height * 1.01
      ) {
        setSize({ height: Math.floor(height), width: Math.floor(width) });
      }
    }, 250),
    [size, setSize],
  );

  useLayoutEffect(() => {
    const node = element.current;
    const resizer = new ResizeObserver(handleResize);

    resizer.observe(node);

    return () => {
      resizer.unobserve(node);
    };
  }, [handleResize]);

  return (
    <div
      ref={element}
      style={{
        height: '100%',
        maxHeight: '100%',
        maxWidth: '100%',
        ...style,
      }}
    >
      {!isFirstPaint.current && (
        <Container
          key={`${size.width}x${size.height}`}
          {...props}
          className={className}
          height={!pie && size.width < 300 ? size.height * 1.25 : size.height}
          style={{ display: 'block', verticalAlign: 'top' }}
          width={!pie && size.width < 300 ? size.width * 1.25 : size.width}
        />
      )}
    </div>
  );
};

Chart.propTypes = {
  className: PropTypes.string,
  pie: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.string),
};

export default styled(Chart)`
  display: block;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  min-height: 5rem;
  position: relative;
  width: 100%;
`;
