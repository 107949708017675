import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import styled, { withStyles as css, theme } from '@styled-components';
import {
  FormattedMessage,
  intlDefault,
  intlShape,
  withIntl,
} from '@react-intl';

import { ArrayOfObjects } from '@array-utils';

import L from 'Components/Link';
import Button from 'Components/Button';
import View from 'Components/View';
import { withRouter } from 'Components/Router';
import ViewHeadline from 'Components/ViewHeadline';

import { Context } from 'Containers/DataProvider';

import Filters from './components/Filters';
import Integration from './components/Integration';
import AddCard from './components/Add';

const Header = styled('div')``;
const Layout = styled('div')``;
const Footer = styled('div')``;

const Link = styled(({ primary, ...props }) => <L {...props} />)``;

let ticking;

const Integrations = ({
  className,
  data: { integrations: allIntegrations = [] } = {},
  filterBy,
  intl,
  onFilterChange: handleFilterChange,
}) => {
  const [isFooterVisible, setFooterVisible] = useState(false);
  const { loading } = useContext(Context);

  const checkScroll = ({ target }) => {
    const { top } = target.firstChild.getClientRects()[0];

    if (!ticking) {
      window.requestAnimationFrame(() => {
        setFooterVisible(top < -35);
        ticking = false;
      });

      ticking = true;
    }
  };

  useEffect(() => {
    const content = document.querySelector('main');

    content.addEventListener('scroll', checkScroll);
    return () => content.removeEventListener('scroll', checkScroll);
  }, []);

  let integrations = allIntegrations;

  if (loading) {
    integrations = allIntegrations.length ? allIntegrations : ArrayOfObjects(1);
  }

  return (
    <View className={className}>
      <Header>
        <ViewHeadline
          heading="INTEGRATIONS_DESCRIPTION"
          title="INTEGRATIONS_TITLE"
        />
        <Filters onChange={handleFilterChange} value={filterBy} />
      </Header>
      <Button primary as={Link} to="/integrations/add">
        <FormattedMessage capitalize id="ACTIONS.ADD_MOBILE" />
      </Button>
      <Layout>
        {integrations.map(
          ({
            id,
            category = 'EMPTY',
            service,
            displayName: integrationName = intl.formatMessage({
              id: service || 'ERROR',
            }),
            status = 'EMPTY',
            logo,
          }) => (
            <Integration
              key={integrationName}
              category={category}
              id={id}
              logo={logo}
              name={integrationName}
              service={service}
              status={status}
            />
          ),
        )}
      </Layout>
      <Footer data-show={isFooterVisible}>
        <Button primary as={Link} to="/integrations/add">
          <FormattedMessage capitalize id="ACTIONS.ADD_MOBILE" />
        </Button>
      </Footer>
    </View>
  );
};

Integrations.defaultProps = {
  intl: intlDefault,
  onFilterChange() {},
};

Integrations.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    integrations: PropTypes.arrayOf(PropTypes.object),
  }),
  filterBy: PropTypes.string,
  intl: intlShape,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
    url: PropTypes.string,
  }),
  onFilterChange: PropTypes.func,
};

export default compose(
  css`
    > ${Button}, ${Footer} > ${Button} {
      ${theme('--font-medium')}
      ${theme('--font-weight-demi')}
      color: ${theme('--color-light')};
      display: none; /* @TODO This has to be flex once we do release */
      justify-content: center;
      padding: 1.2rem;
      width: 100%;
    }

    ${Header} {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      ${Filters} {
        display: none;
      }
    }

    ${Layout} {
      display: grid;
      grid-gap: 2.4rem;
      padding-bottom: 9.4rem;

      ${Integration} {
        width: 100%;
      }

      ${AddCard} {
        display: none;
      }
    }

    ${Footer} {
      align-items: center;
      background-color: white;
      border-top: 0.2rem solid ${theme('--color-dark-night-05')};
      bottom: -8.2rem;
      display: none; /* @TODO This has to be flex once we do release */
      justify-content: center;
      left: 0;
      padding: 2rem;
      position: fixed;
      transition: bottom 0.5s ease-in;
      width: 100%;

      &[data-show='true'] {
        bottom: 0;
      }
    }

    @media (${theme('--column-8')}) {
      padding-bottom: 0;

      > ${Button}, > ${Footer} {
        display: none;
      }

      ${Header} {
        grid-column: span 8;

        ${Filters} {
          display: inline-flex;
        }
      }

      ${Layout} {
        align-items: center;
        display: grid;
        flex-direction: column;
        grid-column: span 8;
        grid-template-columns: repeat(auto-fill, minmax(28rem, 1fr));
        justify-content: space-between;

        ${AddCard} {
          display: flex;
        }
      }
    }

    @media (${theme('--column-12')}) {
      ${Header} {
        grid-column: span 12;
      }

      ${Layout} {
        grid-column: span 12;
        grid-gap: 3.2rem;
      }
    }
  `,
  withIntl,
  withRouter,
)(Integrations);
