import React from 'react';
import styled from '@styled-components';
import PropTypes from '@prop-types';

import { Row, SortableColumn } from 'Components/ResponsiveTable';

const HeaderTemplate = ({ className, ...common }) => (
  <Row className={className}>
    <SortableColumn field="campaign" id="CAMPAIGN" {...common} />
    <SortableColumn field="revenue" id="REVENUE" {...common} />
    <SortableColumn field="createdAt" id="DATE" {...common} />
  </Row>
);

HeaderTemplate.propTypes = {
  className: PropTypes.string,
};

export default styled(HeaderTemplate)`
  grid-template-columns: 1fr 15% 15%;
  padding: 1.2rem 2.4rem 0.8rem;
`;
