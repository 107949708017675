import React from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from '@prop-types';

import Button from 'Components/Button';
import Form, { Input } from 'Components/Form';
import ModalHeadline from 'Components/ViewHeadline';
import Tip from 'Components/Tip';

const Actions = styled('div')``;
const FormTitle = styled('div')``;
const Heading = styled('p')``;
const Title = styled('h4')``;
const Tooltip = styled('span')``;

const AddTitle = ({
  className,
  defaultValue = {},
  onChange,
  onSubmit,
  type = 'MULTIPRODUCT',
}) => {
  return (
    <Form
      className={className}
      defaultValue={defaultValue}
      onChange={onChange}
      onSubmit={onSubmit}
    >
      <ModalHeadline heading="INSTRUCTIONS.MICRO_APP_TEMPLATE" title={type} />
      <FormTitle>
        <Title capitalize translate>
          INSTRUCTIONS.TEXT_BLOCK
        </Title>
        <Heading capitalize translate>
          INSTRUCTIONS.TEXT_BLOCK
        </Heading>
        <Tip>
          <Tooltip translate>PLACEHOLDER.TOOLTIP</Tooltip>
        </Tip>
        <Input capitalize label="title" name="title" />
      </FormTitle>
      <Actions>
        <Button capitalize primary translate type="submit">
          ACTIONS.DONE
        </Button>
      </Actions>
    </Form>
  );
};

AddTitle.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.object,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  type: PropTypes.string,
};

export default styled(AddTitle)`
  
  ${FormTitle} {
    margin-top: 3.2rem;
    position: relative;
    
    ${Title} {
      ${theme('--font-medium')}
      ${theme('--font-weight-demi')}
      ${theme('--font-opacity-80')}
      padding: 0 0 0.8rem;
    }
  
    ${Heading} {
      ${theme('--font-medium')}
      ${theme('--font-weight-book')}
      ${theme('--font-opacity-60')}
      padding-bottom: 1.6rem;
    }
    
    ${Tip} {
      position: absolute;
      right: 0;
      top: 0;
    }
  }


  ${Actions} {
    background: ${theme('--color-blueish-white')};
    border-top: 0.1rem solid ${theme('--color-dark-night-10')};
    bottom: 0;
    display: flex;
    left: 0;
    padding: 2rem;
    position: fixed;
    width: 100vw;

    ${Button} {
      flex-basis: 0;
      flex-grow: 1;
      margin-right: 0.4rem;

      &:last-child {
        margin-left: 0.4rem;
        margin-right: 0;
      }
    }
  }

  @media (${theme('--screen-small')}){
    ${Actions} {
      border: 0;
      display: inline-block;
      margin-top: 4rem;
      padding: 1.6rem 0;
      position: inherit;
      width: auto;

      ${Button} {
        margin: 0 1.6rem 0 0;
        width: 14.4rem;

        &:last-child {
          margin: 0 1.6rem 0 0;
        }
      }
    }
  }
`;
