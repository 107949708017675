import React, { Component, createRef } from 'react';
import { createPortal } from 'react-dom';

import PropTypes from '@prop-types';
import styled, { theme } from '@styled-components';

const Tooltip = styled('aside').attrs(props => ({
  vertical: props.bottom || props.top || false,
}))`
  ${theme('--font-small')}
  ${theme('--font-opacity-40')}
  background: ${theme('--color-light')};
  border-radius: 0.6rem;
  box-shadow: 0 1rem 1.6rem rgba(0, 15, 25, 0.15),
    0 0.2rem 0 rgba(0, 15, 25, 0.05);
  left: ${props => props.x}px;
  min-height: 3.2rem;
  opacity: ${props => (props.in ? 1 : 0)};
  padding: 0.8rem 1.6rem;
  pointer-events: none;
  position: fixed;
  text-align: center;
  top: ${props => props.y}px;
  transform: translate(
    ${props => (props.left ? '-100%' : (props.vertical && '-50%') || '0')},
    ${props => (props.top ? '-100%' : (!props.vertical && '-50%') || '0')}
  );
  z-index: 10000;
`;

class BoundingBox extends Component {
  static isInside([x, y], polygon) {
    let inside = false;

    // eslint-disable-next-line
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      const [xi, yi] = polygon[i];
      const [xj, yj] = polygon[j];

      const intersect =
        yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }

    return inside;
  }

  tooltip = createRef();

  static propTypes = {
    bottom: PropTypes.bool,
    children: PropTypes.children,
    in: PropTypes.bool,
    left: PropTypes.bool,
    offset: PropTypes.number,
    onClose: PropTypes.func,
    right: PropTypes.bool,
    top: PropTypes.bool,
    x: PropTypes.number,
    y: PropTypes.number,
  };

  static defaultProps = {
    bottom: false,
    in: false,
    left: false,
    offset: 8,
    onClose() {},
    right: false,
    top: false,
    x: 0,
    y: 0,
  };

  state = {};

  componentDidMount() {
    this.computePosition();
  }

  computePosition() {
    const { x, y, bottom, left, right, top, offset } = this.props;
    const { current: tooltip } = this.tooltip;

    const { offsetHeight, offsetWidth } = document.body;
    const { height, width } = tooltip.getBoundingClientRect();

    const polygon = [
      [0, 0],
      [0, offsetHeight],
      [offsetWidth, offsetHeight],
      [offsetWidth, 0],
    ];

    switch (true) {
      case bottom: {
        if (!BoundingBox.isInside([x, y + height], polygon)) {
          this.setState({ x, y: y - height });
        } else {
          this.setState({ x, y });
        }
        break;
      }
      case left: {
        if (!BoundingBox.isInside([x - width - offset, y], polygon)) {
          this.setState({ x: x + width + offset, y });
        } else {
          this.setState({ x: x - offset, y });
        }
        break;
      }
      case right: {
        if (!BoundingBox.isInside([x + width + offset, y], polygon)) {
          this.setState({ x: x - width - offset, y });
        } else {
          this.setState({ x: x + offset, y });
        }
        break;
      }
      case top: {
        if (!BoundingBox.isInside([x, y - height], polygon)) {
          this.setState({ x, y: y + height });
        } else {
          this.setState({ x, y });
        }
        break;
      }
      default:
        this.setState({ x, y });
    }
  }

  render() {
    const { children, x: ignoreX, y: ignoreY, offset, ...props } = this.props;
    const { x, y } = this.state;

    return createPortal(
      <Tooltip ref={this.tooltip} {...props} x={x} y={y}>
        {children}
      </Tooltip>,
      document.getElementById('tooltip'),
    );
  }
}

export default BoundingBox;
