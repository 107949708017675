import React from 'react';
import styled, { prop, theme } from '@styled-components';
import PropTypes from '@prop-types';

import Button from 'Components/Button';
import Icon from 'Components/Icon';

const Play = styled('img').attrs({ src: '/images/play.svg' })``;

const ContentCard = ({ className, isSelected, type, onDelete, onSelect }) => {
  return (
    <button
      className={className}
      data-selected={isSelected}
      onClick={onSelect}
      type="button"
    >
      <Button onClick={onDelete}>
        <Icon outline name="trash-bin">
          ACTIONS.DELETE
        </Icon>
      </Button>
      {type === 'video' && <Play />}
    </button>
  );
};

ContentCard.propTypes = {
  className: PropTypes.string,
  isSelected: PropTypes.bool,
  onDelete: PropTypes.func,
  onSelect: PropTypes.func,
  type: PropTypes.string,
};

export default styled(ContentCard)`
  align-items: center;
  background-image: url(${prop('source')});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 0.2rem solid ${theme('--color-dark-night-10')};
  border-radius: 0.6rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: relative;

  &[data-selected='true'] {
    border-color: ${theme('--color-primary')};
  }

  ${Play} {
    bottom: 0.8rem;
    left: 0.8rem;
    position: absolute;
    width: 2.4rem;
  }

  ${Button} {
    background-color: white;
    height: 3.2rem;
    position: absolute;
    right: 0.8rem;
    top: 0.8rem;
    width: 3.2rem;
  }
`;
