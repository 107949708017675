import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import service from 'Services/customers';

import Header from './Header';
import Contacts from './Contacts';

const Group = ({
  account,
  className,
  customersSelected,
  id,
  initialCustomers,
  isSelected,
  name,
  onGroupAdd,
  onGroupCustomersChange,
  totalContacts,
  withPhone,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [customers, setCustomers] = useState([]);

  const handleContactChange = ({ checked, customerId }) => {
    const customersNext = checked
      ? customersSelected.concat(customerId)
      : customersSelected.filter(item => item !== customerId);

    onGroupCustomersChange({ customers: customersNext, groupId: id });
  };

  const handleContactsToggle = async () => {
    setOpen(!isOpen);
    if (!isOpen) {
      setIsLoading(true);
      await service
        .getCustomerByGroup({ account, id })
        .then(({ ofaContacts }) => {
          setCustomers(ofaContacts);
          onGroupAdd({
            customers: ofaContacts.map(customer => customer.id),
            id,
            name,
            ofaContacts,
          });
        });
      setIsLoading(false);
    }
  };

  const isContactSelectedFn = customerId =>
    customersSelected.includes(customerId);

  return (
    <div className={className}>
      <Header
        initialCustomers={initialCustomers}
        isOpen={isOpen}
        isSelected={isSelected}
        name={name}
        onClick={handleContactsToggle}
        totalContacts={totalContacts}
        withPhone={withPhone}
      />
      {isOpen && (
        <Contacts
          customers={customers}
          isContactSelectedFn={isContactSelectedFn}
          isLoading={isLoading}
          onChange={handleContactChange}
        />
      )}
    </div>
  );
};

Group.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
  customersSelected: PropTypes.array.isRequired,
  id: PropTypes.number.isRequired,
  initialCustomers: PropTypes.number,
  isSelected: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onGroupAdd: PropTypes.func.isRequired,
  onGroupCustomersChange: PropTypes.func.isRequired,
  totalContacts: PropTypes.number,
  withPhone: PropTypes.number,
};

export default styled(Group)``;
