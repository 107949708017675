import React from 'react';
import PropTypes from '@prop-types';
import { withStyles as css } from '@styled-components';
import MultiCard from 'Components/MultiCard';
import { intlShape, withIntl } from '@react-intl';

import UsersByGender from 'Containers/UsersByGenderChart';
import UsersByLocaleChart from 'Containers/UsersByLocaleChart';
import UsersByOSChart from 'Containers/UsersByOSChart';
import UsersByPlatformChart from 'Containers/UsersByPlatformChart';

import PieChart from 'Components/Charts/PieChart';
import { compose } from 'underscore';

let content;

function generateContent(intl) {
  if (!content) {
    content = [
      {
        Component: UsersByGender,
        name: intl.formatMessage({ id: 'BY_GENDER' }),
        value: 'USERS_BY_GENDER',
      },
      {
        Component: UsersByLocaleChart,
        name: intl.formatMessage({ id: 'BY_LOCALE' }),
        value: 'USERS_BY_LOCALE',
      },
      {
        Component: UsersByOSChart,
        name: intl.formatMessage({ id: 'BY_OS' }),
        value: 'USERS_BY_OS',
      },
      {
        Component: UsersByPlatformChart,
        name: intl.formatMessage({ id: 'BY_PLATFORM' }),
        value: 'USERS_BY_PLATFORM',
      },
    ];
  }

  return content;
}

const Charts = ({ className, intl }) => (
  <MultiCard
    className={className}
    content={generateContent(intl)}
    title="USERS"
  />
);

Charts.propTypes = {
  className: PropTypes.string,
  intl: intlShape,
};

export default compose(
  css`
    display: flex;
    flex-direction: column;
    position: relative;

    ${PieChart} {
      align-items: flex-end;
      flex: 1;
      grid-template: 20rem auto / 1fr;
      padding-top: 2.4rem;
    }
  `,
  withIntl,
)(Charts);
