import { createService } from '@redux-act';

import request from 'Providers/request';
import {
  notifications as notificationsSchema,
  notificationsUpdated as notificationsUpdatedSchema,
} from 'Schemas';

const get = createService(async ({ account }, token) => {
  const {
    collection: notifications,
  } = await request(
    `/ofa/${account}/notifications`,
    'get',
    {},
    notificationsSchema,
    { token, ttl: 0 },
  );

  return notifications;
});

const update = createService(async ({ ids, status }, token) =>
  request(
    `/notifications/dashboard/update`,
    'post',
    {
      ids,
      status,
    },
    notificationsUpdatedSchema,
    { token },
  ),
);

export default {
  get,
  update,
};
