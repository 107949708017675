import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup } from 'react-transition-group';
import styled, { keyframes } from '@styled-components';

import Snack from './Snack';

class Snackbar extends Component {
  timeout = [];

  static propTypes = {
    className: PropTypes.string,
    duration: PropTypes.number,
    onDismiss: PropTypes.func,
    snacks: PropTypes.arrayOf(
      PropTypes.shape({
        message: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        uid: PropTypes.string.isRequired,
      }),
    ),
  };

  static defaultProps = {
    duration: 2500,
    onDismiss() {},
  };

  static getDerivedStateFromProps(props) {
    const { snacks } = props;

    return { snacks };
  }

  state = {};

  handleEnter = uid => () => {
    const { duration } = this.props;

    this.timeout[uid] = setTimeout(this.handleDismiss(uid), duration);
    // delete this.timeout[uid];
  };

  handleDismiss = uid => () => {
    const { onDismiss } = this.props;

    clearTimeout(this.timeout[uid]);

    this.setState(
      prevState => {
        const { snacks } = prevState;

        return {
          snacks: snacks.filter(({ uid: id }) => uid !== id),
        };
      },
      () => onDismiss(uid),
    );
  };

  render() {
    const { className } = this.props;
    const { snacks = [] } = this.state;

    return (
      <TransitionGroup className={className} component="section">
        {snacks.map(({ message, type, uid }) => (
          <Snack
            key={uid}
            duration={1000}
            onEnter={this.handleEnter(uid)}
            type={type}
          >
            {message}
          </Snack>
        ))}
      </TransitionGroup>
    );
  }
}

const fadeInFromBotton = keyframes`
  from {
    max-height: 0;
    min-height: 0;
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    max-height: 15rem;
    min-height: inherit;
  }
`;

const fadeOut = keyframes`
  to {
    opacity: 0;
    pointer-events: none;
    transform: scale(1.1, 1.1);
  }
`;

export default styled(Snackbar)`
  align-items: center;
  bottom: 1rem;
  display: flex;
  flex-flow: column;
  position: fixed;
  width: 100%;
  z-index: 10000;

  ${Snack} {
    margin-bottom: 1rem;
    overflow: hidden;
    transition: opacity 0.5s ease-out;

    &.enter {
      animation: ${fadeInFromBotton} 1s ease-out;
    }

    &.exit {
      animation: ${fadeOut} 0.5s ease-out;
      animation-fill-mode: forwards;
      height: 0;
      margin: 0;
      min-height: 0;
      transition: min-height 0.5s ease-out, margin 0.5s ease-out;
      transition-delay: 0.5s;
    }
  }
`;
