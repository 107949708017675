import C from 'Constants';

import cache from './cache';
import logger from './logger';
import rest, { OPERATION } from './rest';
import storage from './storage';
import { validateSchema } from './validation'; // It's own provider?

const { STORE } = C;
const { host, protocol } = window.location;
const basename = host.replace('admin.', '');

const API = process.env.API_URI || `${protocol}//call.${basename}/api/v1.0`;

cache.clear();

export async function request(
  resource,
  operation,
  raw,
  schema = {},
  options = {},
) {
  const { headers = {} } = options;
  const {
    session: { id, token },
  } = storage.get(STORE);
  const credentials = token ? { authKey: token, id } : {};

  const ContentType =
    headers['content-type'] || 'application/json; charset=utf-8';

  let content = raw;
  if (ContentType.includes('form-data')) {
    content = new FormData();
    Object.keys(raw).forEach(key => content.append(key, raw[key]));
  }

  const data = await rest(resource, operation, content, {
    ...options,
    baseURL: API,
    headers: { ...headers, ...credentials },
  });

  const error = validateSchema(schema, data);

  if (error) {
    logger.warning(error, { extra: { options, resource, ...raw } });
    // throw new Error(ERROR.SCHEMA);
  }

  return data;
}

Object.values(OPERATION).forEach(operation => {
  request[operation] = (resource, content, schema, options) =>
    request(resource, operation, content, schema, options);
});

export { createToken } from './rest';

export default request;
