import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '@react-intl';
import styled, { theme } from '@styled-components';

import Button from '../../Button';
import { useField } from 'spooner';

const InputHTML = styled('input').attrs({ type: 'file' })``;

const File = ({ className, ...props }) => {
  const {
    accept = '',
    disabled = false,
    multiple = false,
    name,
    onBlur = () => {},
    onChange = () => {},
    onFocus = () => {},
    value,
    ...fieldProps
  } = useField(props);

  const handleBlur = () => {
    onBlur();
  };

  const handleChange = event => {
    const { files } = event.target;

    event.stopPropagation();

    onChange(multiple ? files : files[0]);
  };

  const handleFocus = () => {
    onFocus();
  };

  const inputId = `input-file-${name}`;

  return (
    <Button
      secondary
      as="div"
      className={className}
      data-disabled={disabled}
      data-name={name}
    >
      <FormattedMessage
        capitalize
        component="label"
        htmlFor={inputId}
        id={value ? 'CHANGE_FILE' : 'CHOOSE_FILE'}
      />
      <InputHTML
        {...fieldProps}
        accept={accept}
        disabled={disabled}
        id={inputId}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
      />
    </Button>
  );
};

File.propTypes = {
  className: PropTypes.string,
};

export default styled(File)`
  background-color: ${theme('--color-light')};
  border-color: ${theme('--color-primary-40')};
  color: ${theme('--color-primary-80')};
  position: relative;
  text-align: center;
  text-transform: none;

  ${InputHTML} {
    cursor: pointer;
    height: 100%;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;
