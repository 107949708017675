import React from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from '@prop-types';
import { FormattedMessage } from '@react-intl';

import { Toggle } from 'Components/Form';

const ToggleBg = styled('span')``;
const ToggleState = styled('span')``;
const Coin = styled('span')``;

const View = ({
  className,
  name,
  id = 'ID',
  onChange = () => () => {},
  value = 'off',
}) => {
  return (
    <div className={className}>
      <FormattedMessage capitalize id={id} />
      <Toggle name={name} onChange={onChange(name)} value={value} />
    </div>
  );
};

View.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default styled(View)`
  align-items: center;
  background: ${theme('--color-blueish-white')};
  border-radius: 0.6rem;
  display: flex;
  justify-content: space-between;
  padding: 0.8rem;

  ${ToggleBg} {
    background: ${theme('--color-success')};
    border-radius: 2rem;
    height: 2.4rem;
    position: relative;
    width: 5.6rem;

    ${ToggleState} {
      ${theme('--font-small')}
      ${theme('--font-weight-medium')}
      color: ${theme('--color-light')};
      left: 0.8rem;
      position: absolute;
      top: 0.5rem;
    }

    ${Coin}{
      background: ${theme('--color-light')};
      border-radius: 50%;
      height: 1.6rem;
      left: 3.4rem;
      position: absolute;
      top: 0.4rem;
      width: 1.6rem;
      z-index: 10;
    }
  }
`;
