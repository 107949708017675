import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as logger from 'Actions/logger';
import { currentAccount } from 'Selectors';
import services from 'Services/campaigns';
import { useMultiDataProviderContext } from 'Containers/MultiDataProvider';

import CampaignForm from 'Components/CampaignForm';
import Confirmation from 'Components/CampaignForm/ConfirmationSave';
import Modal from 'Components/Modal';

const mapState = state => ({ account: currentAccount(state) });

const mapDispatch = dispatch =>
  bindActionCreators({ error: logger.error }, dispatch);

const AddCampaign = ({
  account,
  className,
  error,
  order,
  depth,
  in: modalIn,
  onClose,
  onBack,
}) => {
  const [data, setData] = useState({});
  const [confirmationIsOpen, setConfirmationOpen] = useState(false);
  const { refetch } = useMultiDataProviderContext('campaigns');

  const handleDataChange = params => setData({ ...data, ...params });

  const delay = time => {
    return new Promise(resolve => setTimeout(resolve, time));
  };

  const requestLimit = 2500;

  const addNextCustomer = async id => {
    if (data.customers[0].customers.length > requestLimit) {
      let start = requestLimit;
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const customerList = data.customers[0].customers.slice(
          start,
          start + requestLimit,
        );
        start += requestLimit;
        const body = {
          campaignId: id,
          customers: customerList,
        };
        // eslint-disable-next-line no-await-in-loop
        await delay(10000);
        services.addCustomer({ account, body, id });
        if (start >= data.customers[0].customers.length) break;
      }
    }
  };

  const handleCreate = () => {
    if (data.type === 'WHATSAPP') {
      const whatsapp = {
        languageCode: data.selectedMessageTemplate.language,
        templateName: data.selectedMessageTemplate.name,
      };
      const mediaType =
        data.file && data.file.type ? data.file.type.split('/')[0] : '';
      if (data.mediaUrl) {
        whatsapp.headers = [
          {
            [mediaType]: {
              link: data.mediaUrl,
            },
            type: mediaType,
          },
        ];
      }

      let message = '';
      const temp = {};
      if (data.messageTemplatesValue && data.selectedMessageTemplate) {
        whatsapp.body = data.messageTemplatesValue.map(text => {
          return {
            text,
            type: 'text',
          };
        });

        for (let i = 1; i <= data.messageTemplatesValue.length; i += 1) {
          temp[i] = data.messageTemplatesValue[i - 1];
        }

        // eslint-disable-next-line consistent-return, array-callback-return
        const template = data.selectedMessageTemplate.components.find(a => {
          if (a.type === 'BODY') return 1;
        }).text;

        message = template.replace(
          /\{\{[ ]{0,}([\w_-]{1,})[ ]{0,}\}\}/gi,
          function a(...match) {
            return typeof temp[match[1]] !== 'undefined' ? temp[match[1]] : '';
          },
        );
      }
      services
        .add({
          account,
          campaign: {
            cmds: [],
            customerGroups: [
              {
                customers: data.customers[0].customers.slice(0, requestLimit),
                id: data.customers[0].id,
                name: data.customers[0].name,
              },
            ],
            customers: data.customers[0].customers.slice(0, requestLimit),
            message,
            name: data.name,
            platform:
              data.messageCampaign && data.messageCampaign.platform
                ? data.messageCampaign.platform
                : data.type,
            type: data.type,
            whatsapp,
            ...data,
          },
        })
        .then(response => {
          addNextCustomer(response.id);
          setConfirmationOpen(true);
          refetch();
        })
        .catch(() => error('CAMPAIGN_NOT_CREATED'));
    } else {
      services
        .add({
          account,
          campaign: {
            customerGroups: [
              {
                customers: data.customers[0].customers.slice(0, requestLimit),
                id: data.customers[0].id,
                name: data.customers[0].name,
              },
            ],
            customers: data.customers[0].customers.slice(0, requestLimit),
            platform:
              data.messageCampaign && data.messageCampaign.platform
                ? data.messageCampaign.platform
                : data.type,
            ...data,
          },
        })
        .then(response => {
          addNextCustomer(response.id);
          setConfirmationOpen(true);
          refetch();
        })
        .catch(() => error('CAMPAIGN_NOT_CREATED'));
    }
  };

  const handleTest = phoneNumber =>
    services.sendTest({
      account,
      campaign: { message: data.message, phoneNumber },
    });

  const handleClose = () => {
    if (data.fileName && data.fileName.length > 0)
      services.deleteMedia(data.fileName);
    onClose();
  };

  return (
    <>
      <Confirmation
        isCreated
        in={confirmationIsOpen}
        onAccept={onBack}
        title="YOUR_CAMPAIGN_HAS_BEEN_CREATED"
      />
      <Modal
        className={className}
        depth={depth}
        in={!confirmationIsOpen ? modalIn : false}
        onBack={onBack}
        onClose={handleClose}
        onTest={handleTest}
        order={order}
        title="NEW_CAMPAIGN"
      >
        <CampaignForm
          account={account}
          data={data}
          heading="INSTRUCTIONS.CREATE_NEW_CAMPAIGN"
          onChange={handleDataChange}
          onError={error}
          onSubmit={handleCreate}
          onTest={handleTest}
          submitButtonLabel="ACTIONS.SCHEDULE_CAMPAIGN"
          title="ACTIONS.CREATE_NEW_CAMPAIGN"
        />
      </Modal>
    </>
  );
};

AddCampaign.propTypes = {
  account: PropTypes.number.isRequired,
  className: PropTypes.string,
  depth: PropTypes.number,
  error: PropTypes.func.isRequired,
  in: PropTypes.bool,
  onBack: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  order: PropTypes.number,
};

export default connect(mapState, mapDispatch)(AddCampaign);
