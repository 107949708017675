import { createService } from '@redux-act';
import { v4 as uuid } from 'uuid';

import request from 'Providers/request';

import {
  products as productsSchema,
  bestSellers as bestSellersSchema,
  mostViewed as mostViewedSchema,
} from 'Schemas';

const findOne = createService(async ({ account, id }, token) =>
  request(`/ofa/${account}/products/${id}`, 'get', {}, {}, { token }),
);

async function getBestSellers({ account, limit, offset }) {
  const { collection, total } = await request(
    `/analytics/best_sellers`,
    'get',
    {
      limit,
      ofaId: account,
      offset,
    },
    bestSellersSchema,
  );

  return {
    collection: collection.map(item => ({ ...item, key: uuid() })),
    total,
  };
}

async function getMostViewed({ account, limit, offset }) {
  const { collection, total } = await request(
    `/analytics/most_viewed_products`,
    'get',
    {
      limit,
      ofaId: account,
      offset,
    },
    mostViewedSchema,
  );

  return {
    collection: collection.map(item => ({ ...item, key: uuid() })),
    total,
  };
}

const getVariants = createService(
  async ({ account, id, offset, search, limit }, token) => {
    const { collection, total } = await request(
      `/ofa/${account}/products/${id}/variants`,
      'get',
      {
        limit,
        offset,
        ...(search && { search }),
      },
      {},
      { token },
    );

    return {
      collection: collection.map(variant => ({ ...variant, key: variant.id })),
      total,
    };
  },
);

const get = createService(
  async ({ account, limit, offset, search, order, sortBy }, token) => {
    const { collection = [], total } = await request(
      `/ofa/${account}/products`,
      'get',
      {
        limit,
        offset,
        ...(order && { order }),
        ...(search && { search }),
        ...(sortBy && { sortBy }),
      },
      productsSchema,
      { token, ttl: 0 },
    );
    return {
      collection: collection.map(product => ({ ...product, key: product.id })),
      total,
    };
  },
);

export default {
  findOne,
  get,
  getBestSellers,
  getMostViewed,
  getVariants,
};
