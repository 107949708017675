import React from 'react';
import PropTypes from '@prop-types';
import styled, { css, theme } from '@styled-components';
import Loader from 'Components/Loader';

const primaryMixin = css`
  background-color: ${theme('--color-primary')};
  color: ${theme('--color-light')};
  padding: 1.2rem;

  &:active {
    background-color: ${theme('--color-primary-120')};
  }

  &[disabled] {
    background-color: ${theme('--color-dark-night-05')};
  }
`;

const secondaryMixin = css`
  background: none;
  border: 1px solid ${theme('--color-primary')};
  color: ${theme('--color-primary')};
  padding: 1.2rem;

  &:active {
    border-color: ${theme('--color-primary-50')};
    color: ${theme('--color-primary-50')};
  }

  &[disabled] {
    border-color: ${theme('--color-dark-night-20')};
  }
`;

const floatingMixin = css`
  ${theme('--font-weight-book')}
  background-color: ${theme('--color-primary')};
  border-radius: 50%;
  color: ${theme('--color-light')};
  cursor: pointer;
  font-size: 3.2rem;
  height: 4.8rem;
  transition: background-color 0.25s ease-out;
  width: 4.8rem;

  &:active {
    background-color: ${theme('--color-primary-120')};
  }

  &[disabled] {
    background-color: ${theme('--color-dark-night-05')};
  }
`;

const Button = ({
  className,
  children,
  floating,
  isLoading,
  primary,
  secondary,
  ...props
}) => {
  return (
    <button className={className} type="button" {...props}>
      {isLoading && <Loader />}
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.children,
  className: PropTypes.string,
  floating: PropTypes.bool,
  isLoading: PropTypes.bool,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
};

export default styled(Button)`
  ${theme('--font-medium')}
  ${theme('--font-weight-medium')}
  background: none;
  border: none;
  border-radius: 0.6rem;
  cursor: pointer;
  outline: 0;
  transition: background-color 0.25s ease-out, box-shadow 0.25s ease-out;
  ${props => props.floating && floatingMixin}
  ${props => props.primary && primaryMixin}
  ${props => props.secondary && secondaryMixin}
  
  &:focus {
    box-shadow: 0 0 0 0.1rem ${theme('--color-light')},
      0 0 0 0.4rem ${theme('--color-primary-40')};
  }
  
  &[disabled] {
    ${theme('--font-opacity-40')}
  }
`;
