import React from 'react';
import { withStyles as css, theme } from '@styled-components';
import PropTypes from '@prop-types';
import { compose } from 'underscore';
import { withPlaceholder } from 'Containers/Placeholder/index';

const Wrapper = ({ className, children }) => {
  return <div className={className}>{children}</div>;
};

Wrapper.propTypes = {
  children: PropTypes.children,
  className: PropTypes.string,
};

export default compose(
  css`
    height: calc(100vh - 8rem);
    overflow-y: scroll;
    padding: 2.4rem 2rem;
    width: 100%;

    @media (${theme('--column-12')}) {
      padding: 4rem 3.2rem;
    }
  `,
  withPlaceholder,
)(Wrapper);
