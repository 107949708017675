import React, { Component } from 'react';
import PropTypes from '@prop-types';

import { matchPath, Route, withRouter } from 'Components/Router';

class Layout extends Component {
  static propTypes = {
    children: PropTypes.children,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  };

  match(children, route) {
    const { location: own } = this.props;
    const { location: context } = route;

    const location = own || context;

    return React.Children.toArray(children).reduce((result, element) => {
      if (!result && React.isValidElement(element)) {
        if (element.type.toString() === Route.toString()) {
          const {
            path: pathProp,
            exact,
            from,
            sensitive,
            strict,
          } = element.props;
          const path = pathProp || from;

          const isMatch = path
            ? matchPath(location.pathname, { exact, path, sensitive, strict })
            : route.match;

          return isMatch && element;
        }

        return this.match(element.props.children, route) && element;
      }

      return result;
    }, null);
  }

  render() {
    const { children, ...route } = this.props;

    return this.match(children, route);
  }
}

export default withRouter(Layout);
