import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styled, { theme } from '@styled-components';

import Button from 'Components/Button';

const Toolbar = ({ className, onClick: handleClick = () => {} }) => {
  const [element, setElement] = useState();

  useEffect(() => {
    setElement(document.getElementById('toolbar'));
  }, [setElement]);

  if (!element) return false;

  return createPortal(
    <div className={className}>
      <Button capitalize translate onClick={handleClick}>
        TEST_IT_NOW
      </Button>
    </div>,
    element,
  );
};

export default styled(Toolbar)`
  margin-right: 2.4rem;
  ${Button} {
    color: ${theme('--color-primary')};
  }
`;
