import { createService } from '@redux-act';

import request from 'Providers/request';

import {
  averagePurchaseValueByTime as averagePurchaseValueByTimeSchema,
  customers as customersSchema,
  customerDetails as customerDetailsSchema,
} from 'Schemas';

const add = createService(({ account, customer }, token) =>
  request.create('/customers', { ofaId: account, ...customer }, {}, { token }),
);

async function campaigns({ account, id, limit, offset, searchBy }) {
  const { collection, total } = await request.read(
    `/customers/${id}/campaigns`,
    {
      limit,
      ofaId: account,
      offset,
      ...(searchBy && { searchBy }),
    },
  );

  return {
    collection,
    total,
  };
}

const surveys = createService(async ({ account, customerId }) => {
  return request(`/ofa/${account}/customers/${customerId}/surveys`, 'get', {});
});

async function edit({ account, customer, id }) {
  await request.update(`/customers/${id}`, { ofaId: account, ...customer });
}

const find = createService(
  async (
    {
      account,
      filterBy,
      from,
      groupBy,
      limit,
      offset,
      order,
      sortBy,
      searchBy,
      to,
    },
    token,
  ) => {
    const { collection: rawCustomers, total } = await request.read(
      '/customers',
      {
        filterBy,
        from,
        groupBy,
        limit,
        ofaId: account,
        offset,
        order,
        searchBy,
        sortBy,
        to,
      },
      {},
      { token, ttl: 0 },
    );
    const customers = rawCustomers.map(customer => {
      const timeOffset =
        customer.timezone >= 0
          ? `-${customer.timezone}`
          : `+${Math.abs(customer.timezone)}`;

      return {
        ...customer,
        createdAt: new Date(customer.createdAt),
        key: customer.id,
        timezone: customer.timezone !== undefined && `Etc/GMT${timeOffset}`,
        updatedAt: new Date(customer.updatedAt),
      };
    });

    return {
      collection: customers,
      total,
    };
  },
);

const findOne = createService(async ({ account, currency, id }, token) => {
  const customer = await request.read(
    `/customers/${id}`,
    { currency, ofaId: account },
    {},
    { token },
  );

  const offset =
    customer.timezone >= 0
      ? `-${customer.timezone}`
      : `+${Math.abs(customer.timezone)}`;

  customer.createdAt = new Date(customer.createdAt);
  customer.lastActivity = new Date(customer.lastActivity || customer.updatedAt);
  customer.updatedAt = new Date(customer.updatedAt);
  customer.timezone = customer.timezone && `Etc/GMT${offset}`;

  return customer;
});

const exportCustomers = createService(({ account, orderBy, groupBy }) =>
  request.create('/export', { groupBy, ofaId: account, orderBy }),
);

const get = createService(
  async (
    { account, filters: { groupBy, search, orderBy } = {}, limit, offset },
    token,
  ) => {
    const params = {
      limit,
      ofaId: account,
      offset,
      ...(groupBy && { groupBy }),
      ...(search && { search }),
      ...(orderBy && { orderBy }),
    };

    const {
      collection: rawCustomers,
      total,
    } = await request(
      `/analytics/customer_list`,
      'get',
      params,
      customersSchema,
      { token, ttl: 0 },
    );
    const customers = rawCustomers.map(customer => {
      const timeOffset =
        customer.timezone >= 0 ? `+${customer.timezone}` : customer.timezone;

      return {
        ...customer,
        campaigns: customer.campaigns.reduce(
          (result, { campaignId, step }) => ({ ...result, [campaignId]: step }),
          {},
        ),
        fullName: `${customer.firstName} ${customer.lastName}`,
        images: {
          large: customer.imageURL_normal,
          medium: customer.imageURL_small,
          small: customer.imageURL_thumb,
        },
        platforms: [customer.type],
        timezone: customer.timezone !== undefined && `Etc/GMT${timeOffset}`,
      };
    });

    return {
      collection: customers,
      total,
    };
  },
);

const getAveragePurchaseValueByTime = createService(
  async ({ account, currency, from, to, unit }, token) => {
    const { average_customer_value: customers } = await request.read(
      '/analytics/average_customer',
      {
        currency,
        from,
        ofaId: account,
        to,
        unit,
      },
      averagePurchaseValueByTimeSchema,
      { token },
    );

    return { currency, customers };
  },
);

const getCustomerDetails = createService(async (ofaId, profileId, token) => {
  const { devices_w_duration: rawDevices, join, ...props } = await request(
    '/ofa_user_detail',
    'get',
    {
      ofaId,
      profileId,
    },
    customerDetailsSchema,
    { token },
  );

  const devicesWDuration = rawDevices.reduce(
    (acc, device) => ({ ...acc, ...device }),
    {},
  );

  return { ...props, devicesWDuration, join: new Date(join) };
});

const getPlatformUsageByUser = createService(async ({ account, id }, token) => {
  const { data: payments } = await request.read(
    `/customers/${id}/platforms`,
    { ofaId: account },
    {},
    { token },
  );
  return payments.reduce(
    (result, item) => ({
      ...result,
      [item.value]: Number(item.count),
    }),
    {},
  );
});

const groups = createService(({ account }, token) =>
  request.read(`/ofa/${account}/groups`, {}, {}, { token }),
);

const getCustomerGroups = createService(({ account }, token) =>
  request.read(`/ofa/${account}/groups/v2`, {}, {}, { token }),
);

const getCustomerByGroup = createService(({ account, id }, token) =>
  request.read(
    `/ofa/${account}/group/${id}/customers?mode=1`,
    {},
    {},
    { token },
  ),
);

const importCustomers = createService(
  ({ account, customers, groupNames }, token) =>
    request.create(
      `/customers/import?ofaId=${account}`,
      {
        '': customers,
        groupNames: JSON.stringify(groupNames),
      },
      {},
      {
        headers: {
          'content-type': 'form-data/multipart',
        },
        token,
      },
    ),
);

const importCustomersBackground = createService(
  ({ account, customers, groupNames }, token) =>
    request.create(
      `/customers/import?ofaId=${account}&background=1`,
      {
        '': customers,
        groupNames: JSON.stringify(groupNames),
      },
      {},
      {
        headers: {
          'content-type': 'form-data/multipart',
        },
        token,
      },
    ),
);

const messageTemplates = createService(({ account }, token) =>
  request.read(`/whatsapp/message_templates/${account}`, {}, {}, { token }),
);

const remove = createService(({ account, id }, token) =>
  request.delete(`/customers/${id}`, { ofaId: account }, {}, { token }),
);

async function timeline({ account, id, limit, offset }) {
  const [
    {
      timeline: { events: rawEvents, nextOffset },
    },
  ] = await request.read(`/customers/${id}/timeline_all`, {
    limit,
    ofaId: account,
    offset,
  });

  const events = rawEvents.map(event => ({
    ...event,
    date: new Date(event.createdAt),
    timezone:
      event.timezone !== undefined &&
      `Etc/GMT${
        event.timezone >= 0
          ? `-${event.timezone}`
          : `+${Math.abs(event.timezone)}`
      }`,
    title: event.name,
  }));

  return {
    events,
    isLast: nextOffset === -1,
  };
}

function getAverageOrder({ account, currency }) {
  return request.read(`ofa/${account}/average_order`, {
    currency,
  });
}
function getNewCustomers({ account }) {
  const unit = 'month';
  const Today = new Date();
  const StartOfMonth = new Date();
  StartOfMonth.setDate(1);
  const from = StartOfMonth.toISOString();
  const to = Today.toISOString();

  return request.read(`ofa/${account}/new_customers`, {
    from,
    to,
    unit,
  });
}

function getActiveCustomers({ account }) {
  const unit = 'month';
  const Today = new Date();
  const StartOfMonth = new Date();
  StartOfMonth.setDate(1);
  const from = StartOfMonth.toISOString();
  const to = Today.toISOString();

  return request.read(`ofa/${account}/active_customers`, {
    from,
    to,
    unit,
  });
}

function getTotalCustomers({ account, currency }) {
  return request.read(`ofa/${account}/total_customers`, {
    currency,
  });
}

async function getCustomerData({ account, currency }) {
  const [
    averageOrder,
    newCustomers,
    activeCustomers,
    totalCustomers,
  ] = await Promise.all([
    getAverageOrder({ account, currency }),
    getNewCustomers({ account }),
    getActiveCustomers({ account }),
    getTotalCustomers({ account }),
  ]);

  return { activeCustomers, averageOrder, newCustomers, totalCustomers };
}

const tagAdd = createService(({ account, tag }, token) =>
  request.create(`/ofa/${account}/customer/tags`, tag, {}, { token }),
);

const tagsGet = createService(({ account }, token) =>
  request.read(`/ofa/${account}/customer/tags`, {}, {}, { token }),
);

export default {
  add,
  campaigns,
  edit,
  export: exportCustomers,
  find,
  findOne,
  get,
  getActiveCustomers,
  getAverageOrder,
  getAveragePurchaseValueByTime,
  getCustomerByGroup,
  getCustomerData,
  getCustomerDetails,
  getCustomerGroups,
  getNewCustomers,
  getPlatformUsageByUser,
  getTotalCustomers,
  groups,
  import: importCustomers,
  importCustomersBackground,
  messageTemplates,
  remove,
  surveys,
  tagAdd,
  tagsGet,
  timeline,
};
