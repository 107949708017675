import React from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';

import Card from 'Components/Card';
import Map, { Marker } from 'Components/Map';

const UserLocation = ({ className, markers = [] }) => (
  <Card className={className} title="USER_LOCATION">
    <Map
      doubleClickZoom={false}
      lat={20}
      lng={-30}
      scrollWheelZoom={false}
      zoom={1.5}
      zoomControl={false}
    >
      {markers.map(({ lat, lng }) => (
        <Marker key={`${lat},${lng}`} lat={lat} lng={lng} />
      ))}
    </Map>
  </Card>
);

UserLocation.propTypes = {
  className: PropTypes.string,
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
  ),
};

export default styled(UserLocation)`
  height: 48rem;

  ${Map} {
    height: 100%;
    width: 100%;
  }

  .leaflet-interactive {
    cursor: auto;
  }
`;
