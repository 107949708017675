import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@styled-components';

import { currentAccount } from 'Selectors';

import TransactionsTable from './TransactionsTable';

const mapState = state => ({ account: currentAccount(state) });

const Transactions = props => (
  <TransactionsTable
    {...props}
    from={moment()
      .subtract(1, 'month')
      .format()}
    to={moment().format()}
  />
);

Transactions.propTypes = {
  account: PropTypes.number,
};

export default compose(withStyles``, connect(mapState))(Transactions);
