import React from 'react';
import PropTypes from '@prop-types';
import styled, { theme } from '@styled-components';
import { FormattedMessage } from '@react-intl';

import { withPlaceholder } from 'Containers/Placeholder';

import EmptyTemplate from './EmptyTemplate';

const Title = styled('h4')``;
const Placeholder = styled(withPlaceholder('section')).attrs({
  placeholder: 'address placeholder',
})``;

const Content = styled('p')``;

const Email = ({ className, email, phone }) => {
  const hasFields = !!email || !!phone;

  return (
    <section className={className}>
      <FormattedMessage capitalize component={Title} id="EMAIL_PHONE">
        email and phone
      </FormattedMessage>
      <Placeholder>
        {hasFields ? (
          <>
            <Content>{email}</Content>
            <Content>{phone}</Content>
          </>
        ) : (
          <EmptyTemplate />
        )}
      </Placeholder>
    </section>
  );
};

Email.propTypes = {
  className: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
};

export default styled(Email)`
  ${Title} {
    ${theme('--font-large')}
    ${theme('--font-weight-demi')}
    ${theme('--font-opacity-80')}
    margin-bottom: 0.8rem;
  }

  ${Content} {
    ${theme('--font-large')}
    ${theme('--font-weight-book')}
    ${theme('--font-opacity-40')}
    word-break: break-word;
  }
`;
