import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Group from './Group';

const GroupList = ({ className, groups, onGroupAdd, onListToggle }) => {
  return (
    <div className={className}>
      {groups.map(group => (
        <Group
          key={group.id}
          components={group.components}
          customers={group}
          id={group.id}
          name={group.name}
          onGroupAdd={onGroupAdd}
          onListToggle={onListToggle}
        />
      ))}
    </div>
  );
};

GroupList.propTypes = {
  className: PropTypes.string,
  groups: PropTypes.array.isRequired,
  onGroupAdd: PropTypes.func.isRequired,
  onListToggle: PropTypes.func.isRequired,
};

export default styled(GroupList)`
  background: #fff;
  border-radius: 0 0 0.6rem 0.6rem;
  box-shadow: 0px 1px 0px rgba(0, 15, 25, 0.05),
    0px 8px 16px rgba(0, 15, 25, 0.05);
  height: 28.8rem;
  overflow-y: scroll;
`;
