import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from '@prop-types';
import styled, { theme } from '@styled-components';

import { useMultiDataProviderContext } from 'Containers/MultiDataProvider';

import Modal from 'Components/Modal';
import Stepper from 'Components/Stepper';

import services from 'Services/microapps';

import AddTitle from './components/AddTitle';
import Confirm from './components/Confirm';
import Toolbar from './components/Toolbar';

const Form = styled('div')``;
const Wrapper = styled('div')``;

const CreateMicroapp = ({
  account,
  className,
  defaultValue = {},
  editMode = false,
  modalTitle = 'NEW_TEMPLATE',
  template: Template,
  ...props
}) => {
  const [currentStep, setStep] = useState(0);
  const [currentForm, setForm] = useState({});
  const { push } = useHistory();
  const { refetch } = useMultiDataProviderContext('microapps');
  const [additionalFormFields, setAdditionalFormFields] = useState({});

  const steps = [AddTitle, Template];
  const Step = steps[currentStep];

  const isLast = currentStep === steps.length;
  const showToolbar = currentStep === 1;

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (Object.keys(defaultValue).length) {
      setForm(defaultValue);
    }
  }, [Object.keys(defaultValue).length]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const handleBack = useCallback(() => {
    setStep(previousStep => previousStep - 1);
  }, [setStep]);

  const handleNext = useCallback(() => {
    setStep(previousStep => previousStep + 1);
  }, [setStep]);

  const handleStep = useCallback(
    form => {
      setForm(previousForm => ({ ...previousForm, ...form }));
    },
    [setForm],
  );

  const handleStepper = useCallback(
    (step, index) => {
      setStep(index);
    },
    [setStep],
  );

  const handleRequest = useCallback(
    async fields => {
      const { url } = await services[editMode ? 'update' : 'create']({
        account,
        fields,
      });
      return url;
    },
    [account, editMode],
  );

  const handleSubmit = useCallback(
    async fields => {
      await handleRequest({
        ...currentForm,
        ...fields,
        ...additionalFormFields,
      });
      refetch();
      push('/builder');
    },
    [additionalFormFields, currentForm, handleRequest, push, refetch],
  );

  const handleTest = useCallback(async () => {
    const fields = Object.keys(currentForm).length ? currentForm : defaultValue;
    const { url } = await services.createTest({
      account,
      fields: { name: Date.now(), ...fields, ...additionalFormFields },
    });

    window.open(url, 'blank');
  }, [account, additionalFormFields, currentForm, defaultValue]);

  const handleAdditionalFormFields = nextField => {
    setAdditionalFormFields({
      ...additionalFormFields,
      ...nextField,
    });
  };

  return isLast ? (
    <Confirm
      {...props}
      defaultValue={{ name: defaultValue.name }}
      onSubmit={handleSubmit}
    />
  ) : (
    <Modal className={className} {...props} title={modalTitle}>
      <Wrapper>
        <Stepper
          vertical
          currentStep={currentStep}
          onChange={handleStepper}
          steps={steps}
          tooltip={false}
        />
        <Form>
          <Step
            {...props}
            account={account}
            defaultValue={defaultValue}
            handleAdditionalFormFields={handleAdditionalFormFields}
            onBack={handleBack}
            onChange={handleStep}
            onSubmit={handleNext}
          />
        </Form>
      </Wrapper>
      {showToolbar && <Toolbar onClick={handleTest} />}
    </Modal>
  );
};

CreateMicroapp.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
  defaultValue: PropTypes.object,
  editMode: PropTypes.boolean,
  modalTitle: PropTypes.string,
  template: PropTypes.component,
};

export default styled(CreateMicroapp)`
  ${Stepper} {
    display: none;
  }

  @media (${theme('--column-12')}) {
    ${Wrapper} {
      display: grid;
      grid-template: auto 1fr / repeat(12, 1fr);
    }

    ${Stepper} {
      display: flex;
      grid-column: 3 / span 1;
      height: 10rem;
      margin-top: 6rem;
      min-height: auto;
    }

    ${Form} {
      grid-column: 4 / span 7;
      grid-row: 1 / span 2;
    }
  }
`;
