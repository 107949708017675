import React from 'react';
import PropTypes from '@prop-types';
import styled, { theme } from '@styled-components';

const Item = ({ className, children }) => (
  <li className={className}>{children}</li>
);

Item.propTypes = {
  children: PropTypes.children,
  className: PropTypes.string,
};

export default styled(Item)`
  height: 4.8rem;
  min-width: 11.5rem;
  transition: background-color 0.35s ease-out;

  &:hover {
    ${theme('--font-opacity-100')}
    background: ${theme('--color-primary-5')}; /* PRIMARY 05 */
  }
`;
