import React, { forwardRef, memo } from 'react';
import PropTypes from '@prop-types';
import { FormattedMessage as Message } from 'react-intl';

import transform from './transform';

const FormattedMessage = ({
  children,
  capitalize = false,
  component: Wrapper = 'span',
  defaultMessage = Array.isArray(children) ? children.join('') : children,
  forwardedRef,
  id = defaultMessage,
  uppercase = false,
  values,
  ...props
}) => (
  <Message {...props} defaultMessage={defaultMessage} id={id} values={values}>
    {message => (
      <Wrapper {...props} ref={forwardedRef}>
        {transform(message, { capitalize, uppercase })}
      </Wrapper>
    )}
  </Message>
);

FormattedMessage.propTypes = {
  capitalize: PropTypes.bool,
  children: PropTypes.children,
  component: PropTypes.component,
  defaultMessage: PropTypes.string,
  forwardedRef: PropTypes.ref,
  id: PropTypes.string,
  uppercase: PropTypes.bool,
  values: PropTypes.object,
};

export default memo(
  // eslint-disable-next-line react/display-name
  forwardRef((props, forwardedRef) => (
    <FormattedMessage {...props} forwardedRef={forwardedRef} />
  )),
);
