import styled, { theme } from '@styled-components';

export const Heading = styled('h3')`
  ${theme('--font-medium')}
  ${theme('--font-opacity-80')}
  ${theme('--font-weight-medium')}
  margin-bottom: 0.8rem;
`;

export const Card = styled('div')`
  background: ${theme('--color-light')};
  border-radius: 0.6rem;
  box-shadow: inset 0 -0.1rem 0 rgba(0, 15, 25, 0.05);
  width: 100%;
`;

export const Advise = styled('p')`
  ${theme('--font-medium')}
  ${theme('--font-weight-book')}
  ${theme('--font-opacity-60')}
`;
