import React from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';

const LabelText = styled('span')``;

const Circle = styled('div')`
  background-color: ${props => props.color};
`;

const VictoryTooltipLabel = ({ circleColor, className, text, x, y }) => {
  const xShifted = x - 19;

  return (
    <g style={{ pointerEvents: 'none' }}>
      <foreignObject height="50" width="100" x={xShifted} y={y}>
        <div className={className}>
          <Circle color={circleColor} />
          <LabelText>{text}</LabelText>
        </div>
      </foreignObject>
    </g>
  );
};

VictoryTooltipLabel.propTypes = {
  circleColor: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
};

export default styled(VictoryTooltipLabel)`
  background: ${theme('--color-dark-night')};
  border-radius: 0.5rem;
  color: ${theme('--color-light')};
  display: inline-block;
  padding: 1rem 1.5rem;

  ${Circle} {
    border-radius: 0.4rem;
    display: inline-block;
    height: 0.8rem;
    width: 0.8rem;
  }

  ${LabelText} {
    ${theme('--font-small')}
    margin-left: 0.5rem;
  }
`;
