import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withStyles as css } from '@styled-components';
import { intlDefault, intlShape, withIntl } from '@react-intl';
import _ from 'underscore';

import { Select } from 'Components/Form';

class Filters extends Component {
  static propTypes = {
    className: PropTypes.string,
    intl: intlShape,
    onChange: PropTypes.func,
    value: PropTypes.string,
  };

  static defaultProps = {
    intl: intlDefault,
    onChange() {},
  };

  getOptions = _.memoize(() => {
    const { intl } = this.props;

    return [
      {
        disabled: true,
        name: intl.formatMessage({ capitalize: true, id: 'FILTER_BY' }),
      },
      {
        name: intl.formatMessage({ capitalize: true, id: 'ALL' }),
        value: 'ALL',
      },
      {
        name: intl.formatMessage({ capitalize: true, id: 'PAYMENT' }),
        value: 'PAYMENT',
      },
      {
        name: intl.formatMessage({ capitalize: true, id: 'MESSAGING' }),
        value: 'MESSAGING',
      },
      {
        name: intl.formatMessage({ capitalize: true, id: 'CRM' }),
        value: 'CRM',
      },
      {
        name: intl.formatMessage({ capitalize: true, id: 'CMS' }),
        value: 'CMS',
      },

      {
        name: intl.formatMessage({ capitalize: true, id: 'Q&A' }),
        value: 'Q&A',
      },
      {
        name: intl.formatMessage({ capitalize: true, id: 'CONVERSATION' }),
        value: 'CONVERSATION',
      },
      {
        name: intl.formatMessage({ capitalize: true, id: 'SCHEDULING' }),
        value: 'SCHEDULING',
      },
      {
        name: intl.formatMessage({ capitalize: true, id: 'EMAIL_MARKETING' }),
        value: 'EMAIL_MARKETING',
      },
      {
        name: intl.formatMessage({ capitalize: true, id: 'MICRO_APP' }),
        value: 'MICRO_APP',
      },
    ];
  });

  handleChange = value => {
    const { onChange: handleChange } = this.props;
    if (value) {
      handleChange(value);
    }
  };

  render() {
    const { className, value } = this.props;

    const options = this.getOptions();
    const { name } =
      options.find(({ value: optionValue }) => optionValue === value) ||
      options[0];
    return (
      <Select
        className={className}
        name={name}
        onChange={this.handleChange}
        options={options}
        value={value}
      />
    );
  }
}

export default compose(
  css`
    min-width: 20rem;
  `,
  withIntl,
)(Filters);
