import React, { useCallback } from 'react';
import PropTypes from '@prop-types';
import { connect } from 'react-redux';

import * as logger from 'Actions/logger';

import Modal from 'Components/Modal';

import DataProvider from 'Containers/DataProvider';

import { account } from 'Selectors';

import Services from 'Services/accounts';

import View from './Company';

const Company = ({
  depth,
  in: modalIn,
  logoType: logo,
  onBack,
  onClose,
  order,
  id,
  success,
  error,
}) => {
  const handleSubmit = useCallback(
    async ({ primaryColor, secondaryColor, companyName, logo: l }) => {
      try {
        await Services.putSettings({
          account: id,
          companyName,
          logo: l,
          primaryColor,
          secondaryColor,
        });
        success('COMPANY_MODIFIED');
        onClose();
      } catch (e) {
        error('COMPANY_NOT_MODIFIED');
        onClose();
      }
    },
    [error, id, onClose, success],
  );
  return (
    <Modal
      depth={depth}
      in={modalIn}
      onBack={onBack}
      onClose={onClose}
      order={order}
      title="EDIT_COMPANY"
    >
      <DataProvider
        logo={logo}
        onClose={onClose}
        onSubmit={handleSubmit}
        params={{ account: id }}
        query={Services.getOfaSettings}
        template={View}
      />
    </Modal>
  );
};

Company.propTypes = {
  depth: PropTypes.number,
  error: PropTypes.func,
  id: PropTypes.string,
  in: PropTypes.bool,
  logoType: PropTypes.string,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  order: PropTypes.number,
  success: PropTypes.func,
};

const mapStateToProps = state => ({
  ...account(state),
});

const mapDispatchToProps = { error: logger.error, success: logger.success };

export default connect(mapStateToProps, mapDispatchToProps)(Company);
