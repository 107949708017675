import React from 'react';
import PropTypes from '@prop-types';
import { compose } from 'underscore';

import ContextMenu from 'Components/ContextMenu';
import { withRouter } from 'Components/Router';
import withPermissions from 'Components/with/permissions';

import C from 'Constants';

const {
  ROLE: { ADMIN, SUPER_ADMIN },
} = C;

const Toolbar = ({
  history: { push },
  location: { pathname },
  showDelete,
  showStop,
  type,
}) => {
  const options = {};

  if (type === 'SMS') {
    options.EDIT_CAMPAIGN = () => push(`${pathname}/edit`);
    options.STOP_CAMPAIGN = showStop;
  }

  options.DELETE_CAMPAIGN = showDelete;

  return <ContextMenu right options={options} />;
};

Toolbar.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  showDelete: PropTypes.func,
  showStop: PropTypes.func,
  type: PropTypes.string,
};

export default compose(
  withRouter,
  withPermissions([SUPER_ADMIN, ADMIN]),
)(Toolbar);
