import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles, theme } from '@styled-components';

import DataProvider from 'Containers/DataProvider/index';

import Card, { Title } from 'Components/Card';

import service from 'Services/revenues';
import { currentAccount } from 'Selectors';

import Template from './Template';
import EmptyTemplate from './EmptyTemplate';

const mapStateToProps = state => ({
  account: currentAccount(state),
});

const PaymentMethodsChart = ({ account, className }) => (
  <Card className={className} title="PAYMENT_PLATFORM_USAGE">
    <DataProvider
      emptyTemplate={EmptyTemplate}
      params={{ account }}
      query={service.getPaymentMethods}
      template={Template}
    />
  </Card>
);

PaymentMethodsChart.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
};

export default compose(
  connect(mapStateToProps),
  withStyles`
    display: grid;
    padding-top: 13.6rem

    ${Title} {
      ${theme('--font-medium')}
      ${theme('--font-weight-book')}
      color: ${theme('--color-dark-night-60')};
    }
  `,
)(PaymentMethodsChart);
