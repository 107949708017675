import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled, { theme } from '@styled-components';

import service from 'Services/customers';

import Button from 'Components/Button';
import ImportCostumer from 'Modals/ImportCustomer';

import Actions from './Actions';
import SectionHeadline from './SectionHeadline';
import GroupsPicker from './GroupsPicker';
import Confirmation from '../modal/Confirmation';

const GroupPickerBlock = styled('div')``;
const ButtonWhite = styled(Button)``;

const Customers = ({
  activateActions,
  account,
  className,
  customers = [],
  onBack,
  onChange,
  onNext,
}) => {
  const [customerGroups, setCustomerGroups] = useState([]);
  const [importOpen, setImportOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [enrolled, setEnrolled] = useState(true);

  const handleChange = value => onChange({ customers: value });

  const handleToggle = () => setImportOpen(!importOpen);

  const handleToggleSuccess = async () => {
    await service
      .getCustomerGroups({ account })
      .then(({ ofaGroups }) => setCustomerGroups(ofaGroups));
    setImportOpen(!importOpen);
  };

  const handleCancel = () => setConfirmationOpen(false);

  const handleConfirmation = () => {
    if (!enrolled) {
      setConfirmationOpen(true);
    } else {
      onNext();
    }
  };

  useEffect(() => {
    service
      .getCustomerGroups({ account })
      .then(({ ofaGroups }) => setCustomerGroups(ofaGroups));
  }, [account]);

  return (
    <div className={className}>
      <ImportCostumer
        account={account}
        in={importOpen}
        onClose={handleToggle}
        onSuccess={handleToggleSuccess}
        order={0}
      />
      <Confirmation
        customers={customers}
        handleEnrolled={setEnrolled}
        in={confirmationOpen}
        onAccept={onNext}
        onCancel={handleCancel}
      />
      <SectionHeadline
        heading="INSTRUCTIONS.SELECT_CAMPAIGN_CUSTOMER_LIST_HEADING"
        title="INSTRUCTIONS.SELECT_CAMPAIGN_CUSTOMER_LIST"
      />
      <ButtonWhite
        capitalize
        primary
        secondary
        translate
        onClick={handleToggle}
      >
        IMPORT_CUSTOMERS
      </ButtonWhite>
      <GroupPickerBlock>
        <SectionHeadline title="INSTRUCTIONS.SELECT_CUSTOMER" />
        <GroupsPicker
          account={account}
          groups={customerGroups}
          groupsSelected={customers}
          onChange={handleChange}
        />
      </GroupPickerBlock>
      {activateActions && (
        <Actions
          disabled={customers.length === 0}
          onBack={onBack}
          onNext={handleConfirmation}
        />
      )}
    </div>
  );
};

Customers.propTypes = {
  account: PropTypes.number,
  activateActions: PropTypes.bool,
  className: PropTypes.string,
  customers: PropTypes.array,
  onBack: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default styled(Customers)`
  ${GroupPickerBlock} {
    margin-top: 3.2rem;
  }

  ${ButtonWhite} {
    background-color: ${theme('--color-light')};
  }

  ${SectionHeadline} {
    margin-bottom: 1.6rem;
  }

  ${GroupsPicker} {
    margin-top: -1.6rem;
  }
`;
