import React from 'react';
import PropTypes from 'prop-types';
import styled from '@styled-components';

import { Select, MultiSelect } from 'Components/Form';

const Toolbar = ({
  className,
  onPlatformChange = () => {},
  onSeriesChange = () => {},
  platformOptions,
  seriesOptions,
  showSeriesFilter,
}) => (
  <div className={className}>
    <Select onChange={onPlatformChange} options={platformOptions} />
    <MultiSelect
      disabled={!showSeriesFilter}
      onChange={onSeriesChange}
      options={seriesOptions}
    />
  </div>
);

Toolbar.propTypes = {
  className: PropTypes.string,
  onPlatformChange: PropTypes.func,
  onSeriesChange: PropTypes.func,
  platformOptions: PropTypes.arrayOf(PropTypes.object),
  seriesOptions: PropTypes.arrayOf(PropTypes.object),
  showSeriesFilter: PropTypes.bool,
};

export default styled(Toolbar)`
  display: flex;

  ${Select}, ${MultiSelect} {
    width: 21rem;
  }

  ${Select} {
    margin-right: 1.6rem;
  }
`;
