import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useIntl } from '@react-intl';
import { withStyles as css, theme } from '@styled-components';

import DataCard, { CHART } from 'Components/DataCard';

import { currentAccount } from 'Selectors';

import { withPlaceholder } from 'Containers/Placeholder';
import Chart from './Chart';
import Table from './Table';
import Toolbar from './Toolbar';

const mapStateToProps = state => ({
  account: currentAccount(state),
});

const ConversionRatesChart = ({
  account,
  className,
  data = {},
  from,
  to,
  unit,
}) => {
  const socials = Object.keys(data);
  const [socialSelected, setSocialSelected] = useState(socials[0] || null);
  const events = socialSelected ? Object.keys(data[socialSelected]) : [];
  const [eventsEnabled, setEventsEnabled] = useState(events);
  const [tab, setTab] = useState(CHART);
  const eventsData = data[socialSelected] || {};
  const intl = useIntl();

  const eventsOptions = events.map(event => ({
    label: intl.formatMessage({ capitalize: true, id: event }),
    name: event,
    value: event,
  }));

  const socialsOptions = socials.map(social => ({
    name: intl.formatMessage({ capitalize: true, id: social }),
    value: social,
  }));

  const handleTab = tabSelected => setTab(tabSelected);

  const handleSocialChange = social => {
    if (social !== socialSelected) {
      setSocialSelected(social);
    }
  };

  const handleEventsChange = eventsSelected => {
    if (JSON.stringify(eventsSelected) !== JSON.stringify(eventsEnabled)) {
      setEventsEnabled(eventsSelected);
    }
  };

  return (
    <DataCard
      account={account}
      chartTemplate={Chart}
      className={className}
      eventsData={eventsData}
      eventsEnabled={eventsEnabled}
      from={from}
      initial={CHART}
      onChange={handleTab}
      onPlatformChange={handleSocialChange}
      onSeriesChange={handleEventsChange}
      platformOptions={socialsOptions}
      seriesOptions={eventsOptions}
      showSeriesFilter={tab === CHART}
      tableTemplate={Table}
      title="CONVERSION_RATES"
      to={to}
      toolbar={Toolbar}
      unit={unit}
    />
  );
};

ConversionRatesChart.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.array),
  from: PropTypes.string,
  to: PropTypes.string,
  unit: PropTypes.string,
};

export default compose(
  connect(mapStateToProps),
  css`
    height: 100%;
    padding: 6.4rem 0 0;

    ${Chart} {
      height: 100%;
      margin: 0 2rem 0;
    }

    @media (${theme('--screen-small')}) {
      min-width: 40rem;
    }
  `,
  withPlaceholder,
)(ConversionRatesChart);
