import React, { useCallback, useState } from 'react';
import PropTypes from '@prop-types';
import styled, { theme, withStyles as css } from '@styled-components';
import { compose } from 'underscore';

import service from 'Services/faqs';

import Placeholder from 'Containers/Placeholder';
import { useMultiDataProviderContext } from 'Containers/MultiDataProvider';

import Button from 'Components/Button';
import Icon from 'Components/Icon';
import Link from 'Components/Link';
import { Row } from 'Components/ResponsiveTable';

import DeleteFaq from '../DeleteFaq';

const Actions = styled('div')``;
const Field = styled(Placeholder.div)``;
const QuestionField = styled(Field)``;
const Label = styled(Placeholder)``;
const ViewMore = styled('span')``;

const DesktopTemplate = ({
  account,
  className,
  empty,
  id,
  questions = [],
  type,
}) => {
  const { refetch } = useMultiDataProviderContext('faqs');
  const [deleteVisible, setDeleteVisible] = useState(false);

  const showDelete = useCallback(() => setDeleteVisible(true), []);
  const hideDelete = useCallback(() => setDeleteVisible(false), []);

  const questionList = questions.slice(0, 3);

  const handleDelete = useCallback(async () => {
    await service.del({
      account,
      id,
      type,
    });
    refetch();
    hideDelete();
  }, [account, hideDelete, id, refetch, type]);

  return (
    <>
      <DeleteFaq
        in={deleteVisible}
        onAccept={handleDelete}
        onCancel={hideDelete}
      />
      <Row className={className} empty={empty}>
        <QuestionField placeholder="placeholder">
          {questionList.map(({ text }) => (
            <Label placeholder="placeholder">{text}</Label>
          ))}
          {questions && questions.length > 3 && (
            <ViewMore>{`${questions.length - 3} more`}</ViewMore>
          )}
        </QuestionField>
        <Actions>
          <Button as={Link} to={`/builder/faqstudio/${type}_${id}`}>
            <Icon outline name="edit">
              ACTIONS.EDIT_MEMBER
            </Icon>
          </Button>
          <Button onClick={showDelete}>
            <Icon outline name="trash-bin">
              ACTIONS.REMOVE_MEMBER
            </Icon>
          </Button>
        </Actions>
      </Row>
    </>
  );
};

DesktopTemplate.propTypes = {
  account: PropTypes.string,
  className: PropTypes.string,
  empty: PropTypes.bool,
  id: PropTypes.string,
  questions: PropTypes.array,
  type: PropTypes.string,
};

export default compose(
  css`
    align-items: flex-start;
    grid-template-columns: 1fr 8rem;
    min-height: 7.2rem;
    text-align: left;

    &:hover {
      background: ${theme('--color-primary-10')};

      ${Actions} {
        display: flex;
      }
    }

    ${Field} {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;

      ${ViewMore} {
        ${theme('--font-small')}
        ${theme('--font-weight-book')}
        ${theme('--font-opacity-60')}
        display: block;
        padding-bottom: 1.2rem;
      }
    }

    ${Label} {
      ${theme('--font-medium')}
      ${theme('--font-weight-book')}
      ${theme('--font-opacity-80')}
      align-items: center;
      display: flex;
      height: 100%;
      min-height: 5.6rem;
      text-overflow: ellipsis;
      word-break: break-word;
    }
   
    ${Actions} {
      align-items: center;
      display: none;
      height: 100%;
      justify-content: space-between;
    }
  `,
)(DesktopTemplate);
