import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@styled-components';

import { currency as currentCurrency, currentAccount } from 'Selectors';

import service from 'Services/revenues';

import DataProvider from 'Containers/DataProvider';
import Template from './RevenuePerCampaign';
import EmptyTemplate from './EmptyTemplate';

const mapStateToProps = state => ({
  account: currentAccount(state),
  currency: currentCurrency(state),
});

const RevenuesPerCampaign = ({ account, className, currency }) => (
  <DataProvider
    className={className}
    currency={currency}
    emptyTemplate={EmptyTemplate}
    emptyVerifier={({ revenues }) => Object.values(revenues).length === 0}
    params={{ account }}
    query={service.getPerCampaign}
    template={Template}
  />
);

RevenuesPerCampaign.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
  currency: PropTypes.string,
};

export default compose(
  connect(mapStateToProps),
  withStyles``,
)(RevenuesPerCampaign);
