import React from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from '@prop-types';
import { FormattedMessage } from '@react-intl';

import Icon from 'Components/Icon';

const Label = styled('span')``;

const FilterButton = ({ className, onClick }) => (
  <button data-active className={className} onClick={onClick} type="button">
    <FormattedMessage capitalize component={Label} id="FILTER">
      FILTER
    </FormattedMessage>
    <Icon name="close" />
  </button>
);

FilterButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default styled(FilterButton)`
  ${theme('--font-small')}
  ${theme('--font-weight-demi')}
  align-items: center;
  background: none;
  border: none;
  border-radius: 0.6rem;
  cursor: pointer;
  display: flex;
  flex-grow: 0;
  font-weight: ${theme('--font-weight-demi')};
  justify-content: space-evenly;
  outline: none;
  transition: all 250ms ease-in;
  width: 6.6rem;

  ${Label} {
    transition: all 250ms ease-out;
  }

  ${Icon} * {
    transition: fill 250ms ease-out;
  }

  &:hover {
    background: ${theme('--color-blueish-white')};

    ${Icon} * {
      fill: ${theme('--color-primary')};
    }
  }

  &[data-active='true'] {
    background: linear-gradient(
      180deg,
      rgba(0, 132, 255, 0.2) 0%,
      rgba(0, 132, 255, 0.1) 100%
    );

    color: ${theme('--color-primary')};

    ${Icon} * {
      fill: ${theme('--color-primary')};
    }
  }

  @media (${theme('--screen-small')}) {
    visibility: visible;
  }
`;
