import React, { useState, useCallback } from 'react';
import styled, { theme } from '@styled-components';
import PropTypes from '@prop-types';
import { FormattedMessage } from '@react-intl';
import { useField } from 'spooner';

import SelectBrandContent from 'Components/SelectBrandContent';

import AddButton from './AddButton';
import ContentCard from './ContentCard';
import VideoThumbnail from './VideoThumbnail';

const SectionTitle = styled('h5')``;
const MediaCarousel = styled('div')``;

const AddMedia = ({ className }) => {
  const [previewingItemId, setPreviewingItemId] = useState(null);
  const makePreviewItem = useCallback(id => () => setPreviewingItemId(id), [
    setPreviewingItemId,
  ]);

  const { value: selectedItems = [], onChange: setSelectedItems } = useField({
    name: 'media',
  });

  const currentlyPreviewingItem = selectedItems.find(
    item => item.id === previewingItemId,
  );

  const [brandContentSelectorOpen, setBrandContentSelectorOpen] = useState(
    false,
  );
  const openBrandContentSelector = useCallback(
    () => setBrandContentSelectorOpen(true),
    [setBrandContentSelectorOpen],
  );
  const closeBrandContentSelector = useCallback(
    () => setBrandContentSelectorOpen(false),
    [setBrandContentSelectorOpen],
  );

  const handleSubmitSelection = useCallback(
    selection => {
      setSelectedItems([...selectedItems, ...selection]);
      closeBrandContentSelector();
    },
    [selectedItems, setSelectedItems, closeBrandContentSelector],
  );

  const makeHandleDelete = useCallback(
    deleteId => () => {
      setSelectedItems(selectedItems.filter(({ id }) => id !== deleteId));
    },
    [setSelectedItems, selectedItems],
  );

  return (
    <div className={className}>
      <FormattedMessage
        capitalize
        component={SectionTitle}
        id="INSTRUCTIONS.UPLOAD_MEDIA"
      />
      <MediaCarousel>
        {selectedItems.map(item => (
          <ContentCard
            key={item.id}
            isSelected={item === currentlyPreviewingItem}
            onDelete={makeHandleDelete(item.id)}
            onSelect={makePreviewItem(item.id)}
            source={item.source}
            type={item.type}
          />
        ))}
        <AddButton onClick={openBrandContentSelector} />
      </MediaCarousel>
      {currentlyPreviewingItem && currentlyPreviewingItem.type === 'video' && (
        <VideoThumbnail
          previewingId={previewingItemId}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
        />
      )}
      <SelectBrandContent
        in={brandContentSelectorOpen}
        onClose={closeBrandContentSelector}
        onSubmit={handleSubmitSelection}
      />
    </div>
  );
};

AddMedia.propTypes = {
  className: PropTypes.string,
};

export default styled(AddMedia)`
  background: ${theme('--color-blueish-white')};
  border-radius: 0.6rem;
  margin-top: 0.8rem;
  padding: 1.6rem;

  ${SectionTitle}{
    ${theme('--font-medium')}
    ${theme('--font-weight-book')}
    color: ${theme('--color-dark-night-60')};
  }

  ${MediaCarousel}{
    display: grid;
    grid-auto-rows: 8rem;
    grid-gap: 0.8rem;
    grid-template: 8rem / repeat(3, 1fr);
    padding-top: 2.4rem;
  }

  @media (${theme('--screen-small')}){
    ${MediaCarousel}{
      grid-auto-rows: 10rem;
      grid-template: 10rem / repeat(4, 10rem);
    }
  }
`;
