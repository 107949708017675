import React from 'react';
import styled from '@styled-components';
import PropTypes from '@prop-types';

import RadioType from './RadioType';

const TypeSelector = ({ className, init }) => {
  return (
    <div className={className}>
      <RadioType id="VIDEO" init={init} name="type" />
      <RadioType id="IMAGE" init={init} name="type" />
      <RadioType id="AUDIO" init={init} name="type" />
    </div>
  );
};

TypeSelector.propTypes = {
  className: PropTypes.string,
  init: PropTypes.string,
};

export default styled(TypeSelector)`
  display: grid;
  grid-gap: 0.8rem;
  grid-template: 5.4rem 5.4rem / 1fr;
  padding: 0.8rem 0;
`;
