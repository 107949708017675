import React from 'react';
import PropTypes from 'prop-types';
import styled, { theme } from '@styled-components';

const Image = styled('img')`
  background-image: url(${props => props.image});
`;

export const Avatar = ({ className, image = '/images/avatar.jpg', name }) => (
  <figure className={className}>
    <Image alt={name} image={image} src="/images/blank.png" />
  </figure>
);

Avatar.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string,
  name: PropTypes.string,
};

export default styled(Avatar)`
  border: 0.1rem solid ${theme('--color-dark-night-05')};
  border-radius: 50%;
  display: inline-block;
  margin: 0;
  min-height: 3.2rem;
  min-width: 3.2rem;
  overflow: hidden;

  & ${Image} {
    background-position: center;
    background-size: cover;
    display: block;
    height: 100%;
    width: 100%;
  }
`;
