import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from '@styled-components';

import Card from 'Components/Card';
import Table from 'Components/ResponsiveTable';

import Template from './components/Customer';
import { useHistory, useLocation } from 'react-router-dom';

const NewCustomers = ({
  className,
  data: { collection: customers = [] } = {},
  onCursorChange: handleCursorChange = () => {},
}) => {
  const { push } = useHistory();
  const { pathname } = useLocation();

  const handleClick = useCallback(
    id => () => {
      push(`${pathname}/customers/${id}`);
    },
    [pathname, push],
  );
  return (
    <Card className={className} title="NEW_CUSTOMERS">
      <Table
        items={customers}
        onCursorChange={handleCursorChange}
        options={{ onClick: handleClick }}
        placeholders={{
          heading: '34',
          subheading: '+1.5% customers this month',
        }}
        rows={4}
        template={Template}
      />
    </Card>
  );
};

NewCustomers.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    customers: PropTypes.array,
    total: PropTypes.number,
    trend: PropTypes.string,
  }),
  onCursorChange: PropTypes.func,
};

export default styled(NewCustomers)`
  padding: 6.4rem 0 0;
`;
