import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedMessage } from '@react-intl';
import styled, { theme } from '@styled-components';
import { useLocation } from 'react-router-dom';

import RawAvatar from 'Components/Avatar';
import RawLink from 'Components/Link';
import Stepper from 'Components/Stepper';

import { Row } from 'Components/ResponsiveTable';
import {
  withPlaceholderNoData as withPlaceholder,
  PlaceholderNoData as Placeholder,
} from 'Containers/Placeholder';

const Label = styled(Placeholder).attrs({ placeholder: 'label placeholder' })``;
const NameLabel = styled(Label)``;
const TagList = styled(Placeholder.ul)``;
const Tag = styled('li')``;
const Avatar = styled(withPlaceholder(RawAvatar))``;
const Link = styled(withPlaceholder(RawLink))``;

const Template = ({
  className,
  avatar,
  empty,
  firstName,
  id,
  lastName,
  surveyId,
  updatedAt,
  step,
  steps = [],
  type,
}) => {
  const { pathname } = useLocation();

  return (
    <Row className={className} empty={empty}>
      <NameLabel>
        <Avatar fill image={avatar} name={`${firstName} ${lastName}`} />
        <Link to={`${pathname}/customers/${id}`}>
          {firstName} {lastName}
        </Link>
      </NameLabel>
      <Label>
        {updatedAt !== '0' && (
          <FormattedDate
            day="2-digit"
            month="long"
            value={new Date(updatedAt)}
            year="numeric"
          />
        )}
      </Label>
      <TagList placeholder="mock tag">
        <FormattedMessage capitalize component={Tag} id={`USER_TYPE.${type}`} />
      </TagList>
      <Label>
        <Link to={`${pathname}/answers/${surveyId}-${id}`}>
          <Stepper
            currentStep={steps.findIndex(({ key }) => key === step)}
            steps={steps.map(({ displayName }) => displayName)}
          />
        </Link>
      </Label>
    </Row>
  );
};

Template.propTypes = {
  avatar: PropTypes.string,
  className: PropTypes.string,
  empty: PropTypes.bool,
  firstName: PropTypes.string,
  id: PropTypes.number,
  lastName: PropTypes.string,
  step: PropTypes.string,
  steps: PropTypes.arrayOf(PropTypes.string),
  surveyId: PropTypes.string,
  type: PropTypes.string,
  updatedAt: PropTypes.string,
};

export default styled(Template)`
  grid-template-columns: 1fr 15rem 10rem 20rem;
  height: 5.6rem;
  padding: 0 2.4rem;

  ${Label}, ${Link}, ${TagList} {
    ${theme('--font-medium')}
    ${theme('--font-opacity-60')}
    ${theme('--font-weight-book')}
    align-items: center;
    display: flex;

    ${Avatar} {
      height: 4rem;
      margin-right: 1.6rem;
      width: 4rem;
    }

    ${Tag} {
      border: 1px solid ${theme('--color-dark-night-40')};
      border-radius: 0.6rem;
      color: ${theme('--color-dark-night-40')};
      display: inline-block;
      height: 2.4rem;
      padding: 0.4rem;
      text-transform: uppercase;
    }
  }

  ${NameLabel}, ${Link} {
    ${theme('--font-weight-medium')}
    color: ${theme('--color-primary')};
  }

  ${Link}{
    width: 85%;
  }
`;
