import React from 'react';
import PropTypes from '@prop-types';
import styled from '@styled-components';

import { Row, Column, SortableColumn } from 'Components/ResponsiveTable';

const Header = ({ className, ...common }) => (
  <Row className={className}>
    <SortableColumn field="date" id="DATE" {...common} />
    <Column field="dales" id="SALES" {...common} />
  </Row>
);

Header.propTypes = {
  className: PropTypes.string,
};

export default styled(Header)`
  grid-template-columns: 2fr 1fr;
  padding: 1.2rem 2.4rem 0.8rem 2.4rem;
`;
