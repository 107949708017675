import React from 'react';
import PropTypes from 'prop-types';
import styled from '@styled-components';
import { Row, SortableColumn } from 'Components/ResponsiveTable';

const HeaderTemplate = ({ className, ...common }) => (
  <Row className={className}>
    <SortableColumn field="date" id="DATE" {...common} />
    <SortableColumn field="visits" id="VISITS" {...common} />
    <SortableColumn field="sales" id="SALES" {...common} />
  </Row>
);

HeaderTemplate.propTypes = {
  className: PropTypes.string,
};

export default styled(HeaderTemplate)`
  grid-template-columns: 3fr 2fr 2fr;
  padding: 1.2rem 2.4rem 0.8rem;
`;
