import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import styled, { theme } from '@styled-components';

import IconButton from 'Components/IconButton';
import Icon from 'Components/Icon';

const CalendarIcon = styled(Icon)``;
const Day = styled('div')``;
const NavIconLeft = styled(IconButton)``;
const NavIconRight = styled(IconButton)``;

const CustomDatePicker = ({
  className,
  date,
  displayFormat = 'ddd, MMM D, YYYY',
  id = 'date-single-picker',
  numberOfMonths = 1,
  onChange,
  weekDayFormat = 'ddd',
}) => {
  const [isOpen, setOpen] = useState(false);

  const handleFocusChange = ({ focused }) => setOpen(focused);

  return (
    <div className={className}>
      <SingleDatePicker
        hideKeyboardShortcutsPanel
        customInputIcon={<CalendarIcon name="calendar-outline" />}
        date={date}
        daySize={46}
        displayFormat={displayFormat}
        focused={isOpen}
        id={id}
        navNext={<NavIconRight name="chevronright" />}
        navPrev={<NavIconLeft name="chevronleft" />}
        numberOfMonths={numberOfMonths}
        onDateChange={onChange}
        onFocusChange={handleFocusChange}
        renderDayContents={day => <Day>{day.format('D')}</Day>}
        weekDayFormat={weekDayFormat}
      />
    </div>
  );
};

CustomDatePicker.propTypes = {
  className: PropTypes.string,
  date: PropTypes.instanceOf(moment),
  displayFormat: PropTypes.string,
  id: PropTypes.string,
  numberOfMonths: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  weekDayFormat: PropTypes.string,
};

export default styled(CustomDatePicker)`
  ${Day} {
    border-radius: 50% !important;
    height: 4rem;
    line-height: 4rem;
    margin: 0.2rem;
    text-align: center;
    width: 4rem;
  }

  ${NavIconLeft} {
    background: transparent;
    left: 4.5rem;
    position: absolute;
    top: 0.4rem;
  }

  ${NavIconRight} {
    background: transparent;
    position: absolute;
    right: 4.5rem;
    top: 0.4rem;
  }

  .SingleDatePicker,
  .SingleDatePicker > div,
  .SingleDatePickerInput,
  .SingleDatePickerInput .DateInput,
  .SingleDatePickerInput .DateInput input {
    width: 100%;
  }

  .SingleDatePicker,
  .SingleDatePicker > div,
  .SingleDatePickerInput {
    height: 4.8rem;
    width: 100%;
  }

  .SingleDatePickerInput .DateInput,
  .SingleDatePickerInput .DateInput input {
    height: 4.6rem;
    width: 100%;
  }

  .DateInput_input {
    ${theme('--font-medium')}
    color: ${theme('--color-dark-night-60')};
  }

  .SingleDatePicker_picker {
    z-index: 100;
  }

  .SingleDatePickerInput_calendarIcon {
    padding: 0;
  }

  .SingleDatePicker_picker__directionLeft * {
    border: none;
  }

  .DateInput_fangStroke,
  .DateInput_fang {
    display: none;
  }

  .DayPicker__withBorder {
    border: 1px solid ${theme('--color-dark-night-40')};
    border-radius: 0.6rem;
    overflow: hidden;
  }

  .DateInput_input,
  .DateInput_input__focused {
    border: none;
  }

  .SingleDatePickerInput {
    align-items: center;
    display: flex;
  }

  .SingleDatePickerInput,
  .SingleDatePickerInput * {
    border-radius: 0.6rem;
  }

  .DayPicker_weekHeader {
    padding-left: 1.3rem !important;
    top: 56px;
  }

  .DayPicker_weekHeader_li {
    width: 4.6rem !important;
  }

  .CalendarMonth {
    padding-left: 1.3rem !important;
  }

  .CalendarMonth_caption {
    padding-bottom: 40px;
  }

  .CalendarDay:hover {
    background-color: transparent;

    ${Day} {
      background-color: ${theme('--color-dark-night-10')};
    }
  }

  .CalendarDay__selected,
  .CalendarDay__selected:hover {
    background-color: transparent;

    ${Day} {
      background-color: ${theme('--color-primary')};
    }
  }
`;
