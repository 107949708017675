import React, { useCallback, useState } from 'react';
import { Gradient, Stop, Rect } from 'calvin-svg';
import PropTypes from 'prop-types';
import styled, {
  theme,
  withStyles as css,
  withTheme,
} from '@styled-components';
import { compose } from 'underscore';

import { useDeepCompareCallback, useDeepCompareEffect } from 'Hooks';

import Chart, { Funnel, Grid } from 'Components/Chart';
import Definition, { Detail, Term } from 'Components/Definition';
import { withPlaceholder } from 'Containers/Placeholder';

import Tooltip from './Tooltip';

const Number = styled('span')``;
const Step = styled('div')``;

const duration = 1000;

function transform(steps, step) {
  const index = steps.findIndex(({ step: s }) => s === step.step);
  return {
    amount: step.value,
    index,
    name: step.displayName,
  };
}

const Summary = ({
  className,
  onChange: handleChange = () => {},
  step,
  steps,
  theme: palette,
}) => {
  const [, ...intermediateSteps] = steps;
  const [current, setStep] = useState(transform(intermediateSteps, step));
  const [hover, setHover] = useState(null);

  useDeepCompareEffect(() => {
    setHover(null);
    setStep(transform(intermediateSteps, step));
  }, [intermediateSteps, setHover, step, setStep]);

  const handleClick = useCallback(
    index => {
      handleChange(index);
      setHover(null);
      setStep(transform(intermediateSteps, steps[index]));
    },
    [handleChange, intermediateSteps, setHover, steps],
  );

  const handleOut = useDeepCompareCallback(() => {
    setHover(null);
  }, [setHover]);

  const handleOver = useDeepCompareCallback(
    index => {
      setHover(transform(intermediateSteps, steps[index + 1]));
    },
    [intermediateSteps, setHover],
  );

  const values = steps.map(({ value }) => value);

  const [total] = values;
  const length = values.length ? values.length - 1 : 0;

  return (
    <section className={className}>
      <Chart>
        <Gradient right id="gradient">
          <Stop color={palette['--color-chart-purple-80']} offset={0} />
          <Stop color={palette['--color-chart-blue']} offset={100} />
        </Gradient>
        <Grid
          hideBorders
          color={palette['--color-dark-night-05']}
          columns={length}
          left={4}
          right={4}
        />
        <Funnel
          color="url(#gradient)"
          data={values}
          duration={duration}
          padding={{ bottom: 12, left: 4, right: 4, top: 12 }}
          tooltip={() => {}}
        />
        {[...Array(length).keys()].map(key => (
          <g key={key} aria-label="step">
            <Rect
              color={hover && key === hover.index ? '#CCDFFF' : '#E5EFFF'}
              height="100%"
              onBlur={handleOut}
              onClick={() =>
                key === current.index ? handleClick(0) : handleClick(key + 1)
              }
              onFocus={() => handleOver(key)}
              onMouseOut={handleOut}
              onMouseOver={() => handleOver(key)}
              opacity={
                key === current.index || (hover && key === hover.index)
                  ? 0.6
                  : 0
              }
              width={`calc((100% - 8px) / ${length})`}
              x={`calc((100% - 8px) / ${length} * ${key} + 4px)`}
              y={0}
            />
            <Tooltip
              index={key}
              length={length}
              opacity={+(hover && hover.index === key)}
            >
              {intermediateSteps[key].displayName}
            </Tooltip>
          </g>
        ))}
      </Chart>
      <Step style={{ opacity: current.index > -1 ? 1 : 0 }}>
        <Definition>
          <Term capitalize translate>
            STEP
          </Term>
          <Detail>
            <Number>{current.number}</Number> {current.name}
          </Detail>
          <Term capitalize translate>
            %_ACHIEVED
          </Term>
          <Detail translate values={{ percent: current.amount / total || 0 }}>
            %PERCENT%_USERS
          </Detail>
          <Term capitalize translate>
            NUMBER_OF_USERS
          </Term>
          <Detail translate values={{ number: current.amount, total }}>
            %NUMBER%_OUT_OF_%TOTAL%
          </Detail>
        </Definition>
      </Step>
    </section>
  );
};

Summary.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  step: PropTypes.object,
  steps: PropTypes.arrayOf(PropTypes.object),
  theme: PropTypes.object,
};

export default compose(
  css`
    position: relative;

    ${Chart} {
      height: 36.8rem;
      margin: 0 -0.4rem;
      width: calc(100% + 0.8rem);

      rect {
        cursor: pointer;
        transition: opacity 250ms ease-out;
      }
      ${Tooltip} {
        transform: scale(1, -1);
      }
    }

    ${Step} {
      background-color: ${theme('--color-light')};
      border-radius: 0.4rem;
      bottom: 2.4rem;
      box-shadow: 0 0.8rem 2.4rem ${theme('--color-dark-night-05')},
        0 0.1rem 0px ${theme('--color-dark-night-20')};
      height: 8rem;
      left: 0.8rem;
      opacity: 0;
      position: absolute;
      width: calc(100% - 1.6rem);

      ${Definition} {
        grid-template: repeat(2, 1fr) / 1fr auto auto;
        height: 100%;
        padding: 1.6rem;
        width: 100%;

        ${Term}:first-of-type,
        ${Detail}:first-of-type {
          flex: 1;
        }
      }
    }
  `,
  withTheme,
  withPlaceholder,
)(Summary);
