import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@styled-components';

import DataProvider from 'Containers/DataProvider/index';
import service from 'Services/audience';
import { currentAccount } from 'Selectors';

import Template from './Template';
import EmptyTemplate from './EmptyTemplate';

const mapStateToProps = state => ({
  account: currentAccount(state),
});

const UsersByPlatformChart = ({ account, className }) => (
  <DataProvider
    className={className}
    emptyTemplate={EmptyTemplate}
    params={{ account }}
    query={service.getUserPlatform}
    template={Template}
  />
);

UsersByPlatformChart.propTypes = {
  account: PropTypes.number,
  className: PropTypes.string,
};

export default compose(
  connect(mapStateToProps),
  withStyles``,
)(UsersByPlatformChart);
