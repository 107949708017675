import styled, { theme } from '@styled-components';

export default styled('span')`
  ${theme('--font-small')}
  ${theme('--font-weight-medium')}
  align-items: center;
  border: 1px solid ${theme('--color-dark-night-40')};
  border-radius: 0.6rem;
  color: ${theme('--color-dark-night-60')};
  display: inline-flex;
  height: 2.4rem;
  justify-content: center;
  min-width: 6rem;
  overflow: hidden;
  padding: 0.5rem 0.8rem 0.3rem;
  position: relative;
  text-transform: uppercase;
  white-space: nowrap;
`;
